import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useEffect,
} from "react";
import { Box as BoxNew } from "fds";

interface HoverContextType {
  isHovered: boolean;
  setIsHovered: (value: boolean) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  triggerRef: React.RefObject<HTMLDivElement>;
  contentRef: React.RefObject<HTMLDivElement>;
  scrollableRef?: React.RefObject<HTMLDivElement>;
  isScrollable?: boolean;
}

interface BaseProps {
  children: ReactNode;
  classes?: string;
}

interface TriggerProps extends BaseProps {}
interface ContentProps extends BaseProps {
  direction?: "top" | "bottom";
  scrollableRef?: React.RefObject<HTMLDivElement>;
  mx?: number;
  my?: number;
}

interface HoverBoxProps extends BaseProps {
  defaultOpen?: boolean;
  scrollableRef?: React.RefObject<HTMLDivElement>;
  isScrollable?: boolean;
}

interface HoverBoxComponent extends React.FC<HoverBoxProps> {
  Trigger: React.FC<BaseProps>;
  Content: React.FC<BaseProps>;
}

const HoverContext = createContext<HoverContextType | undefined>(undefined);

function useHover() {
  const context = useContext(HoverContext);
  if (!context) {
    throw new Error("HoverBox로 외부를 감싼 후 사용해주세요. ");
  }
  return context;
}

function Trigger({ children, classes = "" }: TriggerProps) {
  const { setIsHovered, triggerRef } = useHover();

  return (
    <BoxNew
      ref={triggerRef}
      classes={classes}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ position: "relative" }}
    >
      {children}
    </BoxNew>
  );
}

function Content({ children, classes = "", mx = 24, my = 14 }: ContentProps) {
  const { isHovered, triggerRef, contentRef, scrollableRef, isScrollable } =
    useHover();

  const [shouldShowOnTop, setShouldShowOnTop] = useState(false);

  function getContentPosition() {
    if (shouldShowOnTop) {
      const triggerHeight =
        triggerRef.current?.getBoundingClientRect().height || 0;
      return {
        bottom: `calc(100% + ${triggerHeight + my + 18}px)`,
        left: `${mx}px`,
      }; // TODO: 하드코딩 체크 (확장할 때 점검)
    }

    return { top: `calc(100% + ${my / 2}px)`, left: `${mx}px` };
  }

  useEffect(() => {
    if (
      isHovered &&
      triggerRef.current &&
      contentRef.current &&
      scrollableRef?.current
    ) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const scrollableRect = scrollableRef.current.getBoundingClientRect();

      const scrollBottom =
        scrollableRect.top + scrollableRef.current.clientHeight;
      const contentBottomNew = triggerRect.bottom + contentRect.height * 2 + my; // TODO: 하드코딩 체크 (확장할 때 점검)

      const bottomOverflow = scrollBottom < contentBottomNew;

      setShouldShowOnTop(bottomOverflow);
    }
  }, [isHovered]);

  if (!isHovered) return null;

  return (
    <BoxNew
      ref={contentRef}
      height={"100%"}
      style={{
        position: "absolute",
        ...(!isScrollable
          ? {
              marginLeft: mx,
              marginTop: my / 2,
              top: "100%",
            }
          : {
              ...getContentPosition(),
            }),
      }}
      classes={`${classes}`}
    >
      {children}
    </BoxNew>
  );
}

const HoverBox: HoverBoxComponent = ({
  children,
  classes = "",
  defaultOpen = false,
  scrollableRef,
  isScrollable,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(defaultOpen);
  const containerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <HoverContext.Provider
      value={{
        isHovered,
        setIsHovered,
        containerRef,
        triggerRef,
        contentRef,
        scrollableRef,
        isScrollable,
      }}
    >
      <BoxNew
        ref={containerRef}
        style={{ position: "relative", display: "inline-block" }}
        classes={classes}
      >
        {children}
      </BoxNew>
    </HoverContext.Provider>
  );
};

HoverBox.Trigger = Trigger;
HoverBox.Content = Content;

export default HoverBox;
