import {
  InputField,
  Box,
  Button,
  getColor,
  Modal,
  Radio,
  Typography,
  useToast,
} from "fds";
import { useEffect, useState } from "react";
import useIpStore from "../../../redux/dispatcher/useIpStore";
import { IpFilterType } from "../../../types/Ip";
import { nanoid } from "@reduxjs/toolkit";
import utilsCommon from "../../../shared/utils/common";
import { useTranslation } from "react-i18next";
interface InsertUpdateIPModalProps {
  isOpen: boolean;
  tab: number;
  modalType: string;
  targetIPId: number;
  onClose: () => void;
  setRowIdCreated: (rowId: string) => void;
}

const InsertUpdateIPModal: React.FC<InsertUpdateIPModalProps> = ({
  isOpen,
  modalType,
  tab,
  targetIPId,
  onClose,
  setRowIdCreated,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { ipState, insertIp, updateIp, getIpList } = useIpStore();
  const [ipInfo, changeIpInfo] = useState({
    ipType: tab === 0 ? "W" : "B",
    ipAddress: "",
    createUser: "admin",
    summary: "",
    ipId: undefined,
  });
  const [error, changeErr] = useState("");

  useEffect(() => {
    if (targetIPId === -1) return;
    changeIpInfo(
      ipState.ipList.filter((item: IpFilterType) => item.ipId === targetIPId)[0]
    );
  }, [ipState.ipList, targetIPId]);
  const handleInsertOrUpdateIp = async () => {
    if (!utilsCommon.ipValidation(ipInfo.ipAddress)) {
      changeErr("INVALID_IP");
      return;
    }
    let checkDup = ipState.ipList.filter(
      (item: IpFilterType) =>
        item.ipAddress === ipInfo.ipAddress && item.ipType === ipInfo.ipType
    );
    if (checkDup.length > 0 && checkDup[0].ipId !== ipInfo.ipId) {
      toast.toastMsg(nanoid(), t("log.msg.logDuplicateMsg"), "error");
      return;
    }
    let resp: any =
      modalType === "CREATE" ? await insertIp(ipInfo) : await updateIp(ipInfo);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        modalType === "CREATE"
          ? t("log.msg.logAddSuccessMsg")
          : t("log.msg.logUpdateSuccsessMsg"),
        "success"
      );
      const idCreated = resp.payload.data?.ipId || "";
      setRowIdCreated(idCreated.toString());
      getIpList({});
      onClose();
    } else {
      toast.toastMsg(
        nanoid(),
        modalType === "CREATE"
          ? t("log.msg.commonCreateIpFailMsg")
          : t("log.msg.commonUploadIpFailMsg"),
        "error"
      );
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      width={540}
      title={modalType === "CREATE" ? t("log.add") : t("log.update")}
      footer={
        <Box justifyContent="flex-end">
          <Button
            type="secondary"
            btnStyle="fill"
            onClick={onClose}
            label={t("common.button.cancel")}
          />
          <Button
            btnStyle="fill"
            classes="mg ml-8"
            onClick={handleInsertOrUpdateIp}
            state={ipInfo.ipAddress.trim() === "" ? "disable" : "default"}
            label={t("common.button.save")}
          />
        </Box>
      }
      body={
        <Box direction="column" width={"100%"}>
          <Typography type="subtitle2">{t("common.list.type")}</Typography>
          <Typography
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
            classes="mg mt-4"
          >
            {t("log.msg.logGuideMsg")}
          </Typography>
          <Box height={32} classes="mg mt-6">
            <Radio
              label={t("log.whiteList")}
              check={ipInfo.ipType === "W"}
              onClick={() => changeIpInfo({ ...ipInfo, ipType: "W" })}
            />
            <Radio
              label={t("log.blackList")}
              check={ipInfo.ipType === "B"}
              onClick={() => changeIpInfo({ ...ipInfo, ipType: "B" })}
              classes="mg ml-24"
            />
          </Box>
          <Box classes="mg mt-24">
            <Typography type="subtitle2">IP</Typography>
            <Typography
              classes="mg ml-4"
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_ERROR")}
            >
              *
            </Typography>
          </Box>
          <InputField
            size={"md"}
            classes="mg mt-6"
            onChange={(e) =>
              changeIpInfo({ ...ipInfo, ipAddress: e.target.value })
            }
            placeholder={t("log.msg.logIpPlaceholderMsg")}
            value={ipInfo.ipAddress}
            maxLength={15}
            isError={error.includes("IP")}
            helpMessage={
              error.includes("IP") ? t("log.msg.commonInvalidIpMsg") : ""
            }
          />
          <Typography type="subtitle2" classes="mg mt-24">
            {t("log.description")}
          </Typography>
          <InputField
            size={"md"}
            maxLength={255}
            classes="mg mt-6"
            placeholder={t("log.msg.logDescPlaceholderMsg")}
            onChange={(e) =>
              changeIpInfo({ ...ipInfo, summary: e.target.value })
            }
            value={ipInfo.summary}
          />
        </Box>
      }
    ></Modal>
  );
};

export default InsertUpdateIPModal;
