import { useRef, useEffect, useState } from "react";

interface UseAbortControllerReturn {
  controller: AbortController | null;
  abortController: () => void;
  resetController: () => void;
}

export const useAbortController = (): UseAbortControllerReturn => {
  const [controller, setController] = useState<AbortController | null>(null);

  const abortController = () => {
    if (controller) {
      controller.abort();
    }
  };

  const resetController = () => {
    setController(new AbortController());
  };

  useEffect(() => {
    setController(new AbortController());
  }, []);

  return {
    controller,
    abortController,
    resetController,
  };
};
