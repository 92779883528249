import "./index.scss";
import React, { useEffect, useState } from "react";

import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Icons as IconsNew,
  Checkbox as CheckboxNew,
  InputField,
  Button as ButtonNew,
  useToast as useToastNew,
  Tooltip,
} from "fds";

import MindsatIcon from "../../shared/image/logo/mindsat";
import LoginImage from "../../shared/image/logo/Login";
import utilsCommon from "../../shared/utils/common";
import {
  removeLocalCustomerCode,
  removeSessionCustomerCode,
  removeUserInfoFromLocalStorage,
  setAccessToken,
} from "../../shared/utils/auth";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import useAccountStore from "../../redux/dispatcher/useAccountStore";
import { AccountResDTO } from "../../types/Login";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToastNew();
  const navigate = useNavigate();

  /** State */
  const [loginInfo, changeLoginInfo] = useState({
    email: "",
    name: "",
    pwd: "",
    checkPwd: "",
  });
  const [errorType, changeErrorType] = useState({
    isError: false,
    errorTarget: "",
    errorMsg: "",
  });
  const [showPassword, changeShowPassword] = useState({
    original: false,
    check: false,
  });
  const [pageStatus, changePageStatus] = useState<"login" | "findPW" | "setPW">(
    "login"
  );
  const [cookies, setCookie, removeCookie] = useCookies(["rememberEmail"]);
  const [rememberEmail, setRememberEmail] = useState<boolean>(
    !!cookies.rememberEmail
  );
  const [infoLoginMsg, changeInfoLoginMsg] = useState(false);
  const { loginSession, resetPassword, changePassword, sessionCheck } =
    useAccountStore();

  const handleSessionCheck = async () => {
    const resp: any = await sessionCheck();

    if (resp.payload && resp.payload.data.code === "SUCCESS") {
      window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
      return;
    } else {
      /** Page 가 바뀔때마다 state 값 초기화 */
      removeUserInfoFromLocalStorage();
      removeSessionCustomerCode();
      removeLocalCustomerCode();

      changeLoginInfo({
        email: cookies.rememberEmail ?? "",
        name: "",
        pwd: "",
        checkPwd: "",
      });
      changeErrorType({
        isError: false,
        errorTarget: "",
        errorMsg: "",
      });
      changeShowPassword({ original: false, check: false });
      changeInfoLoginMsg(false);
    }
  };
  /** UseEffect */
  useEffect(() => {
    // sessionCheck();
    handleSessionCheck();
  }, [pageStatus]);

  /** Function */
  const handleAfterLogin = async (data: AccountResDTO) => {
    const isSuper = data.user.managerType === 1 ? true : false;
    setAccessToken(data.token);
    // setAxiosHeaders();
    if (rememberEmail)
      setCookie("rememberEmail", data.user.managerEmail, { maxAge: 2629743 });
    else removeCookie("rememberEmail");
    // let managerInfo: any = await getManager(data.email);
    // if (managerInfo.payload && managerInfo.payload.manager_code) {
    // getMenu({
    //   accessType: data.user.managerType,
    //   managerCode: data.user.managerEmail,
    // });
    navigate(isSuper ? "/manageTrain/scenario" : "/dashboard");
    // }
  };
  // 임시 비밀번호
  const [tempPw, setTempPw] = useState<string | null>();

  /** 사용자에게서 받은 값들을 기준으로 validation check 후, 각 페이지에 맞는 액션을 취한다.
   * @param {"login" | "findPW" | "setPW"} type login (로그인 페이지) , findPW (비밀번호 찾기 페이지), setPW(임시 비밀번호 변경 페이지)
   */
  const handleLoginOrFindpw = async (type: "login" | "findPW" | "setPW") => {
    if (!checkValidation(type)) return;
    changeErrorType({
      isError: false,
      errorTarget: "",
      errorMsg: "",
    });

    // Do Action
    if (type === "login") {
      setCookie("rememberEmail", loginInfo.email, {
        maxAge: 60 * 60 * 24 * 30,
        path: "/",
      });
      const resp: any = await loginSession({
        userId: loginInfo.email,
        userPwd: loginInfo.pwd,
      });
      if (resp.payload && resp.payload.code === "SUCCESS") {
        /**임시 비밀번호 페이지 이동 */
        if (resp.payload?.data?.user?.isChangedPwd) {
          setTempPw(loginInfo.pwd);
          changePageStatus("setPW");
        } else {
          handleAfterLogin(resp.payload.data);
        }
      } else {
        changeErrorType({
          isError: true,
          errorTarget: "INVALID_INFO",
          errorMsg: t("login.msg.loginFailMsg"),
        });
      }
    }
    if (type === "findPW") {
      const resp: any = await resetPassword({
        email: loginInfo.email ?? rememberEmail,
        name: loginInfo.name,
      });
      if (resp.payload) {
        if (!resp.payload.data) {
          changeErrorType({
            isError: true,
            errorTarget: "findPW-name",
            errorMsg:
              resp.payload.code === "BAD_REQUEST"
                ? t("account.msg.commonNotMatchEmailAndNameMsg")
                : resp.payload.message ?? t("login.msg.loginFailMsg"),
          });
        } else {
          changeInfoLoginMsg(true);
        }
      }
    }
    if (type === "setPW") {
      const resp: any = await changePassword({
        email: loginInfo.email ?? rememberEmail,
        pwd: tempPw ?? "",
        newPwd: loginInfo.checkPwd,
      });
      if (resp.payload) {
        if (resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("account.msg.commonPwUpdateSuccessMsg"),
            "success"
          );
          changePageStatus("login");
        } else {
          toast.toastMsg(nanoid(), t("login.msg.loginFailMsg"), "error");
        }
      }
    }
  };
  const checkValidation = (type: "login" | "findPW" | "setPW") => {
    if (type === "findPW" && !loginInfo.email) {
      changeErrorType({
        isError: true,
        errorTarget: "email",
        errorMsg: t("account.msg.enterUserId"),
      });
      return false;
    }
    if (type === "findPW" && !loginInfo.name) {
      changeErrorType({
        isError: true,
        errorTarget: "name",
        errorMsg: t("user.msg.userUndefinedNameMsg"),
      });
      return false;
    }
    if (type !== "setPW" && !loginInfo.email) {
      changeErrorType({
        isError: true,
        errorTarget: "email",
        errorMsg: t("account.msg.enterUserId"),
      });
      return false;
    }
    if (type !== "findPW" && !loginInfo.pwd) {
      changeErrorType({
        isError: true,
        errorTarget: "pwd",
        errorMsg: t("account.msg.enterPassword"),
      });
      return false;
    }
    if (type === "setPW" && !loginInfo.checkPwd) {
      changeErrorType({
        isError: true,
        errorTarget: "checkPwd",
        errorMsg: t("account.msg.commomAddNewPwMsg"),
      });
      return false;
    }
    if (
      type === "setPW" &&
      loginInfo.pwd &&
      !utilsCommon.passwordValidation(loginInfo.pwd)
    ) {
      changeErrorType({
        isError: true,
        errorTarget: "pwd",
        errorMsg: t("manager.pwdInfo"),
      });
      return false;
    }
    if (
      loginInfo.checkPwd &&
      !utilsCommon.passwordValidation(loginInfo.checkPwd)
    ) {
      changeErrorType({
        isError: true,
        errorTarget: "checkPwd",
        errorMsg: t("manager.pwdInfo"),
      });
      return false;
    }
    if (type === "setPW" && loginInfo.checkPwd !== loginInfo.pwd) {
      changeErrorType({
        isError: true,
        errorTarget: "checkPwd",
        errorMsg: t("account.msg.commomNotMatchPwMsg"),
      });
      return false;
    }
    return true;
  };

  const handleClearLoginInfo = (e: any, key: string) => {
    e.stopPropagation();
    changeLoginInfo((loginInfo) => ({ ...loginInfo, [key]: "" }));
  };
  /** UI - 로그인 페이지 */
  const LoginPage = () => {
    return (
      <BoxNew direction="column" alignItems="center" width={"360px"}>
        <MindsatIcon classes="login-logo" width="222px" />
        <BoxNew classes="login-form">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("common.auth.emailOrId")}
          </TypographyNew>
          <InputField
            size={"lg"}
            placeholder={t("login.loginEmailPlaceholder")}
            value={loginInfo.email}
            classes="mg mb-24"
            isError={
              errorType.errorTarget === "email" ||
              errorType.errorTarget === "INVALID_INFO"
            }
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, email: e.target.value })
            }
            onKeyUp={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("login")
            }
            trailingIcon={
              loginInfo.email.length > 0 && (
                <BoxNew
                  onClick={(e: MouseEvent) => handleClearLoginInfo(e, "email")}
                  classes="login-link"
                >
                  <IconsNew
                    variant="line"
                    label="closeLine"
                    stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                  />
                </BoxNew>
              )
            }
          />
        </BoxNew>
        <BoxNew classes="login-form">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("common.auth.pwd")}
          </TypographyNew>
          <BoxNew direction="column">
            <InputField
              size={"lg"}
              placeholder={t("login.loginPwdPlaceholder")}
              value={loginInfo.pwd}
              isError={
                errorType.errorTarget === "pwd" ||
                errorType.errorTarget === "INVALID_INFO"
              }
              onChange={(e: any) =>
                changeLoginInfo({ ...loginInfo, pwd: e.target.value })
              }
              onKeyUp={(e: any) =>
                e.key === "Enter" && handleLoginOrFindpw("login")
              }
              onClick={(e: any) => handleClearLoginInfo(e, "pwd")}
              type={showPassword.original ? "text" : "password"}
              trailingIcon={
                <BoxNew classes="gap-8">
                  {loginInfo.pwd.length > 0 && (
                    <BoxNew
                      classes="login-link"
                      onClick={(e: any) => handleClearLoginInfo(e, "pwd")}
                    >
                      <IconsNew
                        variant="line"
                        label="closeLine"
                        stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                      />
                    </BoxNew>
                  )}
                  <BoxNew
                    onClick={(e: any) => {
                      e.stopPropagation();
                      changeShowPassword({
                        ...showPassword,
                        original: !showPassword.original,
                      });
                    }}
                  >
                    <Tooltip
                      message={
                        showPassword.original
                          ? t("common.auth.displayPwd")
                          : t("common.auth.hidePwd")
                      }
                    >
                      <IconsNew
                        variant="line"
                        label="preview"
                        isOn={showPassword.original}
                        stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                        classes="login-link"
                      />
                    </Tooltip>
                  </BoxNew>
                </BoxNew>
              }
            />
            {errorType.errorMsg && (
              <BoxNew classes="mg mt-8">
                <IconsNew
                  variant="line"
                  label="alertCircle"
                  stroke={getColor("COLOR_ICON_ERROR")}
                  classes="mg mr-2"
                />
                <TypographyNew
                  type="body2_rg"
                  exactColor={getColor("COLOR_ICON_ERROR")}
                >
                  {errorType.errorMsg}
                </TypographyNew>
              </BoxNew>
            )}
          </BoxNew>
        </BoxNew>
        <ButtonNew
          btnStyle="fill"
          state={loginInfo.email && loginInfo.pwd ? "default" : "disable"}
          type="primary"
          width={"100%"}
          onClick={() => handleLoginOrFindpw("login")}
          classes="mg mt-40"
          size="xlg"
          label={t("common.auth.signin")}
        />
        <BoxNew classes="login-under">
          <BoxNew alignItems="center">
            <CheckboxNew
              check={!!rememberEmail}
              onClick={() => setRememberEmail(!rememberEmail)}
            />
            <TypographyNew
              type="body2_rg"
              classes="mg ml-4"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("login.loginSaveEmail")}
            </TypographyNew>
          </BoxNew>
          <TypographyNew
            onClick={() => changePageStatus("findPW")}
            exactColor={getColor("COLOR_BG_BRAND", "blue")}
            classes="login-link"
          >
            {t("account.commonFindPwd")}
          </TypographyNew>
        </BoxNew>
      </BoxNew>
    );
  };
  /** UI - 비밀번호 찾기 페이지 */
  const FindPWPage = () => {
    return (
      <BoxNew direction="column" alignItems="center" width={"360px"}>
        <TypographyNew
          type="h1"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="login-logo"
        >
          {t("account.msg.findPwdMsg")}
        </TypographyNew>
        <BoxNew classes="login-form">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("login.loginEmailPlaceholder")}
          </TypographyNew>
          <InputField
            size={"lg"}
            classes="mg mb-24"
            placeholder={t("login.loginEmailPlaceholder")}
            value={loginInfo.email}
            isError={errorType.errorTarget === "email"}
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, email: e.target.value })
            }
            onKeyUp={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("findPW")
            }
            trailingIcon={
              loginInfo.email.length > 0 && (
                <BoxNew
                  classes="login-link"
                  onClick={(e: any) => handleClearLoginInfo(e, "email")}
                >
                  <IconsNew
                    variant="line"
                    label="closeLine"
                    stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                  />
                </BoxNew>
              )
            }
          />
        </BoxNew>
        <BoxNew classes="login-form">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("common.list.name")}
          </TypographyNew>
          <InputField
            size={"lg"}
            placeholder={t("login.loginNamePlaceholder")}
            value={loginInfo.name}
            isError={
              errorType.errorTarget === "findPW-name" ||
              errorType.errorTarget === "name"
            }
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, name: e.target.value })
            }
            onKeyUp={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("findPW")
            }
            trailingIcon={
              loginInfo.name.length > 0 && (
                <BoxNew
                  classes="login-link"
                  onClick={(e: any) => handleClearLoginInfo(e, "name")}
                >
                  <IconsNew
                    variant="line"
                    label="closeLine"
                    stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                  />
                </BoxNew>
              )
            }
          />
          {errorType.errorMsg && (
            <BoxNew classes="mg mt-8">
              <IconsNew
                variant="line"
                label="alertCircle"
                stroke={getColor("COLOR_ICON_ERROR")}
                classes="mg mr-2"
              />
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_ICON_ERROR")}
              >
                {errorType.errorMsg}
              </TypographyNew>
            </BoxNew>
          )}

          {infoLoginMsg && (
            <BoxNew classes="mg mt-8">
              <IconsNew
                variant="line"
                label="checkCircle"
                stroke={getColor("COLOR_TEXT_SUCCESS")}
                classes="mg mr-2"
              />
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SUCCESS")}
                whiteSpace="nowrap"
              >
                {t("account.msg.commonReLoginMsg")}
              </TypographyNew>
            </BoxNew>
          )}
        </BoxNew>
        <ButtonNew
          width={"100%"}
          btnStyle="fill"
          type="primary"
          state={loginInfo.email ? "default" : "disable"}
          onClick={() => handleLoginOrFindpw("findPW")}
          classes="mg mt-40"
          size="xlg"
          label={t("account.commonSendTempPw")}
        />
        <TypographyNew
          onClick={() => changePageStatus("login")}
          classes="mg mt-32 login-link"
          exactColor={getColor("COLOR_BG_BRAND", "blue")}
        >
          {t("account.commonBackToLogin")}
        </TypographyNew>
      </BoxNew>
    );
  };
  /** UI - 임시 비밀번호 페이지 */
  const SetPWPage = () => {
    return (
      <BoxNew direction="column" alignItems="center" width={"360px"}>
        <TypographyNew
          type="h1"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="login-logo"
        >
          {t("account.changeTempPwd")}
        </TypographyNew>
        <BoxNew classes="login-form">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("login.loginEmailPlaceholder")}
          </TypographyNew>
          <InputField
            size={"lg"}
            classes="mg mb-24"
            placeholder={t("login.loginEmailPlaceholder")}
            value={loginInfo.email}
            disabled
          />
        </BoxNew>
        <BoxNew classes="login-form">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("common.auth.pwd")}
          </TypographyNew>
          <InputField
            size={"lg"}
            classes="mg mb-24"
            placeholder={t("common.auth.pwd")}
            value={loginInfo.pwd}
            isError={errorType.errorTarget === "pwd"}
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, pwd: e.target.value })
            }
            onKeyUp={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("setPW")
            }
            type={showPassword.original ? "text" : "password"}
            trailingIcon={
              <BoxNew classes="gap-8">
                {loginInfo.pwd.length > 0 && (
                  <BoxNew
                    classes="login-link"
                    onClick={(e: any) => handleClearLoginInfo(e, "pwd")}
                  >
                    <IconsNew
                      variant="line"
                      label="closeLine"
                      stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                    />
                  </BoxNew>
                )}
                <BoxNew
                  onClick={(e: any) => {
                    e.stopPropagation();
                    changeShowPassword({
                      ...showPassword,
                      original: !showPassword.original,
                    });
                  }}
                >
                  <Tooltip
                    message={
                      showPassword.original
                        ? t("common.auth.displayPwd")
                        : t("common.auth.hidePwd")
                    }
                  >
                    <IconsNew
                      variant="line"
                      label="preview"
                      isOn={showPassword.original}
                      stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                    />
                  </Tooltip>
                </BoxNew>
              </BoxNew>
            }
          />
        </BoxNew>
        <BoxNew classes="login-form">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("account.commonPwCheck")}
          </TypographyNew>
          <InputField
            size={"lg"}
            placeholder={t("account.commonPwCheck")}
            value={loginInfo.checkPwd}
            isError={errorType.errorTarget === "checkPwd"}
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, checkPwd: e.target.value })
            }
            onKeyUp={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("setPW")
            }
            type={showPassword.check ? "text" : "password"}
            trailingIcon={
              <BoxNew classes="gap-8">
                {loginInfo.checkPwd.length > 0 && (
                  <BoxNew
                    classes="login-link"
                    onClick={(e: any) => handleClearLoginInfo(e, "checkPwd")}
                  >
                    <IconsNew
                      variant="line"
                      label="closeLine"
                      stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                    />
                  </BoxNew>
                )}
                <BoxNew
                  onClick={(e: any) => {
                    e.stopPropagation();
                    changeShowPassword({
                      ...showPassword,
                      check: !showPassword.check,
                    });
                  }}
                >
                  <Tooltip
                    message={
                      showPassword.check
                        ? t("common.auth.displayPwd")
                        : t("common.auth.hidePwd")
                    }
                  >
                    <IconsNew
                      variant="line"
                      label="preview"
                      isOn={showPassword.check}
                      stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                    />
                  </Tooltip>
                </BoxNew>
              </BoxNew>
            }
          />
          {errorType.errorMsg && (
            <BoxNew classes="mg mt-8">
              <IconsNew
                variant="line"
                label="alertCircle"
                stroke={getColor("COLOR_ICON_ERROR")}
                classes="mg mr-2"
              />
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_ICON_ERROR")}
              >
                {errorType.errorMsg}
              </TypographyNew>
            </BoxNew>
          )}
        </BoxNew>
        <ButtonNew
          onClick={() => handleLoginOrFindpw("setPW")}
          state={loginInfo.pwd && loginInfo.checkPwd ? "default" : "disable"}
          classes="mg mt-40"
          label={t("common.auth.signin")}
          width={"100%"}
          btnStyle="fill"
          size="xlg"
        />
        <TypographyNew
          onClick={() => changePageStatus("login")}
          classes="mg mt-32 login-link"
          exactColor={getColor("COLOR_BG_BRAND", "blue")}
        >
          {t("account.commonBackToLogin")}
        </TypographyNew>
      </BoxNew>
    );
  };
  return (
    <BoxNew alignItems="center" height={"100%"} classes="login">
      {pageStatus === "login"
        ? LoginPage()
        : pageStatus === "findPW"
        ? FindPWPage()
        : SetPWPage()}
      <LoginImage classes="login-img" />
    </BoxNew>
  );
};

export default Login;
