import {
  Box as BoxNew,
  Typography as TypographyNew,
  Icons as IconsNew,
  getColor,
  Tag as TagNew,
  Accordions as AccordionsNew,
  Button as ButtonNew,
  Table as TableNew,
  DropdownBody,
  Avatar,
  useToast,
  Tooltip,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { useEffect, useRef, useState } from "react";
import { QuizContentDTO, QuizCourseDTO } from "../../../types/Quiz";
import utilsCommon from "../../../shared/utils/common";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import { SimpleTreeNode, UserType } from "../../../types/User";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import TreeSimple from "../../../component/TreeSimple";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import HoverBox from "../../../component/HoverBox";
import classNames from "classnames";

import "./index.scss";
import "../../../shared/style/stickyLayout.scss";
import useStickyPage from "../../../hooks/useStickyObserver";
import { userListBean } from "../../../types/Info";
import { DeptType } from "../../../types/Dept";

const QuizCourseDetail: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const { elementRef, sentinel, isAtTop, isHorizontallyScrollable } =
    useStickyPage();
  const courseId = searchParams.get("course_id");
  const [course, setCourse] = useState<QuizCourseDTO>();

  const {
    quizState,
    getQuizCourseDetail,
    getQuizContentList,
    deleteQuizCourse,
  } = useQuizStore();
  const { deptState, getDeptList } = useDeptStore();

  const [selectedQuizList, setSelectedQuizList] = useState<QuizContentDTO[]>(
    []
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [userList, setUserList] = useState<UserType[]>();
  const [isUserListScrollable, setIsUserListScrollable] =
    useState<boolean>(false);
  const userListRef = useRef<HTMLDivElement>(null);
  const MAX_USER_LIST_HEIGHT = 372;

  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();
  const userCheckGroup = useCheckboxGroup<string>();
  const deptCheckGroup = useCheckboxGroup<string>();

  const getCourseDetail = async () => {
    if (courseId) {
      const response: any = await getQuizCourseDetail({
        courseId: parseInt(courseId),
      });

      if (response.payload && response.payload.code === "SUCCESS") {
        if (response.payload.data.userList.length > 0) {
          setUserList(response.payload.data.userList);
        } else {
          if (response.payload.data.courseInfo.deptIds.length > 0)
            deptCheckGroup.handleAllClick(
              response.payload.data.courseInfo.deptIds
            );

          let resp: any = await getDeptList(
            response.payload.data.courseInfo.deptIds
          );

          if (resp.payload) {
            let newUsers =
              resp.payload?.data?.list?.map(
                (item: SimpleTreeNode) => item.deptCode
              ) ?? [];

            userCheckGroup.handleAllClick([
              ...response.payload.data.courseInfo.userIds,
              ...newUsers,
            ]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (courseId) {
      getQuizContentList({});
      getCourseDetail();
      getDeptList();
    }
  }, []);

  useEffect(() => {
    changeOrganTree(deptState.organList);
  }, [deptState.organList]);

  const [isEnd, setIsEnd] = useState<boolean>(false);
  useEffect(() => {
    if (quizState.quizCourse && quizState.quizContentList.list.length > 0) {
      setCourse(quizState.quizCourse);

      // issue #14
      const selectedQuizzes = quizState.quizCourse.quizIds.map((id: string) => {
        const quiz = quizState.quizContentList.list.find(
          (q: QuizContentDTO) => q.quizId?.toString() === id
        );

        // 해당 quizId가 없으면 삭제된 퀴즈로 처리
        return (
          quiz || {
            commonId: -1,
            quizName: "삭제된 퀴즈",
            quizType: -1,
          }
        );
      });

      setSelectedQuizList(selectedQuizzes);

      const endTimeNum =
        typeof quizState.quizCourse.sendEndDate === "string"
          ? parseInt(quizState.quizCourse.sendEndDate)
          : quizState.quizCourse.sendEndDate;

      setIsEnd(endTimeNum && endTimeNum * 1000 < dayjs().valueOf());
    }
  }, [quizState.quizCourse, quizState.quizContentList]);

  const handleDeleteCourse = async () => {
    if (courseId) {
      const resp: any = await deleteQuizCourse({
        courseId: parseInt(courseId),
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("quiz.msg.deleteQuizCourseSuccess"),
          "success"
        );
        setIsDeleteModalOpen(false);
        navigate(`${process.env.PUBLIC_URL}/quiz/course`);
      } else {
        toast.toastMsg(nanoid(), t("quiz.msg.deleteQuizCourseFail"), "error");
      }
    }
  };

  useEffect(() => {
    if (userListRef.current) {
      const height = userListRef.current.scrollHeight;
      setIsUserListScrollable(height >= MAX_USER_LIST_HEIGHT);
    }
  }, [userList, organTree]);

  function transformCompleteList(quizzes: QuizContentDTO[]) {
    const dataTransformed = [];
    for (let i = 0; i < quizzes.length; i++) {
      const q = quizzes[i];

      const dataType = q.commonId ? (
        q.commonId === -1 ? (
          ""
        ) : (
          <TagNew
            as={"p"}
            type={"box"}
            text={t("template.public")}
            textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
            backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
          />
        )
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.private")}
          textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
        />
      );
      const dataQuizName = (
        <Tooltip message={q.quizName}>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {q.quizName}
          </TypographyNew>
        </Tooltip>
      );
      const dataCategory = q.categorys && (
        <CategoryTagList categorys={q.categorys} />
      );
      const dataFormal = (
        <TypographyNew
          ellipsis
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {q.quizType === 1
            ? t("quiz.label.formatTF")
            : q.quizType === -1
            ? ""
            : t("quiz.label.formatChoice")}
        </TypographyNew>
      );

      const qTransformed = {
        data: [
          { columnText: "" },
          { columnText: dataType },
          { columnText: dataQuizName },
          { columnText: dataCategory },
          { columnText: dataFormal },
        ],
        id: String(q.quizId),
      };
      dataTransformed.push(qTransformed);
    }
    return dataTransformed;
  }

  return (
    <BoxNew classes="sticky-layout quiz-course-detail">
      <BoxNew ref={sentinel}>
        <BoxNew
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          <BoxNew direction="column">
            <TitlePath path={[t("menu.quizTitle"), t("menu.quizCourse")]} />
            <BoxNew alignItems="center" classes="mg mt-8">
              <BoxNew
                onClick={() =>
                  navigate(`${process.env.PUBLIC_URL}/quiz/course`)
                }
                style={{ cursor: "pointer" }}
              >
                <IconsNew
                  variant="line"
                  label="arrow"
                  direction="left"
                  classes="mg mr-8"
                />
              </BoxNew>
              <TypographyNew
                type={"h1"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.label.details")}
              </TypographyNew>
            </BoxNew>
          </BoxNew>
          <BoxNew>
            {dayjs(course?.sendStartDate).valueOf() <
              dayjs().valueOf() / 1000 && (
              <ButtonNew
                btnStyle="fill"
                type={"secondary"}
                size={"lg"}
                leadingIcon={<IconsNew variant="line" label="mail" />}
                classes="mg ml-8"
                onClick={() => {
                  if (courseId) {
                    window.sessionStorage.setItem("quizResultFilter", courseId);
                    navigate(`${process.env.PUBLIC_URL}/quiz/result`);
                  }
                }}
                label={t("quiz.label.sentQuizResults")}
              />
            )}
            {!(
              (course?.userCount &&
                course?.sendMailCount &&
                course.sendCount ===
                  course?.userCount * course?.sendMailCount) ||
              isEnd
            ) && (
              <ButtonNew
                btnStyle="fill"
                size="lg"
                type={"secondary"}
                classes="mg ml-10"
                leadingIcon={<IconsNew variant="line" label="edit" />}
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/quiz/course/write?course_id=${courseId}`
                  )
                }
                label={t("common.button.update")}
              />
            )}

            <ButtonNew
              btnStyle="fill"
              type="error"
              size="lg"
              leadingIcon={
                <IconsNew
                  variant="line"
                  label="delete"
                  stroke={getColor("COLOR_ICON_ERROR")}
                />
              }
              onClick={() => setIsDeleteModalOpen(true)}
              classes="mg ml-10"
              label={t("common.button.delete")}
            />
          </BoxNew>
        </BoxNew>
      </BoxNew>

      {course && selectedQuizList.length > 0 && (
        <BoxNew classes="body">
          {/* basic info start  */}
          <AccordionsNew
            label={t("quiz.label.basicInfo")}
            initialOpenStatus={true}
          >
            <BoxNew
              direction="column"
              classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28"
            >
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.label.courseTitle")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    width={598}
                  >
                    {course.courseName}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew height={20}>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.label.period")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew direction="column" justifyContent="space-between">
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    classes="tnum"
                  >
                    {`${utilsCommon.getFullDateStr(course.sendStartDate)} ~
                ${utilsCommon.getFullDateStr(course.sendEndDate)}`}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew height={20}>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.label.solvePeriod")}
                  </TypographyNew>
                </BoxNew>

                <BoxNew direction="column" justifyContent="space-between">
                  <TypographyNew>{`${
                    course.quizSolvePeriod === null ? 7 : course.quizSolvePeriod
                  }${"일"}`}</TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew height={20}>
                <BoxNew
                  direction="column"
                  justifyContent="space-between"
                  width={220}
                  classes="mg mr-28"
                >
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.label.emailsPerTarget")}
                  </TypographyNew>
                </BoxNew>

                <BoxNew alignItems="center">
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >{`${course.sendMailCount}${t(
                    "program.programDataUnit"
                  )}`}</TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew height={20}>
                <BoxNew
                  direction="column"
                  justifyContent="space-between"
                  width={220}
                  classes="mg mr-28"
                >
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.label.quizPerMail")}
                  </TypographyNew>
                </BoxNew>

                <BoxNew alignItems="center">
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >{`${course.quizCount}${t(
                    "program.programTemplateUnit"
                  )}`}</TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew height={20} classes="mg mb-16">
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.label.courseStatus")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {course.activateFlag ? "ON" : "OFF"}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </AccordionsNew>
          {/* basic info end   */}

          {/* user info start  */}
          <AccordionsNew
            label={t("quiz.label.targets")}
            initialOpenStatus={true}
          >
            <BoxNew classes="pd pl-8 pr-8 pb-28" direction="column">
              {userList && userList.length > 0 ? (
                <BoxNew direction="row" classes="mg mt-32">
                  <BoxNew width={220} height={"100%"}>
                    <TypographyNew
                      type="subtitle2"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    >
                      {t("user.user")}
                    </TypographyNew>
                  </BoxNew>
                  <BoxNew classes="mg ml-28" width={620}>
                    <BoxNew
                      ref={userListRef}
                      classes={classNames("user-list", {
                        scrollable: isUserListScrollable,
                      })}
                      style={{
                        maxHeight: `${MAX_USER_LIST_HEIGHT}px`,
                      }}
                    >
                      {userList.map((u) => (
                        <BoxNew alignItems="center" height={32} style={{}}>
                          <BoxNew>
                            <HoverBox
                              scrollableRef={userListRef}
                              isScrollable={isUserListScrollable}
                            >
                              <HoverBox.Trigger>
                                <BoxNew classes="user-list-user">
                                  <IconsNew
                                    variant="fill"
                                    label="user"
                                    stroke={getColor(
                                      "COLOR_AVATAR_LIGHT_BLUE_50"
                                    )}
                                    classes="mg mr-4"
                                  />
                                  <TypographyNew
                                    width={256}
                                    ellipsis
                                    textAlign="start"
                                  >{`${u.userName} (${u.userEmail})`}</TypographyNew>
                                </BoxNew>
                              </HoverBox.Trigger>
                              <HoverBox.Content>
                                <DropdownBody width={280}>
                                  <BoxNew
                                    classes="gap-12 pd pt-12 pb-12"
                                    alignItems="center"
                                  >
                                    <Avatar
                                      name={u.userName}
                                      uniqueCode={u.userEmail || ""}
                                      size={"48"}
                                    />
                                    <BoxNew direction={"column"}>
                                      <TypographyNew
                                        type="subtitle1"
                                        exactColor={getColor(
                                          "COLOR_TEXT_PRIMARY"
                                        )}
                                      >
                                        {u.userName}
                                      </TypographyNew>
                                      <TypographyNew
                                        type="caption1_rg"
                                        exactColor={getColor(
                                          "COLOR_TEXT_SECONDARY"
                                        )}
                                      >
                                        {u.deptName}
                                        {u.jobTitle && ` / ${u.jobTitle}`}
                                      </TypographyNew>
                                    </BoxNew>
                                  </BoxNew>
                                </DropdownBody>
                              </HoverBox.Content>
                            </HoverBox>
                          </BoxNew>
                        </BoxNew>
                      ))}
                    </BoxNew>
                  </BoxNew>
                </BoxNew>
              ) : (
                <>
                  {course?.deptDetailList &&
                    course?.deptDetailList?.length > 0 && (
                      <BoxNew direction="row" classes="mg mt-32">
                        <BoxNew width={220} height={"100%"}>
                          <TypographyNew
                            type="subtitle2"
                            exactColor={getColor("COLOR_TEXT_SECONDARY")}
                          >
                            {t("dept.dept")}
                          </TypographyNew>
                        </BoxNew>
                        <BoxNew width={600} classes="mg ml-28">
                          <BoxNew
                            ref={userListRef}
                            classes={classNames("user-list", {
                              scrollable: isUserListScrollable,
                            })}
                            style={{
                              maxHeight: `${MAX_USER_LIST_HEIGHT}px`,
                            }}
                          >
                            {course?.deptDetailList?.map((u: DeptType) => (
                              <BoxNew classes="user-list-dept">
                                <IconsNew
                                  variant="fill"
                                  label="users"
                                  stroke={getColor(
                                    "COLOR_AVATAR_LIGHT_BLUE_50"
                                  )}
                                  classes="mg mr-4"
                                />
                                <TypographyNew
                                  width={256}
                                  ellipsis
                                  textAlign="start"
                                >
                                  {u.deptName}
                                </TypographyNew>
                              </BoxNew>
                            ))}
                          </BoxNew>
                        </BoxNew>
                      </BoxNew>
                    )}
                  {course?.userDetailList &&
                    course?.userDetailList?.length > 0 && (
                      <BoxNew direction="row" classes="mg mt-32">
                        <BoxNew width={220} height={"100%"}>
                          <TypographyNew
                            type="subtitle2"
                            exactColor={getColor("COLOR_TEXT_SECONDARY")}
                          >
                            {t("user.user")}
                          </TypographyNew>
                        </BoxNew>
                        <BoxNew width={620} classes="mg ml-28">
                          <BoxNew
                            ref={userListRef}
                            classes={classNames("user-list", {
                              scrollable: isUserListScrollable,
                            })}
                            style={{
                              maxHeight: `${MAX_USER_LIST_HEIGHT}px`,
                            }}
                          >
                            {course?.userDetailList?.map((u: userListBean) => (
                              <HoverBox
                                scrollableRef={userListRef}
                                isScrollable={isUserListScrollable}
                              >
                                <HoverBox.Trigger>
                                  <BoxNew classes="user-list-user">
                                    <IconsNew
                                      variant="fill"
                                      label="user"
                                      stroke={getColor(
                                        "COLOR_AVATAR_LIGHT_BLUE_50"
                                      )}
                                      classes="mg mr-4"
                                    />
                                    <TypographyNew
                                      width={256}
                                      ellipsis
                                      textAlign="start"
                                    >{`${u.userName} (${u.userEmail})`}</TypographyNew>
                                  </BoxNew>
                                </HoverBox.Trigger>
                                <HoverBox.Content>
                                  <DropdownBody width={280}>
                                    <BoxNew
                                      classes="gap-12 pd pt-12 pb-12"
                                      alignItems="center"
                                    >
                                      <Avatar
                                        name={u.userName}
                                        uniqueCode={u.userEmail || ""}
                                        size={"48"}
                                      />
                                      <BoxNew direction={"column"}>
                                        <TypographyNew
                                          type="subtitle1"
                                          exactColor={getColor(
                                            "COLOR_TEXT_PRIMARY"
                                          )}
                                        >
                                          {u.userName}
                                        </TypographyNew>
                                        <TypographyNew
                                          type="caption1_rg"
                                          exactColor={getColor(
                                            "COLOR_TEXT_SECONDARY"
                                          )}
                                        >
                                          {u.deptName}
                                          {u.jobTitle && ` / ${u.jobTitle}`}
                                        </TypographyNew>
                                      </BoxNew>
                                    </BoxNew>
                                  </DropdownBody>
                                </HoverBox.Content>
                              </HoverBox>
                            ))}
                          </BoxNew>
                        </BoxNew>
                      </BoxNew>
                    )}
                </>
              )}
            </BoxNew>
          </AccordionsNew>
          {/* user info end   */}

          {/* quiz  info start  */}
          <AccordionsNew
            label={t("quiz.label.quizQuestions")}
            initialOpenStatus={true}
          >
            <BoxNew classes="mg mt-28 pd pl-8 pr-8 pb-28" direction="column">
              <BoxNew alignItems="center" classes="mg mb-12">
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {t("quiz.label.added")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew classes="mg ml-4">
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
                  >
                    {course.quizIds?.length}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <TableNew
                classes="table-chip-hover"
                border="none"
                columnHeight="md"
                clickIds={[]}
                data={transformCompleteList(selectedQuizList)}
                endReached={() => {}}
                header={[
                  {
                    headerText: "",
                    width: "8px",
                  },
                  {
                    headerText: t("quiz.table.type"),
                    width: "72px",
                  },
                  {
                    headerText: t("quiz.table.quizName"),
                    width: "360px",
                  },
                  {
                    headerText: t("quiz.table.category"),
                    width: "calc(100% - 636px)",
                  },
                  {
                    headerText: t("quiz.table.formal"),
                    width: "160px",
                  },
                ]}
                height={`${
                  // 테이블 높이를 데이터 갯수에 반응형으로 처리
                  (selectedQuizList.length + 1) * 50 > 612
                    ? 612
                    : (selectedQuizList.length + 1) * 50
                }px`}
              />
            </BoxNew>
          </AccordionsNew>
          {/* quiz  info end   */}
        </BoxNew>
      )}

      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title={t("quiz.label.deleteCourse")}
          type="delete"
          afterSubmitButton={handleDeleteCourse}
          body={
            <BoxNew direction="column" justifyContent="flex-start" width="100%">
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.msg.deleteQuizCourseMsg")}
              </TypographyNew>
            </BoxNew>
          }
        />
      )}
    </BoxNew>
  );
};

export default QuizCourseDetail;
