import "./index.scss";

import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Dropdown as DropdownNew,
  DropdownItem as DropdownItemNew,
  InputField,
  Icons as IconsNew,
  Button as ButtonNew,
  Chip,
  Modal as ModalNew,
  Table as TableNew,
  useToast,
  CalendarRange,
  Checkbox,
  Tooltip,
} from "fds";

import { useTranslation } from "react-i18next";
import TitlePath from "../../component/TitlePath";
import { useEffect, useState } from "react";
import {
  QuizCourseDTO,
  QuizSendHistoryDTO,
  QuizSendHistoryReqDTO,
} from "../../types/Quiz";
import useQuizStore from "../../redux/dispatcher/useQuizStore";
import utilsCommon from "../../shared/utils/common";
import NoDashboardImage from "../../shared/image/NoDashboardImage";
import NoSearchResultImage from "../../shared/image/NoSearchResultImage";
import { nanoid } from "@reduxjs/toolkit";
import { FilterBtnActivityCheckUtils } from "../../shared/utils/FilterBtnActivityCheckUtils";
import { useElementPosition } from "../../hooks/useElementPosition";

import "../../shared/style/filters.scss";
import useCheckboxGroup from "fds/src/hooks/useCheckboxGroup";
import { useTableOrder } from "../../hooks/useTableOrder";
import dayjs from "dayjs";
import { DateType } from "../../component/DateModal";

enum ColumnsTypeToOrder {
  courseId = "courseId",
  deptName = "deptName",
  sendingDate = "sendingDate",
}

const QuizResult: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const {
    quizState,
    getQuizSendHistory,
    getQuizCourses,
    exportQuizSendHistory,
    resendQuizSendHistory,
  } = useQuizStore();

  const [historyList, setHistoryList] = useState<QuizSendHistoryDTO[]>([]);
  const [historyListTotal, setHistoryListTotal] = useState<number>(0);
  const [courseList, setCourseList] = useState<number[]>();
  const [buttonClick, setButtonClick] = useState(0);

  const [filterData, setFilterData] = useState<QuizSendHistoryReqDTO>(
    {} as QuizSendHistoryReqDTO
  );

  const [tempUserName, setTempUserName] = useState<string>("");
  const [tempDeptName, setTempDeptName] = useState<string>("");
  const [tempCourseName, setTempCourseName] = useState<string>("");
  const [tempUserNames, setTempUserNames] = useState<string[]>([]);
  const [tempDeptNames, setTempDeptNames] = useState<string[]>([]);
  const [tempCourseNames, setTempCourseNames] = useState<string[]>([]);

  const [isResendModalOpen, setIsResendModalOpen] = useState<number>(-1);
  const [isResendModalAllOpen, setIsResendModalAllOpen] =
    useState<boolean>(false);

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const initialSelectedPeriod = {
    value: "PERIOD_3MONTH",
    start: dayjs()
      .subtract(3, "month")
      .add(1, "day")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  };
  const [selectedPeriod, changeSelectedPeriod] = useState(
    initialSelectedPeriod
  );

  const toast = useToast();

  const resetFilter = () => {
    setFilterData({} as QuizSendHistoryReqDTO);
    setTempCourseNames([]);
    setTempUserNames([]);
    setTempDeptNames([]);
  };
  const changeDateFilter = (type: string) => {
    if (type === "ALL") {
      changeSelectedPeriod({
        value: type,
        start: new Date().valueOf(),
        end: new Date().valueOf(),
      });
    } else if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 12,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const dateModal = () => {
    return (
      <ModalNew
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setFilterData({
          ...filterData,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        });
    },
  });

  const checkboxGroup = useCheckboxGroup();
  const tempConfigIdsCheckbox = useCheckboxGroup();

  useEffect(() => {
    setFilterData((prev) => {
      return {
        ...prev,
        sendingStartDate:
          selectedPeriod.value === "ALL"
            ? undefined
            : Math.trunc(selectedPeriod.start / 1000),
        sendingEndDate:
          selectedPeriod.value === "ALL"
            ? undefined
            : Math.trunc(selectedPeriod.end / 1000),
      };
    });
  }, [selectedPeriod]);

  useEffect(() => {
    getQuizSendHistory({ ...filterData, intervalTime: 9 });
  }, [filterData]);

  useEffect(() => {
    getQuizCourses({});
    const courseFilter = window.sessionStorage.getItem("quizResultFilter");
    if (courseFilter) {
      setFilterData((prev) => ({ ...prev, courseId: parseInt(courseFilter) }));
      window.sessionStorage.removeItem("quizResultFilter");
    }
  }, []);

  useEffect(() => {
    if (quizState.quizSendHistoryList.list) {
      setHistoryList(quizState.quizSendHistoryList.list);
      setHistoryListTotal(quizState.quizSendHistoryList.total);
    }
  }, [quizState.quizSendHistoryList]);

  useEffect(() => {
    if (quizState.quizCourseList.list.length > 0) {
      let tempCourseList = quizState.quizCourseList.list.map(
        (i: QuizCourseDTO) => i.courseId
      );
      setCourseList(tempCourseList);
      tempConfigIdsCheckbox.handleAllClick(tempCourseList);
    }
  }, [quizState.quizCourseList]);

  const onResendMail = async () => {
    if (isResendModalOpen !== -1) {
      const resp: any = await resendQuizSendHistory([
        {
          sendHistoryId: isResendModalOpen,
        },
      ]);

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(nanoid(), t("quiz.msg.resendSuccess"), "success");
        setIsResendModalOpen(-1);
        getQuizSendHistory(filterData);
      }
    }
  };

  const onResendMailAll = async () => {
    if (checkboxGroup.selected.length > 0) {
      const resp: any = await resendQuizSendHistory(
        checkboxGroup.selected.map((s) => {
          return { sendHistoryId: parseInt(s as string) };
        })
      );

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(nanoid(), t("quiz.msg.resendSuccess"), "success");
        checkboxGroup.handleAllClick([]);
        setIsResendModalAllOpen(false);
        getQuizSendHistory(filterData);
      }
    }
  };

  function transformEmailHistory(emailHistory: QuizSendHistoryDTO[]) {
    const emailHistoryTransformed = [];
    for (let i = 0; i < emailHistory.length; i++) {
      const h = emailHistory[i];

      const dataCourseId = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {h.courseId}
        </TypographyNew>
      ); // 회차
      // 대상자
      const dataUsername = (
        <Tooltip message={h.userName}>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {h.userName}
          </TypographyNew>
        </Tooltip>
      ); // 대상자
      const dataDeptName = (
        <Tooltip message={h.deptName}>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {h.deptName}
          </TypographyNew>
        </Tooltip>
      ); // 대상자 부서
      const dataCourseName = (
        <Tooltip message={h.courseName}>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {h.courseName}
          </TypographyNew>
        </Tooltip>
      ); // 코스 제목
      const dataCount = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {h.courseSendIdx} / {h.courseSendCount}
        </TypographyNew>
      ); // 발송 회차
      const dataSendingDate = (
        <TypographyNew
          ellipsis
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="tnum"
        >
          {utilsCommon.getFullDateStr(h.sendingDate)}
        </TypographyNew>
      ); // 발송 일시
      const dataIsSendingSucceeded = // 발송 현황
        h.historyResult === 1 ? (
          <BoxNew classes="dot__container">
            <BoxNew classes="dot yellow__dot" />
            <TypographyNew
              ellipsis
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("program.programSendWait")}
            </TypographyNew>
          </BoxNew>
        ) : h.historyResult === 2 ? (
          <BoxNew classes="dot__container">
            <BoxNew classes="dot green__dot" />
            <TypographyNew
              ellipsis
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("program.programSendSuccess")}
            </TypographyNew>
          </BoxNew>
        ) : (
          <BoxNew classes="dot__container">
            <BoxNew classes="dot red__dot" />
            <TypographyNew
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("program.programSendFail")}
            </TypographyNew>
          </BoxNew>
        );
      const dataIsLearningCompleted = // 학습 여부
        h.historyResult === 2 ? (
          h.completionFlag ? (
            <BoxNew classes="dot__container">
              <BoxNew classes="dot blue__dot" />
              <TypographyNew
                ellipsis
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.label.completed")}
              </TypographyNew>
            </BoxNew>
          ) : (
            <BoxNew alignItems="center">
              <BoxNew
                classes={
                  h.resendFlag
                    ? `dot__container resend__container`
                    : `dot__container`
                }
              >
                <BoxNew classes="dot gray__dot" />
                <TypographyNew
                  classes="resend__text"
                  ellipsis
                  type={"body2_rg"}
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("quiz.label.notStarted")}
                </TypographyNew>
                <BoxNew
                  classes="resend__button"
                  onClick={() => setIsResendModalOpen(h.sendHistoryId)}
                >
                  {t("quiz.label.resend")}
                </BoxNew>
              </BoxNew>
            </BoxNew>
          )
        ) : (
          "-"
        );

      const historyTransformed = {
        data: [
          { columnText: dataCourseId },
          { columnText: dataUsername },
          { columnText: dataDeptName },
          { columnText: dataCourseName },
          { columnText: dataCount },
          { columnText: dataSendingDate },
          { columnText: dataIsSendingSucceeded },
          { columnText: dataIsLearningCompleted },
        ],
        id: String(h.sendHistoryId),
        checkBoxClick: () => {
          checkboxGroup.handleClick(h.sendHistoryId);
        },
      };
      emailHistoryTransformed.push(historyTransformed);
    }

    return emailHistoryTransformed;
  }

  const checkAllResend = () => {
    let flag = false;

    checkboxGroup.selected.map((s) => {
      historyList.map((h) => {
        if (
          parseInt(s as string) === h.sendHistoryId &&
          h.historyResult === 2 &&
          !h.completionFlag
        ) {
          flag = true;
        }
      });
    });

    return flag;
  };

  function checkAnyFilterSelected() {
    return (
      filterData.courseId ||
      (filterData.userNames && filterData.userNames.length > 0) ||
      (filterData.deptNames && filterData.deptNames.length > 0) ||
      selectedPeriod.value !== DateType.ALL ||
      filterData.sendingStartDate ||
      filterData.sendingEndDate
    );
  }

  function checkTableRenderStatus() {
    const isFilterApplied = checkAnyFilterSelected();

    const hasHistoryList = historyList && historyList.length > 0;

    // 적용된 필터가 있으면서 리스트가 없다면 -> 검색 결과 없음 (1)
    if (!hasHistoryList && isFilterApplied) return 1;
    // 적용된 필터가 없으면서 리스트가 없다면 -> 표시할 항목 없음 (2)
    else if (!hasHistoryList && !isFilterApplied) return 2;
    // 그 외 (0)
    else return 0;
  }

  function renderTableSwitch() {
    switch (checkTableRenderStatus()) {
      case 1:
        return noEmailFiltered;
      case 2:
        return noEmailResult;
      default:
        return <></>;
    }
  }

  const filter = (
    <BoxNew
      classes="pd pt-12 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      {/* courseId */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        buttonClick={buttonClick}
        placeholder={t("program.configRound") + " · " + t("common.auth.all")}
        value={
          filterData.courseIds === undefined
            ? ""
            : t("program.configRound") + " · " + filterData.courseIds.length
        }
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew
            classes="dropdown-filter-title"
            justifyContent="space-between"
            alignItems="center"
          >
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("program.configRound")}
            </TypographyNew>
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_BRAND")}
              classes="cursor-pointer"
              onClick={() => {
                if (courseList)
                  if (
                    tempConfigIdsCheckbox.selected.length === courseList.length
                  ) {
                    tempConfigIdsCheckbox.handleAllClick([]);
                  } else {
                    tempConfigIdsCheckbox.handleAllClick(
                      courseList.map((item: number) => item)
                    );
                  }
              }}
              style={{ cursor: "pointer" }}
            >
              {t("common.input.selectAll")}
            </TypographyNew>
          </BoxNew>

          <BoxNew classes="gap-10 dropdown-filter-body" direction="column">
            <BoxNew
              classes="overflow-scroll"
              direction="column"
              alignItems="flex-start"
              height={240}
              width={284}
            >
              {courseList?.map((item: number) => (
                <BoxNew height={40} minHeight={40}>
                  <Checkbox
                    check={tempConfigIdsCheckbox.isSelected(item)}
                    onClick={() => {
                      tempConfigIdsCheckbox.handleClick(item);
                    }}
                    label={t("common.list.numOrder", {
                      round: item,
                    })}
                    key={item}
                  />
                </BoxNew>
              ))}
            </BoxNew>
            <BoxNew
              alignItems="center"
              classes="dropdown-filter-footer"
              justifyContent="flex-end"
              height={56}
            >
              <BoxNew>
                <ButtonNew
                  btnStyle={"fill"}
                  label={t("common.button.cancel")}
                  state={"default"}
                  size={"lg"}
                  type={"secondary"}
                  onClick={() => {
                    // setTempCourseName("");
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
                <ButtonNew
                  btnStyle={"fill"}
                  label={t("common.button.apply")}
                  size={"lg"}
                  classes={"mg ml-8"}
                  type="primary"
                  onClick={() => {
                    setFilterData((prev) => ({
                      ...prev,
                      courseIds: tempConfigIdsCheckbox.selected as number[],
                    }));
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
              </BoxNew>
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>

      {/* <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.session") + " · " + t("quiz.label.all")}
        value={
          filterData.courseId === undefined
            ? ""
            : filterData.courseId
            ? t("quiz.table.session") +
              " · " +
              t("common.list.numOrder", { round: filterData.courseId })
            : t("quiz.table.session") + " · " + t("quiz.label.all")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
        dropdownMaxHeight={336}
      >
        <DropdownItemNew
          checked={filterData.courseId === undefined}
          checkColor={filterData.courseId === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, courseId: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        {courseList?.length &&
          courseList.map((round: number) => (
            <DropdownItemNew
              key={`courseList-dropdown-${round}`}
              checked={round === filterData.courseId}
              checkColor={round === filterData.courseId}
              onClick={() => {
                setFilterData((prev) => ({ ...prev, courseId: round }));
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("report.reportConfigId", { round: round })}
              type={"check"}
            />
          ))}
      </DropdownNew> */}

      {/* userName */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.target") + " · " + t("quiz.label.all")}
        value={
          filterData.userNames === undefined ||
          filterData.userNames.length === 0
            ? ""
            : t("quiz.table.target") + " · " + filterData.userNames.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.table.target")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempUserName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("quiz.label.targetHolder")}
              onChange={(e) => setTempUserName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (tempUserNames && tempUserNames.includes(e.target.value)) {
                    return;
                  }
                  setTempUserNames((prev) => [...prev, tempUserName]);
                  setTempUserName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempUserNames &&
              tempUserNames.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempUserNames((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempUserName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filterData.userNames || [],
                    tempUserNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempUserName !== "") {
                    setTempUserNames((prev) => [...prev, tempUserName]);
                    setFilterData((prev) => ({
                      ...prev,
                      userNames: [...tempUserNames, tempUserName],
                    }));
                  } else {
                    setFilterData((prev) => ({
                      ...prev,
                      userNames: tempUserNames,
                    }));
                  }
                  setTempUserName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>

      {/* deptName */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.depts") + " · " + t("quiz.label.all")}
        value={
          filterData.deptNames === undefined ||
          filterData.deptNames.length === 0
            ? ""
            : t("quiz.table.depts") + " · " + filterData.deptNames.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.table.depts")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempDeptName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("quiz.label.targetHolder")}
              onChange={(e) => setTempDeptName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (tempDeptNames && tempDeptNames.includes(e.target.value)) {
                    return;
                  }
                  setTempDeptNames((prev) => [...prev, tempDeptName]);
                  setTempDeptName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempDeptNames &&
              tempDeptNames.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempDeptNames((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempDeptName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filterData.deptNames || [],
                    tempDeptNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempUserName !== "") {
                    setTempUserNames((prev) => [...prev, tempUserName]);
                    setFilterData((prev) => ({
                      ...prev,
                      deptNames: [...tempDeptNames, tempUserName],
                    }));
                  } else {
                    setFilterData((prev) => ({
                      ...prev,
                      deptNames: tempDeptNames,
                    }));
                  }
                  setTempUserName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>

      {/* courseName */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.courseTitle") + " · " + t("quiz.label.all")}
        value={
          filterData.courseNames === undefined ||
          filterData.courseNames.length === 0
            ? ""
            : t("quiz.table.courseTitle") +
              " · " +
              filterData.courseNames.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.table.courseTitle")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempCourseName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("quiz.label.targetHolder")}
              onChange={(e) => setTempCourseName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (
                    tempCourseNames &&
                    tempCourseNames.includes(e.target.value)
                  ) {
                    return;
                  }
                  setTempCourseNames((prev) => [...prev, tempCourseName]);
                  setTempCourseName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempCourseNames &&
              tempCourseNames.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempCourseNames((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempCourseName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filterData.courseNames || [],
                    tempCourseNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempCourseName !== "") {
                    setTempCourseNames((prev) => [...prev, tempCourseName]);
                    setFilterData((prev) => ({
                      ...prev,
                      courseNames: [...tempCourseNames, tempCourseName],
                    }));
                  } else {
                    setFilterData((prev) => ({
                      ...prev,
                      courseNames: tempCourseNames,
                    }));
                  }
                  setTempCourseName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>

      {/* 발송 일시 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.sendDate")}
        value={`${
          selectedPeriod.value === "PERIOD_3MONTH"
            ? t("report.stats.reportRecent3Month")
            : selectedPeriod.value === "PERIOD_6MONTH"
            ? t("report.stats.reportRecent6Month")
            : selectedPeriod.value === "PERIOD_1YEAR"
            ? t("report.stats.reportRecent1Year")
            : selectedPeriod.value === "ALL"
            ? t("common.auth.all")
            : `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
        }`}
        defaultValue={"PERIOD_3MONTH"}
        selectValue={selectedPeriod.value}
        showValue
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={selectedPeriod.value === "ALL"}
          checkColor={selectedPeriod.value === "ALL"}
          onClick={() => {
            changeDateFilter("ALL");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_3MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_3MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_6MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_6MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_1YEAR"}
          checkColor={selectedPeriod.value === "PERIOD_1YEAR"}
          onClick={() => {
            changeDateFilter("PERIOD_1YEAR");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItemNew
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_CUSTOM"}
          checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter("PERIOD_CUSTOM");
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </DropdownNew>

      {/* history result */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.delivery") + " · " + t("quiz.label.all")}
        value={
          filterData.historyResult === undefined
            ? ""
            : filterData.historyResult === 1
            ? t("quiz.table.delivery") + " · " + t("program.programSendWait")
            : filterData.historyResult === 2
            ? t("quiz.table.delivery") + " · " + t("program.programSendSuccess")
            : t("quiz.table.delivery") + " · " + t("program.programSendFail")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={filterData.historyResult === undefined}
          checkColor={filterData.historyResult === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, historyResult: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItemNew
          checked={filterData.historyResult === 2}
          checkColor={filterData.historyResult === 2}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, historyResult: 2 }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.programSendSuccess")}
          type={"check"}
        />
        <DropdownItemNew
          checked={filterData.historyResult === 3}
          checkColor={filterData.historyResult === 3}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, historyResult: 3 }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.programSendFail")}
          type={"check"}
        />
        <DropdownItemNew
          checked={filterData.historyResult === 1}
          checkColor={filterData.historyResult === 1}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, historyResult: 1 }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.programSendWait")}
          type={"check"}
        />
      </DropdownNew>

      {/* completion flag */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.learning") + " · " + t("quiz.label.all")}
        value={
          filterData.completionFlag === undefined
            ? ""
            : filterData.completionFlag
            ? t("quiz.table.learning") + " · " + t("quiz.label.notStarted")
            : t("quiz.table.learning") + " · " + t("quiz.label.completed")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={filterData.completionFlag === undefined}
          checkColor={filterData.completionFlag === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, completionFlag: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItemNew
          checked={filterData.completionFlag === false}
          checkColor={filterData.completionFlag === false}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, completionFlag: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("quiz.label.notStarted")}
          type={"check"}
        />
        <DropdownItemNew
          checked={filterData.completionFlag}
          checkColor={filterData.completionFlag}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, completionFlag: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("quiz.label.completed")}
          type={"check"}
        />
      </DropdownNew>

      <ButtonNew
        btnStyle={"icon"}
        state={
          FilterBtnActivityCheckUtils.checkIfResetFilterBtnActive(filterData)
            ? "default"
            : "disable"
        }
        size={"md"}
        onClick={resetFilter}
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
      />
    </BoxNew>
  );

  const noEmailFiltered = (
    <BoxNew
      width={"100%"}
      direction="column"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoSearchResultImage width={200} />
      <TypographyNew
        type="h3"
        classes="mg mt-36"
        exactColor={getColor("COLOR_TEXT_PRIMARY")}
      >
        {t("quiz.label.noSearch")}
      </TypographyNew>
      <TypographyNew
        type="body2_rg_long"
        classes="mg mt-8"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
      >
        {t("quiz.msg.noFilterQuizSendHistory")}
      </TypographyNew>
      <ButtonNew
        btnStyle={"fill"}
        state={"default"}
        size={"lg"}
        classes="mg mt-20"
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
          />
        }
        onClick={resetFilter}
        label={t("common.button.resetFilter")}
      />
    </BoxNew>
  );

  const noEmailResult = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoDashboardImage />
      <TypographyNew
        classes="mg mt-24 mb-8 "
        type="h3"
        exactColor={getColor("COLOR_TEXT_PRIMARY")}
      >
        {t("quiz.msg.noItems")}
      </TypographyNew>
      <TypographyNew
        type="body2_rg_long"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mt-8"
      >
        {t("program.msg.noSendMailInfoMsg")}
      </TypographyNew>
    </BoxNew>
  );

  return (
    <BoxNew classes="main" ref={tableContainerRef}>
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizResult")]} />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.trainingSendAndResult")}
          </TypographyNew>
        </BoxNew>
        <BoxNew>
          {historyList.length > 0 && (
            <ButtonNew
              btnStyle="fill"
              size="lg"
              type="secondary"
              leadingIcon={<IconsNew variant="line" label="download" />}
              onClick={() => exportQuizSendHistory(filterData)}
              label={t("common.button.exportToExcel")}
            />
          )}
        </BoxNew>
      </BoxNew>
      <BoxNew classes="mg mt-32" justifyContent="space-between">
        <BoxNew alignItems="center">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("program.sendMail")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            classes="mg ml-8"
            exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
          >
            {historyListTotal?.toLocaleString()}
          </TypographyNew>
        </BoxNew>

        {/* 리소스  */}
        <BoxNew>
          <ButtonNew
            leadingIcon={
              <IconsNew
                variant="line"
                label="send"
                stroke="#252d38"
                size={20}
              />
            }
            btnStyle={"link"}
            type={"primary"}
            onClick={() => setIsResendModalAllOpen(true)}
            size={"lg"}
            state={checkAllResend() ? "default" : "disable"}
            label={"일괄 발송"}
          />

          <ButtonNew
            leadingIcon={
              <IconsNew
                variant="line"
                label="filter"
                stroke="#252d38"
                size={20}
              />
            }
            btnStyle={"link"}
            type={"primary"}
            onClick={() => setIsFilterOpen((prev) => !prev)}
            size={"lg"}
            label={t("common.list.filter")}
          />
        </BoxNew>
      </BoxNew>
      {isFilterOpen && filter}
      <BoxNew direction="column" classes="pd pt-12 flex-1">
        <TableNew
          border="none"
          clickIds={checkboxGroup.selected.map((sendHistoryId) =>
            String(sendHistoryId)
          )}
          columnHeight="md"
          totalCount={historyListTotal}
          data={transformEmailHistory(historyList)}
          endReached={() => {}}
          checkBoxClick={() =>
            checkboxGroup.handleAllClick(
              checkboxGroup.selected.length === historyList.length
                ? []
                : historyList.map((q) => q.sendHistoryId)
            )
          }
          header={[
            {
              headerText: t("quiz.table.session"),
              width: "76px",
              ...getOrderRenderProps(ColumnsTypeToOrder.courseId),
            },
            { headerText: t("quiz.table.target"), width: "160px" },
            {
              headerText: t("quiz.table.depts"),
              width: "120px",
              ...getOrderRenderProps(ColumnsTypeToOrder.deptName),
            },
            {
              headerText: t("quiz.table.courseTitle"),
              width: "calc(100% - 900px)",
            },
            { headerText: t("quiz.table.sent"), width: "80px" },
            {
              headerText: t("quiz.table.sentDate"),
              width: "265px",
              ...getOrderRenderProps(ColumnsTypeToOrder.sendingDate),
            },
            { headerText: t("quiz.table.delivery"), width: "80px" },
            { headerText: t("quiz.table.learning"), width: "86px" },
          ]}
          width={"100%"}
          height={
            checkTableRenderStatus() === 0
              ? `${
                  tableContainerPosition.height -
                  (245 + (isFilterOpen ? 56 : 0))
                }px`
              : "0px"
          }
        />
        {renderTableSwitch()}
      </BoxNew>

      {openDateModal && dateModal()}

      {isResendModalAllOpen && (
        <ModalNew
          open={isResendModalAllOpen}
          title={"학습 메일 일괄 재전송"}
          onClose={() => setIsResendModalAllOpen(false)}
          width={540}
          body={
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              이미 퀴즈가 발송된 학습 여부가 미진행인 건들에 대해서만
              재전송됩니다. 정말 재전송하시겠습니까?
            </TypographyNew>
          }
          footer={
            <BoxNew justifyContent="flex-end" classes="gap-8">
              <ButtonNew
                btnStyle="fill"
                type="secondary"
                label={t("common.button.cancel")}
                onClick={() => setIsResendModalAllOpen(false)}
                size="md"
              />
              <ButtonNew
                btnStyle="fill"
                type="primary"
                label={t("quiz.label.resend")}
                onClick={onResendMailAll}
                size="md"
              />
            </BoxNew>
          }
        />
      )}

      {isResendModalOpen !== -1 && (
        <ModalNew
          open={isResendModalOpen !== -1}
          title={t("quiz.label.resendMail")}
          onClose={() => setIsResendModalOpen(-1)}
          width={540}
          body={
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.msg.resendConfirm")}
            </TypographyNew>
          }
          footer={
            <BoxNew justifyContent="flex-end" classes="gap-8">
              <ButtonNew
                btnStyle="fill"
                type="secondary"
                label={t("common.button.cancel")}
                onClick={() => setIsResendModalOpen(-1)}
                size="md"
              />
              <ButtonNew
                btnStyle="fill"
                type="primary"
                label={t("quiz.label.resend")}
                onClick={onResendMail}
                size="md"
              />
            </BoxNew>
          }
        />
      )}
    </BoxNew>
  );
};

export default QuizResult;
