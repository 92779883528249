import "./index.scss";
import { ColumnDatas, Icons } from "fds";
import { useState } from "react";

interface HeaderType {
  headerText: string;
  width: string;
  order?: string;
  orderAction?: () => void;
  isLeftCell?: boolean; // 왼쪽 정렬해야 하는 열
}
interface TableProps {
  tableData: ColumnDatas[];
  headerData: HeaderType[];
  onDataChange: (newData: ColumnDatas[]) => void;
  classes?: string;
  width?: string | number;
}

const EditableTable: React.FC<TableProps> = ({
  tableData,
  onDataChange,
  headerData,
  classes,
  width = "80%",
}) => {
  const [editingCell, setEditingCell] = useState<{
    rowIndex: number;
    cellIndex: number;
  } | null>(null);
  const [inputValue, setInputValue] = useState("");

  // 더블 클릭 시 입력 모드로 변경
  const handleDoubleClick = (
    rowIndex: number,
    cellIndex: number,
    value: string
  ) => {
    setEditingCell({ rowIndex, cellIndex });
    setInputValue(value ?? "");
  };

  // 입력값 변경
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  // 엔터 또는 포커스 아웃 시 저장
  const handleBlurOrEnter = (rowIndex: number, cellIndex: number) => {
    const newData = [...tableData];
    newData[rowIndex].data[cellIndex].columnText = inputValue;
    onDataChange(newData); // 부모 컴포넌트에 변경된 데이터 전달
    setEditingCell(null);
  };

  // 행 추가 (headerData 길이만큼 빈 columnText 추가)
  const addRow = () => {
    const newRow: ColumnDatas = {
      id: (tableData.length + 1).toString(),
      data: new Array(headerData.length)
        .fill(null)
        .map(() => ({ columnText: "" })),
    };
    onDataChange([...tableData, newRow]);
  };

  // 행 삭제
  const deleteRow = (rowIndex: number) => {
    const newData = tableData.filter((_, index) => index !== rowIndex);
    onDataChange(newData);
  };
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  return (
    <div className="report__table">
      <table>
        <colgroup>
          {headerData.map((_, index) => (
            <col key={index} style={{ width: _.width }} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {headerData.map((header, index) => (
              <th key={index} style={{ width: header.width }}>
                {header.headerText}
              </th>
            ))}
            <th></th> {/* 삭제 버튼 컬럼 */}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={row.id}>
              {row.data.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  onMouseEnter={() => setHoveredRow(rowIndex)}
                  onMouseLeave={() => setHoveredRow(null)}
                  onDoubleClick={() =>
                    handleDoubleClick(
                      rowIndex,
                      cellIndex,
                      String(cell.columnText)
                    )
                  }
                  className={`${
                    headerData[cellIndex].isLeftCell && `report__left`
                  }`}
                >
                  {editingCell?.rowIndex === rowIndex &&
                  editingCell?.cellIndex === cellIndex ? (
                    <span className="input">
                      <textarea
                        value={inputValue}
                        onChange={handleChange}
                        autoFocus
                        onBlur={() => handleBlurOrEnter(rowIndex, cellIndex)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleBlurOrEnter(rowIndex, cellIndex);
                          }
                        }}
                        className={`inputArea mg mt-4`}
                        style={{ width: width, height: "48px" }}
                      />
                      {/* <InputField placeholder="hi" /> */}
                    </span>
                  ) : (
                    cell.columnText || "입력"
                  )}
                </td>
              ))}
              <td>
                <button
                  onClick={() => deleteRow(rowIndex)}
                  className={`report__delete__btn ${
                    hoveredRow === rowIndex ? "visible" : ""
                  }`}
                  onMouseEnter={() => setHoveredRow(rowIndex)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <Icons variant="line" label="delete" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={headerData.length + 1}>
              <button onClick={addRow} className="report__add__btn">
                <Icons variant="line" label="plus" />
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default EditableTable;
