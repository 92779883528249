import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Icons as IconsNew,
  Radio as RadioNew,
  InputArea,
  Button as ButtonNew,
  Modal as ModalNew,
  useToast,
  InputField,
} from "fds";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuizContentDTO } from "../../../../types/Quiz";
import useQuizStore from "../../../../redux/dispatcher/useQuizStore";
import { nanoid } from "@reduxjs/toolkit";
import SpinnerModal from "../../../../component/SpinnerModal";
import { useAbortController } from "../../../../hooks/useAbortController";
import QuizzesPreview from "./QuizzesPreview";
import { AI_GENERATE_ERROR_CODE } from "../../../../types/ErrorCode";

interface AIModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSetQuiz: (quiz: QuizContentDTO) => void;
  handleSetQuizzes: (quizzes: QuizContentDTO[]) => void;
}

const AIPromptModal: React.FC<AIModalProps> = ({
  isOpen,
  handleSetQuiz,
  handleSetQuizzes,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { controller, abortController, resetController } = useAbortController();

  const { createAiQuizContent } = useQuizStore();

  const [quizType, setQuizType] = useState<string>("1");
  const [isLoadingCreateAiQuiz, setIsLoadingCreateAiQuiz] = useState(false);
  const [currentPage, changeCurrentPage] = useState(1);

  const [newQuizInfo, changeNewQuizInfo] = useState({
    referText: "", // referText 참고 텍스트
    prompt: "", // 프롬프트
    quantity: 0, // 생성할 퀴즈 수
  });
  const [errorCode, setErrorCode] = useState<AI_GENERATE_ERROR_CODE | "">("");

  const [aiQuizExamples, setAIQuizExamples] = useState<QuizContentDTO[]>([]);

  const onCreateAiQuiz = async () => {
    setIsLoadingCreateAiQuiz(true);
    setErrorCode("");

    const resp: any = await createAiQuizContent(
      {
        reference: newQuizInfo.referText,
        type: quizType === "1" ? 1 : 0,
        request: newQuizInfo.prompt,
        choices: quizType !== "1" ? 5 : null,
        quantity: newQuizInfo.quantity,
      },
      { signal: controller?.signal }
    );

    if (resp.payload && resp.payload.code === "SUCCESS") {
      const DATA_FETCHED_FORMATTED = (resp.payload.data || []).map(
        (data: QuizContentDTO) => ({
          ...data,
          quizName: "",
          categorys: [],
          quizType: quizType === "1" ? 1 : 0,
        })
      );
      setAIQuizExamples(DATA_FETCHED_FORMATTED);
    } else if (controller?.signal.aborted) {
      resetController();
    } else if (resp.payload.code === AI_GENERATE_ERROR_CODE.REQUEST_REFUSED) {
      setErrorCode(AI_GENERATE_ERROR_CODE.REQUEST_REFUSED);
      toast.toastMsg(
        nanoid(),
        t("quiz.quizAiModal.msg.promptErrKeywordModal"),
        "error"
      );
    } else {
      toast.toastMsg(nanoid(), t("quiz.msg.aiCreateFail"), "error");
    }
    setIsLoadingCreateAiQuiz(false);
  };

  const onSubmit = () => {
    const quiz = aiQuizExamples[currentPage - 1];

    handleSetQuiz({
      ...quiz,
      quizType: quiz.quizChoices && quiz.quizChoices.length > 0 ? 2 : 1,
    });

    const quizContentsWithQuizType = [...aiQuizExamples].map((q) => ({
      ...q,
      quizType: q.quizChoices && q.quizChoices.length > 0 ? 2 : 1,
    }));

    handleSetQuizzes(quizContentsWithQuizType);

    setAIQuizExamples([]);
    onClose();
  };

  const checkIfApplyActive = () => {
    if (aiQuizExamples.length === 0) {
      return false;
    }
    return aiQuizExamples.every((q) => (q.quizName || "").trim() !== "");
  };

  const getCustomErrorMsg = () => {
    if (errorCode === "REQUEST_REFUSED") {
      return t("quiz.quizAiModal.msg.promptErrorKeywordInputLabel");
    }
    return "";
  };

  const footer = (
    <BoxNew classes="gap-8" justifyContent="flex-end" alignItems="center">
      <ButtonNew
        btnStyle={"fill"}
        type={"secondary"}
        onClick={onClose}
        size={"md"}
        label={t("common.button.cancel")}
      />
      <ButtonNew
        btnStyle={"fill"}
        type={"primary"}
        state={checkIfApplyActive() ? "default" : "disable"}
        label={t("common.button.apply")}
        onClick={onSubmit}
      />
    </BoxNew>
  );

  const scrollModalToBottom = () => {
    const modalBody = document.querySelector(".aiPrompt .fds-modal-body");
    if (modalBody) {
      modalBody.scrollTo({
        top: modalBody.clientHeight + 20,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const isQuizSuccessfullyCreated =
      !isLoadingCreateAiQuiz && errorCode === "";
    if (isQuizSuccessfullyCreated) scrollModalToBottom();
  }, [isLoadingCreateAiQuiz, errorCode]);

  return (
    <ModalNew
      open={isOpen}
      onClose={onClose}
      title={t("quiz.label.aiCreate")}
      footer={footer}
      width={540}
      subTitle={t("quiz.quizAiModal.subtitle")}
      classes="aiPrompt"
      bodyHeight={588}
      height={768}
      enableDynamicHeaderDivider={true}
      body={
        <BoxNew direction="column">
          {/* 참고 텍스트  */}
          <BoxNew direction="column">
            <BoxNew classes="mg mb-6">
              <TypographyNew
                type={"subtitle2"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.label.refText")}
              </TypographyNew>
            </BoxNew>
            <InputArea
              size={"lg"}
              value={newQuizInfo.referText}
              placeholder={t("quiz.msg.aiRefTextPlaceholder")}
              onChange={(e) =>
                changeNewQuizInfo({ ...newQuizInfo, referText: e.target.value })
              }
              height={94}
              maxLength={100000}
              isError={
                errorCode === "REQUEST_REFUSED" ||
                newQuizInfo.referText.length > 100000
              }
              helpMessage={
                (newQuizInfo.referText.length > 100000
                  ? t("common.msg.maxLengthExceed")
                  : "") || getCustomErrorMsg()
              }
            />
          </BoxNew>
          {/* 유형 */}
          <BoxNew classes="mg mt-16" direction="column">
            <BoxNew classes="mg mb-6">
              <TypographyNew
                type={"subtitle2"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.table.formal")}
              </TypographyNew>
            </BoxNew>
            <BoxNew classes="gap-24">
              <RadioNew
                label={t("quiz.label.formatTF")}
                id="1"
                check={quizType === "1"}
                onClick={(id: string) => {
                  setQuizType(id);
                }}
              />
              <RadioNew
                label={t("quiz.label.formatChoice")}
                id="2"
                check={quizType === "2"}
                onClick={(id: string) => {
                  setQuizType(id);
                }}
              />
            </BoxNew>
          </BoxNew>
          {/* 프롬프트 */}
          <BoxNew classes="prompt mg mt-10" direction="column">
            <TypographyNew type={"subtitle2"}>
              {t("quiz.label.prompt")}
            </TypographyNew>
            <InputArea
              classes="mg mt-6"
              width={444}
              size={"md"}
              height={160}
              value={newQuizInfo.prompt}
              placeholder={t("quiz.msg.aiPromptPlaceholder")}
              onChange={(e) =>
                changeNewQuizInfo({ ...newQuizInfo, prompt: e.target.value })
              }
              maxLength={1000}
              showCount={true}
              isError={
                errorCode === "REQUEST_REFUSED" ||
                newQuizInfo.prompt.length > 1000
              }
              helpMessage={
                newQuizInfo.prompt.length > 1000
                  ? t("common.msg.maxLengthExceed")
                  : getCustomErrorMsg()
              }
            />
          </BoxNew>
          {/* 생성할 퀴즈 수 */}
          <BoxNew direction="column" classes="mg mt-16">
            <TypographyNew
              type={"subtitle2"}
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
              classes="mg mb-4"
            >
              {t("quiz.label.aiPromptQuizCount")}
            </TypographyNew>
            <TypographyNew
              type="body4_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
              classes="mg mb-8"
            >
              {t("quiz.quizAiModal.msg.quizCountMax")}
            </TypographyNew>
            <InputField
              placeholder={t("quiz.msg.aiPromptQuizCountPlaceholder")}
              type="positive-integer"
              width={200}
              size={"md"}
              value={
                newQuizInfo.quantity > 0 ? newQuizInfo.quantity.toString() : ""
              }
              maxLength={1}
              isError={newQuizInfo.quantity > 5}
              onChange={(e) => {
                // if (
                //   (e.target.value.length >= 1 && e.target.value <= 5) ||
                //   e.target.value === ""
                // ) {
                changeNewQuizInfo({
                  ...newQuizInfo,
                  quantity: parseInt(e.target.value),
                });
                // }
              }}
            />
          </BoxNew>
          {/* 생성 버튼 */}
          <ButtonNew
            classes="mg mt-16"
            btnStyle={"fill"}
            leadingIcon={
              <IconsNew
                variant="line"
                label="plusCircle"
                stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
              />
            }
            state={
              !newQuizInfo.prompt ||
              !newQuizInfo.referText ||
              !(newQuizInfo.quantity && newQuizInfo.quantity <= 5)
                ? "disable"
                : "default"
            }
            onClick={onCreateAiQuiz}
            label={t("quiz.label.aiGenerate")}
            style={{ justifyContent: "center" }}
          />
          {/* 미리보기 */}
          {aiQuizExamples.length > 0 && (
            <QuizzesPreview
              currentPage={currentPage}
              changeCurrentPage={changeCurrentPage}
              aiQuizExamples={aiQuizExamples}
              setAIQuizExamples={setAIQuizExamples}
              isOX={quizType === "1"}
            />
          )}
          {/* 로딩 모달 */}
          <SpinnerModal
            isLoading={isLoadingCreateAiQuiz}
            onClose={() => {
              abortController();
              setIsLoadingCreateAiQuiz(false);
            }}
          />
        </BoxNew>
      }
    />
  );
};

export default AIPromptModal;

// TODO: 추후 삭제
// export const MOCK_RESPONSE: QuizContentDTO[] = [
//   {
//     quizName: "",
//     categorys: [],
//     quizType: 1,
//     quizBody:
//       "111The company has a strict policy against sharing passwords with anyone.",
//     quizComments:
//       "Sharing passwords compromises security and is not permitted.",
//     quizAnswer: 0,
//   },
//   {
//     quizName: "",
//     quizType: 1,
//     categorys: [],
//     quizBody:
//       "222The company has a strict policy against sharing passwords with anyone.",
//     quizComments:
//       "Sharing passwords compromises security and is not permitted.",
//     quizAnswer: 0,
//   },
//   {
//     quizName: "",
//     quizType: 1,
//     categorys: [],
//     quizBody:
//       "333The company has a strict policy against sharing passwords with anyone.",
//     quizComments:
//       "Sharing passwords compromises security and is not permitted.",
//     quizAnswer: 0,
//   },
//   {
//     quizName: "",
//     quizType: 1,
//     categorys: [],
//     quizBody:
//       "444The company has a strict policy against sharing passwords with anyone.",
//     quizComments:
//       "Sharing passwords compromises security and is not permitted.",
//     quizAnswer: 0,
//   },
// ];

// export const MOCK_RESPONSE_CHOICE: QuizContentDTO[] = [
//   {
//     quizBody: "질문11",
//     quizComments: "해설11",
//     quizAnswer: 1,
//     quizChoices: [
//       "오지선다1",
//       "오지선다2",
//       "오지선다3",
//       "오지선다4",
//       "오지선다5",
//     ],
//   },
//   {
//     quizBody: "질문22",
//     quizComments: "해설22",
//     quizAnswer: 2,
//     quizChoices: [
//       "오지선다1",
//       "오지선다2",
//       "오지선다3",
//       "오지선다4",
//       "오지선다5",
//     ],
//   },
// ];
