import { useEffect, useRef, useState } from "react";

// ref를 등록한 요소의 위치 정보 (Rect)을 받아오는 훅
export function useElementPosition<T extends HTMLElement>() {
  const targetRef = useRef<T>(null);
  const [position, setPosition] = useState<DOMRect>(new DOMRect());

  useEffect(() => {
    const updatePosition = () => {
      if (targetRef.current) {
        const targetRect = targetRef.current.getBoundingClientRect();
        setPosition(targetRect);
      }
    };

    updatePosition();
    window.addEventListener("resize", updatePosition);
    return () => window.removeEventListener("resize", updatePosition);
  }, []);

  return { targetRef, position };
}
