import "./index.scss";
import { Chip } from "fds";

interface CategoryTagProps {
  onClick?: () => void;
  key: string | number;
  text?: string;
}

const CategoryTag: React.FC<CategoryTagProps> = ({ key, onClick, text }) => {
  return (
    <Chip
      key={key}
      type={"fill"}
      size={"sm"}
      showClose={onClick ? true : false}
      text={text + ""}
      onClick={onClick}
      classes={"mg mb-4 mr-4"}
    />
  );
};

export default CategoryTag;
