import "./index.scss";
import { Box, Button, getColor, Modal, Typography } from "fds";
import React from "react";
import { useTranslation } from "react-i18next";

interface CommonModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  body?: string | JSX.Element;
  footer?: JSX.Element;
  subMessage?: string;
  afterSubmitButton: () => void;
  width?: number;
  isDivider?: boolean;
  isExit?: boolean;
  disabledConfirm?: boolean;
  type?: "delete";
  zIndex?: number;
}

const CommonModal: React.FC<CommonModalProps> = ({
  open,
  onClose,
  title,
  body,
  footer,
  subMessage,
  disabledConfirm = false,
  afterSubmitButton,
  width = 480,
  isDivider = false,
  isExit = true,
  type,
  zIndex,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      zIndex={zIndex}
      open={open}
      onClose={onClose}
      title={title}
      width={width}
      isExit={isExit}
      body={
        <Box
          alignItems="center"
          justifyContent="center"
          width={"100%"}
          classes="common__box"
        >
          {subMessage ? (
            <Box
              alignItems="flex-start"
              justifyContent="center"
              width={"100%"}
              direction={"column"}
            >
              <Typography
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
                lineHeight="normal"
              >
                {subMessage ?? ""}
              </Typography>
            </Box>
          ) : (
            body
          )}
        </Box>
      }
      footer={
        footer ? (
          footer
        ) : (
          <Box justifyContent="flex-end">
            <Button
              btnStyle="fill"
              type="secondary"
              label={t("common.button.cancel")}
              onClick={() => onClose()}
            />
            {type === "delete" ? (
              <Button
                btnStyle="fill"
                type="error"
                label={t("common.button.delete")}
                classes={"mg ml-8"}
                state={disabledConfirm ? "disable" : "default"}
                onClick={() => (afterSubmitButton(), onClose())}
              />
            ) : (
              <Button
                btnStyle="fill"
                type="primary"
                label={t("common.button.check")}
                classes={"mg ml-8"}
                state={disabledConfirm ? "disable" : "default"}
                onClick={() => (afterSubmitButton(), onClose())}
              />
            )}
          </Box>
        )
      }
      classes="commonModal"
    ></Modal>
  );
};

export default CommonModal;
