import { Box, Button, getColor, Modal, Spinner, Typography } from "fds";
import "./index.scss";
import { useEffect } from "react";
import useProgressStore from "../../redux/dispatcher/useProgressStore";

interface SpinnerModalProps {
  isLoading: boolean;
  onClose: () => void;
}

const SpinnerModal = ({ isLoading, onClose }: SpinnerModalProps) => {
  const { boundStopProgress } = useProgressStore();

  boundStopProgress();

  return (
    isLoading && (
      <Modal
        zIndex={1000}
        open={isLoading}
        onClose={onClose}
        width={280}
        isHeader={false}
        isFooter={false}
        classes="spinnerModal"
        enableDynamicHeaderDivider={true}
        body={
          <Box
            direction="column"
            alignItems="center"
            classes="spinnerModal-body"
          >
            <Typography
              type="subtitle1"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              생성 중...
            </Typography>
            <Spinner borderWidth={4} />
            <Button
              btnStyle="fill"
              type="secondary"
              label="취소"
              onClick={onClose}
              size="md"
            />
          </Box>
        }
      />
    )
  );
};

export default SpinnerModal;
