import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkActivateProgram,
  trunkAddNewProgram,
  trunkDeleteProgram,
  trunkExportResultFile,
  trunkExportResultReport,
  trunkExportResultReportToPdf,
  trunkGetProgramDetail,
  trunkGetProgramList,
  trunkUpdateProgram,
} from "../store/program";

// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useProgramStore = () => {
  const programState = useRootState((state) => state.programStore);

  const dispatch = useAppDispatch();

  const boundGetProgramList = useMemo(
    () => bindActionCreators(trunkGetProgramList, dispatch),
    [dispatch]
  );
  const boundGetProgramDetail = useMemo(
    () => bindActionCreators(trunkGetProgramDetail, dispatch),
    [dispatch]
  );
  const boudnAddNewProgram = useMemo(
    () => bindActionCreators(trunkAddNewProgram, dispatch),
    [dispatch]
  );
  const boundUpdateProgram = useMemo(
    () => bindActionCreators(trunkUpdateProgram, dispatch),
    [dispatch]
  );
  const boundActivateProgram = useMemo(
    () => bindActionCreators(trunkActivateProgram, dispatch),
    [dispatch]
  );
  const boundDeleteProgram = useMemo(
    () => bindActionCreators(trunkDeleteProgram, dispatch),
    [dispatch]
  );
  const boundExportResultFile = useMemo(
    () => bindActionCreators(trunkExportResultFile, dispatch),
    [dispatch]
  );
  const boundExportResultReport = useMemo(
    () => bindActionCreators(trunkExportResultReport, dispatch),
    [dispatch]
  );

  const boundExportResultReportToPdf = useMemo(
    () => bindActionCreators(trunkExportResultReportToPdf, dispatch),
    [dispatch]
  );

  return {
    programState,
    getProgramList: boundGetProgramList,
    getProgramDetail: boundGetProgramDetail,
    addNewProgram: boudnAddNewProgram,
    updateProgram: boundUpdateProgram,
    activateProgram: boundActivateProgram,
    deleteProgram: boundDeleteProgram,
    exportResultFile: boundExportResultFile,
    exportResultReport: boundExportResultReport,
    exportResultToPdf: boundExportResultReportToPdf,
  };
};

export default useProgramStore;
