// 파일 없음
const NoFile = ({
  classes,
  width = 105,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      className={classes}
      width={width}
      height="74"
      viewBox="0 0 105 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.5491 69.5284H33.7804C30.7973 69.5284 28.3662 67.109 28.3662 64.1142V9.03237C28.3662 6.03752 30.7856 3.61816 33.7804 3.61816H60.4639L77.9515 21.1057V64.1142C77.9515 67.0973 75.5322 69.5284 72.5491 69.5284Z"
        fill="#DDE1E7"
      />
      <path
        d="M69.8839 36.68H36.4707C35.9775 36.68 35.5781 36.2807 35.5781 35.7874V34.7305C35.5781 34.2372 35.9775 33.8379 36.4707 33.8379H69.8839C70.3772 33.8379 70.7765 34.2372 70.7765 34.7305V35.7874C70.7765 36.2807 70.3772 36.68 69.8839 36.68Z"
        fill="#A5AEB8"
      />
      <path
        d="M69.8839 46.016H36.4707C35.9775 46.016 35.5781 45.6167 35.5781 45.1234V44.0664C35.5781 43.5732 35.9775 43.1738 36.4707 43.1738H69.8839C70.3772 43.1738 70.7765 43.5732 70.7765 44.0664V45.1234C70.7765 45.6167 70.3772 46.016 69.8839 46.016Z"
        fill="#A5AEB8"
      />
      <path
        d="M52.3013 55.3548H36.4462C35.953 55.3548 35.5537 54.9555 35.5537 54.4623V53.4052C35.5537 52.9119 35.953 52.5127 36.4462 52.5127H52.3013C52.7946 52.5127 53.1939 52.9119 53.1939 53.4052V54.4623C53.1939 54.9555 52.7946 55.3548 52.3013 55.3548Z"
        fill="#A5AEB8"
      />
      <path
        d="M60.4873 3.61914V17.2192C60.4873 19.3685 62.2255 21.1067 64.3747 21.1067H77.9749L60.4873 3.61914Z"
        fill="#A5AEB8"
      />
      <path
        d="M87.0884 73.4153L86.9709 73.5328C86.3485 74.1552 85.3267 74.1552 84.7042 73.5328L77.5284 66.3569C76.9059 65.7344 76.9059 64.7127 77.5284 64.0902L77.6458 63.9727C78.2682 63.3502 79.29 63.3502 79.9125 63.9727L87.0884 71.1487C87.7109 71.7594 87.7109 72.7811 87.0884 73.4153Z"
        fill="#6F7985"
      />
      <path
        d="M70.166 43.0684C62.7905 43.0684 56.8125 49.0463 56.8125 56.4219C56.8125 63.7974 62.7905 69.7754 70.166 69.7754C77.5416 69.7754 83.5195 63.7974 83.5195 56.4219C83.5313 49.0463 77.5533 43.0684 70.166 43.0684ZM70.166 66.1111C64.8105 66.1111 60.4768 61.7774 60.4768 56.4219C60.4768 51.0664 64.8105 46.7326 70.166 46.7326C75.5215 46.7326 79.8552 51.0664 79.8552 56.4219C79.8552 61.7774 75.5215 66.1111 70.166 66.1111Z"
        fill="#6F7985"
      />
      <path
        opacity="0.5"
        d="M70.1638 46.7314C64.8083 46.7314 60.4746 51.0652 60.4746 56.4207C60.4746 61.7762 64.8083 66.1099 70.1638 66.1099C75.5193 66.1099 79.8531 61.7762 79.8531 56.4207C79.8531 51.0652 75.5193 46.7314 70.1638 46.7314Z"
        fill="white"
      />
      <path
        d="M97.168 41.2823C96.4046 41.2823 95.7939 40.6598 95.7939 39.9081V36.8663C95.7939 36.103 96.4164 35.4922 97.168 35.4922C97.9314 35.4922 98.5422 36.1147 98.5422 36.8663V39.9081C98.5422 40.6598 97.9314 41.2823 97.168 41.2823Z"
        fill="#FFCC75"
      />
      <path
        d="M97.168 50.6436C96.4046 50.6436 95.7939 50.0211 95.7939 49.2695V46.2276C95.7939 45.4642 96.4164 44.8535 97.168 44.8535C97.9314 44.8535 98.5422 45.476 98.5422 46.2276V49.2695C98.5422 50.0329 97.9314 50.6436 97.168 50.6436Z"
        fill="#FFCC75"
      />
      <path
        d="M98.9551 43.0665C98.9551 42.3031 99.5775 41.6924 100.329 41.6924H103.371C104.134 41.6924 104.745 42.3148 104.745 43.0665C104.745 43.8299 104.123 44.4406 103.371 44.4406H100.329C99.5658 44.4406 98.9551 43.8181 98.9551 43.0665Z"
        fill="#FFCC75"
      />
      <path
        d="M89.582 43.0665C89.582 42.3031 90.2045 41.6924 90.9561 41.6924H93.9979C94.7613 41.6924 95.3721 42.3148 95.3721 43.0665C95.3721 43.8299 94.7496 44.4406 93.9979 44.4406H90.9561C90.1927 44.4406 89.582 43.8181 89.582 43.0665Z"
        fill="#FFCC75"
      />
      <path
        d="M10.7463 21.4924C16.6812 21.4924 21.4924 16.6812 21.4924 10.7462C21.4924 4.81121 16.6812 0 10.7463 0C4.81128 0 0 4.81121 0 10.7462C0 16.6812 4.81128 21.4924 10.7463 21.4924Z"
        fill="#ADCDFB"
      />
      <path
        d="M7.37467 7.01164C7.57433 6.60058 7.84447 6.27176 8.19681 6.01338C8.54914 5.75501 8.94849 5.56712 9.40652 5.44967C9.85282 5.33223 10.3226 5.26172 10.8041 5.26172C11.4501 5.26172 12.049 5.36744 12.601 5.57884C13.153 5.79024 13.5875 6.11904 13.9164 6.55359C14.2452 6.98813 14.4097 7.5284 14.4097 8.18609C14.4097 8.64413 14.3275 9.03175 14.1748 9.3371C14.0221 9.64246 13.8224 9.90081 13.5758 10.1122C13.3292 10.3236 13.0825 10.5115 12.8359 10.6877C12.5305 10.8873 12.2957 11.0635 12.096 11.2162C11.8963 11.3689 11.7672 11.5215 11.685 11.6977C11.6028 11.8739 11.5557 12.097 11.5557 12.3789V12.7547H9.37126L9.3595 12.4024C9.32427 12.0031 9.34776 11.6507 9.44172 11.3336C9.53568 11.0165 9.68837 10.7347 9.89977 10.488C10.1112 10.2414 10.3695 10.0065 10.6866 9.8186C11.0507 9.58371 11.3443 9.36055 11.5675 9.14915C11.7906 8.94949 11.8964 8.66761 11.8964 8.32702C11.8964 8.0569 11.8376 7.84551 11.7202 7.68109C11.6027 7.51666 11.45 7.39922 11.2621 7.31701C11.0742 7.2348 10.8746 7.19959 10.6632 7.19959C10.4518 7.19959 10.2756 7.23477 10.1229 7.2935C9.97024 7.36396 9.84103 7.44624 9.73533 7.57543C9.62963 7.69287 9.54747 7.83379 9.48874 7.99821C9.43002 8.15089 9.40652 8.32706 9.40652 8.51497H7.13984C7.09286 7.916 7.17502 7.4227 7.37467 7.01164ZM9.40652 14.0232C9.67665 13.7883 10.0525 13.6708 10.5223 13.6708C11.0038 13.6708 11.3796 13.7883 11.6497 14.0232C11.9316 14.258 12.0608 14.5751 12.0608 14.9627C12.0608 15.3385 11.9198 15.6439 11.6497 15.8905C11.3678 16.1254 10.992 16.2428 10.5223 16.2428C10.0525 16.2428 9.68839 16.1254 9.40652 15.8905C9.1364 15.6556 8.99542 15.3503 8.99542 14.9627C8.99542 14.5751 9.12466 14.258 9.40652 14.0232Z"
        fill="white"
      />
    </svg>
  );
};

export default NoFile;
