import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Button as ButtonNew,
  Icons as IconsNew,
  Tag as TagNew,
  Table as TableNew,
  Dropdown,
  DropdownItem,
  InputField,
  Chip,
  CalendarRange,
  Modal,
  DropdownBody,
  useToast,
} from "fds";

import TitlePath from "../../../../component/TitlePath";
import "./index.scss";
import { useEffect, useLayoutEffect, useState } from "react";
import useReportStore from "../../../../redux/dispatcher/useReportStore";
import { ReportMail, ReportMailSearchType } from "../../../../types/Report";
import utilsCommon from "../../../../shared/utils/common";
import ReportViewDetail from "../Detail";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";
import { useElementPosition } from "../../../../hooks/useElementPosition";
import NoSearchResultImage from "../../../../shared/image/NoSearchResultImage";
import NoMailImage from "../../../../shared/image/NoMailImage";
import { FilterBtnActivityCheckUtils } from "../../../../shared/utils/FilterBtnActivityCheckUtils";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import useTableHoverBoxClick from "../../../../hooks/useTableHoverBoxClick";
import { useNavigate } from "react-router-dom";
import { useTableOrder } from "../../../../hooks/useTableOrder";

enum ColumnsTypeToOrder {
  subject = "subject",
  datetimeForwarded = "datetimeForwarded",
}

const ReportView: React.FC = () => {
  const PAGING_SIZE = 100;
  const { t } = useTranslation();
  const { reportState, getReportMails } = useReportStore();
  const toast = useToast();
  const navigate = useNavigate();

  const [sort, changeSort] = useState("");
  const [openFilterTab, toggleOpenFilterTab] = useState(false);
  const [reportList, changeReportList] = useState<ReportMail[]>([]);
  const [reportCnt, changeReportCnt] = useState<number>(0);
  const [targetMailId, changeTargetMailId] = useState(-1);
  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setFilterData((filterData) => ({
          ...filterData,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        }));
    },
  });
  const [filterData, setFilterData] = useState<ReportMailSearchType>({
    orderId: getOrderParams().orderId,
    orderType: getOrderParams().orderType,
  } as ReportMailSearchType);

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const initialSelectedPeriod = {
    value: "ALL",
    start: new Date().valueOf(),
    end: new Date().valueOf(),
  };
  const [selectedPeriod, changeSelectedPeriod] = useState(
    initialSelectedPeriod
  );

  const changeDateFilter = (type: string) => {
    if (type === "ALL") {
      changeSelectedPeriod({
        value: type,
        start: new Date().valueOf(),
        end: new Date().valueOf(),
      });
    } else if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 12,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };
  const [buttonClick, setButtonClick] = useState(0);

  useEffect(() => {
    getNewReportMails();
    changeTargetMailId(-1);
  }, [filterData, selectedPeriod]);

  const getNewReportMails = async () => {
    let resp: any;
    if (selectedPeriod.value === "ALL") {
      resp = await getReportMails({
        ...filterData,
        size: PAGING_SIZE,
      });
    } else {
      resp = await getReportMails({
        ...filterData,
        size: PAGING_SIZE,
        forwardedStartDate: selectedPeriod.start
          ? selectedPeriod.start / 1000
          : undefined,
        forwardedEndDate: selectedPeriod.end
          ? selectedPeriod.end / 1000
          : undefined,
      });
    }
    if (resp.payload && resp.payload.code === "SUCCESS") {
      changeReportList(resp.payload.data.list);
    }
  };

  useEffect(() => {
    changeReportCnt(reportState.reportListWithCnt?.total);
  }, [reportState.reportListWithCnt]);

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [reportList]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const loadMoreItems = async () => {
    if (!hasNextPage) return;
    if (isNextPageLoading) return;

    setIsNextPageLoading(true);

    try {
      const resp: any = await getReportMails({
        ...filterData,
        size: PAGING_SIZE,
        cursor: reportList[reportList.length - 1].cursor,
        forwardedStartDate: filterData.forwardedStartDate
          ? filterData.forwardedStartDate / 1000
          : undefined,
        forwardedEndDate: filterData.forwardedEndDate
          ? filterData.forwardedEndDate / 1000
          : undefined,
        receivedStartDate: filterData.receivedStartDate
          ? filterData.receivedStartDate / 1000
          : undefined,
        receivedEndDate: filterData.receivedEndDate
          ? filterData.receivedEndDate / 1000
          : undefined,
      });

      if (resp && resp.payload && resp.payload.data && resp.payload.data.list) {
        changeReportList((prev) => {
          return [...prev, ...resp.payload.data.list];
        });

        if (resp.payload.data.list.length < PAGING_SIZE) {
          setHasNextPage(false);
        } else {
          setHasNextPage(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsNextPageLoading(false);
    }
  };

  const {
    tableRef,
    hoverBoxClickedRow,
    hoverBoxNewCoord,
    onOutsideClick,
    handleHoverBoxClick,
  } = useTableHoverBoxClick<ReportMail>({ width: 116, height: 58 });

  const transformMailList = (reportList: ReportMail[]) => {
    const reportListTransformed = [];

    for (let i = 0; i < reportList.length; i++) {
      const r = reportList[i];

      const padding = <BoxNew width={16} />;
      const dataType = (
        <TagNew
          type="box"
          text={
            r.trainingFlag
              ? t("report.reportTraining")
              : t("report.reportNotTraining")
          }
          textColor={
            r.trainingFlag
              ? getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT")
              : getColor("COLOR_AVATAR_PURPLE_TEXT")
          }
          backgroundColor={
            r.trainingFlag
              ? getColor("COLOR_AVATAR_LIGHT_BLUE_BG")
              : getColor("COLOR_AVATAR_PURPLE_BG")
          }
        />
      );

      const dataSubject = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          ellipsis
        >
          {r.exSubject}
        </TypographyNew>
      );

      const dataUser = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          ellipsis
        >
          {r.userName}
        </TypographyNew>
      );
      const dataForwardedTime = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="tnum"
        >
          {utilsCommon.getFullDateStr(r.datetimeForwarded + "+00:00")}
        </TypographyNew>
      );

      const dataSendAutoReply = r.sendAutoReply ? (
        <BoxNew classes="dot__container">
          <BoxNew classes="dot green__dot" />
          <TypographyNew
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("report.reportSendReply")}
          </TypographyNew>
        </BoxNew>
      ) : (
        <BoxNew classes="dot__container">
          <BoxNew classes="dot red__dot" />
          <TypographyNew
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("report.reportNotSendReply")}
          </TypographyNew>
        </BoxNew>
      );

      const dataSendManualReply = r.sendManualReply ? (
        <BoxNew classes="dot__container">
          <BoxNew classes="dot green__dot" />
          <TypographyNew
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("report.reportSendReply")}
          </TypographyNew>
        </BoxNew>
      ) : (
        <BoxNew classes="dot__container">
          <BoxNew classes="dot red__dot" />
          <TypographyNew
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("report.reportNotSendReply")}
          </TypographyNew>
        </BoxNew>
      );

      const dataComplete = r.processComplete ? (
        <BoxNew classes="dot__container">
          <BoxNew classes="dot green__dot" />
          <TypographyNew
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("report.completed")}
          </TypographyNew>
        </BoxNew>
      ) : (
        <BoxNew classes="dot__container">
          <BoxNew classes="dot red__dot" />
          <TypographyNew
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {/* {t("report.notCompleted")} */}
            미완료
          </TypographyNew>
        </BoxNew>
      );

      const hoverBtn = (
        <ButtonNew
          classes="hover-btn"
          btnStyle={"icon"}
          leadingIcon={<IconsNew variant="line" label="moreVertical" />}
          type={"primary"}
          size={"sm"}
          state={"hover"}
          onClick={(event: MouseEvent) => handleHoverBoxClick(event, r)}
        />
      );

      const reportTransformed = {
        data: [
          { columnText: padding },
          { columnText: dataType },
          { columnText: dataSubject },
          { columnText: dataUser },
          { columnText: dataForwardedTime },
          { columnText: dataSendAutoReply },
          { columnText: dataSendManualReply },
          { columnText: dataComplete },
        ],
        id: String(r.mailReportId),
        hover: hoverBtn,
        clickAction: (e: any) => {
          if (!e.target.closest(".hover-btn")) {
            navigate(
              `${process.env.PUBLIC_URL}/report/view/detail?report_id=${r.mailReportId}`
            );
          }
        },
      };

      reportListTransformed.push(reportTransformed);
    }

    return reportListTransformed;
  };

  const checkTableRenderStatus = () => {
    const isFilterApplied =
      JSON.stringify(filterData) !== JSON.stringify({} as ReportMailSearchType);
    const hasReportList = reportList && reportList.length > 0;

    if (!hasReportList && isFilterApplied) return 1;
    else if (!hasReportList && !isFilterApplied) return 2;
    else return 0;
  };

  const renderTableSwitch = () => {
    switch (checkTableRenderStatus()) {
      case 1:
        return <NoReportFiltered />;
      case 2:
        return <NoMailCreated />;
      default:
        return <></>;
    }
  };

  const resetFilter = () => {
    setFilterData({} as ReportMailSearchType);
    setTempSubjects([]);
    setTempUserNames([]);
  };

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const [tempSubject, setTempSubject] = useState<string>("");
  const [tempSubjects, setTempSubjects] = useState<string[]>([]);

  const [tempUserName, setTempUserName] = useState<string>("");
  const [tempUserNames, setTempUserNames] = useState<string[]>([]);

  const filters = (
    <BoxNew
      classes="pd pt-12 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      {/* 구분  start*/}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("program.trainingType") + " · " + t("common.auth.all")}
        value={
          filterData.mailType === undefined
            ? ""
            : filterData.mailType === "training"
            ? t("program.trainingType") + " · " + t("report.reportTraining")
            : t("program.trainingType") + " · " + t("report.reportNotTraining")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={filterData.mailType === undefined}
          checkColor={filterData.mailType === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, mailType: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={filterData.mailType === "training"}
          checkColor={filterData.mailType === "training"}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, mailType: "training" }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.reportTraining")}
          type={"check"}
        />
        <DropdownItem
          checked={filterData.mailType === "normal"}
          checkColor={filterData.mailType === "normal"}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, mailType: "normal" }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.reportNotTraining")}
          type={"check"}
        />
      </Dropdown>
      {/* 구분 end  */}

      {/* 메일 제목 start  */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("report.reportMailTitle") + " · " + t("quiz.label.all")}
        value={
          filterData.subjects === undefined || filterData.subjects.length === 0
            ? ""
            : t("report.reportMailTitle") + " · " + filterData.subjects.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("report.reportMailTitle")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempSubject}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={"메일 제목을 입력하세요."}
              onChange={(e: any) => setTempSubject(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (tempSubjects && tempSubjects.includes(e.target.value)) {
                    return;
                  }
                  setTempSubjects((prev) => [...prev, tempSubject]);
                  setTempSubject("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempSubjects &&
              tempSubjects.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempSubjects((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempSubject("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filterData.subjects || [],
                    tempSubjects
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempSubject !== "") {
                    setTempSubjects((prev) => [...prev, tempSubject]);
                    setFilterData((prev) => ({
                      ...prev,
                      subjects: [...tempSubjects, tempSubject],
                    }));
                  } else {
                    setFilterData((prev) => ({
                      ...prev,
                      subjects: tempSubjects,
                    }));
                  }
                  setTempSubject("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </Dropdown>
      {/* 메일 제목 end */}

      {/* 신고자 start  */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("report.reportCallUser") + " · " + t("quiz.label.all")}
        value={
          filterData.userNames === undefined ||
          filterData.userNames.length === 0
            ? ""
            : t("report.reportCallUser") + " · " + filterData.userNames.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("report.reportCallUser")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempUserName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={"신고자 이름을 입력하세요."}
              onChange={(e: any) => setTempUserName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (tempUserNames && tempUserNames.includes(e.target.value)) {
                    return;
                  }
                  setTempUserNames((prev) => [...prev, tempUserName]);
                  setTempUserName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempUserNames &&
              tempUserNames.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempUserNames((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempUserName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filterData.userNames || [],
                    tempUserNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempUserName !== "") {
                    setTempUserNames((prev) => [...prev, tempUserName]);
                    setFilterData((prev) => ({
                      ...prev,
                      userNames: [...tempUserNames, tempUserName],
                    }));
                  } else {
                    setFilterData((prev) => ({
                      ...prev,
                      userNames: tempUserNames,
                    }));
                  }
                  setTempUserName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </Dropdown>
      {/* 신고자 end */}

      {/* 신고 시간 기간 start  */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={
          t("report.reportReportingTimePeriod") + " · " + t("common.auth.all")
        }
        value={`${
          selectedPeriod.value === "PERIOD_3MONTH"
            ? t("report.reportReportingTimePeriod") +
              " · " +
              t("report.stats.reportRecent3Month")
            : selectedPeriod.value === "PERIOD_6MONTH"
            ? t("report.reportReportingTimePeriod") +
              " · " +
              t("report.stats.reportRecent6Month")
            : selectedPeriod.value === "PERIOD_1YEAR"
            ? t("report.reportReportingTimePeriod") +
              " · " +
              t("report.stats.reportRecent1Year")
            : selectedPeriod.value === "ALL"
            ? ""
            : t("report.reportReportingTimePeriod") +
              " · " +
              `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={selectedPeriod.value === "ALL"}
          checkColor={selectedPeriod.value === "ALL"}
          onClick={() => {
            changeDateFilter("ALL");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_3MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_3MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_6MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_6MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_1YEAR"}
          checkColor={selectedPeriod.value === "PERIOD_1YEAR"}
          onClick={() => {
            changeDateFilter("PERIOD_1YEAR");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_CUSTOM"}
          checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter("PERIOD_CUSTOM");
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown>
      {/* 신고 기간 end  */}

      {/* 자동 회신 start  */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("report.reportAutoReply") + " · " + t("common.auth.all")}
        value={
          filterData.sendAutoReply === undefined
            ? ""
            : filterData.sendAutoReply
            ? t("report.reportAutoReply") + " · " + t("report.reportSendReply")
            : t("report.reportAutoReply") +
              " · " +
              t("report.reportNotSendReply")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={filterData.sendAutoReply === undefined}
          checkColor={filterData.sendAutoReply === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, sendAutoReply: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={filterData.sendAutoReply}
          checkColor={filterData.sendAutoReply}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, sendAutoReply: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.reportSendReply")}
          type={"check"}
        />
        <DropdownItem
          checked={filterData.sendAutoReply === false}
          checkColor={filterData.sendAutoReply === false}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, sendAutoReply: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.reportNotSendReply")}
          type={"check"}
        />
      </Dropdown>
      {/* 자동 회신 end */}

      {/* 수동 회신 start  */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={
          t("report.reportManualReply") + " · " + t("common.auth.all")
        }
        value={
          filterData.sendManualReply === undefined
            ? ""
            : filterData.sendManualReply
            ? t("report.reportManualReply") +
              " · " +
              t("report.reportSendReply")
            : t("report.reportManualReply") +
              " · " +
              t("report.reportNotSendReply")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={filterData.sendManualReply === undefined}
          checkColor={filterData.sendManualReply === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, sendManualReply: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={filterData.sendManualReply}
          checkColor={filterData.sendManualReply}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, sendManualReply: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.reportSendReply")}
          type={"check"}
        />
        <DropdownItem
          checked={filterData.sendManualReply === false}
          checkColor={filterData.sendManualReply === false}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, sendManualReply: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.reportNotSendReply")}
          type={"check"}
        />
      </Dropdown>
      {/* 자동 회신 end */}

      {/* 처리 완료 start  */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("report.reportComplete") + " · " + t("common.auth.all")}
        value={
          filterData.processComplete === undefined
            ? ""
            : filterData.processComplete
            ? t("report.reportComplete") + " · " + t("report.completed")
            : t("report.reportComplete") + " · " + t("report.notCompleted")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={filterData.processComplete === undefined}
          checkColor={filterData.processComplete === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, processComplete: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={filterData.processComplete}
          checkColor={filterData.processComplete}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, processComplete: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.completed")}
          type={"check"}
        />
        <DropdownItem
          checked={filterData.processComplete === false}
          checkColor={filterData.processComplete === false}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, processComplete: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.notCompleted")}
          type={"check"}
        />
      </Dropdown>
      {/* 자동 회신 end */}
    </BoxNew>
  );

  const renderReportMailList = () => {
    return (
      <BoxNew
        classes="mg mt-32"
        direction="column"
        height={"calc(100% - 84px)"}
      >
        <BoxNew justifyContent="space-between">
          <BoxNew alignItems="center">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("report.reportList")}
            </TypographyNew>
            <TypographyNew
              classes="mg ml-8"
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
            >
              {reportCnt ?? 0}
            </TypographyNew>
          </BoxNew>
          <BoxNew>
            <ButtonNew
              leadingIcon={
                <IconsNew
                  variant="line"
                  label="filter"
                  stroke={getColor("COLOR_ICON_PRIMARY")}
                  size={20}
                />
              }
              btnStyle={"link"}
              type={"primary"}
              onClick={() => toggleOpenFilterTab(!openFilterTab)}
              size={"lg"}
              label={t("common.list.filter")}
            />
          </BoxNew>
        </BoxNew>
        {openFilterTab && filters}

        <BoxNew
          direction="column"
          classes="pd pt-12 flex-1"
          ref={tableRef}
          // height={openFilterTab ? "calc(100% - 170px)" : "calc(100% - 110px)"}
        >
          <TableNew
            border="none"
            columnHeight="md"
            data={transformMailList(reportList)}
            header={[
              { headerText: "", width: "16px" },
              { headerText: t("program.trainingType"), width: "66px" },
              {
                headerText: t("report.reportMailTitle"),
                width: `calc(100% - 850px - ${scrollbarWidth}px)`,
                ...getOrderRenderProps(ColumnsTypeToOrder.subject),
              },
              { headerText: t("report.reportCallUser"), width: "230px" },
              {
                headerText: t("report.reportForwardedTime"),
                width: "230px",
                ...getOrderRenderProps(ColumnsTypeToOrder.datetimeForwarded),
              },
              { headerText: t("report.reportAutoReply"), width: "80px" },
              { headerText: t("report.reportManualReply"), width: "80px" },
              {
                headerText: t("report.reportComplete"),
                width: `calc(80px + ${scrollbarWidth}px)`,
              },
            ]}
            endReached={loadMoreItems}
            width="100%"
            height={
              checkTableRenderStatus() === 0
                ? `${
                    tableContainerPosition.height -
                    (245 + (openFilterTab ? 56 : 0))
                  }px`
                : "0px"
            }
          />
          {renderTableSwitch()}
          <BoxNew>
            <ReportListHoverBox
              isDisplayed={!!hoverBoxClickedRow.mailReportId}
              hoverBoxNewCoord={hoverBoxNewCoord}
              onOutsideClick={onOutsideClick}
              onClickDetail={() =>
                navigate(
                  `${process.env.PUBLIC_URL}/report/view/detail?report_id=${hoverBoxClickedRow.mailReportId}`
                )
              }
            />
          </BoxNew>
        </BoxNew>
      </BoxNew>
    );
  };

  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  const NoReportFiltered = () => {
    return (
      <BoxNew
        width={"100%"}
        direction="column"
        alignItems="center"
        classes="mg mt-80"
      >
        <NoSearchResultImage width={200} />
        <TypographyNew
          type="h3"
          classes="mg mt-36"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("quiz.label.noSearch")}
        </TypographyNew>
        <TypographyNew
          type="body2_rg_long"
          classes="mg mt-8"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          {t("quiz.msg.noFilterQuizContent")}
        </TypographyNew>
        <ButtonNew
          btnStyle={"fill"}
          state={"default"}
          size={"lg"}
          classes="mg mt-20"
          leadingIcon={
            <IconsNew
              variant="line"
              label="redoLeft"
              stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
            />
          }
          onClick={resetFilter}
          label={t("common.button.resetFilter")}
        />
      </BoxNew>
    );
  };

  const NoMailCreated = () => {
    return (
      <BoxNew
        width={"100%"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        classes="mg mt-80"
      >
        <NoMailImage />
        <TypographyNew
          classes="mg mt-24 mb-8 "
          type="h3"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("quiz.msg.noItems")}
        </TypographyNew>
        <TypographyNew
          type="body2_rg_long"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          classes="mg mt-8"
        >
          {t("report.msg.noReportMailMsg")}
        </TypographyNew>
      </BoxNew>
    );
  };

  return (
    <BoxNew classes="main reportView" ref={tableContainerRef}>
      {openDateModal && dateModal()}
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath
            path={[t("menu.reportCenter"), t("menu.reportOperation")]}
          />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.reportOperation")}
          </TypographyNew>
        </BoxNew>
        {targetMailId !== -1 && (
          <ButtonNew
            type={"secondary"}
            onClick={() => changeTargetMailId(-1)}
            label={t("report.reportBackToList")}
          />
        )}
      </BoxNew>
      {targetMailId === -1 ? (
        renderReportMailList()
      ) : (
        <ReportViewDetail
        // targetId={targetMailId}
        // setTargetId={changeTargetMailId}
        // getNewReportMails={getNewReportMails}
        />
      )}
    </BoxNew>
  );
};

export default ReportView;

const ReportListHoverBox = ({
  hoverBoxNewCoord,
  isDisplayed,
  onOutsideClick,
  onClickDetail,
}: {
  hoverBoxNewCoord: { x: number; y: number };
  isDisplayed: boolean;
  onOutsideClick: (event: MouseEvent) => void;
  onClickDetail?: () => void;
}) => {
  const { t } = useTranslation();
  const [targetRef] = useOutsideClick(onOutsideClick);
  return (
    <DropdownBody
      location={{
        x: hoverBoxNewCoord.x,
        y: hoverBoxNewCoord.y,
      }}
      style={{
        position: "fixed",
        display: isDisplayed ? "block" : "none",
        pointerEvents: "auto",
      }}
    >
      <BoxNew ref={targetRef} direction={"column"}>
        {onClickDetail && (
          <DropdownItem
            frontIcon={<IconsNew variant={"line"} label={"info"} />}
            label={t("common.button.detail")}
            onClick={onClickDetail}
          />
        )}
      </BoxNew>
    </DropdownBody>
  );
};
