import {
  Box,
  getColor,
  Icons,
  Button,
  Tag,
  Typography,
  useToast,
  Table as TableNew,
  Dropdown,
  InputField,
  DropdownItem,
  Chip,
  Tooltip,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useEffect, useRef, useState } from "react";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import { PhishingDocument } from "../../../types/Info";
import RegitOrEditDocumentModal from "./RegitOrEditDocumentModal";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { nanoid } from "@reduxjs/toolkit";
import CommonModal from "../../../component/CommonModal";
import { useTranslation } from "react-i18next";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import { useElementPosition } from "../../../hooks/useElementPosition";
import useTableHoverBoxClick from "../../../hooks/useTableHoverBoxClick";
import { FilterBtnActivityCheckUtils } from "../../../shared/utils/FilterBtnActivityCheckUtils";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import HoverClickBox from "../../../component/Table/HoverClickBox";
import NoTrainingContentImage from "../../../shared/image/NoTrainingContentImage";
import IconMindSat from "../../../shared/image/icon";
import utilsCommon from "../../../shared/utils/common";
import dayjs from "dayjs";
import { useTableOrder } from "../../../hooks/useTableOrder";
import DateModal, { DateType } from "../../../component/DateModal";
import useTimedReset from "../../../hooks/useTimedReset";
import { TABLE_HIGHLIGHT_TIMED_OUT } from "../../../shared/constant";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";

enum ColumnsTypeToOrder {
  documentName = "documentName",
  // documentOrgName = "documentOrgName",
  documentEntryDate = "documentEntryDate",
  documentUpdateDate = "documentUpdateDate",
}

const TrainingDocument: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [attaches, setAttaches] = useState<PhishingDocument[]>([]);
  const [attachesTotal, setAttachesTotal] = useState<number>(0);
  const [isCreateDateModalOpen, setIsCreateDateModalOpen] =
    useState<boolean>(false);
  const [isUpdateDateModalOpen, setIsUpdateDateModalOpen] =
    useState<boolean>(false);

  const [isRegitModalOpen, setIsRegitModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedAttaches, setSelectedAttaches] = useState<number[]>([]);
  const [rowIdCreated, setRowIdCreated] = useTimedReset(
    "",
    TABLE_HIGHLIGHT_TIMED_OUT
  );

  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setSearchFilter({
          ...searchFilter,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        });
    },
  });

  const FILTER_TYPE = {
    // id: 서버 구분자
    ALL: { id: undefined, text: t("common.auth.all") },
    PUBLIC: { id: true, text: t("template.public") },
    PRIVATE: { id: false, text: t("template.private") },
  };
  const initialSearchParams = useRef("");

  const { contentState, getAttaches, deleteAttach, downloadAttachFile } =
    useContentStore();
  const localCustomerCode = getLocalCustomerCode();
  const toast = useToast();
  const checkboxGroup = useCheckboxGroup();

  const setDelete = (id: number) => {
    setSelectedAttaches([id]);
  };

  const downloadDocument = async (id: number) => {
    const resp: any = await downloadAttachFile(id);
    if (resp.payload) {
      console.log(resp.payload);
    }
  };

  const onDelete = async () => {
    const resp: any = await deleteAttach({
      request: {
        documentIds:
          selectedAttaches.length > 0
            ? selectedAttaches
            : (checkboxGroup.selected as number[]),
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("attach.msg.attachDeleteSuccessMsg"),
        "success"
      );
    } else if (resp.payload && resp.payload.code === "DATA_USED") {
      toast.toastMsg(
        nanoid(),
        t("attach.msg.attachDeleteUsedTemplateFailMsg"),
        "error"
      );
    } else {
      toast.toastMsg(nanoid(), t("attach.msg.attachDeleteFailMsg"), "error");
    }

    checkboxGroup.handleAllClick([]);
    setSelectedAttaches([]);
    setIsDeleteModalOpen(false);
  };

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const {
    tableRef,
    hoverBoxClickedRow,
    hoverBoxNewCoord,
    onOutsideClick,
    handleHoverBoxClick,
  } = useTableHoverBoxClick<PhishingDocument>({
    width: 116,
    getDynamicHeight: (row) =>
      checkIfDataEditableByCommonId(row.commonId) ? 147 : 58,
  });

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const header = (
    <Box classes="mg mt-32" justifyContent="space-between" width={"100%"}>
      <Box alignItems="center">
        <Typography
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("attach.attachList")}
        </Typography>
        <Typography
          classes="mg ml-8"
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_BRAND")}
        >
          {attachesTotal}
        </Typography>
      </Box>

      <Box>
        <Button
          btnStyle="link"
          state={checkboxGroup.selected.length > 0 ? "default" : "disable"}
          type="secondary"
          leadingIcon={<Icons variant="line" label="delete" />}
          classes="mg mr-4"
          onClick={() => {
            let commonFlag = false;
            checkboxGroup.selected.map((c) => {
              const item: PhishingDocument[] = attaches.filter(
                (t) => t.documentId === c
              );

              item.map((i) => {
                if (i.commonId) {
                  commonFlag = true;
                }
              });
            });
            if (commonFlag) {
              toast.toastMsg(
                nanoid(),
                t("common.msg.cannotDeletePublicContentsMsg"),
                "error"
              );
              return;
            }
            setIsDeleteModalOpen(true);
          }}
          label={t("common.button.delete")}
        />
        <Button
          btnStyle="link"
          type="primary"
          leadingIcon={
            <Icons
              variant="line"
              label="filter"
              stroke={getColor("COLOR_ICON_PRIMARY")}
            />
          }
          onClick={() => {
            setIsFilterOpen((prev) => !prev);
          }}
          label={t("common.list.filter")}
        />
      </Box>
    </Box>
  );

  const [buttonClick, setButtonClick] = useState<number>(0);

  const initialTempSearchFilter = {
    commonId: 0,
    imageNameInput: "",
    documentNames: [],
    createStart: new Date().valueOf(),
    createEnd: new Date().valueOf(),
    updateStart: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    updateEnd: dayjs()
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .valueOf(),
    createType: DateType.ALL,
    updateType: DateType.ALL,
  };

  const [tempSearchFilter, setTempSearchFilter] = useState<{
    commonId: number;
    imageNameInput: string;
    documentNames: string[];
    createStart: number | undefined;
    createEnd: number | undefined;
    updateStart: number | undefined;
    updateEnd: number | undefined;
    createType: string;
    updateType: string;
  }>(initialTempSearchFilter);

  const initialSearchFilter = {
    commonId: undefined,
    documentNames: [],
    createStart: undefined,
    createEnd: undefined,
    orderId: getOrderParams().orderId,
    orderType: getOrderParams().orderType,
    updateStart: undefined,
    updateEnd: undefined,
  };

  const [searchFilter, setSearchFilter] = useState<{
    commonId: boolean | undefined;
    documentNames: string[];
    createStart: number | undefined;
    createEnd: number | undefined;
    updateStart: number | undefined;
    updateEnd: number | undefined;
    orderId: string;
    orderType: string;
  }>(initialSearchFilter);

  const changeDateFilter = (type: DateType, order: "CREATE" | "UPDATE") => {
    let newMonth = 0;
    if (type === DateType.PERIOD_3MONTH) {
      newMonth = 3;
    } else if (type === DateType.PERIOD_6MONTH) {
      newMonth = 6;
    } else if (type === DateType.PERIOD_1YEAR) {
      newMonth = 12;
    }

    const start = dayjs().subtract(newMonth, "month").add(1, "day").valueOf();
    const end = dayjs()
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .valueOf();

    if (order === "CREATE") {
      if (type === DateType.ALL) {
        setSearchFilter((d) => ({
          ...d,
          createStart: undefined,
          createEnd: undefined,
        }));
      } else if (type === DateType.PERIOD_CUSTOM) {
        // pass
      } else {
        setSearchFilter((d) => ({
          ...d,
          createStart: start,
          createEnd: end,
        }));
      }
      setTempSearchFilter((d) => ({
        ...d,
        createType: type,
      }));
    } else {
      // UPDATE
      if (type === DateType.ALL) {
        setSearchFilter((d) => ({
          ...d,
          updateStart: undefined,
          updateEnd: undefined,
        }));
      } else if (type === DateType.PERIOD_CUSTOM) {
        // pass
      } else {
        setSearchFilter((d) => ({
          ...d,
          updateStart: start,
          updateEnd: end,
        }));
      }
      setTempSearchFilter((d) => ({
        ...d,
        updateType: type,
      }));
    }
  };

  function checkAnyFilterSelected() {
    return (
      searchFilter.commonId !== FILTER_TYPE.ALL.id ||
      searchFilter.documentNames.length > 0 ||
      tempSearchFilter.createType !== DateType.ALL ||
      searchFilter.createStart ||
      searchFilter.createEnd ||
      tempSearchFilter.updateType !== DateType.ALL ||
      searchFilter.updateStart ||
      searchFilter.updateEnd
    );
  }

  const filters = (
    <Box classes="pd pt-12 gap-8 dropdown-filter-container" alignItems="center">
      {/* 구분 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.type") + " · " + t("quiz.label.all")}
        value={
          searchFilter.commonId === FILTER_TYPE.PUBLIC.id
            ? t("quiz.table.type") + " · " + t("quiz.label.public")
            : searchFilter.commonId === FILTER_TYPE.PRIVATE.id
            ? t("quiz.table.type") + " · " + t("quiz.label.private")
            : ""
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        {Object.values(FILTER_TYPE).map((item) => (
          <DropdownItem
            checked={item.id === searchFilter.commonId}
            checkColor={item.id === searchFilter.commonId}
            onClick={() => {
              setSearchFilter((prev) => ({
                ...prev,
                commonId: item.id,
              }));
              setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            }}
            isFullWidth
            label={item.text}
            type={"check"}
          />
        ))}
      </Dropdown>

      {/* 첨부 파일 이름 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("attach.name") + " · " + t("common.auth.all")}
        value={
          searchFilter.documentNames.length > 0
            ? t("attach.name") + " · " + searchFilter.documentNames.length
            : ""
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <Box direction={"column"} classes="dropdown-filter">
          <Box classes="dropdown-filter-title">
            <Typography
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("attach.name")}
            </Typography>
          </Box>
          <Box classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempSearchFilter.imageNameInput}
              leadingIcon={<Icons variant="line" label="search" size={20} />}
              placeholder={t("template.msg.attachNamePlaceholder")}
              onChange={(e) => {
                setTempSearchFilter((d) => ({
                  ...d,
                  imageNameInput: e.target.value,
                }));
              }}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  setTempSearchFilter((d) => ({
                    ...d,
                    documentNames: [...d.documentNames, d.imageNameInput],
                  }));
                  setTempSearchFilter((d) => ({ ...d, imageNameInput: "" }));
                }
              }}
            />
          </Box>
          <Box classes="gap-10 dropdown-filter-body">
            {tempSearchFilter.documentNames.map((item) => (
              <Chip
                key={`tempCategoryName-chip-${item}`}
                type={"fill"}
                size={"sm"}
                showClose={true}
                text={item}
                onClick={() =>
                  setTempSearchFilter((d) => ({
                    ...d,
                    documentNames: d.documentNames.filter((q) => q !== item),
                  }))
                }
              />
            ))}
          </Box>
          <Box
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <Box>
              <Button
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <Button
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    searchFilter.documentNames || [],
                    tempSearchFilter.documentNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  setSearchFilter((prev) => ({
                    ...prev,
                    documentNames: tempSearchFilter.documentNames,
                  }));
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Dropdown>

      {/* 등록 일자 */}
      {/* <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.addDate") + " · " + t("common.auth.all")}
        value={`${
          tempSearchFilter.createType === DateType.PERIOD_3MONTH
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent3Month")
            : tempSearchFilter.createType === DateType.PERIOD_6MONTH
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent6Month")
            : tempSearchFilter.createType === DateType.PERIOD_1YEAR
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent1Year")
            : tempSearchFilter.createType === DateType.ALL
            ? ""
            : t("common.time.addDate") + " · " + t("graph.phishingCustomScope")
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.ALL}
          checkColor={tempSearchFilter.createType === DateType.ALL}
          onClick={() => {
            changeDateFilter(DateType.ALL, "CREATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.PERIOD_3MONTH}
          checkColor={tempSearchFilter.createType === DateType.PERIOD_3MONTH}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_3MONTH, "CREATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.PERIOD_6MONTH}
          checkColor={tempSearchFilter.createType === DateType.PERIOD_6MONTH}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_6MONTH, "CREATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.PERIOD_1YEAR}
          checkColor={tempSearchFilter.createType === DateType.PERIOD_1YEAR}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_1YEAR, "CREATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.PERIOD_CUSTOM}
          checkColor={tempSearchFilter.createType === DateType.PERIOD_CUSTOM}
          onClick={() => setIsCreateDateModalOpen(true)}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown> */}

      {/* 수정일자 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.updateDate") + " · " + t("common.auth.all")}
        value={`${
          tempSearchFilter.updateType === DateType.PERIOD_3MONTH
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent3Month")
            : tempSearchFilter.updateType === DateType.PERIOD_6MONTH
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent6Month")
            : tempSearchFilter.updateType === DateType.PERIOD_1YEAR
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent1Year")
            : tempSearchFilter.updateType === DateType.ALL
            ? ""
            : t("common.time.updateDate") +
              " · " +
              `${utilsCommon.getShortDate(searchFilter.updateStart)}
            - ${utilsCommon.getShortDate(searchFilter.updateEnd)}`
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.ALL}
          checkColor={tempSearchFilter.updateType === DateType.ALL}
          onClick={() => {
            changeDateFilter(DateType.ALL, "UPDATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.PERIOD_3MONTH}
          checkColor={tempSearchFilter.updateType === DateType.PERIOD_3MONTH}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_3MONTH, "UPDATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.PERIOD_6MONTH}
          checkColor={tempSearchFilter.updateType === DateType.PERIOD_6MONTH}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_6MONTH, "UPDATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.PERIOD_1YEAR}
          checkColor={tempSearchFilter.updateType === DateType.PERIOD_1YEAR}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_1YEAR, "UPDATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.PERIOD_CUSTOM}
          checkColor={tempSearchFilter.updateType === DateType.PERIOD_CUSTOM}
          onClick={() => setIsUpdateDateModalOpen(true)}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown>

      {/* 초기화 */}
      <Button
        btnStyle={"icon"}
        size={"md"}
        state={checkAnyFilterSelected() ? "default" : "disable"}
        onClick={resetFilter}
        leadingIcon={
          <Icons
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
      />
    </Box>
  );

  function transformData(data: PhishingDocument[]) {
    const dataTransformed = [];

    for (let i = 0; i < data.length; i++) {
      const imageInfo = data[i];
      const dataType = localCustomerCode ? (
        imageInfo.commonId ? (
          <Tag
            as={"p"}
            type={"box"}
            text={t("template.public")}
            textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
            backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
          />
        ) : (
          <Tag
            as={"p"}
            type={"box"}
            text={t("template.private")}
            textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
            backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
          />
        )
      ) : (
        <Tag
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
          backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
        />
      );
      const dataDocumentName = (
        <Tooltip message={imageInfo.documentName}>
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            ellipsis
          >
            {imageInfo.documentName}
          </Typography>
        </Tooltip>
      );
      const dataDocumentOrgName = (
        <Box alignItems="center">
          <IconMindSat variant="document" label="document" classes="mg mr-5" />
          <Tooltip message={imageInfo.documentOrgName}>
            <Typography
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
              ellipsis
              maxWidth={240}
            >
              {imageInfo.documentOrgName}
            </Typography>
          </Tooltip>
        </Box>
      );

      const dataDocumentUpdateDate = (
        <Typography
          type="body2_rg"
          classes="tnum"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {imageInfo.documentUpdateDate
            ? utilsCommon.getFullDateStr(imageInfo.documentUpdateDate)
            : ""}
        </Typography>
      );

      const hoverBtn = (
        <Button
          btnStyle={"icon"}
          leadingIcon={<Icons variant="line" label="moreVertical" />}
          type={"primary"}
          size={"sm"}
          state={"hover"}
          onClick={(event: MouseEvent) => handleHoverBoxClick(event, imageInfo)}
        />
      );

      const dataItemTransformed = {
        data: [
          { columnText: dataType },
          { columnText: dataDocumentName },
          { columnText: dataDocumentOrgName },
          // { columnText: dataDocumentEntryDate },
          { columnText: dataDocumentUpdateDate },
        ],
        id: String(imageInfo.documentId),
        hover: hoverBtn,
        checkBoxClick: () => {
          checkboxGroup.handleClick(imageInfo.documentId);
        },
      };
      dataTransformed.push(dataItemTransformed);
    }

    return dataTransformed;
  }

  function checkTableRenderStatus() {
    const isFilterApplied = checkAnyFilterSelected();
    const hasResult = attaches && attaches.length > 0;
    if (!hasResult && isFilterApplied) return 1;
    else if (!hasResult && !isFilterApplied) return 2;
    else return 0;
  }

  function renderTableSwitch() {
    switch (checkTableRenderStatus()) {
      case 1:
        return noFilteredResult;
      case 2:
        return noResultCreated;
      default:
        return <></>;
    }
  }

  const noFilteredResult = (
    <Box
      direction="column"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      style={{ paddingTop: "90px" }}
    >
      <NoSearchResultImage width={200} />
      <Typography type="h3" classes="mg mt-20">
        {t("quiz.label.noSearch")}
      </Typography>
      <Typography
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        type="body2_rg"
        classes="mg mt-8 mb-24"
      >
        {t("template.msg.noSearchResultMsg")}
      </Typography>
      <Button
        btnStyle={"fill"}
        label={t("common.button.resetFilter")}
        state={"default"}
        size={"lg"}
        type={"primary"}
        classes="mg mt-30"
        leadingIcon={<Icons variant="line" label="redoLeft" stroke="white" />}
        onClick={resetFilter}
      />
    </Box>
  );

  function resetFilter() {
    setSearchFilter(initialSearchFilter);
    setTempSearchFilter(initialTempSearchFilter);
  }

  const noResultCreated = (
    <Box
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoTrainingContentImage />
      <Typography type="h3" classes="mg mt-20">
        {t("attach.msg.noData")}
      </Typography>
      <Typography
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        type="body2_rg"
        classes="mg mt-24 mb-24"
      >
        {t("attach.msg.noDataMsg")}
      </Typography>
      <Button
        btnStyle="fill"
        leadingIcon={
          <Icons
            variant="line"
            label="plusCircle"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
        type="primary"
        onClick={() => setIsRegitModalOpen(true)}
        label={t("attach.addSimple")}
        size="lg"
      />
    </Box>
  );

  useEffect(() => {
    if (initialSearchParams.current !== JSON.stringify(searchFilter)) {
      getAttaches({
        orderId: searchFilter.orderId,
        orderType: searchFilter.orderType,
        isCommon: searchFilter.commonId,
        documentNames: searchFilter.documentNames,
        createStartDate:
          searchFilter.createStart &&
          Math.trunc(searchFilter.createStart / 1000),
        createEndDate:
          searchFilter.createEnd && Math.trunc(searchFilter.createEnd / 1000),
        updateStartDate:
          searchFilter.updateStart &&
          Math.trunc(searchFilter.updateStart / 1000),
        updateEndDate:
          searchFilter.updateEnd && Math.trunc(searchFilter.updateEnd / 1000),
      });
      initialSearchParams.current = JSON.stringify(searchFilter);
    }
  }, [searchFilter]);

  useEffect(() => {
    setAttaches(contentState.attaches);
    setAttachesTotal(contentState.attachesTotal);
    checkboxGroup.handleAllClick([]);
  }, [contentState.attaches]);

  return (
    <Box classes="main manageScenarioMgnt" ref={tableContainerRef}>
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[
              t("menu.trainingManagement"),
              t("menu.templateContentsSetting"),
              t("menu.templateAttachFile"),
            ]}
          />
          <Typography classes="mg mt-8" type="h1">
            {t("menu.templateAttachFile")}
          </Typography>
        </Box>
        <Button
          btnStyle="fill"
          leadingIcon={<Icons variant="line" label="plusCircle" size={18} />}
          type="secondary"
          onClick={() => setIsRegitModalOpen(true)}
          label={t("common.button.add")}
          size="lg"
        />
      </Box>

      <Box direction="column">
        {header}
        {isFilterOpen && filters}
        <DateModal
          isOpen={isCreateDateModalOpen}
          setIsOpen={setIsCreateDateModalOpen}
          start={tempSearchFilter.createStart}
          end={tempSearchFilter.createEnd}
          onSubmit={(start, end) => {
            setSearchFilter((d) => ({
              ...d,
              createStart: start,
              createEnd: end,
            }));
            setTempSearchFilter((d) => ({
              ...d,
              createType: DateType.PERIOD_CUSTOM,
            }));
          }}
        />

        <DateModal
          isOpen={isUpdateDateModalOpen}
          setIsOpen={setIsUpdateDateModalOpen}
          start={tempSearchFilter.updateStart}
          end={tempSearchFilter.updateEnd}
          onSubmit={(start, end) => {
            setSearchFilter((d) => ({
              ...d,
              updateStart: start,
              updateEnd: end,
            }));
            setTempSearchFilter((d) => ({
              ...d,
              updateType: DateType.PERIOD_CUSTOM,
            }));
          }}
        />
        <Box
          direction="column"
          classes="pd pt-12 flex-1"
          ref={tableRef}
          style={{
            pointerEvents: hoverBoxClickedRow.documentId ? "none" : undefined,
          }}
        >
          <TableNew
            border="none"
            clickIds={checkboxGroup.selected.map((documentId) =>
              String(documentId)
            )}
            highlightIds={[rowIdCreated]}
            columnHeight="md"
            totalCount={attachesTotal}
            data={transformData(attaches)}
            endReached={() => {}}
            checkBoxClick={() =>
              checkboxGroup.handleAllClick(
                checkboxGroup.selected.length === attaches.length
                  ? []
                  : attaches.map((q) => q.documentId)
              )
            }
            header={[
              {
                headerText: t("template.type"),
                width: "72px",
              },
              {
                headerText: t("attach.name"),
                width: "calc(100% - 500px)",
                ...getOrderRenderProps(ColumnsTypeToOrder.documentName),
              },
              {
                headerText: t("attach.originalFile"),
                width: "240px",
              },
              {
                headerText: t("common.time.updateDate"),
                width: "210px",
                ...getOrderRenderProps(ColumnsTypeToOrder.documentUpdateDate),
              },
            ]}
            width={"100%"}
            height={
              checkTableRenderStatus() === 0
                ? `${
                    tableContainerPosition.height -
                    (245 + (isFilterOpen ? 56 : 0))
                  }px`
                : "0px"
            }
          />
          {renderTableSwitch()}
          <HoverClickBox
            isDisplayed={!!hoverBoxClickedRow.documentId}
            hoverBoxNewCoord={hoverBoxNewCoord}
            onOutsideClick={onOutsideClick}
            onClickDelete={
              hoverBoxClickedRow.commonId
                ? undefined
                : (e: MouseEvent) => {
                    setDelete(hoverBoxClickedRow.documentId);
                    setIsDeleteModalOpen(true);
                    onOutsideClick(e);
                  }
            }
            onClickDownload={(e: MouseEvent) => {
              downloadDocument(hoverBoxClickedRow.documentId);
              onOutsideClick(e);
            }}
            onClickUpdate={
              hoverBoxClickedRow.commonId
                ? undefined
                : (e: MouseEvent) => {
                    setSelectedAttaches([hoverBoxClickedRow.documentId]);
                    setIsRegitModalOpen(true);
                    onOutsideClick(e);
                  }
            }
          />
        </Box>
      </Box>

      {isRegitModalOpen && (
        <RegitOrEditDocumentModal
          isOpen={isRegitModalOpen}
          onClose={() => {
            setSelectedAttaches([]);
            setIsRegitModalOpen(false);
          }}
          documentId={
            selectedAttaches.length > 0 ? selectedAttaches[0] : undefined
          }
          setRowIdCreated={setRowIdCreated}
        />
      )}
      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title={t("attach.delete")}
          afterSubmitButton={onDelete}
          subMessage={t("attach.msg.deleteConfirmMsg")}
          type="delete"
        />
      )}
    </Box>
  );
};

export default TrainingDocument;
