import {
  Modal,
  Button,
  Icons,
  getColor,
  Typography,
  Box,
  Tooltip,
  useToast,
  InputField,
  Table,
  ColumnDatas,
} from "fds";
import { useRef, useState } from "react";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import { nanoid } from "@reduxjs/toolkit";
import { SimpleTreeNode } from "../../types/User";
import utilsCommon from "../../shared/utils/common";
import { useTranslation } from "react-i18next";

interface InsertDeptByCSVModalProps {
  isOpen: boolean;
  type: "USER" | "DEPT";
  onClose: () => void;
}

const InsertDeptByCSVModal: React.FC<InsertDeptByCSVModalProps> = ({
  isOpen,
  type,
  onClose,
}) => {
  const toast = useToast();
  const {
    deptState,
    checkBeforeUpdateUserCSV,
    uploadUserCSV,
    checkBeforeUpdateDeptCSV,
    uploadDeptCSV,
  } = useDeptStore();
  const { t } = useTranslation();
  const [file, changeFile] = useState<File>();
  const [validateResult, changeValidateResult] = useState<any[] | undefined>();
  const iconInput = useRef<HTMLInputElement | null>(null);

  const loadDeptFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<any> => {
    if (
      !(
        e.target.files &&
        e.target.files.length > 0 &&
        e.target.files[0].name !== undefined
      )
    ) {
      return;
    }
    const file = e.target.files[0];
    if (file.type !== "text/csv") {
      toast.toastMsg(
        nanoid(),
        t("common.msg.invalidFileExtensionMsg"),
        "error"
      );
      return;
    }
    let resp: any =
      type === "USER"
        ? await checkBeforeUpdateUserCSV(e.target.files[0])
        : await checkBeforeUpdateDeptCSV(e.target.files[0]);
    if (resp && resp.payload && resp.payload.data) {
      changeFile(file);
      changeValidateResult(resp.payload.data);
    } else {
      toast.toastMsg(nanoid(), t("common.msg.uploadCsvFailMsg"), "error");
    }
  };

  const checkItemError = (item: any) => {
    let errorItems = [];
    if (type === "USER") {
      if (!item.userName || !item.userEmail || !item.deptCode)
        errorItems.push(t("dept.msg.undefinedInputMsg"));
      if (item.userEmail && !utilsCommon.emailValidation(item.userEmail))
        errorItems.push(t("user.msg.userInvalidEmailMsg"));
      if (
        deptState.organList.filter(
          (item1: SimpleTreeNode) => item1.deptCode === item.deptCode
        ).length === 0
      )
        errorItems.push(t("user.msg.userUndefinedDeptCodeMsg"));
    } else {
      if (item.noParent)
        errorItems.push(t("dept.msg.undefinedHighLevelDeptCodeMsg"));
      if (item.existDept) errorItems.push(t("dept.msg.duplicatedCodeMsg"));
      // if (!utilsCommon.deptCodeValidation(item.deptCode))
      //   errorItems.push("부서 코드는 영어, 한글, 숫자, _만 입력 가능합니다.");
      // if (!utilsCommon.deptCodeValidation(item.deptName))
      //   errorItems.push("부서 이름은 영어, 한글, 숫자, _만 입력 가능합니다.");
      if (item.deptCode && item.deptCode?.length > 64)
        errorItems.push(t("dept.msg.codeLengthLimitMsg"));
      if (item.deptCode && item.deptName?.length > 128)
        errorItems.push(t("dept.msg.namdLengthLimitMsg"));
    }

    return errorItems;
  };

  const handleUploadUserCSV = async (file?: File) => {
    if (!file) return;
    let resp: any = await uploadUserCSV(file);
    if (resp && resp.payload && resp.payload.data) {
      toast.toastMsg(nanoid(), t("user.msg.userAddListSuccessMsg"), "success");
      window.location.reload();
    } else {
      toast.toastMsg(nanoid(), t("user.msg.userAddFailMsg"), "error");
    }
    onClose();
  };

  const handleUploadDeptCSV = async (file?: File) => {
    if (!file) return;
    let resp: any = await uploadDeptCSV(file);
    if (resp && resp.payload && resp.payload.data) {
      toast.toastMsg(nanoid(), t("dept.msg.addListSuccessMsg"), "success");
      window.location.reload();
    } else {
      toast.toastMsg(nanoid(), t("dept.msg.addFailMsg"), "error");
    }
    onClose();
  };

  function transformDept(data: any[] | undefined) {
    const dataTransformed = [] as ColumnDatas[];
    data &&
      data?.map((item: any) => {
        let errors = checkItemError(item);
        const dataItemTransformed = {
          data: [
            {
              columnText: (
                <Box
                  width={44}
                  height={40}
                  alignItems="center"
                  justifyContent="center"
                >
                  {item.exist ? (
                    <Icons
                      variant="line"
                      label="checkCircle"
                      stroke={getColor("COLOR_ICON_INTERACTIVE_SECONDARY")}
                    />
                  ) : errors.length === 0 ? (
                    <Icons
                      variant="line"
                      label="checkCircle"
                      stroke={getColor("COLOR_ICON_INTERACTIVE_BRAND")}
                    />
                  ) : (
                    <Tooltip
                      message={
                        <Box direction="column">
                          {errors.map((item) => (
                            <Typography exactColor={getColor("COLOR_WHITE")}>
                              - {item}
                            </Typography>
                          ))}
                        </Box>
                      }
                    >
                      <Icons
                        variant="line"
                        label="alertCircle"
                        stroke={getColor("COLOR_BG_ERROR")}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
            },
            {
              columnText: (
                <Tooltip message={item.deptCode}>
                  <Typography
                    exactColor={
                      item.exist
                        ? getColor("COLOR_TEXT_DISABLED")
                        : getColor("COLOR_TEXT_PRIMARY")
                    }
                  >
                    {item.deptCode}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              columnText: (
                <Tooltip message={item.deptName}>
                  <Typography
                    exactColor={
                      item.exist
                        ? getColor("COLOR_TEXT_DISABLED")
                        : getColor("COLOR_TEXT_PRIMARY")
                    }
                  >
                    {item.deptName}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              columnText: (
                <Tooltip message={item.parentCode}>
                  <Typography
                    exactColor={
                      item.exist
                        ? getColor("COLOR_TEXT_DISABLED")
                        : getColor("COLOR_TEXT_PRIMARY")
                    }
                  >
                    {item.parentCode}
                  </Typography>
                </Tooltip>
              ),
            },
          ],
          id: String(item.deptCode),
          checkBoxClick: () => {},
        };
        dataTransformed.push(dataItemTransformed);
      });
    return dataTransformed;
  }

  function transformUser(data: any[] | undefined) {
    const dataTransformed = [] as ColumnDatas[];
    data &&
      data?.map((item: any) => {
        let errors = checkItemError(item);
        const dataItemTransformed = {
          data: [
            {
              columnText: (
                <Box
                  width={44}
                  height={40}
                  alignItems="center"
                  justifyContent="center"
                >
                  {item.existUser ? (
                    <Icons
                      variant="line"
                      label="checkCircle"
                      stroke={getColor("COLOR_ICON_INTERACTIVE_SECONDARY")}
                    />
                  ) : errors.length === 0 ? (
                    <Icons
                      variant="line"
                      label="checkCircle"
                      stroke={getColor("COLOR_ICON_INTERACTIVE_BRAND")}
                    />
                  ) : (
                    <Tooltip
                      message={
                        <Box direction="column">
                          {errors.map((item) => (
                            <Typography exactColor={getColor("COLOR_WHITE")}>
                              - {item}
                            </Typography>
                          ))}
                        </Box>
                      }
                    >
                      <Icons
                        variant="line"
                        label="alertCircle"
                        stroke={getColor("COLOR_BG_ERROR")}
                      />
                    </Tooltip>
                  )}
                </Box>
              ),
            },
            {
              columnText: (
                <Tooltip message={item.userName}>
                  <Typography
                    exactColor={
                      item.exist
                        ? getColor("COLOR_TEXT_DISABLED")
                        : getColor("COLOR_TEXT_PRIMARY")
                    }
                  >
                    {item.userName}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              columnText: (
                <Tooltip message={item.userEmail}>
                  <Typography
                    exactColor={
                      item.exist
                        ? getColor("COLOR_TEXT_DISABLED")
                        : getColor("COLOR_TEXT_PRIMARY")
                    }
                  >
                    {item.userEmail}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              columnText: (
                <Tooltip message={item.deptCode}>
                  <Typography
                    exactColor={
                      item.exist
                        ? getColor("COLOR_TEXT_DISABLED")
                        : getColor("COLOR_TEXT_PRIMARY")
                    }
                  >
                    {item.deptCode}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              columnText: (
                <Typography
                  exactColor={
                    item.exist
                      ? getColor("COLOR_TEXT_DISABLED")
                      : getColor("COLOR_TEXT_PRIMARY")
                  }
                >
                  {item.jobTitle}
                </Typography>
              ),
            },
            {
              columnText: (
                <Typography
                  exactColor={
                    item.exist
                      ? getColor("COLOR_TEXT_DISABLED")
                      : getColor("COLOR_TEXT_PRIMARY")
                  }
                >
                  {item.managerFlag
                    ? t("common.button.yes")
                    : t("common.button.no")}
                </Typography>
              ),
            },
          ],
          id: String(item.userEmail),
          checkBoxClick: () => {},
        };
        dataTransformed.push(dataItemTransformed);
      });
    return dataTransformed;
  }

  return (
    <Modal
      title={
        type === "USER" ? t("user.userAddCsvTitle") : t("dept.deptAddCsvTitle")
      }
      onClose={onClose}
      width={816}
      height={validateResult ? 720 : 508}
      isFooterDivider={validateResult ? true : false}
      footer={
        <Box justifyContent="flex-end">
          <Button
            btnStyle="fill"
            type="secondary"
            onClick={onClose}
            label={t("common.button.cancel")}
          />
          <Button
            btnStyle="fill"
            classes="mg ml-8"
            type="primary"
            state={
              !file ||
              (validateResult &&
                validateResult?.filter(
                  (item) => checkItemError(item).length > 0
                )?.length > 0)
                ? "disable"
                : "default"
            }
            onClick={() =>
              type === "USER"
                ? handleUploadUserCSV(file)
                : handleUploadDeptCSV(file)
            }
            label={t("common.button.save")}
          />
        </Box>
      }
      open={isOpen}
      body={
        <Box
          direction="column"
          height={validateResult ? 540 : 328}
          width={"100%"}
        >
          <Box width={"100%"}>
            <InputField
              size="md"
              placeholder={t("common.button.selectFile")}
              value={file?.name}
              width={620}
            />
            <Button
              classes="mg ml-8"
              type="secondary"
              btnStyle="fill"
              onClick={() => iconInput.current?.click()}
              width={130}
              label={t("common.button.selectFile")}
            />
            <input
              style={{ display: "none" }}
              type="file"
              ref={iconInput}
              accept=".csv"
              onChange={(e) => loadDeptFile(e)}
              id={"fileUpload"}
            />
          </Box>
          {file && type === "USER" && (
            <Typography
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
              classes="mg mt-8"
            >
              {t("user.msg.userWarnDuplicationMsg")}
            </Typography>
          )}
          {!file ? (
            <Box
              direction="column"
              alignItems="center"
              justifyContent="center"
              height={"100%"}
            >
              <svg
                width="200"
                height="120"
                viewBox="0 0 200 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M150.74 99.6844L119.119 91.2157C114.185 89.8986 111.262 84.8266 112.579 79.8933L124.803 34.2573C126.12 29.324 131.192 26.401 136.125 27.7181L167.747 36.1867C172.68 37.5038 175.603 42.5758 174.286 47.5091L162.063 93.1451C160.734 98.0784 155.674 101.001 150.74 99.6844Z"
                  fill="#EBF0F2"
                />
                <path
                  d="M145.84 65.2082L153.731 67.2994L153.72 67.3341C152.553 71.7244 148.96 75.1788 144.466 75.8143C135.512 77.0851 129.238 68.9978 131.294 61.3147C132.947 55.1568 139.359 51.4597 145.517 53.1003L143.402 60.9912C142.906 62.8282 144.003 64.723 145.84 65.2082Z"
                  fill="#D0D5D8"
                />
                <path
                  d="M146.095 59.2676L148.036 52.0352C153.824 53.5949 157.302 59.6257 155.754 65.414V65.4256L148.521 63.4846C146.684 62.9878 145.598 61.0931 146.095 59.2676Z"
                  fill="#D0D5D8"
                />
                <path
                  d="M80.4833 91.204L48.8618 99.6727C43.9285 100.99 38.868 98.0667 37.5394 93.1334L25.3157 47.4974C23.9987 42.5641 26.9218 37.5037 31.8552 36.175L63.4767 27.7064C68.41 26.3893 73.4705 29.3123 74.7991 34.2456L87.0228 79.8816C88.3398 84.8149 85.4166 89.8869 80.4833 91.204Z"
                  fill="#EBF0F2"
                />
                <path
                  d="M71.7073 68.203L62.3258 59.457C60.9971 58.2208 58.8251 58.7985 58.2936 60.543L55.3937 70.0399L51.0843 66.0194C50.0676 65.072 48.404 65.511 47.9881 66.8512L45.1113 76.2442C44.6838 77.6652 45.9778 79.0054 47.3988 78.6126L57.4503 75.9207C57.4618 75.9207 57.4734 75.9091 57.485 75.9091C57.4965 75.9091 57.5081 75.9091 57.5196 75.9091L70.6442 72.3968C72.5389 71.8885 73.1168 69.5316 71.7073 68.203Z"
                  fill="#D0D5D8"
                />
                <path
                  d="M47.3537 62.1702C48.9681 62.1702 50.2768 60.8616 50.2768 59.2472C50.2768 57.6329 48.9681 56.3242 47.3537 56.3242C45.7394 56.3242 44.4307 57.6329 44.4307 59.2472C44.4307 60.8616 45.7394 62.1702 47.3537 62.1702Z"
                  fill="#D0D5D8"
                />
                <path
                  d="M116.175 85.739H83.4439C78.3373 85.739 74.2012 81.6029 74.2012 76.4963V29.2427C74.2012 24.1361 78.3373 20 83.4439 20H116.175C121.281 20 125.418 24.1361 125.418 29.2427V76.4963C125.418 81.6029 121.281 85.739 116.175 85.739Z"
                  fill="#ADCDFB"
                />
                <path
                  d="M116.884 56.8906H82.7217C82.1694 56.8906 81.7217 57.3383 81.7217 57.8906V58.5248C81.7217 59.0771 82.1694 59.5248 82.7217 59.5248H116.884C117.436 59.5248 117.884 59.0771 117.884 58.5248V57.8906C117.884 57.3383 117.436 56.8906 116.884 56.8906Z"
                  fill="white"
                />
                <path
                  d="M116.919 64.8242H82.7568C82.2046 64.8242 81.7568 65.2719 81.7568 65.8242V66.4584C81.7568 67.0107 82.2046 67.4584 82.7568 67.4584H116.919C117.471 67.4584 117.919 67.0107 117.919 66.4584V65.8242C117.919 65.2719 117.471 64.8242 116.919 64.8242Z"
                  fill="white"
                />
                <path
                  d="M116.918 72.5547H103.055C102.502 72.5547 102.055 73.0024 102.055 73.5547V74.6048C102.055 75.1571 102.502 75.6048 103.055 75.6048H116.918C117.47 75.6048 117.918 75.1571 117.918 74.6048V73.5547C117.918 73.0024 117.47 72.5547 116.918 72.5547Z"
                  fill="white"
                />
                <path
                  d="M87.1107 31.3672H83.248C82.4196 31.3672 81.748 32.0388 81.748 32.8672V49.404C81.748 50.2325 82.4196 50.904 83.248 50.904H87.1107C87.9391 50.904 88.6107 50.2325 88.6107 49.404V32.8672C88.6107 32.0388 87.9391 31.3672 87.1107 31.3672Z"
                  fill="#E8F3FF"
                />
                <path
                  d="M98.0267 39.1094H94.1641C93.3356 39.1094 92.6641 39.7809 92.6641 40.6094V49.4054C92.6641 50.2338 93.3356 50.9054 94.1641 50.9054H98.0267C98.8552 50.9054 99.5267 50.2338 99.5267 49.4054V40.6094C99.5267 39.7809 98.8552 39.1094 98.0267 39.1094Z"
                  fill="#E8F3FF"
                />
                <path
                  d="M108.935 45.1875H105.072C104.244 45.1875 103.572 45.8591 103.572 46.6875V49.3949C103.572 50.2233 104.244 50.8949 105.072 50.8949H108.935C109.764 50.8949 110.435 50.2233 110.435 49.3949V46.6875C110.435 45.8591 109.764 45.1875 108.935 45.1875Z"
                  fill="#E8F3FF"
                />
              </svg>
              <Typography
                textAlign={"center"}
                lineHeight="normal"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
                classes="mg mt-20"
                width={type === "USER" ? 500 : 600}
              >
                {type === "USER"
                  ? t("user.msg.userInfoCsvMsg")
                  : t("dept.msg.infoCsvMsg")}
              </Typography>
              <a
                href={
                  type === "USER"
                    ? `${process.env.PUBLIC_URL}/storage/user_template.csv`
                    : `${process.env.PUBLIC_URL}/storage/dept_template.csv`
                }
                hrefLang="ko"
                download={
                  type === "USER" ? "user_template.csv" : "dept_template.csv"
                }
                className="mg mt-20"
              >
                <Button
                  type="secondary"
                  btnStyle="fill"
                  onClick={() => {}}
                  leadingIcon={<Icons variant="line" label="download" />}
                  label={t("user.userDownloadCsvTemplate")}
                />
              </a>
            </Box>
          ) : type === "USER" ? (
            <Box
              direction="column"
              alignItems="center"
              justifyContent="center"
              classes="mg mt-20"
            >
              <Table
                key="userTable"
                border="none"
                columnHeight="md"
                data={transformUser(validateResult)}
                endReached={() => {}}
                header={[
                  {
                    headerText: "",
                    width: "44px",
                  },
                  {
                    headerText: t("user.userName"),
                    width: "151px",
                  },
                  {
                    headerText: t("user.userEmail"),
                    width: "217px",
                  },
                  {
                    headerText: t("dept.deptCode"),
                    width: "100px",
                  },
                  {
                    headerText: t("user.userPosition"),
                    width: "100px",
                  },
                  {
                    headerText: t("dept.managerFlag"),
                    width: "100px",
                  },
                ]}
                width={"100%"}
                height={"412px"}
              />
            </Box>
          ) : (
            <Box
              direction="column"
              alignItems="center"
              justifyContent="center"
              classes="mg mt-20"
            >
              <Table
                key="deptTable"
                border="none"
                columnHeight="md"
                data={transformDept(validateResult)}
                endReached={() => {}}
                header={[
                  {
                    headerText: "",
                    width: "44px",
                  },
                  {
                    headerText: t("dept.deptCode"),
                    width: "200px",
                  },
                  {
                    headerText: t("dept.deptName"),
                    width: "268px",
                  },
                  {
                    headerText: t("dept.deptHighLevelDeptCode"),
                    width: "200px",
                  },
                ]}
                width={"100%"}
                height={"440px"}
              />
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default InsertDeptByCSVModal;
