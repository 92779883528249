import {
  Modal as ModalNew,
  Box as BoxNew,
  Button as ButtonNew,
  Typography as TypographyNew,
  getColor,
  Icons,
  useToast,
} from "fds";

import {
  ReportReply,
  ReportReplyReqDTO,
  ReportReplyUpdateBean,
} from "../../../types/Reply";
import { useEffect, useState } from "react";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import MailBody from "../../../component/MailBody";
import CommonModal from "../../../component/CommonModal";
interface DetailTemplateModalProps {
  isOpen: boolean;
  targetId: number;
  onClose: () => void;
  filterData: ReportReplyReqDTO;
}
const DetailTemplateModal: React.FC<DetailTemplateModalProps> = ({
  isOpen,
  targetId,
  onClose,
  filterData,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { replyState, deleteReply, getReplys } = useReplyStore();
  const [body, changeBody] = useState("");
  const [targetReply, changeReply] = useState<ReportReplyUpdateBean>(
    {} as ReportReplyUpdateBean
  );

  const [openDeleteModal, toggleDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    changeReply(
      replyState?.replyListWithCnt?.list?.filter(
        (item: ReportReply) => item.replyId === targetId
      )[0]
    );
    changeBody(
      replyState?.replyListWithCnt?.list?.filter(
        (item: ReportReply) => item.replyId === targetId
      )[0].replyBody
    );
  }, [targetId]);

  const handleDeleteReply = async () => {
    let resp: any = await deleteReply([targetReply.replyId]);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("reply.msg.reportReplyDeleteSuccessMsg"),
        "success"
      );
      getReplys(filterData);
    } else {
      toast.toastMsg(nanoid(), t("reply.msg.answerTemplateDeleteMsg"), "error");
    }

    toggleDeleteModal(false);
  };

  const renderDeleteReplyModal = () => {
    return (
      <CommonModal
        title={"답변 템플릿 삭제"}
        open={openDeleteModal}
        onClose={() => {
          toggleDeleteModal(false);
          onClose();
        }}
        type={"delete"}
        body={
          <BoxNew width={"100%"}>
            <TypographyNew type="body2_rg">
              {t("template.msg.templateDeleteTemplateMsg")}
            </TypographyNew>
          </BoxNew>
        }
        afterSubmitButton={handleDeleteReply}
      />
    );
  };
  return (
    <>
      <ModalNew
        open={isOpen}
        onClose={onClose}
        width={816}
        title={t("common.button.detail")}
        body={
          <BoxNew direction="column" width={"100%"}>
            <BoxNew justifyContent="space-between">
              <BoxNew direction="column" width={"48%"}>
                <TypographyNew type="subtitle2">
                  {t("reply.reportTemplateName")}
                </TypographyNew>
                <TypographyNew type="body2_rg" classes="mg mt-8">
                  {targetReply?.replyTitle}
                </TypographyNew>
              </BoxNew>
              <BoxNew direction="column" width={"48%"}>
                <TypographyNew type="subtitle2">
                  {t("reply.reportReplyType")}
                </TypographyNew>

                <TypographyNew type="body2_rg" classes="mg mt-8">
                  {targetReply.autoYn
                    ? t("reply.reportReplyAuto")
                    : t("reply.reportReplyManual")}
                </TypographyNew>
              </BoxNew>
            </BoxNew>
            <BoxNew classes="mg mt-20">
              {/* <WSEdit
              initBody={targetReply?.replyBody}
              isShowButtons={false}
              onChange={changeBody}
            /> */}
              {targetReply.replyBody && (
                <MailBody body={targetReply.replyBody} height={474} />
              )}
            </BoxNew>
          </BoxNew>
        }
        footer={
          <BoxNew justifyContent="space-between" width={"100%"}>
            <BoxNew>
              <ButtonNew
                btnStyle={"link"}
                size={"lg"}
                classes="mg mr-4"
                leadingIcon={
                  <Icons
                    variant="line"
                    label="delete"
                    stroke={getColor("COLOR_ICON_PRIMARY")}
                  />
                }
                onClick={() => {
                  toggleDeleteModal(true);
                }}
                label={t("common.button.delete")}
              />
            </BoxNew>
            <BoxNew>
              <ButtonNew
                type="secondary"
                btnStyle="fill"
                onClick={onClose}
                label={t("common.button.update")}
              />
              <ButtonNew
                classes="mg ml-8"
                type="primary"
                btnStyle="fill"
                onClick={onClose}
                label={t("common.button.close")}
              />
            </BoxNew>
            {/* <ButtonNew
            type="primary"
            btnStyle="fill"
            onClick={() => handleRegitOrEditTemplate()}
            classes="mg ml-10"
            state={targetReply?.replyTitle ? "default" : "disable"}
            label={
              type === "CREATE"
                ? t("common.button.save")
                : t("common.button.save")
            }
          /> */}
          </BoxNew>
        }
      />
      {openDeleteModal && renderDeleteReplyModal()}
    </>
  );
};

export default DetailTemplateModal;
