import classNames from "classnames";

import "./index.scss";
import { CommonProps } from "fds/src/helpers/props";

export interface DividerProps extends CommonProps {
  direction?: "row" | "col";
  color?: string;
  width?: string;
}

const Divider = ({ direction, color, classes, width }: DividerProps) => {
  return (
    <div
      style={{ borderColor: color, width: width }}
      className={classNames(
        classes,
        direction === "row" ? "divider-row" : "divider-col"
      )}
    />
  );
};

export default Divider;
