import "./index.scss";
import "../../../shared/style/filters.scss";

import {
  Box as BoxNew,
  useToast as useToastNew,
  DropdownItem as DropdownItemNew,
  Dropdown as DropdownNew,
  Button as ButtonNew,
  Typography as TypographyNew,
  Icons as IconsNew,
  getColor,
  InputField,
  Chip,
  Table as TableNew,
  Tag as TagNew,
  Checkbox as CheckboxNew,
  Modal,
  CalendarRange,
  Tooltip,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NoTemplateImage from "../../../shared/image/NoTemplateImage";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { Scenario, ScenarioListReqDto } from "../../../types/Scenario";

import useCheckboxGroup from "../../../shared/utils/checkbox";
import { nanoid } from "@reduxjs/toolkit";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import { Category } from "../../../types/Info";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import { useTranslation } from "react-i18next";
import { FilterBtnActivityCheckUtils } from "../../../shared/utils/FilterBtnActivityCheckUtils";
import FilterCheckerUtils from "./FilterCheckerUtils";
import { useElementPosition } from "../../../hooks/useElementPosition";
import HoverClickBox from "../../../component/Table/HoverClickBox";
import useTableHoverBoxClick from "../../../hooks/useTableHoverBoxClick";
import IconMindSat from "../../../shared/image/icon";
import { useTableOrder } from "../../../hooks/useTableOrder";
import useNavigateState from "../../../hooks/useNavigateState";
import { NavigateStateKeys } from "../../../hooks/NavigateStateKeys";
import useTimedReset from "../../../hooks/useTimedReset";
import { TABLE_HIGHLIGHT_TIMED_OUT } from "../../../shared/constant";
import utilsCommon from "../../../shared/utils/common";
import dayjs from "dayjs";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";

enum ColumnsTypeToOrder {
  templateName = "templateName",
  templateUpdateDate = "templateUpdateDate",
}

export const ManageScenario: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const toast = useToastNew();

  const [templates, setTemplates] = useState<Scenario[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [hasData, setHasData] = useState(false);
  const { categoryState, getCategories } = useCategoryStore();
  const { mailTemplateState, getMailTemplateList, deleteMailTemplate } =
    useMailTemplateStore();
  const { syncMasterToCustomer } = useCustomerStore();
  const localCustomerCode = getLocalCustomerCode();
  const [tempCategoryName, setTempCategoryName] = useState("");
  const [tempCategorys, setTempCategorys] = useState<string[]>([]);
  const [tempTemplateName, setTempTemplateName] = useState("");
  const [tempTemplateNames, setTempTemplateNames] = useState<string[]>([]);
  const [buttonClick, setButtonClick] = useState<number>(0);
  const [scenarioType, setScenarioType] = useState({
    id: 0,
    text: t("common.auth.all"),
  });
  const [nameInput, setNameInput] = useState<string | null>(null);
  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setSearchFilter((searchFilter) => ({
          ...searchFilter,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        }));
    },
  });
  const [searchFilter, setSearchFilter] = useState<ScenarioListReqDto>({
    sendName: "",
    categorys: [],
    templateNames: [],
    siteId: true,
    imageId: true,
    documentId: true,
    orderId: getOrderParams().orderId,
    orderType: getOrderParams().orderType,
    isCommon: null,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<
    "USED" | "NOT_USED" | null
  >(null);
  const [selectedTemplates, setSelectedTemplates] = useState<number[]>([]);
  const [isUsedModal, setIsUsedModal] = useState<number>(0);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState<boolean>(false);
  const [scenarioTypes, setScenarioTypes] = useState([
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("template.public") },
    { id: 2, text: t("template.private") },
  ]);
  const [syncedTemplateIds, setSyncedTemplateIds] = useTimedReset<string[]>(
    [],
    TABLE_HIGHLIGHT_TIMED_OUT
  );

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const initialSelectedPeriod = {
    value: "ALL",
    start: new Date().valueOf(),
    end: new Date().valueOf(),
  };
  const [selectedPeriod, changeSelectedPeriod] = useState(
    initialSelectedPeriod
  );

  const checkboxGroup = useCheckboxGroup();
  const filterCateCheckbox = useCheckboxGroup();
  const filterContentsCheckbox = useCheckboxGroup();
  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const { navigateState } = useNavigateState(
    NavigateStateKeys.fromScenarioCreate
  );

  const {
    tableRef,
    hoverBoxClickedRow,
    hoverBoxNewCoord,
    onOutsideClick,
    handleHoverBoxClick,
  } = useTableHoverBoxClick<Scenario>({
    width: 116,
    getDynamicHeight: (row) =>
      checkIfDataEditableByCommonId(row.commonId) ? 147 : 58,
  });

  const CONTENT_TYPE = [
    { key: "link", resource: t("link.link") },
    { key: "image", resource: t("image.image") },
    { key: "attach", resource: t("attach.attach") },
  ];

  const onSync = async () => {
    const resp: any = await syncMasterToCustomer();
    if (resp.payload && resp.payload.code === "SUCCESS") {
      const templateIds = resp.payload.data?.map((template: Scenario) =>
        template.templateId.toString()
      );
      setSyncedTemplateIds(templateIds);
      toast.toastMsg(nanoid(), t("sync.msg.successMsg"), "success");
      getList();
    } else {
      toast.toastMsg(nanoid(), t("sync.msg.failMsg"), "error");
    }
    setIsSyncModalOpen(false);
  };

  const onDelete = async (e: MouseEvent) => {
    const resp: any = await deleteMailTemplate({
      request: {
        templateIds:
          selectedTemplates.length > 0
            ? selectedTemplates
            : (checkboxGroup.selected as number[]),
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateDeleteSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateNoDeletedTemplateMsg"),
        "error"
      );
    }

    checkboxGroup.handleAllClick([]);
    setSelectedTemplates([]);
    setIsDeleteModalOpen(null);
    onOutsideClick(e);
  };

  const getList = async () => {
    const resp: any = await getMailTemplateList(searchFilter);
    if (
      resp.payload &&
      resp.payload.code === "SUCCESS" &&
      resp.payload.data.total > 0
    ) {
      setHasData(true);
    }
  };

  const onSetFilter = () => {
    const filters = {
      ...searchFilter,
      siteId: filterContentsCheckbox.isSelected("link"),
      imageId: filterContentsCheckbox.isSelected("image"),
      documentId: filterContentsCheckbox.isSelected("attach"),
      isCommon:
        scenarioType.id === 0 ? null : scenarioType.id === 1 ? true : false,
      templateName: nameInput !== null && nameInput !== "" ? nameInput : null,
    };
    setSearchFilter(filters);
    if (filterCateCheckbox.selected?.length === 0) {
      filterCateCheckbox.handleAllClick(
        categoryList?.map((c) => c.categoryName) ?? []
      );
    }
    if (filterContentsCheckbox.selected.length === 0) {
      filterContentsCheckbox.handleAllClick(["link", "image", "attach"]);
    }
  };

  const resetFilter = () => {
    filterContentsCheckbox.handleAllClick(["link", "image", "attach"]);
    setNameInput(null);

    getMailTemplateList({
      sendName: "",
      categorys: [],
      templateNames: [],
      siteId: true,
      imageId: true,
      documentId: true,
      orderId: "templateName",
      orderType: "desc",
      isCommon: null,
      templateName: null,
    });
    setSearchFilter({
      sendName: "",
      categorys: [],
      templateNames: [],
      siteId: true,
      imageId: true,
      documentId: true,
      orderId: "templateName",
      orderType: "desc",
      isCommon: null,
      templateName: null,
    });
    setScenarioType({
      id: 0,
      text: t("common.auth.all"),
    });
    setTempCategorys([]);
    setTempTemplateNames([]);
    changeSelectedPeriod(initialSelectedPeriod);
    changeTempPeriod({
      start: dayjs()
        .subtract(1, "month")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .valueOf(),
      end: dayjs()
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59)
        .valueOf(),
    });
  };

  const changeDateFilter = (type: string) => {
    if (type === "ALL") {
      changeSelectedPeriod({
        value: type,
        start: new Date().valueOf(),
        end: new Date().valueOf(),
      });
    } else if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 12,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  const contentsFilter = () => {
    const selected = [];
    if (searchFilter.siteId) {
      selected.push(t("link.link"));
    }
    if (searchFilter.imageId) {
      selected.push(t("image.image"));
    }
    if (searchFilter.documentId) {
      selected.push(t("attach.attach"));
    }

    if (selected.length === 0 || selected.length === 3) {
      return t("common.auth.all");
    } else if (selected.length === 1) {
      return selected[0];
    } else if (selected.length > 1) {
      return `${selected[0]} ${t("common.msg.moreCnt", {
        length: selected.length - 1,
      })}`;
    }
  };

  function clickAllContentFilter() {
    if (filterContentsCheckbox.selected.length === CONTENT_TYPE.length) {
      filterContentsCheckbox.handleAllClick([]);
    } else {
      filterContentsCheckbox.handleAllClick(
        CONTENT_TYPE.map((item) => item.key)
      );
    }
  }

  function toggleAllContentFilter() {
    if (filterContentsCheckbox.selected.length === CONTENT_TYPE.length) {
      filterContentsCheckbox.handleAllClick([]);
    } else {
      clickAllContentFilter();
    }
  }

  function checkTableRenderStatus() {
    const isFilterApplied = hasData;
    const hasResult = templates && templates.length > 0;
    if (!hasResult && isFilterApplied) return 1;
    else if (!hasResult && !isFilterApplied) return 2;
    else return 0;
  }

  function renderTableSwitch() {
    switch (checkTableRenderStatus()) {
      case 1:
        return noFilteredResult;
      case 2:
        return noResultCreated;
      default:
        return <></>;
    }
  }

  function transformData(data: Scenario[]) {
    const dataTransformed = [];

    for (let i = 0; i < data.length; i++) {
      const template = data[i];
      const dataType = localCustomerCode ? (
        template.commonId ? (
          <TagNew
            as={"p"}
            type={"box"}
            text={t("template.public")}
            textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
            backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
          />
        ) : (
          <TagNew
            as={"p"}
            type={"box"}
            text={t("template.private")}
            textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
            backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
          />
        )
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
          backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
        />
      );
      const dataTemplateName = (
        <Tooltip message={template.templateName}>
          <TypographyNew
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            ellipsis
          >
            {template.templateName}
          </TypographyNew>
        </Tooltip>
      );
      const dataCategory = template.categorys && (
        <CategoryTagList categorys={template.categorys} />
      );

      const dataUpdateDate = (
        <TypographyNew
          type="body2_rg"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="tnum"
          ellipsis
        >
          {template.templateUpdateDate
            ? utilsCommon.getFullDateStr(template.templateUpdateDate)
            : utilsCommon.getFullDateStr(template.templateEntryDate)}
        </TypographyNew>
      );

      const dataSiteId = (
        <IconsNew
          variant="line"
          label={template.siteId ? "link" : "closeLine"}
          size={16.63}
          stroke={
            template.siteId
              ? getColor("COLOR_ICON_PRIMARY")
              : getColor("COLOR_ICON_INFO")
          }
        />
      );

      const dataImageId = template.imageId ? (
        <IconMindSat
          variant="line"
          label="gallery"
          size={16}
          stroke={
            template.imageId
              ? getColor("COLOR_ICON_PRIMARY")
              : getColor("COLOR_ICON_INFO")
          }
        />
      ) : (
        <IconsNew
          variant="line"
          label="closeLine"
          stroke={getColor("COLOR_ICON_INFO")}
        />
      );

      const dataDocumentId = (
        <IconsNew
          variant="line"
          label={template.documentId ? "link" : "closeLine"}
          stroke={
            template.documentId
              ? getColor("COLOR_ICON_PRIMARY")
              : getColor("COLOR_ICON_INFO")
          }
          size={16}
        />
      );

      const hoverBtn = (
        <ButtonNew
          classes="hover-btn"
          btnStyle={"icon"}
          leadingIcon={<IconsNew variant="line" label="moreVertical" />}
          type={"primary"}
          size={"sm"}
          state={"hover"}
          onClick={(event: MouseEvent) => handleHoverBoxClick(event, template)}
        />
      );

      const dataItemTransformed = {
        data: [
          { columnText: dataType },
          { columnText: dataTemplateName },
          { columnText: dataCategory },
          { columnText: dataUpdateDate },
          { columnText: dataSiteId },
          { columnText: dataImageId },
          { columnText: dataDocumentId },
        ],
        id: String(template.templateId),
        hover: hoverBtn,
        checkBoxClick: () => {
          checkboxGroup.handleClick(template.templateId);
        },
        clickAction: (e: any) => {
          if (!e.target.closest(".hover-btn")) {
            navigate(
              `${process.env.PUBLIC_URL}/manageTrain/scenario/detail/${template.templateId}`
            );
          }
        },
      };
      dataTransformed.push(dataItemTransformed);
    }
    return dataTransformed;
  }

  const filterChecker = new FilterCheckerUtils(scenarioType, searchFilter);

  const filters = (
    <BoxNew
      classes="pd pt-12 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      {/* 구분 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.type") + " · " + t("quiz.label.all")}
        value={
          filterChecker.checkType()
            ? scenarioType.id === 1
              ? t("quiz.table.type") + " · " + t("quiz.label.public")
              : t("quiz.table.type") + " · " + t("quiz.label.private")
            : ""
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        {scenarioTypes.map((item) => (
          <DropdownItemNew
            checked={item.id === scenarioType.id}
            checkColor={item.id === scenarioType.id}
            onClick={() => {
              setScenarioType(item);
              setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            }}
            isFullWidth
            label={item.text}
            type={"check"}
          />
        ))}
      </DropdownNew>
      {/* 시나리오  */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={
          t("template.templateSimple") + " · " + t("common.auth.all")
        }
        value={
          filterChecker.checkTemplate()
            ? t("template.templateSimple") +
              " · " +
              (searchFilter.templateNames || []).length
            : ""
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("template.templateSimple")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempTemplateName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("template.msg.namePlaceholder")}
              onChange={(e) => setTempTemplateName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (
                    tempTemplateNames &&
                    tempTemplateNames.includes(e.target.value)
                  ) {
                    return;
                  }
                  setTempTemplateNames((prev) => [...prev, tempTemplateName]);
                  setTempTemplateName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempTemplateNames &&
              tempTemplateNames.map((item) => (
                <Chip
                  key={`tempTemplateName-chip-${item}`}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempTemplateNames((prev) =>
                      prev.filter((q) => q !== item)
                    )
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempTemplateName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    searchFilter.templateNames || [],
                    tempTemplateNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempTemplateName !== "") {
                    setTempTemplateNames((prev) => [...prev, tempTemplateName]);
                    setSearchFilter((prev) => ({
                      ...prev,
                      templateNames: [...tempTemplateNames, tempTemplateName],
                    }));
                  } else {
                    setSearchFilter((prev) => ({
                      ...prev,
                      templateNames: tempTemplateNames,
                    }));
                  }
                  setTempTemplateName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>
      {/* 카테고리 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={
          t("category.categorySimple") + " · " + t("common.auth.all")
        }
        value={
          filterChecker.checkCategory()
            ? t("category.categorySimple") +
              " · " +
              (searchFilter.categorys || []).length
            : ""
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("category.categorySimple")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempCategoryName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("template.msg.categoryPlaceholder")}
              onChange={(e) => setTempCategoryName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (
                    searchFilter.categorys &&
                    searchFilter.categorys.includes(e.target.value)
                  ) {
                    return;
                  }
                  setTempCategorys((prev) => [...prev, tempCategoryName]);
                  setTempCategoryName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempCategorys &&
              tempCategorys.map((item) => (
                <Chip
                  key={`tempCategoryName-chip-${item}`}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempCategorys((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempCategoryName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    searchFilter.categorys || [],
                    tempCategorys
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempCategoryName !== "") {
                    setTempCategorys((prev) => [...prev, tempCategoryName]);
                    setSearchFilter((prev) => ({
                      ...prev,
                      categorys: [...tempCategorys, tempCategoryName],
                    }));
                  } else {
                    setSearchFilter((prev) => ({
                      ...prev,
                      categorys: tempCategorys,
                    }));
                  }
                  setTempCategoryName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>

      {/* 등록 일시 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.updateDate")}
        value={`${
          selectedPeriod.value === "PERIOD_3MONTH"
            ? t("report.stats.reportRecent3Month")
            : selectedPeriod.value === "PERIOD_6MONTH"
            ? t("report.stats.reportRecent6Month")
            : selectedPeriod.value === "PERIOD_1YEAR"
            ? t("report.stats.reportRecent1Year")
            : selectedPeriod.value === "ALL"
            ? t("common.auth.all")
            : t("graph.phishingCustomScope")
        }`}
        defaultValue={"ALL"}
        selectValue={selectedPeriod.value}
        showValue
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={selectedPeriod.value === "ALL"}
          checkColor={selectedPeriod.value === "ALL"}
          onClick={() => {
            changeDateFilter("ALL");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_3MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_3MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_6MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_6MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_1YEAR"}
          checkColor={selectedPeriod.value === "PERIOD_1YEAR"}
          onClick={() => {
            changeDateFilter("PERIOD_1YEAR");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItemNew
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_CUSTOM"}
          checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter("PERIOD_CUSTOM");
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </DropdownNew>

      {/* 콘텐츠 유형 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("template.contents") + " · " + contentsFilter()}
        value={
          filterChecker.checkAllContent()
            ? ""
            : t("template.contents") + " · " + contentsFilter()
        }
        buttonWidth={290}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew
            classes="dropdown-filter-title"
            justifyContent="space-between"
            alignItems="center"
          >
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("template.contents")}
            </TypographyNew>
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_BRAND")}
              classes="cursor-pointer"
              onClick={clickAllContentFilter}
              style={{ cursor: "pointer" }}
            >
              {t("common.input.selectAll")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="setting-button-list-body" height="fit-content">
            {CONTENT_TYPE.map((item) => (
              <BoxNew
                alignItems="center"
                classes="setting-button-list-body-item"
                height={40}
              >
                <CheckboxNew
                  onClick={() => filterContentsCheckbox.handleClick(item.key)}
                  check={filterContentsCheckbox.isSelected(item.key)}
                />
                <TypographyNew type="body2_rg" classes="mg ml-8">
                  {item.resource}
                </TypographyNew>
              </BoxNew>
            ))}
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body"></BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempCategoryName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  filterContentsCheckbox.selected.length === 0
                    ? "disable"
                    : "default"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={onSetFilter}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>
      {/* 초기화 */}
      <ButtonNew
        btnStyle={"icon"}
        state={filterChecker.checkAnyFilterSelected() ? "default" : "disable"}
        size={"md"}
        onClick={resetFilter}
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
      />
    </BoxNew>
  );

  const header = (
    <BoxNew classes="mg mt-32" justifyContent="space-between" width={"100%"}>
      <BoxNew alignItems="center">
        <TypographyNew
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("template.template")}
        </TypographyNew>
        <TypographyNew
          classes="mg ml-8"
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
        >
          {mailTemplateState.total}
        </TypographyNew>
      </BoxNew>
      <BoxNew>
        <BoxNew>
          <ButtonNew
            btnStyle={"link"}
            size={"lg"}
            classes="mg mr-4"
            state={checkboxGroup.selected.length > 0 ? "default" : "disable"}
            leadingIcon={
              <IconsNew
                variant="line"
                label="delete"
                stroke={getColor("COLOR_ICON_PRIMARY")}
              />
            }
            onClick={() => {
              let commonFlag = false;
              let usedFlag = false;
              checkboxGroup.selected.map((c) => {
                const item: Scenario[] = templates.filter(
                  (t) => t.templateId === c
                );

                item.forEach((i) => {
                  if (i.commonId) {
                    commonFlag = true;
                  }
                  if (i.useConfig && parseInt(i.useConfig) > 0) {
                    usedFlag = true;
                  }
                });
              });
              if (commonFlag) {
                toast.toastMsg(
                  nanoid(),
                  t("template.msg.cannotDeletePublicScenarioMsg"),
                  "error"
                );
              } else if (usedFlag) {
                setIsDeleteModalOpen("USED");
              } else {
                setIsDeleteModalOpen("NOT_USED");
              }
            }}
            label={t("common.button.delete")}
          />
        </BoxNew>
        <ButtonNew
          btnStyle="link"
          type="primary"
          leadingIcon={
            <IconsNew
              variant="line"
              label="filter"
              stroke={getColor("COLOR_ICON_PRIMARY")}
            />
          }
          onClick={() => {
            setIsFilterOpen((prev) => !prev);
          }}
          label={t("common.list.filter")}
        />
      </BoxNew>
    </BoxNew>
  );

  const noFilteredResult = (
    <BoxNew
      direction="column"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      style={{ paddingTop: "90px" }}
    >
      <NoSearchResultImage width={200} />
      <TypographyNew
        type="h3"
        classes="mg mt-36"
        exactColor={getColor("COLOR_TEXT_PRIMARY")}
      >
        {t("quiz.label.noSearch")}
      </TypographyNew>
      <TypographyNew
        type="body2_rg"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mt-8"
      >
        {t("template.msg.noFilterMsg")}
      </TypographyNew>
      <ButtonNew
        btnStyle={"fill"}
        label={t("common.button.resetFilter")}
        state={"default"}
        size={"lg"}
        type={"primary"}
        classes="mg mt-20"
        leadingIcon={<IconsNew variant="line" label="refresh" stroke="white" />}
        onClick={resetFilter}
      />
    </BoxNew>
  );

  const noResultCreated = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ paddingTop: "80px" }}
    >
      <NoTemplateImage />
      <TypographyNew
        type="h3"
        exactColor={getColor("COLOR_TEXT_PRIMARY")}
        classes="mg mt-20 mb-8"
      >
        {t("template.msg.noTemplateAlert")}
      </TypographyNew>
      <TypographyNew
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mb-20"
      >
        {t("template.msg.noTemplateMsg")}
      </TypographyNew>
      <ButtonNew
        btnStyle="fill"
        type="primary"
        leadingIcon={
          <IconsNew
            variant="line"
            label="plusCircle"
            stroke={getColor("COLOR_ICON_INVERSE")}
          />
        }
        onClick={() =>
          navigate(`${process.env.PUBLIC_URL}/manageTrain/scenario/write`)
        }
        label={t("template.add")}
      />
    </BoxNew>
  );

  useEffect(() => {
    filterContentsCheckbox.handleAllClick(["link", "image", "attach"]);
    getList();
  }, []);

  useEffect(() => {
    setSearchFilter({
      ...searchFilter,
      updateStartDate:
        selectedPeriod.value === "ALL"
          ? undefined
          : Math.trunc(selectedPeriod.start / 1000),
      updateEndDate:
        selectedPeriod.value === "ALL"
          ? undefined
          : Math.trunc(selectedPeriod.end / 1000),
    });
  }, [selectedPeriod]);
  useEffect(() => {
    getMailTemplateList(searchFilter);
    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
  }, [searchFilter]);

  useEffect(() => {
    onSetFilter();
  }, [scenarioType]);

  useEffect(() => {
    setTemplates(mailTemplateState.mailTemplateList);
    getCategories();
  }, [mailTemplateState.mailTemplateList]);

  useEffect(() => {
    setCategoryList(categoryState.categoryList);
    if (searchFilter.categorys?.length === 0) {
      filterCateCheckbox.handleAllClick(
        categoryState.categoryList?.map((item: Category) => item.categoryName)
      );
    }
  }, [categoryState.categoryList]);

  useEffect(() => {
    setScenarioTypes([
      { id: 0, text: t("common.auth.all") },
      { id: 1, text: t("template.public") },
      { id: 2, text: t("template.private") },
    ]);
    setScenarioType((prev) => ({
      ...prev,
      text:
        prev.id === 0
          ? t("common.auth.all")
          : scenarioTypes.filter((s) => s.id === prev.id)[0].text,
    }));
  }, [i18n.language]);

  return (
    <BoxNew classes="main manageScenarioCategory" ref={tableContainerRef}>
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath
            path={[t("menu.trainingManagement"), t("menu.template")]}
          />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.template")}
          </TypographyNew>
        </BoxNew>

        <BoxNew>
          {localCustomerCode && (
            <ButtonNew
              leadingIcon={
                <IconsNew variant="line" label="refresh" size={18} />
              }
              type="secondary"
              size="lg"
              btnStyle="fill"
              onClick={() => setIsSyncModalOpen(true)}
              classes="mg mr-10"
              label={t("sync.updateSimple")}
            />
          )}
          <ButtonNew
            leadingIcon={
              <IconsNew
                variant="line"
                label="plusCircle"
                stroke={getColor("COLOR_ICON_PRIMARY")}
                size={18}
              />
            }
            type="secondary"
            size="lg"
            btnStyle="fill"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/manageTrain/scenario/write`)
            }
            label={t("template.insert")}
          />
        </BoxNew>
      </BoxNew>
      <BoxNew direction="column">
        {header}
        {isFilterOpen && filters}
        <BoxNew
          direction="column"
          classes="pd pt-12 flex-1"
          ref={tableRef}
          style={{
            pointerEvents: hoverBoxClickedRow.templateId ? "none" : undefined,
          }}
        >
          <TableNew
            classes="table-chip-hover"
            border="none"
            clickIds={checkboxGroup.selected.map((templateId) =>
              String(templateId)
            )}
            highlightIds={
              navigateState?.length ? navigateState : syncedTemplateIds
            }
            totalCount={mailTemplateState.total}
            highlightTimeout={3000}
            columnHeight="md"
            data={transformData(templates)}
            endReached={() => {}}
            checkBoxClick={() =>
              checkboxGroup.handleAllClick(
                checkboxGroup.selected.length === templates.length
                  ? []
                  : templates.map((q) => q.templateId)
              )
            }
            header={[
              {
                headerText: t("template.type"),
                width: "72px",
              },
              {
                headerText: t("template.name"),
                width: "304px",
                ...getOrderRenderProps(ColumnsTypeToOrder.templateName),
              },
              {
                headerText: t("category.categorySimple"),
                width: "calc(100% - 850px)",
              },
              {
                headerText: t("common.time.updateDate"),
                width: "172px",
                ...getOrderRenderProps(ColumnsTypeToOrder.templateUpdateDate),
              },
              {
                headerText: t("link.linkSimple"),
                width: "64px",
              },
              {
                headerText: t("image.imageSimple"),
                width: "64px",
              },
              {
                headerText: t("attach.attachSimple"),
                width: "112px",
              },
            ]}
            width={"100%"}
            height={
              checkTableRenderStatus() === 0
                ? `${
                    tableContainerPosition.height -
                    (245 + (isFilterOpen ? 56 : 0))
                  }px`
                : "0px"
            }
          />
          {renderTableSwitch()}
          <HoverClickBox
            isDisplayed={!!hoverBoxClickedRow.templateId}
            hoverBoxNewCoord={hoverBoxNewCoord}
            onOutsideClick={onOutsideClick}
            onClickDetail={() =>
              navigate(
                `${process.env.PUBLIC_URL}/manageTrain/scenario/detail/${hoverBoxClickedRow.templateId}`
              )
            }
            onClickDelete={
              checkIfDataEditableByCommonId(hoverBoxClickedRow.commonId)
                ? () => {
                    setSelectedTemplates([hoverBoxClickedRow.templateId]);
                    if (
                      hoverBoxClickedRow.useConfig &&
                      parseInt(hoverBoxClickedRow.useConfig) > 0
                    ) {
                      setIsDeleteModalOpen("USED");
                    } else {
                      setIsDeleteModalOpen("NOT_USED");
                    }
                  }
                : undefined
            }
            onClickUpdate={
              checkIfDataEditableByCommonId(hoverBoxClickedRow.commonId)
                ? () => {
                    if (
                      hoverBoxClickedRow.useConfig &&
                      parseInt(hoverBoxClickedRow.useConfig) > 0
                    ) {
                      setIsUsedModal(hoverBoxClickedRow.templateId);
                    } else {
                      navigate(
                        `/manageTrain/scenario/update/${hoverBoxClickedRow.templateId}`
                      );
                    }
                  }
                : undefined
            }
          />
        </BoxNew>
      </BoxNew>

      <Modal
        open={!!isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(null)}
        title={t("template.delete")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              label={t("common.button.cancel")}
              btnStyle="fill"
              type="secondary"
              onClick={() => setIsDeleteModalOpen(null)}
            />
            <ButtonNew
              label={t("common.button.delete")}
              btnStyle="fill"
              type="error"
              onClick={onDelete}
            />
          </BoxNew>
        }
        body={
          <TypographyNew
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {isDeleteModalOpen === "USED"
              ? t("template.msg.templateDeleteUsedTemplateMsg")
              : t("template.msg.templateDeleteConfirmMsg")}
          </TypographyNew>
        }
      />

      <Modal
        open={isUsedModal > 0}
        width={540}
        onClose={() => setIsUsedModal(0)}
        title={t("template.updateSimple")}
        bodyHeight={45}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              label={t("common.button.cancel")}
              btnStyle="fill"
              type="secondary"
              onClick={() => setIsUsedModal(0)}
            />
            <ButtonNew
              label={t("common.button.update")}
              btnStyle="fill"
              type="primary"
              onClick={(e: MouseEvent) => {
                navigate(`/manageTrain/scenario/update/${isUsedModal}`);
                onOutsideClick(e);
              }}
            />
          </BoxNew>
        }
        body={
          <BoxNew direction="column" style={{ lineHeight: "1.5" }}>
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("template.msg.templateUpdateUsedTemplateMsg")}
            </TypographyNew>
          </BoxNew>
        }
      />

      <Modal
        open={isSyncModalOpen}
        onClose={() => setIsSyncModalOpen(false)}
        title={t("sync.update")}
        width={540}
        bodyHeight={30}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              label={t("common.button.cancel")}
              btnStyle="fill"
              type="secondary"
              onClick={() => setIsSyncModalOpen(false)}
            />

            <ButtonNew
              label={t("common.button.update2")}
              btnStyle="fill"
              type="primary"
              onClick={(e: MouseEvent) => {
                onSync();
                setIsSyncModalOpen(false);
                onOutsideClick(e);
              }}
            />
          </BoxNew>
        }
        body={
          <TypographyNew
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.msg.syncScenarioMsg")}
          </TypographyNew>
        }
      />

      {openDateModal && dateModal()}
    </BoxNew>
  );
};

export default ManageScenario;
