import { useEffect, useRef, useState } from "react";

export default function useOutsideClick(
  onOutsideClickCb: (event: MouseEvent) => void // 바깥 클릭 콜백 함수
) {
  const targetRef = useRef<HTMLElement>(null); // 클릭을 감지할 요소 ref
  const [isOutsideClicked, setIsOutsideClicked] = useState(false); // 바깥 요소 클릭 여부

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        targetRef.current &&
        !targetRef.current.contains(event.target as Node)
      ) {
        if (!document.querySelector(".fds-modal-backdrop-root.on")) {
          // 모달이 띄워진 경우 콜백 호출 제한
          onOutsideClickCb(event);
        }
        setIsOutsideClicked(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [targetRef, onOutsideClickCb]);

  return [targetRef, isOutsideClicked];
}
