import {
  Box,
  getColor,
  Icons,
  Button,
  Tag,
  Typography,
  useToast,
  Table as TableNew,
  Dropdown,
  InputField,
  DropdownItem,
  Chip,
  Tooltip,
} from "fds";

import "../../../shared/style/filters.scss";

import TitlePath from "../../../component/TitlePath";
import { useEffect, useRef, useState } from "react";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import { SiteInfo } from "../../../types/Info";
import RegitOrEditSiteModal from "./RegitOrEditSiteModal";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import ResourceListModal from "./ResourceListModal";
import { useTranslation } from "react-i18next";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import HoverClickBox from "../../../component/Table/HoverClickBox";
import { useElementPosition } from "../../../hooks/useElementPosition";
import useTableHoverBoxClick from "../../../hooks/useTableHoverBoxClick";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import { FilterBtnActivityCheckUtils } from "../../../shared/utils/FilterBtnActivityCheckUtils";
import IconMindSat from "../../../shared/image/icon";
import { useTableOrder } from "../../../hooks/useTableOrder";
import dayjs from "dayjs";
import utilsCommon from "../../../shared/utils/common";
import DateModal, { DateType } from "../../../component/DateModal";
import useTimedReset from "../../../hooks/useTimedReset";
import { TABLE_HIGHLIGHT_TIMED_OUT } from "../../../shared/constant";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";
import NoTrainingContentImage from "../../../shared/image/NoTrainingContentImage";

enum ColumnsTypeToOrder {
  siteName = "siteName",
  siteEntryDate = "siteEntryDate",
  siteUpdateDate = "siteUpdateDate",
}

const TrainingSite: React.FC = () => {
  const { t } = useTranslation();
  const [sites, setSites] = useState<SiteInfo[]>([]);
  const [sitesTotal, setSitesTotal] = useState<number>(0);

  const [isRegitModalOpen, setIsRegitModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState<
    "LIST" | "DELETE" | "INSERT" | "NONE"
  >("NONE");
  const [isCreateDateModalOpen, setIsCreateDateModalOpen] =
    useState<boolean>(false);
  const [isUpdateDateModalOpen, setIsUpdateDateModalOpen] =
    useState<boolean>(false);

  const initialSearchParams = useRef("");
  const [rowIdCreated, setRowIdCreated] = useTimedReset(
    "",
    TABLE_HIGHLIGHT_TIMED_OUT
  );

  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setSearchFilter({
          ...searchFilter,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        });
    },
  });

  const [selectedSites, setSelectedSites] = useState<number[]>([]);

  const { contentState, getSites, deleteSite } = useContentStore();
  const localCustomerCode = getLocalCustomerCode();
  const toast = useToast();
  const checkboxGroup = useCheckboxGroup();

  const onDelete = async () => {
    const resp: any = await deleteSite({
      request: {
        siteIds:
          selectedSites.length > 0
            ? selectedSites
            : (checkboxGroup.selected as number[]),
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("link.msg.linkDeleteSuccessMsg"), "success");
    } else if (resp.payload && resp.payload.code === "DATA_USED") {
      toast.toastMsg(
        nanoid(),
        t("link.msg.linkDeleteUsedTemplateFailMsg"),
        "error"
      );
    } else {
      toast.toastMsg(nanoid(), t("link.msg.linkDeleteFailMsg"), "error");
    }

    checkboxGroup.handleAllClick([]);
    setSelectedSites([]);
    setIsDeleteModalOpen(false);
  };

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const {
    tableRef,
    hoverBoxClickedRow,
    hoverBoxNewCoord,
    onOutsideClick,
    handleHoverBoxClick,
  } = useTableHoverBoxClick<SiteInfo>({
    width: 116,
    height: 107,
  });
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const header = (
    <Box classes="mg mt-32" justifyContent="space-between" width={"100%"}>
      <Box alignItems="center">
        <Typography
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("link.linkList")}
        </Typography>
        <Typography
          classes="mg ml-8"
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_BRAND")}
        >
          {sitesTotal}
        </Typography>
      </Box>

      <Box>
        <Button
          btnStyle="link"
          state={checkboxGroup.selected.length > 0 ? "default" : "disable"}
          type="secondary"
          leadingIcon={<Icons variant="line" label="delete" />}
          classes="mg mr-4"
          onClick={() => {
            let commonFlag = false;
            checkboxGroup.selected.map((c) => {
              const item: SiteInfo[] = sites.filter((t) => t.siteId === c);

              item.map((i) => {
                if (i.commonId) {
                  commonFlag = true;
                }
              });
            });
            if (commonFlag) {
              toast.toastMsg(
                nanoid(),
                t("common.msg.cannotDeletePublicContentsMsg"),
                "error"
              );
              return;
            }
            setIsDeleteModalOpen(true);
          }}
          label={t("common.button.delete")}
        />
        <Button
          btnStyle="link"
          type="primary"
          leadingIcon={
            <Icons
              variant="line"
              label="filter"
              stroke={getColor("COLOR_ICON_PRIMARY")}
            />
          }
          onClick={() => {
            setIsFilterOpen((prev) => !prev);
          }}
          label={t("common.list.filter")}
        />
      </Box>
    </Box>
  );

  const [buttonClick, setButtonClick] = useState<number>(0);

  const initialTempSearchFilter = {
    commonId: 0,
    siteNameInput: "",
    siteNames: [],
    siteUrlInput: "",
    siteUrls: [],
    createStart: new Date().valueOf(),
    createEnd: new Date().valueOf(),
    updateStart: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    updateEnd: dayjs()
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .valueOf(),
    createType: DateType.ALL,
    updateType: DateType.ALL,
  };

  const [tempSearchFilter, setTempSearchFilter] = useState<{
    commonId: number;
    siteNameInput: string;
    siteNames: string[];
    siteUrlInput: string;
    siteUrls: string[];
    createStart: number | undefined;
    createEnd: number | undefined;
    updateStart: number | undefined;
    updateEnd: number | undefined;
    createType: string;
    updateType: string;
  }>(initialTempSearchFilter);

  const initialSearchFilter = {
    commonId: undefined,
    siteNames: [],
    siteUrls: [],
    createStart: undefined,
    createEnd: undefined,
    orderId: getOrderParams().orderId,
    orderType: getOrderParams().orderType,
    updateStart: undefined,
    updateEnd: undefined,
  };

  const [searchFilter, setSearchFilter] = useState<{
    commonId: boolean | undefined;
    siteNames: string[];
    siteUrls: string[];
    createStart: number | undefined;
    createEnd: number | undefined;
    updateStart: number | undefined;
    updateEnd: number | undefined;
    orderId: string;
    orderType: string;
  }>(initialSearchFilter);

  const FILTER_TYPE = {
    // id: 서버 구분자
    ALL: { id: undefined, text: t("common.auth.all") },
    PUBLIC: { id: true, text: t("template.public") },
    PRIVATE: { id: false, text: t("template.private") },
  };

  function checkAnyFilterSelected() {
    return (
      searchFilter.commonId !== FILTER_TYPE.ALL.id ||
      searchFilter.siteNames.length > 0 ||
      searchFilter.siteUrls.length > 0 ||
      tempSearchFilter.createType !== DateType.ALL ||
      searchFilter.createStart ||
      searchFilter.createEnd ||
      tempSearchFilter.updateType !== DateType.ALL ||
      searchFilter.updateStart ||
      searchFilter.updateEnd
    );
  }

  const changeDateFilter = (type: DateType, order: "CREATE" | "UPDATE") => {
    let newMonth = 0;
    if (type === DateType.PERIOD_3MONTH) {
      newMonth = 3;
    } else if (type === DateType.PERIOD_6MONTH) {
      newMonth = 6;
    } else if (type === DateType.PERIOD_1YEAR) {
      newMonth = 12;
    }

    const start = dayjs().subtract(newMonth, "month").add(1, "day").valueOf();
    const end = dayjs()
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .valueOf();

    if (order === "CREATE") {
      if (type === DateType.ALL) {
        setSearchFilter((d) => ({
          ...d,
          createStart: undefined,
          createEnd: undefined,
        }));
      } else if (type === DateType.PERIOD_CUSTOM) {
        // pass
      } else {
        setSearchFilter((d) => ({
          ...d,
          createStart: start,
          createEnd: end,
        }));
      }
      setTempSearchFilter((d) => ({
        ...d,
        createType: type,
      }));
    } else {
      // UPDATE
      if (type === DateType.ALL) {
        setSearchFilter((d) => ({
          ...d,
          updateStart: undefined,
          updateEnd: undefined,
        }));
      } else if (type === DateType.PERIOD_CUSTOM) {
        // pass
      } else {
        setSearchFilter((d) => ({
          ...d,
          updateStart: start,
          updateEnd: end,
        }));
      }
      setTempSearchFilter((d) => ({
        ...d,
        updateType: type,
      }));
    }
  };

  const filters = (
    <Box classes="pd pt-12 gap-8 dropdown-filter-container" alignItems="center">
      {/* 구분 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.type") + " · " + t("quiz.label.all")}
        value={
          searchFilter.commonId === FILTER_TYPE.PUBLIC.id
            ? t("quiz.table.type") + " · " + t("quiz.label.public")
            : searchFilter.commonId === FILTER_TYPE.PRIVATE.id
            ? t("quiz.table.type") + " · " + t("quiz.label.private")
            : ""
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        {Object.values(FILTER_TYPE).map((item) => (
          <DropdownItem
            checked={item.id === searchFilter.commonId}
            checkColor={item.id === searchFilter.commonId}
            onClick={() => {
              setSearchFilter((prev) => ({
                ...prev,
                commonId: item.id,
              }));
              setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            }}
            isFullWidth
            label={item.text}
            type={"check"}
          />
        ))}
      </Dropdown>

      {/* 링크 이름 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("link.linkNameSimple") + " · " + t("common.auth.all")}
        value={
          searchFilter.siteNames.length > 0
            ? t("link.linkNameSimple") + " · " + searchFilter.siteNames.length
            : ""
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <Box direction={"column"} classes="dropdown-filter">
          <Box classes="dropdown-filter-title">
            <Typography
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("link.linkNameSimple")}
            </Typography>
          </Box>
          <Box classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempSearchFilter.siteNameInput}
              leadingIcon={<Icons variant="line" label="search" size={20} />}
              placeholder={t("template.msg.categoryPlaceholder")}
              onChange={(e) => {
                setTempSearchFilter((d) => ({
                  ...d,
                  siteNameInput: e.target.value,
                }));
              }}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  setTempSearchFilter((d) => ({
                    ...d,
                    siteNames: [...d.siteNames, d.siteNameInput],
                  }));
                  setTempSearchFilter((d) => ({ ...d, siteNameInput: "" }));
                }
              }}
            />
          </Box>
          <Box classes="gap-10 dropdown-filter-body">
            {tempSearchFilter.siteNames.map((item) => (
              <Chip
                key={`tempCategoryName-chip-${item}`}
                type={"fill"}
                size={"sm"}
                showClose={true}
                text={item}
                onClick={() =>
                  setTempSearchFilter((d) => ({
                    ...d,
                    siteNames: d.siteNames.filter((q) => q !== item),
                  }))
                }
              />
            ))}
          </Box>
          <Box
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <Box>
              <Button
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <Button
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    searchFilter.siteNames || [],
                    tempSearchFilter.siteNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  setSearchFilter((prev) => ({
                    ...prev,
                    siteNames: tempSearchFilter.siteNames,
                  }));
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Dropdown>

      {/* 주소 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("link.generatedUrl") + " · " + t("common.auth.all")}
        value={
          searchFilter.siteUrls.length > 0
            ? t("link.linkUrl") + " · " + searchFilter.siteUrls.length
            : ""
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <Box direction={"column"} classes="dropdown-filter">
          <Box classes="dropdown-filter-title">
            <Typography
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("link.linkUrl")}
            </Typography>
          </Box>
          <Box classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempSearchFilter.siteUrlInput}
              leadingIcon={<Icons variant="line" label="search" size={20} />}
              placeholder={t("template.msg.categoryPlaceholder")}
              onChange={(e) => {
                setTempSearchFilter((d) => ({
                  ...d,
                  siteUrlInput: e.target.value,
                }));
              }}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  setTempSearchFilter((d) => ({
                    ...d,
                    siteUrls: [...d.siteUrls, d.siteUrlInput],
                  }));
                  setTempSearchFilter((d) => ({ ...d, siteUrlInput: "" }));
                }
              }}
            />
          </Box>
          <Box classes="gap-10 dropdown-filter-body">
            {tempSearchFilter.siteUrls.map((item) => (
              <Chip
                key={`tempCategoryName-chip-${item}`}
                type={"fill"}
                size={"sm"}
                showClose={true}
                text={item}
                onClick={() =>
                  setTempSearchFilter((d) => ({
                    ...d,
                    siteUrls: d.siteUrls.filter((q) => q !== item),
                  }))
                }
              />
            ))}
          </Box>
          <Box
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <Box>
              <Button
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <Button
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    searchFilter.siteUrls || [],
                    tempSearchFilter.siteUrls
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  setSearchFilter((prev) => ({
                    ...prev,
                    siteUrls: tempSearchFilter.siteUrls,
                  }));
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Dropdown>

      {/* 등록 일자 */}
      {/* <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.addDate") + " · " + t("common.auth.all")}
        value={`${
          tempSearchFilter.createType === "PERIOD_3MONTH"
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent3Month")
            : tempSearchFilter.createType === "PERIOD_6MONTH"
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent6Month")
            : tempSearchFilter.createType === "PERIOD_1YEAR"
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent1Year")
            : tempSearchFilter.createType === "ALL"
            ? ""
            : t("common.time.addDate") + " · " + t("graph.phishingCustomScope")
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.ALL}
          checkColor={tempSearchFilter.createType === DateType.ALL}
          onClick={() => {
            changeDateFilter(DateType.ALL, "CREATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.PERIOD_3MONTH}
          checkColor={tempSearchFilter.createType === DateType.PERIOD_3MONTH}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_3MONTH, "CREATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.PERIOD_6MONTH}
          checkColor={tempSearchFilter.createType === DateType.PERIOD_6MONTH}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_6MONTH, "CREATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.createType === DateType.PERIOD_1YEAR}
          checkColor={tempSearchFilter.createType === DateType.PERIOD_1YEAR}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_1YEAR, "CREATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />

        <DropdownItem
          checked={tempSearchFilter.createType === DateType.PERIOD_CUSTOM}
          checkColor={tempSearchFilter.createType === DateType.PERIOD_CUSTOM}
          onClick={() => setIsCreateDateModalOpen(true)}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown> */}

      {/* 수정 일자 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.updateDate") + " · " + t("common.auth.all")}
        value={`${
          tempSearchFilter.updateType === "PERIOD_3MONTH"
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent3Month")
            : tempSearchFilter.updateType === "PERIOD_6MONTH"
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent6Month")
            : tempSearchFilter.updateType === "PERIOD_1YEAR"
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent1Year")
            : tempSearchFilter.updateType === "ALL"
            ? ""
            : t("common.time.updateDate") +
              " · " +
              `${utilsCommon.getShortDate(searchFilter.updateStart)}
            - ${utilsCommon.getShortDate(searchFilter.updateEnd)}`
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.ALL}
          checkColor={tempSearchFilter.updateType === DateType.ALL}
          onClick={() => {
            changeDateFilter(DateType.ALL, "UPDATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.PERIOD_3MONTH}
          checkColor={tempSearchFilter.updateType === DateType.PERIOD_3MONTH}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_3MONTH, "UPDATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.PERIOD_6MONTH}
          checkColor={tempSearchFilter.updateType === DateType.PERIOD_6MONTH}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_6MONTH, "UPDATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.PERIOD_1YEAR}
          checkColor={tempSearchFilter.updateType === DateType.PERIOD_1YEAR}
          onClick={() => {
            changeDateFilter(DateType.PERIOD_1YEAR, "UPDATE");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />

        <DropdownItem
          checked={tempSearchFilter.updateType === DateType.PERIOD_CUSTOM}
          checkColor={tempSearchFilter.updateType === DateType.PERIOD_CUSTOM}
          onClick={() => setIsUpdateDateModalOpen(true)}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown>

      {/* 초기화 */}
      <Button
        btnStyle={"icon"}
        size={"md"}
        state={checkAnyFilterSelected() ? "default" : "disable"}
        onClick={resetFilter}
        leadingIcon={
          <Icons
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
      />
    </Box>
  );

  function transformData(data: SiteInfo[]) {
    const dataTransformed = [];

    for (let i = 0; i < data.length; i++) {
      const siteInfo = data[i];
      const dataType = localCustomerCode ? (
        siteInfo.commonId ? (
          <Tag
            as={"p"}
            type={"box"}
            text={t("template.public")}
            textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
            backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
          />
        ) : (
          <Tag
            as={"p"}
            type={"box"}
            text={t("template.private")}
            textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
            backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
          />
        )
      ) : (
        <Tag
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
          backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
        />
      );
      const dataLinkName = (
        <Tooltip message={siteInfo.siteName}>
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            ellipsis
            maxWidth={286}
          >
            {siteInfo.siteName}
          </Typography>
        </Tooltip>
      );
      const dataUrl = (
        <Tooltip message={siteInfo.siteUrl}>
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            ellipsis
          >
            {siteInfo.siteUrl}
          </Typography>
        </Tooltip>
      );

      const dataDocumentUpdateDate = (
        <Typography
          type="body2_rg"
          classes="tnum"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {siteInfo.siteUpdateDate
            ? utilsCommon.getFullDateStr(siteInfo.siteUpdateDate)
            : ""}
        </Typography>
      );

      const hoverBtn = (
        <Button
          btnStyle={"icon"}
          leadingIcon={<Icons variant="line" label="moreVertical" />}
          type={"primary"}
          size={"sm"}
          state={"hover"}
          onClick={(event: MouseEvent) => handleHoverBoxClick(event, siteInfo)}
        />
      );

      const dataItemTransformed = {
        data: [
          { columnText: dataType },
          { columnText: dataLinkName },
          { columnText: dataUrl },
          { columnText: dataDocumentUpdateDate },
        ],
        id: String(siteInfo.siteId),
        hover: checkIfDataEditableByCommonId(siteInfo.commonId) && hoverBtn,
        checkBoxClick: () => {
          checkboxGroup.handleClick(siteInfo.siteId);
        },
      };
      dataTransformed.push(dataItemTransformed);
    }
    return dataTransformed;
  }

  function checkTableRenderStatus() {
    const isFilterApplied = checkAnyFilterSelected();
    const hasResult = sites && sites.length > 0;
    if (!hasResult && isFilterApplied) return 1;
    else if (!hasResult && !isFilterApplied) return 2;
    else return 0;
  }

  function renderTableSwitch() {
    switch (checkTableRenderStatus()) {
      case 1:
        return noFilteredResult;
      case 2:
        return noResultCreated;
      default:
        return <></>;
    }
  }

  const noFilteredResult = (
    <Box
      direction="column"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      style={{ paddingTop: "90px" }}
    >
      <NoSearchResultImage width={200} />
      <Typography type="h3" classes="mg mt-20">
        {t("link.msg.noSearchResult")}
      </Typography>
      <Typography
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        type="body2_rg"
        classes="mg mt-8 mb-24"
      >
        {t("link.msg.noSearchResultMsg")}
      </Typography>
      <Button
        btnStyle={"fill"}
        label={t("common.button.resetFilter")}
        state={"default"}
        size={"lg"}
        type={"primary"}
        classes="mg mt-30"
        leadingIcon={<Icons variant="line" label="refresh" stroke="white" />}
        onClick={resetFilter}
      />
    </Box>
  );

  function resetFilter() {
    setSearchFilter(initialSearchFilter);
    setTempSearchFilter(initialTempSearchFilter);
  }

  const noResultCreated = (
    <Box
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoTrainingContentImage />
      <Typography type="h3" classes="mg mt-20">
        {t("link.msg.noData")}
      </Typography>
      <Typography
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        type="body2_rg"
        classes="mg mt-24 mb-24"
      >
        {t("link.msg.noDataMsg")}
      </Typography>
      <Button
        btnStyle="fill"
        leadingIcon={
          <Icons
            variant="line"
            label="plusCircle"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
        type="primary"
        onClick={() => setIsRegitModalOpen(true)}
        label={t("link.linkAdd")}
        size="lg"
      />
    </Box>
  );

  useEffect(() => {
    if (initialSearchParams.current !== JSON.stringify(searchFilter)) {
      // 파라미터가 동일할 때 재호출 제한
      getSites({
        orderId: searchFilter.orderId,
        orderType: searchFilter.orderType,
        isCommon: searchFilter.commonId,
        siteNames: searchFilter.siteNames,
        siteUrls: searchFilter.siteUrls,
        createStartDate:
          searchFilter.createStart &&
          Math.trunc(searchFilter.createStart / 1000),
        createEndDate:
          searchFilter.createEnd && Math.trunc(searchFilter.createEnd / 1000),
        updateStartDate:
          searchFilter.updateStart &&
          Math.trunc(searchFilter.updateStart / 1000),
        updateEndDate:
          searchFilter.updateEnd && Math.trunc(searchFilter.updateEnd / 1000),
      });
      initialSearchParams.current = JSON.stringify(searchFilter);
    }
  }, [searchFilter]);

  useEffect(() => {
    setSites(contentState.sites);
    setSitesTotal(contentState.sitesTotal);
  }, [contentState.sites]);

  return (
    <Box classes="main manageScenarioMgnt" ref={tableContainerRef}>
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[
              t("menu.trainingManagement"),
              t("menu.templateContentsSetting"),
              t("link.link"),
            ]}
          />
          <Typography classes="mg mt-8" type="h1">
            {t("link.link")}
          </Typography>
        </Box>
        <Box>
          <Button
            btnStyle="fill"
            type="secondary"
            classes="mg mr-8"
            onClick={() => setIsResourceModalOpen("LIST")}
            label={t("resource.phishingInfoImageManage")}
            leadingIcon={
              <IconMindSat variant="line" label="gallery" size={17.6} />
            }
            size="lg"
          />
          <Button
            btnStyle="fill"
            leadingIcon={<Icons variant="line" label="plusCircle" size={18} />}
            type="secondary"
            onClick={() => setIsRegitModalOpen(true)}
            label={t("common.button.add")}
            size="lg"
          />
        </Box>
      </Box>
      <Box direction="column">
        {header}
        {isFilterOpen && filters}
        {isCreateDateModalOpen && (
          <DateModal
            isOpen={isCreateDateModalOpen}
            setIsOpen={setIsCreateDateModalOpen}
            start={tempSearchFilter.createStart}
            end={tempSearchFilter.createEnd}
            onSubmit={(start, end) => {
              setSearchFilter((d) => ({
                ...d,
                createStart: start,
                createEnd: end,
              }));
              setTempSearchFilter((d) => ({
                ...d,
                createType: DateType.PERIOD_CUSTOM,
              }));
            }}
          />
        )}

        {isUpdateDateModalOpen && (
          <DateModal
            isOpen={isUpdateDateModalOpen}
            setIsOpen={setIsUpdateDateModalOpen}
            start={tempSearchFilter.updateStart}
            end={tempSearchFilter.updateEnd}
            onSubmit={(start, end) => {
              setSearchFilter((d) => ({
                ...d,
                updateStart: start,
                updateEnd: end,
              }));
              setTempSearchFilter((d) => ({
                ...d,
                updateType: DateType.PERIOD_CUSTOM,
              }));
            }}
          />
        )}

        <Box
          direction="column"
          classes="pd pt-12 flex-1"
          ref={tableRef}
          style={{
            pointerEvents: hoverBoxClickedRow.siteId ? "none" : undefined,
          }}
        >
          <TableNew
            border="none"
            totalCount={sitesTotal}
            clickIds={checkboxGroup.selected.map((siteId) => String(siteId))}
            columnHeight="md"
            highlightIds={[rowIdCreated]}
            data={transformData(sites)}
            endReached={() => {}}
            checkBoxClick={() =>
              checkboxGroup.handleAllClick(
                checkboxGroup.selected.length === sites.length
                  ? []
                  : sites.map((q) => q.siteId)
              )
            }
            header={[
              {
                headerText: t("template.type"),
                width: "72px",
              },
              {
                headerText: t("link.linkNameSimple"),
                width: "360px",
                ...getOrderRenderProps(ColumnsTypeToOrder.siteName),
              },
              {
                headerText: t("link.generatedUrl"),
                width: "calc(100% - 660px)",
              },
              {
                headerText: t("common.time.updateDate"),
                width: "210px",
                ...getOrderRenderProps(ColumnsTypeToOrder.siteUpdateDate),
              },
            ]}
            width={"100%"}
            height={
              checkTableRenderStatus() === 0
                ? `${
                    tableContainerPosition.height -
                    (245 + (isFilterOpen ? 56 : 0))
                  }px`
                : "0px"
            }
          />
          {renderTableSwitch()}
          <HoverClickBox
            isDisplayed={!!hoverBoxClickedRow.siteId}
            hoverBoxNewCoord={hoverBoxNewCoord}
            onOutsideClick={onOutsideClick}
            onClickDelete={(e: MouseEvent) => {
              setSelectedSites([hoverBoxClickedRow.siteId]);
              setIsDeleteModalOpen(true);
              onOutsideClick(e);
            }}
            onClickUpdate={(e: MouseEvent) => {
              setSelectedSites([hoverBoxClickedRow.siteId]);
              setIsRegitModalOpen(true);
              onOutsideClick(e);
            }}
          />
        </Box>
      </Box>

      {/* 훈련용 링크 생성 (추가) */}
      <RegitOrEditSiteModal
        isOpen={isRegitModalOpen}
        onClose={() => {
          setSelectedSites([]);
          setIsRegitModalOpen(false);
        }}
        siteId={selectedSites.length > 0 ? selectedSites[0] : undefined}
        setRowIdCreated={setRowIdCreated}
      />

      {/* 삭제  */}
      <CommonModal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={t("link.linkDelete")}
        afterSubmitButton={onDelete}
        subMessage={t("link.msg.deleteConfirmMsg")}
        type="delete"
      />

      {/* 훈련용 링크 이미지 관리  */}
      <ResourceListModal
        isOpen={isResourceModalOpen !== "NONE"}
        onClose={() => setIsResourceModalOpen("NONE")}
        modalType={isResourceModalOpen}
        setModalType={setIsResourceModalOpen}
      />
    </Box>
  );
};

export default TrainingSite;
