import "./index.scss";
import "../../../shared/style/stickyLayout.scss";

import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Accordions as AccordionsNew,
  InputField,
  Icons as IconsNew,
  Button as ButtonNew,
  Tooltip as TooltipNew,
  useToast as useToastNew,
  Tag,
  Dropdown,
  InputArea,
  DropdownItem,
  Modal,
} from "fds";
import ScrollBox from "../../../component/ScrollBox";

import TitlePath from "../../../component/TitlePath";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  Category,
  ImagesInfo,
  MailTemplateInsertRequest,
  PhishingDocument,
  SiteInfo,
} from "../../../types/Info";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import utilsCommon from "../../../shared/utils/common";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { nanoid } from "@reduxjs/toolkit";
import WSEdit from "../../../component/WSEdit";
import RegitOrEditImageModal from "../Image/RegitOrEditImageModal";
import { useTranslation } from "react-i18next";
import RegitOrEditDocumentModal from "../Document/RegitOrEditDocumentModal";
import RegitOrEditSiteModal from "../Site/RegitOrEditSiteModal";
import useReportStore from "../../../redux/dispatcher/useReportStore";
import html2canvas from "html2canvas";
import useStickyPage from "../../../hooks/useStickyObserver";
import classNames from "classnames";
import IconMindSat from "../../../shared/image/icon";
import CategoryDropdown from "../../../component/CategoryDropdown";
import Divider from "../../../component/Divider";
import EditCancelModal from "../../QuizCourse/Write/EditCancelModal";
import AIPromptModal from "./AIPromptModal";
import useNavigateState from "../../../hooks/useNavigateState";
import { NavigateStateKeys } from "../../../hooks/NavigateStateKeys";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";

const WriteScenario: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [templateId, setTemplateId] = useState<number | null>();
  const [siteList, setSiteList] = useState<SiteInfo[]>([]);
  const [imageList, setImageList] = useState<ImagesInfo[]>([]);
  const [attachList, setAttachList] = useState<PhishingDocument[]>([]);
  const [buttonClick, setButtonClick] = useState<number>(0);

  const [selectedSite, setSelectedSite] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [selectedAttach, setSelectedAttach] = useState<number>(0);

  const [scenarioInfo, setScenarioInfo] = useState<MailTemplateInsertRequest>({
    templateName: "",
    subject: "",
    sendEmail: "",
    summary: "",
    sendName: "",
    imageId: 0,
    documentId: 0,
    siteId: 0,
    categorys: null,
  });
  const [body, setBody] = useState<string>("");
  const [initBody, setInitBody] = useState<string>("");
  // 사용자 입력값
  const [categoryInput, setCategoryInput] = useState<string>("");
  //사용자 입력값에 대한 기존 category 검색 리스트
  const [categoryList, setCategoryList] = useState<string[]>([]);

  //사용중인 템플릿
  const [isUsed, setIsUsed] = useState<boolean>(false);

  const { categoryState, getCategories } = useCategoryStore();
  const { contentState, getImages, getSites, getAttaches } = useContentStore();
  const {
    mailTemplateState,
    insertMailTemplate,
    getMailTemplateData,
    updateMailTemplate,
    parseEMLFile,
  } = useMailTemplateStore();

  const { navigateWithState } = useNavigateState(
    NavigateStateKeys.fromScenarioCreate
  );

  const { reportState, getReportMailDetail } = useReportStore();

  const [selectedCategoryList, setSelectedCategoryList] = useState<string[]>(
    []
  );
  const [type, changeType] = useState<"CREATE" | "UPDATE">("CREATE");

  const [isOpenImageModal, setIsOpenImageModal] = useState<boolean>(false);
  const [isOpenDocumentModal, setIsOpenDocumentModal] =
    useState<boolean>(false);
  const [isOpenLinkModal, setIsOpenLinkModal] = useState<boolean>(false);
  const [isAiModalOpen, setIsAiModalOpen] = useState<boolean>(false);
  const toast = useToastNew();

  const { elementRef, sentinel, isAtTop, isHorizontallyScrollable } =
    useStickyPage();

  useEffect(() => {
    if (location.search && location.search.includes("mailId")) {
      const mailId = location.search.split("=")[1];
      getReportMailDetail(parseInt(mailId));
      setBody(reportState.reportDetail.body);
      setInitBody(reportState.reportDetail.body);
      setScenarioInfo((prev) => ({
        ...prev,
        subject: reportState.reportDetail.exSubject,
      }));
    }
    if (params["*"]) {
      setTemplateId(parseInt(params["*"]));
      changeType(Number.isNaN(parseInt(params["*"])) ? "CREATE" : "UPDATE");
    }
    let newId = window.sessionStorage.getItem("newTemplateId");
    if (newId) {
      setTemplateId(parseInt(newId));
      window.sessionStorage.removeItem("newTemplateId");
      changeType("CREATE");
    }
    getCategories();
    getSites({});
    getImages({});
    getAttaches({});
  }, []);
  useEffect(() => {
    setImageList(contentState.images);
    setSearchedImageList(contentState.images);
  }, [contentState.images]);
  useEffect(() => {
    setSiteList(contentState.sites);
    setSearchedSiteList(contentState.sites);
  }, [contentState.sites]);
  useEffect(() => {
    setAttachList(contentState.attaches);
    setSearchedAttachList(contentState.attaches);
  }, [contentState.attaches]);
  useEffect(() => {
    if (templateId) {
      getMailTemplateData(templateId);
    }
  }, [templateId]);
  useEffect(() => {
    if (templateId) {
      setBody(mailTemplateState.mailTemplateDetail.templateHtml);
      setInitBody(mailTemplateState.mailTemplateDetail.templateHtml);
      setSelectedSite(mailTemplateState.mailTemplateDetail.siteId);
      setSelectedImage(mailTemplateState.mailTemplateDetail.imageId);
      setSelectedAttach(mailTemplateState.mailTemplateDetail.documentId);
      setSelectedCategoryList(mailTemplateState.mailTemplateDetail.categorys);

      if (
        !checkIfDataEditableByCommonId(
          mailTemplateState.mailTemplateDetail.commonId
        )
      ) {
        toast.toastMsg(nanoid(), t("common.msg.noPermission"), "error");
        navigate(`${process.env.PUBLIC_URL}/manageTrain/scenario`);
      }

      debugger;
      if (location.search.includes("newTemplateId")) {
        setScenarioInfo({
          ...mailTemplateState.mailTemplateDetail,
          templateName: `[${t("common.button.copy")}] ${
            mailTemplateState.mailTemplateDetail.templateName
          }`,
        });
      } else {
        setScenarioInfo(mailTemplateState.mailTemplateDetail);
      }

      if (
        mailTemplateState.mailTemplateDetail.useConfig &&
        parseInt(mailTemplateState.mailTemplateDetail.useConfig) > 0
      ) {
        setIsUsed(true);
      }
    }
  }, [mailTemplateState.mailTemplateDetail]);
  useEffect(() => {
    const newCatName = categoryInput.trim();
    // category 검색
    if (newCatName.length > 0) {
      setCategoryList(
        categoryState.categoryList
          .filter((item: Category) => item.categoryName.includes(newCatName))
          .map((c: Category) => c.categoryName)
      );
    } else {
      setCategoryList([]);
    }
  }, [categoryInput]);

  const validation = () => {
    if (!scenarioInfo.subject) {
      toast.toastMsg(nanoid(), t("template.msg.subjectPlaceholder"), "error");
      return false;
    }

    if (!scenarioInfo.sendName) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.senderNamePlaceholder"),
        "error"
      );
      return false;
    }
    if (!scenarioInfo.sendEmail) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.senderEmailPlaceholder"),
        "error"
      );
      return false;
    }
    const reg =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    if (!reg.test(scenarioInfo.sendEmail)) {
      toast.toastMsg(nanoid(), t("common.msg.invalidEmailMsg"), "error");
      return false;
    }

    if (!body) {
      toast.toastMsg(nanoid(), t("template.msg.templateBodyInfoMsg"), "error");
      return false;
    }

    if (!selectedSite && !selectedImage && !selectedAttach) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateNotSelectedPhishingContentMsg"),
        "error"
      );
      return false;
    }

    if (!scenarioInfo.templateName) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateUndefinedNameMsg"),
        "error"
      );
      return false;
    }

    if (scenarioInfo.templateName.length > 128) {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateNameTooLongMsg"),
        "error"
      );
      return false;
    }

    return true;
  };

  const insertScenario = async () => {
    if (!validation()) return;

    const element = document.getElementsByClassName("note-editable")[0];

    const bodyFile = utilsCommon.generateHtmlFileFromString(
      scenarioInfo.templateName,
      body
    );

    html2canvas(element as HTMLElement, {
      useCORS: true,
      width: 500,
    })
      .then(async (canvas) => {
        const dataUrl = canvas.toDataURL("image/png");

        const byteString = atob(dataUrl.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          intArray[i] = byteString.charCodeAt(i);
        }
        const imageBlob = new Blob([intArray], { type: "image/png" });
        const imageFile = new File([imageBlob], "converted-image.png", {
          type: "image/png",
        });

        const resp: any = await insertMailTemplate({
          file: bodyFile,
          thumb: imageFile,
          request: {
            sendEmail: scenarioInfo.sendEmail,
            sendName: scenarioInfo.sendName,
            summary: scenarioInfo.summary,
            subject: scenarioInfo.subject,
            templateName: scenarioInfo.templateName,
            siteId: selectedSite ?? 0,
            imageId: selectedImage ?? 0,
            documentId: selectedAttach ?? 0,
            categorys: selectedCategoryList,
          },
        });

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("template.msg.templateAddSuccessMsg"),
            "success"
          );

          const idCreated = resp.payload.data?.templateId || "";
          navigateWithState(`${process.env.PUBLIC_URL}/manageTrain/scenario`, [
            idCreated.toString(),
          ]);
        } else {
          toast.toastMsg(
            nanoid(),
            t("template.msg.templateDuplicatedTemplateMsg"),
            "error"
          );
        }
      })
      .catch((error) => {
        console.error("Error generating image:", error);
      });
  };

  const updateScenario = async () => {
    if (!validation()) return;

    if (templateId) {
      const bodyFile = utilsCommon.generateHtmlFileFromString(
        scenarioInfo.templateName,
        body
      );

      const element = document.getElementsByClassName("note-editable")[0];

      html2canvas(element as HTMLElement, { useCORS: true, width: 500 })
        .then(async (canvas) => {
          const dataUrl = canvas.toDataURL("image/png");

          const byteString = atob(dataUrl.split(",")[1]);
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const intArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
          }
          const imageBlob = new Blob([intArray], { type: "image/png" });
          const imageFile = new File([imageBlob], "converted-image.png", {
            type: "image/png",
          });

          const resp: any = await updateMailTemplate({
            file: bodyFile,
            thumb: imageFile,
            request: {
              ...scenarioInfo,
              templateId: templateId,
              siteId: selectedSite,
              imageId: selectedImage,
              documentId: selectedAttach,
              categorys: selectedCategoryList,
            },
          });

          if (resp.payload && resp.payload.code === "SUCCESS") {
            toast.toastMsg(
              nanoid(),
              t("template.msg.templateUpdateSuccessMsg"),
              "success"
            );
          } else {
            toast.toastMsg(
              nanoid(),
              t("template.msg.templateUpdateFailMsg"),
              "error"
            );
          }
          navigate("/manageTrain/scenario");
        })
        .catch((error) => {
          console.error("Error generating image:", error);
        });
    }
  };

  const [searchImageName, setSearchImageName] = useState<string | null>();
  const [searchSiteName, setSearchSiteName] = useState<string | null>();
  const [searchAttachName, setSearchAttachName] = useState<string | null>();
  const [searchedImageList, setSearchedImageList] = useState<ImagesInfo[]>([]);
  const [searchedSiteList, setSearchedSiteList] = useState<SiteInfo[]>([]);
  const [searchedAttachList, setSearchedAttachList] = useState<
    PhishingDocument[]
  >([]);
  const [aiPrompt, setAiPrompt] = useState<string>("");

  useEffect(() => {
    if (searchImageName && searchImageName.length > 0) {
      setSearchedImageList(
        imageList.filter((item) => item.imageOrgName.includes(searchImageName))
      );
    } else {
      setSearchedImageList(imageList);
    }
  }, [searchImageName]);
  useEffect(() => {
    if (searchSiteName && searchSiteName.length > 0) {
      setSearchedSiteList(
        siteList.filter((item) => item.siteName.includes(searchSiteName))
      );
    } else {
      setSearchedSiteList(siteList);
    }
  }, [searchSiteName]);
  useEffect(() => {
    if (searchAttachName && searchAttachName.length > 0) {
      setSearchedAttachList(
        attachList.filter((item) =>
          item.documentName.includes(searchAttachName)
        )
      );
    } else {
      setSearchedAttachList(attachList);
    }
  }, [searchAttachName]);

  const linkDropdown = (
    <Dropdown
      value={
        siteList.filter((item) => item.siteId === selectedSite)[0]?.siteName
      }
      btnStyle="line"
      placeholder={t("template.msg.linkPlaceholderMsg")}
      buttonClick={buttonClick}
      width={363}
      classes="link-dropdown"
    >
      <BoxNew classes="link-dropdown__title">
        <TypographyNew
          type="caption1_rg"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          {t("link.link")}
        </TypographyNew>
      </BoxNew>
      <InputField
        placeholder={t("template.searchLink")}
        value={searchSiteName ?? ""}
        onChange={(e) => setSearchSiteName(e.target.value)}
        leadingIcon={<IconsNew variant="line" label="search" />}
        size={"sm"}
        classes="link-dropdown__search"
      />
      <BoxNew justifyContent="center" width="100%">
        <Divider direction="row" classes="link-dropdown__divider" />
      </BoxNew>
      {searchedSiteList.length === 0 ? (
        <BoxNew classes="link-dropdown__no-link">
          <TypographyNew
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.msg.noLinkMsg")}
          </TypographyNew>
        </BoxNew>
      ) : (
        <ScrollBox classes="link-dropdown__links">
          <DropdownItem
            onClick={() => setIsOpenLinkModal(true)}
            label={t("template.addLink")}
            frontIcon={<IconsNew variant="line" label="plusCircle" />}
          />
          {searchedSiteList.map((item) => (
            <DropdownItem
              id={item.siteId.toString()}
              onClick={() => {
                if (selectedSite === item.siteId) {
                  setSelectedSite(0);
                } else {
                  setSelectedSite(item.siteId);
                }
                setInitBody(body);
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              checked={selectedSite === item.siteId}
              checkColor={selectedSite === item.siteId}
              label={item?.siteName}
            />
          ))}
        </ScrollBox>
      )}
    </Dropdown>
  );

  const imageDropdown = (
    <Dropdown
      value={
        imageList.find((item) => item.imageId === selectedImage)?.imageOrgName
      }
      width={363}
      btnStyle="line"
      placeholder={t("template.msg.imagePlaceholderMsg")}
      buttonClick={buttonClick}
      classes="link-dropdown"
    >
      <BoxNew classes="link-dropdown__title">
        <TypographyNew
          type="caption1_rg"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          {t("image.image")}
        </TypographyNew>
      </BoxNew>
      <InputField
        placeholder={t("template.searchLink")}
        value={searchImageName ?? ""}
        onChange={(e) => setSearchImageName(e.target.value)}
        leadingIcon={<IconsNew variant="line" label="search" />}
        size={"sm"}
        classes="link-dropdown__search"
      />
      <BoxNew justifyContent="center" width="100%">
        <Divider direction="row" classes="link-dropdown__divider" />
      </BoxNew>
      {searchedImageList.length === 0 ? (
        <BoxNew classes="link-dropdown__no-link">
          <TypographyNew
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.msg.noLinkMsg")}
          </TypographyNew>
        </BoxNew>
      ) : (
        <ScrollBox classes="link-dropdown__links">
          <DropdownItem
            onClick={() => setIsOpenImageModal(true)}
            label={t("template.addImage")}
            frontIcon={<IconsNew variant="line" label="plusCircle" />}
            isFullWidth
          />
          <BoxNew classes="image-grid">
            {searchedImageList.map((item) => (
              <BoxNew
                classes={classNames("image-grid__item", {
                  checked: selectedImage === item.imageId,
                })}
                id={item.imageId.toString()}
                onClick={() => {
                  setSelectedImage(item.imageId);
                  setInitBody(body);
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              >
                <TooltipNew message={item.imageOrgName}>
                  <img
                    className="img__thumb"
                    src={item.imagePath}
                    width={"100%"}
                    height={"100%"}
                  />
                </TooltipNew>
              </BoxNew>
            ))}
          </BoxNew>
        </ScrollBox>
      )}
    </Dropdown>
  );

  const attachDropdown = (
    <Dropdown
      value={
        attachList.filter((item) => item.documentId === selectedAttach)[0]
          ?.documentName
      }
      btnStyle="line"
      placeholder={t("template.msg.attachPlaceholder")}
      buttonClick={buttonClick}
      width={363}
      classes="link-dropdown"
    >
      <BoxNew classes="link-dropdown__title">
        <TypographyNew
          type="caption1_rg"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          {t("link.link")}
        </TypographyNew>
      </BoxNew>
      <InputField
        placeholder={t("template.searchLink")}
        value={searchAttachName ?? ""}
        onChange={(e) => setSearchAttachName(e.target.value)}
        leadingIcon={<IconsNew variant="line" label="search" />}
        size={"sm"}
        classes="link-dropdown__search"
      />
      <BoxNew justifyContent="center" width="100%">
        <Divider direction="row" classes="link-dropdown__divider" />
      </BoxNew>
      {searchedAttachList.length === 0 ? (
        <BoxNew classes="link-dropdown__no-link">
          <TypographyNew
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.msg.noLinkMsg")}
          </TypographyNew>
        </BoxNew>
      ) : (
        <ScrollBox classes="link-dropdown__links">
          <DropdownItem
            onClick={() => setIsOpenDocumentModal(true)}
            label={t("template.addAttach")}
            frontIcon={<IconsNew variant="line" label="plusCircle" />}
          />
          {searchedAttachList.map((item) => (
            <DropdownItem
              id={item.documentId.toString()}
              onClick={() => {
                setSelectedAttach(item.documentId);
                setInitBody(body);
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              checked={selectedAttach === item.documentId}
              checkColor={selectedAttach === item.documentId}
              label={
                <TypographyNew type="body2_rg" ellipsis>
                  {item.documentName}
                </TypographyNew>
              }
            />
          ))}
        </ScrollBox>
      )}
    </Dropdown>
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const ext = ["eml"];

  const parseEml = async (req: { file: File }) => {
    const resp: any = await parseEMLFile(req);

    if (resp.payload && resp.payload.code === "SUCCESS") {
      setScenarioInfo({
        ...scenarioInfo,
        subject: resp.payload.data.subject,
        sendName: resp.payload.data.sendName,
        sendEmail: resp.payload.data.sendEmail,
      });
      setBody(resp.payload.data.body);
      setInitBody(resp.payload.data.body);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileName = file.name;
      const idx = fileName ? fileName.lastIndexOf(".") : -1;
      if (idx > 0) {
        const extension = fileName.substring(idx + 1);
        // 확장자 체크
        if (!ext.includes(extension.toLowerCase())) {
          toast.toastMsg(
            nanoid(),
            t("template.msg.templateInvalidExtensionMsg"),
            "error"
          );
          event.target.value = "";
          return;
        }

        // parseEMLFile({ file });
        parseEml({ file });
      }
    }
  };

  const handleSetScenario = (data: any) => {
    setBody(data.mailTemplate);
    setInitBody(data.mailTemplate);
    setScenarioInfo((prev) => ({
      ...prev,
      subject: data.mailTitle,
      sendName: data.mailSenderName,
      sendEmail: data.mailSenderAddress,
    }));
    setIsAiModalOpen(false);
  };

  const isScenarioUpdateDisabled = isUsed && type === "UPDATE";

  // 기본 정보
  const basicInfo = (
    <BoxNew direction="column" classes="mg mt-8 ml-10 mr-10 pd pt-28 pb-28">
      {/* 시나리오 이름 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.scenarioName")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew direction="column">
          <InputField
            placeholder={t("template.msg.namePlaceholder")}
            size={"md"}
            width={598}
            disabled={isScenarioUpdateDisabled}
            maxLength={128}
            value={scenarioInfo.templateName ? scenarioInfo.templateName : ""}
            onChange={(e) =>
              setScenarioInfo((prev) => {
                return { ...prev, templateName: e.target.value };
              })
            }
          />
        </BoxNew>
      </BoxNew>
      {/* 훈련 시나리오 설명 */}
      <BoxNew classes="mg mb-24">
        <BoxNew width={220} classes="mg mt-10 mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.scenarioDescription")}
          </TypographyNew>
        </BoxNew>
        <BoxNew direction="column">
          <InputArea
            placeholder={t("template.msg.descriptionPlaceholder")}
            type="text"
            width={598}
            disabled={isScenarioUpdateDisabled}
            maxLength={128}
            rows={3}
            value={scenarioInfo.summary}
            onChange={(e) =>
              setScenarioInfo((prev) => ({
                ...prev,
                summary: e.target.value,
              }))
            }
            isError={(scenarioInfo.summary || "").length > 128}
            helpMessage={
              (scenarioInfo.summary || "").length > 128
                ? t("common.msg.maxLengthExceed")
                : ""
            }
          />
        </BoxNew>
      </BoxNew>
      {/* 이메일 제목 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.subject")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew direction="column">
          <InputField
            placeholder={t("template.msg.subjectPlaceholder")}
            size={"md"}
            width={598}
            disabled={isScenarioUpdateDisabled}
            maxLength={128}
            value={scenarioInfo.subject ? scenarioInfo.subject : ""}
            onChange={(e) =>
              setScenarioInfo((prev) => {
                return { ...prev, subject: e.target.value };
              })
            }
          />
        </BoxNew>
      </BoxNew>
      {/* 발신자 이름 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.senderName")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew direction="column">
          <InputField
            placeholder={t("template.msg.senderNamePlaceholder")}
            size={"md"}
            width={598}
            disabled={isScenarioUpdateDisabled}
            maxLength={128}
            value={scenarioInfo.sendName ? scenarioInfo.sendName : ""}
            onChange={(e) =>
              setScenarioInfo((prev) => {
                return { ...prev, sendName: e.target.value };
              })
            }
          />
        </BoxNew>
      </BoxNew>
      {/* 발신자 이메일 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.senderEmail")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew direction="column">
          <InputField
            placeholder={t("template.msg.senderEmailPlaceholder")}
            size={"md"}
            width={598}
            disabled={isScenarioUpdateDisabled}
            maxLength={128}
            value={scenarioInfo.sendEmail ? scenarioInfo.sendEmail : ""}
            onChange={(e) =>
              setScenarioInfo((prev) => {
                return { ...prev, sendEmail: e.target.value };
              })
            }
          />
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );

  // 시나리오 콘텐츠
  const scenarioBody = (
    <BoxNew
      direction="column"
      classes="mg mt-10 ml-10 mr-10 pd pt-28 pb-28 write-scenario"
    >
      {/* 훈련용 링크 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("link.link")}
          </TypographyNew>
        </BoxNew>
        <BoxNew direction="column">{linkDropdown}</BoxNew>
      </BoxNew>
      {/* 열람 확인용 이미지 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("image.image")}
          </TypographyNew>
        </BoxNew>
        <BoxNew direction="column">{imageDropdown}</BoxNew>
      </BoxNew>
      {/* 훈련용 첨부 파일 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("attach.attach")}
          </TypographyNew>
        </BoxNew>
        <BoxNew direction="column">{attachDropdown}</BoxNew>
      </BoxNew>
      {/* 시나리오 카테고리*/}
      <BoxNew classes="mg mb-24">
        <BoxNew width={220} classes="mg mr-28 mt-8" direction="column">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
            classes="mg mb-4"
          >
            {t("category.categorySimple")}
          </TypographyNew>
          <TypographyNew
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.msg.categoryGuideMsg")}
          </TypographyNew>
        </BoxNew>
        <CategoryDropdown
          dropdownList={categoryList}
          searchword={categoryInput}
          updateSearchword={(searchword: string) =>
            setCategoryInput(searchword)
          }
          initialSelectedChips={selectedCategoryList}
          updateSelectedChips={(selectedChips) =>
            setSelectedCategoryList(selectedChips)
          }
          width={"362px"}
        />
      </BoxNew>
      {/* 이메일 내용 */}
      <BoxNew direction="column">
        <BoxNew width={220} classes="mg mr-28 mb-20">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.body")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>
        <BoxNew direction="column">
          <WSEdit
            onChange={(e) => {
              setBody(e);
            }}
            initBody={initBody}
            isShowButtons={true}
            isSelectedLink={selectedSite === 0 ? false : true}
            isSelectedImage={selectedImage === 0 ? false : true}
          />
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );

  function checkIfBasicInfoValid() {
    return (
      scenarioInfo.templateName &&
      scenarioInfo.subject &&
      scenarioInfo.sendName &&
      scenarioInfo.sendEmail
    );
  }

  function checkIfScenarioValid() {
    return body !== "" && body !== "<br>";
  }

  const [isEditCancelModalOpen, setIsEditCancelModalOpen] = useState(false);

  const closeEditCancelModal = () => {
    navigate(`${process.env.PUBLIC_URL}/manageTrain/scenario`);
    setIsEditCancelModalOpen(false);
  };

  const stayOnPage = () => {
    setIsEditCancelModalOpen(false);
  };

  return (
    <BoxNew classes="sticky-layout">
      <BoxNew ref={sentinel}>
        <BoxNew
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          {/* 타이틀 */}
          <BoxNew direction="column">
            <TitlePath
              path={[t("menu.trainingManagement"), t("menu.template")]}
            />
            <TypographyNew type="h1" classes="mg mt-8">
              {type === "UPDATE"
                ? t("template.update")
                : t("template.addScenario")}
            </TypographyNew>
          </BoxNew>
          {/* 버튼 */}
          <BoxNew>
            <BoxNew>
              <ButtonNew
                btnStyle="fill"
                leadingIcon={<IconsNew variant="line" label="upload" />}
                type="secondary"
                onClick={handleClick}
                classes="mg mr-10"
                label={t("attach.scenarioUpload")}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </BoxNew>
            {type === "CREATE" && (
              <BoxNew>
                <ButtonNew
                  btnStyle="fill"
                  leadingIcon={
                    <IconMindSat variant="line" label="doubleStar" size={20} />
                  }
                  type="secondary"
                  onClick={() => setIsAiModalOpen(true)}
                  label={t("quiz.label.addAiQuiz")}
                />
              </BoxNew>
            )}
          </BoxNew>
        </BoxNew>
      </BoxNew>

      <BoxNew classes="body">
        <AccordionsNew
          label={t("quiz.label.basicInfo")}
          initialOpenStatus={true}
          labelLeftComponent={
            checkIfBasicInfoValid() ? (
              <Tag
                backgroundColor={getColor("COLOR_BG_SUCCESS_DISABLED")}
                text={t("common.input.completed")}
                textColor={getColor("COLOR_TEXT_SUCCESS")}
              />
            ) : (
              <Tag
                backgroundColor={getColor("COLOR_BG_LOADING_DISABLED")}
                text={t("common.input.incompleted")}
                textColor={getColor("COLOR_TEXT_LOADING")}
              />
            )
          }
        >
          {basicInfo}
        </AccordionsNew>
        <AccordionsNew
          label={t("template.scenarioContents")}
          initialOpenStatus={true}
          labelLeftComponent={
            checkIfScenarioValid() ? (
              <Tag
                backgroundColor={getColor("COLOR_BG_SUCCESS_DISABLED")}
                text={t("common.input.completed")}
                textColor={getColor("COLOR_TEXT_SUCCESS")}
              />
            ) : (
              <Tag
                backgroundColor={getColor("COLOR_BG_LOADING_DISABLED")}
                text={t("common.input.incompleted")}
                textColor={getColor("COLOR_TEXT_LOADING")}
              />
            )
          }
        >
          {scenarioBody}
        </AccordionsNew>
      </BoxNew>

      <BoxNew
        classes={classNames("footer", { showScroll: isHorizontallyScrollable })}
      >
        <BoxNew classes="flex-1">
          <IconsNew
            variant="line"
            label="info"
            size={18}
            stroke={getColor("COLOR_ICON_INFO")}
          />
          <TypographyNew
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_INFO")}
            classes="mg ml-4"
          >
            {t("template.msg.typeInfoMsg")}
          </TypographyNew>
        </BoxNew>
        <ButtonNew
          btnStyle="fill"
          type="secondary"
          classes="mg mr-8"
          onClick={() => setIsEditCancelModalOpen(true)}
          label={t("common.button.cancel")}
        />
        <ButtonNew
          btnStyle="fill"
          type="primary"
          onClick={() => {
            if (type === "UPDATE") updateScenario();
            else insertScenario();
          }}
          label={t("common.button.save")}
        />
      </BoxNew>

      <EditCancelModal
        closeModal={closeEditCancelModal}
        stayOnPage={stayOnPage}
        toShow={isEditCancelModalOpen}
      />

      <RegitOrEditImageModal
        isOpen={isOpenImageModal}
        onClose={() => setIsOpenImageModal(false)}
      />
      <RegitOrEditDocumentModal
        isOpen={isOpenDocumentModal}
        onClose={() => setIsOpenDocumentModal(false)}
      />
      <RegitOrEditSiteModal
        isOpen={isOpenLinkModal}
        onClose={() => setIsOpenLinkModal(false)}
      />
      <AIPromptModal
        isAiModalOpen={isAiModalOpen}
        setIsAiModalOpen={setIsAiModalOpen}
        aiPrompt={aiPrompt}
        setAiPrompt={setAiPrompt}
        handleSetScenario={handleSetScenario}
      />
    </BoxNew>
  );
};

export default WriteScenario;
