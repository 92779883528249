import { Box, Dropdown, DropdownItem, Typography } from "fds";
import { DUMMY_CUSTOMER_LIST } from "../../Pages/DummyData";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserInfoFromLocalStorage,
  setUserInfoFromLocalStorage,
} from "../../shared/utils/auth";
import useCustomerStore from "../../redux/dispatcher/useCustomerStore";
import { CustomerInfoBean } from "../../types/Info";

const Customer: React.FC = () => {
  const navigate = useNavigate();

  const [customerList, changeCustomerList] = useState<CustomerInfoBean[]>(
    [] as CustomerInfoBean[]
  );
  const [userInfo] = useState(
    getUserInfoFromLocalStorage() ? { ...getUserInfoFromLocalStorage() } : null
  );

  useEffect(() => {
    changeCustomerList(DUMMY_CUSTOMER_LIST);
  }, []);

  // const { selectCustomer } = loginApi;

  const { selectCustomer } = useCustomerStore();
  const onClickCustomer = async (key: any) => {
    setUserInfoFromLocalStorage({
      ...userInfo,
      customerCode: key,
    });

    // const res = await selectCustomer(key);
    const res = selectCustomer(key);

    navigate(`${process.env.PUBLIC_URL}/dashboard`);
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      width={"100%"}
      height={"100%"}
    >
      <Typography type="body1_rg">접속할 고객사를 선택해 주세요.</Typography>
      <Dropdown classes="mg ml-40" value={"고객사 선택"}>
        {customerList.map((item, index) => (
          <DropdownItem
            key={"customer" + index}
            onClick={(key: any) => onClickCustomer(item.customerCode)}
            label={item.companyName}
          ></DropdownItem>
        ))}
      </Dropdown>
    </Box>
  );
};
export default Customer;
