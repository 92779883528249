import { Box, getColor, Typography } from "fds";
import React from "react";
import { useTranslation } from "react-i18next";
import NoQuizImage from "../../shared/image/NoQuizImage";

interface NoInfoProps {
  marginTop?: string;
}
const NoInfo: React.FC<NoInfoProps> = ({ marginTop = "0" }) => {
  const { t } = useTranslation();
  return (
    // <Box
    //   width={"100%"}
    //   height={"400px"}
    //   alignItems="center"
    //   justifyContent="center"
    //   direction="column"
    // >
    //   <NoInfoImage width={400} />
    //   <Typography type="h3" classes="mg mt-8">
    //     {t("common.msg.noHistoryMsg")}
    //   </Typography>
    // </Box>
    <Box
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: marginTop }}
    >
      <NoQuizImage />
      <Typography
        type="caption_rg"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
      >
        {t("quiz.msg.noItems")}
      </Typography>
    </Box>
  );
};

export default NoInfo;
