import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  QuizContentReqDTO,
  QuizContentDTO,
  QuizTemplateDTO,
  QuizCategoryDTO,
  QuizCourseDTO,
  QuizSendHistoryDTO,
  QuizSendHistoryReqDTO,
  QuizSolvingReqDTO,
  QuizCourseReqDTO,
  QuizStatReqDTO,
  QuizAIReqDTO,
} from "../../types/Quiz";
import quizApi from "../api/quizApi";
import i18n from "../../locales";
import { UserType } from "../../types/User";

interface QuizContentState {
  quizCourseList: { total: number; list: QuizCourseDTO[] };
  quizCourse?: QuizCourseDTO;
  quizCourseUserList?: UserType[];
  quizContentList: { total: number; list: QuizContentDTO[] };
  quizContent?: QuizContentDTO;
  quizContentCategory: QuizCategoryDTO[];
  quizTemplate?: QuizTemplateDTO;
  quizSendHistoryList: { total: number; list: QuizSendHistoryDTO[] };
}

const initialState: QuizContentState = {
  quizCourseList: { total: 0, list: [] },
  quizCourse: undefined,
  quizCourseUserList: [],
  quizContentList: { total: 0, list: [] },
  quizContent: undefined,
  quizContentCategory: [],
  quizTemplate: undefined,
  quizSendHistoryList: { total: 0, list: [] },
};

export const trunkGetQuizContentList = createAsyncThunk(
  "quiz/content/list",
  async (req: QuizContentReqDTO) => {
    const resp = await quizApi.getQuizContents(req);

    return resp;
  }
);

export const trunkInsertQuizContent = createAsyncThunk(
  "quiz/content/insert",
  async (req: QuizContentDTO) => {
    const resp = await quizApi.insertQuizContent(req);

    return resp;
  }
);

export const trunkGetQuizContentDetail = createAsyncThunk(
  "quiz/content/detail",
  async (req: QuizContentDTO) => {
    const resp = await quizApi.getQuizContentDetail(req);

    return resp;
  }
);

export const trunkUpdateQuizContent = createAsyncThunk(
  "quiz/content/update",
  async (req: QuizContentDTO) => {
    const resp = await quizApi.updateQuizContent(req);

    return resp;
  }
);

export const trunkDeleteQuizContent = createAsyncThunk(
  "quiz/content/delete",
  async (req: { quizIds: number[] }) => {
    const resp = await quizApi.deleteQuizContent(req);

    return resp;
  }
);

export const trunkGetQuizCategory = createAsyncThunk(
  "quiz/content/category",
  async () => {
    const resp = await quizApi.getQuizCategory();

    return resp;
  }
);

export const trunkInsertQuizTemplate = createAsyncThunk(
  "quiz/template/insert",
  async (req: { request: QuizTemplateDTO; file: File }) => {
    const resp = await quizApi.insertQuizTemplate(req);

    return resp;
  }
);

export const trunkUpdateQuizTemplate = createAsyncThunk(
  "quiz/template/update",
  async (req: { request: QuizTemplateDTO; file: File }) => {
    const resp = await quizApi.updateQuizTemplate(req);

    return resp;
  }
);

export const trunkGetQuizTemplate = createAsyncThunk(
  "quiz/template",
  async () => {
    const resp = await quizApi.getQuizTemplate();
    return resp;
  }
);
export const trunkInsertQuizCourse = createAsyncThunk(
  "quiz/course/insert",
  async (req: QuizCourseDTO) => {
    const resp = await quizApi.insertQuizCourse(req);

    return resp;
  }
);

export const trunkGetQuizCourses = createAsyncThunk(
  "quiz/course",
  async (req: QuizCourseReqDTO) => {
    const resp = await quizApi.getQuizCourses(req);

    return resp;
  }
);

export const trunkGetQuizCourseDetail = createAsyncThunk(
  "quiz/course/detail",
  async (req: QuizCourseReqDTO) => {
    const resp = await quizApi.getQuizCourseDetail(req);

    return resp;
  }
);

export const trunkUpdateQuizCourse = createAsyncThunk(
  "quiz/course/update",
  async (req: QuizCourseDTO) => {
    const resp = await quizApi.updateQuizCourse(req);

    return resp;
  }
);

export const trunkDeleteQuizCourse = createAsyncThunk(
  "quiz/course/delete",
  async (req: QuizCourseDTO) => {
    const resp = await quizApi.deleteQuizCourse(req);

    return resp;
  }
);

export const trunkGetQuizSendHistory = createAsyncThunk(
  "quiz/result",
  async (req: QuizSendHistoryReqDTO) => {
    const resp = await quizApi.getQuizSendHistory(req);

    return resp;
  }
);

export const trunkResendQuizMail = createAsyncThunk(
  "quiz/result/resend",
  async (req: QuizSendHistoryReqDTO[]) => {
    const resp = await quizApi.resendQuizMail(req);

    return resp;
  }
);

export const trunkExportQuizSendHistory = createAsyncThunk(
  "quiz/result/export",
  async (req?: QuizSendHistoryReqDTO) => {
    const resp = await quizApi.exportQuizSendHistory(req);

    return resp;
  }
);

export const trunkIsValidQuizAccess = createAsyncThunk(
  "quiz/solve/auth",
  async (req: QuizSolvingReqDTO) => {
    const resp = await quizApi.isValidQuizAccess(req);

    return resp;
  }
);

export const trunkCompleteQuiz = createAsyncThunk(
  "quiz/solve/complete",
  async (req: QuizSolvingReqDTO) => {
    const resp = await quizApi.completeQuiz(req);

    return resp;
  }
);

export const trunkGetSelectedOrgan = createAsyncThunk(
  "quiz/organ",
  async (req: { userIds: string[]; deptCodes: string[] }) => {
    const resp = await quizApi.getSelectedOrgan(req);

    return resp;
  }
);

export const trunkGetQuizStat = createAsyncThunk(
  "quiz/stat",
  async (req: QuizStatReqDTO) => {
    const resp = await quizApi.getQuizStat(req);

    return resp;
  }
);

export const trunkSyncQuizContent = createAsyncThunk("quiz/sync", async () => {
  const resp = await quizApi.syncQuizContent();

  return resp;
});

export const trunkCreateAiQuizContent = createAsyncThunk(
  "quiz/ai/create",
  async (req: QuizAIReqDTO, { signal }) => {
    const resp = await quizApi.createAiQuizContent(req, signal);
    return resp;
  }
);

export const trunkInsertQuizCourseByTraining = createAsyncThunk(
  "quiz/course/training",
  async (req: number) => {
    const resp = await quizApi.insertQuizCourseByTraining(req);

    return resp;
  }
);

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetQuizContentList.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizContentList = data.data;
        }
      }
    );

    builder.addCase(
      trunkInsertQuizContent.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizContentList.list = [
            ...state.quizContentList.list,
            data.data,
          ];
          state.quizContentList.total = state.quizContentList.total + 1;
        }
      }
    );

    builder.addCase(trunkDeleteQuizContent.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.quizContentList.list = state.quizContentList.list.filter(
          (item) => !req.meta.arg.quizIds.some((id) => item.quizId === id)
        );
        state.quizContentList.total =
          state.quizContentList.total - req.meta.arg.quizIds.length;
      }
    });

    builder.addCase(
      trunkGetQuizContentDetail.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizContent = data.data;
        }
      }
    );

    builder.addCase(
      trunkGetQuizCourses.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizCourseList = data.data;
        }
      }
    );

    builder.addCase(
      trunkGetQuizCourseDetail.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizCourse = data.data.courseInfo;
          state.quizCourseUserList = data.data.userList;
        }
      }
    );

    builder.addCase(trunkDeleteQuizCourse.fulfilled, (state, req) => {
      if (req.payload.code === "SUCCESS") {
        state.quizCourseList.list = state.quizCourseList.list.filter(
          (item) => item.courseId !== req.meta.arg.courseId
        );
        state.quizCourseList.total = state.quizCourseList.total - 1;
      }
    });

    builder.addCase(
      trunkGetQuizTemplate.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizTemplate = data.data;
        }
      }
    );

    builder.addCase(
      trunkGetQuizCategory.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizContentCategory = data.data;
        }
      }
    );

    builder.addCase(
      trunkInsertQuizTemplate.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizTemplate = data.data;
        }
      }
    );

    builder.addCase(
      trunkGetQuizSendHistory.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.quizSendHistoryList = data.data;
        }
      }
    );

    builder.addCase(
      trunkExportQuizSendHistory.fulfilled,
      (state, { payload: data }) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          i18n.language === "ko"
            ? "메일 발송 내역 및 결과.xlsx"
            : "Sent Email Details And Result.xlsx"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    );

    // builder.addCase(
    //   trunkInsertQuizCourseByTraining.fulfilled,
    //   (state, { payload: data }) => {
    //     if (data.code === "SUCCESS") {
    //       state.quizSendHistoryList = data.data;
    //     }
    //   }
    // );
  },
});

export const quizActions = quizSlice.actions;

export default quizSlice.reducer;
