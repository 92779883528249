import {
  Box,
  Button,
  InputField,
  getColor,
  Typography,
  Modal,
  useToast,
} from "fds";

import { useEffect, useState } from "react";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import utilsCommon from "../../shared/utils/common";
import { SimpleTreeNode } from "../../types/User";
import TreeSimple from ".";

interface CreateUpdateUserModalProps {
  isOpen: boolean;
  type: string;
  parentCode: string;
  parentName: string;
  deptCode?: string;
  deptName?: string;
  onClose: () => void;
  treeNodes: SimpleTreeNode[];
}

const CreateUpdateDeptModal: React.FC<CreateUpdateUserModalProps> = ({
  isOpen,
  parentCode,
  parentName,
  type,
  deptCode,
  deptName,
  onClose,
  treeNodes,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { addDept, updateDept, getDeptList } = useDeptStore();
  const [tempDeptName, changeTempDeptName] = useState(deptName ?? "");
  const [tempDeptCode, changeTempDeptCode] = useState(deptCode ?? "");
  const [tempParentCode, changeTempParentCode] = useState(parentCode ?? "");
  const handleAddDept = async () => {
    if (
      tempDeptCode.trim() &&
      !utilsCommon.deptCodeValidation(tempDeptCode.trim())
    ) {
      toast.toastMsg(nanoid(), t("dept.msg.invalidCodeMsg"), "error");
      return;
    }
    if (
      tempDeptName.trim() &&
      !utilsCommon.deptNameValidation(tempDeptName.trim())
    ) {
      toast.toastMsg(nanoid(), t("dept.msg.invalidNameMsg"), "error");
      return;
    }
    if (tempDeptCode.trim().length > 64) {
      toast.toastMsg(nanoid(), t("dept.msg.codeLengthLimitMsg"), "error");
      return;
    }
    if (tempDeptName.trim().length > 128) {
      toast.toastMsg(nanoid(), t("dept.msg.namdLengthLimitMsg"), "error");
      return;
    }
    let resp: any =
      type === "CREATE"
        ? await addDept({
            deptCode: tempDeptCode.trim(),
            deptName: tempDeptName.trim(),
            parentCode: tempParentCode.trim(),
          })
        : await updateDept({
            deptCode: tempDeptCode.trim(),
            deptName: tempDeptName.trim(),
            parentCode: tempParentCode.trim(),
          });
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        type === "CREATE"
          ? t("dept.msg.addSuccessMsg")
          : t("dept.msg.updateSuccessMsg"),
        "success"
      );
      getDeptList();
      onClose();
    } else {
      toast.toastMsg(
        nanoid(),
        resp?.payload.code && resp?.payload.code === "DATA_DUPLICATE"
          ? t("dept.msg.existedCodeMsg")
          : t("dept.msg.addFailMsg"),
        "error"
      );
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={
        type === "CREATE"
          ? t("dept.deptAddTitle")
          : t("dept.deptUpdateInfoTitle")
      }
      width={816}
      isFooterDivider={true}
      footer={
        <Box justifyContent="flex-end">
          <Button
            btnStyle="fill"
            type="secondary"
            onClick={onClose}
            label={t("common.button.cancel")}
          />
          <Button
            btnStyle="fill"
            classes="mg ml-4"
            state={
              !tempDeptName || tempDeptName?.trim() === ""
                ? "disable"
                : "default"
            }
            onClick={handleAddDept}
            label={
              type === "CREATE"
                ? t("common.button.save")
                : t("common.button.save")
            }
          />
        </Box>
      }
      body={
        <Box direction="row" width={"100%"}>
          <Box
            width={436}
            height={556}
            classes="treeRightBorder"
            direction="column"
          >
            <Box classes="mg mb-12">
              <Typography type="subtitle2">상위 부서 선택</Typography>{" "}
              <Typography
                type="subtitle2"
                classes="mg ml-4"
                exactColor={getColor("COLOR_TEXT_ERROR")}
              >
                *
              </Typography>
            </Box>
            {treeNodes && (
              <TreeSimple
                rootCode={"COMPANY"}
                clickedId={tempParentCode}
                changeClickedId={changeTempParentCode}
                treeNodes={treeNodes}
                depth={0}
                treeType={"DEPT_ONLY"}
                defaultExpanded={false}
                isOrganModal
              />
            )}
          </Box>
          <Box direction="column" width={300}>
            <Box>
              <Typography type="subtitle2">{t("dept.deptName")}</Typography>
              <Typography
                type="subtitle2"
                classes="mg ml-4"
                exactColor={getColor("COLOR_TEXT_ERROR")}
              >
                *
              </Typography>
            </Box>
            <InputField
              placeholder={t("dept.msg.undefinedNameMsg")}
              classes="mg mt-6"
              size="md"
              value={tempDeptName}
              maxLength={128}
              onChange={(e) => changeTempDeptName(e.target.value)}
            />
            <Typography type="subtitle2" classes="mg mt-24">
              {t("dept.deptCode")}
            </Typography>
            <InputField
              placeholder={t("dept.msg.undefinedCodeMsg")}
              classes="mg mt-6"
              size="md"
              value={tempDeptCode}
              maxLength={64}
              disabled={type === "UPDATE"}
              onChange={(e) => changeTempDeptCode(e.target.value)}
            />
            <Box classes="mg mt-6 mb-24">
              {type !== "UPDATE" ? (
                <Typography exactColor={getColor("COLOR_TEXT_SECONDARY")}>
                  {t("dept.msg.codeRandomMsg")}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
      }
    ></Modal>
  );
};

export default CreateUpdateDeptModal;
