import {
  Typography as TypographyNew,
  Box as BoxNew,
  Modal as ModalNew,
  CalendarRange,
  Button as ButtonNew,
  getColor,
  Dropdown,
  DropdownItem,
  useToast,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import StaticsPage from "./StaticsPage";
import useReportRankStore from "../../../redux/dispatcher/useReportRankStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import utilsCommon from "../../../shared/utils/common";

const ReportStatistics: React.FC = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { getRankByPeriod } = useReportRankStore();

  const [openDateModal, toggleDateModal] = useState(false);
  const [selectedPeriod, changeSelectedPeriod] = useState({
    value: "PERIOD_6MONTH",
    start: dayjs()
      .subtract(6, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const changeDateFilter = (type: string) => {
    if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_1MONTH" ? 1 : type === "PERIOD_3MONTH" ? 3 : 6,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };
  const dateModal = () => {
    return (
      <ModalNew
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };
  useEffect(() => {
    getRankByPeriod({
      startDate: selectedPeriod.start / 1000,
      endDate: selectedPeriod.end / 1000,
      intervalTime: utilsCommon.getIntervalTime(),
    });
  }, [selectedPeriod]);

  const [buttonClick, setButtonClick] = useState(0);

  return (
    <BoxNew classes="main reportStatistics">
      {openDateModal && dateModal()}
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath
            path={[t("menu.reportManagement"), t("menu.reportGraph")]}
          />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.reportGraph")}
          </TypographyNew>
        </BoxNew>
      </BoxNew>
      <BoxNew width={"100%"} direction="column">
        <BoxNew>
          <BoxNew classes="gap-8 dropdown-filter-container" alignItems="center">
            <Dropdown
              btnStyle={"round"}
              btnSize={"md"}
              placeholder={t("common.time.period")}
              value={
                selectedPeriod.value === "PERIOD_3MONTH"
                  ? t("report.stats.reportRecent3Month")
                  : selectedPeriod.value === "PERIOD_6MONTH"
                  ? t("report.stats.reportRecent6Month")
                  : selectedPeriod.value === "PERIOD_1MONTH"
                  ? t("report.stats.reportRecent1Month")
                  : selectedPeriod.value === "ALL"
                  ? ""
                  : `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
              }
              defaultValue={"PERIOD_6MONTH"}
              showValue
              selectValue={selectedPeriod.value}
              buttonClick={buttonClick}
              buttonWidth={224}
            >
              <DropdownItem
                checked={selectedPeriod.value === "PERIOD_1MONTH"}
                checkColor={selectedPeriod.value === "PERIOD_1MONTH"}
                onClick={() => {
                  changeDateFilter("PERIOD_1MONTH");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
                isFullWidth
                label={t("report.stats.reportRecent1Month")}
                type={"check"}
              />
              <DropdownItem
                checked={selectedPeriod.value === "PERIOD_3MONTH"}
                checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
                onClick={() => {
                  changeDateFilter("PERIOD_3MONTH");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
                isFullWidth
                label={t("report.stats.reportRecent3Month")}
                type={"check"}
              />
              <DropdownItem
                checked={selectedPeriod.value === "PERIOD_6MONTH"}
                checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
                onClick={() => {
                  changeDateFilter("PERIOD_6MONTH");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
                isFullWidth
                label={t("report.stats.reportRecent6Month")}
                type={"check"}
              />

              <DropdownItem
                label=""
                onClick={() => {}}
                state="divider"
                type="default"
              />
              <DropdownItem
                checked={selectedPeriod.value === "PERIOD_CUSTOM"}
                checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
                onClick={() => {
                  changeDateFilter("PERIOD_CUSTOM");
                }}
                isFullWidth
                label={t("graph.phishingCustomScope")}
                type={"check"}
              />
            </Dropdown>
          </BoxNew>
        </BoxNew>
        <StaticsPage period={selectedPeriod} />
      </BoxNew>
    </BoxNew>
  );
};

export default ReportStatistics;
