import {
  Modal,
  CalendarRange,
  Box,
  Button,
  getColor,
  Icons,
  Typography,
  Tabs,
  useToast,
  Dropdown,
  DropdownItem,
  Table,
  ColumnDatas,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useEffect, useLayoutEffect, useState } from "react";
import useIpStore from "../../../redux/dispatcher/useIpStore";
import { IpFilterType } from "../../../types/Ip";
import utilsCommon from "../../../shared/utils/common";
import InsertUpdateIPModal from "./InsertUpdateIPModal";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { getUserInfoFromLocalStorage } from "../../../shared/utils/auth";
import { useTableOrder } from "../../../hooks/useTableOrder";
import { TABLE_HIGHLIGHT_TIMED_OUT } from "../../../shared/constant";
import useTimedReset from "../../../hooks/useTimedReset";

enum ColumnsTypeToOrder {
  createDate = "createDate",
}

const ManageLog: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    ipState,
    getIpList,
    deleteIp,
    downloadFilteredLog,
    donwloadAccessLog,
  } = useIpStore();
  const { programState, getProgramList } = useProgramStore();
  const checkboxGroup = useCheckboxGroup<number>();
  const toast = useToast();
  const [height, setHeight] = useState(window.innerHeight - 330);
  const [tab, setTab] = useState(0);
  const [whiteIPlist, changeWhiteIPList] = useState<IpFilterType[]>([]);
  const [blackIPlist, changeBlackIPList] = useState<IpFilterType[]>([]);
  const [buttonClick, setButtonClick] = useState(0);
  const [insertUpdateIPModalInfo, changeInsertUpdateIPModalInfo] = useState({
    isOpen: false,
    modalType: "CLOSE",
    ipCode: -1,
  });
  const [deleteModalInfo, changeDeleteModalInfo] = useState({
    isOpen: false,
    targetIpId: -1,
  });
  const [rowIdCreated, setRowIdCreated] = useTimedReset<string>(
    "",
    TABLE_HIGHLIGHT_TIMED_OUT
  );
  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setSearchFilter((searchFilter) => ({
          ...searchFilter,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        }));
    },
  });
  const [searchFilter, setSearchFilter] = useState({
    orderId: getOrderParams().orderId,
    orderType: getOrderParams().orderType,
  });

  useEffect(() => {
    getIpList(searchFilter);
    getProgramList({ size: 3 });
  }, []);

  useEffect(() => {
    checkboxGroup.handleAllClick([]);
  }, [tab]);
  useEffect(() => {
    changeWhiteIPList(
      ipState.ipList.filter((item: IpFilterType) => item.ipType === "W")
    );
    changeBlackIPList(
      ipState.ipList.filter((item: IpFilterType) => item.ipType === "B")
    );
  }, [ipState.ipList]);

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [whiteIPlist, blackIPlist]);

  function transformData(data: IpFilterType[]) {
    const dataTransformed = [] as ColumnDatas[];
    data?.map((item: IpFilterType) => {
      const dataItemTransformed = {
        data: [
          { columnText: item.ipAddress },
          {
            columnText: utilsCommon.getShortDate(item.createDate),
          },
          { columnText: item.summary },
        ],
        id: String(item.ipId),
        checkBoxClick: () => {
          checkboxGroup.handleClick(item.ipId ?? -1);
        },
        hover: (
          <Dropdown buttonClick={buttonClick} align="right" width={180}>
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                changeInsertUpdateIPModalInfo({
                  isOpen: true,
                  ipCode: item.ipId ?? -1,
                  modalType: "UPDATE",
                });
              }}
              label={
                <Box>
                  <Icons variant="line" label="edit2" />
                  <Typography classes="mg ml-8">
                    {t("common.button.update")}
                  </Typography>
                </Box>
              }
            />
            <DropdownItem state="divider" />
            <DropdownItem
              type="warning"
              onClick={(e) => {
                e.stopPropagation();
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                changeDeleteModalInfo({
                  isOpen: true,
                  targetIpId: item?.ipId ?? -1,
                });
              }}
              label={
                <Box>
                  <Icons
                    variant="line"
                    label="delete"
                    stroke={getColor("COLOR_ICON_ERROR")}
                  />
                  <Typography
                    classes="mg ml-8"
                    exactColor={getColor("COLOR_TEXT_ERROR")}
                  >
                    {t("common.button.delete")}
                  </Typography>
                </Box>
              }
            />
          </Dropdown>
        ),
      };
      dataTransformed.push(dataItemTransformed);
    });
    return dataTransformed;
  }

  const renderListTable = (list: IpFilterType[]) => {
    return (
      <Box
        classes="mg mt-16"
        direction="column"
        height="calc(100% - 16px)"
        width={"100%"}
      >
        <Box justifyContent="space-between" height={36}>
          <Box alignItems="center" height={36}>
            <Typography type="subtitle2">IP</Typography>
            <Typography
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_BRAND")}
              classes="mg ml-4"
            >
              {list?.length ?? 0}
            </Typography>
          </Box>
          <Box alignItems="center">
            <Button
              size="sm"
              btnStyle="link"
              leadingIcon={<Icons variant="line" label="edit2" />}
              onClick={() =>
                changeInsertUpdateIPModalInfo({
                  isOpen: true,
                  ipCode: checkboxGroup.selected[0] ?? -1,
                  modalType: "UPDATE",
                })
              }
              label={t("common.button.update")}
              state={
                checkboxGroup.selected.length === 1 ? "default" : "disable"
              }
            />
            <Button
              size="sm"
              btnStyle="link"
              label={t("common.button.delete")}
              onClick={() =>
                changeDeleteModalInfo({
                  isOpen: true,
                  targetIpId: -1,
                })
              }
              leadingIcon={
                <Icons
                  variant="line"
                  label="delete"
                  stroke={getColor("COLOR_ICON_ERROR")}
                />
              }
              textColor="red"
              state={
                checkboxGroup.selected.length === 0 ? "disable" : "default"
              }
            />
          </Box>
        </Box>

        {list && list.length > 0 ? (
          <Box direction="column" height="calc(100% - 40px)" width={"100%"}>
            <Table
              highlightIds={[rowIdCreated]}
              border="none"
              clickIds={checkboxGroup.selected.map((userId) => String(userId))}
              columnHeight="md"
              data={transformData(list)}
              totalCount={list.length}
              endReached={() => {}}
              checkBoxClick={() =>
                checkboxGroup.handleAllClick(
                  checkboxGroup.selected.length === list.length
                    ? ([] as number[])
                    : list?.map((item) => item.ipId ?? -1)
                )
              }
              header={[
                {
                  headerText: "IP",
                  width: "344px",
                },
                {
                  headerText: t("common.time.updateDate"),
                  width: "300px",
                  ...getOrderRenderProps(ColumnsTypeToOrder.createDate),
                },
                {
                  headerText: t("log.description"),
                  width: "calc(100% - 600px)",
                },
              ]}
              width={"100%"}
              height={list?.length > 0 ? height + "px" : "0px"}
            />
          </Box>
        ) : (
          <Box
            width={"100%"}
            height={500}
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <svg
              width="360"
              height="200"
              viewBox="0 0 360 200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="174" y="27" width="84" height="108" fill="#DDE1E7" />
              <circle cx="190.5" cy="41.5" r="6.5" fill="#64D2D0" />
              <rect
                width="84"
                height="12"
                transform="matrix(1 0 1.04361e-06 -1 174 135)"
                fill="#DDE1E7"
              />
              <rect x="184" y="68" width="64" height="2.99999" fill="#DDE1E7" />
              <rect x="184" y="59" width="64" height="2.99999" fill="#DDE1E7" />
              <rect x="184" y="98" width="64" height="2.99999" fill="#DDE1E7" />
              <rect x="184" y="89" width="64" height="2.99999" fill="#DDE1E7" />
              <rect
                x="102.484"
                y="47"
                width="12"
                height="12"
                transform="rotate(45 102.484 47)"
                fill="#FFC34E"
              />
              <rect x="164" y="17" width="84" height="108" fill="#EBF0F2" />
              <circle cx="182" cy="33" r="8" fill="#64D2D0" />
              <rect
                width="84"
                height="12"
                transform="matrix(1 0 1.04361e-06 -1 164 125)"
                fill="#CCD1D9"
              />
              <rect x="174" y="59" width="64" height="4" fill="#D0D5D8" />
              <rect x="174" y="49" width="64" height="4" fill="#D0D5D8" />
              <rect x="174" y="88" width="64" height="2.99999" fill="#D0D5D8" />
              <rect x="174" y="79" width="64" height="2.99999" fill="#D0D5D8" />
              <rect
                x="108"
                y="75"
                width="118"
                height="84"
                rx="6"
                fill="#4593FC"
              />
              <rect
                x="216"
                y="145"
                width="50"
                height="6"
                transform="rotate(-180 216 145)"
                fill="#1B64DA"
              />
              <rect
                x="216"
                y="109"
                width="88"
                height="5.99999"
                transform="rotate(-180 216 109)"
                fill="#1B64DA"
              />
              <rect
                x="124"
                y="109"
                width="5.99877"
                height="5.99877"
                transform="rotate(-180 124 109)"
                fill="#1B64DA"
              />
              <rect x="128" y="89" width="51" height="6" fill="#1B64DA" />
              <rect
                x="124"
                y="95"
                width="5.99971"
                height="5.99971"
                transform="rotate(-180 124 95)"
                fill="#1B64DA"
              />
              <rect
                x="278.949"
                y="125"
                width="7"
                height="7"
                transform="rotate(45 278.949 125)"
                fill="#FFE0A3"
              />
              <rect
                x="130.949"
                y="7"
                width="7"
                height="7"
                transform="rotate(45 130.949 7)"
                fill="#FFE0A3"
              />
              <circle cx="291" cy="43" r="12" fill="#A6C7FF" />
              <path
                d="M289.782 44.9728C289.782 44.2078 289.875 43.5986 290.061 43.1451C290.246 42.6915 290.585 42.2462 291.077 41.8091C291.574 41.3665 291.905 41.0086 292.069 40.7354C292.232 40.4568 292.314 40.1644 292.314 39.8585C292.314 38.935 291.888 38.4733 291.036 38.4733C290.631 38.4733 290.306 38.599 290.061 38.8503C289.82 39.0962 289.694 39.4377 289.683 39.8748H287.307C287.318 38.8312 287.654 38.0143 288.315 37.4242C288.981 36.8341 289.888 36.5391 291.036 36.5391C292.194 36.5391 293.093 36.8205 293.732 37.3833C294.372 37.9406 294.691 38.7301 294.691 39.7519C294.691 40.2163 294.587 40.6562 294.38 41.0715C294.172 41.4813 293.809 41.9375 293.29 42.4402L292.626 43.0713C292.211 43.4702 291.973 43.9373 291.913 44.4728L291.88 44.9728H289.782ZM289.544 47.4889C289.544 47.1229 289.667 46.8223 289.913 46.5874C290.164 46.347 290.484 46.2268 290.872 46.2268C291.26 46.2268 291.577 46.347 291.823 46.5874C292.074 46.8223 292.2 47.1229 292.2 47.4889C292.2 47.8496 292.077 48.1474 291.831 48.3823C291.59 48.6173 291.271 48.7347 290.872 48.7347C290.473 48.7347 290.151 48.6173 289.905 48.3823C289.664 48.1474 289.544 47.8496 289.544 47.4889Z"
                fill="white"
              />
              <circle cx="152.31" cy="182.31" r="9.31034" fill="#4593FC" />
              <path
                d="M151.366 183.84C151.366 183.247 151.438 182.774 151.582 182.422C151.726 182.07 151.989 181.725 152.37 181.386C152.756 181.042 153.013 180.765 153.14 180.553C153.267 180.336 153.331 180.11 153.331 179.872C153.331 179.156 153 178.798 152.339 178.798C152.025 178.798 151.773 178.895 151.582 179.09C151.395 179.281 151.298 179.546 151.289 179.885H149.445C149.454 179.075 149.715 178.441 150.227 177.984C150.745 177.526 151.448 177.297 152.339 177.297C153.237 177.297 153.935 177.515 154.431 177.952C154.927 178.384 155.175 178.997 155.175 179.79C155.175 180.15 155.094 180.491 154.933 180.813C154.772 181.131 154.49 181.485 154.087 181.875L153.572 182.365C153.25 182.674 153.066 183.037 153.019 183.452L152.994 183.84H151.366ZM151.181 185.792C151.181 185.508 151.277 185.275 151.467 185.093C151.662 184.906 151.91 184.813 152.211 184.813C152.512 184.813 152.758 184.906 152.949 185.093C153.144 185.275 153.242 185.508 153.242 185.792C153.242 186.072 153.146 186.303 152.955 186.486C152.769 186.668 152.521 186.759 152.211 186.759C151.902 186.759 151.652 186.668 151.461 186.486C151.275 186.303 151.181 186.072 151.181 185.792Z"
                fill="white"
              />
              <circle cx="82.2069" cy="81.2069" r="6.2069" fill="#C5DAFF" />
              <path
                d="M81.5791 82.2294C81.5791 81.8338 81.6271 81.5187 81.7232 81.2841C81.8193 81.0495 81.9946 80.8192 82.2489 80.5931C82.5061 80.3641 82.6771 80.179 82.7619 80.0377C82.8467 79.8936 82.8891 79.7424 82.8891 79.5841C82.8891 79.1065 82.6686 78.8677 82.2277 78.8677C82.0186 78.8677 81.8504 78.9327 81.7232 79.0627C81.5989 79.1899 81.5339 79.3665 81.5282 79.5926H80.2988C80.3045 79.0528 80.4783 78.6303 80.8203 78.325C81.1651 78.0198 81.6342 77.8672 82.2277 77.8672C82.8269 77.8672 83.2918 78.0127 83.6225 78.3038C83.9531 78.5921 84.1185 79.0005 84.1185 79.529C84.1185 79.7692 84.0648 79.9967 83.9574 80.2115C83.85 80.4235 83.662 80.6595 83.3935 80.9195L83.0501 81.2459C82.8354 81.4522 82.7124 81.6939 82.6813 81.9709L82.6644 82.2294H81.5791ZM81.4562 83.5309C81.4562 83.3416 81.5198 83.1861 81.6469 83.0646C81.7769 82.9402 81.9423 82.8781 82.1429 82.8781C82.3436 82.8781 82.5075 82.9402 82.6347 83.0646C82.7647 83.1861 82.8297 83.3416 82.8297 83.5309C82.8297 83.7175 82.7661 83.8715 82.6389 83.993C82.5146 84.1145 82.3492 84.1753 82.1429 84.1753C81.9366 84.1753 81.7699 84.1145 81.6427 83.993C81.5183 83.8715 81.4562 83.7175 81.4562 83.5309Z"
                fill="white"
              />
              <path d="M101.5 163L81 183.5" stroke="#64D2D0" stroke-width="4" />
              <circle
                cx="124.5"
                cy="141"
                r="31"
                fill="white"
                fill-opacity="0.2"
                stroke="#D1D6DB"
                stroke-width="4"
              />
              <path
                d="M124.355 123L144.355 143"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M115 131.25L125 141.25"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <Typography classes="mg mt-20" type="h3">
              로그 없음
            </Typography>
            <Typography
              classes="mg mt-8"
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {tab === 0
                ? "화이트리스트 IP 내역이 존재하지 않습니다."
                : "블랙리스트 IP 내역이 존재하지 않습니다."}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
  const handleDeleteIp = async (ip: number) => {
    let resp: any = await deleteIp(
      ip === -1 ? checkboxGroup.selected.map((item) => item) : [ip]
    );
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("log.msg.logDeleteSuccessMsg"), "success");
      getIpList({});
    } else {
      toast.toastMsg(nanoid(), t("log.msg.logDeleteFailMsg"), "error");
    }
    changeDeleteModalInfo({ isOpen: false, targetIpId: -1 });
    checkboxGroup.handleAllClick([]);
    getIpList({});
  };

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={480}
        footer={
          <Box justifyContent="flex-end">
            <Button
              btnStyle="fill"
              type="secondary"
              label={t("common.button.cancel")}
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
            />
            <Button
              btnStyle="fill"
              type="primary"
              label={t("attach.download")}
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }

                toggleDateModal(false);
                donwloadAccessLog({
                  startDate: utilsCommon.getShortDate(tempPeriod.start),
                  endDate: utilsCommon.getShortDate(tempPeriod.end),
                });
              }}
              classes={"mg ml-8"}
            />
          </Box>
        }
        body={
          <Box alignItems="center" justifyContent="center">
            <CalendarRange
              width={210}
              startDate={new Date(tempPeriod?.start)}
              endDate={new Date(tempPeriod?.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  start: date.start,
                  end: date.end,
                });
              }}
              maxDate={
                tempPeriod && tempPeriod.end
                  ? new Date(tempPeriod.end)
                  : new Date()
              }
            />
          </Box>
        }
      />
    );
  };
  return (
    <Box classes="main manageLog">
      {insertUpdateIPModalInfo.isOpen && (
        <InsertUpdateIPModal
          tab={tab}
          isOpen={insertUpdateIPModalInfo.isOpen}
          onClose={() =>
            changeInsertUpdateIPModalInfo({
              isOpen: false,
              ipCode: -1,
              modalType: "CLOSE",
            })
          }
          targetIPId={insertUpdateIPModalInfo.ipCode}
          modalType={insertUpdateIPModalInfo.modalType}
          setRowIdCreated={setRowIdCreated}
        />
      )}
      {deleteModalInfo.isOpen && (
        <CommonModal
          open={deleteModalInfo.isOpen}
          onClose={() =>
            changeDeleteModalInfo({ isOpen: false, targetIpId: -1 })
          }
          title={t("log.delete")}
          subMessage={t("common.msg.deleteConfirmMsg")}
          type={"delete"}
          afterSubmitButton={() => handleDeleteIp(deleteModalInfo.targetIpId)}
        />
      )}
      {openDateModal && dateModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.logManagement")]} />
          <Typography
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.logManagement")}
          </Typography>
        </Box>
        <Box>
          <Button
            type="secondary"
            btnStyle="fill"
            leadingIcon={<Icons variant="line" label="plusCircle" />}
            label={t("log.add")}
            onClick={() =>
              changeInsertUpdateIPModalInfo({
                isOpen: true,
                ipCode: -1,
                modalType: "CREATE",
              })
            }
          />
          <Button
            type="secondary"
            btnStyle="fill"
            label={t("log.downloadFilteredLog")}
            leadingIcon={<Icons variant="line" label="download" />}
            classes="mg ml-10"
            onClick={() =>
              downloadFilteredLog({
                configId: programState.configListWithCount.list[0].configId,
                type: tab === 0 ? "whitelist" : "blacklist",
              })
            }
            state={
              programState.configListWithCount.length > 0
                ? "default"
                : "disable"
            }
          />
          {getUserInfoFromLocalStorage()?.superAdmin && (
            <Button
              type="secondary"
              btnStyle="fill"
              label={t("log.downloadAllLog")}
              leadingIcon={<Icons variant="line" label="download" />}
              classes="mg ml-10"
              onClick={() => {
                toggleDateModal(true);
              }}
            />
          )}
        </Box>
      </Box>
      <Box classes="mainPadding">
        <Tabs
          tabs={[
            {
              color: "default",
              text: t("log.whiteList"),
              isNew: false,
            },
            {
              color: "default",
              text: t("log.blackList"),
              isNew: false,
            },
          ]}
          selected={tab}
          selectTab={(tab) => {
            setTab(tab);
          }}
          size={"md"}
          classes="mainPadding-tab"
        />
      </Box>
      <Box
        classes="mg mt-16"
        width={"100%"}
        style={{ height: "calc(100% - 104px)" }}
      >
        {tab === 0 && renderListTable(whiteIPlist)}
        {tab === 1 && renderListTable(blackIPlist)}
      </Box>
    </Box>
  );
};

export default ManageLog;
