import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import axiosBlob from "../../shared/utils/axiosBlob";
import axiosForm from "../../shared/utils/axiosForm";
import { BaseResponseDTO } from "../../types/Common";
import {
  QuizContentReqDTO,
  QuizContentDTO,
  QuizCategoryDTO,
  QuizTemplateDTO,
  QuizCourseDTO,
  QuizSendHistoryReqDTO,
  QuizSendHistoryResDTO,
  QuizSolvingReqDTO,
  QuizSolvingResDTO,
  QuizCourseReqDTO,
  QuizCourseResDTO,
  QuizOrganResDTO,
  QuizCourseDetailResDTO,
  QuizStatReqDTO,
  QuizStatResDTO,
  QuizAIReqDTO,
} from "../../types/Quiz";

const GATEWAY_URL = config.servers.gateway.url;

interface QuizApi {
  getQuizContents(req: QuizContentReqDTO): Promise<
    BaseResponseDTO<{
      list: QuizContentDTO[];
      total: number;
    }>
  >;
  insertQuizContent(
    req: QuizContentDTO
  ): Promise<BaseResponseDTO<QuizContentDTO>>;

  getQuizContentDetail(
    req: QuizContentDTO
  ): Promise<BaseResponseDTO<QuizContentDTO>>;

  updateQuizContent(
    req: QuizContentDTO
  ): Promise<BaseResponseDTO<QuizContentDTO>>;

  deleteQuizContent(req: {
    quizIds: number[];
  }): Promise<BaseResponseDTO<QuizContentDTO>>;

  getQuizCategory(): Promise<BaseResponseDTO<QuizCategoryDTO[]>>;

  // updateQuizCategory(    req: QuizContentDTO): Promise<BaseResponseDTO<number>>;

  insertQuizTemplate(req: {
    request: QuizTemplateDTO;
    file: File;
  }): Promise<BaseResponseDTO<QuizTemplateDTO>>;

  updateQuizTemplate(req: {
    request: QuizTemplateDTO;
    file: File;
  }): Promise<BaseResponseDTO<QuizTemplateDTO>>;

  getQuizTemplate(): Promise<BaseResponseDTO<QuizTemplateDTO>>;

  getQuizCourses(
    req: QuizCourseReqDTO
  ): Promise<BaseResponseDTO<QuizCourseResDTO>>;

  getQuizCourseDetail(
    req: QuizCourseReqDTO
  ): Promise<BaseResponseDTO<QuizCourseDetailResDTO>>;

  insertQuizCourse(req: QuizCourseDTO): Promise<BaseResponseDTO<QuizCourseDTO>>;

  updateQuizCourse(req: QuizCourseDTO): Promise<BaseResponseDTO<QuizCourseDTO>>;

  updateQuizCourse(req: QuizCourseDTO): Promise<BaseResponseDTO<QuizCourseDTO>>;

  deleteQuizCourse(req: QuizCourseDTO): Promise<BaseResponseDTO<QuizCourseDTO>>;

  getQuizSendHistory(
    req: QuizSendHistoryReqDTO
  ): Promise<BaseResponseDTO<QuizSendHistoryResDTO>>;

  resendQuizMail(
    req: QuizSendHistoryReqDTO[]
  ): Promise<BaseResponseDTO<string>>;

  exportQuizSendHistory(req?: QuizSendHistoryReqDTO): Promise<any>;

  isValidQuizAccess(
    req: QuizSolvingReqDTO
  ): Promise<BaseResponseDTO<QuizSolvingResDTO>>;

  completeQuiz(req: QuizSolvingReqDTO): Promise<BaseResponseDTO<boolean>>;

  getSelectedOrgan(req: {
    userIds: string[];
    deptCodes: string[];
  }): Promise<BaseResponseDTO<QuizOrganResDTO>>;

  getQuizStat(req: QuizStatReqDTO): Promise<BaseResponseDTO<QuizStatResDTO>>;

  syncQuizContent(): Promise<BaseResponseDTO<string>>;

  createAiQuizContent(
    req: QuizAIReqDTO,
    signal?: AbortSignal
  ): Promise<BaseResponseDTO<QuizContentDTO>>;

  insertQuizCourseByTraining(
    configId: number
  ): Promise<BaseResponseDTO<QuizCourseDTO>>;
}

class QuizServerApi implements QuizApi {
  async getQuizContents(
    req: QuizContentReqDTO
  ): Promise<BaseResponseDTO<{ list: QuizContentDTO[]; total: number }>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/content/list`,
      req
    );

    return Promise.resolve(res.data);
  }

  async insertQuizContent(
    req: QuizContentDTO
  ): Promise<BaseResponseDTO<QuizContentDTO>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/content/insert`,
      req
    );

    return Promise.resolve(res.data);
  }

  async getQuizContentDetail(
    req: QuizContentDTO
  ): Promise<BaseResponseDTO<QuizContentDTO>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/content/detail`,
      req
    );

    return Promise.resolve(res.data);
  }

  async updateQuizContent(
    req: QuizContentDTO
  ): Promise<BaseResponseDTO<QuizContentDTO>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/content/update`,
      req
    );

    return Promise.resolve(res.data);
  }

  async deleteQuizContent(req: {
    quizIds: number[];
  }): Promise<BaseResponseDTO<QuizContentDTO>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/content/delete`,
      req
    );

    return Promise.resolve(res.data);
  }

  async getQuizCategory(): Promise<BaseResponseDTO<QuizCategoryDTO[]>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/content/category/list`
    );

    return Promise.resolve(res.data);
  }

  async insertQuizTemplate(req: {
    request: QuizTemplateDTO;
    file: File;
  }): Promise<BaseResponseDTO<QuizTemplateDTO>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file);

    const res = await axiosForm.post(
      `${GATEWAY_URL}/api/v2/quiz/template/insert`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return Promise.resolve(res.data);
  }

  async updateQuizTemplate(req: {
    request: QuizTemplateDTO;
    file: File;
  }): Promise<BaseResponseDTO<QuizTemplateDTO>> {
    let formData = new FormData();
    const requestBlob = new Blob([JSON.stringify(req.request)], {
      type: "application/json",
    });
    formData.append("request", requestBlob);
    formData.append("file", req.file);

    const res = await axiosForm.post(
      `${GATEWAY_URL}/api/v2/quiz/template/update`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return Promise.resolve(res.data);
  }

  async getQuizTemplate(): Promise<BaseResponseDTO<QuizTemplateDTO>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/template`);

    return Promise.resolve(res.data);
  }

  async getQuizCourseDetail(
    req: QuizCourseReqDTO
  ): Promise<BaseResponseDTO<QuizCourseDetailResDTO>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/course/detail`,
      req
    );

    return Promise.resolve(res.data);
  }

  async insertQuizCourse(
    req: QuizCourseDTO
  ): Promise<BaseResponseDTO<QuizCourseDTO>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/course/insert`,
      req
    );

    return Promise.resolve(res.data);
  }

  async getQuizCourses(
    req: QuizCourseReqDTO
  ): Promise<BaseResponseDTO<QuizCourseResDTO>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/course/list`, req);

    return Promise.resolve(res.data);
  }

  async updateQuizCourse(
    req: QuizCourseDTO
  ): Promise<BaseResponseDTO<QuizCourseDTO>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/course/update`,
      req
    );

    return Promise.resolve(res.data);
  }

  async deleteQuizCourse(
    req: QuizCourseDTO
  ): Promise<BaseResponseDTO<QuizCourseDTO>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/course/delete`,
      req
    );

    return Promise.resolve(res.data);
  }

  async getQuizSendHistory(
    req: QuizSendHistoryReqDTO
  ): Promise<BaseResponseDTO<QuizSendHistoryResDTO>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/result`, req);

    return Promise.resolve(res.data);
  }

  async exportQuizSendHistory(req?: QuizSendHistoryReqDTO): Promise<any> {
    const res = await axiosBlob.post(
      `${GATEWAY_URL}/api/v2/quiz/result/export`,
      req ?? {}
    );

    return Promise.resolve(res.data);
  }

  async resendQuizMail(
    req: QuizSendHistoryReqDTO[]
  ): Promise<BaseResponseDTO<string>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/result/resend`,
      req
    );

    return Promise.resolve(res.data);
  }

  async isValidQuizAccess(
    req: QuizSolvingReqDTO
  ): Promise<BaseResponseDTO<QuizSolvingResDTO>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/solve/auth`, req);

    return Promise.resolve(res.data);
  }

  async completeQuiz(
    req: QuizSolvingReqDTO
  ): Promise<BaseResponseDTO<boolean>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/quiz/solve/complete`,
      req
    );

    return Promise.resolve(res.data);
  }

  async getSelectedOrgan(req: {
    userIds: string[];
    deptCodes: string[];
  }): Promise<BaseResponseDTO<QuizOrganResDTO>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/organ`, req);

    return Promise.resolve(res.data);
  }

  async getQuizStat(
    req: QuizStatReqDTO
  ): Promise<BaseResponseDTO<QuizStatResDTO>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/stat`, req);

    return Promise.resolve(res.data);
  }

  async syncQuizContent(): Promise<BaseResponseDTO<string>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/sync`);

    return Promise.resolve(res.data);
  }

  async createAiQuizContent(
    req: QuizAIReqDTO,
    signal: AbortSignal
  ): Promise<BaseResponseDTO<QuizContentDTO>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/ai/create`, req, {
      signal,
    });

    return Promise.resolve(res.data);
  }

  async insertQuizCourseByTraining(
    configId: number
  ): Promise<BaseResponseDTO<QuizCourseDTO>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/quiz/course/training`, {
      configId,
    });

    return Promise.resolve(res.data);
  }
}

const quizApi: QuizApi = new QuizServerApi();

export default quizApi;
