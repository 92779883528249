import { Box, Button, InputArea, Modal, Typography, useToast } from "fds";
import { useTranslation } from "react-i18next";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { useAbortController } from "../../../hooks/useAbortController";
import { nanoid } from "@reduxjs/toolkit";
import { useState } from "react";
import SpinnerModal from "../../../component/SpinnerModal";
import { AI_GENERATE_ERROR_CODE } from "../../../types/ErrorCode";

const AIPromptModal = ({
  isAiModalOpen,
  setIsAiModalOpen,
  aiPrompt,
  setAiPrompt,
  handleSetScenario,
}: {
  isAiModalOpen: boolean;
  setIsAiModalOpen: (isAiModalOpen: boolean) => void;
  aiPrompt: string;
  setAiPrompt: (aiPrompt: string) => void;
  handleSetScenario: (data: any) => void;
}) => {
  const { t } = useTranslation();

  const { controller, abortController, resetController } = useAbortController();
  const { createAiScenario } = useMailTemplateStore();
  const [isLoadingCreateAiQuiz, setIsLoadingCreateAiQuiz] = useState(false);
  const toast = useToast();

  const onCreateAI = async () => {
    const customerCode = getLocalCustomerCode();
    setIsLoadingCreateAiQuiz(true);
    if (!customerCode) return;
    const resp: any = await createAiScenario(
      {
        receiverCompany: customerCode,
        requirement: aiPrompt,
      },
      { signal: controller?.signal }
    );

    if (resp.payload && resp.payload.code === "SUCCESS") {
      handleSetScenario(resp.payload.data);
      setIsLoadingCreateAiQuiz(false);
      setAiPrompt("");
      setIsAiModalOpen(false);
    } else if (controller?.signal.aborted) {
      resetController();
    } else if (resp.payload.code === AI_GENERATE_ERROR_CODE.REQUEST_REFUSED) {
      toast.toastMsg(
        nanoid(),
        t("quiz.quizAiModal.msg.promptErrKeywordModal"),
        "error"
      );
    } else {
      toast.toastMsg(nanoid(), t("quiz.msg.aiCreateFail"), "error");
    }
  };

  return (
    <Modal
      open={isAiModalOpen}
      onClose={() => setIsAiModalOpen(false)}
      width={540}
      title={t("quiz.label.addAiQuiz")}
      body={
        <Box direction="column">
          <Typography type="subtitle2" classes="mg mb-8">
            {t("template.prompt")}
          </Typography>
          <InputArea
            placeholder={t("template.msg.aiPromptPlaceholder")}
            value={aiPrompt}
            onChange={(e) => setAiPrompt(e.target.value)}
            size={"md"}
            width={484}
            height={240}
            maxLength={1000}
            showCount={true}
            isError={aiPrompt.length > 1000}
            helpMessage={
              aiPrompt.length > 1000 ? t("common.msg.maxLengthExceed") : ""
            }
          />
          <SpinnerModal
            isLoading={isLoadingCreateAiQuiz}
            onClose={() => {
              abortController();
              setIsLoadingCreateAiQuiz(false);
            }}
          />
        </Box>
      }
      footer={
        <Box justifyContent="flex-end" width="100%">
          <Button
            btnStyle="fill"
            type="secondary"
            onClick={() => setIsAiModalOpen(false)}
            label={t("common.button.cancel")}
          />
          <Button
            btnStyle="fill"
            type="primary"
            classes="mg ml-8"
            onClick={onCreateAI}
            state={
              aiPrompt.length > 0 && aiPrompt.length <= 1000
                ? "default"
                : "disable"
            }
            label={t("common.button.save")}
          />
        </Box>
      }
    />
  );
};

export default AIPromptModal;
