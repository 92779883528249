const NoMailImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || "360"}
      height="200"
      className={classes}
      viewBox="0 0 361 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M121 77.1654L175.958 24.622C178.217 22.4593 181.783 22.4593 184.042 24.622L239 77.1717L202.087 152H156.595L121 77.1654Z"
        fill="#1659B2"
      />
      <path d="M141 32H199L219 51V130H141V32Z" fill="#F5F8FB" />
      <circle cx="154" cy="51" r="6" fill="#64D2D0" />
      <rect x="148" y="81" width="64" height="4" fill="#CCD1D9" />
      <rect x="148" y="93" width="64" height="4" fill="#CCD1D9" />
      <rect x="148" y="69" width="64" height="4" fill="#CCD1D9" />
      <path d="M199 51H219L199 32V51Z" fill="#CCD1D9" />
      <path
        d="M184.039 129.397C181.78 131.553 178.22 131.553 175.955 129.397L121 77V140H239V77L184.039 129.397Z"
        fill="#4593FC"
      />
      <path
        d="M121 140H239V156C239 159.314 236.314 162 233 162H127C123.686 162 121 159.314 121 156V140Z"
        fill="#4593FC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M236.875 160.528C235.822 161.444 234.447 161.999 232.942 162C232.941 162 232.939 162 232.938 162H126.938C126.936 162 126.934 162 126.933 162C125.428 161.999 124.053 161.444 123 160.528L151.762 140H208.109L236.875 160.528Z"
        fill="#8ABCFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M132.207 154H227.788L195.499 130.958C186.241 124.347 173.75 124.347 164.491 130.958L132.207 154Z"
        fill="#8ABCFF"
      />
      <path
        d="M248 168L264 184"
        stroke="#6F7985"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M231 174.5C244.531 174.5 255.5 163.531 255.5 150C255.5 136.469 244.531 125.5 231 125.5C217.469 125.5 206.5 136.469 206.5 150C206.5 163.531 217.469 174.5 231 174.5Z"
        fill="white"
        fill-opacity="0.3"
        stroke="#CFD5DB"
        stroke-width="5"
      />
      <rect
        x="267.484"
        y="28"
        width="12"
        height="12"
        transform="rotate(45 267.484 28)"
        fill="#FFC34E"
      />
      <rect
        x="78.3643"
        y="72"
        width="9"
        height="9"
        transform="rotate(45 78.3643 72)"
        fill="#FFC34E"
        fill-opacity="0.5"
      />
      <path
        d="M93.2983 110.274C93.2983 109.57 92.728 109 92.0246 109C91.3212 109 90.751 109.57 90.751 110.274V113.097C90.751 113.8 91.3212 114.37 92.0246 114.37C92.728 114.37 93.2983 113.8 93.2983 113.097V110.274Z"
        fill="#64D2D0"
      />
      <path
        d="M93.2983 118.953C93.2983 118.25 92.728 117.68 92.0246 117.68C91.3212 117.68 90.751 118.25 90.751 118.953V121.776C90.751 122.48 91.3212 123.05 92.0246 123.05C92.728 123.05 93.2983 122.48 93.2983 121.776V118.953Z"
        fill="#64D2D0"
      />
      <path
        d="M97.7762 117.301C98.4796 117.301 99.0498 116.731 99.0498 116.028C99.0498 115.324 98.4796 114.754 97.7762 114.754H94.9532C94.2498 114.754 93.6795 115.324 93.6795 116.028C93.6795 116.731 94.2498 117.301 94.9532 117.301H97.7762Z"
        fill="#64D2D0"
      />
      <path
        d="M89.0965 117.301C89.7999 117.301 90.3701 116.731 90.3701 116.028C90.3701 115.324 89.7999 114.754 89.0965 114.754H86.2735C85.57 114.754 84.9998 115.324 84.9998 116.028C84.9998 116.731 85.57 117.301 86.2735 117.301H89.0965Z"
        fill="#64D2D0"
      />
    </svg>
  );
};

export default NoMailImage;
