import { Chip, useToast } from "fds";
import "../List/index.scss";
import { useRef, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import utilsCommon from "../../../../shared/utils/common";
import { t } from "i18next";
import { CCType } from ".";

interface CCInputProps {
  tags: CCType[];
  setTags: React.Dispatch<React.SetStateAction<CCType[]>>;
  isWarning: boolean;
}

const CCInput = ({ tags, setTags, isWarning }: CCInputProps) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const editorRef = useRef<HTMLDivElement>(null);
  const toast = useToast();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && editorRef.current) {
      e.preventDefault();
      const text = editorRef.current.innerText.trim();
      const tempTags = tags.map((t) => t.value);
      if (text) {
        const newTags = text
          .split(",")
          .map((t) => t.trim())
          .filter((t) => t);

        const newTagsArray: CCType[] = [];
        for (const tag of newTags) {
          let newTagValue = {
            isInvalid: false,
            value: tag,
          };
          if (tempTags.includes(tag)) {
            toast.toastMsg(nanoid(), "이미 추가된 사용자입니다.", "error");
            return;
          } else if (!utilsCommon.emailValidation(tag)) {
            newTagValue = {
              isInvalid: true,
              value: tag,
            };
          }

          newTagsArray.push(newTagValue);
        }

        setTags((prev) => [...prev, ...newTagsArray]);
        editorRef.current.innerText = "";
        setIsEmpty(true);
      }
    }
  };

  const handleInput = () => {
    if (editorRef.current) {
      setIsEmpty(editorRef.current.innerText === "");
    }
  };
  console.log(isWarning);

  return (
    <div className={`mail__cc__container ${isWarning ? `warning` : ``}`}>
      <div className="mail__cc__list">
        {tags.map((tag, index) => (
          <Chip
            key={tag.value}
            type={"fill"}
            size={"sm"}
            classes={`${tag.isInvalid ? `warning` : ``}`}
            showClose={true}
            text={tag.value}
            onClick={() => {
              setTags(tags.filter((item) => item.value !== tag.value));
            }}
          />
        ))}
      </div>
      <div
        ref={editorRef}
        contentEditable
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        className={`mail__cc__input ${
          isEmpty && tags.length === 0 ? "mail__cc__placeholder" : ""
        }`}
      />
    </div>
  );
};

export default CCInput;
