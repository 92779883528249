import {
  Box,
  DropdownBody,
  DropdownItem,
  getColor,
  Icons,
  Typography,
} from "fds";
import { useTranslation } from "react-i18next";
import useOutsideClick from "../../hooks/useOutsideClick";

const HoverClickBox = ({
  // REFACT: 확장 가능한 방식으로 리팩토링
  isDisplayed,
  hoverBoxNewCoord,
  onOutsideClick,
  onClickDetail,
  onClickUpdate,
  onClickDownload,
  onClickDelete,
  onClickPreview,
  width = 116,
}: {
  isDisplayed: boolean;
  hoverBoxNewCoord: { x: number; y: number };
  onOutsideClick: (event: MouseEvent) => void;
  onClickDetail?: (event: MouseEvent) => void;
  onClickUpdate?: (event: MouseEvent) => void;
  onClickDownload?: (event: MouseEvent) => void;
  onClickDelete?: (event: MouseEvent) => void;
  onClickPreview?: (event: MouseEvent) => void;
  width?: number;
}) => {
  const { t } = useTranslation();
  const [targetRef] = useOutsideClick(onOutsideClick);

  return (
    <DropdownBody
      location={{
        x: hoverBoxNewCoord.x,
        y: hoverBoxNewCoord.y,
      }}
      style={{
        position: "fixed",
        display: isDisplayed ? "block" : "none",
        pointerEvents: "auto",
      }}
      width={width}
    >
      <Box ref={targetRef} direction={"column"}>
        {onClickDetail && (
          <DropdownItem
            frontIcon={<Icons variant={"line"} label={"info"} />}
            label={t("common.button.detail")}
            onClick={onClickDetail}
          />
        )}
        {onClickUpdate && (
          <DropdownItem
            frontIcon={<Icons variant={"line"} label={"edit2"} />}
            label={t("common.button.update")}
            onClick={onClickUpdate}
          />
        )}
        {onClickDownload && (
          <DropdownItem
            frontIcon={<Icons variant={"line"} label={"download"} />}
            label={t("common.button.download")}
            onClick={onClickDownload}
          />
        )}
        {onClickPreview && (
          <DropdownItem
            frontIcon={<Icons variant={"line"} label={"preview"} isOn />}
            label={t("common.button.preview")}
            onClick={onClickPreview}
          />
        )}
        {onClickDelete && <DropdownItem state={"divider"} />}
        {onClickDelete && (
          <DropdownItem
            frontIcon={
              <Icons
                variant={"line"}
                label={"delete"}
                stroke={getColor("COLOR_TEXT_ERROR")}
              />
            }
            label={
              <Typography exactColor={getColor("COLOR_TEXT_ERROR")}>
                {t("common.button.delete")}
              </Typography>
            }
            onClick={onClickDelete}
          />
        )}
      </Box>
    </DropdownBody>
  );
};
export default HoverClickBox;
