import {
  Box as BoxNew,
  Typography as TypographyNew,
  Icons as IconsNew,
  getColor,
  Tag as TagNew,
  Accordions as AccordionsNew,
  Button as ButtonNew,
  Table as TableNew,
  DropdownBody,
  Avatar,
  Tooltip as TooltipNew,
  useToast as useToastNew,
  Modal as ModalNew,
  Tooltip,
} from "fds";

import { useEffect, useRef, useState } from "react";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import {
  ImagesInfo,
  ProgramDetail,
  UserBean,
  userListBean,
} from "../../../types/Info";
import utilsCommon from "../../../shared/utils/common";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import { SimpleTreeNode } from "../../../types/User";
import dayjs from "dayjs";
import { nanoid } from "@reduxjs/toolkit";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { Scenario } from "../../../types/Scenario";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MailBody from "../../../component/MailBody";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import useSendHistoryStore from "../../../redux/dispatcher/useSendHistoryStore";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { QuizContentDTO, QuizTrainingInfoReq } from "../../../types/Quiz";
import TitlePath from "../../../component/TitlePath";
import HoverBox from "../../../component/HoverBox";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import IconMindSat from "../../../shared/image/icon";

import "./index.scss";
import "../../../shared/style/stickyLayout.scss";

import useStickyPage from "../../../hooks/useStickyObserver";
import classNames from "classnames";
import ButtonGoBack from "../../../component/ButtonGoBack";
import NoQuizImage from "../../../shared/image/NoQuizImage";
import { DeptType } from "../../../types/Dept";

const TrainingDetail: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToastNew();
  const navigate = useNavigate();

  const { deptState, getDeptList } = useDeptStore();
  const { programState, getProgramDetail, deleteProgram } = useProgramStore();
  const { mailTemplateState, getMailTemplateList } = useMailTemplateStore();
  const { getImages, contentState } = useContentStore();
  const { sendResultMail } = useSendHistoryStore();
  const { getQuizContentList, quizState, insertQuizCourseByTraining } =
    useQuizStore();

  const [searchParams] = useSearchParams();
  const configId = Number(searchParams.get("config_id"));

  const [quizFlag, setQuizFlag] = useState<boolean>(false);
  const [selectedQuizList, setSelectedQuizList] = useState<QuizContentDTO[]>(
    []
  );
  const [quizInfo, setQuizInfo] = useState<QuizTrainingInfoReq>({
    quizType: undefined,
    quizIds: [],
    quizCount: 0,
  });

  const [templateDetail, setTemplateDetail] = useState<ProgramDetail>();
  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const userCheckGroup = useCheckboxGroup<string>();
  const deptCheckGroup = useCheckboxGroup<string>();

  const [parsedSendTime, setParsedSendTime] = useState<{
    sendStartTime: string | null;
    sendEndTime: string | null;
  }>({
    sendStartTime: null,
    sendEndTime: null,
  });

  const [isResultMailModalOpen, setIsResultMailModalOpen] = useState(false);
  const [isQuizMailModalOpen, setIsQuizMailModalOpen] =
    useState<boolean>(false);
  const { elementRef, sentinel, isAtTop, isHorizontallyScrollable } =
    useStickyPage();

  const [isUserListScrollable, setIsUserListScrollable] =
    useState<boolean>(false);
  const userListRef = useRef<HTMLDivElement>(null);
  const MAX_USER_LIST_HEIGHT = 372;

  const initializeUserCheckbox = async () => {
    let resp: any = await getDeptList(
      programState.configDetail?.trainingInfo.deptIds
    );
    if (resp.payload) {
      let newUsers =
        resp.payload?.data?.list?.map(
          (item: SimpleTreeNode) => item.deptCode
        ) ?? [];
      userCheckGroup.handleAllClick([
        ...programState.configDetail?.trainingInfo.userIds,
        ...newUsers,
      ]);
    }
  };

  const onDelete = async () => {
    const resp: any = await deleteProgram(configId);

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeleteSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(nanoid(), t("program.msg.programDeleteFailMsg"), "error");
    }

    setIsDeleteModalOpen(false);
  };

  const parseAndSubtractHours = (time: string, hours: number): string => {
    const [hh, mm] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hh, mm, 0, 0);
    date.setHours(date.getHours() + hours);
    const newHH = date.getHours().toString().padStart(2, "0");
    const newMM = date.getMinutes().toString().padStart(2, "0");
    return `${newHH}:${newMM}`;
  };

  const onSendResultMail = async (configId: number) => {
    const resp: any = await sendResultMail(configId);

    if (resp.payload && resp.payload.success === true) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.sendResultMailSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(nanoid(), t("program.msg.sendResultMailFailMsg"), "error");
    }

    setIsResultMailModalOpen(false);
  };

  const onSendQuizMail = async (configId: number) => {
    const resp: any = await insertQuizCourseByTraining(configId);

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("quiz.msg.addQuizCourseSuccess"), "success");
    } else {
      toast.toastMsg(nanoid(), t("quiz.msg.addQuizCourseFail"), "error");
    }

    setIsQuizMailModalOpen(false);
    navigate(`${process.env.PUBLIC_URL}/quiz/course`);
  };

  function transformQuiz(quizzes: QuizContentDTO[]) {
    const dataTransformed = [];
    for (let i = 0; i < quizzes.length; i++) {
      const q = quizzes[i];

      const dataType = q.commonId ? (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
        />
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.private")}
          textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
        />
      );
      const dataQuizName = (
        <Tooltip message={q.quizName}>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {q.quizName}
          </TypographyNew>
        </Tooltip>
      );
      const dataCategory = q.categorys && (
        <CategoryTagList categorys={q.categorys} />
      );
      const dataFormal = (
        <TypographyNew
          ellipsis
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {q.quizType === 1
            ? t("quiz.label.formatTF")
            : t("quiz.label.formatChoice")}
        </TypographyNew>
      );

      const qTransformed = {
        data: [
          { columnText: "" },
          { columnText: dataType },
          { columnText: dataQuizName },
          { columnText: dataCategory },
          { columnText: dataFormal },
        ],
        id: String(q.quizId),
      };
      dataTransformed.push(qTransformed);
    }
    return dataTransformed;
  }

  useEffect(() => {
    if (templateDetail?.trainingInfo.sendStartTime) {
      const tempStartTime = templateDetail.trainingInfo.sendStartTime;
      const tempEndTime = templateDetail.trainingInfo.sendEndTime;

      const newSendStartTime = parseAndSubtractHours(
        tempStartTime as string,
        9
      );
      const newSendEndTime = parseAndSubtractHours(tempEndTime as string, 9);

      setParsedSendTime({
        sendStartTime: newSendStartTime,
        sendEndTime: newSendEndTime,
      });
    }
  }, [templateDetail?.trainingInfo]);

  useEffect(() => {
    getProgramDetail(configId);
    getDeptList();
    getImages({});
  }, []);

  useEffect(() => {
    changeOrganTree(deptState.organList);
  }, [deptState.organList]);

  useEffect(() => {
    if (programState.configDetail) {
      setTemplateDetail(programState.configDetail);
      getMailTemplateList({});
      deptCheckGroup.handleAllClick(
        programState.configDetail.trainingInfo.deptIds
      );
      initializeUserCheckbox();
      changeOrganTree(
        deptState.organList?.filter((item: SimpleTreeNode) =>
          [
            programState.configDetail.trainingInfo.deptIds,
            programState.configDetail.trainingInfo.userIds,
          ].includes(item.deptCode)
        )
      );
      if (programState?.configDetail.trainingInfo.quizType) {
        setQuizFlag(true);
        setQuizInfo({
          quizCount: programState?.configDetail.trainingInfo.quizCount,
          quizIds: programState?.configDetail.trainingInfo.quizIds,
          quizType: programState?.configDetail.trainingInfo.quizType,
        });
        getQuizContentList({});
      } else {
        setQuizInfo({ quizType: undefined, quizIds: [], quizCount: 0 });
      }
    }
  }, [programState.configDetail]);

  useEffect(() => {
    if (userListRef.current) {
      const height = userListRef.current.scrollHeight;
      setIsUserListScrollable(height >= MAX_USER_LIST_HEIGHT);
    }
  }, [templateDetail?.userList, organTree]);

  useEffect(() => {
    if (configId) {
      if (
        quizState.quizContentList &&
        quizState.quizContentList.list.length > 0 &&
        programState?.configDetail.trainingInfo.quizType
      ) {
        setSelectedQuizList(
          quizState.quizContentList.list.filter((t: QuizContentDTO) =>
            programState?.configDetail.trainingInfo.quizIds.some(
              (q: string) => parseInt(q) === t.quizId
            )
          )
        );
      }
    }
  }, [quizState.quizContentList]);

  if (!templateDetail?.trainingInfo) return null;

  function getScenariosDynamically() {
    if (!templateDetail) return [];
    const RANDOM_TEMPLATES_EDITABLE = templateDetail.templateList;
    const TEMPLATES_SENT = templateDetail.trainingInfo.templateIds || [];

    if (RANDOM_TEMPLATES_EDITABLE.length > 0) {
      return templateDetail.templateList;
    }
    if (TEMPLATES_SENT.length > 0) {
      return mailTemplateState.mailTemplateList.filter((item: Scenario) =>
        TEMPLATES_SENT.includes(item.templateId.toString())
      );
    }
    return [];
  }

  function checkScenarioTableRenderStatus() {
    const hasScenarioList = getScenariosDynamically().length > 0;
    if (!hasScenarioList) return 1;
    else return 0;
  }

  function checkQuizTableRenderStatus() {
    const hasQuizList = selectedQuizList.length > 0;
    if (!hasQuizList) return 1;
    else return 0;
  }

  function renderScenarioTable() {
    if (checkScenarioTableRenderStatus() === 1) {
      return noScenarioSelected;
    }
  }

  function renderQuizTable() {
    if (checkQuizTableRenderStatus() === 1) {
      return noQuizSelected;
    }
  }

  const noScenarioSelected = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <NoQuizImage />
      <TypographyNew
        type="caption_rg"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
      >
        {t("quiz.msg.noQuizSelected")}
      </TypographyNew>
    </BoxNew>
  );

  const noQuizSelected = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <NoQuizImage />
      <TypographyNew
        type="caption_rg"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
      >
        {t("quiz.msg.noQuizSelected")}
      </TypographyNew>
    </BoxNew>
  );

  function transformScenarioList(categories: Scenario[]) {
    const scenarioListTransformed = [];
    for (let i = 0; i < categories.length; i++) {
      const categoryCur = categories[i];
      const dataIsCommon = categoryCur.commonId ? (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
        />
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.private")}
          textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
        />
      );

      const dataScenarioName = (
        <BoxNew>
          <TooltipNew
            clickable
            place="top"
            message={
              categoryCur.templateHtml ? (
                <>
                  <BoxNew classes="mg mb-8">
                    <TypographyNew
                      type="body4_rg"
                      exactColor={getColor("COLOR_TEXT_INTERACTIVE_INVERSE")}
                      classes="scenario__tooltip__template"
                      maxWidth={240}
                    >
                      {categoryCur
                        ? categoryCur.templateName
                        : t("template.deletedScenario")}
                    </TypographyNew>
                  </BoxNew>
                  <MailBody
                    body={
                      (categoryCur.imageId || 0) > 0 && categoryCur.templateHtml
                        ? categoryCur.templateHtml.replaceAll(
                            "##IMAGE##",
                            contentState.images.filter(
                              (item: ImagesInfo) =>
                                item.imageId === categoryCur.imageId
                            )[0]?.imagePath ?? "##IMAGE##"
                          )
                        : categoryCur.templateHtml
                    }
                    mailWidth={240}
                    height={200}
                    allowScroll={true}
                    id={categoryCur.documentId}
                  />
                </>
              ) : (
                t("template.deletedScenario")
              )
            }
            maxWidth={"100%"}
            children={
              <TypographyNew ellipsis>{categoryCur.templateName}</TypographyNew>
            }
          />
        </BoxNew>
      );

      const dataCategory = categoryCur.categorys && (
        <CategoryTagList categorys={categoryCur.categorys} />
      );

      const scenarioTransformed = {
        data: [
          { columnText: dataIsCommon },
          { columnText: dataScenarioName },
          { columnText: dataCategory },
        ],
        id: String(categoryCur.templateId),
      };

      scenarioListTransformed.push(scenarioTransformed);
    }

    return scenarioListTransformed;
  }

  const scenarioBody = (
    <BoxNew classes="mg ml-8 mt-40" direction="column">
      <BoxNew classes="mg mb-28">
        <BoxNew width={220} direction="column" classes="mg mr-28">
          <BoxNew classes="mg mb-4">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("menu.template")}
            </TypographyNew>
          </BoxNew>
        </BoxNew>
        <BoxNew alignItems="center" direction="column" width={590}>
          {/* 시나리오 테이블  */}
          <TableNew
            classes="mg mt-12 table-chip-hover"
            border="none"
            clickIds={[]}
            columnHeight="md"
            data={transformScenarioList(getScenariosDynamically())}
            endReached={() => {}}
            header={[
              {
                headerText: t("template.type"),
                width: "72px",
              },
              {
                headerText: t("template.name"),
                width: "180px",
              },
              {
                headerText: t("category.categorySimple"),
                width: "302px",
              },
            ]}
            width={"100%"}
            height={getScenariosDynamically().length > 0 ? "248px" : "30px"}
          />
          {renderScenarioTable()}
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );

  return (
    <BoxNew classes="training-detail sticky-layout">
      <BoxNew ref={sentinel}>
        <BoxNew
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          {/* 타이틀 */}
          <BoxNew direction="column">
            <TitlePath
              path={[t("menu.trainingManagement"), t("menu.training")]}
            />
            <BoxNew alignItems="center" classes="pd pt-8">
              <ButtonGoBack
                referer={`${process.env.PUBLIC_URL}/manageTrain/program`}
              />
              <TypographyNew
                type={"h1"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.label.details")}
              </TypographyNew>
            </BoxNew>
          </BoxNew>
          {/* 버튼 */}
          <BoxNew>
            <BoxNew justifyContent="space-between">
              <BoxNew>
                <ButtonNew
                  btnStyle="fill"
                  type="secondary"
                  onClick={() => {
                    navigate(`/manageTrain/result`);
                    window.sessionStorage.setItem(
                      "resultFilter",
                      `configId=${templateDetail?.trainingInfo?.configId}`
                    );
                  }}
                  label={t("program.sendAndResultFlag")}
                  leadingIcon={<IconsNew variant="line" label="mail" />}
                  size={"lg"}
                  state={
                    dayjs(
                      templateDetail?.trainingInfo.sendStartDate + "+00:00"
                    ).valueOf() < dayjs().valueOf()
                      ? "default"
                      : "disable"
                  }
                />

                <ButtonNew
                  btnStyle="fill"
                  type="secondary"
                  classes="mg ml-10"
                  label={t("common.button.update")}
                  leadingIcon={<IconsNew variant="line" label="edit" />}
                  size={"lg"}
                  onClick={() =>
                    navigate(`${process.env.PUBLIC_URL}/manageTrain/program/write?config_id=${configId}
`)
                  }
                  state={
                    !(
                      (templateDetail?.trainingInfo?.userCount &&
                        templateDetail?.trainingInfo?.sendMailCount &&
                        templateDetail?.trainingInfo.sendCnt ===
                          templateDetail?.trainingInfo?.userCount *
                            templateDetail?.trainingInfo?.sendMailCount) ||
                      dayjs(
                        templateDetail?.trainingInfo.sendEndDate + "+00:00"
                      ).valueOf() < dayjs().valueOf()
                    )
                      ? "default"
                      : "disable"
                  }
                />
                <ButtonNew
                  btnStyle="fill"
                  type="error"
                  classes="mg ml-10"
                  onClick={() => setIsDeleteModalOpen(true)}
                  label={t("program.delete")}
                  leadingIcon={<IconsNew variant="line" label="delete" />}
                  size={"lg"}
                  state={
                    !templateDetail?.trainingInfo?.startedFlag &&
                    dayjs(
                      templateDetail?.trainingInfo.sendStartDate + "+00:00"
                    ).valueOf() > dayjs().valueOf()
                      ? "default"
                      : "disable"
                  }
                />
              </BoxNew>
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </BoxNew>

      {/* 신규 본문 */}
      {templateDetail && (
        <BoxNew classes="body">
          {/* basic info start  */}
          <AccordionsNew
            label={t("quiz.label.basicInfo")}
            initialOpenStatus={true}
          >
            {/* LOW: grid로 리팩토링 권고 */}
            <BoxNew
              direction="column"
              classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28"
            >
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("program.programName")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    width={598}
                  >
                    {templateDetail?.trainingInfo.configName}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("program.trainingDuration")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    classes="tnum"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {`${utilsCommon.getFullDateStr(
                      templateDetail?.trainingInfo.sendStartDate + "+00:00"
                    )} ~ ${utilsCommon.getFullDateStr(
                      templateDetail?.trainingInfo.sendEndDate + "+00:00"
                    )}`}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("program.sendingMailTime")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {templateDetail?.trainingInfo.sendStartTime
                      ? `${parsedSendTime.sendStartTime} ~ ${parsedSendTime.sendEndTime}`
                      : t("program.programAllDay")}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("program.sendMailCnt")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {t("program.sendCnt", {
                      length: templateDetail?.trainingInfo.sendMailCount,
                    })}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("program.collectionPeriod")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {templateDetail?.trainingInfo.collectionPeriod} 일
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("program.setActivateFlag")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {templateDetail?.trainingInfo.activateFlag
                      ? t("program.activated")
                      : t("program.deactivated")}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("reply.isActivateAutoReply")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {templateDetail?.trainingInfo.autoReplyFlag
                      ? t("program.activated")
                      : t("program.deactivated")}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("program.progressStatus")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {templateDetail?.trainingInfo?.sendCnt === 0
                      ? templateDetail?.trainingInfo?.startedFlag ||
                        (templateDetail?.trainingInfo?.sendStartDate &&
                          dayjs(
                            templateDetail?.trainingInfo?.sendStartDate +
                              "+00:00"
                          ).valueOf() < dayjs().valueOf())
                        ? "0%"
                        : t("program.programSendWait")
                      : templateDetail?.trainingInfo?.sendCnt &&
                        templateDetail?.trainingInfo?.userCount &&
                        templateDetail?.trainingInfo?.sendMailCount
                      ? `${(
                          (templateDetail?.trainingInfo?.sendCnt /
                            (templateDetail?.trainingInfo?.userCount *
                              templateDetail?.trainingInfo?.sendMailCount)) *
                          100
                        ).toFixed(0)} %`
                      : t("program.programSendWait")}
                    {templateDetail?.trainingInfo.sendMailCount !== 0 &&
                      templateDetail?.trainingInfo.sendMailCount !==
                        undefined &&
                      templateDetail?.trainingInfo.userCount !== 0 &&
                      templateDetail?.trainingInfo.userCount !== undefined &&
                      ` (${templateDetail?.trainingInfo.sendCnt} / ${
                        templateDetail?.trainingInfo.sendMailCount *
                        templateDetail?.trainingInfo.userCount
                      } ${t("program.programDataUnit")})`}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </AccordionsNew>
          {/* basic info end   */}

          {/* user info start  */}
          <AccordionsNew
            label={t("quiz.label.targets")}
            initialOpenStatus={true}
          >
            <BoxNew classes="pd pl-8 pr-8 pb-28" direction="column">
              {templateDetail?.userList &&
              templateDetail?.userList.length > 0 ? (
                <BoxNew direction="row" classes="mg mt-32">
                  <BoxNew width={220} height={"100%"}>
                    <TypographyNew
                      type="subtitle2"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    >
                      {t("user.user")}
                    </TypographyNew>
                  </BoxNew>

                  <BoxNew width={620} classes="mg ml-28">
                    <BoxNew
                      ref={userListRef}
                      classes={classNames("user-list", {
                        scrollable: isUserListScrollable,
                      })}
                      style={{
                        maxHeight: `${MAX_USER_LIST_HEIGHT}px`,
                      }}
                    >
                      {(templateDetail?.userList).map((u: userListBean) => (
                        <BoxNew alignItems="center" height={32} style={{}}>
                          <BoxNew>
                            <HoverBox
                              scrollableRef={userListRef}
                              isScrollable={isUserListScrollable}
                            >
                              <HoverBox.Trigger>
                                <BoxNew classes="user-list-user">
                                  <IconsNew
                                    variant="fill"
                                    label="user"
                                    stroke={getColor(
                                      "COLOR_AVATAR_LIGHT_BLUE_50"
                                    )}
                                    classes="mg mr-4"
                                  />
                                  <TypographyNew
                                    width={256}
                                    ellipsis
                                    textAlign="start"
                                  >{`${u.userName} (${u.userEmail})`}</TypographyNew>
                                </BoxNew>
                              </HoverBox.Trigger>
                              <HoverBox.Content>
                                <DropdownBody width={280}>
                                  <BoxNew
                                    classes="gap-12 pd pt-12 pb-12"
                                    alignItems="center"
                                  >
                                    <Avatar
                                      name={u.userName}
                                      uniqueCode={u.userEmail || ""}
                                      size={"48"}
                                    />
                                    <BoxNew direction={"column"}>
                                      <TypographyNew
                                        type="subtitle1"
                                        exactColor={getColor(
                                          "COLOR_TEXT_PRIMARY"
                                        )}
                                      >
                                        {u.userName}
                                      </TypographyNew>
                                      <TypographyNew
                                        type="caption1_rg"
                                        exactColor={getColor(
                                          "COLOR_TEXT_SECONDARY"
                                        )}
                                      >
                                        {u.deptName}
                                        {u.jobTitle && ` / ${u.jobTitle}`}
                                      </TypographyNew>
                                    </BoxNew>
                                  </BoxNew>
                                </DropdownBody>
                              </HoverBox.Content>
                            </HoverBox>
                          </BoxNew>
                        </BoxNew>
                      ))}
                    </BoxNew>
                  </BoxNew>
                </BoxNew>
              ) : (
                <>
                  {templateDetail.trainingInfo?.deptDetailList &&
                    templateDetail.trainingInfo?.deptDetailList?.length > 0 && (
                      <BoxNew direction="row" classes="mg mt-32">
                        <BoxNew width={220} height={"100%"}>
                          <TypographyNew
                            type="subtitle2"
                            exactColor={getColor("COLOR_TEXT_SECONDARY")}
                          >
                            {t("dept.dept")}
                          </TypographyNew>
                        </BoxNew>
                        <BoxNew width={600} classes="mg ml-28">
                          <BoxNew
                            ref={userListRef}
                            classes={classNames("user-list", {
                              scrollable: isUserListScrollable,
                            })}
                            style={{
                              maxHeight: `${MAX_USER_LIST_HEIGHT}px`,
                            }}
                          >
                            {templateDetail.trainingInfo?.deptDetailList?.map(
                              (u: DeptType) => (
                                <BoxNew classes="user-list-dept">
                                  <IconsNew
                                    variant="fill"
                                    label="users"
                                    stroke={getColor(
                                      "COLOR_AVATAR_LIGHT_BLUE_50"
                                    )}
                                    classes="mg mr-4"
                                  />
                                  <TypographyNew
                                    width={256}
                                    ellipsis
                                    textAlign="start"
                                  >
                                    {u.deptName}
                                  </TypographyNew>
                                </BoxNew>
                              )
                            )}
                          </BoxNew>
                        </BoxNew>
                      </BoxNew>
                    )}
                  {templateDetail.trainingInfo?.userDetailList &&
                    templateDetail.trainingInfo?.userDetailList?.length > 0 && (
                      <BoxNew direction="row" classes="mg mt-32">
                        <BoxNew width={220} height={"100%"}>
                          <TypographyNew
                            type="subtitle2"
                            exactColor={getColor("COLOR_TEXT_SECONDARY")}
                          >
                            {t("user.user")}
                          </TypographyNew>
                        </BoxNew>
                        <BoxNew width={620} classes="mg ml-28">
                          <BoxNew
                            ref={userListRef}
                            classes={classNames("user-list", {
                              scrollable: isUserListScrollable,
                            })}
                            style={{
                              maxHeight: `${MAX_USER_LIST_HEIGHT}px`,
                            }}
                          >
                            {templateDetail.trainingInfo?.userDetailList?.map(
                              (u: userListBean) => (
                                <HoverBox
                                  scrollableRef={userListRef}
                                  isScrollable={isUserListScrollable}
                                >
                                  <HoverBox.Trigger>
                                    <BoxNew classes="user-list-user">
                                      <IconsNew
                                        variant="fill"
                                        label="user"
                                        stroke={getColor(
                                          "COLOR_AVATAR_LIGHT_BLUE_50"
                                        )}
                                        classes="mg mr-4"
                                      />
                                      <TypographyNew
                                        width={256}
                                        ellipsis
                                        textAlign="start"
                                      >{`${u.userName} (${u.userEmail})`}</TypographyNew>
                                    </BoxNew>
                                  </HoverBox.Trigger>
                                  <HoverBox.Content>
                                    <DropdownBody width={280}>
                                      <BoxNew
                                        classes="gap-12 pd pt-12 pb-12"
                                        alignItems="center"
                                      >
                                        <Avatar
                                          name={u.userName}
                                          uniqueCode={u.userEmail || ""}
                                          size={"48"}
                                        />
                                        <BoxNew direction={"column"}>
                                          <TypographyNew
                                            type="subtitle1"
                                            exactColor={getColor(
                                              "COLOR_TEXT_PRIMARY"
                                            )}
                                          >
                                            {u.userName}
                                          </TypographyNew>
                                          <TypographyNew
                                            type="caption1_rg"
                                            exactColor={getColor(
                                              "COLOR_TEXT_SECONDARY"
                                            )}
                                          >
                                            {u.deptName}
                                            {u.jobTitle && ` / ${u.jobTitle}`}
                                          </TypographyNew>
                                        </BoxNew>
                                      </BoxNew>
                                    </DropdownBody>
                                  </HoverBox.Content>
                                </HoverBox>
                              )
                            )}
                          </BoxNew>
                        </BoxNew>
                      </BoxNew>
                    )}
                </>
              )}
            </BoxNew>
          </AccordionsNew>
          {/* user info end   */}

          {/* scenario info start  */}
          <AccordionsNew label={t("menu.template")} initialOpenStatus={true}>
            {scenarioBody}
          </AccordionsNew>
          {/* scenario info end   */}

          {/* quiz  info start  */}
          <AccordionsNew
            label={t("quiz.label.trainingQuizTitle")}
            initialOpenStatus={true}
          >
            <BoxNew>
              <BoxNew
                direction="column"
                classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28"
              >
                <BoxNew>
                  <BoxNew width={220} classes="mg mr-28">
                    <TypographyNew
                      type="subtitle2"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    >
                      {t("quiz.label.intergrationQuiz")}
                    </TypographyNew>
                  </BoxNew>
                  <BoxNew>
                    <TypographyNew
                      type="body2_rg"
                      exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    >
                      {quizFlag ? "ON" : "OFF"}
                    </TypographyNew>
                  </BoxNew>
                </BoxNew>
                <BoxNew>
                  <BoxNew width={220} classes="mg mr-28">
                    <TypographyNew
                      type="subtitle2"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    >
                      {t("quiz.label.trainingQuizType")}
                    </TypographyNew>
                  </BoxNew>
                  <BoxNew>
                    <TypographyNew
                      type="body2_rg"
                      exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    >
                      {quizInfo.quizType === 1
                        ? t("quiz.label.trainingClick")
                        : t("quiz.label.trainingEnd")}
                    </TypographyNew>
                  </BoxNew>
                </BoxNew>
                <BoxNew>
                  <BoxNew width={220} classes="mg mr-28">
                    <TypographyNew
                      type="subtitle2"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    >
                      {t("quiz.label.trainingQuizCount")}
                    </TypographyNew>
                  </BoxNew>
                  <BoxNew>
                    <TypographyNew
                      type="body2_rg"
                      exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    >
                      {quizInfo.quizCount}
                      {t("program.programDataUnit")}
                    </TypographyNew>
                  </BoxNew>
                </BoxNew>

                <BoxNew>
                  <BoxNew width={220} classes="mg mr-28">
                    <TypographyNew
                      type="subtitle2"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    >
                      {t("quiz.label.quizQuestion")}
                    </TypographyNew>
                  </BoxNew>
                  <BoxNew alignItems="center" direction="column">
                    {/* 학습 연계 테이블 */}
                    <TableNew
                      classes="table-chip-hover"
                      border="none"
                      columnHeight="md"
                      data={transformQuiz(selectedQuizList)}
                      endReached={() => {}}
                      header={[
                        {
                          headerText: "",
                          width: "12px",
                        },
                        {
                          headerText: t("quiz.table.type"),
                          width: "72px",
                        },
                        {
                          headerText: t("quiz.table.quizName"),
                          width: "142px",
                        },
                        {
                          headerText: t("quiz.table.category"),
                          width: "280px",
                        },
                        {
                          headerText: t("quiz.table.formal"),
                          width: "80px",
                        },
                      ]}
                      width={"100%"}
                      height={selectedQuizList.length > 0 ? "300px" : "30px"}
                    />
                    {renderQuizTable()}
                  </BoxNew>
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </AccordionsNew>
          {/* quiz  info end   */}
        </BoxNew>
      )}

      <ModalNew
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={t("program.delete")}
        bodyHeight={20}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => setIsDeleteModalOpen(false)}
              label={t("common.button.close")}
            />
            <ButtonNew
              btnStyle="fill"
              type="error"
              classes="mg ml-8"
              onClick={onDelete}
              label={t("common.button.delete")}
            />
          </BoxNew>
        }
        body={
          <TypographyNew
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.msg.deleteConfirmMsg")}
          </TypographyNew>
        }
      />

      <BoxNew
        classes={classNames("footer", { showScroll: isHorizontallyScrollable })}
      >
        <ButtonNew
          btnStyle="fill"
          type="secondary"
          classes="mg ml-10"
          label={t("program.makeQuizCourse")}
          onClick={() =>
            templateDetail?.trainingInfo?.configId &&
            setIsQuizMailModalOpen(true)
          }
          leadingIcon={<IconMindSat variant="line" label="education" />}
          size={"lg"}
          state={
            quizInfo.quizType &&
            quizInfo.quizType === 2 &&
            ((templateDetail?.trainingInfo?.userCount &&
              templateDetail?.trainingInfo?.sendMailCount &&
              templateDetail?.trainingInfo.sendCnt ===
                templateDetail?.trainingInfo?.userCount *
                  templateDetail?.trainingInfo?.sendMailCount) ||
              dayjs(
                templateDetail?.trainingInfo.sendEndDate + "+00:00"
              ).valueOf() < dayjs().valueOf())
              ? "default"
              : "disable"
          }
        />
        <ButtonNew
          btnStyle="fill"
          type="secondary"
          classes="mg ml-10"
          label={t("program.sendingTrainingResultMail")}
          onClick={() =>
            templateDetail?.trainingInfo?.configId &&
            setIsResultMailModalOpen(true)
          }
          leadingIcon={<IconMindSat variant="line" label="award" />}
          state={
            (templateDetail?.trainingInfo?.userCount &&
              templateDetail?.trainingInfo?.sendMailCount &&
              templateDetail?.trainingInfo.sendCnt ===
                templateDetail?.trainingInfo?.userCount *
                  templateDetail?.trainingInfo?.sendMailCount) ||
            dayjs(
              templateDetail?.trainingInfo.sendEndDate + "+00:00"
            ).valueOf() < dayjs().valueOf()
              ? "default"
              : "disable"
          }
        />
      </BoxNew>

      <ModalNew
        open={isResultMailModalOpen}
        title={t("program.sendResultMail")}
        onClose={() => setIsResultMailModalOpen(false)}
        bodyHeight={20}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => setIsResultMailModalOpen(false)}
              label={t("common.button.close")}
              size="lg"
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              classes="mg ml-8"
              onClick={() =>
                templateDetail?.trainingInfo?.configId &&
                onSendResultMail(templateDetail?.trainingInfo?.configId)
              }
              label={t("common.button.check")}
              size="lg"
            />
          </BoxNew>
        }
        body={
          <TypographyNew
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.msg.resultMailConfirmMsg")}
          </TypographyNew>
        }
      />

      <ModalNew
        open={isQuizMailModalOpen}
        onClose={() => setIsQuizMailModalOpen(false)}
        title={t("program.makeQuizCourse")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => setIsQuizMailModalOpen(false)}
              label={t("common.button.close")}
              size="lg"
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              classes="mg ml-8"
              onClick={() =>
                templateDetail?.trainingInfo?.configId &&
                onSendQuizMail(templateDetail?.trainingInfo?.configId)
              }
              label={t("common.button.check")}
              size="lg"
            />
          </BoxNew>
        }
        body={
          <TypographyNew
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.msg.quizMailConfirmMsg")}
          </TypographyNew>
        }
      />
    </BoxNew>
  );
};

export default TrainingDetail;
