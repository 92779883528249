import {
  Box,
  Checkbox,
  Chip,
  DropdownBody,
  getColor,
  InputField,
  Typography,
  useToast,
  useComponentVisible,
} from "fds";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";

interface DropdownCategoryProps {
  dropdownList: string[];
  initialSelectedChips: string[];
  updateSelectedChips: (selectedChips: string[]) => void;
  searchword: string;
  updateSearchword: (e: any) => void;
  width?: string;
  onCategoryChangeCb?: (selectedChips: string[]) => void;
}

const CategoryDropdown = ({
  dropdownList, // 드롭다운 리스트
  initialSelectedChips, // 선택된 칩
  updateSelectedChips, // 칩 업데이트
  searchword, // 검색어
  updateSearchword, // 검색어 업데이트
  width, // 드롭다운 너비
  onCategoryChangeCb, // 카테고리 변경 콜백
}: DropdownCategoryProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedChips, setSelectedChips] = useState(initialSelectedChips);
  const prevSelectedChips = useRef(JSON.stringify(selectedChips)); // 수정 전 값 저장. 실패 시 백업용

  async function fetchCategoryChange(selectedChips: string[]) {
    if (JSON.stringify(selectedChips) === prevSelectedChips.current) return;
    else if (
      selectedChips.length === 0 ||
      JSON.parse(prevSelectedChips.current).length === 0
    ) {
      prevSelectedChips.current = JSON.stringify(selectedChips);
      return;
    } else if (!onCategoryChangeCb) return;

    const res: any = await onCategoryChangeCb(selectedChips);
    if (res.payload.code !== "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateUpdateFailMsg"),
        "error"
      );
      setSelectedChips(JSON.parse(prevSelectedChips.current));
      throw Error("카테고리 업데이트 오류");
    }
    prevSelectedChips.current = JSON.stringify(selectedChips);
  }

  function addAllChips() {
    deleteChips(dropdownList);
    setSelectedChips((selectedChips: string[]) => [
      ...selectedChips,
      ...dropdownList,
    ]);
    resetSearchword();
    closeDropdown();
  }

  function addChip(item: string) {
    const itemTrimed = item.trim();
    if (itemTrimed === "") return;
    if (itemTrimed.length > 30) {
      toast.toastMsg(
        nanoid(),
        t("category.msg.categoryNameLengthLimitMsg"),
        "error"
      );
      return;
    }

    deleteChips([itemTrimed]);
    setSelectedChips((selectedChips: string[]) => [
      ...selectedChips,
      itemTrimed,
    ]);
    resetSearchword();
    closeDropdown();
  }

  function deleteChips(items: string[]) {
    setSelectedChips((selectedChips: string[]) =>
      selectedChips.filter((chip) => !items.includes(chip))
    );
    closeDropdown();
  }

  function checkIfChipExists(item: string) {
    return selectedChips.includes(item);
  }

  function resetSearchword() {
    updateSearchword("");
  }

  function closeDropdown() {
    setIsComponentVisible(false);
  }

  function checkIfDropdownVisible() {
    return dropdownList.length > 0 && isComponentVisible && searchword;
  }

  useEffect(() => {
    dropdownList.length && setIsComponentVisible(true);
  }, [dropdownList]);

  useEffect(() => {
    updateSelectedChips(selectedChips);
    fetchCategoryChange(selectedChips);
  }, [selectedChips]);

  useEffect(() => {
    setSelectedChips(initialSelectedChips);
  }, [initialSelectedChips]);

  return (
    <Box classes="category__select" ref={ref}>
      <InputField
        placeholder={t("template.msg.categoryPlaceholder")}
        size={"md"}
        width={width}
        maxLength={30}
        value={searchword}
        onChange={(e) => updateSearchword(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            addChip(e.target.value);
          }
        }}
        onFocus={() => setIsComponentVisible(true)}
      />
      {checkIfDropdownVisible() && (
        <DropdownBody classes="" width={width} location={{ x: 0, y: 45 }}>
          <Box classes="category__select__title">
            <Typography
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              title
            </Typography>
            <Typography
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_BRAND")}
              classes="cursor-pointer"
              onClick={addAllChips}
            >
              {t("common.input.selectAll")}
            </Typography>
          </Box>
          {dropdownList.map((item) => (
            <Box
              classes="category__select__item"
              key={item}
              style={{
                backgroundColor: checkIfChipExists(item)
                  ? getColor("COLOR_AVATAR_DEEP_BLUE_BG")
                  : getColor("COLOR_BG_INTERACTIVE_SECONDARY"),
              }}
            >
              <Checkbox
                check={checkIfChipExists(item)}
                onClick={() => {
                  if (checkIfChipExists(item)) {
                    deleteChips([item]);
                  } else {
                    addChip(item);
                  }
                }}
                label={item}
              />
            </Box>
          ))}
        </DropdownBody>
      )}
      {selectedChips.length > 0 && (
        <Box classes="category__select__chips">
          {selectedChips.map((item) => (
            <Chip
              key={`tempConfigName-chip-${item}`}
              type={"fill"}
              size={"sm"}
              showClose={true}
              text={item}
              onClick={() => deleteChips([item])}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CategoryDropdown;
