import {
  Modal as ModalNew,
  Box as BoxNew,
  Button as ButtonNew,
  Typography as TypographyNew,
  getColor,
  Icons,
  InputField,
  Table,
  Radio,
  useToast,
} from "fds";

import { ReportReply, ReportReplyReqDTO } from "../../../types/Reply";
import { useEffect, useState } from "react";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";

interface DefaultReplyModalProps {
  isOpen: boolean;
  onClose: () => void;
  replys: ReportReply[];
  filterData: ReportReplyReqDTO;
}
const DefaultReplyModal: React.FC<DefaultReplyModalProps> = ({
  isOpen,
  onClose,
  replys,
  filterData,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { getReplys, updateReply } = useReplyStore();

  const [trainingReplyId, setTrainingReplyId] = useState<number | null>();
  const [commonReplyId, setCommonReplyId] = useState<number | null>();

  const [search, setSearch] = useState<string>("");

  const [filteredReplys, setFilteredReplys] = useState<ReportReply[]>([]);

  useEffect(() => {
    if (replys) {
      replys.map((r) => {
        if (r.defaultYn && r.replyTargetType === 1)
          setTrainingReplyId(r.replyId);

        if (r.defaultYn && r.replyTargetType === 2) setCommonReplyId(r.replyId);
      });
    }
  }, []);

  useEffect(() => {
    setFilteredReplys(replys.filter((r) => r.replyTitle.includes(search)));
  }, [search]);

  const handleUpdateDefaultReport = async () => {
    let resp: any;
    if (trainingReplyId) {
      resp = await updateReply({
        updateUser: "admin",
        replyId: trainingReplyId,
        replyTargetType: 1,
      });
    }

    if (commonReplyId) {
      resp = await updateReply({
        updateUser: "admin",
        replyId: commonReplyId,
        replyTargetType: 2,
      });
    }
    if (resp.payload) {
      toast.toastMsg(
        nanoid(),
        t("reply.msg.reportSetDefaultReplySuccessMsg"),
        "success"
      );
      getReplys(filterData);
      onClose();
    }
  };

  const transformReplyList = (replyList: ReportReply[]) => {
    const replyListTransformed = [];

    for (let i = 0; i < replyList.length; i++) {
      const r = replyList[i];

      const dataReplyTitle = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {r.replyTitle}
          </TypographyNew>
        </BoxNew>
      );

      const dataTraining = (
        <BoxNew justifyContent="center">
          <Radio
            check={trainingReplyId === r.replyId}
            onClick={() => setTrainingReplyId(r.replyId)}
            classes="mg mr-32"
          />
        </BoxNew>
      );

      const dataNotTraining = (
        <BoxNew justifyContent="center">
          <Radio
            check={commonReplyId === r.replyId}
            onClick={() => setCommonReplyId(r.replyId)}
          />
        </BoxNew>
      );

      const replyTransformed = {
        data: [
          { columnText: "" },
          {
            columnText: dataReplyTitle,
          },
          {
            columnText: dataTraining,
          },
          {
            columnText: dataNotTraining,
          },
        ],
        id: String(r.replyId),
      };
      replyListTransformed.push(replyTransformed);
    }
    return replyListTransformed;
  };
  return (
    <>
      <ModalNew
        open={isOpen}
        onClose={onClose}
        width={600}
        title={t("reply.reportSetDefaultReply")}
        body={
          <BoxNew direction="column" width={"100%"}>
            <InputField
              placeholder="답변 템플릿 이름을 입력하십시오."
              leadingIcon={<Icons label="search" variant="line" />}
              width="100%"
              size="md"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <BoxNew classes="mg mt-24">
              <Table
                border="none"
                data={transformReplyList(filteredReplys)}
                columnHeight="md"
                endReached={() => {}}
                header={[
                  {
                    headerText: "",
                    width: "12px",
                  },
                  {
                    headerText: t("reply.reportReplyName"),
                    width: "calc(100% - 212px)",
                  },

                  {
                    headerText: t("report.reportTrainingMail"),
                    width: "106px",
                  },
                  {
                    headerText: t("report.reportNotTrainingMail"),
                    width: "106px",
                  },
                ]}
                width="100%"
                height={"508px"}
              />
            </BoxNew>
          </BoxNew>
        }
        footer={
          <BoxNew justifyContent="flex-end" width={"100%"}>
            <ButtonNew
              type="secondary"
              btnStyle="fill"
              onClick={onClose}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              classes="mg ml-8"
              type="primary"
              btnStyle="fill"
              onClick={handleUpdateDefaultReport}
              label={t("common.button.save")}
            />
          </BoxNew>
        }
      />
    </>
  );
};

export default DefaultReplyModal;
