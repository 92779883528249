// 문서 정보 없음
const NoDocInfo = ({
  classes,
  width = 150,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      className={classes}
      width={width}
      height="80"
      viewBox="0 0 150 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M125.74 79.6834L94.1186 71.2147C89.1853 69.8976 86.2623 64.8257 87.5794 59.8923L99.803 14.2563C101.12 9.32302 106.192 6.40003 111.125 7.71712L142.747 16.1858C147.68 17.5029 150.603 22.5748 149.286 27.5081L137.063 73.1441C135.734 78.0774 130.674 81.0005 125.74 79.6834Z"
        fill="#EBF0F2"
      />
      <path
        d="M120.84 45.2063L128.731 47.2975L128.72 47.3321C127.553 51.7224 123.96 55.1769 119.466 55.8123C110.512 57.0832 104.238 48.9958 106.294 41.3128C107.947 35.1548 114.359 31.4577 120.517 33.0983L118.402 40.9893C117.906 42.8263 119.003 44.721 120.84 45.2063Z"
        fill="#D0D5D8"
      />
      <path
        d="M121.095 39.2666L123.036 32.0342C128.824 33.5939 132.302 39.6248 130.754 45.413V45.4246L123.521 43.4836C121.684 42.9868 120.598 41.0921 121.095 39.2666Z"
        fill="#D0D5D8"
      />
      <path
        d="M55.4833 71.2049L23.8618 79.6736C18.9285 80.9907 13.868 78.0677 12.5394 73.1344L0.315742 27.4984C-1.00135 22.5651 1.92185 17.5046 6.85516 16.176L38.4767 7.70736C43.41 6.39027 48.4705 9.31329 49.7991 14.2466L62.0228 59.8826C63.3398 64.8159 60.4166 69.8879 55.4833 71.2049Z"
        fill="#EBF0F2"
      />
      <path
        d="M46.7073 48.202L37.3258 39.4561C35.9971 38.2198 33.8251 38.7975 33.2936 40.5421L30.3937 50.039L26.0843 46.0184C25.0676 45.071 23.404 45.51 22.9881 46.8502L20.1113 56.2432C19.6838 57.6643 20.9778 59.0044 22.3988 58.6116L32.4503 55.9197C32.4618 55.9197 32.4734 55.9081 32.485 55.9081C32.4965 55.9081 32.5081 55.9081 32.5196 55.9081L45.6442 52.3959C47.5389 51.8875 48.1168 49.5306 46.7073 48.202Z"
        fill="#D0D5D8"
      />
      <path
        d="M22.3537 42.1702C23.9681 42.1702 25.2768 40.8616 25.2768 39.2472C25.2768 37.6329 23.9681 36.3242 22.3537 36.3242C20.7394 36.3242 19.4307 37.6329 19.4307 39.2472C19.4307 40.8616 20.7394 42.1702 22.3537 42.1702Z"
        fill="#D0D5D8"
      />
      <path
        d="M91.1748 65.739H58.4439C53.3373 65.739 49.2012 61.6029 49.2012 56.4963V9.24274C49.2012 4.13613 53.3373 0 58.4439 0H91.1748C96.2815 0 100.418 4.13613 100.418 9.24274V56.4963C100.418 61.6029 96.2815 65.739 91.1748 65.739Z"
        fill="#ADCDFB"
      />
      <path
        d="M91.8839 36.8896H57.7217C57.1694 36.8896 56.7217 37.3374 56.7217 37.8896V38.5238C56.7217 39.0761 57.1694 39.5238 57.7217 39.5238H91.8839C92.4362 39.5238 92.8839 39.0761 92.8839 38.5238V37.8896C92.8839 37.3374 92.4362 36.8896 91.8839 36.8896Z"
        fill="white"
      />
      <path
        d="M91.9191 44.8252H57.7568C57.2046 44.8252 56.7568 45.2729 56.7568 45.8252V46.4594C56.7568 47.0117 57.2046 47.4594 57.7568 47.4594H91.9191C92.4714 47.4594 92.9191 47.0117 92.9191 46.4594V45.8252C92.9191 45.2729 92.4714 44.8252 91.9191 44.8252Z"
        fill="white"
      />
      <path
        d="M91.9175 52.5557H78.0547C77.5024 52.5557 77.0547 53.0034 77.0547 53.5557V54.6057C77.0547 55.158 77.5024 55.6057 78.0547 55.6057H91.9175C92.4698 55.6057 92.9175 55.158 92.9175 54.6057V53.5557C92.9175 53.0034 92.4698 52.5557 91.9175 52.5557Z"
        fill="white"
      />
      <path
        d="M62.1107 11.3691H58.248C57.4196 11.3691 56.748 12.0407 56.748 12.8691V29.406C56.748 30.2344 57.4196 30.906 58.248 30.906H62.1107C62.9391 30.906 63.6107 30.2344 63.6107 29.406V12.8691C63.6107 12.0407 62.9391 11.3691 62.1107 11.3691Z"
        fill="#E8F3FF"
      />
      <path
        d="M73.0267 19.1104H69.1641C68.3356 19.1104 67.6641 19.7819 67.6641 20.6104V29.4064C67.6641 30.2348 68.3356 30.9064 69.1641 30.9064H73.0267C73.8552 30.9064 74.5267 30.2348 74.5267 29.4064V20.6104C74.5267 19.7819 73.8552 19.1104 73.0267 19.1104Z"
        fill="#E8F3FF"
      />
      <path
        d="M83.9352 25.1855H80.0723C79.2438 25.1855 78.5723 25.8571 78.5723 26.6855V29.3929C78.5723 30.2214 79.2438 30.8929 80.0723 30.8929H83.9352C84.7636 30.8929 85.4352 30.2214 85.4352 29.3929V26.6855C85.4352 25.8571 84.7636 25.1855 83.9352 25.1855Z"
        fill="#E8F3FF"
      />
    </svg>
  );
};

export default NoDocInfo;
