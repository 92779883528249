import {
  Box as BoxNew,
  Typography as TypographyNew,
  Button as ButtonNew,
  Icons as IconsNew,
  Tabs as TabsNew,
  Tag as TagNew,
  Tooltip as TooltipNew,
  Table as TableNew,
  getColor,
  DropdownBody,
  DropdownItem,
  Dropdown,
  InputField,
  Chip,
  Modal as ModalNew,
  CalendarRange,
  Toggle,
  useToast as useToastNew,
} from "fds";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TitlePath from "../../../component/TitlePath";
import { CustomerInfoBean } from "../../../types/Info";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import { ReportReply, ReportReplyReqDTO } from "../../../types/Reply";
import utilsCommon from "../../../shared/utils/common";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { ConfigListType } from "../../../types/Send";
import "./index.scss";
import RegitOrEditTemplateModal from "./RegitOrEditTemplateModal";
import { nanoid } from "@reduxjs/toolkit";
import CommonModal from "../../../component/CommonModal";
import NoInfo from "../../../component/NoInfo";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useTableHoverBoxClick from "../../../hooks/useTableHoverBoxClick";
import { useElementPosition } from "../../../hooks/useElementPosition";
import NoDashboardImage from "../../../shared/image/NoDashboardImage";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import useOutsideClick from "../../../hooks/useOutsideClick";
import DetailTemplateModal from "./DetailTemplateModal";
import { FilterBtnActivityCheckUtils } from "../../../shared/utils/FilterBtnActivityCheckUtils";
import DefaultReplyModal from "./DefaultReplyModal";
import { TrainingListReqDTO } from "../../../types/Training";
import useTimedReset from "../../../hooks/useTimedReset";
import { TABLE_HIGHLIGHT_TIMED_OUT } from "../../../shared/constant";

const ReportSetting: React.FC = () => {
  const { t } = useTranslation();
  const { replyState, getReplys, deleteReply, updateReply } = useReplyStore();
  const { programState, getProgramList, updateProgram } = useProgramStore();
  const checkboxGroup = useCheckboxGroup<number>();
  const [isReplyFilterOpen, setIsReplyFilterOpen] = useState<boolean>(false);
  const [isConfigFilterOpen, setIsConfigFilterOpen] = useState<boolean>(false);
  const [replyTemplateId, setReplayTemplateId] = useTimedReset<string>(
    "",
    TABLE_HIGHLIGHT_TIMED_OUT
  );

  const toast = useToastNew();

  const [tab, setTab] = useState(0);
  const [replys, changeReplys] = useState<ReportReply[]>([]);
  const [replysTotal, changeReplysTotal] = useState(0);
  const [reportCenterInfo, changeReportCenterInfo] = useState<CustomerInfoBean>(
    {} as CustomerInfoBean
  );
  const [configList, changeConfigList] = useState<ConfigListType[]>([]);
  const [configIdList, setConfigIdList] = useState<number[]>();
  const [configListTotal, changeConfigListTotal] = useState(0);
  const [registerReplyTemplateModalMode, changeRegisterReplyTemplateModalMode] =
    useState<"CLOSE" | "CREATE" | "EDIT">("CLOSE");
  const [targetReply, changeTargetReply] = useState(-1);
  const [openDeleteModal, toggleDeleteModal] = useState<boolean>(false);
  const [openActivateModal, toggleActivatModal] = useState<boolean>(false);
  const [replyTargetType, changeReplyTargetType] = useState("train");
  const [openDefaultReplyModal, toggleDefaultReplyModal] =
    useState<boolean>(false);
  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [replyFilterData, setReplyFilterData] = useState(
    {} as ReportReplyReqDTO
  );

  const [configFilterData, setConfigFilterData] = useState(
    {} as TrainingListReqDTO
  );
  const initialSelectedPeriod = {
    value: "ALL",
    start: new Date().valueOf(),
    end: new Date().valueOf(),
  };
  const [selectedCreateDate, setSelectedCreateDate] = useState(
    initialSelectedPeriod
  );
  const [selectedUpdateDate, setSelectedUpdateDate] = useState(
    initialSelectedPeriod
  );
  const [selectedPeriod, setSelectedPeriod] = useState(initialSelectedPeriod);

  const {
    tableRef,
    hoverBoxClickedRow,
    hoverBoxNewCoord,
    onOutsideClick,
    handleHoverBoxClick,
    onCloseBox,
  } = useTableHoverBoxClick<ReportReply>({ width: 116, height: 147 });
  const [buttonClick, setButtonClick] = useState(0);
  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  useEffect(() => {
    changeReplys(replyState?.replyListWithCnt?.list ?? []);
    changeReplysTotal(replyState?.replyListWithCnt?.total ?? 0);
    changeReportCenterInfo(replyState.customerInfo);
  }, [replyState.replyListWithCnt]);

  useEffect(() => {
    changeConfigList(programState?.configListWithCount?.list ?? []);
    changeConfigListTotal(programState?.configListWithCount?.total ?? 0);
  }, [programState.configListWithCount]);

  useEffect(() => {
    getReplys(replyFilterData);
  }, [replyFilterData]);

  useEffect(() => {
    getProgramList(configFilterData);
  }, [configFilterData]);

  const makeConfigIdList = async () => {
    const resp: any = await getProgramList({});

    if (resp.payload && resp.payload.code === "SUCCESS") {
      setConfigIdList(resp.payload.data.list.map((c: any) => c.configId));
    }
  };

  useEffect(() => {
    makeConfigIdList();
  }, []);

  useEffect(() => {
    if (selectedCreateDate.value === "ALL") {
      getReplys(replyFilterData);
    } else {
      getReplys({
        ...replyFilterData,
        createStartDate: selectedCreateDate.start
          ? selectedCreateDate.start / 1000
          : undefined,
        createEndDate: selectedCreateDate.end
          ? selectedCreateDate.end / 1000
          : undefined,
      });
    }
  }, [selectedCreateDate]);

  useEffect(() => {
    if (selectedUpdateDate.value === "ALL") {
      getReplys(replyFilterData);
    } else {
      getReplys({
        ...replyFilterData,
        updateStartDate: selectedUpdateDate.start
          ? selectedUpdateDate.start / 1000
          : undefined,
        updateEndDate: selectedUpdateDate.end
          ? selectedUpdateDate.end / 1000
          : undefined,
      });
    }
  }, [selectedUpdateDate]);

  useEffect(() => {
    if (selectedPeriod.value === "ALL") {
      getProgramList(configFilterData);
    } else {
      getProgramList({
        ...configFilterData,
        sendStartDate: selectedPeriod.start
          ? selectedPeriod.start / 1000
          : undefined,
        sendEndDate: selectedPeriod.end ? selectedPeriod.end / 1000 : undefined,
      });
    }
  }, [selectedPeriod]);

  // useEffect(() => {
  //   changeFilter({
  //     ...filter,
  //     training: {
  //       ...filter.training,
  //       orderType: sort.includes("Desc") || sort === "" ? "desc" : "asc",
  //     },
  //   });
  //   getProgramList({
  //     ...filter.training,
  //     orderId: sort.split("-Desc")[0],
  //     orderType: sort.includes("Desc") || sort === "" ? "desc" : "asc",
  //   });
  //   checkboxGroup.handleAllClick([]);
  //   getReplys({});
  // }, [sort]);

  // const renderTableHeaderWithSort = (key: string, name: string) => {
  //   return (
  //     <BoxNew
  //       onClick={() =>
  //         changeSort(
  //           !sort.includes(key)
  //             ? key + "-Desc"
  //             : sort === key + "-Desc"
  //             ? key
  //             : ""
  //         )
  //       }
  //     >
  //       <Typography classes="cursor-pointer">{name}</Typography>
  //       {key === "autoYn" && (
  //         <ToolTip message={t("reply.msg.reportReplyTypeInfoMsg")}>
  //           <Icons
  //             variant="header"
  //             label="questionBlack"
  //             width={16}
  //             height={16}
  //             classes="mg ml-4"
  //           />
  //         </ToolTip>
  //       )}
  //       {sort.includes(key) && (
  //         <Icons
  //           variant="arrow"
  //           label={sort.includes("-Desc") ? "down" : "up"}
  //         />
  //       )}
  //     </BoxNew>
  //   );
  // };

  const resetFilter = () => {
    setReplyFilterData({} as ReportReplyReqDTO);
    setConfigFilterData({} as TrainingListReqDTO);
    setSelectedCreateDate(initialSelectedPeriod);
    setSelectedUpdateDate(initialSelectedPeriod);
    setSelectedPeriod(initialSelectedPeriod);
  };

  const checkTableRenderStatus = (type: "REPLY" | "CONFIG") => {
    if (type === "REPLY") {
      const isFilterApplied =
        JSON.stringify(replyFilterData) !==
        JSON.stringify({} as ReportReplyReqDTO);
      const hasReplyList = replys && replys.length > 0;

      if (!hasReplyList && isFilterApplied) return 1;
      else if (!hasReplyList && !isFilterApplied) return 2;
      else return 0;
    } else {
      const isFilterApplied =
        JSON.stringify(configFilterData) !==
        JSON.stringify({} as TrainingListReqDTO);
      const hasConfigList = configList && configList.length > 0;

      if (!hasConfigList && isFilterApplied) return 1;
      else if (!hasConfigList && !isFilterApplied) return 2;
      else return 0;
    }
  };

  const transformReplyList = (replyList: ReportReply[]) => {
    const replyListTransformed = [];

    for (let i = 0; i < replyList.length; i++) {
      const r = replyList[i];

      const dataReplyTitle = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {r.replyTitle}
          </TypographyNew>
        </BoxNew>
      );

      const dataReplyAutoYN = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {r.autoYn
              ? t("reply.reportReplyAuto")
              : t("reply.reportReplyManual")}
          </TypographyNew>
        </BoxNew>
      );

      const dataReplyDefault = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {r.defaultYn && (
              <TagNew
                type="box"
                text={
                  r.replyTargetType === 1
                    ? t("report.reportTraining")
                    : t("report.reportNotTraining")
                }
                textColor={getColor("COLOR_WHITE")}
                backgroundColor={
                  r.replyTargetType === 1
                    ? getColor("COLOR_BG_INTERACTIVE_BRAND")
                    : getColor("COLOR_AVATAR_DEEP_BLUE_ICON")
                }
              />
            )}
          </TypographyNew>
        </BoxNew>
      );

      // const dataCreateDate = (
      //   <BoxNew>
      //     <TypographyNew
      //       ellipsis
      //       type={"body2_rg"}
      //       exactColor={getColor("COLOR_TEXT_PRIMARY")}
      //       classes="tnum"
      //     >
      //       {utilsCommon.getFullDateStr(r.createDate + "+00:00")}
      //     </TypographyNew>
      //   </BoxNew>
      // );

      const dataUpdateDate = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            classes="tnum"
          >
            {utilsCommon.getFullDateStr(
              r.updateDate && r.updateDate + "+00:00"
            )}
          </TypographyNew>
        </BoxNew>
      );

      const hoverBtn = (
        <ButtonNew
          classes="hover-btn"
          btnStyle={"icon"}
          leadingIcon={<IconsNew variant="line" label="moreVertical" />}
          type={"primary"}
          size={"sm"}
          state={"hover"}
          onClick={(event: MouseEvent) => handleHoverBoxClick(event, r)}
        />
      );

      const replyTransformed = {
        data: [
          { columnText: "" },
          {
            columnText: dataReplyTitle,
          },
          {
            columnText: dataReplyAutoYN,
          },
          {
            columnText: dataReplyDefault,
          },
          // {
          //   columnText: dataCreateDate,
          // },
          {
            columnText: dataUpdateDate,
          },
        ],
        id: String(r.replyId),
        hover: hoverBtn,
        checkBoxClick: () => {
          checkboxGroup.handleClick(r.replyId);
        },
        clickAction: (e: any) => {
          if (!e.target.closest(".hover-btn")) {
            changeTargetReply(r.replyId);
            onCloseBox();
            setOpenDetailModal(true);
          }
        },
      };
      replyListTransformed.push(replyTransformed);
    }
    return replyListTransformed;
  };

  const transformConfigList = (configList: ConfigListType[]) => {
    const configListTransformed = [];

    for (let i = 0; i < configList.length; i++) {
      const c = configList[i];
      const dataConfig = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {c.configId}
          </TypographyNew>
        </BoxNew>
      );

      const dataConfigName = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {c.configName}
          </TypographyNew>
        </BoxNew>
      );

      const dataPeriod = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            classes="tnum"
          >
            {utilsCommon.getFullDateStr(c.sendStartDate) +
              " ~ " +
              utilsCommon.getFullDateStr(c.sendEndDate)}
          </TypographyNew>
        </BoxNew>
      );

      const dataActiveFlag = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {dayjs(c?.sendEndDate).valueOf() * 1000 < dayjs().valueOf() ? (
              <BoxNew classes="dot__container">
                <BoxNew classes="dot gray__dot" />
                <TypographyNew
                  type={"body2_rg"}
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("program.finished")}
                </TypographyNew>
              </BoxNew>
            ) : c.activateFlag ? (
              <BoxNew classes="dot__container">
                <BoxNew classes="dot green__dot" />
                <TypographyNew
                  type={"body2_rg"}
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("program.activated")}
                </TypographyNew>
              </BoxNew>
            ) : (
              <BoxNew classes="dot__container">
                <BoxNew classes="dot red__dot" />
                <TypographyNew
                  type={"body2_rg"}
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("program.deactivated")}
                </TypographyNew>
              </BoxNew>
            )}
          </TypographyNew>
        </BoxNew>
      );

      const dataAutoReply = (
        <BoxNew>
          <Toggle
            checked={c.autoReplyFlag}
            disabled={
              dayjs(c?.sendEndDate).valueOf() * 1000 < dayjs().valueOf() ||
              !c.activateFlag
            }
            onChange={() => {
              changeTargetReply(c.configId);
              toggleActivatModal(true);
            }}
          />
        </BoxNew>
      );

      const configTransformed = {
        data: [
          { columnText: "" },
          {
            columnText: dataConfig,
          },
          {
            columnText: dataConfigName,
          },
          {
            columnText: dataPeriod,
          },
          {
            columnText: dataActiveFlag,
          },
          {
            columnText: dataAutoReply,
          },
        ],
        id: String(c.configId),
      };
      configListTransformed.push(configTransformed);
    }

    return configListTransformed;
  };

  const renderTableSwitch = (type: "REPLY" | "CONFIG") => {
    if (type === "REPLY") {
      switch (checkTableRenderStatus("REPLY")) {
        case 1:
          return <NoReplyFiltered resetFilter={resetFilter} />;
        case 2:
          return <NoReplyCreated />;
        default:
          return <></>;
      }
    } else {
      switch (checkTableRenderStatus("REPLY")) {
        case 1:
          return <NoReplyFiltered resetFilter={resetFilter} />;
        case 2:
          return <NoReplyCreated />;
        default:
          return <></>;
      }
    }
  };

  const [tempReplyTitle, setTempReplyTitle] = useState("");
  const [tempReplyTitles, setTempReplyTitles] = useState<string[]>([]);
  const [tempConfigName, setTempConfigName] = useState("");
  const [tempConfigNames, setTempConfigNames] = useState<string[]>([]);

  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const [openCreateDateModal, toggleCreateDateModal] = useState(false);
  const [openUpdateDateModal, toggleUpdateDateModal] = useState(false);
  const [openProgramDateModal, toggleProgramDateModal] = useState(false);

  const changeDateFilter = (
    type: string,
    changeSelectedPeriod: Dispatch<
      SetStateAction<{
        value: string;
        start: number;
        end: number;
      }>
    >,
    toggleDateModal: Dispatch<SetStateAction<boolean>>
  ) => {
    if (type === "ALL") {
      changeSelectedPeriod({
        value: type,
        start: new Date().valueOf(),
        end: new Date().valueOf(),
      });
    } else if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 12,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const dateModal = (
    selectedCreateDate: {
      value: string;
      start: number;
      end: number;
    },
    changeSelectedPeriod: Dispatch<
      SetStateAction<{
        value: string;
        start: number;
        end: number;
      }>
    >,
    openDateModal: boolean,
    toggleDateModal: Dispatch<SetStateAction<boolean>>
  ) => {
    return (
      <ModalNew
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedCreateDate,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  const replyFilters = (
    <BoxNew
      classes="pd pt-12 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      {/* 템플릿 이름 start */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("reply.reportReplyName") + " · " + t("common.auth.all")}
        value={
          replyFilterData.replyTitles === undefined ||
          replyFilterData.replyTitles.length === 0
            ? ""
            : t("reply.reportReplyName") +
              " · " +
              replyFilterData.replyTitles.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("reply.reportReplyName")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempReplyTitle}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={"템플릿 이름을 입력하세요."}
              onChange={(e: any) => setTempReplyTitle(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (
                    tempReplyTitles &&
                    tempReplyTitles.includes(e.target.value)
                  ) {
                    return;
                  }
                  setTempReplyTitles((prev) => [...prev, tempReplyTitle]);
                  setTempReplyTitle("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempReplyTitles &&
              tempReplyTitles.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempReplyTitles((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempReplyTitle("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    replyFilterData.replyTitles || [],
                    tempReplyTitles
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempReplyTitle !== "") {
                    setTempReplyTitles((prev) => [...prev, tempReplyTitle]);
                    setReplyFilterData((prev) => ({
                      ...prev,
                      replyTitles: [...tempReplyTitles, tempReplyTitle],
                    }));
                  } else {
                    setReplyFilterData((prev) => ({
                      ...prev,
                      replyTitles: tempReplyTitles,
                    }));
                  }
                  setTempReplyTitle("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </Dropdown>
      {/* 템플릿 이름 end */}

      {/* 답변 종류 start */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("reply.reportReplyType") + " · " + t("common.auth.all")}
        value={
          replyFilterData.autoYn === undefined
            ? ""
            : replyFilterData.autoYn
            ? t("reply.reportReplyType") + " · " + t("reply.reportReplyAuto")
            : t("reply.reportReplyType") + " · " + t("reply.reportReplyManual")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={replyFilterData.autoYn === undefined}
          checkColor={replyFilterData.autoYn === undefined}
          onClick={() => {
            setReplyFilterData((prev) => ({ ...prev, autoYn: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={replyFilterData.autoYn}
          checkColor={replyFilterData.autoYn}
          onClick={() => {
            setReplyFilterData((prev) => ({ ...prev, autoYn: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("reply.reportReplyAuto")}
          type={"check"}
        />
        <DropdownItem
          checked={replyFilterData.autoYn === false}
          checkColor={!replyFilterData.autoYn === false}
          onClick={() => {
            setReplyFilterData((prev) => ({ ...prev, autoYn: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("reply.reportReplyManual")}
          type={"check"}
        />
      </Dropdown>
      {/* 답변 종류 end */}

      {/* 답변 대상  start*/}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={
          t("reply.reportReplyDefault") + " · " + t("common.auth.all")
        }
        value={
          replyFilterData.defaultYn === undefined
            ? ""
            : replyFilterData.defaultYn && replyFilterData.replyTargetType === 1
            ? t("reply.reportReplyDefault") +
              " · " +
              t("report.reportTrainingMail")
            : t("reply.reportReplyDefault") +
              " · " +
              t("report.reportNotTrainingMail")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={replyFilterData.defaultYn === undefined}
          checkColor={replyFilterData.defaultYn === undefined}
          onClick={() => {
            setReplyFilterData((prev) => ({
              ...prev,
              defaultYn: undefined,
              replyTargetType: undefined,
            }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={
            replyFilterData.defaultYn && replyFilterData.replyTargetType === 1
          }
          checkColor={
            replyFilterData.defaultYn && replyFilterData.replyTargetType === 1
          }
          onClick={() => {
            setReplyFilterData((prev) => ({
              ...prev,
              defaultYn: true,
              replyTargetType: 1,
            }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.reportTraining")}
          type={"check"}
        />
        <DropdownItem
          checked={
            replyFilterData.defaultYn && replyFilterData.replyTargetType === 2
          }
          checkColor={
            replyFilterData.defaultYn && replyFilterData.replyTargetType === 2
          }
          onClick={() => {
            setReplyFilterData((prev) => ({
              ...prev,
              defaultYn: true,
              replyTargetType: 2,
            }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.reportNotTraining")}
          type={"check"}
        />
      </Dropdown>
      {/* 답변 대상 end  */}

      {/* 등록 일자  start*/}
      {/* <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.addDate") + " · " + t("common.auth.all")}
        value={`${
          selectedCreateDate.value === "PERIOD_3MONTH"
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent3Month")
            : selectedCreateDate.value === "PERIOD_6MONTH"
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent6Month")
            : selectedCreateDate.value === "PERIOD_1YEAR"
            ? t("common.time.addDate") +
              " · " +
              t("report.stats.reportRecent1Year")
            : selectedCreateDate.value === "ALL"
            ? ""
            : t("common.time.addDate") + " · " + t("graph.phishingCustomScope")
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={selectedCreateDate.value === "ALL"}
          checkColor={selectedCreateDate.value === "ALL"}
          onClick={() => {
            changeDateFilter(
              "ALL",

              setSelectedCreateDate,
              toggleCreateDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedCreateDate.value === "PERIOD_3MONTH"}
          checkColor={selectedCreateDate.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_3MONTH",

              setSelectedCreateDate,
              toggleCreateDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedCreateDate.value === "PERIOD_6MONTH"}
          checkColor={selectedCreateDate.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_6MONTH",

              setSelectedCreateDate,
              toggleCreateDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedCreateDate.value === "PERIOD_1YEAR"}
          checkColor={selectedCreateDate.value === "PERIOD_1YEAR"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_1YEAR",

              setSelectedCreateDate,
              toggleCreateDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItem
          checked={selectedCreateDate.value === "PERIOD_CUSTOM"}
          checkColor={selectedCreateDate.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_CUSTOM",

              setSelectedCreateDate,
              toggleCreateDateModal
            );
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown> */}
      {/* 등록 일자 end */}

      {/* 수정 일자  start*/}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.updateDate") + " · " + t("common.auth.all")}
        value={`${
          selectedUpdateDate.value === "PERIOD_3MONTH"
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent3Month")
            : selectedUpdateDate.value === "PERIOD_6MONTH"
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent6Month")
            : selectedUpdateDate.value === "PERIOD_1YEAR"
            ? t("common.time.updateDate") +
              " · " +
              t("report.stats.reportRecent1Year")
            : selectedUpdateDate.value === "ALL"
            ? ""
            : t("common.time.updateDate") +
              " · " +
              `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={selectedUpdateDate.value === "ALL"}
          checkColor={selectedUpdateDate.value === "ALL"}
          onClick={() => {
            changeDateFilter(
              "ALL",

              setSelectedUpdateDate,
              toggleUpdateDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedUpdateDate.value === "PERIOD_3MONTH"}
          checkColor={selectedUpdateDate.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_3MONTH",
              setSelectedUpdateDate,
              toggleUpdateDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedUpdateDate.value === "PERIOD_6MONTH"}
          checkColor={selectedUpdateDate.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_6MONTH",
              setSelectedUpdateDate,
              toggleUpdateDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedUpdateDate.value === "PERIOD_1YEAR"}
          checkColor={selectedUpdateDate.value === "PERIOD_1YEAR"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_1YEAR",
              setSelectedUpdateDate,
              toggleUpdateDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItem
          checked={selectedCreateDate.value === "PERIOD_CUSTOM"}
          checkColor={selectedCreateDate.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_CUSTOM",
              setSelectedUpdateDate,
              toggleUpdateDateModal
            );
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown>
      {/* 수정 일자 end */}

      <ButtonNew
        btnStyle={"icon"}
        size={"md"}
        onClick={resetFilter}
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_INFO")}
          />
        }
      />
    </BoxNew>
  );
  const configFilters = (
    <BoxNew
      classes="pd pt-12 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      {/* 회차 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("program.configRound") + " · " + t("common.auth.all")}
        value={
          configFilterData.configId === undefined
            ? ""
            : t("program.configRound") +
              " · " +
              t("common.list.numOrder", { round: configFilterData.configId })
        }
        buttonClick={buttonClick}
        buttonWidth={224}
        dropdownMaxHeight={276}
      >
        <DropdownItem
          checked={configFilterData.configId === undefined}
          checkColor={configFilterData.configId === undefined}
          onClick={() => {
            setConfigFilterData({ ...configFilterData, configId: undefined });
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        {configIdList &&
          configIdList.map((c: number) => (
            <DropdownItem
              key={`configId-dropdownItem-${c}`}
              checked={configFilterData.configId === c}
              checkColor={configFilterData.configId === c}
              onClick={() => {
                setConfigFilterData({
                  ...configFilterData,
                  configId: c,
                });
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("common.list.numOrder", { round: c })}
              type={"check"}
            />
          ))}
      </Dropdown>

      {/* 훈련 이름 start */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("menu.trainingName") + " · " + t("common.auth.all")}
        value={
          configFilterData.configNames === undefined ||
          configFilterData.configNames.length === 0
            ? ""
            : t("menu.trainingName") +
              " · " +
              configFilterData.configNames.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("menu.trainingName")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempConfigName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("program.msg.programUndefinedNameMsg")}
              onChange={(e) => setTempConfigName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (
                    configFilterData.configNames &&
                    configFilterData.configNames.includes(e.target.value)
                  ) {
                    return;
                  }
                  setTempConfigNames((prev) => [...prev, tempConfigName]);
                  setTempConfigName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempConfigNames &&
              tempConfigNames.map((item) => (
                <Chip
                  key={`tempConfigName-chip-${item}`}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempConfigNames((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempConfigName(configFilterData.configName ?? "");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    configFilterData.configNames || [],
                    tempConfigNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempConfigName !== "") {
                    setTempConfigNames((prev) => [...prev, tempConfigName]);
                    setConfigFilterData((prev) => ({
                      ...prev,
                      configNames: [...tempConfigNames, tempConfigName],
                    }));
                  } else {
                    setConfigFilterData((prev) => ({
                      ...prev,
                      configNames: tempConfigNames,
                    }));
                  }
                  setTempConfigName("");

                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </Dropdown>
      {/* 훈련 이름 end */}

      {/* 훈련 기간 */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={
          t("program.trainingDuration") + " · " + t("common.auth.all")
        }
        value={`${
          selectedPeriod.value === "PERIOD_3MONTH"
            ? t("program.trainingDuration") +
              " · " +
              t("report.stats.reportRecent3Month")
            : selectedPeriod.value === "PERIOD_6MONTH"
            ? t("program.trainingDuration") +
              " · " +
              t("report.stats.reportRecent6Month")
            : selectedPeriod.value === "PERIOD_1YEAR"
            ? t("program.trainingDuration") +
              " · " +
              t("report.stats.reportRecent1Year")
            : selectedPeriod.value === "ALL"
            ? ""
            : t("program.trainingDuration") +
              " · " +
              `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={selectedPeriod.value === "ALL"}
          checkColor={selectedPeriod.value === "ALL"}
          onClick={() => {
            changeDateFilter("ALL", setSelectedPeriod, toggleProgramDateModal);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_3MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_3MONTH",
              setSelectedPeriod,
              toggleProgramDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_6MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_6MONTH",
              setSelectedPeriod,
              toggleProgramDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_1YEAR"}
          checkColor={selectedPeriod.value === "PERIOD_1YEAR"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_1YEAR",
              setSelectedPeriod,
              toggleProgramDateModal
            );
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_CUSTOM"}
          checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter(
              "PERIOD_CUSTOM",
              setSelectedPeriod,
              toggleProgramDateModal
            );
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown>
      {/* 훈련 기간 end  */}

      {/* 훈련 활성화 여부 start  */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("program.isActivateFlag") + " · " + t("common.auth.all")}
        value={
          configFilterData.activateFlag === undefined
            ? ""
            : configFilterData.activateFlag
            ? t("program.isActivateFlag") + " · " + t("program.activated")
            : t("program.isActivateFlag") + " · " + t("program.deactivated")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={configFilterData.activateFlag === undefined}
          checkColor={configFilterData.activateFlag === undefined}
          onClick={() => {
            setConfigFilterData((prev) => ({
              ...prev,
              activateFlag: undefined,
            }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={configFilterData.activateFlag}
          checkColor={configFilterData.activateFlag}
          onClick={() => {
            setConfigFilterData((prev) => ({ ...prev, activateFlag: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.activated")}
          type={"check"}
        />
        <DropdownItem
          checked={configFilterData.activateFlag === false}
          checkColor={configFilterData.activateFlag === false}
          onClick={() => {
            setConfigFilterData((prev) => ({ ...prev, activateFlag: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.deactivated")}
          type={"check"}
        />
      </Dropdown>

      {/* 자동 응답 여부 start  */}
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={
          t("reply.autoReplyActivate") + " · " + t("common.auth.all")
        }
        value={
          configFilterData.autoReplyFlag === undefined
            ? ""
            : configFilterData.autoReplyFlag
            ? t("reply.autoReplyActivate") + " · " + t("program.activated")
            : t("reply.autoReplyActivate") + " · " + t("program.deactivated")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={configFilterData.autoReplyFlag === undefined}
          checkColor={configFilterData.autoReplyFlag === undefined}
          onClick={() => {
            setConfigFilterData((prev) => ({
              ...prev,
              autoReplyFlag: undefined,
            }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={configFilterData.autoReplyFlag}
          checkColor={configFilterData.autoReplyFlag}
          onClick={() => {
            setConfigFilterData((prev) => ({ ...prev, autoReplyFlag: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.activated")}
          type={"check"}
        />
        <DropdownItem
          checked={configFilterData.autoReplyFlag === false}
          checkColor={configFilterData.autoReplyFlag === false}
          onClick={() => {
            setConfigFilterData((prev) => ({ ...prev, autoReplyFlag: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.deactivated")}
          type={"check"}
        />
      </Dropdown>

      <ButtonNew
        btnStyle={"icon"}
        size={"md"}
        onClick={resetFilter}
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_INFO")}
          />
        }
      />
    </BoxNew>
  );

  const NoReplyFiltered = ({ resetFilter }: { resetFilter: () => void }) => {
    return (
      <BoxNew
        width={"100%"}
        direction="column"
        alignItems="center"
        classes="mg mt-80"
      >
        <NoSearchResultImage width={200} />
        <TypographyNew
          type="h3"
          classes="mg mt-36"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("quiz.label.noSearch")}
        </TypographyNew>
        <TypographyNew
          type="body2_rg_long"
          classes="mg mt-8"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          필터 조건에 맞는 답변 템플릿이 없습니다.
        </TypographyNew>
        <ButtonNew
          btnStyle={"fill"}
          state={"default"}
          size={"lg"}
          classes="mg mt-20"
          leadingIcon={
            <IconsNew
              variant="line"
              label="redoLeft"
              stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
            />
          }
          onClick={resetFilter}
          label={t("common.button.resetFilter")}
        />
      </BoxNew>
    );
  };

  const NoReplyCreated = () => {
    return (
      <BoxNew
        width={"100%"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        classes="mg mt-80"
      >
        <NoDashboardImage />
        <TypographyNew
          classes="mg mt-24 mb-8 "
          type="h3"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          답변 템플릿 없음
        </TypographyNew>
        <TypographyNew
          type="body2_rg_long"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          classes="mg mt-8"
        >
          작성한 답변 템플릿이 없습니다.
        </TypographyNew>
      </BoxNew>
    );
  };

  /** 훈련별 답변 활성화 탭 UI */
  const renderReplyTemplateActivateList = () => {
    return (
      <BoxNew direction="column" height={"100%"}>
        <BoxNew classes="mg mt-32" justifyContent="space-between">
          <BoxNew>
            <TypographyNew type="subtitle2">
              {t("program.programList")}
            </TypographyNew>
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
              classes="mg ml-4"
            >
              {configListTotal ?? 0}
            </TypographyNew>
            <TooltipNew message={t("reply.msg.replyActiveConfigInfoMsg")}>
              <IconsNew
                variant="line"
                label="info"
                classes="mg ml-4 mt-2"
                size={14}
                stroke={getColor("COLOR_ICON_INFO")}
              />
            </TooltipNew>
          </BoxNew>
          <BoxNew>
            <ButtonNew
              leadingIcon={
                <IconsNew
                  variant="line"
                  label="filter"
                  stroke={getColor("COLOR_ICON_PRIMARY")}
                  size={20}
                />
              }
              btnStyle={"link"}
              type={"primary"}
              onClick={() => setIsConfigFilterOpen((prev) => !prev)}
              size={"lg"}
              label={t("common.list.filter")}
            />
          </BoxNew>
        </BoxNew>

        {/* 필터  */}
        {isConfigFilterOpen && configFilters}

        <BoxNew
          direction="column"
          classes="pd pt-12 flex-1"
          ref={tableRef}
          style={{
            pointerEvents: hoverBoxClickedRow.replyId ? "none" : undefined,
          }}
        >
          <TableNew
            border="none"
            columnHeight="md"
            data={transformConfigList(configList)}
            endReached={() => {}}
            totalCount={configListTotal}
            header={[
              {
                headerText: "",
                width: "12px",
              },
              {
                headerText: t("program.configRound"),
                width: "76px",
              },
              {
                headerText: t("program.programName"),
                width: "calc(100% - 750px)",
              },
              {
                headerText: t("program.trainingDuration"),
                width: "370px",
              },
              {
                headerText: t("program.isActivateFlag"),
                width: "138px",
              },
              {
                headerText: t("reply.autoReplyActivate"),
                width: "102px",
              },
            ]}
            width={"100%"}
            height={
              checkTableRenderStatus("CONFIG") === 0
                ? `${
                    tableContainerPosition.height -
                    (245 + (isConfigFilterOpen ? 56 : 0))
                  }px`
                : "0px"
            }
          />
          {renderTableSwitch("CONFIG")}
          <BoxNew></BoxNew>
        </BoxNew>

        {configList && configList?.length > 0 ? <></> : <NoInfo />}
      </BoxNew>
    );
  };

  /** 답변 템플릿 목록 탭 UI */
  const renderReplyTemplateList = () => {
    return (
      <BoxNew direction="column" height={"calc(100% - 84px)"}>
        <BoxNew classes="mg mt-32" justifyContent="space-between">
          <BoxNew>
            <TypographyNew type="subtitle2">
              {t("reply.reportReplyList")}
            </TypographyNew>
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
              classes="mg ml-4"
            >
              {replysTotal ?? 0}
            </TypographyNew>
            <TooltipNew message={t("reply.msg.reportTemplatInfoMsg")}>
              <IconsNew
                variant="line"
                label="info"
                classes="mg ml-4 mt-2"
                size={14}
                stroke={getColor("COLOR_ICON_INFO")}
              />
            </TooltipNew>
          </BoxNew>
          <BoxNew>
            <BoxNew>
              <ButtonNew
                btnStyle={"link"}
                size={"lg"}
                classes="mg mr-4"
                state={
                  checkboxGroup.selected.length > 0 ? "default" : "disable"
                }
                leadingIcon={
                  <IconsNew
                    variant="line"
                    label="delete"
                    stroke={getColor("COLOR_ICON_PRIMARY")}
                  />
                }
                onClick={() => {
                  toggleDeleteModal(true);
                }}
                label={t("common.button.delete")}
              />
            </BoxNew>
            <ButtonNew
              leadingIcon={
                <IconsNew
                  variant="line"
                  label="filter"
                  stroke={getColor("COLOR_ICON_PRIMARY")}
                  size={20}
                />
              }
              btnStyle={"link"}
              type={"primary"}
              onClick={() => setIsReplyFilterOpen((prev) => !prev)}
              size={"lg"}
              label={t("common.list.filter")}
            />
          </BoxNew>
        </BoxNew>

        {isReplyFilterOpen && replyFilters}

        <BoxNew
          direction="column"
          classes="pd pt-12 flex-1"
          ref={tableRef}
          style={{
            pointerEvents: hoverBoxClickedRow.replyId ? "none" : undefined,
          }}
        >
          <TableNew
            border="none"
            clickIds={checkboxGroup.selected.map((replyId) => String(replyId))}
            columnHeight="md"
            highlightIds={[replyTemplateId]}
            data={transformReplyList(replys)}
            totalCount={replysTotal}
            endReached={() => {}}
            checkBoxClick={() =>
              checkboxGroup.handleAllClick(
                checkboxGroup.selected.length === replys.length
                  ? []
                  : replys.map((r) => r.replyId)
              )
            }
            header={[
              {
                headerText: "",
                width: "12px",
              },
              {
                headerText: t("reply.reportReplyName"),
                width: "calc(100% - 450px)",
              },
              {
                headerText: t("reply.reportReplyType"),
                width: "88px",
              },
              {
                headerText: t("reply.reportReplyDefault"),
                width: "80px",
              },
              // {
              //   headerText: t("common.time.addDate"),
              //   width: "200px",
              // },
              {
                headerText: t("common.time.updateDate"),
                width: "200px",
              },
            ]}
            width={"100%"}
            height={
              checkTableRenderStatus("REPLY") === 0
                ? `${
                    tableContainerPosition.height -
                    (245 + (isReplyFilterOpen ? 56 : 0))
                  }px`
                : "0px"
            }
          />
          {renderTableSwitch("REPLY")}
          <BoxNew>
            <ReplyCrudHoverBox
              isDisplayed={!!hoverBoxClickedRow.replyId}
              hoverBoxNewCoord={hoverBoxNewCoord}
              onOutsideClick={onOutsideClick}
              onClickDetail={() => {
                changeTargetReply(hoverBoxClickedRow.replyId);
                onCloseBox();
                setOpenDetailModal(true);
              }}
              onClickUpdate={() => {
                changeTargetReply(hoverBoxClickedRow.replyId);
                onCloseBox();
                changeRegisterReplyTemplateModalMode("EDIT");
              }}
              onClickDelete={() => {
                changeTargetReply(hoverBoxClickedRow.replyId);
                onCloseBox();
                toggleDeleteModal(true);
              }}
            />
          </BoxNew>
        </BoxNew>
      </BoxNew>
    );
  };
  const handleUpdateDefaultReport = async () => {
    let resp: any = await updateReply({
      updateUser: "admin",
      replyId: checkboxGroup.selected[0],
      replyTargetType: replyTargetType === "train" ? 1 : 2,
    });
    if (resp.payload) {
      toast.toastMsg(
        nanoid(),
        t("reply.msg.reportSetDefaultReplySuccessMsg"),
        "success"
      );
      getReplys(replyFilterData);
    }
  };

  const handleDeleteReply = async () => {
    let resp: any = await deleteReply(
      targetReply !== -1 ? [targetReply] : checkboxGroup.selected
    );
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("reply.msg.reportReplyDeleteSuccessMsg"),
        "success"
      );
      checkboxGroup.handleAllClick([]);
      getReplys(replyFilterData);
    } else {
      toast.toastMsg(nanoid(), t("reply.msg.answerTemplateDeleteMsg"), "error");
    }
    if (!targetReply) {
      checkboxGroup.handleAllClick([]);
    }
    changeTargetReply(-1);
    toggleDeleteModal(false);
  };

  /** 삭제 확인 모달 */
  const renderDeleteReplyModal = () => {
    return (
      <CommonModal
        title={"답변 템플릿 삭제"}
        open={openDeleteModal}
        onClose={() => {
          changeTargetReply(-1);
          toggleDeleteModal(false);
        }}
        type={"delete"}
        body={
          <BoxNew width={"100%"}>
            <TypographyNew type="body2_rg">
              {t("template.msg.templateDeleteTemplateMsg")}
            </TypographyNew>
          </BoxNew>
        }
        afterSubmitButton={handleDeleteReply}
      />
    );
  };
  const updateDectivate = async (replyId: number, nextStatus: boolean) => {
    const resp: any = await updateProgram({
      configId: replyId,
      autoReplyFlag: nextStatus,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        nextStatus
          ? t("reply.msg.reportActivateAutoFlagSuccessMsg")
          : t("reply.msg.reportDeactivateAutoFlagSuccessMsg"),
        "success"
      );
      getProgramList(configFilterData);
    } else {
      toast.toastMsg(
        nanoid(),
        nextStatus
          ? t("reply.msg.reportActivateAutoFlagFailMsg")
          : t("reply.msg.reportDeactivateAutoFlagFailMsg"),
        "error"
      );
    }

    changeTargetReply(-1);
    toggleActivatModal(false);
  };
  const renderActivateModal = () => {
    const activateTarget = configList?.filter(
      (item) => item.configId === targetReply
    )[0];
    return (
      <ModalNew
        open={openActivateModal}
        title={
          activateTarget?.autoReplyFlag
            ? t("reply.autoReplyDeactivate")
            : t("reply.autoReplyActivate")
        }
        width={388}
        onClose={() => {
          changeTargetReply(-1);
          toggleActivatModal(false);
        }}
        body={
          <BoxNew>
            <TypographyNew type="body2_rg">
              {activateTarget?.autoReplyFlag
                ? t("reply.msg.autoReplyDeactivateConfirmMsg")
                : t("reply.msg.autoReplyActivateConfirmMsg")}
            </TypographyNew>
          </BoxNew>
        }
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              type="secondary"
              btnStyle="fill"
              onClick={() => {
                changeTargetReply(-1);
                toggleActivatModal(false);
              }}
              label={t("common.button.cancel")}
            />

            <ButtonNew
              classes="mg ml-8"
              type="primary"
              btnStyle="fill"
              onClick={() =>
                updateDectivate(targetReply, !activateTarget?.autoReplyFlag)
              }
              label={
                activateTarget?.autoReplyFlag
                  ? t("program.programDeactive")
                  : t("program.programActivate")
              }
            />
          </BoxNew>
        }
      />
    );
  };

  return (
    <BoxNew classes="main reportSetting" ref={tableContainerRef}>
      {registerReplyTemplateModalMode !== "CLOSE" && (
        <RegitOrEditTemplateModal
          type={registerReplyTemplateModalMode}
          isOpen={true}
          targetId={targetReply}
          onClose={() => {
            changeTargetReply(-1);
            changeRegisterReplyTemplateModalMode("CLOSE");
          }}
          setReplayTemplateId={setReplayTemplateId}
        />
      )}
      {openDeleteModal && renderDeleteReplyModal()}
      {openActivateModal && renderActivateModal()}
      {openDefaultReplyModal && (
        <DefaultReplyModal
          isOpen={openDefaultReplyModal}
          onClose={() => toggleDefaultReplyModal(false)}
          replys={replys.filter((r) => r.autoYn)}
          filterData={replyFilterData}
        />
      )}
      {openCreateDateModal &&
        dateModal(
          selectedCreateDate,
          setSelectedCreateDate,
          openCreateDateModal,
          toggleCreateDateModal
        )}
      {openUpdateDateModal &&
        dateModal(
          selectedUpdateDate,
          setSelectedUpdateDate,
          openUpdateDateModal,
          toggleUpdateDateModal
        )}
      {openProgramDateModal &&
        dateModal(
          selectedPeriod,
          setSelectedPeriod,
          openProgramDateModal,
          toggleProgramDateModal
        )}
      {openDetailModal && (
        <DetailTemplateModal
          isOpen={openDetailModal}
          onClose={() => setOpenDetailModal(false)}
          targetId={targetReply}
          filterData={replyFilterData}
        />
      )}
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath
            path={[t("menu.reportManagement"), t("menu.reportSetting")]}
          />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.reportSetting")}
          </TypographyNew>
        </BoxNew>
        <BoxNew>
          <ButtonNew
            btnStyle="fill"
            size="lg"
            type="secondary"
            leadingIcon={<IconsNew variant="line" label="edit" />}
            onClick={() => toggleDefaultReplyModal(true)}
            label={t("reply.reportReplySetDefault")}
          />
          <ButtonNew
            classes="mg ml-10"
            btnStyle="fill"
            size="lg"
            type="secondary"
            leadingIcon={<IconsNew variant="line" label="plusCircle" />}
            onClick={() => changeRegisterReplyTemplateModalMode("CREATE")}
            label={t("reply.reportReplyAdd")}
          />
        </BoxNew>
      </BoxNew>
      <BoxNew height={40} style={{ minHeight: 40 }}>
        <TypographyNew type="subtitle2">{t("menu.reportCenter")}</TypographyNew>
        <TypographyNew classes="mg ml-20" type="body2_rg">
          {reportCenterInfo?.reportInfo ?? t("common.info.noData")}
        </TypographyNew>
      </BoxNew>

      <TabsNew
        tabs={[
          {
            color: "default",
            text: t("reply.reportReplyList"),
            isNew: false,
          },
          {
            color: "default",
            text: t("reply.activateReplyByConfig"),
            isNew: false,
          },
        ]}
        selected={tab}
        size="md"
        selectTab={(e) => setTab(e)}
      />

      {tab === 0 && renderReplyTemplateList()}
      {tab === 1 && renderReplyTemplateActivateList()}
    </BoxNew>
  );
};

export default ReportSetting;

const ReplyCrudHoverBox = ({
  isDisplayed,
  hoverBoxNewCoord,
  onOutsideClick,
  onClickDetail,
  onClickUpdate,
  onClickDelete,
}: {
  isDisplayed: boolean;
  hoverBoxNewCoord: { x: number; y: number };
  onOutsideClick: (event: MouseEvent) => void;
  onClickDetail?: () => void;
  onClickUpdate?: () => void;
  onClickDelete?: () => void;
}) => {
  const { t } = useTranslation();
  const [targetRef] = useOutsideClick(onOutsideClick);

  return (
    <DropdownBody
      location={{
        x: hoverBoxNewCoord.x,
        y: hoverBoxNewCoord.y,
      }}
      style={{
        position: "fixed",
        display: isDisplayed ? "block" : "none",
        pointerEvents: "auto",
      }}
    >
      <BoxNew ref={targetRef} direction={"column"}>
        {onClickDetail && (
          <DropdownItem
            frontIcon={<IconsNew variant={"line"} label={"info"} />}
            label={t("common.button.detail")}
            onClick={onClickDetail}
          />
        )}
        {onClickUpdate && (
          <DropdownItem
            frontIcon={<IconsNew variant={"line"} label={"edit2"} />}
            label={t("common.button.update")}
            onClick={onClickUpdate}
          />
        )}
        <DropdownItem state={"divider"} />
        {onClickDelete && (
          <DropdownItem
            frontIcon={
              <IconsNew
                variant={"line"}
                label={"delete"}
                stroke={getColor("COLOR_TEXT_ERROR")}
              />
            }
            label={
              <TypographyNew exactColor={getColor("COLOR_TEXT_ERROR")}>
                {t("common.button.delete")}
              </TypographyNew>
            }
            onClick={onClickDelete}
          />
        )}
      </BoxNew>
    </DropdownBody>
  );
};
