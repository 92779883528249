import classNames from "classnames";
import { CSSProperties, useEffect, useRef, useState } from "react";

import "./index.scss";

interface ScrollBoxProps {
  children: React.ReactNode;
  classes?: string;
  height?: number | string;
  maxHeight?: number | string;
  width?: number | string;
  tabIndex?: number;
  onMouseLeave?: (e: any) => void;
  backgroundColor?: string;
  px?: number;
  py?: number;
}

// fasoo-common-ui 필요 속성만 단순화함
const ScrollBox = ({
  children,
  classes,
  height,
  maxHeight,
  width,
  tabIndex = 0,
  backgroundColor,
  onMouseLeave,
  px = 4,
  py = 4,
}: ScrollBoxProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const getStyleSize = (size: number | string | undefined) => {
    if (typeof size === "number") {
      return size + "px";
    }
    return size;
  };

  const checkIfScrollable = () => {
    if (ref.current) {
      return ref.current.scrollHeight > ref.current.clientHeight;
    }
    return false;
  };

  useEffect(() => {
    if (ref.current) {
      const isScrollable = checkIfScrollable();
      setIsScrollable(isScrollable);
    }
  }, [height, maxHeight, children]);

  return (
    <div
      ref={ref}
      className={classNames("scroll-box", classes, {
        scrollable: isScrollable,
      })}
      style={
        {
          height: getStyleSize(height),
          maxHeight: getStyleSize(maxHeight),
          width: getStyleSize(width),
          backgroundColor: backgroundColor,
          "--scrollbar-border-color": backgroundColor || "white",
          padding: !isScrollable
            ? `${py}px ${px}px`
            : `${py}px ${px - 4}px ${py}px ${px}px`,
        } as CSSProperties
      }
      tabIndex={tabIndex}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export default ScrollBox;
