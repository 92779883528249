import "./index.scss";
import "../../../shared/style/filters.scss";

import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Button as ButtonNew,
  Icons as IconsNew,
  Dropdown as DropdownNew,
  DropdownItem as DropdownItemNew,
  Checkbox as CheckboxNew,
  InputField,
  Table as TableNew,
  Tooltip as TooltipNew,
  Chip,
  useToast,
  Modal,
  CalendarRange,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import {
  ConfigListType,
  TrainingResultReqType,
  TrainingResultType,
} from "../../../types/Send";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import useSendHistoryStore from "../../../redux/dispatcher/useSendHistoryStore";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { useTranslation } from "react-i18next";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { Scenario } from "../../../types/Scenario";
import MailBody from "../../../component/MailBody";
import utilsCommon from "../../../shared/utils/common";
import { getLocalCustomerCode } from "../../../shared/utils/auth";

import { nanoid } from "@reduxjs/toolkit";

import ReportModal from "./ReportModal";
import { FilterBtnActivityCheckUtils } from "../../../shared/utils/FilterBtnActivityCheckUtils";
import { useElementPosition } from "../../../hooks/useElementPosition";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import NoMailImage from "../../../shared/image/NoMailImage";
import dayjs from "dayjs";
import { useTableOrder } from "../../../hooks/useTableOrder";
import FilterCheckerUtils from "../../ManageScenario/List/FilterCheckerUtils";
import { DataStructureUtils } from "../../../shared/utils/DataStructureUtils";
interface filterType {
  id: number;
  text: string;
}
export interface TrainResultFilterProps {
  configName: string;
  clickTypes: number[];
  sendResult: filterType;
  callResult: filterType;
  completionType: filterType;
  deptName: string;
  userSearchParam: string;
  configId: number | null;
  configNames: string[];
  userSearchParams: string[];
  deptNames: string[];
  configIds?: number[];
  orderId?: string;
  orderType?: string;
}

enum ColumnsTypeToOrder {
  configId = "configId",
  deptName = "deptName",
  sendingDate = "sendingDate",
}

const ManageTrainResult: React.FC = () => {
  const PAGING_SIZE = 100;
  const { t, i18n } = useTranslation();

  const { programState, getProgramList, exportResultFile } = useProgramStore();
  const { sendHistoryState, getTrainingResult, resendQuizSendHistory } =
    useSendHistoryStore();
  const { getMailTemplateData } = useMailTemplateStore();

  const [isResendModalOpen, setIsResendModalOpen] = useState<number>(-1);
  const [isResendModalAllOpen, setIsResendModalAllOpen] =
    useState<boolean>(false);

  const [historyList, setHistoryList] = useState<TrainingResultType[]>([]);
  const [historyListTotal, setHistoryListTotal] = useState<number>(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [buttonClick, setButtonClick] = useState(0);

  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setFilter({
          ...filter,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        });
    },
  });

  const initialFilter: TrainResultFilterProps = {
    configName: "",
    clickTypes: [],
    sendResult: { id: 0, text: t("common.auth.all") },
    callResult: { id: 0, text: t("common.auth.all") },
    completionType: { id: 0, text: t("common.auth.all") },
    deptName: "",
    userSearchParam: "",
    configId: null,
    configNames: [],
    userSearchParams: [],
    deptNames: [],
    orderId: getOrderParams().orderId,
    orderType: getOrderParams().orderType,
  };
  const [filter, setFilter] = useState<TrainResultFilterProps>(initialFilter);

  const [copyFilter, changeCopyFilter] = useState<
    TrainingResultReqType | undefined
  >();

  const clickTypes = [
    // {
    //   id: 0,
    //   text: t("common.auth.all"),
    // },

    {
      id: 1,
      text: t("template.mailOpen"),
    },
    {
      id: 2,
      text: t("template.linkOpen"),
    },
    {
      id: 3,
      text: t("template.personalInfo"),
    },
    {
      id: 4,
      text: t("dashboard.attachOpen"),
    },
    {
      id: 5,
      text: t("common.info.noData"),
    },
  ];

  const sendResults = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("program.programSendWait") },
    { id: 2, text: t("program.programSendSuccess") },
    { id: 3, text: t("program.programSendFail") },
  ];
  const callResults = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("dashboard.report") },
    { id: 2, text: t("dashboard.dashboardUnreport") },
  ];

  const completionTypes = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: "-" },
    { id: 2, text: t("quiz.label.notRequired") },
    { id: 3, text: t("quiz.label.notStarted") },
    { id: 4, text: t("quiz.label.completed") },
  ];

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const initialSelectedPeriod = {
    value: "PERIOD_3MONTH",
    start: dayjs()
      .subtract(3, "month")
      .add(1, "day")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  };
  const [selectedPeriod, changeSelectedPeriod] = useState(
    initialSelectedPeriod
  );

  const [searchUserName, setSearchUserName] = useState<string>("");
  const [searchDeptName, setSearchDeptName] = useState<string>("");
  const [searchConfigName, setSearchConfigName] = useState<string>("");

  const [searchUserNames, setSearchUserNames] = useState<string[]>([]);
  const [searchDeptNames, setSearchDeptNames] = useState<string[]>([]);
  const [searchConfigNames, setSearchConfigNames] = useState<string[]>([]);

  const listCheckbox = useCheckboxGroup();
  const filterTypeCheckbox = useCheckboxGroup();
  const tempConfigIdsCheckbox = useCheckboxGroup();
  const [templateHtml, setTemplateHtml] = useState<Scenario | null>();
  const templateRef = useRef<boolean>(false);

  const [hasData, setHasData] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const toast = useToast();

  const onResendMail = async () => {
    let tempFilter: any = {
      // size: 200,
      size: PAGING_SIZE,
      deptName: filter.deptName ?? undefined,
      historyResult: filter.sendResult.id === 0 ? null : filter.sendResult.id,
      callResult:
        filter.callResult.id === 0
          ? null
          : filter.callResult.id === 1
          ? true
          : false,
      configName: filter.configName,
      userSearchParam: filter.userSearchParam,
      configId: filter.configId,
      click1: null,
      click2: null,
      click3: null,
      click4: null,
      intervalTime: utilsCommon.getIntervalTime(),
      configNames: filter.configNames,
      userSearchParams: filter.userSearchParams,
      deptNames: filter.deptNames,
      sendingStartDate:
        selectedPeriod.value === "ALL"
          ? undefined
          : Math.trunc(selectedPeriod.start / 1000),
      sendingEndDate:
        selectedPeriod.value === "ALL"
          ? undefined
          : Math.trunc(selectedPeriod.end / 1000),
    };

    if (
      filter?.clickTypes.length > 0 &&
      !(
        filter?.clickTypes?.includes(1) &&
        filter?.clickTypes?.includes(2) &&
        filter?.clickTypes?.includes(3) &&
        filter?.clickTypes?.includes(4) &&
        filter?.clickTypes?.includes(5)
      ) &&
      !filter?.clickTypes?.includes(0)
    ) {
      if (filter?.clickTypes?.includes(1)) {
        tempFilter = { ...tempFilter, click1: true };
      } else if (!filter?.clickTypes?.indexOf(1)) {
        tempFilter = { ...tempFilter, click1: false };
      }
      if (filter?.clickTypes?.includes(2)) {
        tempFilter = { ...tempFilter, click2: true };
      } else if (!filter?.clickTypes?.indexOf(2)) {
        tempFilter = { ...tempFilter, click2: false };
      }
      if (filter?.clickTypes?.includes(3)) {
        tempFilter = { ...tempFilter, click3: true };
      } else if (!filter?.clickTypes?.indexOf(3)) {
        tempFilter = { ...tempFilter, click3: false };
      }
      if (filter?.clickTypes?.includes(4)) {
        tempFilter = { ...tempFilter, click4: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, click4: false };
      }

      if (filter?.clickTypes?.includes(5)) {
        tempFilter = { ...tempFilter, notClicked: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, notClicked: false };
      }
    }

    if (
      buttonClick > 0 &&
      filter?.clickTypes.length === 1 &&
      filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: false };
      tempFilter = { ...tempFilter, click2: false };
      tempFilter = { ...tempFilter, click3: false };
      tempFilter = { ...tempFilter, click4: false };
    } else if (
      buttonClick > 0 &&
      filter?.clickTypes?.includes(1) &&
      filter?.clickTypes?.includes(2) &&
      filter?.clickTypes?.includes(3) &&
      filter?.clickTypes?.includes(4) &&
      !filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: true };
      tempFilter = { ...tempFilter, click2: true };
      tempFilter = { ...tempFilter, click3: true };
      tempFilter = { ...tempFilter, click4: true };
    }

    if (isResendModalOpen !== -1) {
      const resp: any = await resendQuizSendHistory([
        {
          size: 0,
          sendHistoryId: isResendModalOpen,
          cpCode: getLocalCustomerCode(),
        },
      ]);

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(nanoid(), t("quiz.msg.resendSuccess"), "success");

        loadHistory(tempFilter);
        setIsResendModalOpen(-1);
      } else {
        toast.toastMsg(nanoid(), t("quiz.msg.resendFail"), "error");
        setIsResendModalOpen(-1);
      }
    }

    if (isResendModalAllOpen) {
      const resp: any = await resendQuizSendHistory(
        listCheckbox.selected.map((s) => {
          return {
            size: 0,
            sendHistoryId: parseInt(s as string),
            cpCode: getLocalCustomerCode(),
          };
        })
      );

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(nanoid(), t("quiz.msg.resendSuccess"), "success");

        loadHistory(tempFilter);
        listCheckbox.handleAllClick([]);
        setIsResendModalAllOpen(false);
      } else {
        toast.toastMsg(nanoid(), t("quiz.msg.resendFail"), "error");
        setIsResendModalAllOpen(false);
      }
    }
  };

  const onHoverTemplate = async (templateId: number) => {
    if (!templateRef.current) {
      const resp: any = await getMailTemplateData(templateId);

      if (resp.payload && resp.payload.code === "SUCCESS") {
        setTemplateHtml(resp.payload.data);
      } else {
        setTemplateHtml(null);
      }
      templateRef.current = true;
    }
  };
  useEffect(() => {
    getProgramList({
      orderId: "configId",
      orderType: "desc",
      startedFlag: true,
    });
  }, []);

  useEffect(() => {
    const urlFilter = window.sessionStorage.getItem("resultFilter");
    let name = undefined;
    let callResult = undefined;
    let configId = undefined;
    if (urlFilter) {
      const splitUrlFilter = urlFilter.split("&");
      name = splitUrlFilter[1]?.split("=")[1];
      configId =
        splitUrlFilter[0]?.split("=")[1] &&
        parseInt(splitUrlFilter[0]?.split("=")[1]) > 0
          ? splitUrlFilter[0]?.split("=")[1]
          : null;
      if (urlFilter.includes("callResult")) {
        callResult = false;
      } else {
        callResult = null;
      }
      setFilter({
        ...filter,
        userSearchParam: name,
        configId: configId ? parseInt(configId) : null,
        callResult:
          callResult === false
            ? { id: 2, text: t("dashboard.dashboardUnreport") }
            : {
                id: 0,
                text: t("common.auth.all"),
              },
      });
    }
    window.sessionStorage.removeItem("resultFilter");
    filterTypeCheckbox.handleAllClick([1, 2, 3, 4, 5]);
  }, [window.location.href]);

  useEffect(() => {
    if (!hasData && sendHistoryState.total > 0) {
      setHasData(true);
    } else if (sendHistoryState.total === 0) {
      setHasData(false);
    }
    setHistoryListTotal(sendHistoryState.total);
  }, [sendHistoryState.sendHistory]);

  useEffect(() => {
    // setHasNextPage(true);

    let tempFilter: any = {
      size: PAGING_SIZE,
      deptName: filter.deptName ?? undefined,
      historyResult: filter.sendResult.id === 0 ? null : filter.sendResult.id,
      completionType:
        filter.completionType.id === 0 ? null : filter.completionType.id,
      callResult:
        filter.callResult.id === 0
          ? null
          : filter.callResult.id === 1
          ? true
          : false,
      configName: filter.configName,
      userSearchParam: filter.userSearchParam,
      configId: filter.configId,
      click1: null,
      click2: null,
      click3: null,
      click4: null,
      intervalTime: utilsCommon.getIntervalTime(),
      configNames: filter.configNames,
      userSearchParams: filter.userSearchParams,
      deptNames: filter.deptNames,
      configIds: filter.configIds,
      orderId: filter.orderId,
      orderType: filter.orderType,
      sendingStartDate:
        selectedPeriod.value === "ALL"
          ? undefined
          : Math.trunc(selectedPeriod.start / 1000),
      sendingEndDate:
        selectedPeriod.value === "ALL"
          ? undefined
          : Math.trunc(selectedPeriod.end / 1000),
    };

    if (
      filter?.clickTypes.length > 0 &&
      !(
        filter?.clickTypes?.includes(1) &&
        filter?.clickTypes?.includes(2) &&
        filter?.clickTypes?.includes(3) &&
        filter?.clickTypes?.includes(4) &&
        filter?.clickTypes?.includes(5)
      ) &&
      !filter?.clickTypes?.includes(0)
    ) {
      if (filter?.clickTypes?.includes(1)) {
        tempFilter = { ...tempFilter, click1: true };
      } else if (!filter?.clickTypes?.indexOf(1)) {
        tempFilter = { ...tempFilter, click1: false };
      }
      if (filter?.clickTypes?.includes(2)) {
        tempFilter = { ...tempFilter, click2: true };
      } else if (!filter?.clickTypes?.indexOf(2)) {
        tempFilter = { ...tempFilter, click2: false };
      }
      if (filter?.clickTypes?.includes(3)) {
        tempFilter = { ...tempFilter, click3: true };
      } else if (!filter?.clickTypes?.indexOf(3)) {
        tempFilter = { ...tempFilter, click3: false };
      }
      if (filter?.clickTypes?.includes(4)) {
        tempFilter = { ...tempFilter, click4: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, click4: false };
      }

      if (filter?.clickTypes?.includes(5)) {
        tempFilter = { ...tempFilter, notClicked: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, notClicked: false };
      }
    }

    if (
      buttonClick > 0 &&
      filter?.clickTypes.length === 1 &&
      filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: false };
      tempFilter = { ...tempFilter, click2: false };
      tempFilter = { ...tempFilter, click3: false };
      tempFilter = { ...tempFilter, click4: false };
    } else if (
      buttonClick > 0 &&
      filter?.clickTypes?.includes(1) &&
      filter?.clickTypes?.includes(2) &&
      filter?.clickTypes?.includes(3) &&
      filter?.clickTypes?.includes(4) &&
      !filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: true };
      tempFilter = { ...tempFilter, click2: true };
      tempFilter = { ...tempFilter, click3: true };
      tempFilter = { ...tempFilter, click4: true };
    }

    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);

    !window.sessionStorage.getItem("resultFilter") && loadHistory(tempFilter);
    changeCopyFilter(tempFilter);
  }, [filter, selectedPeriod]);

  const loadHistory = async (tempFilter: any) => {
    const resp: any = await getTrainingResult(tempFilter);
    setHistoryList(resp.payload.data.list);
  };

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      sendResult: {
        ...prev.sendResult,
        text: sendResults.filter((c) => c.id === prev.sendResult.id)[0].text,
      },
      callResult: {
        ...prev.callResult,
        text: callResults.filter((c) => c.id === prev.callResult.id)[0].text,
      },
    }));
  }, [i18n.language]);

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [historyList]);

  // const isItemLoaded = (index: number): boolean => {
  //   return index < historyList.length && historyList[index] !== undefined;
  // };

  const loadMoreItems = async () => {
    if (!hasNextPage || isNextPageLoading) return;

    setIsNextPageLoading(true);

    let tempFilter: any = {
      deptName: filter.deptName ?? undefined,
      callResult:
        filter.callResult.id === 0
          ? null
          : filter.callResult.id === 1
          ? true
          : false,
      historyResult: filter.sendResult.id === 0 ? null : filter.sendResult.id,
      completionType:
        filter.completionType.id === 0 ? null : filter.completionType.id,
      configName: filter.configName,
      userSearchParam: filter.userSearchParam,
      configId: filter.configId,
      click1: null,
      click2: null,
      click3: null,
      click4: null,
      intervalTime: utilsCommon.getIntervalTime(),
      configNames: filter.configNames,
      userSearchParams: filter.userSearchParams,
      deptNames: filter.deptNames,
      configIds: filter.configIds,
      sendingStartDate:
        selectedPeriod.value === "ALL"
          ? undefined
          : Math.trunc(selectedPeriod.start / 1000),
      sendingEndDate:
        selectedPeriod.value === "ALL"
          ? undefined
          : Math.trunc(selectedPeriod.end / 1000),
    };

    if (
      filter?.clickTypes.length > 0 &&
      !(
        filter?.clickTypes?.includes(1) &&
        filter?.clickTypes?.includes(2) &&
        filter?.clickTypes?.includes(3) &&
        filter?.clickTypes?.includes(4) &&
        filter?.clickTypes?.includes(5)
      ) &&
      !filter?.clickTypes?.includes(0)
    ) {
      if (filter?.clickTypes?.includes(1)) {
        tempFilter = { ...tempFilter, click1: true };
      } else if (!filter?.clickTypes?.indexOf(1)) {
        tempFilter = { ...tempFilter, click1: false };
      }
      if (filter?.clickTypes?.includes(2)) {
        tempFilter = { ...tempFilter, click2: true };
      } else if (!filter?.clickTypes?.indexOf(2)) {
        tempFilter = { ...tempFilter, click2: false };
      }
      if (filter?.clickTypes?.includes(3)) {
        tempFilter = { ...tempFilter, click3: true };
      } else if (!filter?.clickTypes?.indexOf(3)) {
        tempFilter = { ...tempFilter, click3: false };
      }
      if (filter?.clickTypes?.includes(4)) {
        tempFilter = { ...tempFilter, click4: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, click4: false };
      }

      if (filter?.clickTypes?.includes(5)) {
        tempFilter = { ...tempFilter, notClicked: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, notClicked: false };
      }
    }

    if (
      buttonClick > 0 &&
      filter?.clickTypes.length === 1 &&
      filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: false };
      tempFilter = { ...tempFilter, click2: false };
      tempFilter = { ...tempFilter, click3: false };
      tempFilter = { ...tempFilter, click4: false };
    } else if (
      buttonClick > 0 &&
      filter?.clickTypes?.includes(1) &&
      filter?.clickTypes?.includes(2) &&
      filter?.clickTypes?.includes(3) &&
      filter?.clickTypes?.includes(4) &&
      !filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: true };
      tempFilter = { ...tempFilter, click2: true };
      tempFilter = { ...tempFilter, click3: true };
      tempFilter = { ...tempFilter, click4: true };
    }

    try {
      const resp: any =
        !window.sessionStorage.getItem("resultFilter") &&
        (await getTrainingResult({
          ...tempFilter,
          size: PAGING_SIZE,
          cursor: historyList[historyList.length - 1].cursor,
        }));

      if (resp && resp.payload && resp.payload.data && resp.payload.data.list) {
        if (resp.payload.data.list.length < PAGING_SIZE) {
          setHasNextPage(false);
        } else {
          setHasNextPage(true);
        }
        setHistoryList((prev) => {
          return [...prev, ...resp.payload.data.list];
        });
      }
    } catch (error) {
      console.error("Failed to load more items:", error);
    } finally {
      setIsNextPageLoading(false);
    }

    changeCopyFilter(tempFilter);
  };

  useEffect(() => {
    if (programState.configListWithCount?.list) {
      tempConfigIdsCheckbox.handleAllClick(
        programState.configListWithCount?.list.map((item: any) => item.configId)
      );
    }
  }, [programState.configListWithCount?.list]);

  const [tableHeight, setTableHeight] = useState(660);

  useEffect(() => {
    const handleResize = () => {
      const tableElement = document.querySelector(
        ".table-scroll"
      ) as HTMLElement;
      const tempTableHeight = tableElement ? tableElement.offsetHeight : 0;
      tableElement && setTableHeight(tempTableHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);

  const transformSendHistoryList = (sendHistoryList: TrainingResultType[]) => {
    const sendHistoryListTransformed = [];

    for (let i = 0; i < sendHistoryList.length; i++) {
      const s = sendHistoryList[i];

      const dataConfigId = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {s.configId}
        </TypographyNew>
      );

      const dataUser = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          ellipsis
        >
          {`${s.userName} (${s.userEmail})`}
        </TypographyNew>
      );

      const dataDept = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          ellipsis
        >
          {s.deptName}
        </TypographyNew>
      );

      const dataConfigName = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          ellipsis
        >
          {s.quizIds && s.quizIds !== ""
            ? `[${t("quiz.table.trainingQuiz")}] ${s.configName}`
            : s.configName}
        </TypographyNew>
      );

      const dataConfigIdx = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {`${s.mailRank} / ${s.mailTotal}`}
        </TypographyNew>
      );

      const dataTemplateName = (
        <BoxNew
          onMouseEnter={() => onHoverTemplate(s.templateId)}
          onMouseLeave={() => (templateRef.current = false)}
        >
          {templateHtml ? (
            <TooltipNew
              message={
                <>
                  <BoxNew classes="mg mb-8">
                    <TypographyNew exactColor="white">
                      {s.templateName}
                    </TypographyNew>
                  </BoxNew>
                  <MailBody
                    mailWidth={240}
                    height={200}
                    allowScroll={false}
                    body={
                      templateHtml.imagePath
                        ? templateHtml.templateHtml.replaceAll(
                            "##IMAGE##",
                            templateHtml.imagePath
                          )
                        : templateHtml.templateHtml
                    }
                    id={i}
                    width="300px"
                  />
                </>
              }
              width={"100%"}
            >
              <TypographyNew
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
                ellipsis
              >
                {s.templateName}
              </TypographyNew>
            </TooltipNew>
          ) : (
            <TooltipNew
              message={
                <TypographyNew exactColor="white">
                  {t("template.deletedScenario")}
                </TypographyNew>
              }
              width={"100%"}
              classes="scenario__tooltip"
            >
              <TypographyNew
                lineHeight="normal"
                ellipsis
                as={"p"}
                width={"90%"}
              >
                {s.templateName}
              </TypographyNew>
            </TooltipNew>
          )}
        </BoxNew>
      );

      const dataSendingData = (
        <TypographyNew
          classes="tnum"
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          ellipsis
        >
          {utilsCommon.getFullDateStr(s.sendingDate)}
        </TypographyNew>
      );

      const dataSendStatus =
        s.historyResult === 1 ? (
          <BoxNew classes="dot__container">
            <BoxNew classes="dot yellow__dot" />
            <TypographyNew
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("program.programSendWait")}
            </TypographyNew>
          </BoxNew>
        ) : s.historyResult === 2 ? (
          <BoxNew classes="dot__container">
            <BoxNew classes="dot green__dot" />
            <TypographyNew
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("program.programSendSuccess")}
            </TypographyNew>
          </BoxNew>
        ) : (
          <BoxNew classes="dot__container">
            <BoxNew classes="dot red__dot" />
            <TypographyNew
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("program.programSendFail")}
            </TypographyNew>
          </BoxNew>
        );

      const dataClickType = (
        <BoxNew alignItems="center">
          {s.click1 === 1 && (
            <IconsNew
              variant="line"
              label="mail"
              stroke="#252d38"
              size={16}
              classes="mg mr-8"
            />
          )}
          {s.click2 === 1 && (
            <IconsNew
              variant="line"
              label="link"
              stroke="#252d38"
              size={16}
              classes="mg mr-8"
            />
          )}
          {s.click3 === 1 && (
            <IconsNew
              variant="line"
              label="edit"
              stroke="#252d38"
              size={16}
              classes="mg mr-8"
            />
          )}
          {s.click4 === 1 && (
            <IconsNew
              variant="line"
              label="paperClip"
              stroke="#252d38"
              size={16}
              classes="mg mr-8"
            />
          )}

          {s.click1 === 0 &&
            s.click2 === 0 &&
            s.click3 === 0 &&
            s.click4 === 0 &&
            "-"}
        </BoxNew>
      );

      const dataReportStatus =
        s.historyResult === 2 ? (
          s.callResult ? (
            <BoxNew classes="dot__container">
              <BoxNew classes="dot blue__dot" />
              <TypographyNew
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("dashboard.report")}
              </TypographyNew>
            </BoxNew>
          ) : (
            <BoxNew classes="dot__container">
              <BoxNew classes="dot secondary__dot" />
              <TypographyNew
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("dashboard.dashboardUnreport")}
              </TypographyNew>
            </BoxNew>
          )
        ) : (
          <BoxNew classes="dot__container">
            <TypographyNew
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              -
            </TypographyNew>
          </BoxNew>
        );

      const dataQuizComplete =
        s.completionType === 1 ? (
          "-"
        ) : s.completionType === 2 ? (
          `${t("quiz.label.notRequired")}`
        ) : s.completionType === 3 ? (
          <BoxNew alignItems="center">
            <BoxNew
              classes={
                s.resendFlag
                  ? `dot__container resend__container`
                  : `dot__container`
              }
            >
              <BoxNew classes="dot gray__dot" />
              <TypographyNew
                classes="resend__text"
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.label.notStarted")}
              </TypographyNew>
              <BoxNew
                classes="resend__button"
                onClick={() => setIsResendModalOpen(s.sendHistoryId)}
              >
                {t("quiz.label.resend")}
              </BoxNew>
            </BoxNew>
          </BoxNew>
        ) : (
          <BoxNew classes="dot__container">
            <BoxNew classes="dot blue__dot" />
            <TypographyNew
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("quiz.label.completed")}
            </TypographyNew>
          </BoxNew>
        );

      const historyTransformed = {
        data: [
          { columnText: dataConfigId },
          { columnText: dataUser },
          { columnText: dataDept },
          { columnText: dataConfigName },
          { columnText: dataConfigIdx },
          { columnText: dataTemplateName },
          { columnText: dataSendingData },
          { columnText: dataSendStatus },
          { columnText: dataClickType },
          { columnText: dataReportStatus },
          { columnText: dataQuizComplete },
        ],
        id: String(s.sendHistoryId),
        checkBoxClick: () => {
          listCheckbox.handleClick(s.sendHistoryId);
        },
      };
      sendHistoryListTransformed.push(historyTransformed);
    }

    return sendHistoryListTransformed;
  };

  const checkAllResend = () => {
    let flag = false;

    listCheckbox.selected.map((s) => {
      historyList.map((h) => {
        if (
          parseInt(s as string) === h.sendHistoryId &&
          h.historyResult === 2 &&
          h.completionType === 3 &&
          !h.completionFlag
        ) {
          flag = true;
        }
      });
    });

    return flag;
  };

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const resetFilter = () => {
    setFilter(initialFilter);
    setSearchConfigNames([]);
    setSearchDeptNames([]);
    setSearchUserNames([]);
    tempConfigIdsCheckbox.handleAllClick(
      programState.configListWithCount?.list.map(
        (item: ConfigListType) => item.configId
      )
    );
    changeSelectedPeriod(initialSelectedPeriod);
  };

  function checkAnyFilterSelected() {
    const isFilterSelected =
      !DataStructureUtils.isObjectEqual(initialFilter, filter) ||
      selectedPeriod.value !== initialSelectedPeriod.value;
    return isFilterSelected;
  }

  const changeDateFilter = (type: string) => {
    if (type === "ALL") {
      changeSelectedPeriod({
        value: type,
        start: new Date().valueOf(),
        end: new Date().valueOf(),
      });
    } else if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 12,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  return (
    <BoxNew
      classes="main manageTrainResult"
      ref={tableContainerRef}
      style={{ width: "max(100%, 1450px)" }}
    >
      {isReportModalOpen &&
        (filter.configId !== null ||
          (filter.configIds && filter.configIds.length > 0)) && (
          <ReportModal
            isOpen={isReportModalOpen}
            onClose={() => setIsReportModalOpen(false)}
            configId={filter.configId || undefined}
            configIds={filter.configIds || undefined}
          />
        )}
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath
            path={[
              t("menu.trainingManagement"),
              t("menu.trainingSendAndResult"),
            ]}
          />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.trainingSendAndResult")}
          </TypographyNew>
        </BoxNew>
        <BoxNew>
          {filter.configId ||
            (filter.configIds && filter.configIds.length > 0 && (
              <ButtonNew
                leadingIcon={
                  <IconsNew variant="line" label="download" stroke="#252d38" />
                }
                btnStyle="fill"
                type="secondary"
                size="lg"
                onClick={() => setIsReportModalOpen(true)}
                classes="mg mr-8"
                label={t("program.exportToReport")}
              />
            ))}
          {hasData && (
            <ButtonNew
              leadingIcon={
                <IconsNew variant="line" label="download" stroke="#252d38" />
              }
              btnStyle="fill"
              type="secondary"
              size="lg"
              onClick={() => exportResultFile({ ...copyFilter, size: 0 })}
              label={t("common.button.exportToExcel")}
            />
          )}
        </BoxNew>
      </BoxNew>
      <BoxNew classes="mg mt-32" justifyContent="space-between">
        <BoxNew alignItems="center">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("program.sendMail")}
          </TypographyNew>
          <TypographyNew
            classes="mg ml-8"
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
          >
            {historyListTotal?.toLocaleString()}
          </TypographyNew>
        </BoxNew>

        <BoxNew>
          <ButtonNew
            leadingIcon={
              <IconsNew
                variant="line"
                label="send"
                stroke="#252d38"
                size={20}
              />
            }
            btnStyle={"link"}
            type={"primary"}
            onClick={() => setIsResendModalAllOpen(true)}
            size={"lg"}
            state={checkAllResend() ? "default" : "disable"}
            label={"일괄 발송"}
          />
          <ButtonNew
            leadingIcon={
              <IconsNew
                variant="line"
                label="filter"
                stroke={getColor("COLOR_ICON_PRIMARY")}
                size={20}
              />
            }
            btnStyle={"link"}
            type={"primary"}
            onClick={() => setIsFilterOpen((prev) => !prev)}
            size={"lg"}
            state={isFilterOpen ? "select" : "default"}
            label={t("common.list.filter")}
          />
        </BoxNew>
      </BoxNew>

      {/* filter start */}
      {isFilterOpen && (
        <BoxNew
          classes="pd pt-12 gap-8 dropdown-filter-container"
          alignItems="center"
        >
          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            buttonClick={buttonClick}
            placeholder={
              t("program.configRound") + " · " + t("common.auth.all")
            }
            value={
              filter.configIds === undefined
                ? ""
                : filter.configIds.length ===
                  programState.configListWithCount?.list.length
                ? t("program.configRound") + " · " + t("common.auth.all")
                : t("program.configRound") + " · " + filter.configIds.length
            }
            // defaultValue={programState.configListWithCount?.list.length}
          >
            <BoxNew direction={"column"} classes="dropdown-filter">
              <BoxNew
                classes="dropdown-filter-title"
                justifyContent="space-between"
                alignItems="center"
              >
                <TypographyNew
                  type="caption1_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("program.configRound")}
                </TypographyNew>
                <TypographyNew
                  type="caption1_rg"
                  exactColor={getColor("COLOR_TEXT_BRAND")}
                  classes="cursor-pointer"
                  onClick={() => {
                    if (
                      tempConfigIdsCheckbox.selected.length ===
                      programState.configListWithCount?.list.length
                    ) {
                      tempConfigIdsCheckbox.handleAllClick([]);
                    } else {
                      tempConfigIdsCheckbox.handleAllClick(
                        programState.configListWithCount?.list.map(
                          (item: ConfigListType) => item.configId
                        )
                      );
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("common.input.selectAll")}
                </TypographyNew>
              </BoxNew>

              <BoxNew classes="gap-10 dropdown-filter-body" direction="column">
                <BoxNew
                  classes="overflow-scroll"
                  direction="column"
                  alignItems="flex-start"
                  height={240}
                  width={284}
                >
                  {programState.configListWithCount?.list?.map(
                    (item: ConfigListType) => (
                      <BoxNew height={40} minHeight={40}>
                        <CheckboxNew
                          check={tempConfigIdsCheckbox.isSelected(
                            item.configId
                          )}
                          onClick={() => {
                            tempConfigIdsCheckbox.handleClick(item.configId);
                          }}
                          label={t("common.list.numOrder", {
                            round: item.configId,
                          })}
                          key={item.configId}
                        />
                      </BoxNew>
                    )
                  )}
                </BoxNew>
                <BoxNew
                  alignItems="center"
                  classes="dropdown-filter-footer"
                  justifyContent="flex-end"
                  height={56}
                >
                  <BoxNew>
                    <ButtonNew
                      btnStyle={"fill"}
                      label={t("common.button.cancel")}
                      state={"default"}
                      size={"lg"}
                      type={"secondary"}
                      onClick={() => {
                        // setTempCourseName("");
                        setButtonClick(
                          buttonClick > 99999 ? 1 : buttonClick + 1
                        );
                      }}
                    />
                    <ButtonNew
                      btnStyle={"fill"}
                      label={t("common.button.apply")}
                      // state={
                      //   FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                      //     filter.configIds || [],
                      //     tempConfigIdsCheckbox.selected
                      //   )
                      //     ? "default"
                      //     : "disable"
                      // }
                      size={"lg"}
                      classes={"mg ml-8"}
                      type="primary"
                      onClick={() => {
                        setFilter((prev) => ({
                          ...prev,
                          configIds: tempConfigIdsCheckbox.selected as number[],
                        }));
                        setButtonClick(
                          buttonClick > 99999 ? 1 : buttonClick + 1
                        );
                      }}
                    />
                  </BoxNew>
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </DropdownNew>

          {/* userSearchParam  */}
          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={
              t("program.trainingUser") + " · " + t("common.auth.all")
            }
            value={
              filter.userSearchParams === undefined ||
              filter.userSearchParams.length === 0
                ? ""
                : t("program.trainingUser") +
                  ` · ${filter.userSearchParams.length}`
            }
            buttonWidth={320}
            buttonClick={buttonClick}
          >
            <BoxNew direction={"column"} classes="dropdown-filter">
              <BoxNew classes="dropdown-filter-title">
                <TypographyNew
                  type="caption1_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("program.trainingUser")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="dropdown-filter-search">
                <InputField
                  width={288}
                  size={"sm"}
                  value={searchUserName}
                  leadingIcon={
                    <IconsNew variant="line" label="search" size={20} />
                  }
                  placeholder={t("quiz.label.targetHolder")}
                  onChange={(e) => setSearchUserName(e.target.value)}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter") {
                      if (e.target.value === "") return;
                      if (
                        filter.configNames &&
                        filter.configNames.includes(e.target.value)
                      ) {
                        return;
                      }
                      setSearchUserNames((prev) => [...prev, searchUserName]);
                      setSearchUserName("");
                    }
                  }}
                />
              </BoxNew>
              <BoxNew classes="gap-10 dropdown-filter-body">
                {searchUserNames &&
                  searchUserNames.map((item) => (
                    <Chip
                      key={`tempUserName-chip-${item}`}
                      type={"fill"}
                      size={"sm"}
                      showClose={true}
                      text={item}
                      onClick={() =>
                        setSearchUserNames((prev) =>
                          prev.filter((q) => q !== item)
                        )
                      }
                    />
                  ))}
              </BoxNew>
              <BoxNew
                alignItems="center"
                classes="dropdown-filter-footer"
                justifyContent="flex-end"
              >
                <BoxNew>
                  <ButtonNew
                    btnStyle={"fill"}
                    label={t("common.button.cancel")}
                    state={"default"}
                    size={"lg"}
                    type={"secondary"}
                    onClick={() => {
                      setSearchUserName("");
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                  />
                  <ButtonNew
                    btnStyle={"fill"}
                    label={t("common.button.apply")}
                    state={
                      FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                        filter.userSearchParams || [],
                        searchUserNames
                      )
                        ? "default"
                        : "disable"
                    }
                    size={"lg"}
                    classes={"mg ml-8"}
                    type="primary"
                    onClick={() => {
                      if (searchUserName !== "") {
                        setSearchUserNames((prev) => [...prev, searchUserName]);
                        setFilter((prev) => ({
                          ...prev,
                          userSearchParams: [
                            ...searchUserNames,
                            searchUserName,
                          ],
                        }));
                      } else {
                        setFilter((prev) => ({
                          ...prev,
                          userSearchParams: searchUserNames,
                        }));
                      }
                      setSearchUserName("");
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                  />
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </DropdownNew>

          {/* deptName  */}
          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={t("quiz.table.depts") + " · " + t("common.auth.all")}
            value={
              filter.deptNames === undefined || filter.deptNames.length === 0
                ? ""
                : t("quiz.table.depts") + ` · ${filter.deptNames.length}`
            }
            buttonWidth={320}
            buttonClick={buttonClick}
          >
            <BoxNew direction={"column"} classes="dropdown-filter">
              <BoxNew classes="dropdown-filter-title">
                <TypographyNew
                  type="caption1_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.table.depts")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="dropdown-filter-search">
                <InputField
                  width={288}
                  size={"sm"}
                  value={searchDeptName}
                  leadingIcon={
                    <IconsNew variant="line" label="search" size={20} />
                  }
                  placeholder={t("program.msg.programUndefinedNameMsg")}
                  onChange={(e) => setSearchDeptName(e.target.value)}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter") {
                      if (e.target.value === "") return;
                      if (
                        filter.deptNames &&
                        filter.deptNames.includes(e.target.value)
                      ) {
                        return;
                      }
                      setSearchDeptNames((prev) => [...prev, searchDeptName]);
                      setSearchDeptName("");
                    }
                  }}
                />
              </BoxNew>
              <BoxNew classes="gap-10 dropdown-filter-body">
                {searchDeptNames &&
                  searchDeptNames.map((item) => (
                    <Chip
                      key={`tempDeptName-chip-${item}`}
                      type={"fill"}
                      size={"sm"}
                      showClose={true}
                      text={item}
                      onClick={() =>
                        setSearchDeptNames((prev) =>
                          prev.filter((q) => q !== item)
                        )
                      }
                    />
                  ))}
              </BoxNew>
              <BoxNew
                alignItems="center"
                classes="dropdown-filter-footer"
                justifyContent="flex-end"
              >
                <BoxNew>
                  <ButtonNew
                    btnStyle={"fill"}
                    label={t("common.button.cancel")}
                    state={"default"}
                    size={"lg"}
                    type={"secondary"}
                    onClick={() => {
                      setSearchDeptName("");
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                  />
                  <ButtonNew
                    btnStyle={"fill"}
                    label={t("common.button.apply")}
                    state={
                      FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                        filter.deptNames || [],
                        searchDeptNames
                      )
                        ? "default"
                        : "disable"
                    }
                    size={"lg"}
                    classes={"mg ml-8"}
                    type="primary"
                    onClick={() => {
                      if (searchDeptName !== "") {
                        setSearchDeptNames((prev) => [...prev, searchDeptName]);
                        setFilter((prev) => ({
                          ...prev,
                          deptNames: [...searchDeptNames, searchDeptName],
                        }));
                      } else {
                        setFilter((prev) => ({
                          ...prev,
                          deptNames: searchDeptNames,
                        }));
                      }
                      setSearchDeptName("");
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                  />
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </DropdownNew>

          {/* configName */}

          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={t("menu.trainingName") + " · " + t("common.auth.all")}
            value={
              filter.configNames === undefined ||
              filter.configNames.length === 0
                ? ""
                : t("menu.trainingName") + " · " + filter.configNames.length
            }
            buttonWidth={320}
            buttonClick={buttonClick}
          >
            <BoxNew direction={"column"} classes="dropdown-filter">
              <BoxNew classes="dropdown-filter-title">
                <TypographyNew
                  type="caption1_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("menu.trainingName")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="dropdown-filter-search">
                <InputField
                  width={288}
                  size={"sm"}
                  value={searchConfigName}
                  leadingIcon={
                    <IconsNew variant="line" label="search" size={20} />
                  }
                  placeholder={t("program.msg.programUndefinedNameMsg")}
                  onChange={(e) => setSearchConfigName(e.target.value)}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter") {
                      if (e.target.value === "") return;
                      if (
                        filter.configNames &&
                        filter.configNames.includes(e.target.value)
                      ) {
                        return;
                      }
                      setSearchConfigNames((prev) => [
                        ...prev,
                        searchConfigName,
                      ]);
                      setSearchConfigName("");
                    }
                  }}
                />
              </BoxNew>
              <BoxNew classes="gap-10 dropdown-filter-body">
                {searchConfigNames &&
                  searchConfigNames.map((item) => (
                    <Chip
                      key={`searchConfigName-chip-${item}`}
                      type={"fill"}
                      size={"sm"}
                      showClose={true}
                      text={item}
                      onClick={() =>
                        setSearchConfigNames((prev) =>
                          prev.filter((q) => q !== item)
                        )
                      }
                    />
                  ))}
              </BoxNew>
              <BoxNew
                alignItems="center"
                classes="dropdown-filter-footer"
                justifyContent="flex-end"
              >
                <BoxNew>
                  <ButtonNew
                    btnStyle={"fill"}
                    label={t("common.button.cancel")}
                    state={"default"}
                    size={"lg"}
                    type={"secondary"}
                    onClick={() => {
                      setSearchConfigName(filter.configName ?? "");
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                  />
                  <ButtonNew
                    btnStyle={"fill"}
                    label={t("common.button.apply")}
                    state={
                      FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                        filter.configNames || [],
                        searchConfigNames
                      )
                        ? "default"
                        : "disable"
                    }
                    size={"lg"}
                    classes={"mg ml-8"}
                    type="primary"
                    onClick={() => {
                      if (searchConfigName !== "") {
                        setSearchConfigNames((prev) => [
                          ...prev,
                          searchConfigName,
                        ]);
                        setFilter((prev) => ({
                          ...prev,
                          configNames: [...searchConfigNames, searchConfigName],
                        }));
                      } else {
                        setFilter((prev) => ({
                          ...prev,
                          configNames: searchConfigNames,
                        }));
                      }
                      setSearchConfigName("");

                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                  />
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </DropdownNew>

          {/* 발송 일시 */}
          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={t("common.time.sendDate")}
            value={`${
              selectedPeriod.value === "PERIOD_3MONTH"
                ? t("report.stats.reportRecent3Month")
                : selectedPeriod.value === "PERIOD_6MONTH"
                ? t("report.stats.reportRecent6Month")
                : selectedPeriod.value === "PERIOD_1YEAR"
                ? t("report.stats.reportRecent1Year")
                : selectedPeriod.value === "ALL"
                ? t("common.auth.all")
                : `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
            }`}
            defaultValue={"PERIOD_3MONTH"}
            selectValue={selectedPeriod.value}
            showValue
            buttonClick={buttonClick}
            buttonWidth={224}
          >
            <DropdownItemNew
              checked={selectedPeriod.value === "ALL"}
              checkColor={selectedPeriod.value === "ALL"}
              onClick={() => {
                changeDateFilter("ALL");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("common.auth.all")}
              type={"check"}
            />
            <DropdownItemNew
              checked={selectedPeriod.value === "PERIOD_3MONTH"}
              checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
              onClick={() => {
                changeDateFilter("PERIOD_3MONTH");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("report.stats.reportRecent3Month")}
              type={"check"}
            />
            <DropdownItemNew
              checked={selectedPeriod.value === "PERIOD_6MONTH"}
              checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
              onClick={() => {
                changeDateFilter("PERIOD_6MONTH");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("report.stats.reportRecent6Month")}
              type={"check"}
            />
            <DropdownItemNew
              checked={selectedPeriod.value === "PERIOD_1YEAR"}
              checkColor={selectedPeriod.value === "PERIOD_1YEAR"}
              onClick={() => {
                changeDateFilter("PERIOD_1YEAR");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("report.stats.reportRecent1Year")}
              type={"check"}
            />
            <DropdownItemNew
              label=""
              onClick={() => {}}
              state="divider"
              type="default"
            />
            <DropdownItemNew
              checked={selectedPeriod.value === "PERIOD_CUSTOM"}
              checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
              onClick={() => {
                changeDateFilter("PERIOD_CUSTOM");
              }}
              isFullWidth
              label={t("graph.phishingCustomScope")}
              type={"check"}
            />
          </DropdownNew>

          {/* 발송 현황  */}

          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={
              t("program.programSendResult") + " · " + filter.sendResult.text
            }
            value={
              filter.sendResult.id === 0
                ? ""
                : t("program.programSendResult") +
                  " · " +
                  filter.sendResult.text
            }
            buttonClick={buttonClick}
            buttonWidth={224}
            dropdownMaxHeight={276}
          >
            {sendResults &&
              sendResults.map((item) => (
                <DropdownItemNew
                  key={`programSendResult-dropdownItem-${item.id}`}
                  checked={filter.sendResult.id === item.id}
                  checkColor={filter.sendResult.id === item.id}
                  onClick={() => {
                    setFilter({ ...filter, sendResult: item });
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                  isFullWidth
                  label={item.text}
                  type={"check"}
                />
              ))}
          </DropdownNew>

          {/* 탐지 행위  */}
          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={t("program.result") + " · " + t("common.auth.all")}
            value={
              filter.clickTypes?.length === 0
                ? ""
                : t("program.result") + " · " + filter.clickTypes.length
            }
            buttonClick={buttonClick}
            buttonWidth={224}
            dropdownMaxHeight={399}
          >
            <BoxNew direction={"column"} classes="dropdown-filter">
              <BoxNew
                classes="dropdown-filter-title"
                justifyContent="space-between"
                alignItems="center"
              >
                <TypographyNew
                  type="caption1_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("program.result")}
                </TypographyNew>
                <TypographyNew
                  type="caption1_rg"
                  exactColor={getColor("COLOR_TEXT_BRAND")}
                  classes="cursor-pointer"
                  onClick={() => {
                    if (filterTypeCheckbox.selected.length === 5) {
                      filterTypeCheckbox.handleAllClick([]);
                    } else {
                      filterTypeCheckbox.handleAllClick([1, 2, 3, 4, 5]);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("common.input.selectAll")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="gap-10 dropdown-filter-body" direction="column">
                <BoxNew
                  classes="overflow-scroll"
                  direction="column"
                  alignItems="flex-start"
                  width={284}
                >
                  {clickTypes &&
                    clickTypes.map((item) => (
                      <BoxNew height={40} minHeight={40}>
                        <CheckboxNew
                          check={filterTypeCheckbox.isSelected(item.id)}
                          onClick={() => {
                            filterTypeCheckbox.handleClick(item.id);
                          }}
                          label={item.text}
                          key={item.id}
                        />
                      </BoxNew>
                    ))}
                </BoxNew>
              </BoxNew>
              <BoxNew
                alignItems="center"
                classes="dropdown-filter-footer"
                justifyContent="flex-end"
                height={56}
              >
                <BoxNew>
                  <ButtonNew
                    btnStyle={"fill"}
                    label={t("common.button.cancel")}
                    state={"default"}
                    size={"lg"}
                    type={"secondary"}
                    onClick={() => {
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                  />
                  <ButtonNew
                    btnStyle={"fill"}
                    label={t("common.button.apply")}
                    size={"lg"}
                    classes={"mg ml-8"}
                    type="primary"
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        clickTypes: filterTypeCheckbox.selected as number[],
                      }));
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                  />
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </DropdownNew>

          {/* call Result  */}

          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={
              t("program.programCallResult") + " · " + filter.callResult.text
            }
            value={
              filter.callResult.id === 0
                ? ""
                : t("program.programCallResult") +
                  " · " +
                  filter.callResult.text
            }
            buttonClick={buttonClick}
            buttonWidth={224}
            dropdownMaxHeight={276}
          >
            {callResults &&
              callResults.map((item) => (
                <DropdownItemNew
                  key={`callResults-dropdownItem-${item.id}`}
                  checked={filter.callResult.id === item.id}
                  checkColor={filter.callResult.id === item.id}
                  onClick={() => {
                    setFilter({ ...filter, callResult: item });
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                  isFullWidth
                  label={item.text}
                  type={"check"}
                />
              ))}
          </DropdownNew>

          {/* completion Type  */}

          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={
              t("quiz.table.learning") + " · " + filter.completionType.text
            }
            value={
              filter.completionType.id === 0
                ? ""
                : t("quiz.table.learning") + " · " + filter.completionType.text
            }
            buttonClick={buttonClick}
            buttonWidth={224}
            dropdownMaxHeight={276}
          >
            {completionTypes &&
              completionTypes.map((item) => (
                <DropdownItemNew
                  key={`completionTypes-dropdownItem-${item.id}`}
                  checked={filter.completionType.id === item.id}
                  checkColor={filter.completionType.id === item.id}
                  onClick={() => {
                    setFilter({ ...filter, completionType: item });
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                  isFullWidth
                  label={item.text}
                  type={"check"}
                />
              ))}
          </DropdownNew>

          {/* completion Type end */}

          <ButtonNew
            btnStyle={"icon"}
            size={"md"}
            onClick={resetFilter}
            state={checkAnyFilterSelected() ? "default" : "disable"}
            leadingIcon={
              <IconsNew
                variant="line"
                label="redoLeft"
                stroke={getColor("COLOR_ICON_PRIMARY")}
              />
            }
          />
        </BoxNew>
      )}
      {/* filter end */}

      {/* table start */}

      <BoxNew direction="column" classes="pd pt-12 flex-1" width={"100%"}>
        <TableNew
          border="none"
          clickIds={listCheckbox.selected.map((sendHistoryId) =>
            String(sendHistoryId)
          )}
          columnHeight="md"
          totalCount={historyListTotal}
          data={transformSendHistoryList(historyList)}
          endReached={loadMoreItems}
          checkBoxClick={() =>
            listCheckbox.handleAllClick(
              listCheckbox.selected.length === historyList.length
                ? []
                : historyList.map((q) => q.sendHistoryId)
            )
          }
          header={[
            {
              headerText: t("program.configRound"),
              width: "76px",
              ...getOrderRenderProps(ColumnsTypeToOrder.configId),
            },
            { headerText: t("program.trainingUser"), width: "260px" },
            {
              headerText: t("dept.deptLong"),
              width: "120px",
              ...getOrderRenderProps(ColumnsTypeToOrder.deptName),
            },
            {
              headerText: t("program.programName"),
              width: "calc(100% - 1300px)",
            },
            { headerText: t("program.sendRound"), width: "80px" },
            { headerText: t("template.templateSimple"), width: "180px" },
            {
              headerText: t("common.time.sendDate"),
              width: "180px",
              ...getOrderRenderProps(ColumnsTypeToOrder.sendingDate),
            },
            { headerText: t("program.programSendResult"), width: "80px" },
            {
              headerText: (
                <BoxNew>
                  <TypographyNew
                    type="body3_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("program.result")}
                  </TypographyNew>
                  <TooltipNew
                    message={
                      <BoxNew direction="column">
                        <BoxNew>
                          <IconsNew
                            variant="line"
                            label="mail"
                            stroke="#FFFFFF"
                            size={16}
                            classes="mg mr-8"
                          />
                          {t("template.mailOpen")}
                        </BoxNew>
                        <BoxNew>
                          <IconsNew
                            variant="line"
                            label="link"
                            stroke="#FFFFFF"
                            size={16}
                            classes="mg mr-8"
                          />
                          {t("template.linkOpen")}
                        </BoxNew>
                        <BoxNew>
                          <IconsNew
                            variant="line"
                            label="edit"
                            stroke="#FFFFFF"
                            size={16}
                            classes="mg mr-8"
                          />
                          {t("template.personalInfo")}
                        </BoxNew>
                        <BoxNew>
                          <IconsNew
                            variant="line"
                            label="paperClip"
                            stroke="#FFFFFF"
                            size={16}
                            classes="mg mr-8"
                          />
                          {t("dashboard.attachOpen")}
                        </BoxNew>
                      </BoxNew>
                    }
                  >
                    <IconsNew
                      variant="line"
                      label="info"
                      stroke="#717985"
                      classes="mg ml-8"
                      size={16}
                    />
                  </TooltipNew>
                </BoxNew>
              ),
              width: "120px",
            },
            {
              headerText: t("program.programCallResult"),
              width: i18n.language === "ko" ? "80px" : "96px",
            },
            {
              headerText: t("quiz.table.learning"),
              width:
                i18n.language === "ko"
                  ? `calc(80px + ${scrollbarWidth}px)`
                  : `calc(96px + ${scrollbarWidth}px)`,
            },
          ]}
          width={"100%"}
          height={`${
            hasData
              ? tableContainerPosition.height - (245 + (isFilterOpen ? 56 : 0))
              : "0"
          }px`}
        />
        {!hasData &&
          (JSON.stringify(filter) === JSON.stringify(initialFilter) ? (
            <BoxNew
              direction="column"
              alignItems="center"
              style={{ paddingTop: "80px" }}
            >
              {/* <img
                src={require("../../../shared/image/logo/NoTrainResult.png")}
                alt={"noTrain"}
                width={360}
                height={200}
                className="mg mt-48"
                style={{ marginTop: "160px" }}
              /> */}
              <NoMailImage />
              <TypographyNew
                classes="mg mt-20"
                type="h3"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                메일 발송 내역 및 결과 없음
              </TypographyNew>
              <TypographyNew
                classes="mg mt-8"
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                훈련을 활성화하여 대상자에게 훈련 메일을 보내세요.
              </TypographyNew>
            </BoxNew>
          ) : (
            <BoxNew
              direction="column"
              width="100%"
              style={{ paddingTop: "80px" }}
              justifyContent="center"
              alignItems="center"
            >
              <NoSearchResultImage width={200} />
              <TypographyNew
                type="h3"
                classes="mg mt-36"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.label.noSearch")}
              </TypographyNew>
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
                classes="mg mt-8"
              >
                필터 조건에 맞는 메일 발송 내역 및 결과가 없습니다.
              </TypographyNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.resetFilter")}
                state={"default"}
                size={"lg"}
                type={"primary"}
                classes="mg mt-20"
                leadingIcon={
                  <IconsNew variant="line" label="refresh" stroke="white" />
                }
                onClick={resetFilter}
              />
            </BoxNew>
          ))}
      </BoxNew>

      {/* table end */}
      {isResendModalOpen !== -1 && (
        <Modal
          open={isResendModalOpen !== -1}
          title={t("quiz.label.resendMail")}
          onClose={() => setIsResendModalOpen(-1)}
          width={540}
          body={
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.msg.resendConfirm")}
            </TypographyNew>
          }
          footer={
            <BoxNew justifyContent="flex-end" classes="gap-8">
              <ButtonNew
                btnStyle="fill"
                type="secondary"
                label={t("common.button.cancel")}
                onClick={() => setIsResendModalOpen(-1)}
                size="md"
              />
              <ButtonNew
                btnStyle="fill"
                type="primary"
                label={t("quiz.label.resend")}
                onClick={onResendMail}
                size="md"
              />
            </BoxNew>
          }
        />
      )}

      {isResendModalAllOpen && (
        <Modal
          open={isResendModalAllOpen}
          title={"학습 메일 일괄 재전송"}
          onClose={() => setIsResendModalAllOpen(false)}
          width={540}
          body={
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              이미 퀴즈가 발송된 학습 여부가 미진행인 건들에 대해서만
              재전송됩니다. 정말 재전송하시겠습니까?
            </TypographyNew>
          }
          footer={
            <BoxNew justifyContent="flex-end" classes="gap-8">
              <ButtonNew
                btnStyle="fill"
                type="secondary"
                label={t("common.button.cancel")}
                onClick={() => setIsResendModalAllOpen(false)}
                size="md"
              />
              <ButtonNew
                btnStyle="fill"
                type="primary"
                label={t("quiz.label.resend")}
                onClick={onResendMail}
                size="md"
              />
            </BoxNew>
          }
        />
      )}
      {openDateModal && dateModal()}
    </BoxNew>
  );
};

export default ManageTrainResult;
