import {
  Box,
  Button,
  getColor,
  Icons,
  InputField,
  Modal,
  Typography,
} from "fds";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { SimpleTreeNode, UserType } from "../../../types/User";
import TreeSimple from "../../../component/TreeSimple";
import { DeptType } from "../../../types/Dept";
import useCheckboxGroup from "fds/src/hooks/useCheckboxGroup";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import OrganCard from "../../../component/OrganCard";
import NoSelectedUserImage from "../../../shared/image/NoSelectedUserImage";

interface UserAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  userCheckboxGroup: {
    selected: string[];
    handleClick: (id: string) => string[];
    handleAllClick: (allIds: string[]) => void;
    isSelected: (id: string) => boolean;
    initSelected: (initIds: string[]) => void;
  };
  deptCheckboxGroup: {
    selected: string[];
    handleClick: (id: string) => string[];
    handleAllClick: (allIds: string[]) => void;
    isSelected: (id: string) => boolean;
    initSelected: (initIds: string[]) => void;
  };
  organTree: SimpleTreeNode[] | undefined;
  //   changeOrganTree: Dispatch<SetStateAction<SimpleTreeNode[]>>;
}

const UserModal: React.FC<UserAddModalProps> = ({
  isOpen,
  onClose,
  userCheckboxGroup,
  deptCheckboxGroup,
  organTree,
}) => {
  const { t } = useTranslation();
  const { getSelectedOrgan } = useQuizStore();
  const onSubmit = () => {
    userCheckboxGroup.handleAllClick(userCheckboxGroupTemp.selected);
    deptCheckboxGroup.handleAllClick(deptCheckboxGroupTemp.selected);
    onClose();
  };

  const [searchDeptTxt, changeSearchDeptText] = useState("");
  const [filterOrganTree, setFilterOrganTree] = useState<
    SimpleTreeNode[] | undefined
  >();

  const userCheckboxGroupTemp = useCheckboxGroup<string>();
  const deptCheckboxGroupTemp = useCheckboxGroup<string>();
  const [selectedUserInfo, setSelectedUserInfo] = useState<UserType[]>([]);
  const [selectedDeptInfo, setSelectedDeptInfo] = useState<DeptType[]>([]);

  useEffect(() => {
    userCheckboxGroupTemp.handleAllClick(userCheckboxGroup.selected);
    deptCheckboxGroupTemp.handleAllClick(deptCheckboxGroup.selected);
  }, []);

  useEffect(() => {
    organTree && setFilterOrganTree(filterDeptList(organTree));
  }, [searchDeptTxt, organTree]);

  const getOrganInfo = async () => {
    const resp: any = await getSelectedOrgan({
      userIds: userCheckboxGroupTemp.selected,
      deptCodes: deptCheckboxGroupTemp.selected,
    });

    setSelectedDeptInfo(resp.payload.data.deptList ?? []);
    setSelectedUserInfo(resp.payload.data.userList ?? []);
  };

  useEffect(() => {
    if (
      userCheckboxGroupTemp.selected.length > 0 ||
      deptCheckboxGroupTemp.selected.length > 0
    ) {
      getOrganInfo();
    } else {
      setSelectedDeptInfo([]);
      setSelectedUserInfo([]);
    }
  }, [userCheckboxGroupTemp.selected, deptCheckboxGroupTemp.selected]);

  const filterDeptList = (organList: SimpleTreeNode[]) => {
    if (searchDeptTxt === "") return organList;
    if (!organList || organList?.length === 0) return [];
    let remainDeptList = organList?.filter(
      (item) => item?.deptName.includes(searchDeptTxt) && item.type === "dept"
    );
    let returnDeptList = organList?.filter(
      (item) => item?.deptCode === "COMPANY"
    );

    while (remainDeptList?.length > 0 && remainDeptList[0]) {
      let targetDept = remainDeptList[0];
      if (
        targetDept?.parentCode !== "COMPANY" &&
        returnDeptList?.filter(
          (item) => item?.deptCode === targetDept?.parentCode
        ).length === 0 &&
        targetDept
      ) {
        remainDeptList.push(
          organList.filter(
            (item) =>
              item?.deptCode === targetDept?.parentCode && item.type === "dept"
          )[0]
        );
      }
      if (
        returnDeptList.filter((item) => item?.deptCode === targetDept?.deptCode)
          .length === 0
      )
        returnDeptList.push(targetDept);

      remainDeptList.shift();
    }

    return returnDeptList;
  };

  const footer = (
    <Box justifyContent="flex-end">
      <Button
        type="secondary"
        btnStyle="fill"
        onClick={onClose}
        label={t("common.button.cancel")}
      />
      <Button
        type="primary"
        btnStyle="fill"
        classes="mg ml-8"
        onClick={onSubmit}
        label={t("common.button.apply")}
      />
    </Box>
  );
  return (
    <Modal
      open={isOpen}
      title={t("quiz.label.selectOrgChart")}
      width={816}
      height={712}
      onClose={onClose}
      footer={footer}
      isFooterDivider={true}
      isHeaderDivider={true}
      body={
        <Box classes="userSettingModal" direction="row">
          <Box classes="userSettingModal-left" direction="column">
            <InputField
              leadingIcon={<Icons variant="line" label="search" />}
              placeholder={t("dept.msg.undefinedNameMsg")}
              size={"md"}
              value={searchDeptTxt}
              onChange={(e) => changeSearchDeptText(e.target.value)}
              width={"100%"}
              classes="mg mb-12"
            />
            <Box classes="userSettingModal-left-tree">
              {filterOrganTree && (
                <TreeSimple
                  rootCode={"COMPANY"}
                  treeNodes={filterOrganTree}
                  depth={0}
                  checkboxUserGroup={userCheckboxGroupTemp}
                  checkboxDeptGroup={deptCheckboxGroupTemp}
                  checkUserIds={userCheckboxGroupTemp.selected}
                  checkDeptIds={deptCheckboxGroupTemp.selected}
                  isUserCheck
                  isDeptCheck
                  checkType="plus"
                  isOrganModal={searchDeptTxt === "" ? false : true}
                />
              )}
            </Box>
          </Box>
          <Box classes="userSettingModal-right" direction="column">
            <Box classes="userSettingModal-right-top">
              <Typography type="subtitle2">
                {t("program.selectedUser")}
              </Typography>
              <Typography
                classes="mg ml-4"
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_BRAND")}
              >
                {selectedDeptInfo.length + selectedUserInfo.length}
              </Typography>
              <Button
                label="모두 삭제"
                type="primary"
                btnStyle="none"
                size="xsm"
                classes="mg ml-auto"
                textColor={getColor("COLOR_TEXT_BRAND")}
                onClick={() => {
                  deptCheckboxGroupTemp.handleAllClick([]);
                  userCheckboxGroupTemp.handleAllClick([]);
                }}
              />
            </Box>
            <Box classes="userSettingModal-right-list">
              {(selectedDeptInfo && selectedDeptInfo.length > 0) ||
              (selectedUserInfo && selectedUserInfo.length > 0) ? (
                <Box direction="column">
                  {selectedDeptInfo &&
                    selectedDeptInfo.length > 0 &&
                    selectedDeptInfo.map((i) => (
                      <OrganCard
                        userName={i.deptName}
                        userId={i.deptCode}
                        type="DEPT"
                        deleteEvent={() => {
                          deptCheckboxGroupTemp.handleClick(i.deptCode + "");
                        }}
                      />
                    ))}
                  {selectedUserInfo &&
                    selectedUserInfo.length > 0 &&
                    selectedUserInfo.map((i) => (
                      <OrganCard
                        userId={i.userId ?? undefined}
                        userName={i.userName}
                        jobTitle={i.jobTitle ?? undefined}
                        deptName={i.deptName}
                        userEmail={i.userEmail}
                        deleteEvent={() => {
                          userCheckboxGroupTemp.handleClick(i.userId + "");
                        }}
                      />
                    ))}
                </Box>
              ) : (
                <span>
                  <Box width={280} classes="mg mt-144" direction="column">
                    {/* <Box classes="mg mt-40" justifyContent="center">
                      <NoSelectedUserImage />
                    </Box>
                    <Box justifyContent="center">
                      <Typography
                        type="body3_rg"
                        exactColor={getColor("COLOR_TEXT_SECONDARY")}
                      >
                        {t("quiz.msg.addTargetOrgChart")}
                      </Typography>
                    </Box> */}
                  </Box>
                </span>
              )}
            </Box>
          </Box>
        </Box>
      }
    ></Modal>
  );
};

export default UserModal;
