import "./index.scss";
import {
  Box as BoxNew,
  Typography as TypographyNew,
  Avatar as AvatarNew,
  Button as ButtonNew,
  Modal as ModalNew,
  useToast,
  Dropdown,
  DropdownItem,
  getColor,
  CalendarRange,
} from "fds";
import TitlePath from "../../../component/TitlePath";
import useDashboardStore from "../../../redux/dispatcher/useDashboardStore";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TotalType } from "../../Dashboard";
import utilsCommon from "../../../shared/utils/common";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts, { TooltipFormatterContextObject } from "highcharts";
import NoInfo from "../../../component/NoInfo";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { ConfigListType } from "../../../types/Send";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { nanoid } from "@reduxjs/toolkit";
import IconMindSat from "../../../shared/image/icon";
import Divider from "../../../component/Divider";

const ManageTrainStat: React.FC = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { dashboardState, getStatTraining, getStatTopTraining } =
    useDashboardStore();
  const { programState, getProgramList } = useProgramStore();
  const [stats, setStats] = useState<StatTraining[]>([] as StatTraining[]);
  const [statsTop, setStatsTop] = useState<StatTopTraining>();
  const [totalData, setTotalData] = useState<TotalType | null>();
  const [targetConfigId, changeTargetConfigId] = useState(0);
  const [configList, setConfigList] = useState<number[]>([]);
  const [realTrainingFlag, setRealTrainingFlag] = useState<boolean | null>(
    null
  );

  const [selectedPeriod, changeSelectedPeriod] = useState({
    value: "PERIOD_6MONTH",
    start: dayjs()
      .subtract(6, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const [topWidth, setTopWidth] = useState<number>(window.innerWidth - 310);
  useEffect(() => {
    getProgramList({});
    const handleResize = () => {
      setTopWidth(window.innerWidth - 310);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setConfigList(
      programState.configListWithCount?.list?.map(
        (i: ConfigListType) => i.configId
      ) ?? []
    );
  }, [programState.configListWithCount]);

  useEffect(() => {
    if (targetConfigId && targetConfigId > 0) {
      getStatTopTraining({
        configId: targetConfigId,
        intervalTime: utilsCommon.getIntervalTime(),
        realTrainingFlag,
      });
    } else if (targetConfigId === 0) {
      getStatTopTraining({
        configId: 0,
        intervalTime: utilsCommon.getIntervalTime(),
        startDate: Math.trunc(selectedPeriod.start / 1000),
        endDate: Math.trunc(selectedPeriod.end / 1000),
        realTrainingFlag,
      });
    }
  }, [targetConfigId]);

  useEffect(() => {
    setStats(dashboardState.stats);

    if (dashboardState.stats.length > 0) {
      // changeTargetConfigId(dashboardState.stats[0].configId);

      let totalType: TotalType = {
        callAverage: 0,
        callTotal: 0,
        applyAverage: 0,
        applyTotal: 0,
        linkAverage: 0,
        linkTotal: 0,
        infoAverage: 0,
        infoTotal: 0,
        fileAverage: 0,
        fileTotal: 0,
        allFile: 0,
        allLink: 0,
        allInfo: 0,
      };
      let allUser = 0;
      let allSendSuccess = 0;

      dashboardState.stats.forEach((item: StatTraining) => {
        totalType.callTotal += item.callResult;
        totalType.applyTotal += item.applyUser;
        totalType.linkTotal += item.clickLink;
        totalType.infoTotal += item.clickInfo;
        totalType.fileTotal += item.clickFile;
        allUser += item.sendUserCount;
        allSendSuccess += item.sendSuccess;
        totalType.allLink += item.totalClickLink;
        totalType.allFile += item.totalClickFile;
      });

      totalType.callAverage =
        allSendSuccess === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.callTotal / allSendSuccess) * 100
            );
      totalType.applyAverage =
        allUser === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.applyTotal / allUser) * 100
            );
      totalType.linkAverage =
        totalType.allLink === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.linkTotal / totalType.allLink) * 100
            );

      totalType.infoAverage =
        allSendSuccess === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.infoTotal / allSendSuccess) * 100
            );
      totalType.fileAverage =
        totalType.allFile === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.fileTotal / totalType.allFile) * 100
            );

      setTotalData(totalType);
    } else {
      setTotalData(null);
    }
  }, [dashboardState.stats]);

  useEffect(() => {
    setStatsTop(dashboardState.statsTop);
  }, [dashboardState.statsTop]);

  const [lineOptions, setLineOptions] = useState<any>([]);
  const [chartKey, setChartKey] = useState(0);

  useEffect(() => {
    var nowConfig = 0;
    setLineOptions({
      chart: {
        type: "spline",
        width: topWidth,
        height: 280,
        // style: { fontFamily: "Noto Sans KR" },
      },
      title: {
        text: "",
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        margin: 30,
        itemStyle: {
          fontSize: "12px",
        },
      },
      xAxis: {
        categories: stats
          .map((item) => t("report.reportConfigId", { round: item.configId }))
          .reverse(),
        title: {
          text: t("program.configRound"),
          align: "high",
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      yAxis: {
        max: 100,
        title: {
          text: "%",
          align: "high",
          rotation: 0,
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: false,
          point: {
            events: {
              click: function (this: Highcharts.Point) {
                const category: string = this.category as string; // 클릭된 포인트의 카테고리 값 가져오기
                if (
                  nowConfig ===
                  parseInt(category.replace(t("program.configRound"), ""))
                ) {
                  nowConfig = 0;
                  changeTargetConfigId(0);
                  const chart = this.series.chart;
                  chart.series.forEach((series) => {
                    series.points.forEach((point) => {
                      if (point.category === category) {
                        point.update({
                          selected: false,
                        });
                      }
                    });
                  });
                } else {
                  nowConfig = parseInt(
                    category.replace(t("program.configRound"), "")
                  );
                  changeTargetConfigId(
                    parseInt(category.replace(t("program.configRound"), ""))
                  );
                  const chart = this.series.chart;
                  chart.series.forEach((series) => {
                    series.points.forEach((point) => {
                      if (point.category === category) {
                        point.update({
                          selected: true,
                        });
                      } else if (point.selected) {
                        point.update({
                          selected: false,
                        });
                      }
                    });
                  });
                }
              },
            },
          },
        },
      },
      tooltip: {
        shared: true,
        style: {
          fontSize: "12px",
        },
        pointFormatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`;
        },
      },
      credits: { enabled: false },
      series: [
        {
          name: t("dashboard.report"),
          data: stats
            .map((item) =>
              item.callResult === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.callResult * 100) / item.sendSuccess
                  )
            )
            .reverse(),

          color: "#3182f6",
        },
        {
          name: t("dashboard.clickUser"),
          data: stats
            .map((item) =>
              item.applyUser === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.applyUser * 100) / item.sendUserCount
                  )
            )
            .reverse(),

          color: "#05c072",
        },
        {
          name: t("template.linkOpen"),
          data: stats
            .map((item) =>
              item.totalClickLink === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.clickLink * 100) / item.totalClickLink
                  )
            )

            .reverse(),

          color: "#fcaf5c",
        },
        {
          name: t("template.attachOpen"),
          data: stats
            .map((item) =>
              item.totalClickFile === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.clickFile * 100) / item.totalClickFile
                  )
            )
            .reverse(),
          color: "#9b77fc",
        },
      ],
    });
  }, [stats, i18n.language, topWidth]);

  const changeDateFilter = (type: string) => {
    if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 1,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const changeTrainingFlag = (type: string) => {
    if (type === "mock") {
      setRealTrainingFlag(false);
    } else if (type === "real") {
      setRealTrainingFlag(true);
    } else {
      setRealTrainingFlag(null);
    }
  };

  const [openDateModal, toggleDateModal] = useState(false);

  useEffect(() => {
    getStatTraining({
      startDate: Math.trunc(selectedPeriod.start / 1000),
      endDate: Math.trunc(selectedPeriod.end / 1000),
      intervalTime: utilsCommon.getIntervalTime(),
      realTrainingFlag,
    });
    getStatTopTraining({
      configId: 0,
      intervalTime: utilsCommon.getIntervalTime(),
      startDate: Math.trunc(selectedPeriod.start / 1000),
      endDate: Math.trunc(selectedPeriod.end / 1000),
      realTrainingFlag,
    });
  }, [selectedPeriod, realTrainingFlag]);

  const dateModal = () => {
    return (
      <ModalNew
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  const [buttonClick, setButtonClick] = useState(0);

  const filters = (
    <BoxNew
      classes="mg mb-24 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.period")}
        value={
          selectedPeriod.value === "PERIOD_3MONTH"
            ? t("report.stats.reportRecent3Month")
            : selectedPeriod.value === "PERIOD_6MONTH"
            ? t("report.stats.reportRecent6Month")
            : selectedPeriod.value === "PERIOD_1MONTH"
            ? t("report.stats.reportRecent1Month")
            : selectedPeriod.value === "ALL"
            ? ""
            : `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
        }
        defaultValue={"PERIOD_6MONTH"}
        showValue
        selectValue={selectedPeriod.value}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_1MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_1MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_1MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_3MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_3MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_6MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_6MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />

        <DropdownItem
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItem
          checked={selectedPeriod.value === "PERIOD_CUSTOM"}
          checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter("PERIOD_CUSTOM");
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </Dropdown>

      <Dropdown
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("program.trainingType") + " · " + t("quiz.label.all")}
        value={
          realTrainingFlag === null
            ? ""
            : realTrainingFlag
            ? t("program.trainingType") + " · " + t("program.realTrainingLabel")
            : t("program.trainingType") + " · " + t("program.mockTrainingLabel")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItem
          checked={realTrainingFlag === null}
          checkColor={realTrainingFlag === null}
          onClick={() => {
            changeTrainingFlag("all");
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItem
          checked={realTrainingFlag === true}
          checkColor={realTrainingFlag === true}
          onClick={() => {
            changeTrainingFlag("real");
          }}
          isFullWidth
          label={t("program.realTrainingLabel")}
          type={"check"}
        />
        <DropdownItem
          checked={realTrainingFlag === false}
          checkColor={realTrainingFlag === false}
          onClick={() => {
            changeTrainingFlag("mock");
          }}
          isFullWidth
          label={t("program.mockTrainingLabel")}
          type={"check"}
        />
      </Dropdown>
    </BoxNew>
  );

  return (
    <BoxNew classes="main manageTrainStat">
      {openDateModal && dateModal()}
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
        id={"manageTrainStat"}
      >
        <BoxNew direction="column">
          <TitlePath
            path={[t("menu.trainingManagement"), t("menu.trainingGraph")]}
          />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.trainingGraph")}
          </TypographyNew>
        </BoxNew>
      </BoxNew>
      {configList.length === 0 ? (
        <BoxNew direction="column" alignItems="center">
          <img
            src={require("../../../shared/image/logo/NoMain.png")}
            alt={"noTrain"}
            width={360}
            height={200}
            className="mg mt-48"
            style={{ marginTop: "160px" }}
          />
          <TypographyNew
            classes="mg mt-24"
            type="h2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.msg.noStatsMsg")}
          </TypographyNew>
        </BoxNew>
      ) : (
        <BoxNew>
          <BoxNew width={"100%"} direction="column">
            {filters}
            <BoxNew direction="column">
              {/* 최근 10회 훈련 평균 */}
              <BoxNew direction="column" width={"100%"}>
                {totalData && (
                  <BoxNew justifyContent="space-between" width={"100%"}>
                    <BoxNew
                      classes="dashboard__box blue__box mg mr-20"
                      width={"25%"}
                      direction="column"
                    >
                      <BoxNew justifyContent="space-between" width={"100%"}>
                        <BoxNew alignItems="center">
                          <TypographyNew type={"h1"} exactColor="white">
                            {totalData.callAverage}
                          </TypographyNew>
                          <TypographyNew
                            type={"h3"}
                            exactColor="white"
                            classes="mg ml-4"
                          >
                            %
                          </TypographyNew>
                        </BoxNew>
                        <IconMindSat
                          variant="document"
                          label="report"
                          size={40}
                          stroke={"#3E6AC8"}
                          strokeBg={"#AFC8FA"}
                        />
                      </BoxNew>
                      <BoxNew justifyContent="flex-start" width={"100%"}>
                        <TypographyNew type="body1_rg" exactColor="white">
                          {t("dashboard.report")}
                        </TypographyNew>
                      </BoxNew>
                    </BoxNew>

                    <BoxNew
                      classes="dashboard__box green__box mg mr-20"
                      width={"25%"}
                      direction="column"
                    >
                      <BoxNew justifyContent="space-between" width={"100%"}>
                        <BoxNew alignItems="center">
                          <TypographyNew type={"h1"} exactColor="white">
                            {totalData.applyAverage}
                          </TypographyNew>
                          <TypographyNew
                            type={"h3"}
                            exactColor="white"
                            classes="mg ml-4"
                          >
                            %
                          </TypographyNew>
                        </BoxNew>
                        <IconMindSat
                          variant="document"
                          label="privacy"
                          size={40}
                          stroke={"#1BA45D"}
                          strokeBg={"#9BE0BC"}
                        />
                      </BoxNew>
                      <BoxNew justifyContent="flex-start" width={"100%"}>
                        <TypographyNew type="body1_rg" exactColor="white">
                          {t("dashboard.clickUser")}
                        </TypographyNew>
                      </BoxNew>
                    </BoxNew>

                    <BoxNew
                      classes="dashboard__box yellow__box mg mr-20"
                      width={"25%"}
                      direction="column"
                    >
                      <BoxNew justifyContent="space-between" width={"100%"}>
                        <BoxNew alignItems="center">
                          <TypographyNew type={"h1"} exactColor="white">
                            {totalData.linkAverage}
                          </TypographyNew>
                          <TypographyNew
                            type={"h3"}
                            exactColor="white"
                            classes="mg ml-4"
                          >
                            %
                          </TypographyNew>
                          <TypographyNew
                            type={"h2"}
                            exactColor="white"
                            classes="mg ml-4"
                          >
                            /
                          </TypographyNew>
                          <TypographyNew
                            type={"h1"}
                            exactColor="white"
                            classes="mg ml-4"
                          >
                            {totalData.infoTotal}
                          </TypographyNew>
                          <TypographyNew
                            type={"h3"}
                            exactColor="white"
                            classes="mg ml-4"
                          >
                            {t("program.programDataUnit")}
                          </TypographyNew>
                        </BoxNew>
                        <IconMindSat
                          variant="document"
                          label="linkClick"
                          size={40}
                          stroke={"#C48D35"}
                          strokeBg={"#FBD8A1"}
                        />
                      </BoxNew>
                      <BoxNew justifyContent="flex-start" width={"100%"}>
                        <TypographyNew type="body1_rg" exactColor="white">
                          {`${t("dashboard.linkOpen")} / ${t(
                            "template.personalInfo"
                          )}`}
                        </TypographyNew>
                      </BoxNew>
                    </BoxNew>

                    <BoxNew
                      classes="dashboard__box purple__box mg mr-20"
                      width={"25%"}
                      direction="column"
                    >
                      <BoxNew justifyContent="space-between" width={"100%"}>
                        <BoxNew alignItems="center">
                          <TypographyNew type={"h1"} exactColor="white">
                            {totalData.fileAverage}
                          </TypographyNew>
                          <TypographyNew
                            type={"h3"}
                            exactColor="white"
                            classes="mg ml-4"
                          >
                            %
                          </TypographyNew>
                        </BoxNew>
                        <IconMindSat
                          variant="document"
                          label="attachment"
                          size={40}
                          stroke={"#725FEB"}
                          strokeBg={"#CBB9FF"}
                        />
                      </BoxNew>
                      <BoxNew justifyContent="flex-start" width={"100%"}>
                        <TypographyNew type="body1_rg" exactColor="white">
                          {t("dashboard.attachOpen")}
                        </TypographyNew>
                      </BoxNew>
                    </BoxNew>
                  </BoxNew>
                )}
              </BoxNew>

              {/* 훈련 추이 */}
              <BoxNew direction="column" width={"100%"} classes="mg mt-40">
                <BoxNew alignItems="flex-end" justifyContent="space-between">
                  <TypographyNew type="subtitle1">
                    {t("graph.result")}
                  </TypographyNew>
                </BoxNew>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={lineOptions}
                  key={chartKey}
                />
              </BoxNew>

              {/* TopN */}
              <BoxNew classes="mg mt-40 mb-40" width={"100%"}>
                <BoxNew direction="column" width={"33%"}>
                  <BoxNew alignItems="flex-end">
                    <TypographyNew type="subtitle1">
                      {targetConfigId
                        ? t("report.reportConfigId", {
                            round: targetConfigId,
                          })
                        : t("common.auth.all")}{" "}
                      {t("dashboard.clickUserTopTen")}
                    </TypographyNew>
                  </BoxNew>
                  <BoxNew classes="mg mt-16" direction="column">
                    {statsTop?.clickedUser &&
                    statsTop?.clickedUser.length > 0 ? (
                      statsTop?.clickedUser?.map((item, index) => (
                        <BoxNew
                          justifyContent="space-between"
                          alignItems="center"
                          height={52}
                          classes="dashboard_toplist"
                          onClick={() => {
                            navigate(`/manageTrain/result`);
                            window.sessionStorage.setItem(
                              "resultFilter",
                              `configId=${targetConfigId}&userName=${item.name}`
                            );
                          }}
                        >
                          <BoxNew alignItems="center">
                            <TypographyNew width="28px" type="body2_md">
                              {(index < 9 ? "0" : "") + (index + 1)}
                            </TypographyNew>
                            <AvatarNew
                              name={item.name}
                              uniqueCode={item.email}
                            />
                            <TypographyNew classes="mg ml-8" type="body2_rg">
                              {item.name}
                            </TypographyNew>
                            <TypographyNew
                              ellipsis
                              classes="mg ml-4"
                              type="body2_rg"
                            >
                              ({item.email})
                            </TypographyNew>
                          </BoxNew>
                          <TypographyNew type="body2_rg">
                            {item.count.toLocaleString()}{" "}
                            {t("program.programDataUnit")}
                          </TypographyNew>
                        </BoxNew>
                      ))
                    ) : (
                      <NoInfo marginTop="72px" />
                    )}
                  </BoxNew>
                </BoxNew>
                <Divider direction="col" classes="mg ml-32 mr-32" />
                <BoxNew direction="column" width={"33%"}>
                  <BoxNew alignItems="flex-end">
                    <TypographyNew type="subtitle1">
                      {targetConfigId
                        ? t("report.reportConfigId", {
                            round: targetConfigId,
                          })
                        : t("common.auth.all")}{" "}
                      {t("dashboard.noReportUserTopTen")}
                    </TypographyNew>
                  </BoxNew>
                  <BoxNew classes="mg mt-16" direction="column">
                    {statsTop?.nothingUser &&
                    statsTop?.nothingUser.length > 0 ? (
                      statsTop?.nothingUser?.map((item, index) => (
                        <BoxNew
                          justifyContent="space-between"
                          height={52}
                          alignItems="center"
                          classes="dashboard_toplist"
                          onClick={() => {
                            navigate(`/manageTrain/result`);
                            window.sessionStorage.setItem(
                              "resultFilter",
                              `configId=${targetConfigId}&userName=${item.name}&callResult`
                            );
                          }}
                        >
                          <BoxNew alignItems="center">
                            <TypographyNew width="28px" type="body2_md">
                              {(index < 9 ? "0" : "") + (index + 1)}
                            </TypographyNew>
                            <AvatarNew
                              name={item.name}
                              uniqueCode={item.email}
                            />

                            <TypographyNew classes="mg ml-8" type="body2_rg">
                              {item.name}
                            </TypographyNew>
                            <TypographyNew
                              ellipsis
                              classes="mg ml-4"
                              type="body2_rg"
                            >
                              ({item.email})
                            </TypographyNew>
                          </BoxNew>
                          <TypographyNew type="body2_rg">
                            {item.count.toLocaleString()}{" "}
                            {t("program.programDataUnit")}
                          </TypographyNew>
                        </BoxNew>
                      ))
                    ) : (
                      <NoInfo marginTop="72px" />
                    )}
                  </BoxNew>
                </BoxNew>
                <Divider direction="col" classes="mg ml-32 mr-32" />
                <BoxNew direction="column" width={"33%"}>
                  <BoxNew alignItems="flex-end">
                    <TypographyNew type="subtitle1">
                      {targetConfigId
                        ? t("report.reportConfigId", {
                            round: targetConfigId,
                          })
                        : t("common.auth.all")}{" "}
                      {t("dashboard.clickScenarioTopTen")}
                    </TypographyNew>
                  </BoxNew>
                  <BoxNew classes="mg mt-16" direction="column">
                    {statsTop?.scenario && statsTop?.scenario.length > 0 ? (
                      statsTop?.scenario.map((item, index) => (
                        <BoxNew
                          justifyContent="space-between"
                          alignItems="center"
                          height={52}
                          classes="dashboard_toplist"
                          onClick={() =>
                            navigate(
                              `${process.env.PUBLIC_URL}/manageTrain/program/detail?config_id=${item.id}`
                            )
                          }
                        >
                          <BoxNew alignItems="center" height="100%">
                            <TypographyNew width="28px" type="body2_md">
                              {(index < 9 ? "0" : "") + (index + 1)}
                            </TypographyNew>
                            <TypographyNew
                              ellipsis
                              classes="mg ml-8"
                              type="body2_rg"
                            >
                              {item.name}
                            </TypographyNew>
                          </BoxNew>
                          <TypographyNew type="body2_rg">
                            {item.count.toLocaleString()}{" "}
                            {t("program.programDataUnit")}
                          </TypographyNew>
                        </BoxNew>
                      ))
                    ) : (
                      <NoInfo marginTop="72px" />
                    )}
                  </BoxNew>
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </BoxNew>
        </BoxNew>
      )}
    </BoxNew>
  );
};

export default ManageTrainStat;
