import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Modal as ModalNew,
  Icons as IconsNew,
  Dropdown as DropdownNew,
  DropdownItem as DropdownItemNew,
  Chip,
  InputField,
  Button as ButtonNew,
  CalendarRange,
  Tooltip as ToolTipNew,
  Tag as TagNew,
  Table as TableNew,
  DropdownBody as DropdownBodyNew,
  Toggle,
  useToast,
  Tooltip,
} from "fds";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { QuizCourseDTO, QuizCourseReqDTO } from "../../../types/Quiz";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import utilsCommon from "../../../shared/utils/common";
import dayjs from "dayjs";
import { nanoid } from "@reduxjs/toolkit";
import NoDashboardImage from "../../../shared/image/NoDashboardImage";
import { Icons as IconsNEW } from "fds";
import { FilterBtnActivityCheckUtils } from "../../../shared/utils/FilterBtnActivityCheckUtils";
import { useElementPosition } from "../../../hooks/useElementPosition";
import useTableHoverBoxClick from "../../../hooks/useTableHoverBoxClick";

import "../../../shared/style/filters.scss";
import HoverClickBox from "../../../component/Table/HoverClickBox";
import { NavigateStateKeys } from "../../../hooks/NavigateStateKeys";
import useNavigateState from "../../../hooks/useNavigateState";
import { useTableOrder } from "../../../hooks/useTableOrder";

enum ColumnsTypeToOrder {
  courseId = "courseId",
  courseName = "courseName",
}

const QuizCourse: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const { quizState, getQuizCourses, updateQuizCourse, deleteQuizCourse } =
    useQuizStore();

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const initialSelectedPeriod = {
    value: "ALL",
    start: new Date().valueOf(),
    end: new Date().valueOf(),
  };
  const [selectedPeriod, changeSelectedPeriod] = useState(
    initialSelectedPeriod
  );

  const changeDateFilter = (type: string) => {
    if (type === "ALL") {
      changeSelectedPeriod({
        value: type,
        start: new Date().valueOf(),
        end: new Date().valueOf(),
      });
    } else if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 12,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const [courseList, setCourseList] = useState<QuizCourseDTO[]>([]);
  const [courseIdList, setCourseIdList] = useState<number[]>();
  const [totalCourseCount, setTotalCourseCount] = useState<number>(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const [buttonClick, setButtonClick] = useState(0);

  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] =
    useState<boolean>(false);

  const [filterData, setFilterData] = useState<QuizCourseReqDTO>(
    {} as QuizCourseReqDTO
  );

  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setFilterData({
          ...filterData,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        });
    },
  });

  const resetFilter = () => {
    setFilterData({} as QuizCourseReqDTO);
    setProgressStatus(0);
    changeSelectedPeriod(initialSelectedPeriod);
  };

  const [progressStatus, setProgressStatus] = useState<number>(0);
  const [tempCourseName, setTempCourseName] = useState<string>("");
  const [tempCourseNames, setTempCourseNames] = useState<string[]>([]);

  const { navigateState } = useNavigateState(
    NavigateStateKeys.fromQuizCourseCreate
  );

  const checkboxGroup = useCheckboxGroup();

  const filterProgress = (list: any) => {
    let tempList = list.filter((item: QuizCourseDTO) => {
      let startDate =
        (typeof item.sendStartDate === "string"
          ? parseInt(item.sendStartDate)
          : item.sendStartDate ?? 0) * 1000;
      let endDate =
        (typeof item.sendEndDate === "string"
          ? parseInt(item.sendEndDate)
          : item.sendEndDate ?? 0) * 1000;
      switch (progressStatus) {
        case 1:
          return startDate > dayjs().valueOf();
        case 2:
          return (
            startDate <= dayjs().valueOf() &&
            endDate >= dayjs().valueOf() &&
            item.activateFlag
          );
        case 3:
          return endDate < dayjs().valueOf();
        case 4:
          return (
            startDate <= dayjs().valueOf() &&
            endDate >= dayjs().valueOf() &&
            !item.activateFlag
          );
        default:
          return true;
      }
    });
    setCourseList(tempList);
    setTotalCourseCount(tempList.length);
  };

  const makeCourseIdList = async () => {
    const resp: any = await getQuizCourses({});
    if (resp.payload && resp.payload.code === "SUCCESS") {
      const courseList = resp.payload.data.list || [];
      setCourseIdList(courseList.map((c: any) => c.courseId));
    }
  };

  const getQuizCourseList = async () => {
    let resp: any;
    if (selectedPeriod.value === "ALL") {
      resp = await getQuizCourses({
        ...filterData,
      });
    } else {
      resp = await getQuizCourses({
        ...filterData,
        sendStartDate: selectedPeriod.start / 1000,
        sendEndDate: selectedPeriod.end / 1000,
      });
    }

    if (resp.payload && resp.payload.code === "SUCCESS") {
      if (progressStatus !== 0) {
        filterProgress(resp.payload.data.list);
      }
    }
  };
  useEffect(() => {
    makeCourseIdList();
  }, []);

  useEffect(() => {
    getQuizCourseList();
  }, [progressStatus]);

  useEffect(() => {
    if (filterData) {
      getQuizCourseList();
    }
  }, [filterData, selectedPeriod]);

  useEffect(() => {
    if (quizState.quizCourseList.list) {
      setCourseList(quizState.quizCourseList.list);
      setTotalCourseCount(quizState.quizCourseList.total);
    }
  }, [quizState.quizCourseList]);

  const refreshList = () => {
    getQuizCourses({});
  };

  const updateActivate = async () => {
    //update로 활성화
    const resp: any = await updateQuizCourse({
      courseId: selectedCourses[0],
      activateFlag: true,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("quiz.msg.courseActivateSuccess"), "success");
      refreshList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programActivateFailMsg"),
        "error"
      );
    }
    setIsActivateModalOpen(false);
  };

  const updateDectivate = async () => {
    const resp: any = await updateQuizCourse({
      courseId: selectedCourses[0],
      activateFlag: false,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("quiz.msg.courseDeactivateSuccess"),
        "success"
      );
      refreshList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeactivateFailMsg"),
        "error"
      );
    }
    setIsDeactivateModalOpen(false);
  };

  const handleDeleteCourse = async () => {
    const resp: any = await deleteQuizCourse({
      courseId: selectedCourses[0],
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("quiz.msg.deleteQuizCourseSuccess"),
        "success"
      );
      refreshList();
    } else {
      toast.toastMsg(nanoid(), t("quiz.msg.deleteQuizCourseFail"), "error");
    }
    checkboxGroup.handleAllClick([]);
    setIsDeleteModalOpen(false);
  };

  const header = (
    <BoxNew
      classes="mg mt-32"
      justifyContent="space-between"
      width={"100%"}
      height={32}
    >
      <BoxNew alignItems="center">
        <TypographyNew
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("quiz.label.courses")}
        </TypographyNew>
        <TypographyNew
          classes="mg ml-8"
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
        >
          {totalCourseCount}
        </TypographyNew>
      </BoxNew>
      <BoxNew>
        <BoxNew>
          <ButtonNew
            btnStyle={"link"}
            size={"lg"}
            classes="mg mr-4"
            state={checkboxGroup.selected.length > 0 ? "default" : "disable"}
            leadingIcon={
              <IconsNew
                variant="line"
                label="delete"
                stroke={getColor("COLOR_ICON_PRIMARY")}
              />
            }
            onClick={() => setIsDeleteModalOpen(true)}
            label={t("common.button.delete")}
          />
        </BoxNew>
        <ButtonNew
          leadingIcon={
            <IconsNew
              variant="line"
              label="filter"
              stroke={getColor("COLOR_ICON_PRIMARY")}
              size={20}
            />
          }
          btnStyle={"link"}
          type={"primary"}
          onClick={() => setIsFilterOpen((prev) => !prev)}
          size={"lg"}
          label={t("common.list.filter")}
        />
      </BoxNew>
    </BoxNew>
  );

  const filters = (
    <BoxNew
      classes="pd pt-12 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      {/* courseId  */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.session") + " · " + t("quiz.label.all")}
        value={
          filterData.courseId === undefined
            ? ""
            : t("quiz.table.session") +
              " · " +
              t("common.list.numOrder", { round: filterData.courseId })
        }
        buttonClick={buttonClick}
        buttonWidth={224}
        dropdownMaxHeight={276}
      >
        <DropdownItemNew
          checked={filterData.courseId === undefined}
          checkColor={filterData.courseId === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, courseId: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        {courseIdList &&
          courseIdList.map((c: number) => (
            <DropdownItemNew
              checked={filterData.courseId === c}
              checkColor={filterData.courseId === c}
              onClick={() => {
                setFilterData((prev) => ({ ...prev, courseId: c }));
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("common.list.numOrder", { round: c })}
              type={"check"}
            />
          ))}
      </DropdownNew>
      {/* status  */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.status") + " · " + t("quiz.label.all")}
        value={
          progressStatus > 0
            ? progressStatus === 1
              ? t("quiz.table.status") + " · " + t("program.filter.beforeStart")
              : progressStatus === 2
              ? t("quiz.table.status") + " · " + t("program.filter.onProgress")
              : progressStatus === 3
              ? t("quiz.table.status") + " · " + t("program.filter.finished")
              : t("quiz.table.status") + " · " + t("program.filter.stop")
            : ""
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={progressStatus === 0}
          checkColor={progressStatus === 0}
          onClick={() => {
            setProgressStatus(0);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItemNew
          checked={progressStatus === 1}
          checkColor={progressStatus === 1}
          onClick={() => {
            setProgressStatus(1);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.filter.beforeStart")}
          type={"check"}
        />
        <DropdownItemNew
          checked={progressStatus === 2}
          checkColor={progressStatus === 2}
          onClick={() => {
            setProgressStatus(2);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.filter.onProgress")}
          type={"check"}
        />
        <DropdownItemNew
          checked={progressStatus === 3}
          checkColor={progressStatus === 3}
          onClick={() => {
            setProgressStatus(3);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.filter.finished")}
          type={"check"}
        />
        <DropdownItemNew
          checked={progressStatus === 4}
          checkColor={progressStatus === 4}
          onClick={() => {
            setProgressStatus(4);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("program.filter.stop")}
          type={"check"}
        />
      </DropdownNew>
      {/* courseName  */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.courseTitle") + " · " + t("quiz.label.all")}
        value={
          filterData.courseNames === undefined ||
          filterData.courseNames.length === 0
            ? ""
            : t("quiz.table.courseTitle") +
              " · " +
              filterData.courseNames.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.table.courseTitle")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempCourseName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("quiz.label.courseTitleHolder")}
              onChange={(e: any) => setTempCourseName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (
                    tempCourseNames &&
                    tempCourseNames.includes(e.target.value)
                  ) {
                    return;
                  }
                  setTempCourseNames((prev) => [...prev, tempCourseName]);
                  setTempCourseName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempCourseNames &&
              tempCourseNames.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempCourseNames((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempCourseName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filterData.courseNames || [],
                    tempCourseNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempCourseName !== "") {
                    setTempCourseNames((prev) => [...prev, tempCourseName]);
                    setFilterData((prev) => ({
                      ...prev,
                      courseNames: [...tempCourseNames, tempCourseName],
                    }));
                  } else {
                    setFilterData((prev) => ({
                      ...prev,
                      courseNames: tempCourseNames,
                    }));
                  }
                  setTempCourseName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>
      {/* period  */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.label.period1") + " · " + t("common.auth.all")}
        value={`${
          selectedPeriod.value === "PERIOD_3MONTH"
            ? t("quiz.label.period1") +
              " · " +
              t("report.stats.reportRecent3Month")
            : selectedPeriod.value === "PERIOD_6MONTH"
            ? t("quiz.label.period1") +
              " · " +
              t("report.stats.reportRecent6Month")
            : selectedPeriod.value === "PERIOD_1YEAR"
            ? t("quiz.label.period1") +
              " · " +
              t("report.stats.reportRecent1Year")
            : selectedPeriod.value === "ALL"
            ? ""
            : t("quiz.label.period1") +
              " · " +
              `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
        }`}
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={selectedPeriod.value === "ALL"}
          checkColor={selectedPeriod.value === "ALL"}
          onClick={() => {
            changeDateFilter("ALL");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_3MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_3MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_6MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_6MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_1YEAR"}
          checkColor={selectedPeriod.value === "PERIOD_1YEAR"}
          onClick={() => {
            changeDateFilter("PERIOD_1YEAR");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItemNew
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_CUSTOM"}
          checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter("PERIOD_CUSTOM");
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </DropdownNew>
      {/* reset filter */}
      <ButtonNew
        btnStyle={"icon"}
        state={
          FilterBtnActivityCheckUtils.checkIfResetFilterBtnActive(filterData) ||
          progressStatus !== 0 ||
          selectedPeriod.value !== "ALL"
            ? "default"
            : "disable"
        }
        size={"md"}
        onClick={resetFilter}
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
      />
    </BoxNew>
  );

  const dateModal = () => {
    return (
      <ModalNew
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  const {
    tableRef,
    hoverBoxClickedRow,
    hoverBoxNewCoord,
    onOutsideClick,
    handleHoverBoxClick,
  } = useTableHoverBoxClick<QuizCourseDTO>({
    width: 116,
    getDynamicHeight: (row) =>
      row?.userCount &&
      row?.sendMailCount &&
      row.sendCount === row?.userCount * row?.sendMailCount
        ? 147
        : 107,
  });

  function checkIfCourseCompleted(course: QuizCourseDTO) {
    return (
      (course.userCount &&
        course.sendMailCount &&
        course.sendCount === course.userCount * course.sendMailCount) ||
      dayjs(course.sendEndDate).valueOf() * 1000 < dayjs().valueOf()
    );
  }

  function transformQuizList(courseList: QuizCourseDTO[]) {
    const courseListTransformed = [];
    for (let i = 0; i < courseList.length; i++) {
      const c = courseList[i];
      const padding = <BoxNew width={16} />;
      const dataSession = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {c.courseId}
        </TypographyNew>
      );
      const dataCourseTitle = (
        <Tooltip message={c.courseName}>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {c.courseName}
          </TypographyNew>
        </Tooltip>
      );
      const dataPeriod = (
        <TypographyNew
          ellipsis
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="tnum"
        >
          {utilsCommon.getFullDateStr(c.sendStartDate)}&nbsp;~&nbsp;
          {utilsCommon.getFullDateStr(c.sendEndDate)}
        </TypographyNew>
      );
      const dataCompletions = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {c.sendCount === 0
            ? c.startedFlag ||
              (c.sendStartDate &&
                dayjs(c.sendStartDate).valueOf() * 1000 < dayjs().valueOf())
              ? "0%"
              : t("program.programSendWait")
            : c.sendCount && c.userCount && c.sendMailCount
            ? `${(
                (c.sendCount / (c.userCount * c.sendMailCount)) *
                100
              ).toFixed(0)} %`
            : t("program.programSendWait")}
        </TypographyNew>
      );

      const dataTargets = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {c.userCount} {t("program.programPeopleUnit")}
        </TypographyNew>
      );
      const dataEmails = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {c.sendMailCount} {t("program.programDataUnit")}
        </TypographyNew>
      );
      const dataStatus = checkIfCourseCompleted(c) ? (
        <Toggle checked={false} disabled={true} />
      ) : (
        <ToolTipNew
          message={
            c.activateFlag
              ? t("program.programActivateHeader")
              : t("program.programDeactivateHeader")
          }
        >
          <Toggle
            checked={c.activateFlag}
            onChange={() => {
              c.courseId && setSelectedCourses([c.courseId]);
              c.activateFlag
                ? setIsDeactivateModalOpen(true)
                : setIsActivateModalOpen(true);
            }}
          />
        </ToolTipNew>
      );

      const hoverBtn = (
        <ButtonNew
          classes="hover-btn"
          btnStyle={"icon"}
          leadingIcon={<IconsNew variant="line" label="moreVertical" />}
          type={"primary"}
          size={"sm"}
          state={"hover"}
          onClick={(event: MouseEvent) => handleHoverBoxClick(event, c)}
        />
      );

      const courseTransformed = {
        data: [
          { columnText: padding },
          { columnText: dataSession },
          { columnText: dataCourseTitle },
          { columnText: dataPeriod },
          { columnText: dataCompletions },
          { columnText: dataTargets },
          { columnText: dataEmails },
          { columnText: dataStatus },
        ],
        id: String(c.courseId),
        hover: hoverBtn,
        checkBoxClick: () => {
          checkboxGroup.handleClick(c.courseId);
        },
        clickAction: (e: any) => {
          if (!e.target.closest(".hover-btn")) {
            navigate(
              `${process.env.PUBLIC_URL}/quiz/course/detail?course_id=${c.courseId}`
            );
          }
        },
      };

      courseListTransformed.push(courseTransformed);
    }

    return courseListTransformed;
  }

  function checkTableRenderStatus() {
    const isFilterApplied =
      JSON.stringify(filterData) !== JSON.stringify({} as QuizCourseReqDTO);
    const hasCourseList = courseList && courseList.length > 0;
    if (!hasCourseList && isFilterApplied) return 1;
    else if (!hasCourseList && !isFilterApplied) return 2;
    else return 0;
  }

  function renderTableSwitch() {
    switch (checkTableRenderStatus()) {
      case 1:
        return noQuizFiltered;
      case 2:
        return noQuizCreated;
      default:
        return <></>;
    }
  }

  const noQuizFiltered = (
    <BoxNew
      width={"100%"}
      direction="column"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoSearchResultImage width={200} />
      <TypographyNew
        type="h3"
        classes="mg mt-36"
        exactColor={getColor("COLOR_TEXT_PRIMARY")}
      >
        {t("quiz.label.noSearch")}
      </TypographyNew>
      <TypographyNew
        type="body2_rg_long"
        classes="mg mt-8"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
      >
        {t("quiz.msg.noFilterQuizContent")}
      </TypographyNew>
      <ButtonNew
        btnStyle={"fill"}
        state={"default"}
        size={"lg"}
        classes="mg mt-20"
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
          />
        }
        onClick={resetFilter}
        label={t("common.button.resetFilter")}
      />
    </BoxNew>
  );

  const noQuizCreated = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoDashboardImage />
      <TypographyNew
        classes="mg mt-24 mb-8 "
        type="h3"
        exactColor={getColor("COLOR_TEXT_PRIMARY")}
      >
        {t("quiz.msg.noItems")}
      </TypographyNew>
      <TypographyNew
        type="body2_rg_long"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mt-8"
      >
        {t("quiz.msg.noQuizContent")}
      </TypographyNew>
      <TypographyNew
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mb-24"
        fontSize={14}
      >
        {t("quiz.msg.addQuizContent")}
      </TypographyNew>
      <ButtonNew
        btnStyle="fill"
        state={"default"}
        size={"lg"}
        leadingIcon={
          <IconsNew
            variant="line"
            label="plusCircle"
            stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
          />
        }
        onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/content/write`)}
        label={t("common.button.create")}
      />
    </BoxNew>
  );

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  return (
    <BoxNew classes="main" ref={tableContainerRef}>
      {openDateModal && dateModal()}
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizCourse")]} />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.quizCourse")}
          </TypographyNew>
        </BoxNew>
        <BoxNew>
          <ButtonNew
            leadingIcon={
              <IconsNEW variant="line" label="plusCircle" stroke="#252d38" />
            }
            btnStyle="fill"
            type="secondary"
            size="lg"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/quiz/course/write`)
            }
            label={t("common.button.create")}
          />
        </BoxNew>
      </BoxNew>

      {/* main start */}
      <BoxNew width="100%">
        <BoxNew direction="column" width="100%">
          {header}
          {isFilterOpen && filters}
          <BoxNew
            direction="column"
            classes="pd pt-12 flex-1"
            ref={tableRef}
            style={{
              pointerEvents: hoverBoxClickedRow.courseId ? "none" : undefined,
            }}
          >
            <TableNew
              border="none"
              highlightIds={navigateState?.length ? navigateState : undefined}
              columnHeight="md"
              data={transformQuizList(courseList)}
              endReached={() => {}}
              checkBoxClick={() =>
                checkboxGroup.handleAllClick(
                  checkboxGroup.selected.length === courseList.length
                    ? []
                    : courseList.map((q) => q.courseId)
                )
              }
              header={[
                {
                  headerText: "",
                  width: "16px",
                },
                {
                  headerText: t("quiz.table.session"),
                  width: "76px",
                  ...getOrderRenderProps(ColumnsTypeToOrder.courseId),
                },
                {
                  headerText: t("quiz.table.courseTitle"),
                  width: "calc(100% - 750px)",
                  ...getOrderRenderProps(ColumnsTypeToOrder.courseName),
                },
                {
                  headerText: t("quiz.table.period"),
                  width: "330px",
                },
                {
                  headerText: t("quiz.table.completions"),
                  width: "72px",
                },
                {
                  headerText: t("quiz.table.targets"),
                  width: "72px",
                },
                {
                  headerText: t("quiz.table.emails"),
                  width: "72px",
                },
                {
                  headerText: t("quiz.table.status"),
                  width: "104px",
                },
              ]}
              width={"100%"}
              height={
                checkTableRenderStatus() === 0
                  ? `${
                      tableContainerPosition.height -
                      (245 + (isFilterOpen ? 56 : 0))
                    }px`
                  : "0px"
              }
            />
            {renderTableSwitch()}
            <BoxNew>
              <HoverClickBox
                isDisplayed={!!hoverBoxClickedRow.courseId}
                hoverBoxNewCoord={hoverBoxNewCoord}
                onOutsideClick={onOutsideClick}
                onClickDetail={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/quiz/course/detail?course_id=${hoverBoxClickedRow.courseId}`
                  )
                }
                onClickUpdate={(function () {
                  const isMailSentEnded =
                    Number(hoverBoxClickedRow.sendEndDate) * 1000 <=
                    dayjs().valueOf();
                  function updateTraining() {
                    navigate(`${process.env.PUBLIC_URL}/quiz/course/write?course_id=${hoverBoxClickedRow.courseId}
                      `);
                  }
                  return !isMailSentEnded ? updateTraining : undefined;
                })()}
                onClickDelete={(e: MouseEvent) => {
                  hoverBoxClickedRow.courseId &&
                    setSelectedCourses([hoverBoxClickedRow.courseId]);
                  setIsDeleteModalOpen(true);
                  onOutsideClick(e);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </BoxNew>
      {/* main end  */}
      {isActivateModalOpen && (
        <ModalNew
          open={isActivateModalOpen}
          onClose={() => setIsActivateModalOpen(false)}
          width={480}
          title={t("quiz.label.courseActivate")}
          body={
            <BoxNew direction="column" justifyContent="flex-start">
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.msg.courseActivateConfirm")}
              </TypographyNew>
            </BoxNew>
          }
          footer={
            <BoxNew justifyContent="flex-end">
              <ButtonNew
                label={t("common.button.cancel")}
                type="secondary"
                btnStyle="fill"
                onClick={() => setIsActivateModalOpen(false)}
              />
              <ButtonNew
                label={t("program.programActivate")}
                classes={"mg ml-8"}
                btnStyle="fill"
                type="primary"
                onClick={updateActivate}
              />
            </BoxNew>
          }
        />
      )}
      {isDeleteModalOpen && (
        <ModalNew
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title={t("quiz.label.deleteCourse")}
          width={480}
          body={
            <BoxNew direction="column" justifyContent="flex-start" width="100%">
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.msg.deleteQuizCourseMsg")}
              </TypographyNew>
            </BoxNew>
          }
          footer={
            <BoxNew justifyContent="flex-end">
              <ButtonNew
                label={t("common.button.cancel")}
                type="secondary"
                btnStyle="fill"
                onClick={() => setIsDeleteModalOpen(false)}
              />
              <ButtonNew
                label={t("common.button.delete")}
                type="primary"
                btnStyle="fill"
                classes={"mg ml-8"}
                onClick={handleDeleteCourse}
              />
            </BoxNew>
          }
        />
      )}
      {isDeactivateModalOpen && (
        <ModalNew
          open={isDeactivateModalOpen}
          width={480}
          onClose={() => setIsDeactivateModalOpen(false)}
          title={t("quiz.label.courseDeactivate")}
          footer={
            <BoxNew justifyContent="flex-end">
              <ButtonNew
                btnStyle="fill"
                label={t("common.button.cancel")}
                type="secondary"
                onClick={() => setIsDeactivateModalOpen(false)}
              />
              <ButtonNew
                btnStyle="fill"
                label={t("program.programDeactive")}
                classes={"mg ml-8"}
                type="primary"
                onClick={updateDectivate}
              />
            </BoxNew>
          }
          body={
            <BoxNew direction="column" justifyContent="flex-start" width="100%">
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.msg.courseDeactivateConfirm")}
              </TypographyNew>
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
                classes="mg mt-10"
              >
                {t("quiz.msg.courseDeactivateConfirm2")}
              </TypographyNew>
            </BoxNew>
          }
        />
      )}
    </BoxNew>
  );
};

export default QuizCourse;
