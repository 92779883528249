import { useEffect, useState } from "react";

interface TUseDebounce<T> {
  value: T;
  delay: number;
}

/**
 * api call 디바운싱 훅
 * @param {*} value searchword 값
 * @param {*} delay 지연 값
 */
export function useDebounce<T>({ value, delay }: TUseDebounce<T>) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
