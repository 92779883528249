const NoTrainingContentImage = ({
  classes,
  width,
  height,
}: {
  classes?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      className={classes}
      width={width || "220"}
      height={height || "124"}
      viewBox="0 0 220 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29923 1.97676C8.29923 1.27335 7.72899 0.703125 7.02558 0.703125C6.32217 0.703125 5.75195 1.27335 5.75195 1.97676V4.79976C5.75195 5.50317 6.32217 6.0734 7.02558 6.0734C7.72899 6.0734 8.29923 5.50317 8.29923 4.79976V1.97676Z"
        fill="#64D2D0"
      />
      <path
        d="M8.29923 10.6564C8.29923 9.95304 7.72899 9.38281 7.02558 9.38281C6.32217 9.38281 5.75195 9.95304 5.75195 10.6564V13.4794C5.75195 14.1829 6.32217 14.7531 7.02558 14.7531C7.72899 14.7531 8.29923 14.1829 8.29923 13.4794V10.6564Z"
        fill="#64D2D0"
      />
      <path
        d="M12.7771 9.00422C13.4806 9.00422 14.0508 8.43404 14.0508 7.73063C14.0508 7.02722 13.4806 6.45703 12.7771 6.45703H9.95415C9.25074 6.45703 8.68051 7.02722 8.68051 7.73063C8.68051 8.43404 9.25074 9.00422 9.95415 9.00422H12.7771Z"
        fill="#64D2D0"
      />
      <path
        d="M4.09745 9.00422C4.80086 9.00422 5.37109 8.43404 5.37109 7.73063C5.37109 7.02722 4.80086 6.45703 4.09745 6.45703H1.27443C0.571014 6.45703 0.000782967 7.02722 0.000782967 7.73063C0.000782967 8.43404 0.571014 9.00422 1.27443 9.00422H4.09745Z"
        fill="#64D2D0"
      />
      <path
        d="M209.969 2.50979L216.859 9.4L219.369 0L209.969 2.50979Z"
        fill="#64D2D0"
        fillOpacity="0.8"
      />
      <path
        d="M176.872 109.069L134.23 97.649C127.578 95.8729 123.636 89.0334 125.412 82.3809L141.895 20.8415C143.672 14.189 150.511 10.2474 157.164 12.0234L199.805 23.4433C206.457 25.2194 210.399 32.0588 208.623 38.7113L192.14 100.251C190.348 106.903 183.524 110.845 176.872 109.069Z"
        fill="#EBF0F2"
      />
      <path
        d="M170.265 62.579L180.906 65.3989L180.89 65.4456C179.317 71.3659 174.471 76.0242 168.411 76.8811C156.337 78.5948 147.877 67.6891 150.65 57.3287C152.878 49.0247 161.525 44.0393 169.828 46.2516L166.977 56.8924C166.307 59.3696 167.788 61.9246 170.265 62.579Z"
        fill="#FFBB47"
      />
      <path
        d="M170.608 54.5693L173.225 44.8164C181.03 46.9197 185.72 55.0522 183.632 62.8576V62.8732L173.88 60.2558C171.402 59.5859 169.938 57.0308 170.608 54.5693Z"
        fill="#FFBB47"
      />
      <path
        d="M82.1309 97.6373L39.4897 109.057C32.8372 110.833 26.0133 106.892 24.2216 100.239L7.73827 38.6996C5.9622 32.0471 9.90408 25.2232 16.5566 23.4316L59.1978 12.0117C65.8503 10.2357 72.6742 14.1773 74.4659 20.8298L90.9492 82.3693C92.7253 89.0217 88.7834 95.8612 82.1309 97.6373Z"
        fill="#EBF0F2"
      />
      <path
        d="M70.2965 66.6215L57.6457 54.8277C55.854 53.1607 52.9251 53.9396 52.2084 56.2922L48.2979 69.0986L42.4867 63.6769C41.1157 62.3994 38.8724 62.9914 38.3115 64.7987L34.4322 77.4649C33.8557 79.3812 35.6006 81.1884 37.5169 80.6587L51.0711 77.0287C51.0867 77.0287 51.1023 77.0131 51.1179 77.0131C51.1335 77.0131 51.1491 77.0131 51.1647 77.0131L68.8629 72.2769C71.418 71.5914 72.1972 68.4131 70.2965 66.6215Z"
        fill="#50D79F"
      />
      <path
        d="M37.4554 58.4848C39.6323 58.4848 41.397 56.7201 41.397 54.5432C41.397 52.3663 39.6323 50.6016 37.4554 50.6016C35.2785 50.6016 33.5137 52.3663 33.5137 54.5432C33.5137 56.7201 35.2785 58.4848 37.4554 58.4848Z"
        fill="#50D79F"
      />
      <path
        d="M130.261 90.2652H86.1238C79.2377 90.2652 73.6602 84.6877 73.6602 77.8015V14.0809C73.6602 7.19469 79.2377 1.61719 86.1238 1.61719H130.261C137.147 1.61719 142.725 7.19469 142.725 14.0809V77.8015C142.725 84.6877 137.147 90.2652 130.261 90.2652Z"
        fill="#6DA8FC"
      />
      <path
        d="M131.565 52H84.8008C84.2485 52 83.8008 52.4477 83.8008 53V54.5521C83.8008 55.1044 84.2485 55.5521 84.8008 55.5521H131.565C132.117 55.5521 132.565 55.1044 132.565 54.5521V53C132.565 52.4477 132.117 52 131.565 52Z"
        fill="white"
      />
      <path
        d="M131.565 62H84.8008C84.2485 62 83.8008 62.4477 83.8008 63V64.5521C83.8008 65.1044 84.2485 65.5521 84.8008 65.5521H131.565C132.117 65.5521 132.565 65.1044 132.565 64.5521V63C132.565 62.4477 132.117 62 131.565 62Z"
        fill="white"
      />
      <path
        d="M131.5 72H113.5C112.948 72 112.5 72.4477 112.5 73V74.55C112.5 75.1023 112.948 75.55 113.5 75.55H131.5C132.052 75.55 132.5 75.1023 132.5 74.55V73C132.5 72.4477 132.052 72 131.5 72Z"
        fill="white"
      />
      <path
        d="M91.5901 16.9492H85.3359C84.5075 16.9492 83.8359 17.6208 83.8359 18.4492V41.7943C83.8359 42.6228 84.5075 43.2943 85.3359 43.2943H91.5901C92.4186 43.2943 93.0901 42.6228 93.0901 41.7943V18.4492C93.0901 17.6208 92.4186 16.9492 91.5901 16.9492Z"
        fill="#C5DAFF"
      />
      <path
        d="M106.311 27.3906H100.057C99.2282 27.3906 98.5566 28.0622 98.5566 28.8906V41.7974C98.5566 42.6258 99.2282 43.2974 100.057 43.2974H106.311C107.139 43.2974 107.811 42.6258 107.811 41.7974V28.8906C107.811 28.0622 107.139 27.3906 106.311 27.3906Z"
        fill="#C5DAFF"
      />
      <path
        d="M121.022 35.5781H114.768C113.939 35.5781 113.268 36.2497 113.268 37.0781V41.7745C113.268 42.6029 113.939 43.2745 114.768 43.2745H121.022C121.851 43.2745 122.522 42.6029 122.522 41.7745V37.0781C122.522 36.2497 121.851 35.5781 121.022 35.5781Z"
        fill="#C5DAFF"
      />
      <path
        d="M144 103L160 119"
        stroke="#6F7985"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127 109.5C140.531 109.5 151.5 98.531 151.5 85C151.5 71.469 140.531 60.5 127 60.5C113.469 60.5 102.5 71.469 102.5 85C102.5 98.531 113.469 109.5 127 109.5Z"
        fill="white"
        fillOpacity="0.3"
        stroke="#CFD5DB"
        strokeWidth="5"
      />
      <circle cx="70" cy="118" r="6" fill="#FFC34E" />
      <path
        d="M69.3899 118.986C69.3899 118.604 69.4364 118.299 69.5293 118.073C69.6222 117.846 69.7915 117.623 70.0374 117.405C70.286 117.183 70.4513 117.004 70.5333 116.868C70.6152 116.728 70.6562 116.582 70.6562 116.429C70.6562 115.968 70.4431 115.737 70.0169 115.737C69.8148 115.737 69.6522 115.799 69.5293 115.925C69.4091 116.048 69.3462 116.219 69.3408 116.437H68.1523C68.1578 115.916 68.3258 115.507 68.6564 115.212C68.9897 114.917 69.4432 114.77 70.0169 114.77C70.5961 114.77 71.0455 114.91 71.3652 115.192C71.6848 115.47 71.8447 115.865 71.8447 116.376C71.8447 116.608 71.7927 116.828 71.6889 117.036C71.5851 117.241 71.4034 117.469 71.1439 117.72L70.812 118.036C70.6043 118.235 70.4855 118.469 70.4554 118.736L70.439 118.986H69.3899ZM69.2711 120.244C69.2711 120.061 69.3326 119.911 69.4555 119.794C69.5812 119.673 69.741 119.613 69.935 119.613C70.129 119.613 70.2874 119.673 70.4103 119.794C70.536 119.911 70.5989 120.061 70.5989 120.244C70.5989 120.425 70.5374 120.574 70.4144 120.691C70.2942 120.809 70.1344 120.867 69.935 120.867C69.7355 120.867 69.5744 120.809 69.4514 120.691C69.3312 120.574 69.2711 120.425 69.2711 120.244Z"
        fill="white"
      />
    </svg>
  );
};

export default NoTrainingContentImage;
