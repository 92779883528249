import { SetStateAction, useEffect, useState } from "react";

// 일정 시간이 지난 후 자동으로 초기화되는 훅
const useTimedReset = <T>(initialValue: T, delay: number) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setValue(initialValue);
    }, delay);
    return () => clearTimeout(timeout);
  }, [value]);

  return [value, setValue] as [T, React.Dispatch<SetStateAction<T>>];
};

export default useTimedReset;
