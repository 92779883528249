import {
  Box as BoxNew,
  Icons as IconsNew,
  Button as ButtonNew,
  Typography as TypographyNew,
  Accordions as AccordionsNew,
  Modal as ModalNew,
  getColor,
  Table,
  InputArea,
  Checkbox as CheckboxNew,
  Dropdown,
  DropdownItem,
  Chip,
  useToast,
} from "fds";

import "../List/index.scss";
import { useEffect, useState } from "react";
import useReportStore from "../../../../redux/dispatcher/useReportStore";
import { AttachmentType, ReportMail } from "../../../../types/Report";
import CommonModal from "../../../../component/CommonModal";
import copy from "clipboard-copy";
import utilsCommon from "../../../../shared/utils/common";
import MailBody from "../../../../component/MailBody";
import { nanoid } from "@reduxjs/toolkit";
import useReplyStore from "../../../../redux/dispatcher/useReplyStore";
import { ReportReply } from "../../../../types/Reply";
import WSEdit from "../../../../component/WSEdit";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import TitlePath from "../../../../component/TitlePath";
import useCheckboxGroup from "fds/src/hooks/useCheckboxGroup";
import CCInput from "./CCInput";
import NoFileImage from "../../../../shared/image/NoFileImage";

import "../../../../shared/style/stickyLayout.scss";
import useStickyPage from "../../../../hooks/useStickyObserver";
import classNames from "classnames";

export interface CCType {
  isInvalid: boolean;
  value: string;
}
interface ReportViewDetailProps {
  // targetId: number;
  // getNewReportMails: () => void;
  // setTargetId: (id: number) => void;
}

const ReportViewDetail: React.FC<ReportViewDetailProps> = (
  {
    // targetId,
    // setTargetId,
    // getNewReportMails,
  }
) => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    reportState,
    getReportMailDetail,
    sendManualReply,
    updateReportMail,
    downloadAttachment,
  } = useReportStore();
  const { replyState, getReplys } = useReplyStore();
  const [targetMail, changeTargetMail] = useState<ReportMail | undefined>();
  const [manualReplyList, changeManualReplyList] = useState<ReportReply[]>([]);
  const [modalOpenStatus, changeModalOpenStatus] = useState("CLOSE");
  const [comment, changeComment] = useState("");
  const [targetTemplateId, changeTaregetTemplateId] = useState("none");
  const [CCList, changeCCList] = useState<CCType[]>([]);
  const [tempCCList, setTempCCList] = useState<CCType[]>([]);
  const [BCCList, changeBCCList] = useState<CCType[]>([]);
  const [tempBCCList, setTempBCCList] = useState<CCType[]>([]);
  const [isCCWarning, setIsCCWarning] = useState<boolean>(false);
  const [isBCCWarning, setIsBCCWarning] = useState<boolean>(false);

  const { elementRef, sentinel, isAtTop, isHorizontallyScrollable } =
    useStickyPage();

  const [replyBody, changeReplyBody] = useState("<p></p>");
  const [searchParams] = useSearchParams();
  const targetId = searchParams.get("report_id");
  const navigate = useNavigate();
  const [buttonClick, setButtonClick] = useState(0);

  const checkboxGroup = useCheckboxGroup<string | undefined>();
  useEffect(() => {
    if (targetId) {
      getReportMailDetail(parseInt(targetId));
      getReplys({ autoYn: false });
    }
  }, [targetId]);

  useEffect(() => {
    changeTargetMail(reportState.reportDetail);
    changeComment(reportState.reportDetail?.spamComment ?? "");
  }, [reportState.reportDetail]);
  useEffect(() => {
    changeManualReplyList(replyState?.replyListWithCnt?.list);
    if (
      replyState?.replyListWithCnt?.list.filter(
        (item: any) => item.replyId + "" === targetTemplateId
      )?.length > 0
    ) {
      changeReplyBody(
        replyState?.replyListWithCnt?.list.filter(
          (item: any) => item.replyId + "" === targetTemplateId
        )[0].replyBody
      );
    }
  }, [replyState.replyListWithCnt]);

  useEffect(() => {
    tempBCCList.map((t) => {
      if (t.isInvalid) {
        setIsBCCWarning(true);
      }
    });
  }, [tempBCCList]);

  useEffect(() => {
    tempCCList.map((t) => {
      if (t.isInvalid) {
        setIsCCWarning(true);
      }
    });
  }, [tempCCList]);

  const handleUpdateComment = async () => {
    if (targetId) {
      let resp: any = await updateReportMail({
        mailReportId: parseInt(targetId),
        spamComment: comment,
      });
      if (resp.payload?.data) {
        toast.toastMsg(
          nanoid(),
          t("report.msg.reportCommentAddSuccessMsg"),
          "success"
        );
      } else {
        toast.toastMsg(
          nanoid(),
          t("report.msg.reportCommentAddFailMsg"),
          "error"
        );
      }
    }
  };
  const handleUpdateReportStatus = async (value: boolean) => {
    if (targetId) {
      let resp: any = await updateReportMail({
        mailReportId: parseInt(targetId),
        spamFlag: value,
      });
      if (resp.payload?.data) {
        toast.toastMsg(
          nanoid(),
          value
            ? t("report.msg.reportMaliciousUpdateSuccessMsg")
            : t("report.msg.reportMaliciousUpdateCancelMsg"),
          "success"
        );
        targetMail && changeTargetMail({ ...targetMail, spamFlag: value });
      } else {
        toast.toastMsg(
          nanoid(),
          t("report.msg.reportMaliciousUpdateFailMsg"),
          "error"
        );
      }
    }
  };
  const handleUpdateReportComplete = async () => {
    if (targetId) {
      let resp: any = await updateReportMail({
        mailReportId: parseInt(targetId),
        processComplete: true,
      });
      if (resp.payload?.data) {
        toast.toastMsg(
          nanoid(),
          t("report.msg.reportCompleteUpdateSuccessMsg"),
          "success"
        );
        targetMail &&
          changeTargetMail({ ...targetMail, processComplete: true });
      } else {
        toast.toastMsg(
          nanoid(),
          t("report.msg.reportCompleteUpdateFailMsg"),
          "error"
        );
      }
    }
  };

  const onDownload = async () => {
    if (checkboxGroup.selected.length > 0) {
      let selectedFiles: AttachmentType[] = [];

      if (targetMail?.attachments) {
        selectedFiles = targetMail.attachments.filter(
          (a) => a.fileName && checkboxGroup.selected.includes(a.fileName)
        );
      }
      if (targetMail?.fileName && targetMail?.filePath) {
        selectedFiles.push({
          fileName: targetMail?.fileName,
          filePath: targetMail?.filePath,
        });
      }

      const resp: any = await downloadAttachment(selectedFiles);
    }
  };
  const handleSendReportMail = async (isComplete?: boolean) => {
    if (!targetMail?.mailReportId) return;

    let resp: any = await sendManualReply(
      isComplete
        ? {
            ...targetMail,
            body: replyBody
              .replaceAll(
                "<table",
                "<table style='width: 100%;max-width: 100%;margin-bottom: 20px;border-collapse: collapse;'"
              )
              .replaceAll(
                "<td",
                "<td style='border: 1px solid #ddd; padding: 15px;'"
              ),
            reportDate: dayjs().format("YYYYY-MM-DD HH:mm:ss.000"),
            ccAddress: CCList.map((cc) => cc.value),
            bccAddress: BCCList.map((bcc) => bcc.value),
            processComplete: true,
          }
        : {
            ...targetMail,
            body: replyBody
              .replaceAll(
                "<table",
                "<table style='width: 100%;max-width: 100%;margin-bottom: 20px;border-collapse: collapse;'"
              )
              .replaceAll(
                "<td",
                "<td style='border: 1px solid #ddd; padding: 15px;'"
              ),
            reportDate: dayjs().format("YYYYY-MM-DD HH:mm:ss.000"),
            ccAddress: CCList.map((cc) => cc.value),
            bccAddress: BCCList.map((bcc) => bcc.value),
          }
    );
    if (resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("reply.msg.reportReplySuccessMsg"), "success");
      // getNewReportMails();
      // navigate(`${process.env.PUBLIC_URL}/report/view`)
    } else {
      toast.toastMsg(nanoid(), t("reply.msg.reportReplyFailMsg"), "error");
    }
    changeModalOpenStatus("CLOSE");
  };

  const transformAttachList = (attaches: AttachmentType[]) => {
    const attachListTrasnformed = [];

    for (let i = 0; i < attaches.length; i++) {
      const a = attaches[i];
      const dataName = (
        <BoxNew>
          <TypographyNew
            ellipsis
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {a.fileName}
          </TypographyNew>
        </BoxNew>
      );

      const attachTransformed = {
        data: [
          {
            columnText: "",
          },
          {
            columnText: dataName,
          },
        ],
        id: String(a.fileName),
        checkBoxClick: () => {
          checkboxGroup.handleClick(a.fileName ?? "");
        },
      };
      attachListTrasnformed.push(attachTransformed);
    }
    return attachListTrasnformed;
  };
  return (
    <BoxNew classes="sticky-layout" direction="column">
      {modalOpenStatus === "CONFIRM_SAVE_COMMENT" && (
        <CommonModal
          open={modalOpenStatus === "CONFIRM_SAVE_COMMENT"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportCommentSave")}
          subMessage={t("report.msg.reportCommentAddConfirmMsg")}
          afterSubmitButton={handleUpdateComment}
        />
      )}
      {modalOpenStatus === "SET_REPLY_COMPLETE" && (
        <CommonModal
          open={modalOpenStatus === "SET_REPLY_COMPLETE"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportComplete")}
          subMessage={t("report.msg.reportCompleteUpdateConfirmMsg")}
          afterSubmitButton={() => handleUpdateReportComplete()}
        />
      )}
      {modalOpenStatus === "CONFIRM_SAVE_SPAMFLAG" && (
        <CommonModal
          open={modalOpenStatus === "CONFIRM_SAVE_SPAMFLAG"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportMaliciousYN")}
          subMessage={
            targetMail?.spamFlag
              ? t("report.msg.reportMaliciousUpdateCancelConfirmMsg")
              : t("report.msg.reportMaliciousUpdateConfirmMsg")
          }
          afterSubmitButton={() =>
            handleUpdateReportStatus(!targetMail?.spamFlag && false)
          }
        />
      )}
      {modalOpenStatus === "SEND_REPLY_MAIL" && (
        <ModalNew
          open={modalOpenStatus === "SEND_REPLY_MAIL"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportReply")}
          width={536}
          bodyHeight={30}
          body={t("report.msg.reportReplyConfirmMsg")}
          footer={
            <BoxNew justifyContent="flex-end">
              <ButtonNew
                type="secondary"
                btnStyle="fill"
                size="md"
                onClick={() => changeModalOpenStatus("CLOSE")}
                label={t("common.button.cancel")}
              />

              <ButtonNew
                type="secondary"
                btnStyle="fill"
                size="md"
                onClick={() => {
                  handleSendReportMail(true);
                  changeModalOpenStatus("CLOSE");
                }}
                classes="mg ml-4"
                label={t("report.reportReplyAndComplete")}
              />

              <ButtonNew
                type="primary"
                btnStyle="fill"
                size="md"
                onClick={() => handleSendReportMail()}
                classes="mg ml-4"
                label={t("report.reportReply")}
              />
            </BoxNew>
          }
        ></ModalNew>
      )}
      {modalOpenStatus === "VIEW_MAIL_HEADER" && (
        <ModalNew
          open={modalOpenStatus === "VIEW_MAIL_HEADER"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportMailHeaderInfo")}
          width={540}
          height={728}
          bodyHeight={548}
          body={
            <BoxNew direction="column">
              <TypographyNew type="subtitle2">
                {t("quiz.label.text")}
              </TypographyNew>
              <BoxNew classes="mail__header__content">
                <TypographyNew lineHeight="normal">
                  {targetMail?.mailHeader}
                </TypographyNew>
              </BoxNew>
            </BoxNew>
          }
          footer={
            <BoxNew justifyContent="space-between" width={"100%"}>
              <ButtonNew
                btnStyle="link"
                onClick={() => {
                  toast.toastMsg(
                    nanoid(),
                    t("report.msg.reportHeaderCopySuccessMsg"),
                    "success"
                  );
                  copy(targetMail?.mailHeader ?? "");
                }}
                leadingIcon={<IconsNew variant="line" label="copy" />}
                label={t("report.reportHeaderCopy")}
              />
              <ButtonNew
                onClick={() => changeModalOpenStatus("CLOSE")}
                label={t("common.button.close")}
                btnStyle="fill"
              />
            </BoxNew>
          }
        />
      )}
      {modalOpenStatus === "DOWNLOAD_FILE" && (
        <ModalNew
          open={modalOpenStatus === "DOWNLOAD_FILE"}
          onClose={() => changeModalOpenStatus("CLOSE")}
          title={t("report.reportFileDownload")}
          width={540}
          // height={728}
          // bodyHeight={548}
          body={
            <BoxNew>
              <TypographyNew type="body2_rg">
                {t("report.msg.reportFileDownloadWarningMsg")}
              </TypographyNew>
            </BoxNew>
          }
          footer={
            <BoxNew justifyContent="flex-end" width={"100%"}>
              <ButtonNew
                onClick={() => changeModalOpenStatus("CLOSE")}
                label={t("common.button.cancel")}
                btnStyle="fill"
                type="secondary"
              />
              <ButtonNew
                btnStyle="fill"
                onClick={() => {
                  onDownload();
                  changeModalOpenStatus("CLOSE");
                }}
                type="primary"
                label={t("common.button.download")}
                style={{
                  backgroundColor: getColor("COLOR_ERROR_60"),
                }}
              />
            </BoxNew>
          }
        />
      )}

      {modalOpenStatus === "ADD_CC_BCC" && (
        <>
          <ModalNew
            open={modalOpenStatus === "ADD_CC_BCC"}
            onClose={() => changeModalOpenStatus("CLOSE")}
            title={"참조 추가"}
            subTitle={`대상을 입력한 후 Enter 키를 누르십시오. 여러 개의 대상을 입력하려면 구분자(,)로 구분해 입력한 후 Enter 키를 누르십시오.`}
            width={540}
            height={500}
            bodyHeight={300}
            body={
              <BoxNew direction="column" classes="overflow-scroll">
                <BoxNew direction="column" width={"100%"}>
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    classes="mg mb-8"
                  >
                    {t("report.cc")}
                  </TypographyNew>

                  <CCInput
                    tags={tempCCList}
                    setTags={setTempCCList}
                    isWarning={isCCWarning}
                  />
                </BoxNew>
                <BoxNew direction="column" width={"100%"} classes="mg mt-16">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    classes="mg mb-8"
                  >
                    {t("report.bcc")}
                  </TypographyNew>
                  <CCInput
                    tags={tempBCCList}
                    setTags={setTempBCCList}
                    isWarning={isBCCWarning}
                  />
                </BoxNew>
              </BoxNew>
            }
            footer={
              <BoxNew justifyContent="flex-end">
                <ButtonNew
                  btnStyle="fill"
                  size="md"
                  type="secondary"
                  onClick={() => {
                    changeModalOpenStatus("CLOSE");
                  }}
                  label={t("common.button.cancel")}
                />

                <ButtonNew
                  btnStyle="fill"
                  size="md"
                  type="primary"
                  onClick={() => {
                    changeCCList(tempCCList);
                    changeBCCList(tempBCCList);
                    changeModalOpenStatus("CLOSE");
                  }}
                  classes="mg ml-4"
                  label={t("common.button.apply")}
                  state={
                    tempCCList.length > 0 || tempBCCList.length > 0
                      ? "default"
                      : "disable"
                  }
                />
              </BoxNew>
            }
          />
        </>
      )}
      <BoxNew ref={sentinel}>
        <BoxNew
          justifyContent="space-between"
          alignItems="center"
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          <BoxNew direction="column">
            <TitlePath
              path={[t("menu.reportCenter"), t("menu.reportOperation")]}
            />
            <BoxNew alignItems="center" classes="mg mt-8">
              <BoxNew
                onClick={() =>
                  navigate(`${process.env.PUBLIC_URL}/report/view`)
                }
                style={{ cursor: "pointer" }}
              >
                <IconsNew
                  variant="line"
                  label="arrow"
                  direction="left"
                  classes="mg mr-8"
                />
              </BoxNew>
              <TypographyNew
                type={"h1"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("common.button.detail")}
              </TypographyNew>
            </BoxNew>
          </BoxNew>
          <BoxNew>
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              label={t("report.reportSaveToTemplate")}
              leadingIcon={<IconsNew variant="line" label="filePlus" />}
              onClick={() => {
                navigate(`/manageTrain/scenario/write?mailId=${targetId}`);
              }}
            />
          </BoxNew>
        </BoxNew>
      </BoxNew>
      <BoxNew classes="body">
        {/* basic info start  */}
        <BoxNew direction="column">
          <AccordionsNew
            label={t("quiz.label.basicInfo")}
            initialOpenStatus={true}
          >
            <BoxNew
              direction="column"
              classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28"
            >
              {targetMail?.trainingFlag && (
                <>
                  <BoxNew>
                    <BoxNew width={220} classes="mg mr-28">
                      <TypographyNew
                        type="subtitle2"
                        exactColor={getColor("COLOR_TEXT_SECONDARY")}
                      >
                        {t("program.programName")}
                      </TypographyNew>
                    </BoxNew>
                    <BoxNew>
                      <TypographyNew
                        type="body2_rg"
                        exactColor={getColor("COLOR_TEXT_PRIMARY")}
                      >
                        {targetMail?.configName}
                      </TypographyNew>
                    </BoxNew>
                  </BoxNew>
                  <BoxNew>
                    <BoxNew width={220} classes="mg mr-28">
                      <TypographyNew
                        type="subtitle2"
                        exactColor={getColor("COLOR_TEXT_SECONDARY")}
                      >
                        {t("program.trainingDurationOrder")}
                      </TypographyNew>
                    </BoxNew>
                    <BoxNew>
                      {targetMail.sendStartDate ? (
                        <TypographyNew
                          type="body2_rg"
                          exactColor={getColor("COLOR_TEXT_PRIMARY")}
                        >
                          {`${utilsCommon.getShortDate(
                            targetMail.sendStartDate + "+00:00"
                          )}
                ~ ${utilsCommon.getShortDate(targetMail.sendEndDate + "+00:00")}
                / ${t("report.reportConfigId", {
                  round: targetMail.configId,
                })}
                `}
                        </TypographyNew>
                      ) : (
                        <TypographyNew
                          type="body2_rg"
                          exactColor={getColor("COLOR_TEXT_SECONDARY")}
                        >
                          {t("program.deletedProgram")}
                        </TypographyNew>
                      )}
                    </BoxNew>
                  </BoxNew>
                </>
              )}

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.reportCallUser")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {`${targetMail?.userName}(${targetMail?.userEmail})`}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.reportForwardedTime")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    classes="tnum"
                  >
                    {utilsCommon.getFullDateStr(
                      targetMail?.datetimeForwarded + "+00:00"
                    )}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.reportAutoReply")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  {targetMail?.sendAutoReply ? (
                    <BoxNew classes="dot__container">
                      <BoxNew classes="dot green__dot" />
                      <TypographyNew
                        type={"body2_rg"}
                        exactColor={getColor("COLOR_TEXT_PRIMARY")}
                      >
                        {t("report.reportSendReply")}
                      </TypographyNew>
                    </BoxNew>
                  ) : (
                    <BoxNew classes="dot__container">
                      <BoxNew classes="dot red__dot" />
                      <TypographyNew
                        type={"body2_rg"}
                        exactColor={getColor("COLOR_TEXT_PRIMARY")}
                      >
                        {t("report.reportNotSendReply")}
                      </TypographyNew>
                    </BoxNew>
                  )}
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.reportManualReply")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  {targetMail?.sendManualReply ? (
                    <BoxNew classes="dot__container">
                      <BoxNew classes="dot green__dot" />
                      <TypographyNew
                        type={"body2_rg"}
                        exactColor={getColor("COLOR_TEXT_PRIMARY")}
                      >
                        {t("report.reportManualReply")}
                      </TypographyNew>
                    </BoxNew>
                  ) : (
                    <BoxNew classes="dot__container">
                      <BoxNew classes="dot red__dot" />
                      <TypographyNew
                        type={"body2_rg"}
                        exactColor={getColor("COLOR_TEXT_PRIMARY")}
                      >
                        {t("report.reportNotSendReply")}
                      </TypographyNew>
                    </BoxNew>
                  )}
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </AccordionsNew>
        </BoxNew>
        {/* basic info end   */}

        {/* report Mail Start  */}
        <BoxNew direction="column">
          <AccordionsNew
            label={t("report.reportDetail")}
            initialOpenStatus={true}
          >
            <BoxNew
              direction="column"
              classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28"
            >
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.reportMailTitle")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {targetMail?.subject}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("template.body")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew width={598}>
                  {targetMail?.body && targetId && (
                    <MailBody
                      id={parseInt(targetId)}
                      body={targetMail?.body ?? ""}
                      classes={"mg mt-12 mb-28"}
                    />
                  )}
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28" direction="column">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.reportHeader")}
                  </TypographyNew>
                  <TypographyNew
                    type="body4_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-4"
                  >
                    {t("reply.msg.reportHeaderInfoMsg")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <ButtonNew
                    btnStyle={"fill"}
                    size="lg"
                    label={t("report.reportHeaderButton")}
                    type="secondary"
                    leadingIcon={
                      <IconsNew variant="line" label="preview" isOn={true} />
                    }
                    onClick={() => changeModalOpenStatus("VIEW_MAIL_HEADER")}
                  />
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28" direction="column">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("attach.attachSimple")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew direction="column">
                  <BoxNew>
                    <ButtonNew
                      btnStyle={"fill"}
                      size="lg"
                      label={t("report.reportFileDownload")}
                      type="secondary"
                      leadingIcon={<IconsNew variant="line" label="download" />}
                      onClick={() => changeModalOpenStatus("DOWNLOAD_FILE")}
                      state={
                        targetMail?.hasAttachments &&
                        checkboxGroup.selected.length > 0
                          ? "default"
                          : "disable"
                      }
                    />
                  </BoxNew>

                  <Table
                    classes="mg mt-12"
                    border="none"
                    clickIds={checkboxGroup.selected.map(
                      (a, index) => a ?? index + ""
                    )}
                    columnHeight="md"
                    width="554px"
                    height={targetMail?.hasAttachments ? "204px" : "0px"}
                    header={[
                      {
                        headerText: "",
                        width: "12px",
                      },
                      {
                        headerText: "원본 파일 이름",
                        width: "calc(100% - 66px)",
                      },
                    ]}
                    data={transformAttachList(
                      targetMail?.hasAttachments
                        ? targetMail?.attachments
                          ? [
                              ...targetMail.attachments,
                              {
                                fileName: targetMail.fileName,
                                filePath: targetMail.filePath,
                              },
                            ]
                          : [
                              {
                                fileName: targetMail.fileName,
                                filePath: targetMail.filePath,
                              },
                            ]
                        : []
                    )}
                    endReached={() => {}}
                    checkBoxClick={() => {
                      // 첨부파일의 구조: 2개 이상일 때부터 attachments 존재
                      // 1개일 때는 fileName, filePath 에만 담겨 있으므로 따로 처리
                      if (targetMail?.hasAttachments && targetMail.fileName) {
                        if (targetMail.attachments) {
                          checkboxGroup.handleAllClick(
                            checkboxGroup.selected.length ===
                              targetMail.attachments.length + 1
                              ? []
                              : [
                                  ...targetMail.attachments.map(
                                    (a) => a.fileName ?? undefined
                                  ),
                                  targetMail.fileName,
                                ]
                          );
                        } else {
                          checkboxGroup.handleAllClick(
                            checkboxGroup.selected.length === 1
                              ? []
                              : [targetMail.fileName]
                          );
                        }
                      }
                    }}
                  />
                  {!targetMail?.hasAttachments && (
                    <BoxNew
                      justifyContent="center"
                      direction="column"
                      alignItems="center"
                    >
                      <NoFileImage />
                      <TypographyNew
                        type="body4_rg"
                        exactColor={getColor("COLOR_TEXT_SECONDARY")}
                        classes="mg mt-4"
                      >
                        첨부파일이 존재하지 않습니다.
                      </TypographyNew>
                    </BoxNew>
                  )}
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28" direction="column">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.reportComment")}
                  </TypographyNew>
                  <TypographyNew
                    type="body4_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-4"
                  >
                    {t("report.msg.reportCommentInfoMsg")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew direction="column" alignItems="flex-end">
                  <BoxNew>
                    <InputArea
                      width={598}
                      size="lg"
                      maxLength={255}
                      placeholder="코멘트를 입력해 주세요."
                      value={comment}
                      onChange={(e) => changeComment(e.target.value)}
                      isError={comment.length > 255}
                      helpMessage={
                        comment.length > 255
                          ? t("common.msg.maxLengthExceed")
                          : ""
                      }
                    />
                  </BoxNew>
                  <ButtonNew
                    btnStyle="fill"
                    type={"secondary"}
                    classes="mg mt-12"
                    onClick={() =>
                      changeModalOpenStatus("CONFIRM_SAVE_COMMENT")
                    }
                    label={t("report.reportCommentSave")}
                    state={comment.length > 0 ? "default" : "disable"}
                  />
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28" direction="column">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.reportMaliciousYN")}
                  </TypographyNew>
                  <TypographyNew
                    type="body4_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-4"
                  >
                    {t("reply.msg.reportMaliciousFlagInfoMsg")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew direction="column" alignItems="flex-end">
                  <BoxNew>
                    <CheckboxNew
                      label={t("report.stats.commonMaliciousMail")}
                      check={targetMail?.spamFlag}
                      onClick={() =>
                        changeModalOpenStatus("CONFIRM_SAVE_SPAMFLAG")
                      }
                    />
                  </BoxNew>
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </AccordionsNew>
        </BoxNew>
        {/* report Mail End  */}

        {/* reply start */}
        <BoxNew direction="column">
          <AccordionsNew
            label={t("report.reportReply")}
            initialOpenStatus={true}
          >
            <BoxNew
              direction="column"
              classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28"
            >
              <BoxNew alignItems="center">
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("reply.reportSelectManualTemplate")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew>
                  <Dropdown
                    btnStyle="line"
                    width={"224px"}
                    buttonWidth={"224px"}
                    btnSize={"md"}
                    placeholder="선택하지 않음"
                    value={
                      targetTemplateId !== "none"
                        ? manualReplyList.filter(
                            (i) => i.replyId === parseInt(targetTemplateId)
                          )[0].replyTitle
                        : "선택하지 않음"
                    }
                    buttonClick={buttonClick}
                  >
                    {manualReplyList?.map((item) => (
                      <DropdownItem
                        key={item.replyId}
                        label={item.replyTitle}
                        onClick={() => {
                          changeTaregetTemplateId(item.replyId.toString());
                          setButtonClick(
                            buttonClick > 99999 ? 1 : buttonClick + 1
                          );
                        }}
                        checked={item.replyId === parseInt(targetTemplateId)}
                        checkColor={item.replyId === parseInt(targetTemplateId)}
                        type={"check"}
                      />
                    ))}
                  </Dropdown>
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.cc")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew direction="column">
                  <BoxNew classes="mg mb-28">
                    <ButtonNew
                      btnStyle={"fill"}
                      size="lg"
                      label={"참조 추가"}
                      type="secondary"
                      leadingIcon={
                        <IconsNew variant="line" label="addPerson" />
                      }
                      onClick={() => changeModalOpenStatus("ADD_CC_BCC")}
                    />
                  </BoxNew>
                  <BoxNew
                    width={598}
                    maxHeight={307}
                    classes="mg mt-8 gridList mail_ccList overflow-scroll"
                  >
                    {CCList.map((cc) => (
                      <BoxNew classes="mg mr-4">
                        <Chip
                          onClick={() => {
                            changeCCList(CCList.filter((item) => item !== cc));
                            setTempCCList(CCList.filter((item) => item !== cc));
                          }}
                          type={"fill"}
                          size={"sm"}
                          showClose={true}
                          key={cc.value}
                          text={cc.value}
                        />
                      </BoxNew>
                    ))}
                  </BoxNew>
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("report.bcc")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew
                  width={598}
                  maxHeight={307}
                  classes="gridList mail_ccList overflow-scroll"
                >
                  {BCCList.map((bcc) => (
                    <BoxNew classes="mg mr-4">
                      <Chip
                        onClick={() => {
                          changeBCCList(CCList.filter((item) => item !== bcc));
                          setTempBCCList(
                            BCCList.filter((item) => item !== bcc)
                          );
                        }}
                        type={"fill"}
                        size={"sm"}
                        showClose={true}
                        key={bcc.value}
                        text={bcc.value}
                      />
                    </BoxNew>
                  ))}
                </BoxNew>
              </BoxNew>

              <BoxNew>
                <BoxNew width={220} classes="mg mr-28">
                  <TypographyNew
                    type="subtitle2"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    답변
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew direction="column" width={1034}>
                <BoxNew>
                  <WSEdit
                    initBody={
                      manualReplyList.filter(
                        (item) => item.replyId + "" === targetTemplateId
                      )[0]?.replyBody ?? undefined
                    }
                    onChange={(e) => changeReplyBody(e)}
                    isShowButtons={false}
                  />
                </BoxNew>
                <BoxNew justifyContent="flex-end">
                  <ButtonNew
                    btnStyle="fill"
                    size="md"
                    type={"secondary"}
                    classes="mg mt-12"
                    onClick={() => changeModalOpenStatus("SEND_REPLY_MAIL")}
                    label={t("report.reportReply")}
                  />
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </AccordionsNew>
        </BoxNew>
      </BoxNew>
      <BoxNew justifyContent="flex-end" classes="footer ">
        <ButtonNew
          type="primary"
          btnStyle="fill"
          onClick={() => changeModalOpenStatus("SET_REPLY_COMPLETE")}
          label={t("report.reportComplete")}
        />
      </BoxNew>
    </BoxNew>
  );
};

export default ReportViewDetail;
