import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { Box, Button, CalendarRange, Modal, Typography, useToast } from "fds";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DateModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  start: number | undefined;
  end: number | undefined;
  onSubmit: (start: number, end: number) => void;
}

export enum DateType {
  ALL = "ALL",
  PERIOD_3MONTH = "PERIOD_3MONTH",
  PERIOD_6MONTH = "PERIOD_6MONTH",
  PERIOD_1YEAR = "PERIOD_1YEAR",
  PERIOD_CUSTOM = "PERIOD_CUSTOM",
}

// TODO: 타 페이지도 해당 컴포넌트 재사용하도록 리팩토링 필요함
export default function DateModal({
  isOpen,
  setIsOpen,
  start,
  end,
  onSubmit,
}: DateModalProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const [tempDate, setTempDate] = useState({
    start: start,
    end: end,
  });

  const initialDate = {
    start: undefined,
    end: undefined,
  };

  function onCloseDateModal() {
    setIsOpen(false);
    if (initialDate.start && initialDate.end) {
      onSubmit(initialDate.start, initialDate.end);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onCloseDateModal();
      }}
      title={t("graph.phishingCustomScope")}
      width={540}
      body={
        <Box alignItems="center" justifyContent="center" direction="column">
          <Box
            justifyContent="space-between"
            width="100%"
            style={{ gap: 28 }}
            classes="mg mb-12"
          >
            <Typography type="subtitle2" width="50%">
              {t("common.time.selectDate")}
            </Typography>
            <Typography type="subtitle2" width="50%">
              {t("common.time.selectDate")}
            </Typography>
          </Box>
          <CalendarRange
            width={228}
            startDate={start ? new Date(start) : undefined}
            endDate={end ? new Date(end) : undefined}
            onSubmit={(date: any) => {
              setTempDate({
                start: date.start,
                end: date.end,
              });
            }}
            maxDate={new Date()}
          />
        </Box>
      }
      footer={
        <Box justifyContent="flex-end">
          <Button
            btnStyle="fill"
            type="secondary"
            label={t("common.button.cancel")}
            onClick={onCloseDateModal}
          />
          <Button
            btnStyle="fill"
            type="primary"
            label={t("common.button.apply")}
            onClick={() => {
              if (!tempDate.start || !tempDate.end) return;
              if (tempDate.start > tempDate.end) {
                toast.toastMsg(
                  nanoid(),
                  t("program.msg.programEndBeforeStartMsg"),
                  "error"
                );
                return;
              }
              if (tempDate.end / 1000 - tempDate.start / 1000 > 31556926) {
                toast.toastMsg(
                  nanoid(),
                  "설정 날짜는 1년 이내여야만 합니다.",
                  "error"
                );
                return;
              }
              setIsOpen(false);
              const startFormatted = dayjs(tempDate.start)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .valueOf();
              const endFormatted = dayjs(tempDate.end)
                .set("hour", 23)
                .set("minute", 59)
                .set("second", 59)
                .valueOf();
              onSubmit(startFormatted, endFormatted);
            }}
            classes={"mg ml-8"}
          />
        </Box>
      }
    />
  );
}
