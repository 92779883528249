import { useRef, useState } from "react";

export default function useTableHoverBoxClick<T>({
  width = 0,
  height = 0,
  getDynamicHeight, // 선택된 행 값에 따라 높이가 상이해지는 경우
}: {
  width: number;
  height?: number;
  getDynamicHeight?: (row: T) => number;
}) {
  const [hoverBoxClickedRow, setHoverBoxClickedRow] = useState<T>({} as T);
  const [hoverBoxNewCoord, setHoverBoxNewCoor] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 }); // 새로 이동할 좌표 (HoverBox를 띄워주는 왼쪽 위 좌표에 해당함 )

  const tableRef = useRef<HTMLElement>(null);

  function onOutsideClick(event: MouseEvent) {
    setHoverBoxClickedRow({} as T);
  }

  function handleHoverBoxClick(event: MouseEvent, rowData: T) {
    setHoverBoxClickedRow(rowData);
    getHoverBoxPosition(event, rowData);
  }

  // Hover 박스가 table 내에 존재하는지 체크하기 위함. 넘친다면 띄워줄 위치 재조정.
  // 가정 1. HoverBox의 위치는 왼쪽 위 좌표를 조작하여 변경할 수 있다.
  // 가정 2. HoverBox의 디폴트 위치는 클릭 이벤트가 발생한 좌표 기준 왼쪽 아래 뜬다.
  // 하단 함수는 위 두가지 가정이 성립할 때 정상 동작. 가정에 벗어나는 경우 추가 조건 정의가 필요함.
  function getHoverBoxPosition(event: MouseEvent, rowData: T) {
    if (!tableRef.current) return;
    const tableRect = tableRef.current.getBoundingClientRect();
    const hoverBoxHeight = getDynamicHeight
      ? getDynamicHeight(rowData)
      : height;

    const { clientX: eventClientX, clientY: eventClientY } = event;
    const { bottom: tableBottom, left: tableLeft } = tableRect;

    const isXInside = eventClientX - width >= tableLeft;
    const isYInside = eventClientY + hoverBoxHeight <= tableBottom;

    const newXCoord = isXInside ? eventClientX - width : eventClientX;
    const newYCoord = isYInside ? eventClientY : eventClientY - hoverBoxHeight;

    setHoverBoxNewCoor({ x: newXCoord, y: newYCoord });
  }

  function onCloseBox() {
    setHoverBoxClickedRow({} as T);
  }

  return {
    tableRef, // 테이블 ref
    hoverBoxClickedRow, // 클릭한 요소의 행 데이터
    hoverBoxNewCoord, // 호버 박스를 보여줄 새로운 위치 정보
    onOutsideClick, // 클릭한 요소 바깥(외) 요소 클릭 시 실행될 함수
    handleHoverBoxClick, // 클릭한 요소 클릭 시 실행될 함수
    onCloseBox, // 호버 박스 닫고 싶은 경우 실행할 함수
  };
}
