export class FilterBtnActivityCheckUtils {
  // 검색 필터 적용 버튼 활성화 체크
  public static checkIfApplyBtnActive(
    filterTags: string[],
    newFilterTags: string[]
  ): boolean {
    if (filterTags.length === 0 && newFilterTags.length === 0) {
      return false;
    }
    return !FilterBtnActivityCheckUtils.areStrArraysEqual(
      filterTags,
      newFilterTags
    );
  }

  // 필터 초기화 버튼 활성화 체크
  public static checkIfResetFilterBtnActive(filterData: any): boolean {
    const keys = Object.keys(filterData);
    for (const key of keys) {
      const value = (filterData as any)[key];
      if (
        (Array.isArray(value) && value.length > 0) || // 값이 undefined 혹은 배열인 경우 요소가 없을 때
        (typeof value !== "object" && value !== undefined)
      ) {
        return true;
      }
    }
    return false;
  }

  // 순서 무관 두 문자열 배열이 동일한 요소 포함 여부 체크
  private static areStrArraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }
}
