import {
  Typography as TypographyNew,
  Box as BoxNew,
  Avatar as AvatarNew,
} from "fds";
import useReportRankStore from "../../../redux/dispatcher/useReportRankStore";
import { useEffect, useState } from "react";
import utilsCommon from "../../../shared/utils/common";
import { ReportStateType } from "../../../types/ReportRank";
import Highcharts, { TooltipFormatterContextObject } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import NoQuizImage from "../../../shared/image/NoQuizImage";
import { getColor } from "fds";
import IconMindSat from "../../../shared/image/icon";
import Divider from "../../../component/Divider";
interface StaticsPageProps {
  period?: {
    value: string;
    start: number;
    end: number;
  };
}
const StaticsPage: React.FC<StaticsPageProps> = ({ period }) => {
  const { t, i18n } = useTranslation();
  const { reportRankState } = useReportRankStore();
  const [reportStatInfo, changeReportStatInfo] = useState<ReportStateType>({
    totalStat: {
      receivedCnt: 0,
      reportCnt: 0,
      spamCnt: 0,
    },
    dateStat: [],
    reportStat: [],
    spamStat: [],
  });
  const [lineOptions, setLineOptions] = useState<any>([]);

  useEffect(() => {
    setLineOptions({
      chart: {
        type: "spline",
        width: document.getElementById("reportStats")?.clientWidth ?? 1400,
      },
      title: {
        text: "",
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        margin: 30,
        itemStyle: {
          fontSize: "14px",
        },
      },
      xAxis: {
        categories: reportStatInfo?.dateStat
          .map((item) => utilsCommon.getShortDate(item.date))
          .slice(0, 20)
          .reverse(),
        title: {
          text: t("common.time.date"),
          align: "high",
          style: {
            fontSize: "14px",
          },
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
      },
      yAxis: {
        title: {
          text: t("program.programDataUnit"),
          align: "high",
          rotation: 0,
          labels: {
            style: {
              fontSize: "14px",
            },
          },
        },
        style: {
          fontSize: "14px",
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        tickInterval: 50,
      },
      plotOptions: {
        series: {
          allowPointSelect: false,
          events: {},
        },
      },
      tooltip: {
        shared: true,
        style: {
          fontSize: "12px",
        },
        pointFormatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="color:${this.color}">\u25CF</span> ${
            this.series.name
          }: <b>${this.y} ${t("program.programDataUnit")}</b><br/>`;
        },
      },
      credits: { enabled: false },
      series: [
        {
          name: t("report.stats.reportTrainingMailReport"),
          data: reportStatInfo?.dateStat
            .map((item) => item.reportCnt)
            .slice(0, 20)
            .reverse(),
          color: "#05C072",
        },
        {
          name: t("report.stats.reportSuspiciousMail"),
          data: reportStatInfo?.dateStat
            .map((item) => item.receivedCnt - item.reportCnt)
            .slice(0, 20)
            .reverse(),
          color: "#FCAF5C",
        },
        {
          name: t("report.stats.reportActualMaliciousMail"),
          data: reportStatInfo?.dateStat
            .map((item) => item.spamCnt)
            .slice(0, 20)
            .reverse(),
          color: "#9B77FC",
        },
      ],
    });
  }, [reportStatInfo, i18n.language]);
  useEffect(() => {
    changeReportStatInfo(reportRankState?.reportPeriod);
  }, [reportRankState.reportPeriod]);
  const DATE_RESOURCE: { [key: string]: string } = {
    PERIOD_6MONTH: t("report.stats.reportRecent6Month"),
    PERIOD_1YEAR: t("report.stats.reportRecent1Year"),
    PERIOD_CUSTOM: t("graph.phishingCustomScope"),
  };
  return (
    <BoxNew
      classes="mg mt-24"
      direction="column"
      id={"reportStats"}
      width={"100%"}
    >
      {/* <BoxNew alignItems="center">
        <TypographyNew >
          {DATE_RESOURCE[period?.value ?? ""]}
        </TypographyNew>
        <Typography size={16} classes="mg ml-4">{`(${utilsCommon.getShortDate(
          period?.start
        )}~${utilsCommon.getShortDate(period?.end)})`}</Typography>
      </BoxNew> */}
      <BoxNew justifyContent="space-evenly" direction="column">
        <BoxNew justifyContent="space-between" width={"100%"}>
          <BoxNew
            classes="dashboard__box blue__box mg mr-20"
            width={"25%"}
            direction="column"
          >
            <BoxNew justifyContent="space-between" width={"100%"}>
              <BoxNew alignItems="center">
                <TypographyNew type={"h1"} exactColor="white">
                  {reportStatInfo?.totalStat.receivedCnt}
                </TypographyNew>
                <TypographyNew type={"h3"} exactColor="white" classes="mg ml-4">
                  {t("program.programDataUnit")}
                </TypographyNew>
              </BoxNew>
              <IconMindSat
                variant="document"
                label="announcement"
                size={40}
                stroke={"#3E6AC8"}
                strokeBg={"#AFC8FA"}
              />
            </BoxNew>
            <BoxNew justifyContent="flex-start" width={"100%"}>
              <TypographyNew type="body1_rg" exactColor="white">
                {t("report.stats.reportReportReceived")}
              </TypographyNew>
            </BoxNew>
          </BoxNew>
          <BoxNew
            classes="dashboard__box green__box mg mr-20"
            width={"25%"}
            direction="column"
          >
            <BoxNew justifyContent="space-between" width={"100%"}>
              <BoxNew alignItems="center">
                <TypographyNew type={"h1"} exactColor="white">
                  {reportStatInfo?.totalStat.reportCnt}
                </TypographyNew>
                <TypographyNew type={"h3"} exactColor="white" classes="mg ml-4">
                  {t("program.programDataUnit")}
                </TypographyNew>
              </BoxNew>
              <IconMindSat
                variant="document"
                label="report"
                size={40}
                stroke={"#1BA45D"}
                strokeBg={"#9BE0BC"}
              />
            </BoxNew>
            <BoxNew justifyContent="flex-start" width={"100%"}>
              <TypographyNew type="body1_rg" exactColor="white">
                {t("report.stats.reportTrainingMailReport")}
              </TypographyNew>
            </BoxNew>
          </BoxNew>
          <BoxNew
            classes="dashboard__box yellow__box mg mr-20"
            width={"25%"}
            direction="column"
          >
            <BoxNew justifyContent="space-between" width={"100%"}>
              <BoxNew alignItems="center">
                <TypographyNew type={"h1"} exactColor="white">
                  {reportStatInfo?.totalStat.receivedCnt -
                    reportStatInfo?.totalStat.reportCnt}
                </TypographyNew>
                <TypographyNew type={"h3"} exactColor="white" classes="mg ml-4">
                  {t("program.programDataUnit")}
                </TypographyNew>
              </BoxNew>
              <IconMindSat
                variant="document"
                label="report"
                size={40}
                stroke={"#C48D35"}
                strokeBg={"#FBD8A1"}
              />
            </BoxNew>
            <BoxNew justifyContent="flex-start" width={"100%"}>
              <TypographyNew type="body1_rg" exactColor="white">
                {t("report.stats.reportSuspiciousMail")}
              </TypographyNew>
            </BoxNew>
          </BoxNew>
          <BoxNew
            classes="dashboard__box purple__box"
            width={"25%"}
            direction="column"
          >
            <BoxNew justifyContent="space-between" width={"100%"}>
              <BoxNew alignItems="center">
                <TypographyNew type={"h1"} exactColor="white">
                  {reportStatInfo?.totalStat.spamCnt}
                </TypographyNew>
                <TypographyNew type={"h3"} exactColor="white" classes="mg ml-4">
                  {t("program.programDataUnit")}
                </TypographyNew>
              </BoxNew>
              <IconMindSat
                variant="document"
                label="report"
                size={40}
                stroke={"#725FEB"}
                strokeBg={"#CBB9FF"}
              />
            </BoxNew>
            <BoxNew justifyContent="flex-start" width={"100%"}>
              <TypographyNew type="body1_rg" exactColor="white">
                {t("report.stats.reportActualMaliciousMail")}
              </TypographyNew>
            </BoxNew>
          </BoxNew>
        </BoxNew>
        <BoxNew direction="column" width={"100%"} classes="mg mt-40">
          <BoxNew alignItems="flex-end">
            <TypographyNew type="subtitle1">
              {t("report.reportMailStatus")}
            </TypographyNew>
          </BoxNew>
          <BoxNew>
            <HighchartsReact highcharts={Highcharts} options={lineOptions} />
          </BoxNew>
        </BoxNew>
        <Divider direction="row" />
        <BoxNew classes="mg mt-40 mb-40" width={"100%"}>
          <BoxNew direction="column" width={"50%"} classes="mg mr-32">
            <BoxNew alignItems="flex-end">
              <TypographyNew type="subtitle1">
                {t("report.stats.reportAllReportTop10")}
              </TypographyNew>
            </BoxNew>
            <BoxNew classes="mg mt-16" direction="column">
              {reportStatInfo?.reportStat.length > 0 ? (
                reportStatInfo?.reportStat?.map((stat, index) => (
                  <BoxNew
                    justifyContent="space-between"
                    alignItems="center"
                    height={52}
                  >
                    <BoxNew alignItems="center">
                      <TypographyNew width="28px" type="body2_md">
                        {" "}
                        {(index < 9 ? "0" : "") + (index + 1)}
                      </TypographyNew>
                      <AvatarNew name={stat.name} uniqueCode={stat.email} />
                      <TypographyNew ellipsis classes="mg ml-8" type="body2_rg">
                        {stat.name} ({stat.email})
                      </TypographyNew>
                    </BoxNew>
                    <TypographyNew type="body2_rg">
                      {stat.reportCnt} {t("program.programDataUnit")}
                    </TypographyNew>
                  </BoxNew>
                ))
              ) : (
                <BoxNew
                  width={"100%"}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <NoQuizImage />
                  <TypographyNew
                    type="caption_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.msg.noItems")}
                  </TypographyNew>
                </BoxNew>
              )}
            </BoxNew>
          </BoxNew>
          <Divider direction="col" />
          <BoxNew direction="column" width={"50%"} classes="mg ml-32">
            <BoxNew alignItems="flex-end">
              <TypographyNew type="subtitle1">
                {t("report.stats.reportMaliciousReportTop10")}
              </TypographyNew>
            </BoxNew>
            <BoxNew classes="mg mt-16" direction="column">
              {reportStatInfo?.spamStat.length > 0 ? (
                reportStatInfo?.spamStat?.map((stat, index) => (
                  <BoxNew
                    justifyContent="space-between"
                    alignItems="center"
                    height={52}
                  >
                    <BoxNew alignItems="center">
                      <TypographyNew width="28px" type="body2_md">
                        {(index < 9 ? "0" : "") + (index + 1)}
                      </TypographyNew>
                      <AvatarNew name={stat.name} uniqueCode={stat.email} />
                      <TypographyNew classes="mg ml-8" type="body2_rg">
                        {stat.name} ({stat.email})
                      </TypographyNew>
                    </BoxNew>
                    <TypographyNew type="body2_rg">
                      {stat.spamCnt} {t("program.programDataUnit")}
                    </TypographyNew>
                  </BoxNew>
                ))
              ) : (
                <BoxNew
                  width={"100%"}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <NoQuizImage />
                  <TypographyNew
                    type="caption_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.msg.noItems")}
                  </TypographyNew>
                </BoxNew>
              )}
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );
};

export default StaticsPage;
