import { Box, Button, getColor, Modal, Typography } from "fds";
import { useTranslation } from "react-i18next";

const EditCancelModal = ({
  closeModal,
  stayOnPage,
  toShow,
}: {
  closeModal: () => void;
  stayOnPage: () => void;
  toShow: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={toShow}
      onClose={closeModal}
      title={t("common.info.leavePage")}
      width={540}
      body={
        <Typography
          type="body2_rg"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          {t("common.msg.confirmLeavePage")}
        </Typography>
      }
      footer={
        <Box>
          <Button
            btnStyle="fill"
            type="secondary"
            label={"나가기"}
            size="md"
            onClick={closeModal}
          />
          <Button
            btnStyle="fill"
            type="primary"
            label={t("common.info.stayOnPage")}
            size="md"
            onClick={stayOnPage}
          />
        </Box>
      }
    />
  );
};

export default EditCancelModal;
