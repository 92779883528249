import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import axiosBlob from "../../shared/utils/axiosBlob";
import {
  BaseResponseArrDTO,
  BaseResponseBean,
  BaseResponseDTO,
} from "../../types/Common";
import {
  AttachmentType,
  ReportCommentType,
  ReportMail,
  ReportMailSearchType,
  SendReplyType,
} from "../../types/Report";

const GATEWAY_URL = config.servers.gateway.url;

interface ReportApi {
  getReportMails(
    reportMailSearchType: ReportMailSearchType
  ): Promise<BaseResponseArrDTO<ReportMail[]>>;
  getReportMailCnt(req: {
    searchTerm: string;
    rsd: string;
    red: string;
    fsd: string;
    fed: string;
    trainingFlag?: boolean | null;
  }): Promise<number>;
  getReportMailDetail(id: number): Promise<BaseResponseDTO<ReportMail>>;
  updateSpamFlag(req: {
    flag: boolean;
    mailReportId: number;
  }): Promise<BaseResponseBean<any>>;
  updateComment(
    reportCommentType: ReportCommentType
  ): Promise<BaseResponseBean<any>>;
  updateComplete(req: {
    flag: boolean;
    mailReportId: number;
  }): Promise<BaseResponseBean<any>>;
  updateManualReplyState(req: {
    mailReportId: number;
    nowFlag: boolean;
  }): Promise<BaseResponseBean<any>>;
  sendManualReply(sendReplyType: SendReplyType): Promise<BaseResponseBean<any>>;
  getReportAttachments(id: number): Promise<AttachmentType[]>;
  downloadAttachment(attachmentType: AttachmentType[]): Promise<any>;
  updateReportMail(req: ReportCommentType): Promise<BaseResponseBean<any>>;
}

class ReportServerApi implements ReportApi {
  // 구: /report/list
  async getReportMails(
    reportMailSearchType: ReportMailSearchType
  ): Promise<BaseResponseArrDTO<ReportMail[]>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/report/mailbox/list`,
      reportMailSearchType
    );

    return Promise.resolve(res.data);
  }

  async getReportMailCnt(req: {
    searchTerm: string;
    rsd: string;
    red: string;
    fsd: string;
    fed: string;
    trainingFlag: boolean | null;
  }): Promise<number> {
    const res = await axios.get("/report/list/cnt", {
      params: req,
    });

    return Promise.resolve(res.data);
  }

  async getReportMailDetail(id: number): Promise<BaseResponseDTO<ReportMail>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/report/mailbox/detail`,
      {
        mailReportId: id,
      }
    );

    return Promise.resolve(res.data);
  }

  async updateSpamFlag(req: {
    flag: boolean;
    mailReportId: number;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/report/update/spamFlag", null, {
      params: req,
    });

    return Promise.resolve(res.data);
  }
  async updateReportMail(
    req: ReportCommentType
  ): Promise<BaseResponseBean<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/report/mailbox/update`,
      req
    );

    return Promise.resolve(res.data);
  }
  async updateComment(
    reportCommentType: ReportCommentType
  ): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/report/update/comment", reportCommentType);

    return Promise.resolve(res.data);
  }

  async updateComplete(req: {
    flag: boolean;
    mailReportId: number;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/report/update/complete", null, {
      params: req,
    });
    return Promise.resolve(res.data);
  }

  async updateManualReplyState(req: {
    mailReportId: number;
    nowFlag: boolean;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/report/update/manualReply", null, {
      params: req,
    });

    return Promise.resolve(res.data);
  }

  async sendManualReply(
    sendReplyType: SendReplyType
  ): Promise<BaseResponseBean<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/report/send`,
      sendReplyType
    );

    return Promise.resolve(res.data);
  }

  async getReportAttachments(id: number): Promise<AttachmentType[]> {
    const res = await axios.get(`/report/attachments/${id}`);

    return Promise.resolve(res.data);
  }

  async downloadAttachment(attachmentType: AttachmentType[]): Promise<any> {
    const res = await axiosBlob.post(
      `${GATEWAY_URL}/report/download`,
      attachmentType
    );

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    let filename = res.headers["content-disposition"].substring(
      res.headers["content-disposition"].indexOf("filename=") + 9
    );

    filename = filename.replace(/"/g, "");

    link.href = url;
    link.setAttribute("download", decodeURIComponent(filename));

    document.body.appendChild(link);
    link.click();

    return Promise.resolve(res.data);
  }
}

const reportApi: ReportApi = new ReportServerApi();

export default reportApi;
