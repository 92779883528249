import {
  Box as BoxNew,
  getColor,
  Modal as ModalNew,
  Tooltip as TooltipNew,
  Typography as TypographyNew,
  Checkbox as CheckboxNew,
  InputField,
  Button as ButtonNew,
  Icons,
} from "fds";

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { useTranslation } from "react-i18next";
import { Scenario } from "../../../types/Scenario";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import MailBody from "../../../component/MailBody";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import { Category } from "../../../types/Info";

import "./index.scss";
import ScrollBox from "../../../component/ScrollBox";
import { useDebounce } from "../../../hooks/useDebounce";
import useProgressStore from "../../../redux/dispatcher/useProgressStore";
import classNames from "classnames";
import NoFile from "../../../shared/image/NoFile";
import NoDocInfo from "../../../shared/image/NoDocInfo";

interface ScenarioAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedScenarioList: Scenario[];
  setSelectedScenarioList: Dispatch<SetStateAction<Scenario[]>>;
}

const ScenarioModal: React.FC<ScenarioAddModalProps> = ({
  isOpen,
  onClose,
  selectedScenarioList,
  setSelectedScenarioList,
}) => {
  const { t } = useTranslation();

  const [scenarioInput, setScenarioInput] = useState<string>();
  const [selectedThumbnail, setSelectedThumbnail] = useState<Scenario | null>();
  const [templates, setTemplates] = useState<Scenario[]>([]);

  const { mailTemplateState, getMailTemplateData } = useMailTemplateStore();

  const checkboxCateGroup = useCheckboxGroup();
  const checkboxScenarioGroup = useCheckboxGroup<string>(
    selectedScenarioList.map((s) => String(s.templateId))
  );

  const isSubset = (subset: any[], set: any[]) => {
    return subset.every((val) => set.includes(val));
  };

  const viewThumbnail = async (id: number) => {
    const resp: any = await getMailTemplateData(id);
    setSelectedThumbnail(resp.payload?.data ?? null);
  };

  useEffect(() => {
    if (scenarioInput && scenarioInput.length > 0) {
      const filteredTemplates = mailTemplateState.mailTemplateList
        .filter((item: Scenario) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                isSubset(checkboxCateGroup.selected, item.categorys)
            : item;
        })
        .filter((item: Scenario) => item.templateName.includes(scenarioInput));

      setTemplates(filteredTemplates);
    } else {
      const filteredTemplates = mailTemplateState.mailTemplateList.filter(
        (item: Scenario) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                isSubset(checkboxCateGroup.selected, item.categorys)
            : item;
        }
      );

      setTemplates(filteredTemplates);
    }
  }, [scenarioInput]);

  const [selctedTemplateInDetail, changeSelctedTempalteInDetail] = useState<
    string[]
  >([]);

  useEffect(() => {
    setTemplates(mailTemplateState.mailTemplateList);
    if (selctedTemplateInDetail.length === 0)
      changeSelctedTempalteInDetail(
        mailTemplateState.mailTemplateList
          .filter((item1: Scenario) =>
            checkboxScenarioGroup.selected.includes(item1.templateId + "")
          )
          .map((item: Scenario) => item.templateName)
      );
  }, [mailTemplateState.mailTemplateList]);

  useEffect(() => {
    if (checkboxCateGroup.selected.length === 0) {
      setTemplates(mailTemplateState.mailTemplateList);
    } else {
      setTemplates(
        mailTemplateState.mailTemplateList.filter((item: Scenario) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                isSubset(checkboxCateGroup.selected, item.categorys)
            : item;
        })
      );
    }
  }, [checkboxCateGroup.selected]);

  const [categoryInput, setCategoryInput] = useState<string>();

  const footer = (
    <BoxNew justifyContent="flex-end">
      <ButtonNew
        btnStyle="fill"
        type="secondary"
        onClick={onClose}
        label={t("common.button.cancel")}
        size="md"
      />
      <ButtonNew
        classes="mg ml-8"
        btnStyle="fill"
        type="primary"
        onClick={() => {
          setSelectedScenarioList(
            mailTemplateState.mailTemplateList.filter((s: Scenario) =>
              checkboxScenarioGroup.selected.includes(s.templateId + "")
            )
          );
          onClose();
        }}
        state={
          checkboxScenarioGroup.selected.length === 0 ? "disable" : "default"
        }
        label={t("common.button.apply")}
      />
    </BoxNew>
  );

  const [categories, setCategories] = useState<string[]>([]);

  const { categoryState } = useCategoryStore();

  useEffect(() => {
    let newCategory = categoryInput?.trim();
    if (newCategory && newCategory.length > 0) {
      setCategories(
        categoryState.categoryList
          .filter((item: Category) => item.categoryName.includes(newCategory))
          ?.map((c: Category) => c.categoryName) ?? []
      );
    } else {
      setCategories(
        categoryState.categoryList?.map(
          (item: Category) => item.categoryName
        ) ?? []
      );
    }
  }, [categoryInput]);

  const [scenarioHovered, setScenarioHovered] = useState(0);
  const { progressState } = useProgressStore();
  const scenarioHoveredDebounce = useDebounce<number>({
    value: scenarioHovered,
    delay: 500,
  });

  useEffect(() => {
    if (
      scenarioHovered === scenarioHoveredDebounce &&
      scenarioHovered !== 0 &&
      progressState.count === 0
    ) {
      viewThumbnail(scenarioHoveredDebounce);
    }
  }, [scenarioHoveredDebounce]);

  return (
    <ModalNew
      open={isOpen}
      title={t("program.selectScenarioFull")}
      width={"fit-content"}
      onClose={onClose}
      footer={footer}
      classes="scenarioSelectModal"
      body={
        <BoxNew height={528}>
          {/* category start */}
          <BoxNew direction="column" width={270} classes="mg mr-8">
            <TypographyNew type="subtitle2" classes="mg mb-12">
              {t("category.categorySimple")}
            </TypographyNew>
            <InputField
              width={270}
              size={"md"}
              placeholder={t("template.msg.categoryPlaceholder")}
              value={categoryInput ?? ""}
              onChange={(e) => setCategoryInput(e.target.value)}
              leadingIcon={<Icons variant={"line"} label={"search"} />}
            />
            <ScrollBox height={448} classes="border-box mg mt-16">
              {categories && categories?.length > 0 ? (
                <>
                  {!categoryInput && (
                    <BoxNew
                      classes={classNames("selects-box", {
                        selected: checkboxCateGroup.selected.length === 0,
                      })}
                      onClick={() => {
                        checkboxCateGroup.handleAllClick([]);
                      }}
                    >
                      <BoxNew alignItems="center">
                        <TypographyNew
                          type="body2_rg"
                          classes="mg ml-8"
                          ellipsis
                        >
                          {t("common.auth.all")}
                        </TypographyNew>
                      </BoxNew>
                      <CheckboxNew
                        roundshape
                        check={
                          checkboxCateGroup.selected.length > 0 ? false : true
                        }
                      />
                    </BoxNew>
                  )}
                  {categories?.map((item) => (
                    <BoxNew
                      key={item}
                      classes={classNames("selects-box", {
                        selected: checkboxCateGroup.isSelected(item),
                      })}
                      onClick={() => {
                        checkboxCateGroup.handleClick(item);
                        setScenarioInput("");
                      }}
                    >
                      <BoxNew alignItems="center" width={"80%"}>
                        <TooltipNew message={item} maxWidth={"100%"}>
                          <TypographyNew
                            type="body2_rg"
                            classes="mg ml-8"
                            ellipsis
                          >
                            {item}
                          </TypographyNew>
                        </TooltipNew>
                      </BoxNew>
                      <CheckboxNew
                        roundshape
                        check={checkboxCateGroup.isSelected(item)}
                      />
                    </BoxNew>
                  ))}
                </>
              ) : categoryInput?.length &&
                categoryInput?.length > 0 &&
                categories.length === 0 ? (
                <BoxNew
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  classes="no-result-margin"
                >
                  <NoSearchResultImage width={200} />
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-16"
                  >
                    {t("common.msg.noSearchMsg")}
                  </TypographyNew>
                </BoxNew>
              ) : (
                <BoxNew
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  classes="no-result-margin"
                >
                  <NoFile />
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-16"
                  >
                    {t("common.msg.noHistoryMsg")}
                  </TypographyNew>
                </BoxNew>
              )}
            </ScrollBox>
          </BoxNew>
          {/* category end */}
          {/* template start*/}
          <BoxNew direction="column" classes="mg mr-20" width={270}>
            <TypographyNew type="subtitle2" classes="mg mb-12">
              {t("template.template")}
            </TypographyNew>
            <InputField
              width={270}
              size={"md"}
              placeholder={t("template.msg.templateSimplePlaceholder")}
              value={scenarioInput ?? ""}
              onChange={(e) => setScenarioInput(e.target.value)}
              leadingIcon={<Icons variant={"line"} label={"search"} />}
            />
            <ScrollBox
              height={448}
              onMouseLeave={() => setScenarioHovered(0)}
              classes="border-box mg mt-16"
            >
              {templates && templates.length > 0 ? (
                <>
                  {!scenarioInput && (
                    <BoxNew
                      classes={classNames("selects-box", {
                        selected: templates.every((item) =>
                          checkboxScenarioGroup.isSelected(
                            item.templateId.toString()
                          )
                        ),
                      })}
                      onClick={() => {
                        if (
                          checkboxScenarioGroup.selected.length ===
                          templates.length
                        ) {
                          checkboxScenarioGroup.handleAllClick([]);
                        } else {
                          checkboxScenarioGroup.handleAllClick(
                            templates.map((item) => item.templateId.toString())
                          );
                        }
                        setSelectedThumbnail(undefined);
                      }}
                    >
                      <BoxNew>
                        <TypographyNew type="body2_rg" classes="mg ml-8">
                          {t("common.auth.all")}
                        </TypographyNew>
                      </BoxNew>
                      <CheckboxNew
                        roundshape
                        check={templates.every((item) =>
                          checkboxScenarioGroup.selected.includes(
                            item.templateId.toString()
                          )
                        )}
                      />
                    </BoxNew>
                  )}

                  {templates.map((item) => (
                    <BoxNew
                      key={item.templateId}
                      onMouseEnter={() => {
                        setScenarioHovered(item.templateId);
                      }}
                      classes={classNames("selects-box", {
                        selected: checkboxScenarioGroup.isSelected(
                          item.templateId.toString()
                        ),
                      })}
                      onClick={() => {
                        let last = checkboxScenarioGroup.selected.findLast(
                          (item1) => item1 !== item.templateId + ""
                        );
                        viewThumbnail(
                          checkboxScenarioGroup.isSelected(
                            item.templateId + ""
                          ) &&
                            checkboxScenarioGroup.selected?.length > 0 &&
                            last
                            ? parseInt(last)
                            : item.templateId
                        );

                        checkboxScenarioGroup.handleClick(
                          item.templateId.toString()
                        );
                      }}
                    >
                      <BoxNew alignItems="center" width={"80%"}>
                        <TooltipNew
                          message={item.templateName}
                          maxWidth={"100%"}
                        >
                          <TypographyNew
                            type="body2_rg"
                            classes="mg ml-8 overflow-hidden scenario__select__item"
                            lineHeight="normal"
                            maxWidth={"100%"}
                            ellipsis
                          >
                            {item.templateName}
                          </TypographyNew>
                        </TooltipNew>
                      </BoxNew>
                      <CheckboxNew
                        roundshape
                        check={checkboxScenarioGroup.isSelected(
                          item.templateId.toString()
                        )}
                      />
                    </BoxNew>
                  ))}
                </>
              ) : scenarioInput &&
                scenarioInput.length > 0 &&
                templates.length === 0 ? (
                <BoxNew
                  alignItems="center"
                  direction="column"
                  justifyContent={"center"}
                  classes="no-result-margin"
                >
                  <NoSearchResultImage width={200} />
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-16"
                  >
                    {t("common.msg.noSearchMsg")}
                  </TypographyNew>
                </BoxNew>
              ) : (
                <BoxNew
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  classes="no-result-margin"
                >
                  <NoFile />
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-16"
                  >
                    {t("common.msg.noHistoryMsg")}
                  </TypographyNew>
                </BoxNew>
              )}
            </ScrollBox>
          </BoxNew>
          {/* template end */}
          {/* thumbnail start */}
          <BoxNew width={460} direction="column">
            <TypographyNew type="subtitle2" classes="mg mb-12">
              {t("template.preview")}
            </TypographyNew>
            {selectedThumbnail ? (
              <ScrollBox
                classes="scenario-preview-container has-preview border-box"
                px={16}
                py={28}
                backgroundColor={getColor("COLOR_BG_SECONDARY")}
              >
                <BoxNew
                  height={"fit-content"}
                  width={"100%"}
                  direction="column"
                >
                  <BoxNew classes="mg mb-12">
                    <TypographyNew
                      type="body2_rg"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                      classes="mg mr-16"
                    >
                      {t("template.title")}
                    </TypographyNew>
                    <TypographyNew type="subtitle2">
                      {selectedThumbnail.templateName}
                    </TypographyNew>
                  </BoxNew>
                  <MailBody
                    minHeight={405}
                    maxHeight={"auto"}
                    allowScroll={false}
                    body={
                      selectedThumbnail.imagePath
                        ? selectedThumbnail.templateHtml.replaceAll(
                            "##IMAGE##",
                            selectedThumbnail.imagePath
                          )
                        : selectedThumbnail.templateHtml
                    }
                    id={selectedThumbnail.templateId}
                  />
                </BoxNew>
              </ScrollBox>
            ) : (
              <BoxNew classes="scenario-preview-container border-box">
                <BoxNew direction="column" classes="no-result-margin">
                  <NoDocInfo />
                  <TypographyNew
                    classes="mg mt-20"
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    textAlign="center"
                    whiteSpace={"pre-line"}
                  >
                    {t("template.msg.noScenarioPreviewed")}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
            )}
          </BoxNew>
          {/* thumbnail end  */}
        </BoxNew>
      }
    />
  );
};

export default ScenarioModal;
