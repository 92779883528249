import "./index.scss";

import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Avatar as AvatarNew,
  Tag as TagNew,
  Tooltip as TooltipNew,
  Icons as IconsNew,
  Button as ButtonNew,
  Table as TableNew,
  Tooltip,
} from "fds";

import { useEffect, useRef, useState } from "react";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts, { TooltipFormatterContextObject } from "highcharts";
import useDashboardStore from "../../redux/dispatcher/useDashboardStore";
import utilsCommon from "../../shared/utils/common";
import NoTemplateImage from "../../shared/image/NoTemplateImage";
import ScenarioInfoImage from "../../shared/image/ScenarioInfoImage";
import StatInfoImage from "../../shared/image/StatInfoImage";
import { useNavigate } from "react-router-dom";
import NoInfo from "../../component/NoInfo";
import { useTranslation } from "react-i18next";
import Divider from "../../component/Divider";
import IconMindSat from "../../shared/image/icon";

export interface TotalType {
  callAverage: number;
  callTotal: number;
  applyAverage: number;
  applyTotal: number;
  linkAverage: number;
  linkTotal: number;
  infoAverage: number;
  infoTotal: number;
  fileAverage: number;
  fileTotal: number;
  allLink: number;
  allInfo: number;
  allFile: number;
}
const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { dashboardState, getStatTraining, getStatTopTraining } =
    useDashboardStore();
  const [stats, setStats] = useState<StatTraining[]>([]);
  const [statsTop, setStatsTop] = useState<StatTopTraining>();
  const [totalData, setTotalData] = useState<TotalType | null>();
  const [targetConfigId, changeTargetConfigId] = useState(0);
  const { t, i18n } = useTranslation();
  const [topWidth, setTopWidth] = useState<number>(window.innerWidth - 320);
  const [middleWidth, setMiddleWidth] = useState<number>(
    (window.innerWidth - 400) / 2
  );
  useEffect(() => {
    getStatTraining({ realTrainingFlag: true });
    const handleResize = () => {
      setTopWidth(window.innerWidth - 310);
      setMiddleWidth((window.innerWidth - 400) / 2);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (targetConfigId > 0)
      getStatTopTraining({ configId: targetConfigId, intervalTime: 9 });
  }, [targetConfigId]);

  useEffect(() => {
    setStats(dashboardState.stats);
    if (dashboardState.stats.length > 0) {
      changeTargetConfigId(dashboardState.stats[0].configId);

      let totalType: TotalType = {
        callAverage: 0,
        callTotal: 0,
        applyAverage: 0,
        applyTotal: 0,
        linkAverage: 0,
        linkTotal: 0,
        infoAverage: 0,
        infoTotal: 0,
        fileAverage: 0,
        fileTotal: 0,
        allFile: 0,
        allLink: 0,
        allInfo: 0,
      };
      let allUser = 0;
      let allSendSuccess = 0;

      dashboardState.stats.forEach((item: StatTraining) => {
        totalType.callTotal += item.callResult;
        totalType.applyTotal += item.applyUser;
        totalType.linkTotal += item.clickLink;
        totalType.infoTotal += item.clickInfo;
        totalType.fileTotal += item.clickFile;
        allUser += item.sendUserCount;
        allSendSuccess += item.sendSuccess;
        totalType.allLink += item.totalClickLink;
        totalType.allFile += item.totalClickFile;
        // totalType.allInfo
      });

      totalType.callAverage =
        totalType.callTotal === 0 && allSendSuccess === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.callTotal * 100) / allSendSuccess
            );
      totalType.applyAverage =
        totalType.applyTotal === 0 && allUser === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.applyTotal * 100) / allUser
            );
      totalType.linkAverage =
        totalType.linkTotal === 0 && totalType.allLink === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.linkTotal * 100) / totalType.allLink
            );

      totalType.infoAverage =
        totalType.infoTotal === 0 && allSendSuccess === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.infoTotal * 100) / allSendSuccess
            );
      totalType.fileAverage =
        totalType.fileTotal === 0 && totalType.allFile === 0
          ? 0
          : utilsCommon.truncateToTwoDecimals(
              (totalType.fileTotal * 100) / totalType.allFile
            );

      setTotalData(totalType);
    } else {
      setTotalData(null);
    }
  }, [dashboardState.stats]);

  useEffect(() => {
    setStatsTop(dashboardState.statsTop);
  }, [dashboardState.statsTop]);

  const getDateRange = (startDate: string, endDate: string) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const stopDate = new Date(endDate);
    while (currentDate <= stopDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates.map((date) => utilsCommon.getShortDate(date.toString()));
  };

  const mapDataToDates = (
    dates: string[],
    data: StatDate[],
    key: keyof StatDate
  ) => {
    const dataMap: { [key: string]: number } = data.reduce((acc, item) => {
      const date = utilsCommon.getShortDate(item.sendStartDate);
      acc[date] = (item[key] as number) || 0;
      return acc;
    }, {} as { [key: string]: number });
    return dates.map((date) => dataMap[date] || 0);
  };

  useEffect(() => {
    if (statsTop) {
      const startDate = utilsCommon.getShortDate(
        dashboardState.stats.filter(
          (item: StatTraining) => item.configId === targetConfigId
        )[0]?.sendStartDate + "+00:00"
      );

      const endDate = utilsCommon.getShortDate(
        dashboardState.stats.filter(
          (item: StatTraining) => item.configId === targetConfigId
        )[0]?.sendEndDate + "+00:00"
      );

      const allDates = getDateRange(startDate, endDate);

      const reportData = mapDataToDates(
        allDates,
        statsTop.statDate,
        "callResult"
      );
      const clickUserData = mapDataToDates(
        allDates,
        statsTop.statDate,
        "applyUser"
      );
      const linkOpenData = mapDataToDates(
        allDates,
        statsTop.statDate,
        "clickLink"
      );
      const attachOpenData = mapDataToDates(
        allDates,
        statsTop.statDate,
        "clickFile"
      );
      setselectedLineOptions({
        chart: {
          type: "spline",
          width: middleWidth,
          height: 384,
          // style: { fontFamily: "Noto Sans KR" },
        },
        title: {
          text: "",
        },
        legend: {
          align: "left",
          verticalAlign: "top",
          margin: 30,
          itemStyle: {
            fontSize: "12px",
          },
        },
        xAxis: {
          // categories: statsTop?.statDate
          //   .filter((item) => item.applyUser !== 0)
          //   .map((item) => utilsCommon.getShortDate(item?.sendStartDate)),
          categories: allDates,
          title: {
            align: "high",
            style: {
              fontSize: "12px",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        yAxis: {
          // max: 100,
          softMax: 1,
          title: {
            text: t("program.programDataUnit"),
            align: "high",
            rotation: 0,
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          style: {
            fontSize: "12px",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
          dataMin: 0,
          dataMax: 0,

          tickInterval: 1,
        },
        plotOptions: {
          series: {
            allowPointSelect: false,
            events: {},
          },
        },
        tooltip: {
          shared: true,
          style: {
            fontSize: "12px",
          },
          pointFormatter: function (
            this: TooltipFormatterContextObject
          ): string {
            return `<span style="color:${this.color}">\u25CF</span> ${
              this.series.name
            }: <b>${this.y} ${t("program.programDataUnit")}</b><br/>`;
          },
        },
        credits: { enabled: false },
        series: [
          {
            name: t("dashboard.report"),
            data: reportData,
            color: "#3182f6",
          },
          {
            name: t("dashboard.clickUser"),
            // data: statsTop?.statDate
            //   .filter((item) => item.applyUser !== 0)
            //   .map((item) => item.applyUser),
            data: clickUserData,
            color: "#05c072",
          },
          {
            name: t("template.linkOpen"),
            // data: statsTop?.statDate
            //   .filter((item) => item.applyUser !== 0)
            //   .map((item) => item.clickLink),
            data: linkOpenData,
            color: "#fcaf5c",
          },
          // {
          //   name: t("template.personalInfo"),
          //   data: statsTop?.statDate
          //     .filter((item) => item.applyUser !== 0)
          //     .map((item) => item.clickInfo),
          //   color: "#fa504b",
          // },
          {
            name: t("dashboard.attachOpen"),
            // data: statsTop?.statDate
            //   .filter((item) => item.applyUser !== 0)
            //   .map((item) => item.clickFile),
            data: attachOpenData,
            color: "#9b77fc",
          },
        ],
      });
    }
  }, [statsTop, i18n.language, middleWidth]);

  const [lineOptions, setLineOptions] = useState<any>([]);
  const [selectedLineOptions, setselectedLineOptions] = useState<any>([]);
  const [chartKey, setChartKey] = useState(0);
  const [chartKey2, setChartKey2] = useState(0);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    setChartKey((prevKey) => prevKey + 1);
  }, [lineOptions]);

  useEffect(() => {
    setChartKey2((prevKey) => prevKey + 1);
  }, [selectedLineOptions]);
  useEffect(() => {
    if (chartComponentRef.current) {
      const chart = chartComponentRef.current.chart;
      chart.series.forEach((series) => {
        series.points.forEach((point) => {
          let category = point.category as string;
          if (
            parseInt(category.replace(t("program.configRound"), "")) ===
            targetConfigId
          ) {
            point.update({
              selected: true,
            });
          } else {
            point.update({
              selected: false,
            });
          }
        });
      });
    }
  }, [targetConfigId]);

  useEffect(() => {
    let nowConfig = targetConfigId;
    setLineOptions({
      chart: {
        type: "spline",
        width: topWidth,
        height: 280,
        // style: { fontFamily: "Noto Sans KR" },
      },
      title: {
        text: "",
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        margin: 30,
        itemStyle: {
          fontSize: "12px",
        },
      },
      xAxis: {
        categories: stats
          .map((item) => t("report.reportConfigId", { round: item.configId }))
          .reverse(),
        title: {
          text: t("program.configRound"),
          align: "high",
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      yAxis: {
        max: 100,
        title: {
          text: "%",
          align: "high",
          rotation: 0,
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      plotOptions: {
        series: {
          allowPointSelect: false,

          point: {
            events: {
              click: function (this: Highcharts.Point) {
                const category: string = this.category as string; // 클릭된 포인트의 카테고리 값 가져오기
                if (
                  nowConfig ===
                  parseInt(category.replace(t("program.configRound"), ""))
                ) {
                  // nowConfig = 0;
                  // changeTargetConfigId(dashboardState.stats[0].configId);
                  // const chart = this.series.chart;
                  // chart.series.forEach((series) => {
                  //   series.points.forEach((point) => {
                  //     if (point.category === category) {
                  //       point.update({
                  //         selected: false,
                  //       });
                  //     }
                  //   });
                  // });
                } else {
                  nowConfig = parseInt(
                    category.replace(t("program.configRound"), "")
                  );
                  changeTargetConfigId(
                    parseInt(category.replace(t("program.configRound"), ""))
                  );
                  const chart = this.series.chart;
                  chart.series.forEach((series) => {
                    series.points.forEach((point) => {
                      if (point.category === category) {
                        point.update({
                          selected: true,
                        });
                      }
                      // else if (point.selected) {
                      //   point.update({
                      //     selected: false,
                      //   });
                      // }
                    });
                  });
                }
              },
            },
          },
        },
      },
      tooltip: {
        shared: true,
        style: {
          fontSize: "14px",
        },
        pointFormatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y} %</b><br/>`;
        },
      },
      credits: { enabled: false },

      series: [
        {
          name: t("dashboard.report"),
          data: stats
            .map((item) =>
              item.callResult === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.callResult * 100) / item.sendSuccess
                  )
            )
            .reverse(),

          color: "#55a3ee",
        },
        {
          name: t("dashboard.clickUser"),
          data: stats
            .map((item) =>
              item.applyUser === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.applyUser * 100) / item.sendUserCount
                  )
            )
            .reverse(),

          color: "#1bbb97",
        },
        {
          name: t("template.linkOpen"),
          data: stats
            .map((item) =>
              item.totalClickLink === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.clickLink * 100) / item.totalClickLink
                  )
            )

            .reverse(),

          color: "#ff9700",
        },
        {
          name: t("dashboard.attachOpen"),
          data: stats
            .map((item) =>
              item.totalClickFile === 0
                ? 0
                : utilsCommon.truncateToTwoDecimals(
                    (item.clickFile * 100) / item.totalClickFile
                  )
            )
            .reverse(),
          color: "#9b77fc",
        },
      ],
    });
  }, [stats, i18n.language, topWidth]);

  function transformRecentTrainingList(data: StatTraining[]) {
    const dataTransformed = [];
    for (let i = 0; i < data.length; i++) {
      const dataItem = data[i];
      const dataRound = dataItem.configId;
      const dataFlag = dataItem.realTrainingFlag ? (
        <TagNew
          text={t("program.realTrainingLabel")}
          type="box"
          textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
        />
      ) : (
        <TagNew
          text={t("program.mockTrainingLabel")}
          type="box"
          textColor={getColor("COLOR_AVATAR_COOL_GRAY_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_COOL_GRAY_BG")}
        />
      );
      const dataProgramName = (
        <BoxNew width={"100%"}>
          <TooltipNew message={dataItem.configName} maxWidth="90%">
            <Tooltip message={dataItem.configName}>
              <TypographyNew
                ellipsis
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {dataItem.configName}
              </TypographyNew>
            </Tooltip>
          </TooltipNew>
        </BoxNew>
      );
      const dataUser = (
        <TypographyNew
          type="body2_rg"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {dataItem.userCount}
          {t("program.programPeopleUnit")}
        </TypographyNew>
      );
      const dataClickCnt = (
        <TypographyNew
          type="body2_rg"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {dataItem.clickFile + dataItem.clickInfo + dataItem.clickLink}
          {t("program.programDataUnit")}
        </TypographyNew>
      );
      const dataItemTransformed = {
        data: [
          { columnText: dataRound },
          { columnText: dataFlag },
          { columnText: dataProgramName },
          { columnText: dataUser },
          { columnText: dataClickCnt },
        ],
        id: String(dataItem.configId),
        clickAction: () => {
          changeTargetConfigId(dataItem.configId);
        },
      };
      dataTransformed.push(dataItemTransformed);
    }

    return dataTransformed;
  }

  return (
    <BoxNew classes="main dashboard">
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
        id={"dashboardPage"}
      >
        {stats?.length > 0 && (
          <BoxNew direction="column">
            <TypographyNew
              type="h1"
              classes="mg mt-8"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("menu.home")}
            </TypographyNew>
          </BoxNew>
        )}
      </BoxNew>
      {stats?.length > 0 ? (
        <BoxNew classes="mg mt-20" direction="column">
          {/* 최근 10회 훈련 평균 */}
          <BoxNew direction="column" width={"100%"}>
            <BoxNew alignItems="flex-end">
              <TypographyNew
                type="subtitle1"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
                classes="mg mb-24"
              >
                {t("dashboard.recentTenAverage")}
              </TypographyNew>
            </BoxNew>
            {totalData && (
              <BoxNew
                justifyContent="space-between"
                width={"100%"}
                classes="dashboard__boxes"
              >
                <BoxNew
                  classes="dashboard__box blue__box"
                  width={"25%"}
                  direction="column"
                >
                  <BoxNew justifyContent="space-between" width={"100%"}>
                    <BoxNew alignItems="center">
                      <TypographyNew
                        exactColor={getColor("COLOR_TEXT_INVERSE")}
                        type="h1"
                      >
                        {totalData.callAverage}
                      </TypographyNew>
                      <TypographyNew
                        type={"h3"}
                        exactColor="white"
                        classes="mg ml-4"
                      >
                        %
                      </TypographyNew>
                    </BoxNew>
                    <IconMindSat
                      variant="document"
                      label="announcement"
                      size={40}
                      stroke={"#3E6AC8"}
                      strokeBg={"#AFC8FA"}
                    />
                  </BoxNew>
                  <BoxNew justifyContent="flex-start" width={"100%"}>
                    <TypographyNew
                      classes="mg mb-4"
                      exactColor={getColor("COLOR_TEXT_INVERSE")}
                      type="body1_rg"
                    >
                      {t("dashboard.report")}
                    </TypographyNew>
                  </BoxNew>
                </BoxNew>

                <BoxNew
                  classes="dashboard__box green__box"
                  width={"25%"}
                  direction="column"
                >
                  <BoxNew justifyContent="space-between" width={"100%"}>
                    <BoxNew alignItems="center">
                      <TypographyNew
                        exactColor={getColor("COLOR_TEXT_INVERSE")}
                        type="h1"
                      >
                        {totalData.applyAverage}
                      </TypographyNew>
                      <TypographyNew
                        type={"h3"}
                        exactColor="white"
                        classes="mg ml-4"
                      >
                        %
                      </TypographyNew>
                    </BoxNew>
                    <IconMindSat
                      variant="document"
                      label="privacy"
                      size={40}
                      stroke={"#1BA45D"}
                      strokeBg={"#9BE0BC"}
                    />
                  </BoxNew>
                  <BoxNew justifyContent="flex-start" width={"100%"}>
                    <TypographyNew
                      classes="mg mb-4"
                      exactColor={getColor("COLOR_TEXT_INVERSE")}
                      type="body1_rg"
                    >
                      {t("dashboard.clickUser")}
                    </TypographyNew>
                  </BoxNew>
                </BoxNew>

                <BoxNew
                  classes="dashboard__box yellow__box"
                  width={"25%"}
                  direction="column"
                >
                  <BoxNew justifyContent="space-between" width={"100%"}>
                    <BoxNew alignItems="center">
                      <TypographyNew type={"h1"} exactColor="white">
                        {totalData.linkAverage}
                      </TypographyNew>
                      <TypographyNew
                        type={"h3"}
                        exactColor="white"
                        classes="mg ml-4"
                      >
                        %
                      </TypographyNew>
                      <TypographyNew
                        type={"h2"}
                        exactColor="white"
                        classes="mg ml-4"
                      >
                        /
                      </TypographyNew>
                      <TypographyNew
                        type={"h1"}
                        exactColor="white"
                        classes="mg ml-4"
                      >
                        {totalData.infoTotal}
                      </TypographyNew>
                      <TypographyNew
                        type={"h3"}
                        exactColor="white"
                        classes="mg ml-4"
                      >
                        {t("program.programDataUnit")}
                      </TypographyNew>
                    </BoxNew>
                    <IconMindSat
                      variant="document"
                      label="linkClick"
                      size={40}
                      stroke={"#C48D35"}
                      strokeBg={"#FBD8A1"}
                    />
                  </BoxNew>
                  <BoxNew justifyContent="flex-start" width={"100%"}>
                    <TypographyNew
                      classes="mg mb-4"
                      exactColor={getColor("COLOR_TEXT_INVERSE")}
                      type="body1_rg"
                    >
                      {`${t("dashboard.linkOpen")} / ${t(
                        "template.personalInfo"
                      )}`}
                    </TypographyNew>
                  </BoxNew>
                </BoxNew>

                <BoxNew
                  classes="dashboard__box purple__box"
                  width={"25%"}
                  direction="column"
                >
                  <BoxNew justifyContent="space-between" width={"100%"}>
                    <BoxNew alignItems="center">
                      <TypographyNew
                        exactColor={getColor("COLOR_TEXT_INVERSE")}
                        type="h1"
                      >
                        {totalData.fileAverage}
                      </TypographyNew>
                      <TypographyNew
                        type={"h3"}
                        exactColor="white"
                        classes="mg ml-4"
                      >
                        %
                      </TypographyNew>
                    </BoxNew>
                    <IconMindSat
                      variant="document"
                      label="attachment"
                      size={40}
                      stroke={"#725FEB"}
                      strokeBg={"#CBB9FF"}
                    />
                  </BoxNew>
                  <BoxNew justifyContent="flex-start" width={"100%"}>
                    <TypographyNew
                      classes="mg mb-4"
                      exactColor={getColor("COLOR_TEXT_INVERSE")}
                      type="body1_rg"
                    >
                      {t("dashboard.attachOpen")}
                    </TypographyNew>
                  </BoxNew>
                </BoxNew>
              </BoxNew>
            )}
          </BoxNew>

          {/* 최근 10회 훈련 추이 */}
          <BoxNew direction="column" width={"100%"} classes="mg mt-40">
            <BoxNew alignItems="flex-end" justifyContent="space-between">
              <TypographyNew
                type="subtitle1"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
                classes="mg mb-24"
              >
                {t("dashboard.recentTenTrainingTrend")}
              </TypographyNew>
            </BoxNew>
            <HighchartsReact
              highcharts={Highcharts}
              options={lineOptions}
              key={chartKey}
              ref={chartComponentRef}
            />
          </BoxNew>

          {/* 최근 훈련 목록 */}
          <BoxNew width={"100%"} direction="column">
            <BoxNew alignItems="flex-end">
              <TypographyNew
                type="subtitle1"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
                classes="mg mb-24"
              >
                {t("dashboard.recentTrainingList")}
              </TypographyNew>
            </BoxNew>
            <BoxNew width={"100%"}>
              <BoxNew width={"50%"}>
                <TableNew
                  border="none"
                  clickIds={[String(targetConfigId)]}
                  columnHeight="md"
                  data={transformRecentTrainingList(stats)}
                  endReached={() => {}}
                  header={[
                    {
                      headerText: t("program.configRound"),
                      width: "52px",
                    },
                    {
                      headerText: t("template.type"),
                      width: "56px",
                    },
                    {
                      headerText: t("program.programName"),
                      width: "calc(100% - 252px)",
                    },
                    {
                      headerText: t("dashboard.user"),
                      width: "72px",
                    },
                    {
                      headerText: t("dashboard.clickCnt"),
                      width: "72px",
                    },
                  ]}
                  width={"100%"}
                  height={`552px`}
                />
              </BoxNew>
              <BoxNew classes="configChart">
                <TypographyNew
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("dashboard.trainingTrend", { configId: targetConfigId })}
                </TypographyNew>
                <BoxNew classes="mg mt-12 configChart-body">
                  <BoxNew
                    justifyContent="space-between"
                    height={32}
                    width={"100%"}
                  >
                    <TypographyNew
                      type="body2_rg"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    >
                      {t("program.trainingDuration")}:{" "}
                      {utilsCommon.getShortDate(
                        dashboardState.stats.filter(
                          (item: StatTraining) =>
                            item.configId === targetConfigId
                        )[0]?.sendStartDate + "+00:00"
                      )}
                      ~{" "}
                      {utilsCommon.getShortDate(
                        dashboardState.stats.filter(
                          (item: StatTraining) =>
                            item.configId === targetConfigId
                        )[0]?.sendEndDate + "+00:00"
                      )}
                    </TypographyNew>
                  </BoxNew>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={selectedLineOptions}
                    key={chartKey2}
                  />
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </BoxNew>

          {/* TopN */}
          <BoxNew classes="mg mt-40 mb-40" width={"100%"}>
            <BoxNew direction="column" width={"calc((100% - 130px)/3)"}>
              <BoxNew alignItems="flex-end">
                <TypographyNew
                  type="subtitle1"
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("dashboard.clickUserTopTen")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="mg mt-16" direction="column">
                {statsTop?.clickedUser && statsTop?.clickedUser.length > 0 ? (
                  statsTop?.clickedUser?.map((item, index) => (
                    <BoxNew
                      justifyContent="space-between"
                      alignItems="center"
                      height={52}
                      classes="dashboard_toplist"
                      onClick={() => {
                        navigate(`/manageTrain/result`);
                        window.sessionStorage.setItem(
                          "resultFilter",
                          `configId=${targetConfigId}&userName=${item.name}`
                        );
                      }}
                    >
                      <BoxNew alignItems="center" width={"calc(100% - 30px)"}>
                        <TypographyNew
                          width="28px"
                          type="body2_md"
                          exactColor={getColor(
                            "COLOR_TEXT_INTERACTIVE_PRIMARY"
                          )}
                        >
                          {(index < 10 ? "0" : "") + (index + 1)}
                        </TypographyNew>
                        <AvatarNew name={item.name} uniqueCode={item.email} />
                        <TooltipNew
                          message={`${item.name} (${item.email})`}
                          maxWidth={"calc(100% - 70px)"}
                        >
                          <TypographyNew
                            classes="mg ml-8"
                            maxWidth="150px"
                            ellipsis
                            type="body2_rg"
                            exactColor={getColor("COLOR_TEXT_PRIMARY")}
                          >
                            {item.name}
                          </TypographyNew>
                          <TypographyNew
                            classes="mg ml-8"
                            maxWidth="200px"
                            ellipsis
                            type="body2_rg"
                            exactColor={getColor("COLOR_TEXT_PRIMARY")}
                          >
                            ({item.email})
                          </TypographyNew>
                        </TooltipNew>
                      </BoxNew>
                      <TypographyNew
                        type="body2_rg"
                        exactColor={getColor("COLOR_TEXT_PRIMARY")}
                        width={96}
                        ellipsis
                        textAlign="right"
                      >
                        {item.count.toLocaleString()}
                        {t("program.programDataUnit")}
                      </TypographyNew>
                    </BoxNew>
                  ))
                ) : (
                  <NoInfo />
                )}
              </BoxNew>
            </BoxNew>
            <Divider direction="col" classes="mg ml-32 mr-32" />
            <BoxNew direction="column" width={"calc((100% - 130px)/3)"}>
              <BoxNew alignItems="flex-end">
                <TypographyNew
                  type="subtitle1"
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("dashboard.noReportUserTopTen")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="mg mt-16" direction="column">
                {statsTop?.nothingUser && statsTop?.nothingUser.length > 0 ? (
                  statsTop?.nothingUser?.map((item, index) => (
                    <BoxNew
                      justifyContent="space-between"
                      alignItems="center"
                      height={52}
                      classes="dashboard_toplist"
                      onClick={() => {
                        navigate(`/manageTrain/result`);
                        window.sessionStorage.setItem(
                          "resultFilter",
                          `configId=${targetConfigId}&userName=${item.name}&callResult`
                        );
                      }}
                    >
                      <BoxNew alignItems="center" width={"calc(100% - 30px)"}>
                        <TypographyNew
                          width="28px"
                          type="body2_md"
                          exactColor={getColor(
                            "COLOR_TEXT_INTERACTIVE_PRIMARY"
                          )}
                        >
                          {(index < 10 ? "0" : "") + (index + 1)}
                        </TypographyNew>
                        <AvatarNew name={item.name} uniqueCode={item.email} />
                        <TooltipNew
                          message={`${item.name} (${item.email})`}
                          maxWidth={"calc(100% - 70px)"}
                        >
                          <BoxNew alignItems="center" height={20}>
                            <TypographyNew
                              classes="mg ml-8"
                              maxWidth="150px"
                              ellipsis
                              type="body2_rg"
                              exactColor={getColor("COLOR_TEXT_PRIMARY")}
                            >
                              {item.name}
                            </TypographyNew>
                            <TypographyNew
                              classes="mg ml-8"
                              maxWidth="200px"
                              ellipsis
                              type="body2_rg"
                              exactColor={getColor("COLOR_TEXT_PRIMARY")}
                            >
                              ({item.email})
                            </TypographyNew>
                          </BoxNew>
                        </TooltipNew>
                      </BoxNew>
                      <TypographyNew
                        type="body2_rg"
                        exactColor={getColor("COLOR_TEXT_PRIMARY")}
                        width={96}
                        ellipsis
                        textAlign="right"
                      >
                        {item.count.toLocaleString()}
                        {t("program.programDataUnit")}
                      </TypographyNew>
                    </BoxNew>
                  ))
                ) : (
                  <NoInfo />
                )}
              </BoxNew>
            </BoxNew>
            <Divider direction="col" classes="mg ml-32 mr-32" />
            <BoxNew direction="column" width={"calc((100% - 130px)/3)"}>
              <BoxNew alignItems="flex-end">
                <TypographyNew
                  type="subtitle1"
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("dashboard.clickScenarioTopTen")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="mg mt-16" direction="column">
                {statsTop?.scenario && statsTop?.scenario.length > 0 ? (
                  statsTop?.scenario.map((item, index) => (
                    <BoxNew
                      justifyContent="space-between"
                      alignItems="center"
                      height={52}
                      classes="dashboard_toplist"
                      onClick={() =>
                        navigate(
                          `${
                            process.env.PUBLIC_URL
                          }/manageTrain/program/detail?config_id=${parseInt(
                            item.id
                          )}`
                        )
                      }
                    >
                      <BoxNew alignItems="center" width={"calc(100% - 30px)"}>
                        <TypographyNew
                          width="28px"
                          type="body2_md"
                          exactColor={getColor(
                            "COLOR_TEXT_INTERACTIVE_PRIMARY"
                          )}
                        >
                          {(index < 10 ? "0" : "") + (index + 1)}
                        </TypographyNew>
                        <TooltipNew
                          message={`${item.name}`}
                          maxWidth={"calc(100% - 70px)"}
                          // width={"100%"}
                        >
                          <TypographyNew
                            classes="mg ml-8"
                            ellipsis
                            maxWidth="350px"
                            type="body2_rg"
                            exactColor={getColor("COLOR_TEXT_PRIMARY")}
                          >
                            {item.name}
                          </TypographyNew>
                        </TooltipNew>
                      </BoxNew>
                      <TypographyNew
                        type="body2_rg"
                        exactColor={getColor("COLOR_TEXT_PRIMARY")}
                        width={96}
                        ellipsis
                        textAlign="right"
                      >
                        {item.count.toLocaleString()}{" "}
                        {t("program.programDataUnit")}
                      </TypographyNew>
                    </BoxNew>
                  ))
                ) : (
                  <NoInfo />
                )}
              </BoxNew>
            </BoxNew>
          </BoxNew>
        </BoxNew>
      ) : (
        <BoxNew direction="column" classes="mg mt-40">
          <BoxNew width="100%" height={256} justifyContent="center">
            <NoTemplateImage width={288} height={160} />
            <BoxNew width={800} direction="column" classes="mg ml-24">
              <TypographyNew
                classes="mg mb-12"
                type="h2"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("dashboard.createTrainingTitle")}
              </TypographyNew>
              <BoxNew width={295}>
                <TypographyNew
                  classes="mg mb-24"
                  type="body1_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("dashboard.msg.createTrainingTitleMsg")}
                </TypographyNew>
              </BoxNew>
              <ButtonNew
                btnStyle="fill"
                leadingIcon={
                  <IconsNew
                    variant="line"
                    label="plusCircle"
                    stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
                  />
                }
                type="primary"
                size="lg"
                width={115}
                onClick={() => navigate("/manageTrain/program")}
                label={t("menu.createTraining")}
              />
            </BoxNew>
          </BoxNew>
          <BoxNew width="100%" height={256} justifyContent="center">
            <ScenarioInfoImage />
            <BoxNew width={800} direction="column" classes="mg ml-24">
              <TypographyNew
                classes="mg mb-12"
                type="h2"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("menu.templateManagement")}
              </TypographyNew>
              <BoxNew width={301}>
                <TypographyNew
                  classes="mg mb-24"
                  type="body1_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("dashboard.msg.scenarioTitleMsg")}
                </TypographyNew>
              </BoxNew>
              <ButtonNew
                leadingIcon={
                  <IconsNew
                    variant="line"
                    label="document"
                    stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
                  />
                }
                btnStyle="fill"
                type="primary"
                size="lg"
                width={141}
                onClick={() => navigate("/manageTrain/scenario")}
                label={t("menu.templateManagement")}
              />
            </BoxNew>
          </BoxNew>
          <BoxNew width="100%" height={256} justifyContent="center">
            <StatInfoImage />
            <BoxNew width={800} direction="column" classes="mg ml-24">
              <TypographyNew
                classes="mg mb-24"
                type="h2"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("dashboard.reportTitle")}
              </TypographyNew>
              <BoxNew width={260}>
                <TypographyNew
                  classes="mg mb-24"
                  type="body1_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("dashboard.msg.reportTitleMsg")}
                </TypographyNew>
              </BoxNew>
            </BoxNew>
          </BoxNew>
        </BoxNew>
      )}
    </BoxNew>
  );
};
export default Dashboard;
