import { Avatar, Box, Button, Icons, Typography } from "fds";

interface OrganCardProps {
  type?: "USER" | "DEPT";
  userId?: string;
  userName?: string;
  deptName?: string;
  height?: number;
  jobTitle?: string;
  userEmail?: string;
  deleteEvent?: () => void;
}
const OrganCard: React.FC<OrganCardProps> = ({
  type,
  userId,
  userName,
  deptName,
  height,
  jobTitle,
  userEmail,
  deleteEvent,
}) => {
  return (
    <Box
      width={"100%"}
      height={height ?? 52}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box width={"100%"}>
        <Box width={32}>
          <Avatar
            uniqueCode={userId + ""}
            name={userName}
            shape={type === "DEPT" ? "team" : "user"}
          />
        </Box>
        <Box
          width={"calc(100% - 80px"}
          classes="mg ml-8"
          direction="column"
          justifyContent="center"
        >
          <Box>
            <Typography ellipsis>
              {type === "DEPT" ? `${userName}` : `${userName} (${userEmail})`}
            </Typography>
          </Box>
          {deptName && (
            <Box classes="mg mt-2">
              <Typography type="body4_rg">
                {jobTitle ? `${deptName} / ${jobTitle}` : `${deptName}`}
              </Typography>
            </Box>
          )}
        </Box>
        <Box width={32} classes="mg ml-auto">
          {deleteEvent && (
            <Button
              btnStyle="icon"
              onClick={deleteEvent}
              leadingIcon={<Icons variant="line" label="delete" />}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrganCard;
