import "./index.scss";
import {
  Checkbox,
  ColumnDatas,
  Typography,
  Box,
  Button,
  Modal as ModalNew,
  useToast,
  Spinner,
} from "fds";

import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useRef, useState } from "react";
import Highcharts3d from "highcharts/highcharts-3d"; // 3D 기능 활성화
import html2pdf from "html2pdf.js";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { ExportToPDFResDTO } from "../../../types/Training";
import EditableTable from "../../../component/EditableTable";
import useProgressStore from "../../../redux/dispatcher/useProgressStore";
import { TrainingResultType } from "../../../types/Send";
import dayjs from "dayjs";
import Divider from "../../../component/Divider";
import ReactDOMServer from "react-dom/server";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { CustomerInfoBean } from "../../../types/Info";
import { nanoid } from "@reduxjs/toolkit";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import { Scenario } from "../../../types/Scenario";
import { useTranslation } from "react-i18next";

Highcharts3d(Highcharts); // 3D 기능을 Highcharts에 적용

interface ReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  configId?: number;
  configIds?: number[];
}

const ReportModal: React.FC<ReportModalProps> = ({
  isOpen,
  onClose,
  configId,
  configIds,
}) => {
  const { t } = useTranslation();
  const { exportResultToPdf, exportResultReport } = useProgramStore();
  const { customerState } = useCustomerStore();
  const thumbRefs = useRef<(HTMLTableCellElement | null)[]>([]);

  let clickTypeIndex = 0;

  const [viewPage, setViewPage] = useState(0);

  const [downloadTypes, setDownloadTypes] = useState<number[]>([0, 1]);

  const [mailOptions, setMailOptions] = useState<any>([]);
  const [linkOptions, setLinkOptions] = useState<any>([]);
  const [fileOptions, setFileOptions] = useState<any>([]);

  const [chartKey, setChartKey] = useState(0);
  const [chartKey2, setChartKey2] = useState(0);
  const [chartKey3, setChartKey3] = useState(0);

  const [reportData, setReportData] = useState<ExportToPDFResDTO>();
  const [chunkedData, setChunkedData] = useState<TrainingResultType[][]>();
  // 1: 메일 열람, 2: 링크 접속, 3: 개인정보 입력, 4: 첨부파일 실행, 5: 신고
  const [selectedClickTypes, setSelectedClickTypes] = useState<number[]>([
    1, 2, 3, 4, 5,
  ]);
  const customerCode = getLocalCustomerCode();
  const customerName = customerState.companyList.filter(
    (item: CustomerInfoBean) => item.customerCode === customerCode
  )[0].companyName;

  const [coverTitle, setCoverTitle] = useState<string | null>(
    `${customerName}\n악성메일 모의훈련 결과보고서`
  );

  const chunkSizeFirst = 17;
  const chunkSizeNext = 20;

  const toast = useToast();

  const onDownloadExcel = async () => {
    if (configId || configIds) {
      if (customerCode) {
        setIsDownloading(true);
        const resp: any = await exportResultReport({
          size: 0,
          configId: configId,
          configIds: configIds,
          customerName,
        });
        setIsDownloading(false);
      }
    }
  };

  // dummy
  const clickTypes = [
    { id: 1, text: "메일 열람" },
    { id: 2, text: "링크 접속" },
    { id: 3, text: "개인 정보 입력" },
    { id: 4, text: "첨부파일 실행" },
    { id: 5, text: "신고" },
  ];

  const listTypes = [
    { id: 0, text: "표지" },
    {
      id: 1,
      text: "개요",
      child: [
        { id: 4, text: "수행 목적" },
        { id: 5, text: "훈련 대상" },
        { id: 6, text: "수행 일정" },
        { id: 7, text: "수행 인력" },
      ],
    },
    {
      id: 2,
      text: "훈련 내용 및 절차",
      child: [
        { id: 8, text: "훈련 내용" },
        { id: 9, text: "훈련 프로세스" },
        { id: 10, text: "성과 측정 방안" },
        {
          id: 11,
          text: "훈련 시나리오",
          child: [
            { id: 12, text: "훈련 시나리오" },
            { id: 13, text: "훈련 안내 페이지" },
          ],
        },
      ],
    },
    {
      id: 3,
      text: "악성메일 모의 훈련 결과",
      child: [
        { id: 14, text: "총평" },
        { id: 15, text: "훈련 결과 상세" },
      ],
    },
  ];

  const clickDescData = [
    {
      data: [
        { columnText: "메일 열람" },
        { columnText: "• 훈련 대상자의 메일 열람 여부 확인" },
        { columnText: "발송 메일 수 대비 메일 열람 수" },
      ],
      id: String(0),
    },
    {
      data: [
        { columnText: "링크 접속" },
        {
          columnText: "	• 훈련 메일에 삽입된 훈련용 악성 링크 클릭 여부 확인",
        },
        { columnText: "발송 메일 수 대비 링크 접속 수" },
      ],
      id: String(1),
    },
    {
      data: [
        { columnText: "개인정보 입력" },
        { columnText: "• 훈련용 악성 링크 접속 후 개인정보 입력 여부 확인" },
        { columnText: "발송 메일 수 대비 개인정보 입력 수" },
      ],
      id: String(2),
    },
    {
      data: [
        { columnText: "첨부파일 실행" },
        { columnText: "• 훈련 메일에 삽입된 훈련용 첨부파일 실행 여부 확인" },
        { columnText: "발송 메일 수 대비 첨부파일 실행 수" },
      ],
      id: String(3),
    },
    {
      data: [
        { columnText: "메일 신고" },
        { columnText: "• 기업 정책에 따른 훈련 메일 신고 여부 확인" },
        { columnText: "발송 메일 수 대비 메일 신고 수" },
      ],
      id: String(4),
    },
  ];

  const [textData, setTextData] = useState([
    "본 악성메일 모의훈련은 최근 랜섬웨어 등의 악성코드를 포함하는 이메일을 통한 계정 탈취, 정보유출 등의 피해에 대한 대응능력 향상을 위하여 회사명 임직원 대상으로 훈련을 실시하고, 악성메일에 대한 대응 실태를 파악하여 임직원들의 사이버 보안의식을 향상하고, 보안 수준을 향상하는데 그 목적이 있습니다.",
    "악성메일 모의훈련은 불분명한 링크 접속 및 개인정보 입력으로 인한 사이버 침해 사고를 예방하며 임직원들의 정보보안 인식 제고를 목표로 훈련을 수행합니다.",
    "다음과 같은 훈련 프로세스를 통해 악성메일 모의훈련을 단계별로 추진합니다.",
    "메일 열람과 링크 접속 및 개인정보 입력 여부에 대한 데이터를 수집하여 아래와 같이 수준을 측정합니다.",
  ]);

  useEffect(() => {
    onLoadData();
  }, []);

  useEffect(() => {
    setChartKey((prev) => prev + 1);
  }, [mailOptions]);
  useEffect(() => {
    setChartKey2((prev) => prev + 1);
  }, [linkOptions]);
  useEffect(() => {
    setChartKey3((prev) => prev + 1);
  }, [fileOptions]);

  useEffect(() => {
    setMailOptions({
      chart: {
        type: "pie",
        animation: false,
        events: {
          load: null,
        },
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
          depth: 300,
        },
        height: 110,
        width: 590,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      title: {
        text: "",
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        margin: 50,
        itemWidth: 120,
        x: -20,
      },
      credits: { enabled: false },
      series: [
        {
          data: [
            {
              name: "메일 열람",
              y: reportData?.countData?.mailRatio || 0,
            },
            {
              name: "메일 미열람",
              y: 100 - (reportData?.countData?.mailRatio || 0),
            },
          ],
          dataLabels: {
            enabled: false,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              color: "#000",
            },
          },
          showInLegend: true,
        },
      ],
    });

    setLinkOptions({
      chart: {
        type: "pie",
        align: "center",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
          depth: 300,
        },
        height: 110,
        width: 590,
      },
      title: {
        text: "",
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        margin: 50,
        itemWidth: 120,
        x: -20,
      },
      credits: { enabled: false },
      series: [
        {
          data: [
            { name: "링크 접속", y: reportData?.countData?.linkRatio || 0 },
            {
              name: "링크 미접속",
              y: 100 - (reportData?.countData?.linkRatio || 0),
            },
          ],
          dataLabels: {
            enabled: false,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              color: "#000",
            },
          },
          showInLegend: true,
        },
      ],
    });

    setFileOptions({
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
          beta: 0,
          depth: 300,
        },
        height: 110,
        width: 590,
      },
      title: {
        text: "",
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        margin: 50,
        itemWidth: 120,
        x: -20,
      },
      credits: { enabled: false },
      series: [
        {
          data: [
            { name: "첨부파일 실행", y: reportData?.countData?.fileRatio || 0 },
            {
              name: "첨부파일 미실행",
              y: 100 - (reportData?.countData?.fileRatio || 0),
            },
          ],
          dataLabels: {
            enabled: false,
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "normal",
            },
            align: "left",
            x: -100,
            crop: false, // 잘리지 않도록 설정
            overflow: "none", // 말줄임표 방지
          },
          showInLegend: true,
        },
      ],
    });

    if (reportData?.resultList)
      setChunkedData(
        chunkArray(reportData.resultList || [], chunkSizeFirst, chunkSizeNext)
      );
  }, [reportData]);

  const chunkArray = (
    data: TrainingResultType[],
    chunkSizeFirst: number,
    chunkSizeNext: number
  ) => {
    if (data.length === 0) return [];

    const chunks = [];
    let startIndex = 0;

    // 첫 번째 chunk는 chunkSizeFirst 개
    if (chunkSizeFirst > 0) {
      chunks.push(data.slice(startIndex, chunkSizeFirst));
      startIndex = chunkSizeFirst;
    }

    // 이후의 chunk는 chunkSizeNext 개씩 자르기
    while (startIndex < data.length) {
      chunks.push(data.slice(startIndex, startIndex + chunkSizeNext));
      startIndex += chunkSizeNext;
    }

    return chunks;
  };

  const [selectedList, setSelectedList] = useState<number[]>(
    Array.from({ length: 16 }, (_, i) => i)
  );

  const toggleItemAndChildren = (item: any, isChecked: boolean) => {
    const collectIds = (item: any): number[] => {
      return [item.id, ...(item.child ? item.child.flatMap(collectIds) : [])];
    };

    const idsToToggle = collectIds(item);

    setSelectedList((prev) => {
      // 자식 항목들을 포함하여 상태를 업데이트
      const updatedList = isChecked
        ? [...prev, ...idsToToggle.filter((id) => !prev.includes(id))]
        : prev.filter((id) => !idsToToggle.includes(id));

      // 자식들이 모두 선택 해제되면 부모도 선택 해제
      if (!isChecked && item.child) {
        const allChildrenDeselected = item.child.every(
          (child: any) => !updatedList.includes(child.id)
        );

        if (allChildrenDeselected) {
          // 부모 항목도 선택 해제
          return updatedList.filter((id) => id !== item.id);
        }
      }

      return updatedList;
    });
  };

  const isParentChecked = (items: any[], targetId: number): boolean => {
    for (const item of items) {
      if (item.child?.some((child: any) => child.id === targetId)) {
        return selectedList.includes(item.id);
      }
      if (item.child && isParentChecked(item.child, targetId)) {
        return selectedList.includes(item.id);
      }
    }
    return false;
  };

  const renderList = (items: any, depth: number = 0) => {
    return items.map((item: any) => {
      const isChecked = selectedList.includes(item.id);
      const isParentSelected =
        depth === 0 || isParentChecked(listTypes, item.id);

      return (
        <div
          key={item.id}
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginLeft: depth === 0 ? 0 : 20,
            marginTop: "8px",
            cursor: "pointer",
          }}
          onClick={() => setViewPage(item.id)}
        >
          <Checkbox
            label={item.text}
            check={isChecked}
            onClick={() => toggleItemAndChildren(item, !isChecked)}
            disable={depth > 0 && !isParentSelected}
            classDiv={viewPage === item.id ? "report__selected__list" : ""}
            // classes="mg mb-8"
          />
          {item.child && renderList(item.child, depth + 1)}
        </div>
      );
    });
  };

  const onLoadData = async () => {
    const resp: any = await exportResultToPdf({
      configId: configId || undefined,
      configIds: configIds || undefined,
    });

    if (resp && resp.payload && resp.payload.code === "SUCCESS") {
      setReportData(resp.payload.data);
      resp.payload.data.scenarioList.forEach((s: Scenario, index: number) => {
        if (thumbRefs.current[index]) {
          thumbRefs.current[index]!.innerHTML = s.base64Thumb
            ? `<img src="data:image/png;base64,${s.base64Thumb}" alt="Uploaded" />`
            : "";
        }
      });
    }
  };

  const onDownloadPdf = async () => {
    const element = document.getElementById("report__container");

    if (element) {
      // setViewPage(-1);
      setIsDownloading(true);

      toast.toastMsg(
        nanoid(),
        "훈련 결과 상세를 다운받는 경우, 데이터의 양이 많으면 다운로드 완료까지 3분 이상 소요될 수 있습니다.",
        "success"
      );
      // PDF용 임시 컨테이너 생성 (화면에 보이지 않음)
      const pdfContainer = document.createElement("div");
      pdfContainer.style.position = "fixed";
      pdfContainer.style.left = "-9999px";
      pdfContainer.style.bottom = "-9999px";

      document.body.appendChild(pdfContainer);

      // selectedList에 있는 항목을 DOM에 추가
      selectedList.forEach((id) => {
        if ([0, 1, 2, 3].includes(id)) {
          // 1, 2, 3일 때만 렌더링
          const componentHTML = ReactDOMServer.renderToStaticMarkup(
            renderPages(id)
          );
          pdfContainer.innerHTML += componentHTML;
        }
      });

      // PDF로 변환

      const opt = {
        margin: 10,
        filename: "report.pdf",
        html2canvas: {
          dpi: 96,
          letterRendering: true,
          scale: 1.5, // 성능 최적화
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
        optimize: true,
        pagebreak: {
          mode: ["css", "legacy"],
          before: ".page-break-before",
          after: ".page-break-after",
          avoid: ".page-break-avoid",
        },
      };
      // const pages = Array.from(element.children);
      const pages = Array.from(pdfContainer.children);
      let worker = html2pdf().set(opt).from(pages[0]).toPdf();

      pages.slice(1, pages.length).forEach((page) => {
        worker = worker
          .get("pdf")
          .then((pdf: any) => {
            pdf.addPage();
          })
          .from(page)
          .toContainer()
          .toCanvas()
          .toPdf();
      });

      worker.save().then(() => {
        document.body.removeChild(pdfContainer);
        setIsDownloading(false);
      });
    }
  };

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const onDownload = async () => {
    setIsDownloading(true);

    if (downloadTypes.includes(0)) await onDownloadExcel();
    if (downloadTypes.includes(1)) await onDownloadPdf();
  };
  // data
  const [userTableData, setUserTableData] = useState<ColumnDatas[]>([
    {
      data: [{ columnText: "" }, { columnText: "" }, { columnText: "" }],
      id: String(0),
    },
  ]);

  const [workTableData, setWorkTableData] = useState<ColumnDatas[]>([
    {
      data: [{ columnText: "" }, { columnText: "" }, { columnText: "" }],
      id: String(0),
    },
  ]);

  const [managerTableData, setManagerTableData] = useState<ColumnDatas[]>([
    {
      data: [
        { columnText: "" },
        { columnText: "" },
        { columnText: "" },
        { columnText: "" },
      ],
      id: String(0),
    },
  ]);

  // 2.
  const [clickDescTableData, setClickDescTableData] = useState<ColumnDatas[]>([
    {
      data: [{ columnText: "" }, { columnText: "" }, { columnText: "" }],
      id: String(0),
    },
  ]);

  const [processTableData, setProcessTableData] = useState<ColumnDatas[]>([
    {
      data: [{ columnText: "" }, { columnText: "" }],
      id: String(0),
    },
  ]);

  useEffect(() => {
    setUserTableData([
      {
        data: [
          { columnText: `${reportData?.countData?.userCount} 명` },
          { columnText: "" },
          { columnText: "" },
        ],
        id: String(0),
      },
    ]);
    setWorkTableData([
      {
        data: [
          { columnText: "2025.00.00 ~ 2025.00.00" },
          { columnText: "훈련 준비" },
          {
            columnText: "정보보호솔루션 정책 변경\n훈련대상자 리스트 확인",
          },
        ],
        id: String(0),
      },
      {
        data: [
          { columnText: "2025.00.00 ~ 2025.00.00" },
          { columnText: "테스트 훈련" },
          {
            columnText: "테스트 훈련 메일 송/수신 \n테스트 훈련 결과 확인",
          },
        ],
        id: String(1),
      },
      {
        data: [
          {
            columnText: `${dayjs(
              reportData?.configDetail?.sendStartDate + "+00:00"
            ).format("YYYY.MM.DD")} ~ ${dayjs(
              reportData?.configDetail?.sendEndDate + "+00:00"
            ).format("YYYY.MM.DD")}`,
          },
          { columnText: "훈련 수행" },
          {
            columnText: `훈련 메일 발송: ${dayjs(
              reportData?.configDetail?.sendStartDate + "+00:00"
            ).format("YYYY.MM.DD HH:mm")} ~ \n${dayjs(
              reportData?.configDetail?.sendEndDate + "+00:00"
            ).format(
              "YYYY.MM.DD HH:mm"
            )}\n훈련 결과 수집: 00.00. 09:00 ~\n00.00. 18:00`,
          },
        ],
        id: String(2),
      },
      {
        data: [
          { columnText: "2025.00.00 ~ 2025.00.00" },
          { columnText: "훈련 종료" },
          {
            columnText:
              "악성메일 모의훈련 상세보고서 작성 \n악성메일 모의훈련 결과보고서 작성",
          },
        ],
        id: String(3),
      },
    ]);
    setManagerTableData([
      {
        data: [
          { columnText: `주식회사 파수` },
          { columnText: "컨설팅서비스팀" },
          { columnText: "훈련 총괄" },
          { columnText: "@fasoo.com" },
        ],
        id: String(0),
      },
    ]);

    //2.
    setClickDescTableData(
      clickDescData.filter((c) =>
        selectedClickTypes.includes(parseInt(c.id) + 1)
      )
    );

    setProcessTableData([
      {
        data: [
          { columnText: "훈련 준비" },
          {
            columnText:
              "• 훈련 전 협의 사항 확정\n - 훈련 시나리오 선정 \n - 신고 방식 선정\n - 스팸솔루션 및 정보보호솔루션 정책 변경 \n• 훈련 대상자 리스트 확인",
          },
        ],
        id: String(0),
      },
      {
        data: [
          { columnText: "테스트 훈련" },
          {
            columnText:
              "• 고객사 정보보호 솔루션 정책 변경 확인\n - 스팸솔루션 수신 메일 임계치 설정\n - 스팸솔루션 파수 훈련 서버 예외 처리\n - 정보보호솔루션 파수 웹 서버 예외 처리\n• 훈련 담당자를 대상으로 훈련메일 발송\n - 외부에서 훈련메일 일괄 발송 \n - 메일열람, 링크접속, 개인정보 입력, 메일신고 로그 수집",
          },
        ],
        id: String(1),
      },
      {
        data: [
          { columnText: "훈련 수행" },
          {
            columnText:
              "• 훈련 일정에 맞추어 외부에서 훈련메일 랜덤 발송 \n• 훈련 대상자 행위별 로그 수집 \n - 메일 열람 \n - 링크 접속\n - 개인정보 입력 \n - 메일 신고",
          },
        ],
        id: String(2),
      },
      {
        data: [
          { columnText: "훈련 종료" },
          {
            columnText:
              "• 악성메일 모의훈련 상세보고서 작성 \n• 악성메일 모의훈련 결과보고서 작성",
          },
        ],
        id: String(3),
      },
    ]);

    // setScenarioData(reportData?.scenarioList);
  }, [reportData, selectedClickTypes]);
  // 시나리오 설명
  const [editingCell, setEditingCell] = useState<number | null>(null);
  const [editingTextField, setEditingTextField] = useState<number | null>(null);
  const [editingCover, setEditingCover] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState("");

  const handleDoubleClick = (
    index: number,
    value: string,
    type: string = "TABLE"
  ) => {
    if (type === "TABLE") setEditingCell(index);
    else if (type === "COVER") setEditingCover(true);
    else setEditingTextField(index);
    setInputValue(value ?? "");
  };

  const handleBlurOrEnter = (index: number, type: string = "TABLE") => {
    if (type === "TEXT") {
      setTextData((prev) =>
        prev.map((item, i) => (i === index ? inputValue : item))
      );
      setEditingTextField(null);
    } else if (type === "COVER") {
      setCoverTitle(inputValue);
      setEditingCover(false);
    } else {
      if (reportData?.scenarioList) {
        const newData = [...reportData?.scenarioList];
        newData[index].summary = inputValue;
        setReportData((prev) => ({ ...prev, scenarioList: newData }));
        setEditingCell(null);
      }
    }
  };

  const renderPages = (
    id: number = 0,
    index: string = "0"
  ): JSX.Element | JSX.Element[] => {
    switch (id) {
      case 0:
        return (
          <Box direction="column" classes="mg mb-32">
            <Box height={300} justifyContent="flex-end">
              <img
                src={require("../../../shared/image/report/confidential.png")}
                width={128}
                height={56}
              />
            </Box>
            <Box
              height={300}
              justifyContent="center"
              alignItems="center"
              classes="report__cover"
              direction="column"
            >
              <Box
                onDoubleClick={() =>
                  handleDoubleClick(0, coverTitle || "", "COVER")
                }
              >
                {editingCover ? (
                  <span className="input">
                    <textarea
                      value={inputValue}
                      onChange={(e: any) => {
                        setInputValue(e.target.value);
                      }}
                      autoFocus
                      onBlur={() => handleBlurOrEnter(0, "COVER")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleBlurOrEnter(0, "COVER");
                        }
                      }}
                      className={`inputArea`}
                      style={{ width: "780px", height: "100px" }}
                    />
                  </span>
                ) : (
                  <Typography
                    fontWeight={800}
                    fontSize={40}
                    exactColor="white"
                    classes="mg mb-8"
                    textAlign="center"
                    lineHeight="1.4"
                  >
                    {coverTitle &&
                      coverTitle.split("\n").map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box height={300} justifyContent="center" alignItems="flex-end">
              <Typography fontWeight={700} fontSize={28}>
                {dayjs(new Date()).format("YYYY.MM.DD")}
              </Typography>
            </Box>
            <Box justifyContent="center" alignItems="flex-end" height={100}>
              <img
                src={require("../../../shared/image/report/fasoo.png")}
                width={117}
                height={29}
              />
            </Box>
          </Box>
        );
      case 1:
        let firstIndex = 0;
        return (
          <Box direction="column" id={`report__element__${id}`}>
            {selectedList.includes(1) && (
              <Box classes="mg mb-12 mt-32">
                <Typography type="h1">1. 개요</Typography>
              </Box>
            )}
            {selectedList.includes(4) && renderPages(4, `1.${++firstIndex}`)}
            {selectedList.includes(5) && renderPages(5, `1.${++firstIndex}`)}
            {selectedList.includes(6) && renderPages(6, `1.${++firstIndex}`)}
            {selectedList.includes(7) && renderPages(7, `1.${++firstIndex}`)}
          </Box>
        );
      case 2:
        let secondIndex = 0;
        return (
          <>
            <Box direction="column" id={`report__element__${id}`}>
              {selectedList.includes(2) && (
                <Box classes="mg mb-12 mt-32">
                  <Typography type="h1">2. 훈련 내용 및 절차</Typography>
                </Box>
              )}
              {selectedList.includes(8) && renderPages(8, `2.${++secondIndex}`)}
            </Box>
            {selectedList.includes(9) && renderPages(9, `2.${++secondIndex}`)}
            {selectedList.includes(10) && renderPages(10, `2.${++secondIndex}`)}
            {selectedList.includes(11) && renderPages(11, `2.${++secondIndex}`)}
          </>
        );
      case 3:
        let thirdIndex = 0;
        return (
          <>
            <Box direction="column" id={`report__element__${id}`}>
              {selectedList.includes(3) && (
                <Box classes="mg mb-12 mt-32">
                  <Typography type="h1">3. 악성메일 모의 훈련 결과</Typography>
                </Box>
              )}
              {selectedList.includes(14) &&
                renderPages(14, `3.${++thirdIndex}`)}
            </Box>
            {selectedList.includes(15) && renderPages(15, `3.${++thirdIndex}`)}
          </>
        );

      // 1
      case 4:
        return (
          <Box direction="column">
            <Box classes="mg mb-12">
              <Typography type="h2">{index} 수행 목적</Typography>
            </Box>
            <Box
              classes="mg mb-12"
              onDoubleClick={() =>
                handleDoubleClick(0, textData[0] || "", "TEXT")
              }
            >
              {editingTextField === 0 ? (
                <span className="input">
                  <textarea
                    value={inputValue}
                    onChange={(e: any) => {
                      setInputValue(e.target.value);
                    }}
                    autoFocus
                    onBlur={() => handleBlurOrEnter(0, "TEXT")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleBlurOrEnter(0, "TEXT");
                      }
                    }}
                    className={`inputArea`}
                    style={{ width: "780px", height: "50px" }}
                  />
                </span>
              ) : (
                <Typography type="body2_rg" lineHeight="1.4">
                  {textData[0]}
                </Typography>
              )}
            </Box>
          </Box>
        );
      case 5:
        return (
          <Box direction="column">
            <Box classes="mg mb-12">
              <Typography type="h2">{index} 훈련 대상</Typography>
            </Box>

            <Box classes="mg mb-12">
              <EditableTable
                headerData={[
                  { headerText: "대상자", width: "200px" },
                  { headerText: "훈련 구분", width: "260px" },
                  {
                    headerText: "비고",
                    width: "260px",
                  },
                ]}
                tableData={userTableData}
                onDataChange={setUserTableData}
                classes="report__table"
              />
            </Box>
          </Box>
        );
      case 6:
        return (
          <Box direction="column">
            <Box classes="mg mb-12">
              <Typography type="h2">{index} 수행 일정</Typography>
            </Box>
            <Box classes="mg mb-12">
              <EditableTable
                headerData={[
                  { headerText: "기간", width: "230px" },
                  { headerText: "수행 업무", width: "190px" },
                  {
                    headerText: "상세 내용",
                    width: "300px",
                    isLeftCell: true,
                  },
                ]}
                tableData={workTableData}
                onDataChange={setWorkTableData}
                classes="report__table"
              />
            </Box>
          </Box>
        );
      case 7:
        return (
          <Box direction="column">
            <Box classes="mg mb-12">
              <Typography type="h2">{index} 수행 인력</Typography>
            </Box>
            <Box classes="mg mb-12">
              <EditableTable
                headerData={[
                  { headerText: "소속", width: "200px" },
                  { headerText: "담당자", width: "160px" },
                  {
                    headerText: "업무",
                    width: "160px",
                  },
                  {
                    headerText: "연락처",
                    width: "200px",
                  },
                ]}
                tableData={managerTableData}
                onDataChange={setManagerTableData}
                classes="report__table"
              />
            </Box>
          </Box>
        );
      // 2
      case 8:
        return (
          <Box direction="column">
            <Box classes="mg mb-12">
              <Typography type="h2">{index} 훈련 내용</Typography>
            </Box>
            <Box
              classes="mg mb-12"
              onDoubleClick={() =>
                handleDoubleClick(1, textData[1] || "", "TEXT")
              }
            >
              {editingTextField === 1 ? (
                <span className="input">
                  <textarea
                    value={inputValue}
                    onChange={(e: any) => {
                      setInputValue(e.target.value);
                    }}
                    autoFocus
                    onBlur={() => handleBlurOrEnter(1, "TEXT")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleBlurOrEnter(1, "TEXT");
                      }
                    }}
                    className={`inputArea`}
                    style={{ width: "780px", height: "50px" }}
                  />
                </span>
              ) : (
                <Typography type="body2_rg" lineHeight="1.4">
                  {textData[1]}
                </Typography>
              )}
            </Box>

            <EditableTable
              headerData={[
                { headerText: "훈련 내용", width: "150px" },
                { headerText: "설명", width: "340px" },
                {
                  headerText: "평가 항목",
                  width: "230px",
                },
              ]}
              tableData={clickDescTableData}
              onDataChange={setClickDescTableData}
              classes="report__table"
            />
          </Box>
        );
      case 9:
        return (
          <Box direction="column">
            <Box classes="mg mb-12">
              <Typography type="h2">{index} 훈련 프로세스</Typography>
            </Box>
            <Box
              classes="mg mb-12"
              onDoubleClick={() =>
                handleDoubleClick(2, textData[2] || "", "TEXT")
              }
            >
              {editingTextField === 2 ? (
                <span className="input">
                  <textarea
                    value={inputValue}
                    onChange={(e: any) => {
                      setInputValue(e.target.value);
                    }}
                    autoFocus
                    onBlur={() => handleBlurOrEnter(2, "TEXT")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleBlurOrEnter(2, "TEXT");
                      }
                    }}
                    className={`inputArea`}
                    style={{ width: "780px", height: "50px" }}
                  />
                </span>
              ) : (
                <Typography type="body2_rg" lineHeight="1.4">
                  {textData[2]}
                </Typography>
              )}
            </Box>
            <EditableTable
              headerData={[
                { headerText: "단계", width: "250px" },
                {
                  headerText: "수행 업무",
                  width: "480px",
                  isLeftCell: true,
                },
              ]}
              tableData={processTableData}
              onDataChange={setProcessTableData}
              classes="report__table"
            />
          </Box>
        );
      case 10:
        return (
          <Box direction="column">
            <Box classes="mg mb-12 mt-32">
              <Typography type="h2">2.3. 성과 측정 방안</Typography>
            </Box>
            <Box
              onDoubleClick={() =>
                handleDoubleClick(3, textData[3] || "", "TEXT")
              }
            >
              {editingTextField === 3 ? (
                <span className="input">
                  <textarea
                    value={inputValue}
                    onChange={(e: any) => {
                      setInputValue(e.target.value);
                    }}
                    autoFocus
                    onBlur={() => handleBlurOrEnter(3, "TEXT")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleBlurOrEnter(3, "TEXT");
                      }
                    }}
                    className={`inputArea`}
                    style={{ width: "700px", height: "50px" }}
                  />
                </span>
              ) : (
                <Typography type="body2_rg" lineHeight="1.4">
                  {textData[3]}
                </Typography>
              )}
            </Box>

            {selectedClickTypes.includes(1) && (
              <Box direction="column" classes="mg mt-32">
                <Box classes="mg mb-12">
                  <Typography type="h3">
                    {`2.3.${++clickTypeIndex}. 메일 열람률`}
                  </Typography>
                </Box>
                <Box alignItems="center" classes="mg mt-8">
                  <Box direction="column" width={80} alignItems="center">
                    <Box classes="mg mb-8">메일 열람 수</Box>
                    <Box
                      style={{
                        borderTop: "1px solid black",
                        paddingTop: "8px",
                        width: "80px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      발송 메일 수
                    </Box>
                  </Box>
                  <Box classes="mg ml-8">x 100 = 수준 측정치 (%)</Box>
                </Box>
              </Box>
            )}

            {selectedClickTypes.includes(2) && (
              <Box direction="column" classes="mg mt-32">
                <Box classes="mg mb-12">
                  <Typography type="h3">
                    {`2.3.${++clickTypeIndex}. 링크 접속률`}
                  </Typography>
                </Box>
                <Box alignItems="center" classes="mg mt-8">
                  <Box direction="column" width={80} alignItems="center">
                    <Box classes="mg mb-8">링크 접속 수</Box>
                    <Box
                      style={{
                        borderTop: "1px solid black",
                        paddingTop: "8px",
                        width: "80px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      발송 메일 수
                    </Box>
                  </Box>
                  <Box classes="mg ml-8">x 100 = 수준 측정치 (%)</Box>
                </Box>
              </Box>
            )}

            {selectedClickTypes.includes(3) && (
              <Box direction="column" classes="mg mt-32">
                <Box classes="mg mb-12">
                  <Typography type="h3">
                    {`2.3.${++clickTypeIndex}. 개인정보 입력률`}
                  </Typography>
                </Box>
                <Box alignItems="center" classes="mg mt-8">
                  <Box direction="column" width={100} alignItems="center">
                    <Box classes="mg mb-8">개인정보 입력 수</Box>
                    <Box
                      style={{
                        borderTop: "1px solid black",
                        paddingTop: "8px",
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      발송 메일 수
                    </Box>
                  </Box>
                  <Box classes="mg ml-8">x 100 = 수준 측정치 (%)</Box>
                </Box>
              </Box>
            )}

            {selectedClickTypes.includes(4) && (
              <Box direction="column" classes="mg mt-32">
                <Box classes="mg mb-12">
                  <Typography type="h3">
                    {`2.3.${++clickTypeIndex}. 첨부파일 실행율`}
                  </Typography>
                </Box>
                <Box alignItems="center" classes="mg mt-8">
                  <Box direction="column" width={100} alignItems="center">
                    <Box classes="mg mb-8">첨부파일 실행 수</Box>
                    <Box
                      style={{
                        borderTop: "1px solid black",
                        paddingTop: "8px",
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      발송 메일 수
                    </Box>
                  </Box>
                  <Box classes="mg ml-8">x 100 = 수준 측정치 (%)</Box>
                </Box>
              </Box>
            )}

            {selectedClickTypes.includes(5) && (
              <Box direction="column" classes="mg mt-32">
                <Box classes="mg mb-12">
                  <Typography type="h3">
                    {`2.3.${++clickTypeIndex}. 신고율`}
                  </Typography>
                </Box>
                <Box alignItems="center" classes="mg mt-8">
                  <Box direction="column" width={80} alignItems="center">
                    <Box classes="mg mb-8">메일 신고 수</Box>
                    <Box
                      style={{
                        borderTop: "1px solid black",
                        paddingTop: "8px",
                        width: "80px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      발송 메일 수
                    </Box>
                  </Box>
                  <Box classes="mg ml-8">x 100 = 수준 측정치 (%)</Box>
                </Box>
              </Box>
            )}
          </Box>
        );

      case 11:
        let secondSecondIndex = 0;
        return reportData ? (
          <>
            <Box direction="column">
              <Box classes="mg mb-12 mt-32" id={`report__element__${11}`}>
                <Typography type="h2">{`${index} 훈련 시나리오`}</Typography>
              </Box>
              {selectedList.includes(12) &&
                renderPages(12, `${index}.${++secondSecondIndex}`)}
              {!selectedList.includes(12) &&
                selectedList.includes(13) &&
                renderPages(13, `${index}.${++secondSecondIndex}`)}
            </Box>
            {selectedList.includes(12) &&
              selectedList.includes(13) &&
              renderPages(13, `${index}.${++secondSecondIndex}`)}
          </>
        ) : (
          <></>
        );
      case 12:
        return reportData ? (
          <>
            <Box classes="mg mb-12">
              <Typography type="h3">{index} 훈련 시나리오</Typography>
            </Box>

            {reportData.scenarioList?.map((s, index) => (
              <table
                className={`report__table mg mb-12 ${
                  index !== (reportData.scenarioList?.length ?? 0) - 1 &&
                  `page-break-after`
                }`}
                key={index}
              >
                <colgroup>
                  <col style={{ width: "17%" }}></col>
                  <col style={{ width: "33%" }}></col>
                  <col style={{ width: "17%" }}></col>
                  <col style={{ width: "33%" }}></col>
                </colgroup>

                <tbody>
                  <tr>
                    <td className="report__background__color">제목</td>
                    <td
                      className="report__left report__scenario__td"
                      colSpan={3}
                    >
                      {s.subject}
                    </td>
                  </tr>
                  <tr>
                    <td className="report__background__color">발신자 명</td>
                    <td className="report__left report__scenario__td">
                      {s.sendName}
                    </td>
                    <td className="report__background__color">발신자 주소</td>
                    <td className="report__left report__scenario__td">
                      {s.sendEmail}
                    </td>
                  </tr>
                  <tr>
                    <td className="report__background__color">시나리오 설명</td>
                    <td
                      className="report__left report__scenario__td"
                      colSpan={3}
                      onDoubleClick={() =>
                        handleDoubleClick(
                          index,
                          s.summary ? String(s.summary) : ""
                        )
                      }
                    >
                      {editingCell === index ? (
                        <span className="input">
                          <textarea
                            value={inputValue}
                            onChange={(e: any) => {
                              setInputValue(e.target.value);
                            }}
                            autoFocus
                            onBlur={() => handleBlurOrEnter(index)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleBlurOrEnter(index);
                              }
                            }}
                            className={`inputArea`}
                            style={{ width: "80%" }}
                          />
                        </span>
                      ) : (
                        s.summary || "입력"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="report__background__color" colSpan={4}>
                      메일 본문
                    </td>
                  </tr>

                  <tr style={{ height: "700px" }}>
                    <td
                      colSpan={4}
                      className="report__left report__image"
                      contentEditable="true"
                      ref={(el) => (thumbRefs.current[index] = el)}
                    ></td>
                  </tr>
                </tbody>
                {/* {index !== (reportData.scenarioList?.length ?? 0) - 1 && (
                  <div style={{ breakAfter: "page" }}></div>
                )} */}
              </table>
            ))}
          </>
        ) : (
          <></>
        );
      case 13:
        return (
          <Box direction="column">
            <Box classes="mg mb-12">
              <Typography type="h3">{index} 훈련 안내 페이지</Typography>
            </Box>

            <table className="report__table">
              <colgroup>
                <col style={{ width: "100%" }}></col>
              </colgroup>

              <tbody>
                <tr>
                  <td className="report__background__color">
                    훈련 안내 페이지
                  </td>
                </tr>

                <tr style={{ height: "700px" }}>
                  <td
                    className="report__left report__image"
                    contentEditable="true"
                  ></td>
                </tr>
              </tbody>
            </table>
          </Box>
        );

      // 3
      case 14:
        const chart1 = document.getElementById("report__chart__1");
        const chart2 = document.getElementById("report__chart__2");
        const chart3 = document.getElementById("report__chart__3");

        const fixedWidth = 90 + 100;
        const totalColumns = 2 + selectedClickTypes.length;
        const dynamicWidth = `calc((100% - ${fixedWidth}px) / ${selectedClickTypes.length})`;

        const clickResults = [];

        if (selectedClickTypes.includes(1)) {
          clickResults.push(`메일 열람 ${reportData?.countData?.clickMail}건`);
        }
        if (selectedClickTypes.includes(2)) {
          clickResults.push(`링크 접속 ${reportData?.countData?.clickLink}건`);
        }
        if (selectedClickTypes.includes(3)) {
          clickResults.push(
            `개인정보 입력 ${reportData?.countData?.clickInfo}건`
          );
        }
        if (selectedClickTypes.includes(4)) {
          clickResults.push(
            `첨부파일 실행 ${reportData?.countData?.clickFile}건`
          );
        }

        const resultString =
          clickResults.length > 0
            ? clickResults.join(", ") + "이 확인되었습니다."
            : "";

        const reportMessage = `금번 실시한 악성메일 모의훈련은 훈련 시나리오
          ${reportData?.configDetail?.templateCount}종을 기반으로
          진행하였으며, 훈련 대상자는 총
          ${reportData?.countData?.mailCount}건의 메일을 수신
          받았습니다. 전체 훈련 메일
          ${reportData?.countData?.mailCount}건 발송 결과 
          ${resultString}`;

        return reportData ? (
          <Box direction="column">
            <Box classes="mg mb-12">
              <Typography type="h3">{index} 총평</Typography>
            </Box>
            <Box classes="mg mb-12">
              <Typography type="body2_rg" lineHeight="1.4">
                {reportMessage}
              </Typography>
            </Box>
            <table
              className="report__table"
              style={{ tableLayout: "fixed", width: "100%" }}
            >
              <colgroup>
                <col style={{ width: "90px" }}></col>
                <col style={{ width: "100px" }}></col>
                {Array.from({ length: selectedClickTypes.length }, (_, i) => (
                  <col style={{ width: dynamicWidth }}></col>
                ))}
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>훈련 대상자</th>
                  <th>메일 발송</th>
                  {selectedClickTypes.includes(1) && <th>메일 열람</th>}
                  {selectedClickTypes.includes(2) && <th>링크 접속</th>}
                  {selectedClickTypes.includes(4) && <th>첨부파일 실행</th>}
                  {selectedClickTypes.includes(5) && <th>신고</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="report__background__color report__scenario__td">
                    값(건)
                  </td>
                  <td className="report__scenario__td">
                    {reportData.countData?.userCount}
                  </td>
                  <td className="report__scenario__td">
                    {reportData.configDetail?.sendCnt}
                  </td>
                  {selectedClickTypes.includes(1) && (
                    <td className="report__scenario__td">
                      {reportData.countData?.clickMail}
                    </td>
                  )}
                  {selectedClickTypes.includes(2) && (
                    <td className="report__scenario__td">
                      {reportData.countData?.clickLink}
                    </td>
                  )}
                  {selectedClickTypes.includes(4) && (
                    <td className="report__scenario__td">
                      {reportData.countData?.clickFile}
                    </td>
                  )}
                  {selectedClickTypes.includes(5) && (
                    <td className="report__scenario__td">
                      {reportData.countData?.callResult}
                    </td>
                  )}
                </tr>
                <tr>
                  <td className="report__background__color report__scenario__td">
                    백분율
                  </td>
                  {/* <td>{reportData.configDetail?.sendMailCount / reportData.configDetail?.sendCnt}</td> */}
                  <td className="report__scenario__td"></td>
                  <td className="report__scenario__td">
                    {reportData.countData?.mailCount}
                  </td>
                  {selectedClickTypes.includes(1) && (
                    <td className="report__scenario__td">
                      {reportData.countData?.mailRatio}
                    </td>
                  )}
                  {selectedClickTypes.includes(2) && (
                    <td className="report__scenario__td">
                      {reportData.countData?.linkRatio}
                    </td>
                  )}
                  {selectedClickTypes.includes(4) && (
                    <td className="report__scenario__td">
                      {reportData.countData?.fileRatio}
                    </td>
                  )}
                  {selectedClickTypes.includes(5) && (
                    <td className="report__scenario__td">
                      {reportData.countData?.callResult &&
                        Math.floor(
                          ((reportData.countData?.callResult * 100) /
                            reportData.countData?.mailCount) *
                            100
                        ) / 100}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            <table className="report__table">
              <colgroup>
                <col style={{ width: "20%" }}></col>
                <col style={{ width: "80%" }}></col>
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={2}>전체 훈련 결과 통계</th>
                </tr>
              </thead>
              <tbody>
                {selectedClickTypes.includes(1) && (
                  <tr>
                    <td>메일 열람률</td>
                    <td
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      {/* <HighchartsReact
                        highcharts={Highcharts}
                        options={mailOptions}
                        key={chartKey}
                      /> */}
                      {chart1 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: chart1?.outerHTML,
                          }}
                        />
                      )}
                    </td>
                  </tr>
                )}
                {selectedClickTypes.includes(2) && (
                  <tr>
                    <td>링크 접속률</td>
                    <td
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      {chart2 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: chart2?.outerHTML,
                          }}
                        />
                      )}
                    </td>
                  </tr>
                )}
                {selectedClickTypes.includes(4) && (
                  <tr>
                    <td>첨부파일 실행률</td>
                    <td
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      {chart3 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: chart3?.outerHTML,
                          }}
                        />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
        ) : (
          <></>
        );

      case 15:
        return chunkedData ? (
          chunkedData.map((chunk, tableIndex) => {
            const startNumber =
              tableIndex === 0
                ? 1
                : chunkSizeFirst + (tableIndex - 1) * chunkSizeNext + 1;
            return tableIndex === 0 ? (
              // 첫 번째 테이블은 Box로 감싸기
              <Box
                direction="column"
                key={tableIndex}
                id={`report__element__${id}`}
              >
                <Box classes="mg mt-12 mb-12">
                  <Typography type="h2">{index} 훈련 결과 상세</Typography>
                </Box>
                <Box classes="mg mb-12">
                  <Typography type="body2_rg" lineHeight="1.4">
                    {`훈련 시나리오 대상자 ${reportData?.countData?.userCount}명 중, 
${reportData?.countData?.clickMailUser}명이 메일 열람, 
${reportData?.countData?.clickLinkUser}명이 링크 접속, 
${reportData?.countData?.clickInfoUser}명이 개인정보를 입력, 
${reportData?.countData?.clickFileUser}명이 첨부파일을 실행하였습니다.`}
                  </Typography>
                </Box>

                <table className="report__table">
                  <colgroup>
                    <col style={{ width: "70px" }} />
                    <col style={{ width: "90px" }} />
                    <col style={{ width: "80px" }} />
                    <col style={{ width: "90px" }} />
                    {selectedClickTypes.includes(1) && (
                      <col style={{ width: "*" }} />
                    )}
                    {selectedClickTypes.includes(2) && (
                      <col style={{ width: "*" }} />
                    )}
                    {selectedClickTypes.includes(3) && (
                      <col style={{ width: "*" }} />
                    )}
                    {selectedClickTypes.includes(4) && (
                      <col style={{ width: "*" }} />
                    )}
                  </colgroup>

                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>부서</th>
                      <th>이름</th>
                      <th>훈련 시나리오</th>
                      {selectedClickTypes.includes(1) && <th>메일 열람</th>}
                      {selectedClickTypes.includes(2) && <th>링크 접속</th>}
                      {selectedClickTypes.includes(3) && <th>개인정보 입력</th>}
                      {selectedClickTypes.includes(4) && <th>첨부파일 실행</th>}
                    </tr>
                  </thead>

                  <tbody id="report__history__table">
                    {chunk.map((r, index) => (
                      <tr key={`${tableIndex}-${index}`}>
                        <td>{startNumber + index}</td>
                        <td>{r.deptName}</td>
                        <td>{r.userName}</td>
                        <td>{r.templateName}</td>
                        {selectedClickTypes.includes(1) && (
                          <td>{r.click1 ? "O" : "X"}</td>
                        )}
                        {selectedClickTypes.includes(2) && (
                          <td>{r.click2 ? "O" : "X"}</td>
                        )}
                        {selectedClickTypes.includes(3) && (
                          <td>{r.click3 ? "O" : "X"}</td>
                        )}
                        {selectedClickTypes.includes(4) && (
                          <td>{r.click4 ? "O" : "X"}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            ) : (
              // 첫 번째 이후의 테이블은 Box 없이 바로 렌더링
              <table className="report__table" key={tableIndex}>
                <colgroup>
                  <col style={{ width: "70px" }} />
                  <col style={{ width: "90px" }} />
                  <col style={{ width: "80px" }} />
                  <col style={{ width: "90px" }} />
                  {selectedClickTypes.includes(1) && (
                    <col style={{ width: "*" }} />
                  )}
                  {selectedClickTypes.includes(2) && (
                    <col style={{ width: "*" }} />
                  )}
                  {selectedClickTypes.includes(3) && (
                    <col style={{ width: "*" }} />
                  )}
                  {selectedClickTypes.includes(4) && (
                    <col style={{ width: "*" }} />
                  )}
                </colgroup>

                <tbody id="report__history__table">
                  {chunk.map((r, index) => (
                    <tr key={`${tableIndex}-${index}`}>
                      <td>{startNumber + index}</td>
                      <td>{r.deptName}</td>
                      <td>{r.userName}</td>
                      <td>{r.templateName}</td>
                      {selectedClickTypes.includes(1) && (
                        <td>{r.click1 ? "O" : "X"}</td>
                      )}
                      {selectedClickTypes.includes(2) && (
                        <td>{r.click2 ? "O" : "X"}</td>
                      )}
                      {selectedClickTypes.includes(3) && (
                        <td>{r.click3 ? "O" : "X"}</td>
                      )}
                      {selectedClickTypes.includes(4) && (
                        <td>{r.click4 ? "O" : "X"}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            );
          })
        ) : (
          <></>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <ModalNew
        width={1150}
        bodyHeight={720}
        open={isOpen}
        onClose={onClose}
        footer={
          <Box alignItems="flex-end">
            <Button
              onClick={onClose}
              size="lg"
              label={t("common.button.check")}
              btnStyle="fill"
              type="primary"
            />
            {/* <Button
              onClick={onDownload}
              size="lg"
              classes="mg ml-8"
              label="내려받기"
              btnStyle="fill"
              type="primary"
              state={downloadTypes.length > 0 ? "default" : "disable"}
            /> */}
          </Box>
        }
        // isDivider={false}
        body={
          <>
            <Box direction="column">
              {isDownloading && (
                <Box classes="report__dimmed">
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                  >
                    <Spinner />
                    <Typography type="body2_rg" classes="mg mt-16">
                      다운로드 중...
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box justifyContent="space-between">
                <Box classes="mg mb-16" width={"50%"} direction="column">
                  <Typography type="subtitle2" classes="mg mr-32" width="100px">
                    훈련 유형 선택
                  </Typography>
                  <Box classes="mg mt-16 mb-16">
                    {clickTypes.map((c, index) => (
                      <Checkbox
                        key={c.id}
                        label={c.text}
                        check={selectedClickTypes.includes(c.id)}
                        onClick={() => {
                          if (selectedClickTypes.includes(c.id)) {
                            setSelectedClickTypes((prev) =>
                              prev.filter((p) => p !== c.id)
                            );
                          } else {
                            setSelectedClickTypes((prev) => [...prev, c.id]);
                          }
                        }}
                        classes={`mg ${index !== 0 && `ml-24`}`}
                      />
                    ))}
                  </Box>
                </Box>
                <Box width={"50%"} direction="column">
                  <Typography type="subtitle2" classes="mg mr-32" width="150px">
                    다운로드 파일 유형
                  </Typography>
                  <Box classes="mg mt-16 mb-24">
                    <Button
                      onClick={onDownloadExcel}
                      width={150}
                      label="엑셀로 내려받기"
                      btnStyle="fill"
                      type="secondary"
                    />
                    <Button
                      onClick={onDownloadPdf}
                      width={150}
                      classes="mg ml-8"
                      label="PDF로 내려받기"
                      btnStyle="fill"
                      type="secondary"
                    />
                    {/* <Checkbox
                      key={0}
                      label="엑셀로 내려받기"
                      check={downloadTypes.includes(0)}
                      onClick={() => {
                        if (downloadTypes.includes(0)) {
                          setDownloadTypes((prev) =>
                            prev.filter((p) => p !== 0)
                          );
                        } else {
                          setDownloadTypes((prev) => [...prev, 0]);
                        }
                      }}
                    /> */}

                    {/* <Checkbox
                      key={1}
                      label="PDF로 내려받기"
                      check={downloadTypes.includes(1)}
                      onClick={() => {
                        if (downloadTypes.includes(1)) {
                          setDownloadTypes((prev) =>
                            prev.filter((p) => p !== 1)
                          );
                        } else {
                          setDownloadTypes((prev) => [...prev, 1]);
                        }
                      }}
                      classes="mg ml-24"
                    /> */}
                  </Box>
                </Box>
              </Box>
              <Divider direction="row" classes="mg mb-32" />
              {reportData ? (
                <Box>
                  <Box height={"100%"}>
                    <Box width={240} direction="column">
                      <Box>
                        <Typography type="subtitle2">목차</Typography>
                      </Box>
                      <Box
                        direction="column"
                        alignItems="flex-start"
                        width={"100%"}
                        classes="mg mt-8"
                      >
                        {renderList(listTypes)}
                      </Box>
                    </Box>
                    <Divider direction="col" classes="mg mr-48" />
                    <Box
                      width={800}
                      classes="pd pb-16"
                      direction="column"
                      height={570}
                    >
                      <Box
                        direction="column"
                        classes="mg mr-48 overflow-scroll"
                        id="report__container"
                      >
                        {viewPage === 0 && renderPages(0)}
                        {viewPage === 1 && renderPages(1)}
                        {viewPage === 2 && renderPages(2)}
                        {viewPage === 3 && renderPages(3)}
                      </Box>
                      {/* {selectedList.includes(0) && renderPages(0)}
                  {renderPages(1)}
                  {renderPages(2)}
                  {renderPages(3)} */}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <div>내역 없음</div>
              )}
            </Box>
          </>
        }
        title={"결과 보고서 내려받기"}
      />

      <div className="hieee" style={{ position: "absolute", left: "-9999px" }}>
        {reportData && (
          <>
            <div id="report__chart__1">
              <HighchartsReact
                highcharts={Highcharts}
                options={mailOptions}
                key={chartKey}
              />
            </div>
            <div id="report__chart__2">
              <HighchartsReact
                highcharts={Highcharts}
                options={linkOptions}
                key={chartKey2}
              />
            </div>
            <div id="report__chart__3">
              <HighchartsReact
                highcharts={Highcharts}
                options={fileOptions}
                key={chartKey3}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ReportModal;
