import {
  Box,
  Button,
  getColor,
  Icons,
  InputField,
  Modal,
  Typography,
} from "fds";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserInfoFromLocalStorage } from "../../shared/utils/auth";

interface PwdModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  changePwdInfo: {
    nowPwd: string;
    newPwd: string;
    checkPwd: string;
  };
  setChangePwdInfo: React.Dispatch<
    React.SetStateAction<{
      nowPwd: string;
      newPwd: string;
      checkPwd: string;
    }>
  >;
  errorType: {
    isError: boolean;
    errorTarget: string;
    errorMsg: string;
  };
  changeErrorType: React.Dispatch<
    React.SetStateAction<{
      isError: boolean;
      errorTarget: string;
      errorMsg: string;
    }>
  >;
}
const PwdModal: React.FC<PwdModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  changePwdInfo,
  setChangePwdInfo,
  errorType,
  changeErrorType,
}: PwdModalProps) => {
  const { t } = useTranslation();

  const userInfo = getUserInfoFromLocalStorage();

  const [showPassword, changeShowPassword] = useState({
    original: false,
    new: false,
    check: false,
  });

  return (
    <Modal
      open={isOpen}
      width={540}
      title={t("common.auth.changePwd")}
      onClose={onClose}
      footer={
        <Box width={"100%"} justifyContent="flex-end">
          <Button
            label={t("common.button.cancel")}
            type="secondary"
            btnStyle="fill"
            onClick={onClose}
          />
          <Button
            label={t("common.button.save")}
            type="primary"
            btnStyle="fill"
            classes="mg ml-8"
            state={
              changePwdInfo.nowPwd === "" ||
              changePwdInfo.newPwd === "" ||
              changePwdInfo.checkPwd === ""
                ? "disable"
                : "default"
            }
            onClick={onSubmit}
          />
        </Box>
      }
      body={
        <Box direction="column" width={"100%"}>
          <Typography type="subtitle2">{t("user.userEmail")}</Typography>
          <InputField
            classes="mg mt-4"
            placeholder={t("login.loginEmailPlaceholder")}
            value={userInfo?.email ?? ""}
            isError={errorType.errorTarget === "email"}
            helpMessage={
              errorType.errorTarget === "email" ? errorType.errorMsg : ""
            }
            disabled
            onChange={(e: any) =>
              setChangePwdInfo((prev) => ({ ...prev, email: e.target.value }))
            }
            size="md"
          />
          <Typography type="subtitle2" classes="mg mt-32">
            {t("account.nowPwd")}
          </Typography>
          <InputField
            classes="mg mt-4"
            size="md"
            placeholder={t("account.msg.nowPwdPlaceholder")}
            value={changePwdInfo.nowPwd}
            isError={errorType.errorTarget === "nowPwdNotMatch"}
            helpMessage={
              errorType.errorTarget === "nowPwdNotMatch"
                ? errorType.errorMsg
                : ""
            }
            onChange={(e: any) =>
              setChangePwdInfo((prev) => ({
                ...prev,
                nowPwd: e.target.value,
              }))
            }
            type={showPassword.original ? "text" : "password"}
            trailingIcon={
              <Button
                btnStyle="link"
                size="min"
                onClick={() =>
                  changeShowPassword({
                    ...showPassword,
                    original: !showPassword.original,
                  })
                }
                leadingIcon={
                  <Icons
                    variant="line"
                    label={"preview"}
                    isOn={!showPassword.original}
                    stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                  />
                }
                classes="showPassword"
              />
            }
          />
          <Typography type="subtitle2" classes="mg mt-32">
            {t("account.newPwd")}
          </Typography>
          <InputField
            classes="mg mt-4"
            size="md"
            placeholder={t("account.msg.newPwdPlaceholder")}
            value={changePwdInfo.newPwd}
            isError={
              errorType.errorTarget === "findPW-name" ||
              errorType.errorTarget === "name"
            }
            helpMessage={
              errorType.errorTarget === "findPW-name" ||
              errorType.errorTarget === "name"
                ? errorType.errorMsg
                : ""
            }
            onChange={(e: any) =>
              setChangePwdInfo((prev) => ({
                ...prev,
                newPwd: e.target.value,
              }))
            }
            type={showPassword.new ? "text" : "password"}
            trailingIcon={
              <Button
                btnStyle="link"
                size="min"
                onClick={() =>
                  changeShowPassword({
                    ...showPassword,
                    new: !showPassword.new,
                  })
                }
                leadingIcon={
                  <Icons
                    variant="line"
                    label={"preview"}
                    isOn={!showPassword.new}
                    stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                  />
                }
                classes="showPassword"
              />
            }
          />
          <Typography type="subtitle2" classes="mg mt-32">
            {t("account.confirmPwd")}
          </Typography>
          <InputField
            size="md"
            classes="mg mt-4"
            placeholder={t("account.msg.confirmPwdPlaceholder")}
            value={changePwdInfo.checkPwd}
            isError={errorType.errorTarget === "pwdNotMatch"}
            helpMessage={
              errorType.errorTarget === "pwdNotMatch" ? errorType.errorMsg : ""
            }
            onChange={(e: any) =>
              setChangePwdInfo((prev) => ({
                ...prev,
                checkPwd: e.target.value,
              }))
            }
            type={showPassword.check ? "text" : "password"}
            trailingIcon={
              <Button
                btnStyle="link"
                size="min"
                onClick={() =>
                  changeShowPassword({
                    ...showPassword,
                    check: !showPassword.check,
                  })
                }
                leadingIcon={
                  <Icons
                    variant="line"
                    label={"preview"}
                    isOn={!showPassword.check}
                    stroke={getColor("COLOR_ICON_INTERACTIVE_TERTIARY")}
                  />
                }
                classes="showPassword"
              />
            }
          />
        </Box>
      }
    />
  );
};

export default PwdModal;
