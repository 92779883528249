import "../../../shared/style/filters.scss";
import {
  getColor,
  Icons as IconsNew,
  Typography as TypographyNew,
  DropdownItem as DropdownItemNew,
  Button as ButtonNew,
  InputField,
  Box as BoxNew,
  Tag as TagNew,
  Tooltip as TooltipNew,
  Table as TableNew,
  Chip,
  Dropdown as DropdownNew,
  useToast,
  CalendarRange,
  Modal,
} from "fds";

import { useEffect, useState } from "react";
import { QuizContentDTO, QuizContentReqDTO } from "../../../types/Quiz";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import TitlePath from "../../../component/TitlePath";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import NoDashboardImage from "../../../shared/image/NoDashboardImage";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import { FilterBtnActivityCheckUtils } from "../../../shared/utils/FilterBtnActivityCheckUtils";
import useTableHoverBoxClick from "../../../hooks/useTableHoverBoxClick";
import { useElementPosition } from "../../../hooks/useElementPosition";

import "../../../shared/style/filters.scss";
import HoverClickBox from "../../../component/Table/HoverClickBox";
import { TABLE_HIGHLIGHT_TIMED_OUT } from "../../../shared/constant";
import useTimedReset from "../../../hooks/useTimedReset";
import useNavigateState from "../../../hooks/useNavigateState";
import { NavigateStateKeys } from "../../../hooks/NavigateStateKeys";
import { useTableOrder } from "../../../hooks/useTableOrder";
import utilsCommon from "../../../shared/utils/common";
import dayjs from "dayjs";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";

enum ColumnsTypeToOrder {
  quizName = "quizName",
  quizUpdateDate = "quizUpdateDate",
}

const Quiz: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [quizList, setQuizList] = useState<QuizContentDTO[]>([]);
  const [totalQuizCount, setTotalQuizCount] = useState<number>(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const localCustomerCode = getLocalCustomerCode();
  const navigate = useNavigate();
  const toast = useToast();
  const [buttonClick, setButtonClick] = useState(0);

  const { getQuizContentList, deleteQuizContent, quizState, syncQuizContent } =
    useQuizStore();

  const [isSyncModalOpen, setIsSyncModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<
    "USED" | "NOT_USED" | null
  >(null);
  const [isUpdateModalopen, setIsUpdateModalOpen] = useState<number | null>(
    null
  );
  const [selectedQuizes, setSelectedQuizes] = useState<number[]>();
  const checkboxGroup = useCheckboxGroup<number | undefined>();
  const [syncQuizIds, setSyncedQuizIds] = useTimedReset<string[]>(
    [],
    TABLE_HIGHLIGHT_TIMED_OUT
  );

  const [tempQuizName, setTempQuizName] = useState("");
  const [tempQuizNames, setTempQuizNames] = useState<string[]>([]);
  const [tempCategoryName, setTempCategoryName] = useState("");
  const [tempCategorys, setTempCategorys] = useState<string[]>([]);

  const [filterData, setFilterData] = useState<QuizContentReqDTO>(
    {} as QuizContentReqDTO
  );

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const initialSelectedPeriod = {
    value: "ALL",
    start: new Date().valueOf(),
    end: new Date().valueOf(),
  };
  const [selectedPeriod, changeSelectedPeriod] = useState(
    initialSelectedPeriod
  );

  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        setFilterData({
          ...filterData,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        });
    },
  });

  const { navigateState } = useNavigateState(
    NavigateStateKeys.fromQuizContentCreate
  );

  useEffect(() => {
    if (filterData) {
      getQuizContentList({
        ...filterData,
        updateStartDate:
          selectedPeriod.value === "ALL"
            ? undefined
            : Math.trunc(selectedPeriod.start / 1000),
        updateEndDate:
          selectedPeriod.value === "ALL"
            ? undefined
            : Math.trunc(selectedPeriod.end / 1000),
      });
    }
  }, [filterData, selectedPeriod]);

  useEffect(() => {
    getQuizContentList({});
  }, []);

  useEffect(() => {
    if (quizState.quizContentList) {
      setQuizList(quizState.quizContentList.list);
      setTotalQuizCount(quizState.quizContentList.total);
    }
  }, [quizState.quizContentList]);

  const resetFilter = () => {
    setFilterData({} as QuizContentReqDTO);
    setTempCategorys([]);
    setTempQuizNames([]);
  };

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const {
    tableRef,
    hoverBoxClickedRow,
    hoverBoxNewCoord,
    onOutsideClick,
    handleHoverBoxClick,
  } = useTableHoverBoxClick<QuizContentDTO>({
    width: 116,
    getDynamicHeight: (row) =>
      checkIfDataEditableByCommonId(row.commonId) ? 147 : 58,
  });

  const onDelete = async () => {
    if (selectedQuizes) {
      const resp: any = await deleteQuizContent({
        quizIds: selectedQuizes,
      });
      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("quiz.msg.deleteQuizContentSuccess"),
          "success"
        );
        checkboxGroup.handleAllClick(
          checkboxGroup.selected.filter(
            (num) => num && !selectedQuizes.includes(num)
          )
        );
        setIsDeleteModalOpen(null);
      } else {
        toast.toastMsg(
          nanoid(),
          t("template.msg.templateDuplicatedTemplateMsg"),
          "error"
        );
      }
    }
  };

  const onSync = async () => {
    const resp: any = await syncQuizContent();

    if (resp.payload && resp.payload.code === "SUCCESS") {
      const quizIds = resp.payload.data?.map((quiz: QuizContentDTO) =>
        quiz.quizId?.toString()
      );
      setSyncedQuizIds(quizIds);

      toast.toastMsg(nanoid(), t("quiz.msg.syncSuccess"), "success");
      getQuizContentList({});
    } else {
      toast.toastMsg(nanoid(), t("sync.msg.failMsg"), "error");
    }

    setIsSyncModalOpen(false);
  };

  const changeDateFilter = (type: string) => {
    if (type === "ALL") {
      changeSelectedPeriod({
        value: type,
        start: new Date().valueOf(),
        end: new Date().valueOf(),
      });
    } else if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(
            type === "PERIOD_3MONTH" ? 3 : type === "PERIOD_6MONTH" ? 6 : 12,
            "month"
          )
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  function transformQuizList(quizList: QuizContentDTO[]) {
    const quizListTransformed = [];
    for (let i = 0; i < quizList.length; i++) {
      const quizCur = quizList[i];
      const localCustomerCode = getLocalCustomerCode();
      // 구분
      const dataIsCommon = localCustomerCode ? (
        quizCur.commonId ? (
          <TagNew
            as={"p"}
            type={"box"}
            text={t("template.public")}
            textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
            backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
          />
        ) : (
          <TagNew
            as={"p"}
            type={"box"}
            text={t("template.private")}
            textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
            backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
          />
        )
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
        />
      );

      // 퀴즈이름
      const dataQuizName = (
        <BoxNew>
          <TooltipNew
            message={quizCur.quizName}
            maxWidth={"100%"}
            place={"top"}
            children={
              <TypographyNew
                ellipsis
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {quizCur.quizName}
              </TypographyNew>
            }
          ></TooltipNew>
        </BoxNew>
      );

      // 카테고리
      const dataCategory = quizCur.categorys && (
        <CategoryTagList categorys={quizCur.categorys} />
      );

      // 수정 일자
      const dataUpdateDate = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="tnum"
        >
          {quizCur.quizUpdateDate
            ? utilsCommon.getFullDateStr(quizCur.quizUpdateDate)
            : utilsCommon.getFullDateStr(quizCur.quizEntryDate)}
        </TypographyNew>
      );
      // 유형
      const dataQuizType = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {quizCur.quizType === 1
            ? t("quiz.label.formatTF")
            : t("quiz.label.formatChoice")}{" "}
        </TypographyNew>
      );

      const hoverBtn = (
        <ButtonNew
          classes="hover-btn"
          btnStyle={"icon"}
          leadingIcon={<IconsNew variant="line" label="moreVertical" />}
          type={"primary"}
          size={"sm"}
          state={"hover"}
          onClick={(event: MouseEvent) => handleHoverBoxClick(event, quizCur)}
        />
      );

      const quizTransformed = {
        data: [
          { columnText: "" },
          { columnText: dataIsCommon },
          { columnText: dataQuizName },
          { columnText: dataCategory },
          { columnText: dataUpdateDate },
          { columnText: dataQuizType },
        ],
        id: String(quizCur.quizId),
        hover: hoverBtn,
        checkBoxClick: () => {
          checkboxGroup.handleClick(quizCur.quizId);
        },
        clickAction: (e: any) => {
          if (!e.target.closest(".hover-btn")) {
            navigate(
              `${process.env.PUBLIC_URL}/quiz/content/detail?quiz_id=${quizCur.quizId}`
            );
          }
        },
      };

      quizListTransformed.push(quizTransformed);
    }

    return quizListTransformed;
  }

  function checkTableRenderStatus() {
    // TODO: 로딩 상태 처리 필요함 (progressState.count는 0인 경우 fetching 전인지 후인지 구분 불가)
    // quizList 초기값을 null로 구분 후 타입카드 추가해도 setQuizList가 초기회되는 의존값이 변경되어 여러번 초기화... 우선 보류
    const isFilterApplied =
      JSON.stringify(filterData) !== JSON.stringify({} as QuizContentReqDTO);
    const hasQuizList = quizList && quizList.length > 0;

    // 적용된 필터가 있으면서 퀴즈 리스트가 없다면 -> 검색 결과 없음 (1)
    if (!hasQuizList && isFilterApplied) return 1;
    // 적용된 필터가 없으면서 퀴즈 리스트가 없다면 -> 표시할 항목 없음 (2)
    else if (!hasQuizList && !isFilterApplied) return 2;
    // 그 외 (0)
    else return 0;
  }

  function renderTableSwitch() {
    switch (checkTableRenderStatus()) {
      case 1:
        return noFilteredResult;
      case 2:
        return noQuizCreated;
      default:
        return <></>;
    }
  }

  const header = (
    <BoxNew classes="mg mt-32" justifyContent="space-between" width={"100%"}>
      <BoxNew alignItems="center">
        <TypographyNew
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("quiz.label.quiz")}
        </TypographyNew>
        <TypographyNew
          type="subtitle2"
          classes="mg ml-8"
          exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
        >
          {totalQuizCount}
        </TypographyNew>
      </BoxNew>
      <BoxNew>
        <BoxNew>
          <ButtonNew
            btnStyle={"link"}
            size={"lg"}
            classes="mg mr-4"
            state={checkboxGroup.selected.length > 0 ? "default" : "disable"}
            leadingIcon={
              <IconsNew
                variant="line"
                label="delete"
                stroke={getColor("COLOR_ICON_PRIMARY")}
              />
            }
            onClick={() => {
              let commonFlag = false;
              let usedFlag = false;
              checkboxGroup.selected.map((c) => {
                const item: QuizContentDTO[] = quizList.filter(
                  (q) => q.quizId === c
                );
                item.forEach((i) => {
                  if (i.commonId) {
                    commonFlag = true;
                  }
                  if (i.useCourse) {
                    usedFlag = true;
                  }
                });
              });
              if (commonFlag) {
                toast.toastMsg(
                  nanoid(),
                  t("template.msg.cannotDeletePublicScenarioMsg"),
                  "error"
                );
              } else if (usedFlag) {
                setSelectedQuizes(checkboxGroup.selected as number[]);
                setIsDeleteModalOpen("USED");
              } else {
                setSelectedQuizes(checkboxGroup.selected as number[]);
                setIsDeleteModalOpen("NOT_USED");
              }
            }}
            label={t("common.button.delete")}
          />
        </BoxNew>
        <ButtonNew
          leadingIcon={
            <IconsNew
              variant="line"
              label="filter"
              stroke={getColor("COLOR_ICON_PRIMARY")}
              size={20}
            />
          }
          btnStyle={"link"}
          type={"primary"}
          onClick={() => setIsFilterOpen((prev) => !prev)}
          size={"lg"}
          label={t("common.list.filter")}
        />
      </BoxNew>
    </BoxNew>
  );

  const filters = (
    <BoxNew
      classes="pd pt-12 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      {/* 구분  */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.type") + " · " + t("quiz.label.all")}
        value={
          filterData.isCommon === undefined
            ? ""
            : filterData.isCommon
            ? t("quiz.table.type") + " · " + t("quiz.label.public")
            : t("quiz.table.type") + " · " + t("quiz.label.private")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={filterData.isCommon === undefined}
          checkColor={filterData.isCommon === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, isCommon: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItemNew
          checked={filterData.isCommon === false}
          checkColor={filterData.isCommon === false}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, isCommon: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("template.private")}
          type={"check"}
        />
        <DropdownItemNew
          checked={filterData.isCommon}
          checkColor={filterData.isCommon}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, isCommon: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("template.public")}
          type={"check"}
        />
      </DropdownNew>

      {/* quizName */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.quizName") + " · " + t("quiz.label.all")}
        value={
          filterData.quizNames === undefined ||
          filterData.quizNames.length === 0
            ? ""
            : t("quiz.table.quizName") + " · " + filterData.quizNames.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.table.quizName")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempQuizName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("quiz.label.quizNameHolder")}
              onChange={(e: any) => setTempQuizName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (tempQuizNames && tempQuizNames.includes(e.target.value)) {
                    return;
                  }
                  setTempQuizNames((prev) => [...prev, tempQuizName]);
                  setTempQuizName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempQuizNames &&
              tempQuizNames.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempQuizNames((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempQuizName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filterData.quizNames || [],
                    tempQuizNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempQuizName !== "") {
                    setTempQuizNames((prev) => [...prev, tempQuizName]);
                    setFilterData((prev) => ({
                      ...prev,
                      quizNames: [...tempQuizNames, tempQuizName],
                    }));
                  } else {
                    setFilterData((prev) => ({
                      ...prev,
                      quizNames: tempQuizNames,
                    }));
                  }
                  setTempQuizName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>

      {/* category */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.category") + " · " + t("quiz.label.all")}
        value={
          filterData.categorys === undefined ||
          filterData.categorys.length === 0
            ? ""
            : t("quiz.table.category") + " · " + filterData.categorys.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.table.category")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempCategoryName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("template.msg.categoryPlaceholder")}
              onChange={(e) => setTempCategoryName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (tempCategorys && tempCategorys.includes(e.target.value)) {
                    return;
                  }
                  setTempCategorys((prev) => [...prev, tempCategoryName]);
                  setTempCategoryName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempCategorys &&
              tempCategorys.map((item) => (
                <Chip
                  key={item}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempCategorys((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  setTempCategoryName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filterData.categorys || [],
                    tempCategorys
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempCategoryName !== "") {
                    setTempCategorys((prev) => [...prev, tempCategoryName]);
                    setFilterData((prev) => ({
                      ...prev,
                      categorys: [...tempCategorys, tempCategoryName],
                    }));
                  } else {
                    setFilterData((prev) => ({
                      ...prev,
                      categorys: tempCategorys,
                    }));
                  }
                  setTempCategoryName("");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>

      {/* 등록 일시 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("common.time.updateDate")}
        value={`${
          selectedPeriod.value === "PERIOD_3MONTH"
            ? t("report.stats.reportRecent3Month")
            : selectedPeriod.value === "PERIOD_6MONTH"
            ? t("report.stats.reportRecent6Month")
            : selectedPeriod.value === "PERIOD_1YEAR"
            ? t("report.stats.reportRecent1Year")
            : selectedPeriod.value === "ALL"
            ? t("common.auth.all")
            : `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
        }`}
        defaultValue={"ALL"}
        selectValue={selectedPeriod.value}
        showValue
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={selectedPeriod.value === "ALL"}
          checkColor={selectedPeriod.value === "ALL"}
          onClick={() => {
            changeDateFilter("ALL");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_3MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_3MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent3Month")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_6MONTH"}
          checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
          onClick={() => {
            changeDateFilter("PERIOD_6MONTH");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent6Month")}
          type={"check"}
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_1YEAR"}
          checkColor={selectedPeriod.value === "PERIOD_1YEAR"}
          onClick={() => {
            changeDateFilter("PERIOD_1YEAR");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("report.stats.reportRecent1Year")}
          type={"check"}
        />
        <DropdownItemNew
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItemNew
          checked={selectedPeriod.value === "PERIOD_CUSTOM"}
          checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
          onClick={() => {
            changeDateFilter("PERIOD_CUSTOM");
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </DropdownNew>

      {/* type */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("quiz.table.formal") + " · " + t("quiz.label.all")}
        value={
          filterData.quizType === undefined
            ? ""
            : filterData.quizType === 1
            ? t("quiz.table.formal") + " · " + t("quiz.label.formatTF")
            : t("quiz.table.formal") + " · " + t("quiz.label.formatChoice")
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        <DropdownItemNew
          checked={filterData.quizType === undefined}
          checkColor={filterData.quizType === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, quizType: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          type={"check"}
          label={t("common.auth.all")}
        />
        <DropdownItemNew
          checked={filterData.quizType === 1}
          checkColor={filterData.quizType === 1}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, quizType: 1 }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          type={"check"}
          label={t("quiz.label.formatTF")}
        />
        <DropdownItemNew
          checked={filterData.quizType === 2}
          checkColor={filterData.quizType === 2}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, quizType: 2 }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          type={"check"}
          label={t("quiz.label.formatChoice")}
        />
      </DropdownNew>

      <ButtonNew
        btnStyle={"icon"}
        state={
          FilterBtnActivityCheckUtils.checkIfResetFilterBtnActive(filterData)
            ? "default"
            : "disable"
        }
        size={"md"}
        onClick={resetFilter}
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
      />
    </BoxNew>
  );

  const noFilteredResult = (
    <BoxNew
      width={"100%"}
      direction="column"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoSearchResultImage width={200} />
      <TypographyNew
        type="h3"
        classes="mg mt-36"
        exactColor={getColor("COLOR_TEXT_PRIMARY")}
      >
        {t("quiz.label.noSearch")}
      </TypographyNew>
      <TypographyNew
        type="body2_rg_long"
        classes="mg mt-8"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
      >
        {t("quiz.msg.noFilterQuizContent")}
      </TypographyNew>
      <ButtonNew
        btnStyle={"fill"}
        state={"default"}
        size={"lg"}
        classes="mg mt-20"
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
          />
        }
        onClick={resetFilter}
        label={t("common.button.resetFilter")}
      />
    </BoxNew>
  );

  const noQuizCreated = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoDashboardImage />
      <TypographyNew
        classes="mg mt-24 mb-8 "
        type="h3"
        exactColor={getColor("COLOR_TEXT_PRIMARY")}
      >
        {t("quiz.msg.noItems")}
      </TypographyNew>
      <TypographyNew
        type="body2_rg_long"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mt-8"
      >
        {t("quiz.msg.noQuizContent")}
      </TypographyNew>
      <TypographyNew
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mb-24"
        fontSize={14}
      >
        {t("quiz.msg.addQuizContent")}
      </TypographyNew>
      <ButtonNew
        btnStyle="fill"
        state={"default"}
        size={"lg"}
        leadingIcon={
          <IconsNew
            variant="line"
            label="plusCircle"
            stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
          />
        }
        onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/content/write`)}
        label={t("template.insert")}
      />
    </BoxNew>
  );

  return (
    <BoxNew classes="main" ref={tableContainerRef}>
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizContent")]} />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {[t("menu.quizContent")]}
          </TypographyNew>
        </BoxNew>
        <BoxNew>
          {localCustomerCode && (
            <ButtonNew
              btnStyle={"fill"}
              label={t("sync.updateSimple")}
              leadingIcon={
                <IconsNew
                  variant="line"
                  label="refresh"
                  stroke={getColor("COLOR_ICON_PRIMARY")}
                />
              }
              state={"default"}
              size={"lg"}
              type={"secondary"}
              onClick={() => setIsSyncModalOpen(true)}
              classes="mg mr-10"
            />
          )}
          <ButtonNew
            btnStyle={"fill"}
            label={t("common.button.add")}
            leadingIcon={
              <IconsNew
                variant="line"
                label="plusCircle"
                stroke={getColor("COLOR_ICON_PRIMARY")}
              />
            }
            state={"default"}
            size={"lg"}
            type={"secondary"}
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/quiz/content/write`)
            }
          />
        </BoxNew>
      </BoxNew>
      <BoxNew width="100%">
        <BoxNew direction="column" width="100%">
          {header}
          {isFilterOpen && filters}
          <BoxNew
            direction="column"
            classes="pd pt-12 flex-1"
            ref={tableRef}
            style={{
              pointerEvents: hoverBoxClickedRow.quizId ? "none" : undefined,
            }}
          >
            <TableNew
              classes="table-chip-hover"
              border="none"
              clickIds={checkboxGroup.selected.map((quizId) => String(quizId))}
              highlightIds={navigateState?.length ? navigateState : syncQuizIds}
              highlightTimeout={3000}
              totalCount={totalQuizCount}
              columnHeight="md"
              data={transformQuizList(quizList)}
              endReached={() => {}}
              checkBoxClick={() =>
                checkboxGroup.handleAllClick(
                  checkboxGroup.selected.length === quizList.length
                    ? []
                    : quizList.map((q) => q.quizId)
                )
              }
              header={[
                {
                  headerText: "",
                  width: "12px",
                },
                {
                  headerText: t("quiz.table.type"),
                  width: "72px",
                },
                {
                  headerText: t("quiz.table.quizName"),
                  width: "360px",
                  ...getOrderRenderProps(ColumnsTypeToOrder.quizName),
                },
                {
                  headerText: t("quiz.table.category"),
                  width: "calc(100% - 780px)",
                },
                {
                  headerText: t("common.time.updateDate"),
                  width: "170px",
                  ...getOrderRenderProps(ColumnsTypeToOrder.quizUpdateDate),
                },
                {
                  headerText: t("quiz.table.formal"),
                  width: "100px",
                },
              ]}
              width={"100%"}
              height={
                checkTableRenderStatus() === 0
                  ? `${
                      // 테이블 컨테이너 내부 고정 요소 높이 수동 차감
                      tableContainerPosition.height -
                      (245 + (isFilterOpen ? 56 : 0))
                    }px`
                  : "0px"
              }
            />
            {renderTableSwitch()}
            <BoxNew>
              <HoverClickBox
                isDisplayed={!!hoverBoxClickedRow.quizId}
                hoverBoxNewCoord={hoverBoxNewCoord}
                onOutsideClick={onOutsideClick}
                onClickDetail={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/quiz/content/detail?quiz_id=${hoverBoxClickedRow.quizId}`
                  )
                }
                onClickUpdate={
                  checkIfDataEditableByCommonId(hoverBoxClickedRow.commonId)
                    ? () =>
                        hoverBoxClickedRow.useCourse
                          ? setIsUpdateModalOpen(
                              hoverBoxClickedRow.quizId ?? null
                            )
                          : navigate(
                              `${process.env.PUBLIC_URL}/quiz/content/write?quiz_id=${hoverBoxClickedRow.quizId}`
                            )
                    : undefined
                }
                onClickDelete={
                  checkIfDataEditableByCommonId(hoverBoxClickedRow.commonId)
                    ? (e: MouseEvent) => {
                        hoverBoxClickedRow.quizId &&
                          setSelectedQuizes([hoverBoxClickedRow.quizId]);
                        if (hoverBoxClickedRow.useCourse) {
                          setIsDeleteModalOpen("USED");
                        } else {
                          setIsDeleteModalOpen("NOT_USED");
                        }
                        onOutsideClick(e);
                      }
                    : undefined
                }
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </BoxNew>
      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen === null ? false : true}
          onClose={() => setIsDeleteModalOpen(null)}
          title={t("quiz.msg.deleteQuizContentTitle")}
          afterSubmitButton={onDelete}
          subMessage={
            isDeleteModalOpen === "USED"
              ? t("quiz.msg.deleteQuizContentAlert")
              : t("quiz.msg.deleteQuizContentMsg")
          }
          type="delete"
        />
      )}
      {!!isUpdateModalopen && (
        <CommonModal
          open={!!isUpdateModalopen}
          onClose={() => setIsUpdateModalOpen(null)}
          title={t("quiz.msg.updateQuizContentTitle")}
          afterSubmitButton={() => {
            navigate(
              `${process.env.PUBLIC_URL}/quiz/content/write?quiz_id=${isUpdateModalopen}`
            );
            setIsUpdateModalOpen(null);
          }}
          subMessage={t("quiz.msg.updateQuizContentAlert")}
        />
      )}
      {isSyncModalOpen && (
        <CommonModal
          open={isSyncModalOpen}
          onClose={() => setIsSyncModalOpen(false)}
          afterSubmitButton={onSync}
          title={t("quiz.label.sync")}
          subMessage={t("quiz.msg.syncContent")}
          footer={
            <BoxNew justifyContent="flex-end">
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"md"}
                type={"secondary"}
                onClick={() => setIsSyncModalOpen(false)}
              />

              <ButtonNew
                btnStyle={"fill"}
                type={"primary"}
                state={"default"}
                size={"md"}
                label={t("common.button.update2")}
                classes={"mg ml-8"}
                onClick={() => {
                  onSync();
                  setIsSyncModalOpen(false);
                }}
              />
            </BoxNew>
          }
        />
      )}
      {openDateModal && dateModal()}
    </BoxNew>
  );
};

export default Quiz;
