import "./index.scss";
import "../../../shared/style/filters.scss";

import {
  Box as BoxNew,
  getColor,
  Tooltip as TooltipNew,
  Typography as TypographyNew,
  useToast as useToastNew,
  Icons as IconsNew,
  Dropdown as DropdownNew,
  DropdownItem as DropdownItemNew,
  InputField,
  Button as ButtonNew,
  Chip,
  Modal as ModalNew,
  CalendarRange,
  Toggle,
  Tag as TagNew,
  Table as TableNew,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { ConfigListType } from "../../../types/Send";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import utilsCommon from "../../../shared/utils/common";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { TrainingListReqDTO } from "../../../types/Training";
import NoInfoImage from "../../../shared/image/NoInfoImage";
import NoTemplateImage from "../../../shared/image/NoTemplateImage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FilterBtnActivityCheckUtils } from "../../../shared/utils/FilterBtnActivityCheckUtils";

import useTableHoverBoxClick from "../../../hooks/useTableHoverBoxClick";
import { useElementPosition } from "../../../hooks/useElementPosition";
import HoverClickBox from "../../../component/Table/HoverClickBox";
import useNavigateState from "../../../hooks/useNavigateState";
import { NavigateStateKeys } from "../../../hooks/NavigateStateKeys";
import { useTableOrder } from "../../../hooks/useTableOrder";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";

enum ColumnsTypeToOrder {
  configId = "configId",
  configName = "configName",
}

interface ProgressType {
  id: number;
  text: string;
}

interface FilterDateType {
  startDate: string;
  endDate: string;
}

const ManageTrainList: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { programState, getProgramList, deleteProgram, updateProgram } =
    useProgramStore();
  const [configList, setConfigList] = useState<ConfigListType[]>([]);
  const [configListTotal, setConfigListTotal] = useState<number>(0);
  const checkboxGroup = useCheckboxGroup<number>();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [progressState, setProgressState] = useState<ProgressType>({
    id: 0,
    text: t("common.auth.all"),
  });
  const [configIdState, setConfigState] = useState<ProgressType>({
    id: 0,
    text: t("common.auth.all"),
  });
  const [dateState, setDateState] = useState<ProgressType>({
    id: 0,
    text: t("common.auth.all"),
  });

  const initialCustomDate = {
    startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  };
  const [customDate, setCustomDate] =
    useState<FilterDateType>(initialCustomDate);

  const [getOrderRenderProps, getOrderParams] = useTableOrder({
    orderCb: (orderParams) => {
      orderParams &&
        changeFilter((filter) => ({
          ...filter,
          orderId: orderParams.orderId,
          orderType: orderParams.orderType,
        }));
    },
  });

  const [filter, changeFilter] = useState<TrainingListReqDTO>({
    orderId: getOrderParams().orderId,
    orderType: getOrderParams().orderType,
    configId: undefined,
    trainingStatus: undefined,
    configName: undefined,
    configNames: [],
    sendStartDate: undefined,
    sendEndDate: undefined,
  });

  const [tempConfigName, changeTempConfigName] = useState("");
  const [tempConfigNames, setTempConfigNames] = useState<string[]>([]);
  const [isFilterCalendarOpen, setIsFilterCalendarOpen] =
    useState<boolean>(false);
  const [selectedConfigs, setSelectedConfigs] = useState<number[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] =
    useState<boolean>(false);
  const [buttonClick, setButtonClick] = useState(0);
  const [configIdList, setConfigIdList] = useState<ConfigListType[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<ConfigListType | null>();

  const {
    tableRef,
    hoverBoxClickedRow,
    hoverBoxNewCoord,
    onOutsideClick,
    handleHoverBoxClick,
  } = useTableHoverBoxClick<ConfigListType>({
    width: 116,
    getDynamicHeight: (row) => (row.quizType ? 147 : 107),
  });

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const toast = useToastNew();

  const progressStates = [
    {
      id: 0,
      text: t("common.auth.all"),
    },
    { id: 1, text: t("program.filter.beforeStart") },
    { id: 2, text: t("program.filter.onProgress") },
    { id: 3, text: t("program.filter.finished") },
    { id: 4, text: t("program.filter.stop") },
  ];

  const dateStates = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("program.filter.beforeOneMonth") },
    { id: 2, text: t("program.filter.beforeThreeMonth") },
    { id: 3, text: t("program.filter.beforeSixMonth") },
  ];

  const initialPage = async () => {
    const resp: any = await getProgramList(filter);
    if (resp.payload && resp.payload.data?.list.length > 0) {
      setConfigIdList(resp.payload.data.list);
    }
  };
  const refreshList = () => {
    getProgramList(filter);
  };

  const onActivate = (flag?: boolean) => {
    if (flag) {
      setIsDeactivateModalOpen(true);
    } else {
      setIsActivateModalOpen(true);
    }
  };

  const updateActivate = async () => {
    //update로 활성화
    const resp: any = await updateProgram({
      configId: selectedConfigs[0],
      activateFlag: true,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programActivateSuccessMsg"),
        "success"
      );
      refreshList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programActivateFailMsg"),
        "error"
      );
    }
    setIsActivateModalOpen(false);
  };

  const updateDectivate = async () => {
    const resp: any = await updateProgram({
      configId: selectedConfigs[0],
      activateFlag: false,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeactivateSuccessMsg"),
        "success"
      );
      refreshList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeactivateFailMsg"),
        "error"
      );
    }
    setIsDeactivateModalOpen(false);
  };

  const onFilterDate = (date: Date, type: string) => {
    const dateStr = date.toString();
    if (type === "start") {
      setCustomDate((prev) => {
        return {
          ...prev,
          startDate: utilsCommon.getFullDate(dateStr).slice(0, 10),
        };
      });
    } else if (type === "end") {
      setCustomDate((prev) => {
        return {
          ...prev,
          endDate: utilsCommon.getFullDate(dateStr).slice(0, 10),
        };
      });
    }
  };

  const handleChangeDateFilter = (item: ProgressType) => {
    let startTime = undefined;
    let endTime = undefined;

    switch (item.id) {
      case 0:
        startTime = undefined;
        endTime = undefined;
        break;
      case 1:
        startTime = dayjs().subtract(1, "M").valueOf() / 1000;
        endTime = dayjs().valueOf() / 1000;
        break;
      case 2:
        startTime = dayjs().subtract(3, "M").valueOf() / 1000;
        endTime = dayjs().valueOf() / 1000;
        break;
      case 3:
        startTime = dayjs().subtract(6, "M").valueOf() / 1000;
        endTime = dayjs().valueOf() / 1000;
        break;
      default:
        break;
    }
    setDateState(item);
    changeFilter({
      ...filter,
      sendStartDate: startTime,
      sendEndDate: endTime,
    });
  };

  const handleInitializeFilter = () => {
    changeFilter({
      orderId: "configId",
      orderType: "desc",
      configId: undefined,
      trainingStatus: undefined,
      configName: undefined,
      configNames: [],
      sendStartDate: undefined,
      sendEndDate: undefined,
    });
    setProgressState({
      id: 0,
      text: t("common.auth.all"),
    });
    setConfigState({
      id: 0,
      text: t("common.auth.all"),
    });
    setDateState({
      id: 0,
      text: t("common.auth.all"),
    });
    setTempConfigNames([]);
    setCustomDate(initialCustomDate);
  };

  const onDelete = async () => {
    const resp: any = await deleteProgram(selectedConfigs[0]);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeleteSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(nanoid(), t("program.msg.programDeleteFailMsg"), "error");
    }
    checkboxGroup.handleAllClick([]);
    setSelectedConfigs([]);
    setIsDeleteModalOpen(false);
  };

  function checkTableRenderStatus() {
    const isFilterApplied =
      JSON.stringify(filter) !== JSON.stringify({} as FilterDateType);
    const hasResult = configList && configList.length > 0;
    if (!hasResult && isFilterApplied) return 1;
    else if (!hasResult && !isFilterApplied) return 2;
    else return 0;
  }

  function renderTableSwitch() {
    switch (checkTableRenderStatus()) {
      case 1:
        return noFilteredResult;
      case 2:
        return noResultCreated;
      default:
        return <></>;
    }
  }
  function transformData(data: ConfigListType[]) {
    const dataTransformed = [];

    for (let i = 0; i < data.length; i++) {
      const config = data[i];
      const padding = <BoxNew width={16} />;
      const dataConfigId = <TypographyNew>{config.configId}</TypographyNew>;
      const dataFlag = config.realTrainingFlag ? (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("program.realTrainingLabel")}
          textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
          backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
        />
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("program.mockTrainingLabel")}
          textColor={getColor("COLOR_AVATAR_COOL_GRAY_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_COOL_GRAY_BG")}
        />
      );
      const dataConfigName = (
        <BoxNew>
          <TooltipNew message={config.configName} maxWidth={"99%"}>
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
              ellipsis
            >
              {config.quizType ? `[${t("quiz.table.trainingQuiz")}]` : ""}
              &nbsp;{config.configName}
            </TypographyNew>
          </TooltipNew>
        </BoxNew>
      );
      const dataTrainingDuration = (
        <TypographyNew classes="tnum">
          {utilsCommon.getFullDateStr(config.sendStartDate)} ~{" "}
          {utilsCommon.getFullDateStr(config.sendEndDate)}
        </TypographyNew>
      );
      const dataProgressStatus =
        config.sendCount === 0
          ? config.startedFlag ||
            (config.sendStartDate &&
              dayjs(config.sendStartDate).valueOf() * 1000 < dayjs().valueOf())
            ? "0%"
            : t("program.programSendWait")
          : config.sendCount && config.userCount && config.sendMailCount
          ? `${(
              (config.sendCount / (config.userCount * config.sendMailCount)) *
              100
            ).toFixed(0)} %`
          : t("program.programSendWait");

      const dataUserCount = (
        <TypographyNew>
          {config.userCount + t("program.programPeopleUnit")}
        </TypographyNew>
      );
      const dataSendMailCount = (
        <TypographyNew>
          {config.sendMailCount + t("program.programDataUnit")}
        </TypographyNew>
      );
      const dataTemplateCount = (
        <TypographyNew>
          {config.templateCount + t("program.programTemplateUnit")}
        </TypographyNew>
      );
      const dataActivateFlag =
        (config?.userCount &&
          config?.sendMailCount &&
          config.sendCount === config?.userCount * config?.sendMailCount) ||
        dayjs(config?.sendEndDate).valueOf() * 1000 < dayjs().valueOf() ? (
          <Toggle checked={false} disabled={true} />
        ) : (
          <TooltipNew
            message={
              config.activateFlag
                ? t("program.programActivateHeader")
                : t("program.programDeactivateHeader")
            }
          >
            <Toggle
              checked={config.activateFlag}
              onChange={() => {
                setSelectedConfigs([config.configId]);
                onActivate(config.activateFlag);
              }}
            />
          </TooltipNew>
        );

      const hoverBtn = (
        <ButtonNew
          classes="hover-btn"
          btnStyle={"icon"}
          leadingIcon={<IconsNew variant="line" label="moreVertical" />}
          type={"primary"}
          size={"sm"}
          state={"hover"}
          onClick={(event: MouseEvent) => handleHoverBoxClick(event, config)}
        />
      );

      const dataItemTransformed = {
        data: [
          { columnText: padding },
          { columnText: dataConfigId },
          { columnText: dataFlag },
          { columnText: dataConfigName },
          { columnText: dataTrainingDuration },
          { columnText: dataProgressStatus },
          { columnText: dataUserCount },
          { columnText: dataSendMailCount },
          { columnText: dataTemplateCount },
          { columnText: dataActivateFlag },
        ],
        id: String(config.configId),
        clickAction: (e: any) => {
          if (!e.target.closest(".hover-btn")) {
            navigate(
              `${process.env.PUBLIC_URL}/manageTrain/program/detail?config_id=${config.configId}`
            );
          }
        },
        hover: hoverBtn,
      };
      dataTransformed.push(dataItemTransformed);
    }
    return dataTransformed;
  }

  useEffect(() => {
    initialPage();
  }, []);

  useEffect(() => {
    setConfigList(programState.configListWithCount?.list ?? []);
    setConfigListTotal(programState.configListWithCount?.total ?? 0);
  }, [programState.configListWithCount]);

  useEffect(() => {
    refreshList();
  }, [filter]);

  useEffect(() => {
    setProgressState((prev) => ({
      ...prev,
      text: progressStates.filter((p) => p.id === prev.id)[0].text,
    }));

    setConfigState((prev) => ({
      id: prev.id,
      text:
        prev.id === 0
          ? t("common.auth.all")
          : t("common.list.numOrder", { round: prev.id }),
    }));

    setDateState((prev) => ({
      ...prev,
      text:
        prev.id === 5
          ? prev.text
          : dateStates.filter((d) => d.id === prev.id)[0].text,
    }));
  }, [i18n.language]);

  const dateModal = () => {
    return (
      <ModalNew
        open={isFilterCalendarOpen}
        onClose={() => setIsFilterCalendarOpen(false)}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => setIsFilterCalendarOpen(false)}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                setDateState({ id: 5, text: t("graph.phishingCustomScope") });
                changeFilter({
                  ...filter,
                  sendStartDate:
                    dayjs(customDate?.startDate)
                      .set("hour", 0)
                      .set("minute", 0)
                      .set("seconds", 0)
                      .valueOf() / 1000,
                  sendEndDate:
                    dayjs(customDate?.endDate)
                      .set("hour", 23)
                      .set("minute", 59)
                      .set("seconds", 59)
                      .valueOf() / 1000,
                });
                setIsFilterCalendarOpen(false);
              }}
              label={t("common.button.check")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(customDate.startDate)}
              endDate={new Date(customDate.endDate)}
              onSubmit={(date: any) => {
                onFilterDate(date.start, "start");
                onFilterDate(date.end, "end");
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  const header = (
    <BoxNew classes="mg mt-32" justifyContent="space-between">
      <BoxNew alignItems="center">
        <TypographyNew
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("program.tests")}
        </TypographyNew>
        <TypographyNew
          classes="mg ml-8"
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_BRAND", "blue")}
        >
          {configListTotal ?? 0}
        </TypographyNew>
      </BoxNew>
      <BoxNew>
        <BoxNew>
          <ButtonNew
            btnStyle={"link"}
            size={"lg"}
            classes="mg mr-4"
            state={checkboxGroup.selected.length > 0 ? "default" : "disable"}
            leadingIcon={
              <IconsNew
                variant="line"
                label="delete"
                stroke={getColor("COLOR_ICON_PRIMARY")}
              />
            }
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
            label={t("common.button.delete")}
          />
        </BoxNew>
        <ButtonNew
          btnStyle="link"
          type="primary"
          leadingIcon={
            <IconsNew
              variant="line"
              label="filter"
              stroke={getColor("COLOR_ICON_PRIMARY")}
            />
          }
          onClick={() => {
            setIsFilterOpen((prev) => !prev);
          }}
          label={t("common.list.filter")}
        />
      </BoxNew>
    </BoxNew>
  );

  function checkIfFilterSelected() {
    return (
      filter.configId ||
      filter.trainingStatus ||
      filter.configNames!.length > 0 ||
      filter.sendStartDate ||
      filter.sendEndDate
    );
  }

  const filters = (
    <BoxNew
      classes="pd pt-12 gap-8 dropdown-filter-container"
      alignItems="center"
    >
      {/* 회차 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("program.configRound") + " · " + configIdState.text}
        value={
          configIdState.id === 0
            ? ""
            : t("program.configRound") + " · " + configIdState.text
        }
        buttonClick={buttonClick}
        buttonWidth={224}
        dropdownMaxHeight={276}
      >
        <DropdownItemNew
          checked={configIdState.id === 0}
          checkColor={configIdState.id === 0}
          onClick={() => {
            setConfigState({
              id: 0,
              text: t("common.auth.all"),
            });
            changeFilter({ ...filter, configId: undefined });
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("common.auth.all")}
          type={"check"}
        />
        {configIdList &&
          configIdList.map((c: ConfigListType) => (
            <DropdownItemNew
              key={`configId-dropdownItem-${c.configId}`}
              checked={configIdState.id === c.configId}
              checkColor={configIdState.id === c.configId}
              onClick={() => {
                setConfigState({
                  id: c.configId,
                  text: t("common.list.numOrder", { round: c.configId }),
                });
                changeFilter({ ...filter, configId: c.configId });
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("common.list.numOrder", { round: c.configId })}
              type={"check"}
            />
          ))}
      </DropdownNew>
      {/* 훈련 상태 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("program.filter.status") + " · " + progressState.text}
        value={
          progressState.id === 0
            ? ""
            : t("program.filter.status") + " · " + progressState.text
        }
        buttonClick={buttonClick}
        buttonWidth={224}
        dropdownMaxHeight={276}
      >
        {progressStates &&
          progressStates.map((item) => (
            <DropdownItemNew
              key={`progressState-dropdownItem-${item.id}`}
              checked={progressState.id === item.id}
              checkColor={progressState.id === item.id}
              onClick={() => {
                setProgressState(item);
                changeFilter({ ...filter, trainingStatus: item.id });
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={item.text}
              type={"check"}
            />
          ))}
      </DropdownNew>
      {/* 훈련 이름 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={t("menu.trainingName") + " · " + t("common.auth.all")}
        value={
          filter.configNames === undefined || filter.configNames.length === 0
            ? ""
            : t("menu.trainingName") + " · " + filter.configNames.length
        }
        buttonWidth={320}
        buttonClick={buttonClick}
      >
        <BoxNew direction={"column"} classes="dropdown-filter">
          <BoxNew classes="dropdown-filter-title">
            <TypographyNew
              type="caption1_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("menu.trainingName")}
            </TypographyNew>
          </BoxNew>
          <BoxNew classes="dropdown-filter-search">
            <InputField
              width={288}
              size={"sm"}
              value={tempConfigName}
              leadingIcon={<IconsNew variant="line" label="search" size={20} />}
              placeholder={t("program.msg.programUndefinedNameMsg")}
              onChange={(e) => changeTempConfigName(e.target.value)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  if (e.target.value === "") return;
                  if (
                    filter.configNames &&
                    filter.configNames.includes(e.target.value)
                  ) {
                    return;
                  }
                  setTempConfigNames((prev) => [...prev, tempConfigName]);
                  changeTempConfigName("");
                }
              }}
            />
          </BoxNew>
          <BoxNew classes="gap-10 dropdown-filter-body">
            {tempConfigNames &&
              tempConfigNames.map((item) => (
                <Chip
                  key={`tempConfigName-chip-${item}`}
                  type={"fill"}
                  size={"sm"}
                  showClose={true}
                  text={item}
                  onClick={() =>
                    setTempConfigNames((prev) => prev.filter((q) => q !== item))
                  }
                />
              ))}
          </BoxNew>
          <BoxNew
            alignItems="center"
            classes="dropdown-filter-footer"
            justifyContent="flex-end"
          >
            <BoxNew>
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.cancel")}
                state={"default"}
                size={"lg"}
                type={"secondary"}
                onClick={() => {
                  changeTempConfigName(filter.configName ?? "");
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
              <ButtonNew
                btnStyle={"fill"}
                label={t("common.button.apply")}
                state={
                  FilterBtnActivityCheckUtils.checkIfApplyBtnActive(
                    filter.configNames || [],
                    tempConfigNames
                  )
                    ? "default"
                    : "disable"
                }
                size={"lg"}
                classes={"mg ml-8"}
                type="primary"
                onClick={() => {
                  if (tempConfigName !== "") {
                    setTempConfigNames((prev) => [...prev, tempConfigName]);
                    changeFilter((prev) => ({
                      ...prev,
                      configNames: [...tempConfigNames, tempConfigName],
                    }));
                  } else {
                    changeFilter((prev) => ({
                      ...prev,
                      configNames: tempConfigNames,
                    }));
                  }
                  changeTempConfigName("");

                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </DropdownNew>
      {/* 훈련 기간 */}
      <DropdownNew
        btnStyle={"round"}
        btnSize={"md"}
        placeholder={
          t("program.trainingDuration") + " · " + t("common.auth.all")
        }
        value={
          dateState.id === 0
            ? ""
            : dateState.id === 5
            ? `${t("program.trainingDuration")} · ${utilsCommon.getShortDate(
                filter.sendStartDate
              )} - ${utilsCommon.getShortDate(filter.sendEndDate)}`
            : `${t("program.trainingDuration")} · ${dateState.text}`
        }
        buttonClick={buttonClick}
        buttonWidth={224}
      >
        {dateStates.map((item) => (
          <DropdownItemNew
            checked={item.id === dateState.id}
            checkColor={item.id === dateState.id}
            onClick={() => {
              handleChangeDateFilter(item);
              setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            }}
            isFullWidth
            label={item.text}
            type={"check"}
          />
        ))}
        <DropdownItemNew
          label=""
          onClick={() => {}}
          state="divider"
          type="default"
        />
        <DropdownItemNew
          checked={dateState.id === 5}
          checkColor={dateState.id === 5}
          onClick={() => {
            setIsFilterCalendarOpen((prev) => !prev);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isFullWidth
          label={t("graph.phishingCustomScope")}
          type={"check"}
        />
      </DropdownNew>
      {/* 초기화 */}
      <ButtonNew
        btnStyle={"icon"}
        state={checkIfFilterSelected() ? "default" : "disable"}
        size={"md"}
        onClick={handleInitializeFilter}
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_PRIMARY")}
          />
        }
      />
    </BoxNew>
  );

  const noFilteredResult = (
    <BoxNew
      width={"100%"}
      direction="column"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoSearchResultImage width={200} />
      <TypographyNew type="h3" classes="mg mt-20">
        {t("quiz.label.noSearch")}
      </TypographyNew>
      <TypographyNew
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        type="body2_rg"
        classes="mg mt-8 mb-24"
      >
        {t("program.msg.noFilterMsg")}
      </TypographyNew>
      <ButtonNew
        btnStyle="fill"
        type="primary"
        size={"lg"}
        leadingIcon={
          <IconsNew
            variant="line"
            label="redoLeft"
            stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
          />
        }
        onClick={handleInitializeFilter}
        label={t("common.button.resetFilter")}
      />
    </BoxNew>
  );

  const noResultCreated = (
    <BoxNew
      width={"100%"}
      direction="column"
      alignItems="center"
      classes="mg mt-80"
    >
      <NoTemplateImage />
      <TypographyNew
        type="body2_rg"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mt-24 mb-24"
      >
        {t("program.msg.noTrainingNewMsg")}
      </TypographyNew>
      <ButtonNew
        btnStyle="fill"
        type="primary"
        size={"lg"}
        leadingIcon={
          <IconsNew
            variant="line"
            label="plusCircle"
            stroke={getColor("COLOR_ICON_INTERACTIVE_INVERSE")}
          />
        }
        onClick={() => {
          setSelectedConfig(null);
          navigate(`${process.env.PUBLIC_URL}/manageTrain/program/write`);
        }}
        label={t("menu.createTraining")}
      />
    </BoxNew>
  );

  const { navigateState } = useNavigateState(
    NavigateStateKeys.fromProgramCreate
  );

  return (
    <BoxNew classes="main manageTrainList" ref={tableContainerRef}>
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath
            path={[t("menu.trainingManagement"), t("menu.training")]}
          />
          <TypographyNew
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.training")}
          </TypographyNew>
        </BoxNew>
        <ButtonNew
          btnStyle="fill"
          size={"lg"}
          type="secondary"
          leadingIcon={
            <IconsNew
              variant="line"
              label="plusCircle"
              stroke={getColor("COLOR_ICON_PRIMARY")}
            />
          }
          onClick={() =>
            navigate(`${process.env.PUBLIC_URL}/manageTrain/program/write`)
          }
          label={t("program.add")}
        />
      </BoxNew>
      {header}
      {isFilterOpen && filters}
      <BoxNew
        direction="column"
        classes="pd pt-12 flex-1"
        ref={tableRef}
        style={{
          pointerEvents: hoverBoxClickedRow.configId ? "none" : undefined,
        }}
      >
        <TableNew
          border="none"
          columnHeight="md"
          data={transformData(configList)}
          endReached={() => {}}
          highlightIds={navigateState?.length ? navigateState : undefined}
          header={[
            { headerText: "", width: "16px" },
            {
              headerText: t("program.configRound"),
              width: "75px",
              ...getOrderRenderProps(ColumnsTypeToOrder.configId),
            },
            {
              headerText: t("template.type"),
              width: "64px",
            },
            {
              headerText: t("program.programName"),
              width: "calc(100% - 850px)",
              ...getOrderRenderProps(ColumnsTypeToOrder.configName),
            },
            {
              headerText: t("program.trainingDuration"),
              width: "330px",
            },
            {
              headerText: t("program.progressStatus"),
              width: "64px",
            },
            {
              headerText: t("dashboard.user"),
              width: "68px",
            },
            {
              headerText: t("program.sendMail"),
              width: "65px",
            },
            {
              headerText: t("template.templateSimple"),
              width: "64px",
            },
            {
              headerText: t("program.programActivate"),
              width: "100px",
            },
          ]}
          width={"100%"}
          height={
            checkTableRenderStatus() === 0
              ? `${
                  tableContainerPosition.height -
                  (245 + (isFilterOpen ? 56 : 0))
                }px`
              : "0px"
          }
        />
        {renderTableSwitch()}
        <HoverClickBox
          isDisplayed={!!hoverBoxClickedRow.configId}
          hoverBoxNewCoord={hoverBoxNewCoord}
          onOutsideClick={onOutsideClick}
          onClickDetail={() => {
            navigate(
              `${process.env.PUBLIC_URL}/manageTrain/program/detail?config_id=${hoverBoxClickedRow.configId}`
            );
          }}
          onClickDelete={(e: MouseEvent) => {
            setSelectedConfigs([hoverBoxClickedRow.configId]);
            setIsDeleteModalOpen(true);
            onOutsideClick(e);
          }}
          onClickUpdate={(function () {
            const isTrainingEnded =
              Number(hoverBoxClickedRow.sendEndDate) * 1000 <=
              dayjs().valueOf();
            function updateTraining() {
              setSelectedConfig(hoverBoxClickedRow);
              navigate(
                `${process.env.PUBLIC_URL}/manageTrain/program/write?config_id=${hoverBoxClickedRow.configId}`
              );
            }
            return !isTrainingEnded ? updateTraining : undefined;
          })()}
        />
      </BoxNew>
      {isFilterCalendarOpen && dateModal()}

      <ModalNew
        width={540}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={t("program.delete")}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              label={t("common.button.cancel")}
              onClick={() => setIsDeleteModalOpen(false)}
            />
            <ButtonNew
              btnStyle="fill"
              type="error"
              label={t("program.delete")}
              onClick={onDelete}
            />
          </BoxNew>
        }
        body={
          <BoxNew>
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("program.msg.deleteConfirmMsg")}
            </TypographyNew>
          </BoxNew>
        }
      />

      <ModalNew
        width={540}
        open={isActivateModalOpen}
        onClose={() => setIsActivateModalOpen(false)}
        title={t("program.programActivateHeader")}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              label={t("common.button.cancel")}
              onClick={() => setIsActivateModalOpen(false)}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              label={t("program.programActivate")}
              classes={"mg ml-8"}
              onClick={updateActivate}
            />
          </BoxNew>
        }
        body={
          <BoxNew>
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("program.msg.programActivateConfrimMsg")}
            </TypographyNew>
          </BoxNew>
        }
      />

      <ModalNew
        width={540}
        open={isDeactivateModalOpen}
        onClose={() => setIsDeactivateModalOpen(false)}
        title={t("program.programDeactivateHeader")}
        footer={
          <BoxNew justifyContent="flex-end">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              label={t("common.button.cancel")}
              onClick={() => setIsDeactivateModalOpen(false)}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              label={t("program.programDeactive")}
              classes={"mg ml-8"}
              onClick={updateDectivate}
            />
          </BoxNew>
        }
        body={
          <BoxNew>
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("program.msg.programDeactivateConfrimMsg")}&nbsp;
              {t("program.msg.programDeactivateInfoMsg")}
            </TypographyNew>
          </BoxNew>
        }
      />
    </BoxNew>
  );
};

export default ManageTrainList;
