import { Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";

import {
  Avatar,
  Box,
  getColor,
  Dropdown,
  DropdownItem,
  Tooltip,
  Typography,
  Icons,
  SideMenu,
  SideMenuItem,
  SideMenuAccordion,
  Layout,
  LayoutTop,
  useToast,
} from "fds";

import "./index.scss";
import React, { ReactNode, useEffect, useState } from "react";
import DummyAPIPage from "./Temp/DummyAPIPage";
import DummyAPIPage2 from "./Temp/DummyAPIPage2";
import ManageTrainList from "./ManageTrain/List";
import ManageTrainResult from "./ManageTrain/Result";
import ManageTrainStat from "./ManageTrain/Stat";
import ReportStatistics from "./Report/Statistics";
import ReportView from "./Report/View/List";
import ReportSetting from "./Report/Setting";
import ManageLog from "./Manage/Log";
import ManageCustomer from "./Manage/Customer";
import ManageAccess from "./Manage/Access";
import Organ from "./Organ";
import System from "./System";
import useCustomerStore from "../redux/dispatcher/useCustomerStore";
import WriteScenario from "./ManageScenario/Write";
import ManageScenario from "./ManageScenario/List";
import {
  getAccessToken,
  getLocalCustomerCode,
  getUserInfoFromLocalStorage,
  removeLocalCustomerCode,
  setLocalCustomerCode,
  setSessionCustomerCode,
} from "../shared/utils/auth";
import TrainingSite from "./ManageScenario/Site";
import TrainingImage from "./ManageScenario/Image";
import TrainingDocument from "./ManageScenario/Document";
import useAccountStore from "../redux/dispatcher/useAccountStore";
import { CustomerInfoBean } from "../types/Info";
import { myInfoType } from "../types/User";
import i18n from "../locales";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { sessionCheck } from "../redux/store/account";
import Quiz from "./Quiz/List";
import WriteQuiz from "./Quiz/Write";
import QuizTemplate from "./QuizTemplate/List";
import WriteQuizTemplate from "./QuizTemplate/Write";
import QuizCourse from "./QuizCourse/List";
import WriteQuizCourse from "./QuizCourse/Write";
import QuizResult from "./QuizResult";
import WriteTraining from "./ManageTrain/Write";
import QuizCourseDetail from "./QuizCourse/Detail";
import QuizContentDetail from "./Quiz/Detail";
import QuizStat from "./QuizStat";
import PwdModal from "../component/PwdModal";
import { nanoid } from "@reduxjs/toolkit";
import TrainingDetail from "./ManageTrain/Detail";
import ReportViewDetail from "./Report/View/Detail";
import DetailTraining from "./ManageTrain/Detail";
import DetailScenario from "./ManageScenario/Detail";
import DynamicHead from "./DynamicHead";

interface MenuType {
  path: string;
  name: string;
  policy: string;
  icon?: ReactNode;
  subMenu?: MenuType[];
}

const BaseLayout: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { customerState, selectCustomer, getAccessCompanyList } =
    useCustomerStore();
  const { sessionLogOut, changePassword } = useAccountStore();
  const { changeLanguage } = i18next;
  const { t } = useTranslation();
  const [customerList, setCustomerList] = useState<CustomerInfoBean[]>([]);
  const [customerCode, setCustomerCode] = useState<string | null>();
  const [myInfo, setMyInfo] = useState<myInfoType>();
  const [menus, setMenus] = useState<MenuType[]>([]);
  const [buttonClick, setButtonClick] = useState(0);
  const [lang, changelang] = useState(i18n.language ?? "en");
  const [isPwdModalOpen, setIsPwdModalOpen] = useState<boolean>(false);

  const mindsatLogo = require("../shared/image/logo/img_mindsat_bi.png");

  const currentPage = window.location.pathname;
  const [showLayout, setShowLayout] = useState(true);
  const [location, setLocation] = useState(
    currentPage === "/"
      ? getAccessToken()
        ? "dashboard"
        : "login"
      : currentPage
  );
  const channel = new BroadcastChannel("customer");

  const menuList: MenuType[] = [
    {
      path: "dashboard",
      icon: <Icons variant="fill" label="home" />,
      name: t("menu.home"),
      policy: "",
    },
    {
      path: "manageTrain",
      icon: <Icons variant="fill" label="log" />,
      name: t("menu.trainingManagement"),
      policy: "3",
      subMenu: [
        {
          path: "manageTrain/program",
          name: t("menu.training"),
          policy: "9",
        },
        {
          path: "manageTrain/scenario",
          name: t("menu.template"),
          policy: "10",
        },
        {
          path: "manageTrain/content/site",
          name: t("menu.templateContentsSetting"),
          policy: "11",
          subMenu: [
            {
              path: "manageTrain/content/site",
              name: t("menu.templateLink"),
              policy: "11",
            },
            {
              path: "manageTrain/content/image",
              name: t("menu.templateImage"),
              policy: "11",
            },
            {
              path: "manageTrain/content/document",
              name: t("menu.templateAttachFile"),
              policy: "11",
            },
          ],
        },
        {
          path: "manageTrain/result",
          name: t("menu.trainingSendAndResult"),
          policy: "15",
        },
        {
          path: "manageTrain/stat",
          name: t("menu.trainingGraph"),
          policy: "16",
        },
      ],
    },
    {
      path: "quiz",
      icon: <Icons variant="fill" label="education" />,
      name: t("menu.quizTitle"),
      policy: "4",
      subMenu: [
        {
          path: "quiz/course",
          name: t("menu.quizCourse"),
          policy: "17",
        },
        {
          path: "quiz/content",
          name: t("menu.quizContent"),
          policy: "18",
        },
        {
          path: "quiz/template",
          name: t("menu.quizTemplate"),
          policy: "19",
        },
        {
          path: "quiz/result",
          name: t("menu.quizResult"),
          policy: "20",
        },
        {
          path: "quiz/stat",
          name: t("menu.quizStat"),
          policy: "21",
        },
      ],
    },
    {
      path: "report",
      icon: <Icons variant="fill" label="megaphone" />,
      name: t("menu.reportManagement"),
      policy: "5",
      subMenu: [
        {
          path: "report/view",
          name: t("menu.reportOperation"),
          policy: "22",
        },
        {
          path: "report/setting",
          name: t("menu.reportSetting"),
          policy: "23",
        },
        {
          path: "report/statistics",
          name: t("menu.reportGraph"),
          policy: "24",
        },
      ],
    },

    {
      path: "organ",
      icon: <Icons variant="fill" label="users" />,
      name: t("menu.userManagement"),
      policy: "6",
    },
    {
      path: "manager/access",
      icon: <Icons variant="fill" label="userSetting" />,
      name: t("menu.managerSetting"),
      policy: "7",
    },
    {
      path: "manager/log",
      icon: <Icons variant="fill" label="setting" />,
      name: t("menu.logManagement"),
      policy: "",
    },

    // {
    //   path: "system",
    //   icon: <Icons variant="sideMenu" label="systemSetting" />,
    //   name: "시스템 관리",
    //   policy: "super",
    // },
    // {
    //   path: "dummyApi1",
    //   icon: <Icons variant="sideMenu" label="tag" />,
    //   name: "API 모음1",
    //   policy: "super",
    // },
    // {
    //   path: "dummyApi2",
    //   icon: <Icons variant="sideMenu" label="tag" />,
    //   name: "API 모음 2",
    //   policy: "super",
    // },
    // {
    //   path: "sampleUI",
    //   icon: <Icons variant="sideMenu" label="tag" />,
    //   name: "sampleUI",
    //   policy: "super",
    // },
  ];

  const menuListForMaster: MenuType[] = [
    {
      path: "manageTrain",
      icon: <Icons variant="fill" label="log" />,
      name: t("menu.trainingManagement"),
      policy: "super",
      subMenu: [
        {
          path: "manageTrain/scenario",
          name: t("menu.template"),
          policy: "",
        },
        {
          path: "manageTrain/content/site",
          name: t("menu.templateContentsSetting"),
          policy: "",
          subMenu: [
            {
              path: "manageTrain/content/site",
              name: t("menu.templateLink"),
              policy: "",
            },
            {
              path: "manageTrain/content/image",
              name: t("menu.templateImage"),
              policy: "",
            },
            {
              path: "manageTrain/content/document",
              name: t("menu.templateAttachFile"),
              policy: "",
            },
          ],
        },
      ],
    },
    {
      path: "quiz",
      icon: <Icons variant="fill" label="education" />,
      name: t("menu.quizTitle"),
      policy: "4",
      subMenu: [
        {
          path: "quiz/content",
          name: t("menu.quizContent"),
          policy: "",
        },
      ],
    },
    {
      path: "manager/access",
      icon: <Icons variant="fill" label="userSetting" />,
      name: t("menu.managerSetting"),
      policy: "super",
    },
    {
      path: "manager/customer",
      icon: <Icons variant="fill" label="company" />,
      name: t("menu.customerManagement"),
      policy: "",
    },
  ];

  const findPolicyByPath = (
    menus: MenuType[],
    cleanPath: string
  ): string | null => {
    for (const menu of menus) {
      if (menu.path === cleanPath) {
        return menu.policy;
      }

      if (menu.subMenu) {
        const policy = findPolicyByPath(menu.subMenu, cleanPath);
        if (policy) {
          return policy;
        }
      }
    }
    return null;
  };

  const getPolicyByPath = (path: string): string | null => {
    const cleanPath = path.startsWith("/") ? path.slice(1) : path;
    return findPolicyByPath(menuList, cleanPath);
    // for (const menu of menuList) {
    //   if (menu.path === cleanPath) {
    //     return menu.policy;
    //   }
    //   if (menu.subMenu) {
    //     for (const subMenu of menu.subMenu) {
    //       if (subMenu.path === cleanPath) {
    //         return menu.policy;
    //       }
    //       if (subMenu.subMenu) {
    //         for (const subSubMenu of subMenu.subMenu) {
    //           if (subSubMenu.path === cleanPath) {
    //             return menu.policy;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // return null;
  };

  const filterMenuList = (
    menus: MenuType[],
    accessArray: number[]
  ): MenuType[] => {
    return menus
      .map((menu) => {
        const filteredSubMenu = menu.subMenu
          ? filterMenuList(menu.subMenu, accessArray)
          : undefined;

        if (
          accessArray.includes(parseInt(menu.policy)) ||
          menu.policy === "" ||
          (filteredSubMenu && filteredSubMenu.length > 0)
        ) {
          return {
            ...menu,
            subMenu: filteredSubMenu,
          };
        }

        return null;
      })
      .filter(Boolean) as MenuType[];
  };

  useEffect(() => {
    changelang(i18n.language);
    // 임시조치:  고객사 선택되도록
    // selectCustomer("d42585b2f4ce466a97b62f28f4236d77");
    // Super Admin 인 경우 전용화면만 접근. 일반 admin 인 경우 고객사 선택
    const info = getUserInfoFromLocalStorage();
    const localCustomerCode = getLocalCustomerCode();
    const listener = (event: any) => {
      setSessionCustomerCode(event.data);
      if (event.data === "master") {
        window.location.href = `${process.env.PUBLIC_URL}/manageTrain/scenario`;
      } else {
        window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
      }
    };
    channel.addEventListener("message", listener);

    if (info) setMyInfo(info);
    if (info?.superAdmin) {
      getAccessCompanyList();
    }

    if (localCustomerCode) {
      // 고객사 접속 됨
      setCustomerCode(localCustomerCode);
      // setMenus(menuList);
      // info 에서 menuAccess 가져와서 menuList policy 와 매칭
      if (info) {
        if (!info.superAdmin && info.menuAccess) {
          const accessArray = JSON.parse(info.menuAccess) as number[];
          // setMenus(
          //   menuList.filter(
          //     (menu) =>
          //       accessArray.includes(parseInt(menu.policy)) ||
          //       menu.policy === ""
          //   )
          // );
          setMenus(filterMenuList(menuList, accessArray));
        } else {
          setMenus(menuList);
        }
      }
    } else {
      // 마스터페이지로 보여야 함
      setCustomerCode(null);
      setMenus(menuListForMaster);
    }
    return () => {
      channel.removeEventListener("message", listener);
    };
  }, [i18n.language]);

  // useEffect(() => {
  //   changelang(i18n.language);
  // }, [i18n.language]);

  useEffect(() => {
    setCustomerList(customerState.companyList);
  }, [customerState.companyList]);

  useEffect(() => {
    if (location !== window.location.pathname)
      navigate(
        process.env.PUBLIC_URL
          ? `${process.env.PUBLIC_URL}/${location}`
          : location
      );
    sessionCheck();
  }, [location]);

  useEffect(() => {
    const info = getUserInfoFromLocalStorage();
    let pathCheckTemp = false;
    let path = window.location.pathname;

    if (info) {
      if (!info.superAdmin && info.menuAccess) {
        const accessArray = JSON.parse(info.menuAccess) as number[];

        // 현재 경로의 policy를 확인
        const policy = getPolicyByPath(path);

        console.log(policy);
        let allowedPaths = accessArray.includes(10)
          ? [
              "/manageTrain/scenario/detail/",
              "/manageTrain/scenario/update/",
              "/manageTrain/scenario/write",
            ]
          : [];

        if (accessArray.includes(9)) {
          allowedPaths = [...allowedPaths, "/manageTrain/program/write"];
        }
        if (accessArray.includes(17)) {
          allowedPaths = [
            ...allowedPaths,
            "/quiz/course/write",
            "/quiz/course/detail",
          ];
        }

        if (accessArray.includes(18)) {
          allowedPaths = [
            ...allowedPaths,
            "/quiz/content/write",
            "/quiz/content/detail",
            "/quiz/template/write",
          ];
        }

        if (accessArray.includes(19)) {
          allowedPaths = [...allowedPaths, "/quiz/template/write"];
        }

        const pathCheckTemp =
          policy === "" ||
          (policy && accessArray.includes(parseInt(policy))) ||
          allowedPaths.some((allowedPath) => path.includes(allowedPath));

        if (!pathCheckTemp) {
          window.location.href = `${process.env.PUBLIC_URL}/error`;
        }

        setLocation(window.location.pathname);
      } else {
        switch (path) {
          case "/dashboard":
          case "/manageTrain/program":
          case "/manageTrain/program/write":
          case "/manageTrain/program/detail":
          case "/manageTrain/result":
          case "/manageTrain/stat":
          case "/manageTrain/scenario":
          case "/manageTrain/scenario/detail":
          case "/manageTrain/scenario/write":
          case "/manageTrain/content/site":
          case "/manageTrain/content/image":
          case "/manageTrain/content/document":
          case "/quiz/course":
          case "/quiz/course/write":
          case "/quiz/course/detail":
          case "/quiz/content":
          case "/quiz/content/write":
          case "/quiz/content/detail":
          case "/quiz/template":
          case "/quiz/template/write":
          case "/quiz/result":
          case "/quiz/solve":
          case "/quiz/stat":
          case "/report/setting":
          case "/report/view":
          case "/report/view/detail":
          case "/report/statistics":
          case "/organ":
          case "/manager/access":
          case "/manager/customer":
          case "/manager/log":
          case "/system":
          case "/login":
            pathCheckTemp = true;
            break;
          default:
            if (path.includes("/manageTrain/scenario/update/")) {
              pathCheckTemp = true;
            } else if (path.includes("/manageTrain/scenario/detail")) {
              pathCheckTemp = true;
            }
            break;
        }
        if (!pathCheckTemp) {
          window.location.href = `${process.env.PUBLIC_URL}/error`;
        }

        setLocation(window.location.pathname);
      }
    }
  }, [window.location.pathname]);

  const handleSideDrop = async (toggle: boolean, name: string) => {
    setLocation(name);
  };

  const handleLogout = async () => {
    await sessionLogOut();
    navigate("/login");
  };
  useEffect(() => {
    sessionStorage.setItem("mindsat-lang", lang);
  }, [lang]);

  const [changePwdInfo, setChangePwdInfo] = useState({
    nowPwd: "",
    newPwd: "",
    checkPwd: "",
  });

  const [errorType, changeErrorType] = useState({
    isError: false,
    errorTarget: "",
    errorMsg: "",
  });

  const onChangePwd = async () => {
    //validation
    if (!changePwdInfo.nowPwd || changePwdInfo.nowPwd === "") {
      changeErrorType({
        isError: true,
        errorTarget: "INVALID_INFO",
        errorMsg: t("login.msg.loginFailMsg"),
      });
      return;
    }

    if (changePwdInfo.newPwd !== changePwdInfo.checkPwd) {
      changeErrorType({
        isError: true,
        errorTarget: "pwdNotMatch",
        errorMsg: "새로운 비밀번호가 일치하지 않습니다.",
      });
      return;
    }

    const email = getUserInfoFromLocalStorage()?.email;

    if (email) {
      const resp: any = await changePassword({
        email: email,
        pwd: changePwdInfo.nowPwd,
        newPwd: changePwdInfo.newPwd,
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        // 성공

        toast.toastMsg(
          nanoid(),
          t("account.msg.commonPwUpdateSuccessMsg"),
          "success"
        );

        changeErrorType({
          isError: false,
          errorTarget: "",
          errorMsg: "",
        });
        setChangePwdInfo({
          nowPwd: "",
          newPwd: "",
          checkPwd: "",
        });
        setIsPwdModalOpen(false);

        handleLogout();
      } else if (resp.payload && resp.payload.code === "DATA_NOT_FOUND") {
        // 현재 비밀번호 안맞음
        changeErrorType({
          isError: true,
          errorTarget: "nowPwdNotMatch",
          errorMsg: "현재 비밀번호가 일치하지 않습니다.",
        });
      } else {
        // 실패
      }
    }
  };

  return (
    <Layout
      top={
        showLayout && (
          <LayoutTop
            left={
              <Box alignItems="center" key={"wrapsodyLogoBox"}>
                <img
                  className={"cursor-pointer mg ml-12"}
                  src={mindsatLogo}
                  alt={"Mindsat"}
                  onClick={() =>
                    navigate(
                      myInfo?.superAdmin && !customerCode
                        ? `${process.env.PUBLIC_URL}/manageTrain/scenario`
                        : `${process.env.PUBLIC_URL}/dashboard`
                    )
                  }
                  key={"mindsatLogoBox-img"}
                />
              </Box>
            }
            right={
              <Box classes="mg mr-16" alignItems="center">
                {myInfo?.superAdmin && (
                  <Dropdown
                    btnStyle="link"
                    btnSize="md"
                    placeholder=" "
                    value={
                      customerCode !== null &&
                      customerList.filter(
                        (item) => item.customerCode === customerCode
                      ).length > 0
                        ? customerList.filter(
                            (item) => item.customerCode === customerCode
                          )[0].companyName + ""
                        : t("common.auth.master")
                    }
                    buttonClick={buttonClick}
                    align="right"
                    dropdownMaxWidth={192}
                    dropdownMaxHeight={300}
                  >
                    <DropdownItem
                      label={
                        <Box width={176}>
                          {!customerCode ? (
                            <Box>
                              <Icons
                                variant="line"
                                label="check"
                                stroke={getColor("COLOR_ICON_BRAND")}
                              />
                              <Typography classes="mg ml-4">
                                {t("common.auth.master")}
                              </Typography>
                            </Box>
                          ) : (
                            <Box width={176}>
                              <Typography
                                classes="mg ml-24 word_wrap"
                                width={138}
                              >
                                {t("common.auth.master")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      }
                      checkColor={!customerCode}
                      onClick={() => {
                        selectCustomer(null);
                        removeLocalCustomerCode();
                        channel.postMessage("master");
                        setButtonClick(
                          buttonClick > 99999 ? 1 : buttonClick + 1
                        );
                      }}
                    />
                    {customerList &&
                      customerList.map(
                        (item) =>
                          item.dbName && (
                            <DropdownItem
                              checkColor={item.customerCode === customerCode}
                              label={
                                <Box width={176}>
                                  {item.customerCode === customerCode ? (
                                    <Box>
                                      <Icons
                                        variant="line"
                                        label="check"
                                        stroke={getColor("COLOR_ICON_BRAND")}
                                      />
                                      <Typography
                                        classes="mg ml-4 word_wrap"
                                        width={138}
                                      >
                                        {item.companyName}
                                      </Typography>
                                    </Box>
                                  ) : (
                                    <Box>
                                      <Typography
                                        classes="mg ml-24 word_wrap"
                                        width={138}
                                      >
                                        {item.companyName}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              }
                              onClick={() => {
                                selectCustomer(item.customerCode);
                                setLocalCustomerCode(item.customerCode);
                                channel.postMessage(item.customerCode);
                                setButtonClick(
                                  buttonClick > 99999 ? 1 : buttonClick + 1
                                );
                              }}
                            />
                          )
                      )}
                  </Dropdown>
                )}
                <Tooltip message={t("common.info.changeLang")}>
                  <Dropdown
                    btnIcon={
                      <Icons
                        variant="line"
                        label="globe"
                        stroke={getColor("COLOR_ICON_INTERACTIVE_SECONDARY")}
                      />
                    }
                    buttonClick={buttonClick}
                    align="right"
                    width={145}
                    classes="mg ml-20"
                  >
                    <DropdownItem
                      checkColor={lang === "ko"}
                      label={
                        <Box>
                          {lang === "ko" ? (
                            <Box>
                              <Icons
                                variant="line"
                                label="check"
                                stroke={getColor("COLOR_ICON_BRAND")}
                              />
                              <Typography classes="mg ml-4">한국어</Typography>
                            </Box>
                          ) : (
                            <Box>
                              <Typography classes="mg ml-24">한국어</Typography>
                            </Box>
                          )}
                        </Box>
                      }
                      onClick={() => {
                        setButtonClick(
                          buttonClick > 99999 ? 1 : buttonClick + 1
                        );
                        changeLanguage("ko");
                      }}
                    />
                    <DropdownItem
                      label={
                        <Box>
                          {lang === "en" ? (
                            <Box>
                              <Icons
                                variant="line"
                                label="check"
                                stroke={getColor("COLOR_ICON_BRAND")}
                              />
                              <Typography classes="mg ml-4">English</Typography>
                            </Box>
                          ) : (
                            <Box>
                              <Typography classes="mg ml-24">
                                English
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      }
                      onClick={() => {
                        setButtonClick(
                          buttonClick > 99999 ? 1 : buttonClick + 1
                        );
                        changeLanguage("en");
                        // window.location.reload();
                      }}
                    />
                  </Dropdown>
                </Tooltip>

                <Dropdown
                  btnStyle="link"
                  btnSize="sm"
                  btnIcon={
                    <Avatar
                      uniqueCode={myInfo?.email ?? "admin"}
                      name={myInfo?.name ?? t("common.auth.unknown")}
                      key={"profileItem-avatar"}
                    />
                  }
                  align="right"
                  width={180}
                  classes="mg ml-20"
                >
                  <DropdownItem
                    label={
                      <Typography>{t("common.auth.changePwd")}</Typography>
                    }
                    onClick={() => setIsPwdModalOpen((prev) => !prev)}
                  ></DropdownItem>
                  <DropdownItem state="divider" />
                  <DropdownItem
                    type="warning"
                    label={
                      <Typography exactColor={getColor("COLOR_TEXT_ERROR")}>
                        {t("common.auth.signout")}
                      </Typography>
                    }
                    onClick={handleLogout}
                  />
                </Dropdown>
              </Box>
            }
          />
        )
      }
      left={
        showLayout && (
          <SideMenu type="sidemenu2New" id={"sidemenuId"} classes="sidemenu">
            {menus.map(
              (item) =>
                (myInfo?.superAdmin ||
                  (myInfo?.menuAccess &&
                    myInfo.menuAccess.includes(item.policy))) &&
                (item.subMenu ? (
                  <SideMenuAccordion
                    iconLeft={item.icon}
                    text={item.name}
                    dropDownDirection={location.includes(item.path)}
                    type="sidemenu2New"
                    handleSideDrop={(toggle: boolean, name: string) => {
                      const getFirstSubMenuPath = (menu: MenuType): string => {
                        if (menu.subMenu && menu.subMenu.length > 0) {
                          return getFirstSubMenuPath(menu.subMenu[0]);
                        }
                        return menu.path;
                      };

                      const targetPath = item.subMenu?.length
                        ? getFirstSubMenuPath(item)
                        : item.path;

                      handleSideDrop(toggle, targetPath);
                    }}
                    // onText={location.includes(item.path)}
                    alignIcon="right"
                  >
                    {item.subMenu &&
                      item.subMenu.map((menu) => (
                        <React.Fragment key={menu.path}>
                          {menu.subMenu ? (
                            <>
                              <SideMenuItem
                                icon={<Icons variant="fill" label="dot" />}
                                onClick={() => {
                                  setLocation(menu.path);
                                }}
                                text={menu.name}
                                // on={location.includes(menu.path)}
                                classes={"pd pl-24"}
                                type="sidemenu2New"
                              />
                              {menu.subMenu.map((subMenu) => (
                                <SideMenuItem
                                  key={subMenu.path}
                                  icon={<Icons variant="fill" label="dot" />}
                                  onClick={() => {
                                    setLocation(subMenu.path);
                                  }}
                                  text={subMenu.name}
                                  on={location.includes(subMenu.path)}
                                  classes={"pd pl-40"}
                                  type="sidemenu2New"
                                />
                              ))}
                            </>
                          ) : (
                            <SideMenuItem
                              icon={<Icons variant="fill" label="dot" />}
                              onClick={() => {
                                setLocation(menu.path);
                              }}
                              text={menu.name}
                              on={location.includes(menu.path)}
                              classes={"pd pl-24"}
                              type="sidemenu2New"
                            />
                          )}
                        </React.Fragment>
                      ))}
                  </SideMenuAccordion>
                ) : (
                  <SideMenuItem
                    icon={item.icon}
                    onClick={() => {
                      setLocation(item.path);
                    }}
                    on={location.includes(item.path)}
                    text={item.name}
                    type="sidemenu2New"
                  />
                ))
            )}
          </SideMenu>
        )
      }
      classes="baseLayout"
      id={"baseLayout"}
    >
      <Box
        style={{ minWidth: "1248px", width: "calc(100%)" }}
        direction="column"
        id={"home-body"}
      >
        <DynamicHead />
        <Routes>
          <Route
            path={`${process.env.PUBLIC_URL}/dummyApi1`}
            element={<DummyAPIPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dummyApi2`}
            element={<DummyAPIPage2 />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/dashboard`}
            element={<Dashboard />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/program`}
            element={<ManageTrainList />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/program/write`}
            element={<WriteTraining />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/program/detail`}
            element={<TrainingDetail />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/result`}
            element={<ManageTrainResult />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/stat`}
            element={<ManageTrainStat />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/scenario`}
            element={<ManageScenario />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/scenario/write`}
            element={<WriteScenario />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/scenario/detail/*`}
            element={<DetailScenario />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/scenario/update/*`}
            element={<WriteScenario />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/content/site`}
            element={<TrainingSite />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/content/image`}
            element={<TrainingImage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manageTrain/content/document`}
            element={<TrainingDocument />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/course`}
            element={<QuizCourse />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/course/write`}
            element={<WriteQuizCourse />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/course/detail`}
            element={<QuizCourseDetail />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/content`}
            element={<Quiz />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/content/detail`}
            element={<QuizContentDetail />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/content/write`}
            element={<WriteQuiz />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/stat`}
            element={<QuizStat />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/template`}
            element={<QuizTemplate />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/template/write`}
            element={<WriteQuizTemplate />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/quiz/result`}
            element={<QuizResult />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/report/setting`}
            element={<ReportSetting />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/report/view`}
            element={<ReportView />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/report/view/detail`}
            element={<ReportViewDetail />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/report/statistics`}
            element={<ReportStatistics />}
          />
          <Route path={`${process.env.PUBLIC_URL}/organ`} element={<Organ />} />
          <Route
            path={`${process.env.PUBLIC_URL}/manager/access`}
            element={<ManageAccess />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manager/customer`}
            element={<ManageCustomer />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/manager/log`}
            element={<ManageLog />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/system`}
            element={<System />}
          />
        </Routes>
      </Box>
      {isPwdModalOpen && (
        <PwdModal
          isOpen={isPwdModalOpen}
          onClose={() => {
            changeErrorType({
              isError: false,
              errorTarget: "",
              errorMsg: "",
            });
            setChangePwdInfo({
              nowPwd: "",
              newPwd: "",
              checkPwd: "",
            });
            setIsPwdModalOpen(false);
          }}
          onSubmit={onChangePwd}
          changePwdInfo={changePwdInfo}
          setChangePwdInfo={setChangePwdInfo}
          errorType={errorType}
          changeErrorType={changeErrorType}
        />
      )}
    </Layout>
  );
};
export default BaseLayout;
