import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const BOOTSTRAP_ROUTES = [
  "manageTrain/scenario/update",
  "manageTrain/scenario/write",
  "quiz/template/write",
  "report/setting",
  "report/view/detail",
];

const BOOTSTRAP_CSS_ID = "bootstrap-css";
const BOOTSTRAP_CSS_URL =
  "https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css";

const DynamicHead = () => {
  const location = useLocation();

  const createBootstrapLink = () => {
    const link = document.createElement("link");
    link.href = BOOTSTRAP_CSS_URL;
    link.rel = "stylesheet";
    link.crossOrigin = "anonymous";
    link.id = BOOTSTRAP_CSS_ID;
    return link;
  };

  const shouldLoadBootstrap = (pathname: string) => {
    return BOOTSTRAP_ROUTES.some((route) => pathname.includes(route));
  };

  const cleanupModalElements = () => {
    // Summernote 관련 모달 및 백드롭 요소들 제거
    const modalElements = document.querySelectorAll(".modal, .modal-backdrop");
    modalElements.forEach((element) => element.remove());

    // body에 추가된 modal 관련 클래스 제거
    document.body.classList.remove("modal-open");
    document.body.style.removeProperty("padding-right");
  };

  useEffect(() => {
    const link = createBootstrapLink();

    if (shouldLoadBootstrap(location.pathname)) {
      if (!document.getElementById(BOOTSTRAP_CSS_ID)) {
        document.head.prepend(link);
      }
    } else {
      const existingLink = document.getElementById(BOOTSTRAP_CSS_ID);
      if (existingLink) {
        existingLink.remove();
      }
      cleanupModalElements();
    }

    return () => {
      link.remove();
    };
  }, [location]);

  return null;
};

export default DynamicHead;
