import { Box, Button, Typography } from "fds";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import {
  DUMMY_DOWNLOAD_ATTACHMENT,
  DUMMY_INSERT_DEPT,
  DUMMY_INSERT_USER,
  DUMMY_REPLY_INSERT,
  DUMMY_REPLY_UPDATE_REPLY,
  DUMMY_REPORT_COMMENT,
  DUMMY_REPORT_MAIL_CNT,
  DUMMY_REPORT_MAIL_LIST,
  DUMMY_REPORT_STAT_BY_PERIOD,
  DUMMY_SEND_REPLY,
} from "../../DummyData";
import useReportStore from "../../../redux/dispatcher/useReportStore";
import useReportStatStore from "../../../redux/dispatcher/useReportStatStrore";
import useReportRankStore from "../../../redux/dispatcher/useReportRankStore";
import useUserStore from "../../../redux/dispatcher/useUserStore";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import useIpStore from "../../../redux/dispatcher/useIpStore";
import useSignupStore from "../../../redux/dispatcher/useSignupStore";
import useConfigStore from "../../../redux/dispatcher/useConfigStore";

const DummyAPIPage2: React.FC = () => {
  const {
    getReplys,
    deleteReply,
    updateConfigReplyFlag,
    updateReply,
    insertReply,
    getManualReplys,
  } = useReplyStore();

  const {
    getReportMails,
    getReportMailCnt,
    getReportMailDetail,
    updateSpamFlag,
    updateComment,
    updateComplete,
    updateManualReplyState,
    sendManualReply,
    getReportAttachments,
    downloadAttachment,
  } = useReportStore();

  const {
    getReportStat,
    getReportStatByPeriod,
    getReportStatDetailPaging,
    getReportStatDetailByPeriod,
    getReportStatDetailByPeriodType,
    getConfigsByDate,
  } = useReportStatStore();
  const { getTrainConfigInfo } = useConfigStore();
  const {
    getRank,
    getRankByPeriod,
    getRankDeptDetail,
    getRankDeptDetailByPeriod,
    getRankDetail,
    getRankDetailByPeriod,
    getUserReportRankDetailByConfig,
    getConfigProgramInfo,
  } = useReportRankStore();

  const {
    getUsers,
    updateUserActivateFlag,
    updateUserDeactivateFlag,
    deleteUser,
    uploadUserCsv,
    insertUserCsv,
    setUser,
    decryptUser,
  } = useUserStore();

  const {
    getDeptList,
    getDeptListForUser,
    getDept,
    updateDept,
    uploadDeptCsv,
    insertDeptCsv,
    addDept,
    deleteDept,
  } = useDeptStore();

  const {
    getIpList,
    insertIp,
    deleteIp,
    updateIp,
    downloadFilteredLog,
    donwloadAccessLog,
    isExistsIp,
  } = useIpStore();

  const {
    isExistsEmail,
    sendAuthMail,
    isMatchAuthCode,
    isExistsReportAddress,
    doSignup,
  } = useSignupStore();

  return (
    <Box classes="main dummyAPI">
      {/* /reply start  */}
      <Typography type="subtitle2">/reply(s)</Typography>
      <Box classes="apis">
        <Button label="/replys" width={100} onClick={() => getReplys({})} />
        <Button
          label="/reply/delete"
          width={100}
          onClick={() => deleteReply([2])}
        />
        <Button
          label="/report/config/update"
          width={200}
          onClick={() =>
            updateConfigReplyFlag({ configId: 23, flag: true, sendAll: true })
          }
        />
        <Button
          label="/reply/update"
          width={100}
          onClick={() => updateReply(DUMMY_REPLY_UPDATE_REPLY)}
        />

        <Button
          label="/reply/insert"
          width={100}
          onClick={() => insertReply(DUMMY_REPLY_INSERT)}
        />

        <Button
          label="/replys/manual"
          width={150}
          onClick={() => getManualReplys()}
        />
      </Box>
      {/* reply end  */}
      {/* report start */}
      <Typography type="subtitle2">/report</Typography>
      <Box classes="apis">
        <Button
          label="/report/list"
          width={100}
          onClick={() => getReportMails(DUMMY_REPORT_MAIL_LIST)}
        />
        <Button
          label="/report/list/cnt"
          width={150}
          onClick={() => getReportMailCnt(DUMMY_REPORT_MAIL_CNT)}
        />
        <Button
          label={"/report/detail/${id}"}
          width={200}
          onClick={() => getReportMailDetail(50)}
        />
        <Button
          label="/report/update/spamFlag"
          width={250}
          onClick={() => updateSpamFlag({ flag: true, mailReportId: 50 })}
        />

        <Button
          label="/report/update/comment"
          width={200}
          onClick={() => updateComment(DUMMY_REPORT_COMMENT)}
        />

        <Button
          label="/report/update/complete"
          width={200}
          onClick={() => updateComplete({ flag: true, mailReportId: 50 })}
        />

        <Button
          label="/report/update/manualReply"
          width={200}
          onClick={() =>
            updateManualReplyState({ nowFlag: false, mailReportId: 50 })
          }
        />

        <Button
          label="/report/send"
          width={100}
          onClick={() => console.log("API CHANGEAS")}
        />

        <Box classes="apis">
          <Button
            label={"/report/attachments/${id}"}
            width={200}
            onClick={() => getReportAttachments(50)}
          />

          {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
          <Button
            label="/report/download"
            width={150}
            // onClick={() => downloadAttachment(DUMMY_DOWNLOAD_ATTACHMENT)}
          />
        </Box>
      </Box>
      {/* report end */}
      {/* report stat start */}
      <Box classes="apis">
        <Button
          label="/report/statistics"
          width={150}
          onClick={() => getReportStat([29])}
        />
        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button
          label="/report/statistics/period"
          width={200}
          onClick={() => getReportStatByPeriod(DUMMY_REPORT_STAT_BY_PERIOD)}
        />
        <Button
          label="/report/statistics/detail/paging"
          width={250}
          onClick={() =>
            getReportStatDetailPaging({ configId: 29, pageNum: 1, pageRow: 10 })
          }
        />
        <Button
          label="/report/statistics/detail/period"
          width={250}
          onClick={() =>
            getReportStatDetailByPeriod({
              startConfig: 1,
              endConfig: 29,
              month: 6,
              realTrainingFlag: null,
            })
          }
        />
        <Button
          label="/report/statistics/detail/period/type"
          width={250}
          onClick={() =>
            getReportStatDetailByPeriodType({
              startConfig: 1,
              endConfig: 29,
              month: 6,
              realTrainingFlag: false,
            })
          }
        />
        <Button
          label=""
          width={150}
          onClick={() => getConfigsByDate(DUMMY_REPORT_STAT_BY_PERIOD)}
        />
      </Box>
      {/* report stat end  */}

      {/* report rank start */}
      <Box classes="apis">
        <Button
          label="/report/rank"
          width={150}
          onClick={() => getRank([29])}
        />

        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button
          label="/report/rank/period"
          width={200}
          onClick={() =>
            getRankByPeriod({
              startDate: 1709251200,
              endDate: 1711929599,
              realTrainingFlag: null,
            })
          }
        />
        <Button
          label="/report/rank/detail"
          width={150}
          onClick={() =>
            getRankDetail({
              configIds: [23],
              ids: ["fd76be3e9dbc4a50b7ef76f496790c96"],
            })
          }
        />
        <Button
          label={"/report/rank/detail/config/${type}"}
          width={250}
          onClick={() =>
            getUserReportRankDetailByConfig({
              type: 1,
              configCodeListType: {
                configIds: [23],
                ids: ["fd76be3e9dbc4a50b7ef76f496790c96"],
              },
            })
          }
        />
        <Button
          label="/report/rank/detail/period"
          width={200}
          onClick={() =>
            getRankDetailByPeriod({
              endDate: "2024-03-25 16:24:26",
              ids: ["fd76be3e9dbc4a50b7ef76f496790c96"],
              startDate: "2023-03-25 16:24:26",
            })
          }
        />
        <Button
          label="/report/rank/dept/detail"
          width={200}
          onClick={() =>
            getRankDeptDetail({
              configIds: [23],
              ids: ["37aeb28411dd4b78bf2f1f304f531aae"],
            })
          }
        />

        <Button
          label="/report/rank/dept/detail/period"
          width={250}
          onClick={() =>
            getRankDeptDetailByPeriod({
              endDate: "2024-03-25 16:24:26",
              ids: ["37aeb28411dd4b78bf2f1f304f531aae"],
              startDate: "2023-03-25 16:24:26",
            })
          }
        />
        <Box classes="apis">
          <Button
            label="/getTrainConfigInfo"
            width={250}
            onClick={() => getTrainConfigInfo(23)}
          />
          <Button
            label={"/programs/${configId}"}
            width={200}
            onClick={() => getConfigProgramInfo(23)}
          />
        </Box>
      </Box>
      {/* report rank end  */}
      {/* user start */}
      <Typography type="subtitle2">/user(s)</Typography>
      <Box classes="apis">
        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button label="/users" width={100} onClick={() => getUsers()} />
        <Button
          label="/users/update/active"
          width={200}
          onClick={() =>
            updateUserActivateFlag(["58daf00b75cb4456a226391904dbbc4f"])
          }
        />
        <Button
          label="/users/update/deactive"
          width={200}
          onClick={() =>
            updateUserDeactivateFlag(["58daf00b75cb4456a226391904dbbc4f"])
          }
        />
        <Button
          label="/users/delete"
          width={150}
          onClick={() => deleteUser(["bff93af92e104356b7cc4691458cf6ba"])}
        />
        <Button
          label="/users/uploadCsv"
          width={200}
          onClick={() => {
            const formData = new FormData();
            uploadUserCsv(formData);
          }}
        />
        <Button
          label="/users/insertCsv"
          width={200}
          onClick={() => insertUserCsv([DUMMY_INSERT_USER])}
        />

        <Button
          label="/users/setUser"
          width={150}
          onClick={() => setUser(DUMMY_INSERT_USER)}
        />

        <Button
          label="/decrypt/user"
          width={150}
          onClick={() =>
            decryptUser({
              userEmail: "injubi@fasoo.com",
              userName: "인주비",
              dbName: "test_injubi",
            })
          }
        />
      </Box>
      {/* user end */}
      {/* dept start  */}
      <Typography type="subtitle2">/organ (dept)</Typography>
      <Box classes="apis">
        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button
          label="/organ/getDeptList"
          width={150}
          onClick={() => getDeptList()}
        />
        <Button
          label="/organ/getDeptListforUser"
          width={200}
          onClick={() => getDeptListForUser()}
        />
        {/* <Button
          width={150}
          onClick={() => getDept("37aeb28411dd4b78bf2f1f304f531aae")}
        >
          /organ/getDept
        </Button> */}
        <Button
          label="/organ/updateDept"
          width={180}
          onClick={
            () => console.log("API CHANGED")
            // updateDept({
            //   deptCode: "37aeb28411dd4b78bf2f1f304f531aae",
            //   deptName: "update test",
            // })
          }
        />
        <Button
          label="/organ/uploadCsv"
          width={200}
          onClick={() => {
            const formData = new FormData();
            uploadDeptCsv(formData);
          }}
        />
        <Button
          label="/organ/insertCsv"
          width={200}
          onClick={() =>
            insertDeptCsv({
              deptCodes: ["insertcsvtest"].toString(),
              deptNames: ["insertcsvTest"].toString(),
              parentCodes: ["COMPANY"].toString(),
            })
          }
        />

        <Button
          label="/organ/addDept"
          width={150}
          onClick={() => addDept(DUMMY_INSERT_DEPT)}
        />

        <Button
          label="/organ/deleteDept"
          width={180}
          onClick={() => deleteDept("codetest2222")}
        />
      </Box>
      {/* dept end */}
      {/* ip start  */}
      <Typography type="subtitle2">/ip (log)</Typography>
      <Box classes="apis">
        {/* local 경로일 경우 해당되는 파일로 테스트 해야 함 */}
        <Button label="/ip/list" width={100} onClick={() => getIpList({})} />
        <Button
          label="/ip/add"
          width={100}
          onClick={() =>
            insertIp({
              ipType: "W",
              ipAddress: "127.0.0.1",
              createDate: null,
              createUser: "admin",
              summary: "insert ip test",
            })
          }
        />
        <Button
          label="/ip/update"
          width={100}
          onClick={() =>
            updateIp({
              ipId: 1,
              ipType: "W",
              ipAddress: "127.0.0.1",
              createDate: null,
              createUser: "admin",
              summary: "update ip test",
            })
          }
        />
        <Button label="/ip/delete" width={100} onClick={() => deleteIp([1])} />
        <Button
          label="/log/filteredLog"
          width={180}
          onClick={() =>
            downloadFilteredLog({
              // customerName: "test_injubi",
              configId: 23,
              type: "W",
            })
          }
        />

        {/* <Button width={200} onClick={() => donwloadAccessLog(["2024-03-16"])}>
          /log/accessDownload
        </Button> */}

        <Button
          label="/ip/exist"
          width={100}
          onClick={() => isExistsIp("127.0.0.1")}
        />
      </Box>
      {/* ip end */}

      {/* signup start */}
      <Typography type="subtitle2">/signup (account)</Typography>
      <Box classes="apis">
        <Button
          label="/account/exists"
          width={150}
          onClick={() => isExistsEmail("injubi@fasoo.com")}
        />
        <Button
          label="/signup/send"
          width={100}
          onClick={() => sendAuthMail("injubi2@fasoo.com")}
        />
        <Button
          label="/signup/auth"
          width={100}
          onClick={() =>
            isMatchAuthCode({
              email: "injubi2@fasoo.com",
              code: "000",
            })
          }
        />
        <Button
          label="/signup/reportAddress"
          width={180}
          onClick={() => isExistsReportAddress("test_injubi@mind-sat.com")}
        />

        <Button
          label="/signup"
          width={100}
          onClick={() =>
            doSignup({
              email: "injubi2@fasoo.com",
              customerName: "인주비",
              companyName: "signupTest",
              reportInfo: "injubi2@mind-sat.com",
              serviceType: 1,
              managerPwd: "dlswnql0",
              accessMenu: [1],
              categoryId: 11,
            })
          }
        />
      </Box>
      {/* signup end */}
    </Box>
  );
};

export default DummyAPIPage2;
