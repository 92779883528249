import {
  Box as BoxNew,
  getColor,
  Modal as ModalNew,
  Tooltip as TooltipNew,
  Typography as TypographyNew,
  Checkbox as CheckboxNew,
  InputField,
  Button as ButtonNew,
} from "fds";

import { QuizContentDTO } from "../../../types/Quiz";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { useTranslation } from "react-i18next";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import ScrollBox from "../../../component/ScrollBox";
import classNames from "classnames";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import NoFile from "../../../shared/image/NoFile";

interface QuizAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedQuizList: QuizContentDTO[];
  setSelectedQuizList: Dispatch<SetStateAction<QuizContentDTO[]>>;
}
const QuizModal: React.FC<QuizAddModalProps> = ({
  isOpen,
  onClose,
  selectedQuizList,
  setSelectedQuizList,
}) => {
  const { t } = useTranslation();
  const [categoryInput, setCategoryInput] = useState<string>();
  const [quizInput, setQuizInput] = useState<string>("");
  const [categories, setCategories] = useState<string[]>([]);
  const [quizContents, setQuizContents] = useState<QuizContentDTO[]>([]);

  const checkboxCateGroup = useCheckboxGroup();
  const checkboxQuizGroup = useCheckboxGroup();

  const { getQuizContentList, getQuizCategory, quizState } = useQuizStore();

  useEffect(() => {
    getQuizCategory();
    if (selectedQuizList.length > 0) {
      checkboxQuizGroup.handleAllClick(
        selectedQuizList.map((item) => item.quizId)
      );
    }
    getQuizContentList({});
  }, []);

  useEffect(() => {
    if (
      quizState.quizContentCategory &&
      quizState.quizContentCategory.length > 0
    ) {
      setCategories(quizState.quizContentCategory);
    }
  }, [quizState.quizContentCategory]);

  useEffect(() => {
    if (
      quizState.quizContentList &&
      quizState.quizContentList.list.length > 0
    ) {
      setQuizContents(quizState.quizContentList.list);
    }
  }, [quizState.quizContentList]);

  useEffect(() => {
    if (checkboxCateGroup.selected.length === 0) {
      setQuizContents(quizState.quizContentList.list);
    } else {
      setQuizContents(
        quizState.quizContentList.list.filter((item: QuizContentDTO) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                (checkboxCateGroup.selected as string[]).some((val) =>
                  item.categorys?.includes(val)
                )
            : item;
        })
      );
    }
  }, [checkboxCateGroup.selected]);

  useEffect(() => {
    let newCategory = categoryInput?.trim();
    if (newCategory && newCategory.length > 0) {
      setCategories(
        quizState.quizContentCategory
          .filter((item: string) => item.includes(newCategory))
          ?.map((c: string) => c) ?? []
      );
    } else {
      setCategories(
        quizState.quizContentCategory?.map((item: string) => item) ?? []
      );
    }
  }, [categoryInput]);

  useEffect(() => {
    if (quizInput && quizInput.length > 0) {
      setQuizContents(
        quizState.quizContentList.list
          .filter((item: QuizContentDTO) => {
            return checkboxCateGroup.selected?.length > 0
              ? item.categorys &&
                  (checkboxCateGroup.selected as string[]).some((val) =>
                    item.categorys?.includes(val)
                  )
              : item;
          })
          .filter(
            (item: QuizContentDTO) =>
              item.quizName && item.quizName.includes(quizInput)
          )
      );
    } else {
      setQuizContents(
        quizState.quizContentList.list.filter((item: QuizContentDTO) => {
          return checkboxCateGroup.selected?.length > 0
            ? item.categorys &&
                (checkboxCateGroup.selected as string[]).some((val) =>
                  item.categorys?.includes(val)
                )
            : item;
        })
      );
    }
  }, [quizInput]);

  const onSubmit = () => {
    if (checkboxQuizGroup.selected.length === 0) {
      setSelectedQuizList(quizContents);
    } else {
      setSelectedQuizList(
        quizState.quizContentList.list.filter((item: any) =>
          checkboxQuizGroup.isSelected(item.quizId)
        )
      );
    }
    onClose();
  };

  const footer = (
    <BoxNew justifyContent="flex-end">
      <ButtonNew
        btnStyle="fill"
        type="secondary"
        onClick={onClose}
        label={t("common.button.cancel")}
        size="md"
      />
      <ButtonNew
        classes="mg ml-8"
        btnStyle="fill"
        type="primary"
        onClick={onSubmit}
        state={checkboxQuizGroup.selected.length === 0 ? "disable" : "default"}
        label={t("common.button.apply")}
      />
    </BoxNew>
  );
  return (
    <ModalNew
      open={isOpen}
      title={t("quiz.label.choiceQuizContent")}
      width={720}
      onClose={onClose}
      footer={footer}
      classes="quizSelectModal"
      body={
        <BoxNew classes="gap-8">
          <BoxNew direction="column" width={"49%"}>
            <BoxNew direction="column">
              <BoxNew classes="mg mb-16">
                <TypographyNew type={"subtitle2"} fontWeight={700}>
                  {t("quiz.label.category")}
                </TypographyNew>
              </BoxNew>
              <InputField
                placeholder={t("quiz.label.categoryHolder")}
                value={categoryInput && categoryInput}
                size={"md"}
                onChange={(e) => setCategoryInput(e.target.value)}
              />
            </BoxNew>
            <ScrollBox height={448} classes="border-box mg mt-16">
              {categories && categories?.length > 0 ? (
                <>
                  {!categoryInput && (
                    <BoxNew
                      classes={classNames("selects-box", {
                        selected: checkboxCateGroup.selected.length === 0,
                      })}
                      onClick={() => {
                        checkboxCateGroup.handleAllClick([]);
                      }}
                    >
                      <BoxNew alignItems="center">
                        <TypographyNew
                          type="body2_rg"
                          exactColor={getColor("COLOR_TEXT_PRIMARY")}
                          classes="mg ml-8"
                        >
                          {t("common.auth.all")}
                        </TypographyNew>
                      </BoxNew>
                      <CheckboxNew
                        roundshape
                        check={
                          checkboxCateGroup.selected.length > 0 ? false : true
                        }
                      />
                    </BoxNew>
                  )}
                  {categories?.map((item) => (
                    <BoxNew
                      key={item}
                      classes={classNames("selects-box", {
                        selected: checkboxCateGroup.isSelected(item),
                      })}
                      onClick={() => {
                        checkboxCateGroup.handleClick(item);
                        setQuizInput("");
                      }}
                    >
                      <TooltipNew message={item}>
                        <TypographyNew
                          type="body2_rg"
                          exactColor={getColor("COLOR_TEXT_PRIMARY")}
                          classes="mg ml-8"
                          ellipsis
                          maxWidth="280px"
                          whiteSpace="pre"
                        >
                          {item}
                        </TypographyNew>
                      </TooltipNew>
                      <CheckboxNew
                        roundshape
                        check={checkboxCateGroup.isSelected(item)}
                      />
                    </BoxNew>
                  ))}
                </>
              ) : categoryInput &&
                categoryInput?.length > 0 &&
                categories.length === 0 ? (
                <BoxNew
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  width="100%"
                  classes="no-result-margin"
                >
                  <NoSearchResultImage width={200} />
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-16"
                  >
                    {t("common.msg.noSearchMsg")}
                  </TypographyNew>
                </BoxNew>
              ) : (
                <BoxNew
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  width="100%"
                  classes="no-result-margin"
                >
                  <NoFile />
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-16"
                  >
                    {t("common.msg.noCategorySearched")}
                  </TypographyNew>
                </BoxNew>
              )}
            </ScrollBox>
          </BoxNew>
          <BoxNew direction="column" width={"49%"}>
            <BoxNew direction="column">
              <BoxNew classes="mg mb-16">
                <TypographyNew type="subtitle2">
                  {t("quiz.label.quizQuestion")}
                </TypographyNew>
              </BoxNew>
              <InputField
                placeholder={t("quiz.label.quizQuestionsHolder")}
                size="md"
                value={quizInput && quizInput}
                onChange={(e) => setQuizInput(e.target.value)}
              />
            </BoxNew>
            <ScrollBox height={448} classes="border-box mg mt-16">
              {quizContents && quizContents?.length > 0 ? (
                <>
                  {!quizInput && (
                    <BoxNew
                      classes={classNames("selects-box", {
                        selected: quizContents.every((item) =>
                          checkboxQuizGroup.selected.includes(item.quizId)
                        ),
                      })}
                      onClick={() => {
                        if (
                          checkboxQuizGroup.selected.length ===
                          quizContents.length
                        ) {
                          checkboxQuizGroup.handleAllClick([]);
                        } else {
                          checkboxQuizGroup.handleAllClick(
                            quizContents.map((item) => item.quizId)
                          );
                        }
                      }}
                    >
                      <BoxNew alignItems="center">
                        <TypographyNew
                          classes="mg ml-8"
                          type="body2_rg"
                          exactColor={getColor("COLOR_TEXT_PRIMARY")}
                        >
                          {t("common.auth.all")}
                        </TypographyNew>
                      </BoxNew>
                      <CheckboxNew
                        roundshape
                        check={quizContents.every((item) =>
                          checkboxQuizGroup.selected.includes(item.quizId)
                        )}
                      />
                    </BoxNew>
                  )}

                  {quizContents?.map((item) => (
                    <BoxNew
                      key={item.quizId}
                      classes={classNames("selects-box", {
                        selected: checkboxQuizGroup.isSelected(item.quizId),
                      })}
                      onClick={() => {
                        checkboxQuizGroup.handleClick(item.quizId);
                      }}
                    >
                      <TooltipNew message={item.quizName}>
                        <TypographyNew
                          type="body2_rg"
                          exactColor={getColor("COLOR_TEXT_PRIMARY")}
                          classes="mg ml-8"
                          maxWidth="270px"
                          whiteSpace="pre"
                          ellipsis={true}
                        >
                          {item.quizName}
                        </TypographyNew>
                      </TooltipNew>
                      <CheckboxNew
                        classes="mg ml-2"
                        roundshape={true}
                        check={checkboxQuizGroup.isSelected(item.quizId)}
                      />
                    </BoxNew>
                  ))}
                </>
              ) : quizInput &&
                quizInput.length > 0 &&
                quizContents.length === 0 ? (
                <BoxNew
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  classes="no-result-margin"
                  width="100%"
                >
                  <NoSearchResultImage width={200} />
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-16"
                  >
                    {t("common.msg.noSearchMsg")}
                  </TypographyNew>
                </BoxNew>
              ) : (
                <BoxNew
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  classes="no-result-margin"
                  width="100%"
                >
                  <NoFile />
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    classes="mg mt-16"
                  >
                    {t("quiz.msg.noQuizSearched")}
                  </TypographyNew>
                </BoxNew>
              )}
            </ScrollBox>
          </BoxNew>
        </BoxNew>
      }
    />
  );
};

export default QuizModal;
