import {
  Modal as ModalNew,
  Box as BoxNew,
  useToast as useToastNew,
  InputField,
  Typography as TypographyNew,
  Button as ButtonNew,
} from "fds";

import { useEffect, useRef, useState } from "react";
import { ImagesInfo } from "../../../types/Info";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface RegitOrEditSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageId?: number;
  setRowIdCreated?: (id: string) => void;
}

const RegitOrEditImageModal: React.FC<RegitOrEditSiteModalProps> = ({
  isOpen,
  onClose,
  imageId,
  setRowIdCreated,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<any>(null);
  const [file, setFile] = useState<File | null>();
  const [imageName, setImageName] = useState<string | null>();
  const [imageOrgName, setImageOrgName] = useState<string | null>();

  const { contentState, uploadImage, updateImage, getImages } =
    useContentStore();
  const toast = useToastNew();

  const ext = ["gif", "svg", "png", "jpg"];

  useEffect(() => {
    if (imageId) {
      const image = contentState.images.filter(
        (item: ImagesInfo) => item.imageId === imageId
      )[0];
      setImageName(image.imageName ?? null);
      setImageOrgName(image.imageOrgName ?? null);
      setFile(null);
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    }
  }, [imageId]);

  const onSubmit = async () => {
    //유효성 검사

    if (imageName) {
      if (/^\s*$/.test(imageName)) {
        toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
        return;
      }

      if (imageName?.length > 128) {
        toast.toastMsg(nanoid(), t("common.msg.fieldLengthLimitMsg"), "error");
        return;
      }
      if (imageId) {
        const resp: any = await updateImage({
          request: { imageName, imageId: imageId },
          file: file ?? null,
        });

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageUpdateSuccessMsg"),
            "success"
          );
          getImages({});
        } else {
          if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
            toast.toastMsg(
              nanoid(),
              t("image.msg.imageDuplicatedNameMsg"),
              "error"
            );
            return;
          }
          toast.toastMsg(nanoid(), t("image.msg.imageUpdateErrorMsg"), "error");
        }
      } else {
        if (!file) {
          toast.toastMsg(nanoid(), t("image.msg.nofilePlaceholder"), "error");
          return;
        }

        const resp: any = await uploadImage({ file, request: { imageName } });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageAddSuccessMsg"),
            "success"
          );
          const idCreatedImage = resp.payload.data?.imageId || "";
          setRowIdCreated && setRowIdCreated(idCreatedImage.toString());
          getImages({});
        } else {
          if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
            toast.toastMsg(
              nanoid(),
              t("image.msg.imageDuplicatedNameMsg"),
              "error"
            );
            return;
          }

          toast.toastMsg(nanoid(), t("image.msg.imageInsertErrorMsg"), "error");
        }
      }
      setFile(null);
      setImageName(null);
      onClose();
    } else {
      toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
    }
  };

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleFileChange = (e: any) => {
    let value = "";
    const name = e.target.name;

    if (name === "file") {
      const fileName = e.target.files[0] && e.target.files[0].name;
      const idx = fileName ? fileName.lastIndexOf(".") : -1;
      if (idx > 0) {
        const extension = fileName.substring(idx + 1);
        // 확장자 체크
        if (!ext.includes(extension.toLowerCase())) {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageInvalidExtenstionMsg"),
            "error"
          );
          e.target.value = "";
          return;
        }
      }

      // 사이즈 체크
      if (e.target.files[0] && e.target.files[0].size > 20971520) {
        toast.toastMsg(
          nanoid(),
          t("attach.msg.attachInvalidFileSizeMsg"),
          "error"
        );
        e.target.value = "";
        return;
      }

      setFile(e.target.files[0]);
    } else {
      value = e.target.value.trim();
    }
  };

  const checkIfSaveActivated = () => {
    return imageId ? imageName : imageName && file;
  };

  return (
    <ModalNew
      open={isOpen}
      onClose={onClose}
      width={540}
      title={imageId ? t("image.update") : t("image.add")}
      footer={
        <BoxNew justifyContent="flex-end" width="100%">
          <ButtonNew
            btnStyle="fill"
            type="secondary"
            onClick={onClose}
            label={t("common.button.cancel")}
          />
          <ButtonNew
            btnStyle="fill"
            type="primary"
            classes="mg ml-8"
            onClick={onSubmit}
            state={checkIfSaveActivated() ? "default" : "disable"}
            label={t("common.button.save")}
          />
        </BoxNew>
      }
      body={
        <BoxNew direction="column">
          <BoxNew direction="column">
            <TypographyNew type="subtitle2">{t("image.name")}</TypographyNew>
            <InputField
              classes="mg mt-12"
              value={imageName ?? ""}
              onChange={(e: any) => setImageName(e.target.value)}
              placeholder={t("template.msg.imageNamePlaceholder")}
              maxLength={128}
              size={"md"}
            />
          </BoxNew>
          <BoxNew direction="column" classes="mg mt-32">
            <TypographyNew type="subtitle2">
              {t("common.button.imageUpload")}
            </TypographyNew>
            <BoxNew
              alignItems="center"
              width={600}
              height={50}
              classes="mg mt-12"
            >
              <input
                style={{ display: "none" }}
                name="file"
                type="file"
                onChange={handleFileChange}
                className="modalField hide"
                ref={hiddenFileInput}
                accept="image/*"
              />
              <InputField
                size="md"
                name="fileName"
                value={imageOrgName ? imageOrgName : file ? file.name : ""}
                placeholder={t("image.msg.nofilePlaceholder")}
                width={376}
                classes="mg mr-8"
              />
              <ButtonNew
                btnStyle="fill"
                type="secondary"
                onClick={handleFileClick}
                label={t("common.button.selectFile")}
                width={100}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      }
    />
  );
};

export default RegitOrEditImageModal;
