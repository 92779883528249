import { Box, Icons } from "fds";
import { useNavigate } from "react-router-dom";

const ButtonGoBack = ({ referer }: { referer: string }) => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => (referer ? navigate(referer) : navigate(-1))}
      style={{ cursor: "pointer" }}
    >
      <Icons variant="line" label="arrow" direction="left" classes="mg mr-8" />
    </Box>
  );
};

export default ButtonGoBack;
