import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Button as ButtonNew,
  PageIndicator,
  Modal,
  Chip,
  InputField,
} from "fds";

import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuizContentDTO } from "../../../../types/Quiz";
import Divider from "../../../../component/Divider";
import QuizChoicePreview from "./QuizChoicePreview";
import QuizOXPreview from "./QuizOXPreview";

const QuizzesPreview = ({
  currentPage,
  changeCurrentPage,
  aiQuizExamples,
  setAIQuizExamples,
  isOX,
}: {
  currentPage: number;
  changeCurrentPage: React.Dispatch<SetStateAction<number>>;
  aiQuizExamples: QuizContentDTO[];
  setAIQuizExamples: React.Dispatch<SetStateAction<QuizContentDTO[]>>;
  isOX: boolean;
}) => {
  const quizIdx = currentPage - 1;
  const quiz = aiQuizExamples[quizIdx];
  const [isModalExcludeOpen, setIsModalExcludeOpen] = useState(false);

  const { t } = useTranslation();

  function updateQuizName(quizName: string) {
    setAIQuizExamples((quizzes) => {
      const newQuizzes = [...quizzes];
      const newQuiz = newQuizzes[quizIdx];
      newQuiz.quizName = quizName;
      newQuizzes[quizIdx] = newQuiz;
      return newQuizzes;
    });
  }

  function addQuizCategory(quizCategory: string) {
    if (aiQuizExamples[quizIdx].categorys?.includes(quizCategory)) return;
    setAIQuizExamples((quizzes) => {
      const newQuizzes = [...quizzes];
      const newQuiz = newQuizzes[quizIdx];
      newQuiz.categorys = [...(newQuiz.categorys || []), quizCategory];
      newQuizzes[quizIdx] = newQuiz;
      return newQuizzes;
    });
  }

  function deleteQuizCategory(quizCategory: string) {
    setAIQuizExamples((quizzes) => {
      const newQuizzes = [...quizzes];
      const newQuiz = quizzes[quizIdx];
      newQuiz.categorys = (newQuiz.categorys || []).filter(
        (category) => category !== quizCategory
      );
      newQuizzes[quizIdx] = newQuiz;
      return newQuizzes;
    });
  }

  function deleteQuiz(idx: number) {
    setAIQuizExamples((quizzes) => {
      return [...quizzes].filter((_, i) => i !== idx);
    });
  }

  const getInputHelpMsg = (value: string, maxLength: number) => {
    if (value.length > maxLength) {
      return t("common.msg.maxLengthExceed");
    }
    return "";
  };

  const [categoryInput, setCategoryInput] = useState("");

  useEffect(() => {
    setCategoryInput("");
  }, [quizIdx]);

  return (
    <BoxNew direction="column" alignItems="center">
      <BoxNew direction="column" width={"100%"}>
        <Divider direction="row" classes="mg mt-20 mb-20" />
        <TypographyNew
          type={"subtitle2"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {t("quiz.label.aiPreview")}
        </TypographyNew>

        <BoxNew direction="column" classes="aiPrompt-quizAnswer mg mt-16">
          <BoxNew direction="column" classes="gap-12">
            <BoxNew alignItems="center">
              <BoxNew width={100}>
                <TypographyNew
                  type={"subtitle2"}
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.quizAiModal.label.name")}
                </TypographyNew>
                <TypographyNew
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_ERROR")}
                >
                  &nbsp;*
                </TypographyNew>
              </BoxNew>
              <InputField
                placeholder={t("quiz.quizAiModal.msg.namePlaceholder")}
                width={240}
                value={quiz.quizName}
                onChange={(e) => updateQuizName(e.target.value)}
                isError={
                  !quiz.quizName ||
                  getInputHelpMsg(quiz.quizName || "", 128) !== ""
                }
                helpMessage={getInputHelpMsg(quiz.quizName || "", 128)}
                maxLength={128}
              />
            </BoxNew>
            <BoxNew alignItems="center">
              <TypographyNew
                type={"subtitle2"}
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
                width={100}
              >
                {t("quiz.quizAiModal.label.category")}
              </TypographyNew>
              <BoxNew direction="column">
                <InputField
                  placeholder={t("quiz.quizAiModal.msg.categoryPlaceholder")}
                  width={240}
                  value={categoryInput}
                  onChange={(e) => setCategoryInput(e.target.value)}
                  maxLength={30}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      addQuizCategory(categoryInput);
                      setCategoryInput("");
                    }
                  }}
                  isError={getInputHelpMsg(categoryInput || "", 30) !== ""}
                  helpMessage={getInputHelpMsg(categoryInput || "", 30)}
                />
                <BoxNew
                  classes="gap-4 mg mt-8"
                  width={240}
                  style={{ flexWrap: "wrap" }}
                >
                  {(quiz.categorys || []).length > 0 &&
                    (quiz.categorys || []).map((category) => (
                      <Chip
                        key={`tempConfigName-chip-${category}`}
                        type={"fill"}
                        size={"sm"}
                        showClose={true}
                        text={category}
                        onClick={() => deleteQuizCategory(category)}
                      />
                    ))}
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </BoxNew>
          {isOX ? (
            <QuizOXPreview
              quiz={quiz}
              openModalExclude={() => setIsModalExcludeOpen(true)}
            />
          ) : (
            <QuizChoicePreview
              quiz={quiz}
              openModalExclude={() => setIsModalExcludeOpen(true)}
            />
          )}
        </BoxNew>
      </BoxNew>
      <PageIndicator
        currentPage={aiQuizExamples.length === 1 ? 0 : currentPage}
        itemsCount={aiQuizExamples.length}
        listSize={1}
        onPageChange={(page: number) => changeCurrentPage(page)}
        pageSize={aiQuizExamples.length}
        showListSize={false}
      />
      <Modal
        zIndex={1000}
        open={isModalExcludeOpen}
        onClose={() => setIsModalExcludeOpen(false)}
        title={t("quiz.quizAiModal.label.excludeModalTitle")}
        width={540}
        body={
          <BoxNew>
            <TypographyNew
              type={"body2_rg"}
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.quizAiModal.msg.excludeModalMsg")}
            </TypographyNew>
          </BoxNew>
        }
        footer={
          <BoxNew>
            <ButtonNew
              btnStyle={"fill"}
              label="취소"
              type="secondary"
              onClick={() => setIsModalExcludeOpen(false)}
            />
            <ButtonNew
              btnStyle={"fill"}
              label="제외"
              onClick={() => {
                currentPage !== 1 && changeCurrentPage(currentPage - 1);
                deleteQuiz(quizIdx);
                setIsModalExcludeOpen(false);
              }}
            />
          </BoxNew>
        }
      />
    </BoxNew>
  );
};

export default QuizzesPreview;
