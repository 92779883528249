// 훈련 시나리오 필터 활성화 조건 체크 유틸 클래스

// TODO: 전체적인 필터 유틸 클래스로 뺄 수 있을지 고민... 다른 페이지도 같이 수정하면 좋을지 고민...
class FilterCheckerUtils {
  constructor(
    private scenarioType: { id: number },
    private searchFilter: {
      templateNames?: string[];
      categorys?: string[];
      documentId?: boolean;
      imageId?: boolean;
      siteId?: boolean;
    }
  ) {}

  // 타입 필터 활성화 조건 체크
  checkType(): boolean {
    return this.scenarioType.id !== 0;
  }

  // 템플릿 필터 활성화 조건 체크
  checkTemplate(): boolean {
    return Boolean(this.searchFilter.templateNames?.length);
  }

  // 카테고리 필터 활성화 조건 체크
  checkCategory(): boolean {
    return Boolean(this.searchFilter.categorys?.length);
  }

  // 모든 콘텐츠 필터 활성화 조건 체크
  checkAllContent(): boolean {
    return Boolean(
      this.searchFilter.documentId &&
        this.searchFilter.imageId &&
        this.searchFilter.siteId
    );
  }

  // 콘텐츠 필터 활성화 조건 체크
  checkContent(): boolean {
    return !this.checkAllContent();
  }

  // 모든 필터 활성화 조건 체크
  checkAnyFilterSelected(): boolean {
    return (
      this.checkType() ||
      this.checkTemplate() ||
      this.checkCategory() ||
      this.checkContent()
    );
  }
}

export default FilterCheckerUtils;
