export class DataStructureUtils {
  public static isObjectRequiredFilled(
    obj: any,
    requiredKeys: string[]
  ): boolean {
    const isAllKeyRequired = requiredKeys.length === 0;
    const requiredKeysNew = isAllKeyRequired ? Object.keys(obj) : requiredKeys;

    for (let i = 0; i < requiredKeysNew.length; i++) {
      const key = requiredKeysNew[i];
      const value = obj[key];
      if (typeof value === "object" && Array.isArray(value)) {
        if (value.length === 0) {
          return false;
        }
        return value.every(
          (item) => item !== undefined && item !== null && item !== ""
        );
      }
      if (value === undefined || value === null || value === "") return false;
    }

    return true;
  }

  public static isObjectEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;
    if (obj1 == null || obj2 == null) return obj1 === obj2;
    if (typeof obj1 !== typeof obj2) return false;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;
      return obj1.every((item, index) => this.isObjectEqual(item, obj2[index]));
    }

    if (typeof obj1 === "object" && typeof obj2 === "object") {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (let i = 0; i < keys1.length; i++) {
        const key = keys1[i];
        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          if (!this.isObjectEqual(obj1[key], obj2[key])) return false;
        } else {
          if (obj1[key] !== obj2[key]) return false;
        }
      }
      return true;
    }

    return false;
  }
}
