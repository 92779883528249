import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Button as ButtonNew,
} from "fds";

import { useTranslation } from "react-i18next";
import { QuizContentDTO } from "../../../../types/Quiz";

const QuizOXPreview = ({
  quiz,
  openModalExclude,
}: {
  quiz: QuizContentDTO;
  openModalExclude: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <BoxNew>
        <TypographyNew
          type={"subtitle2"}
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          width={100}
        >
          {t("quiz.label.question")}
        </TypographyNew>
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="flex-1"
        >
          {quiz.quizBody}
        </TypographyNew>
      </BoxNew>
      <BoxNew>
        <TypographyNew
          type={"subtitle2"}
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          width={100}
        >
          {t("quiz.label.answerSimple")}
        </TypographyNew>
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="flex-1"
        >
          {quiz.quizAnswer === 1
            ? t("quiz.label.choiceO")
            : t("quiz.label.choiceX")}
        </TypographyNew>
      </BoxNew>
      <BoxNew>
        <TypographyNew
          type={"subtitle2"}
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          width={100}
        >
          {t("quiz.label.answerEx")}
        </TypographyNew>
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="flex-1"
        >
          {quiz.quizComments}
        </TypographyNew>
      </BoxNew>
      <BoxNew justifyContent="flex-end">
        <ButtonNew
          btnStyle={"link"}
          size={"min"}
          label={t("quiz.quizAiModal.label.exclude")}
          onClick={openModalExclude}
          textColor={getColor("COLOR_TEXT_SECONDARY")}
        />
      </BoxNew>
    </>
  );
};

export default QuizOXPreview;
