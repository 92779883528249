import {
  Box as BoxNew,
  Button as ButtonNew,
  getColor,
  Typography as TypographyNew,
} from "fds";

import { useTranslation } from "react-i18next";
import { QuizContentDTO } from "../../../../types/Quiz";

const QuizChoicePreview = ({
  quiz,
  openModalExclude,
}: {
  quiz: QuizContentDTO;
  openModalExclude: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <BoxNew>
        <TypographyNew
          type={"subtitle2"}
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          width={100}
        >
          {t("quiz.label.question")}
        </TypographyNew>
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="flex-1"
        >
          {quiz.quizBody}
        </TypographyNew>
      </BoxNew>
      <BoxNew alignItems="flex-start">
        <TypographyNew
          type={"subtitle2"}
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          width={100}
        >
          {t("quiz.label.choice")}
        </TypographyNew>
        <BoxNew direction="column" classes="flex-1">
          {(quiz.quizChoices || []).map((choice, index) => (
            <BoxNew
              alignItems="flex-start"
              classes={
                quiz.quizChoices && index !== quiz.quizChoices.length - 1
                  ? "mg mb-16"
                  : ""
              }
            >
              <TypographyNew
                type={"subtitle2"}
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
                width={32}
              >
                {index + 1}
              </TypographyNew>
              <TypographyNew
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
                classes="flex-1"
              >
                {choice}
              </TypographyNew>
            </BoxNew>
          ))}
        </BoxNew>
      </BoxNew>
      <BoxNew>
        <TypographyNew
          type={"subtitle2"}
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          width={100}
        >
          {t("quiz.label.answerSimple")}
        </TypographyNew>
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
          classes="flex-1"
        >
          {quiz.quizChoices &&
          quiz.quizAnswer !== undefined &&
          quiz.quizChoices.length > 0
            ? quiz.quizAnswer + 1
            : quiz.quizAnswer === 1}
        </TypographyNew>
      </BoxNew>
      <BoxNew direction="column">
        <BoxNew>
          <TypographyNew
            type={"subtitle2"}
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
            width={100}
          >
            {t("quiz.label.answerEx")}
          </TypographyNew>
          <TypographyNew
            type={"body2_rg"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            classes="flex-1"
          >
            {quiz.quizComments}
          </TypographyNew>
        </BoxNew>
        <BoxNew justifyContent="flex-end">
          <ButtonNew
            btnStyle={"link"}
            size={"min"}
            label={t("quiz.quizAiModal.label.exclude")}
            onClick={openModalExclude}
            textColor={getColor("COLOR_TEXT_SECONDARY")}
          />
        </BoxNew>
      </BoxNew>
    </>
  );
};

export default QuizChoicePreview;
