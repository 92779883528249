import "./index.scss";
import "../../../shared/style/stickyLayout.scss";

import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Accordions as AccordionsNew,
  InputField,
  Icons as IconsNew,
  Button as ButtonNew,
  Tag as TagNew,
  Calendar as CalendarNew,
  Toggle as ToggleNew,
  Table as TableNew,
  Tooltip as TooltipNew,
  TimePicker,
  Dropdown,
  DropdownItem,
  useToast,
  Tag,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { QuizContentDTO, QuizCourseDTO } from "../../../types/Quiz";
import NoQuizImage from "../../../shared/image/NoQuizImage";
import QuizModal from "./QuizModal";
import { useTranslation } from "react-i18next";
import UserModal from "./UserModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { SimpleTreeNode, UserType } from "../../../types/User";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import NoSelectedUserImage from "../../../shared/image/NoSelectedUserImage";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import { nanoid } from "@reduxjs/toolkit";
import { DeptType } from "../../../types/Dept";
import OrganCard from "../../../component/OrganCard";
import { useElementPosition } from "../../../hooks/useElementPosition";
import classNames from "classnames";
import useStickyPage from "../../../hooks/useStickyObserver";
import EditCancelModal from "./EditCancelModal";
import useNavigateState from "../../../hooks/useNavigateState";
import { NavigateStateKeys } from "../../../hooks/NavigateStateKeys";

const WriteQuizCourse: React.FC = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get("course_id");
  const navigate = useNavigate();
  const [course, setCourse] = useState<QuizCourseDTO>({
    courseName: "",
    activateFlag: false,
    quizSolvePeriod: 7,
  });
  const [type, changeType] = useState<"CREATE" | "UPDATE">("CREATE");

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);
  const [isAllDayDate, setIsAllDayDate] = useState<boolean>(false);
  // const [startTime, setStartTime] = useState<string>("");
  // const [endTime, setEndTime] = useState<string>("");
  const [isAllDayTime, setIsAllDayTime] = useState<boolean>(false);
  const [isStarted, changeIsStared] = useState(false);

  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [isQuizModalOpen, setIsQuizModalOpen] = useState<boolean>(false);

  const [selectedUserInfo, setSelectedUserInfo] = useState<UserType[]>([]);
  const [selectedDeptInfo, setSelectedDeptInfo] = useState<DeptType[]>([]);

  const [selectedQuizList, setSelectedQuizList] = useState<QuizContentDTO[]>(
    []
  );

  const [userList, setUserList] = useState<UserType[]>();

  const userCheckboxGroup = useCheckboxGroup<string>();
  const deptCheckboxGroup = useCheckboxGroup<string>();
  const quizCheckboxGroup = useCheckboxGroup<number | undefined>();

  const [searchWord, setSearchWord] = useState("");
  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();

  const { elementRef, sentinel, isAtTop, isHorizontallyScrollable } =
    useStickyPage();
  const { deptState, getDeptList } = useDeptStore();
  const {
    insertQuizCourse,
    updateQuizCourse,
    getSelectedOrgan,
    getQuizCourseDetail,
    getQuizContentList,
    quizState,
  } = useQuizStore();

  const { navigateWithState } = useNavigateState(
    NavigateStateKeys.fromQuizCourseCreate
  );

  useEffect(() => {
    getDeptList();
    if (courseId) {
      changeType("UPDATE");
      getQuizCourseDetail({ courseId: parseInt(courseId) });
    }
  }, []);

  useEffect(() => {
    if (courseId && quizState.quizCourse) {
      setCourse({
        ...quizState.quizCourse,
      });

      setStartDate(new Date(quizState.quizCourse.sendStartDate * 1000));
      setEndDate(new Date(quizState.quizCourse.sendEndDate * 1000));

      if (quizState.quizCourse.deptIdTemp !== "") {
        deptCheckboxGroup.handleAllClick(quizState.quizCourse.deptIds);
      }
      if (quizState.quizCourse.userIdTemp !== "") {
        userCheckboxGroup.handleAllClick(quizState.quizCourse.userIds);
      }

      changeIsStared(quizState.quizCourse.startedFlag);

      if (quizState.quizCourseUserList) {
        setUserList(quizState.quizCourseUserList);
      }

      getQuizContentList({});

      getOrganInfo();
    }
  }, [quizState.quizCourse]);

  useEffect(() => {
    if (courseId && quizState.quizCourse) {
      if (
        quizState.quizContentList &&
        quizState.quizContentList.list.length > 0
      ) {
        setSelectedQuizList(
          quizState.quizContentList.list.filter((t: QuizContentDTO) =>
            quizState.quizCourse.quizIds.some(
              (q: string) => parseInt(q) === t.quizId
            )
          )
        );
      }
    }
  }, [quizState.quizContentList]);

  useEffect(() => {
    changeOrganTree(deptState.organList);
  }, [deptState.organList]);

  const getOrganInfo = async () => {
    const resp: any = await getSelectedOrgan({
      userIds: userCheckboxGroup.selected,
      deptCodes: deptCheckboxGroup.selected,
    });

    setSelectedDeptInfo(resp.payload.data.deptList);
    setSelectedUserInfo(resp.payload.data.userList);
    //  initializeUserCheckbox();
  };

  useEffect(() => {
    if (
      deptCheckboxGroup.selected.length > 0 ||
      userCheckboxGroup.selected.length > 0
    ) {
      getOrganInfo();
    } else {
      setSelectedDeptInfo([]);
      setSelectedUserInfo([]);
    }
  }, [userCheckboxGroup.selected, deptCheckboxGroup.selected]);

  const onSubmit = async () => {
    //validation
    if (!startDate || !endDate) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.notSelectedDurationMsg"),
        "error"
      );

      return;
    }

    const tempStartDate = new Date(dayjs(startDate).valueOf());
    const tempEndDate = new Date(dayjs(endDate).valueOf());

    // 시나리오
    if (isAllDayDate) {
      tempStartDate.setHours(0);
      tempStartDate.setMinutes(0);
      tempStartDate.setSeconds(0);
      tempEndDate.setHours(23);
      tempEndDate.setMinutes(59);
      tempEndDate.setSeconds(59);
    }

    if (startDate >= endDate) {
      toast.toastMsg(nanoid(), t("quiz.msg.courseEndBeforeStart"), "error");
      return;
    }
    if (
      (!isStarted && dayjs(tempStartDate).valueOf() < dayjs().valueOf()) ||
      dayjs(tempEndDate).valueOf() < dayjs().valueOf()
    ) {
      toast.toastMsg(nanoid(), t("quiz.msg.courseNowFail"), "error");
      return;
    }

    if (course.courseName && startDate && endDate && course.sendMailCount) {
      if (courseId) {
        const params = {
          courseId: parseInt(courseId),
          courseName: course.courseName,
          sendStartDate: tempStartDate.getTime() / 1000,
          sendEndDate: tempEndDate.getTime() / 1000,
          sendMailCount: course.sendMailCount,
          templateId: 1,
          deptIds: deptCheckboxGroup.selected,
          userIds: organTree
            ?.filter(
              (item) =>
                item.type === "user" &&
                userCheckboxGroup.selected.includes(item.deptCode)
              // &&
              // !deptCheckboxGroup.selected.includes(item.parentCode)
            )
            .map((item1) => item1?.deptCode),
          quizIds: selectedQuizList
            .filter((q) => q.quizId != null)
            .map((item) => (item.quizId ? item.quizId.toString() : "")),
          activateFlag: course.activateFlag,
          quizCount: course.quizCount,
          quizSolvePeriod: course.quizSolvePeriod,
        };

        const resp: any = await updateQuizCourse(params);

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("quiz.msg.updateQuizCourseSuccess"),
            "success"
          );
          navigate(`${process.env.PUBLIC_URL}/quiz/course`);
        } else {
          toast.toastMsg(nanoid(), t("quiz.msg.addQuizCourseFail"), "error");
        }
      } else {
        const params = {
          courseName: course.courseName,
          sendStartDate: tempStartDate.getTime() / 1000,
          sendEndDate: tempEndDate.getTime() / 1000,
          sendMailCount: course.sendMailCount,
          templateId: 1,
          deptIds: deptCheckboxGroup.selected,
          userIds: organTree
            ?.filter(
              (item) =>
                item.type === "user" &&
                userCheckboxGroup.selected.includes(item.deptCode)
              // &&
              // !deptCheckboxGroup.selected.includes(item.parentCode)
            )
            .map((item1) => item1?.deptCode),
          quizIds: selectedQuizList
            .filter((q) => q.quizId != null)
            .map((item) => (item.quizId ? item.quizId.toString() : "")),
          activateFlag: course.activateFlag,
          quizCount: course.quizCount,
          quizSolvePeriod: course.quizSolvePeriod,
        };

        const resp: any = await insertQuizCourse(params);

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("quiz.msg.addQuizCourseSuccess"),
            "success"
          );
          const idCreated = resp.payload.data?.courseId || "";
          navigateWithState(`${process.env.PUBLIC_URL}/quiz/course`, [
            idCreated.toString(),
          ]);
        } else {
          toast.toastMsg(nanoid(), t("quiz.msg.addQuizCourseFail"), "error");
        }
      }
    }
  };

  const { targetRef: tableContainerRef, position: tableContainerPosition } =
    useElementPosition();

  const initializeDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  };

  const updateSendDate = (type: "start" | "end", hour: number, min: number) => {
    if (type === "start") {
      setStartDateTime(hour + ":" + min);
      setStartDate((prevStartDate) => {
        const newDate = prevStartDate ? new Date(prevStartDate) : new Date();
        newDate.setHours(hour);
        newDate.setMinutes(min);
        newDate.setMilliseconds(0);
        return newDate;
      });
    } else {
      setEndDateTime(hour + ":" + min);
      setEndDate((prevEndDate) => {
        const newDate = prevEndDate ? new Date(prevEndDate) : new Date();
        newDate.setHours(hour);
        newDate.setMinutes(min);
        newDate.setMilliseconds(0);
        return newDate;
      });
    }
  };

  function transformQuizList(quizList: QuizContentDTO[]) {
    const quizListTransformed = [];
    for (let i = 0; i < quizList.length; i++) {
      const quizCur = quizList[i];

      // 구분
      const dataIsCommon = quizCur.commonId ? (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
        />
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.private")}
          textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
        />
      );

      // 퀴즈이름
      const dataQuizName = (
        <BoxNew>
          <TooltipNew
            message={quizCur.quizName}
            maxWidth={"100%"}
            place={"top"}
            children={
              <TypographyNew
                ellipsis
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {quizCur.quizName}
              </TypographyNew>
            }
          ></TooltipNew>
        </BoxNew>
      );

      // 카테고리
      const dataCategory = quizCur.categorys && (
        <CategoryTagList categorys={quizCur.categorys} />
      );

      // 유형
      const dataQuizType = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {quizCur.quizType === 1
            ? t("quiz.label.formatTF")
            : t("quiz.label.formatChoice")}
        </TypographyNew>
      );

      const quizTransformed = {
        data: [
          { columnText: "" },
          { columnText: dataIsCommon },
          { columnText: dataQuizName },
          { columnText: dataCategory },
          { columnText: dataQuizType },
        ],
        id: String(quizCur.quizId),
        checkBoxClick: () => {
          quizCheckboxGroup.handleClick(quizCur.quizId);
        },
      };

      quizListTransformed.push(quizTransformed);
    }

    return quizListTransformed;
  }

  function checkTableRenderStatus() {
    const hasQuizList = selectedQuizList && selectedQuizList.length > 0;
    if (!hasQuizList) return 1;
    else return 0;
  }

  function renderTableSwitch() {
    if (checkTableRenderStatus() === 1) {
      return noQuizSelected;
    }
  }

  const noQuizSelected = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <NoQuizImage />
      <TypographyNew
        type="body2_rg_long"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
        classes="mg mt-8"
      >
        {t("quiz.msg.choiceQuiz")}
      </TypographyNew>
    </BoxNew>
  );

  function isUserSelected() {
    return (
      userCheckboxGroup.selected.length + deptCheckboxGroup.selected.length > 0
    );
  }

  function isBasicInfoValid() {
    const hasCourseName = course.courseName && course.courseName.length > 0;
    const hasStartTime = !!dayjs(startDate).hour();
    const hasEndTime = !!dayjs(endDate).hour();
    const hasSendMail =
      course.sendMailCount &&
      course.sendMailCount > 0 &&
      course.sendMailCount < 100;
    const hasQuizCount =
      course.quizCount && course.quizCount > 0 && course.quizCount < 10;
    return (
      hasSendMail && hasQuizCount && hasCourseName && hasStartTime && hasEndTime
    );
  }

  function isQuizValid() {
    return (
      selectedQuizList.length !== 0 &&
      selectedQuizList.length >=
        (course.sendMailCount ?? 0) * (course.quizCount ?? 0)
    );
  }

  function isFormValid() {
    return isUserSelected() && isBasicInfoValid() && isQuizValid();
  }

  const [isEditCancelModalOpen, setIsEditCancelModalOpen] = useState(false);

  const closeEditCancelModal = () => {
    navigate(`${process.env.PUBLIC_URL}/quiz/course`);
    setIsEditCancelModalOpen(false);
  };

  const stayOnPage = () => {
    setIsEditCancelModalOpen(false);
  };

  return (
    <BoxNew classes="sticky-layout" ref={tableContainerRef}>
      <BoxNew ref={sentinel}>
        <BoxNew
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          <BoxNew direction="column">
            <TitlePath path={[t("menu.quizTitle"), t("menu.quizCourse")]} />
            <TypographyNew
              type="h1"
              classes="mg mt-8"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {/* 퀴즈 코스 생성 */}
              {type === "UPDATE"
                ? t("quiz.label.editQuizCourse")
                : t("quiz.label.addQuizCourse")}
            </TypographyNew>
          </BoxNew>
        </BoxNew>
      </BoxNew>

      <BoxNew classes="body">
        {/* basic info start  */}
        <AccordionsNew
          label={t("quiz.label.basicInfo")}
          initialOpenStatus={true}
          labelLeftComponent={
            isBasicInfoValid() ? (
              <Tag
                backgroundColor={getColor("COLOR_BG_SUCCESS_DISABLED")}
                text={t("common.input.completed")}
                textColor={getColor("COLOR_TEXT_SUCCESS")}
              />
            ) : (
              <Tag
                backgroundColor={getColor("COLOR_BG_LOADING_DISABLED")}
                text={t("common.input.incompleted")}
                textColor={getColor("COLOR_TEXT_LOADING")}
              />
            )
          }
        >
          <BoxNew
            direction="column"
            classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28"
          >
            <BoxNew>
              <BoxNew width={220} classes="mg mr-28">
                <TypographyNew
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.courseTitle")}
                </TypographyNew>
              </BoxNew>
              <BoxNew>
                <InputField
                  width={598}
                  size={"md"}
                  maxLength={128} // 시나리오 제목 길이 사용
                  placeholder={t("quiz.label.courseTitleHolder")}
                  value={course.courseName}
                  onChange={(e) =>
                    setCourse((prev) => ({
                      ...prev,
                      courseName: e.target.value,
                    }))
                  }
                  disabled={isStarted}
                />
              </BoxNew>
            </BoxNew>
            <BoxNew height={92}>
              <BoxNew width={220} classes="mg mr-28">
                <TypographyNew
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.period")}
                </TypographyNew>
              </BoxNew>
              <BoxNew
                direction="column"
                height={92}
                justifyContent="space-between"
              >
                {/* 시작일 */}
                <BoxNew alignItems="center">
                  <TypographyNew
                    type="body2_rg"
                    classes="mg mr-20"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {t("quiz.label.startDate")}
                  </TypographyNew>
                  <CalendarNew
                    type="single"
                    onSubmit={(date) => {
                      setStartDate((prevStartDate) => {
                        if (!(date instanceof Date)) return prevStartDate;
                        const newDate = new Date(date);
                        if (prevStartDate) {
                          newDate.setHours(prevStartDate.getHours());
                          newDate.setMinutes(prevStartDate.getMinutes());
                          newDate.setSeconds(0);
                          newDate.setMilliseconds(0);
                        }
                        return newDate;
                      });
                    }}
                    width={262.5}
                    minDate={new Date(dayjs().subtract(1, "D").valueOf())}
                    maxDate={endDate ? endDate : undefined}
                    selected={startDate ? startDate : undefined}
                    placeholder={t("quiz.label.selectDate")}
                    disabled={isStarted}
                    classes="mg mr-16"
                  />
                  <TimePicker
                    key={
                      courseId
                        ? dayjs(startDate).get("hour")
                        : "quizCourseCalendarStart"
                    }
                    minOffset={1}
                    disable={isAllDayDate || isStarted}
                    width={262.5}
                    onSubmit={(time) => {
                      const { hour, min } = time;
                      updateSendDate("start", hour, min);
                    }}
                    initialSelected={
                      courseId
                        ? {
                            hour: dayjs(startDate).get("hour"),
                            min: dayjs(startDate).get("minute"),
                          }
                        : undefined
                    }
                  />
                </BoxNew>
                {/* 종료일 */}
                <BoxNew alignItems="center">
                  <TypographyNew
                    type="body2_rg"
                    classes="mg mr-20"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {t("quiz.label.endDate")}
                  </TypographyNew>
                  <CalendarNew
                    type="single"
                    onSubmit={(date) => {
                      // TODO: refactor
                      setEndDate((prevEndDate) => {
                        if (!(date instanceof Date)) return prevEndDate;
                        const newDate = new Date(date);
                        if (prevEndDate) {
                          newDate.setHours(prevEndDate.getHours());
                          newDate.setMinutes(prevEndDate.getMinutes());
                          newDate.setSeconds(0);
                          newDate.setMilliseconds(0);
                        }
                        return newDate;
                      });
                    }}
                    width={262.5}
                    minDate={
                      startDate ?? new Date(dayjs().subtract(1, "D").valueOf())
                    }
                    selected={endDate ? endDate : undefined}
                    placeholder={t("quiz.label.selectDate")}
                    disabled={isStarted}
                    classes="mg mr-16"
                  />
                  <TimePicker
                    key={
                      courseId
                        ? dayjs(endDate).get("hour")
                        : "quizCourseCalendarEnd"
                    }
                    minOffset={1}
                    disable={isAllDayDate || isStarted}
                    width={262.5}
                    onSubmit={(time) => {
                      const { hour, min } = time;
                      updateSendDate("end", hour, min);
                    }}
                    initialSelected={
                      courseId
                        ? {
                            hour: dayjs(endDate).get("hour"),
                            min: dayjs(endDate).get("minute"),
                          }
                        : undefined
                    }
                  />
                </BoxNew>
              </BoxNew>
            </BoxNew>
            <BoxNew>
              <BoxNew width={220} classes="mg mr-28" alignItems="center">
                <TypographyNew
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.solvePeriod")}
                </TypographyNew>
              </BoxNew>

              <BoxNew alignItems="center">
                <Dropdown
                  width={66}
                  classes="mg mr-8"
                  disabled={isAllDayDate || isStarted}
                  value={
                    course.quizSolvePeriod !== undefined
                      ? course.quizSolvePeriod + ""
                      : "7"
                  }
                  btnSize={"md"}
                  placeholder={
                    course.quizSolvePeriod !== undefined
                      ? course.quizSolvePeriod + ""
                      : "7"
                  }
                >
                  {Array(8)
                    .fill("")
                    .map((item, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() =>
                          setCourse((prev) => ({
                            ...prev,
                            quizSolvePeriod: index,
                          }))
                        }
                        label={index}
                      />
                    ))}
                </Dropdown>
                일
              </BoxNew>
            </BoxNew>

            <BoxNew height={42}>
              <BoxNew
                direction="column"
                justifyContent="space-between"
                width={220}
                classes="mg mr-28"
              >
                <TypographyNew
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.emailsPerTarget")}
                </TypographyNew>
                <TypographyNew
                  type="body4_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.valueRange")}: 1-99
                </TypographyNew>
              </BoxNew>
              <InputField
                type="number"
                size="md"
                placeholder=""
                width={66}
                maxLength={2}
                value={course.sendMailCount?.toString()}
                onChange={(e) => {
                  if (
                    !e.target.value ||
                    (parseInt(e.target.value) <= 99 &&
                      parseInt(e.target.value) >= 0)
                  ) {
                    setCourse((prev) => ({
                      ...prev,
                      sendMailCount: parseInt(e.target.value),
                    }));
                  }
                }}
                disabled={isStarted}
              />

              <BoxNew classes="mg ml-8" alignItems="center">
                <TypographyNew
                  type="body2_rg"
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("quiz.label.ea1")}
                </TypographyNew>
              </BoxNew>
            </BoxNew>

            <BoxNew height={42}>
              <BoxNew
                direction="column"
                justifyContent="space-between"
                width={220}
                classes="mg mr-28"
              >
                <TypographyNew
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.quizPerMail")}
                </TypographyNew>
                <TypographyNew
                  type="body4_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.valueRange")}: 1-10
                </TypographyNew>
              </BoxNew>
              <InputField
                width={66}
                type="number"
                size="md"
                placeholder=""
                maxLength={1}
                value={course.quizCount?.toString()}
                onChange={(e) => {
                  if (
                    !e.target.value ||
                    (parseInt(e.target.value) <= 10 &&
                      parseInt(e.target.value) >= 0)
                  )
                    setCourse((prev) => ({
                      ...prev,
                      quizCount: e.target.value ? parseInt(e.target.value) : 0,
                    }));
                }}
                disabled={isStarted}
              />
              <BoxNew classes="mg ml-8" alignItems="center">
                <TypographyNew
                  type="body2_rg"
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("quiz.label.ea1")}
                </TypographyNew>
              </BoxNew>
            </BoxNew>

            <BoxNew height={42}>
              <BoxNew width={220} classes="mg mr-28">
                <TypographyNew
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.courseStatus")}
                </TypographyNew>
              </BoxNew>
              <ToggleNew
                checked={course.activateFlag}
                onChange={() =>
                  setCourse((prev) => ({
                    ...prev,
                    activateFlag: !prev.activateFlag,
                  }))
                }
                label={course.activateFlag ? "ON" : "OFF"}
              />
            </BoxNew>
          </BoxNew>
        </AccordionsNew>
        {/* basic info end   */}
        {/* user info start  */}
        <AccordionsNew
          label={t("quiz.label.targets")}
          initialOpenStatus={true}
          labelLeftComponent={
            isUserSelected() ? (
              <Tag
                backgroundColor={getColor("COLOR_BG_SUCCESS_DISABLED")}
                text={t("common.input.completed")}
                textColor={getColor("COLOR_TEXT_SUCCESS")}
              />
            ) : (
              <Tag
                backgroundColor={getColor("COLOR_BG_LOADING_DISABLED")}
                text={t("common.input.incompleted")}
                textColor={getColor("COLOR_TEXT_LOADING")}
              />
            )
          }
        >
          <BoxNew classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28">
            <BoxNew width={220} classes="mg mr-28">
              <TypographyNew
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.user")}
              </TypographyNew>
            </BoxNew>
            <BoxNew direction="column">
              <BoxNew>
                <InputField
                  size="md"
                  placeholder={t("account.msg.userUndefinedIdEmailMsg")}
                  width={"100%"}
                  value={searchWord}
                  onChange={(e) => setSearchWord(e.target.value)}
                  classes="mg mr-8"
                />
                <ButtonNew
                  btnStyle="fill"
                  type="secondary"
                  leadingIcon={<IconsNew variant="line" label="plusCircle" />}
                  state={isStarted ? "disable" : "default"}
                  onClick={() => setIsUserModalOpen(true)}
                  classes="quiz__select__button"
                  label={t("quiz.label.selectOrgChart")}
                  width={i18n.language === "ko" ? 180 : 280}
                />
              </BoxNew>
              <BoxNew classes="mg mt-8">
                <BoxNew
                  classes="selected__user__container"
                  alignItems="center"
                  direction="column"
                >
                  <BoxNew width={"100%"} direction="column">
                    {(selectedDeptInfo && selectedDeptInfo.length > 0) ||
                    (selectedUserInfo && selectedUserInfo.length > 0) ? (
                      <BoxNew
                        direction="column"
                        classes="selected__user__container-card"
                      >
                        {selectedDeptInfo &&
                          selectedDeptInfo.length > 0 &&
                          selectedDeptInfo.map(
                            (i) =>
                              (searchWord === "" ||
                                i.deptName.includes(searchWord)) && (
                                <OrganCard
                                  userName={i.deptName}
                                  userId={i.deptCode}
                                  type="DEPT"
                                  deleteEvent={() => {
                                    deptCheckboxGroup.handleClick(
                                      i.deptCode + ""
                                    );
                                  }}
                                />
                              )
                          )}
                        {selectedUserInfo &&
                          selectedUserInfo.length > 0 &&
                          selectedUserInfo.map(
                            (i) =>
                              (searchWord === "" ||
                                i.userName.includes(searchWord) ||
                                i.userEmail.includes(searchWord)) && (
                                <OrganCard
                                  userId={i.userId ?? undefined}
                                  userName={i.userName}
                                  jobTitle={i.jobTitle ?? undefined}
                                  deptName={i.deptName}
                                  userEmail={i.userEmail}
                                  deleteEvent={() => {
                                    userCheckboxGroup.handleClick(
                                      i.userId + ""
                                    );
                                  }}
                                />
                              )
                          )}
                      </BoxNew>
                    ) : (
                      <BoxNew
                        width={"100%"}
                        alignItems="center"
                        direction="column"
                      >
                        <BoxNew classes="mg mt-24">
                          <NoSelectedUserImage />
                        </BoxNew>
                        <BoxNew>
                          <TypographyNew
                            type="body3_rg"
                            exactColor={getColor("COLOR_TEXT_SECONDARY")}
                          >
                            {t("quiz.msg.addTargetOrgChart")}
                          </TypographyNew>
                        </BoxNew>
                      </BoxNew>
                    )}
                  </BoxNew>
                </BoxNew>
              </BoxNew>
            </BoxNew>
          </BoxNew>
        </AccordionsNew>
        {/* user info end   */}
        {/* quiz  info start  */}
        <AccordionsNew
          label={t("quiz.label.quizQuestions")}
          initialOpenStatus={true}
          labelLeftComponent={
            isQuizValid() ? (
              <Tag
                backgroundColor={getColor("COLOR_BG_SUCCESS_DISABLED")}
                text={t("common.input.completed")}
                textColor={getColor("COLOR_TEXT_SUCCESS")}
              />
            ) : (
              <Tag
                backgroundColor={getColor("COLOR_BG_LOADING_DISABLED")}
                text={t("common.input.incompleted")}
                textColor={getColor("COLOR_TEXT_LOADING")}
              />
            )
          }
        >
          <BoxNew direction="column" classes="mg mt-12 pd pl-8 pr-8 pb-28">
            <BoxNew classes="mg mb-28">
              <TypographyNew
                type="body3_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.msg.choiceQuizCourse")}
              </TypographyNew>
            </BoxNew>
            <BoxNew classes="mg mb-12" justifyContent="space-between">
              <BoxNew alignItems="center">
                <ButtonNew
                  btnStyle="fill"
                  size="lg"
                  leadingIcon={<IconsNew variant="line" label="plusCircle" />}
                  type="secondary"
                  onClick={() => setIsQuizModalOpen(true)}
                  state={isStarted ? "disable" : "default"}
                  classes="quiz__select__button"
                  label={t("quiz.label.choiceQuiz")}
                />
                <BoxNew classes="mg ml-12">
                  <TypographyNew
                    type="body2_rg"
                    exactColor={getColor("COLOR_TEXT_PRIMARY")}
                  >
                    {t("quiz.label.added")}
                  </TypographyNew>
                </BoxNew>
                <BoxNew classes="mg ml-4">
                  <TypographyNew
                    type="body2_rg"
                    exactColor={
                      course.quizCount === undefined ||
                      course.quizCount === 0 ||
                      course.sendMailCount === undefined ||
                      course.sendMailCount === 0 ||
                      (course.sendMailCount &&
                        course.quizCount &&
                        selectedQuizList.length <
                          course.sendMailCount * course.quizCount)
                        ? getColor("COLOR_TEXT_ERROR")
                        : getColor("COLOR_TEXT_BRAND")
                    }
                  >
                    {selectedQuizList.length}
                  </TypographyNew>
                  <TypographyNew type="body2_rg">
                    &nbsp;/&nbsp;
                    {(course.sendMailCount || 0) * (course.quizCount || 0)}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
              <BoxNew alignItems="center">
                <ButtonNew
                  btnStyle={"link"}
                  size={"lg"}
                  classes="mg mr-4"
                  state={
                    quizCheckboxGroup.selected.length > 0
                      ? "default"
                      : "disable"
                  }
                  leadingIcon={
                    <IconsNew
                      variant="line"
                      label="delete"
                      stroke={getColor("COLOR_ICON_PRIMARY")}
                    />
                  }
                  onClick={() => {
                    setSelectedQuizList((quizList) =>
                      quizList.filter(
                        (q) => !quizCheckboxGroup.selected.includes(q.quizId)
                      )
                    );
                    quizCheckboxGroup.handleAllClick([]);
                  }}
                  label={t("common.button.delete")}
                />
              </BoxNew>
            </BoxNew>

            <TableNew
              classes="table-chip-hover"
              border="none"
              clickIds={quizCheckboxGroup.selected.map((quizId) =>
                String(quizId)
              )}
              columnHeight="md"
              data={transformQuizList(selectedQuizList)}
              endReached={() => {}}
              checkBoxClick={() =>
                quizCheckboxGroup.handleAllClick(
                  quizCheckboxGroup.selected.length === selectedQuizList.length
                    ? []
                    : selectedQuizList.map((q) => q.quizId)
                )
              }
              header={[
                {
                  headerText: "",
                  width: "12px",
                },
                {
                  headerText: t("quiz.table.type"),
                  width: "72px",
                },
                {
                  headerText: t("quiz.table.quizName"),
                  width: "360px",
                },
                {
                  headerText: t("quiz.table.category"),
                  width: "calc(100% - 636px)",
                },
                {
                  headerText: t("quiz.table.formal"),
                  width: "160px",
                },
              ]}
              width={"100%"}
              height={`${
                (selectedQuizList.length + 1) * 50 > 612
                  ? 612
                  : (selectedQuizList.length + 1) * 50
              }px`}
            />
            {renderTableSwitch()}
          </BoxNew>
        </AccordionsNew>
        {/* quiz  info end   */}
      </BoxNew>
      <BoxNew
        classes={classNames("footer", { showScroll: isHorizontallyScrollable })}
      >
        <ButtonNew
          btnStyle="fill"
          type="secondary"
          size="md"
          onClick={() => setIsEditCancelModalOpen(true)}
          label={t("common.button.cancel")}
        />
        <ButtonNew
          classes="mg ml-8"
          onClick={onSubmit}
          btnStyle="fill"
          type="primary"
          size="md"
          state={isFormValid() ? "default" : "disable"}
          label={t("quiz.label.save")}
        />
      </BoxNew>

      <EditCancelModal
        closeModal={closeEditCancelModal}
        stayOnPage={stayOnPage}
        toShow={isEditCancelModalOpen}
      />

      {isQuizModalOpen && (
        <QuizModal
          isOpen={isQuizModalOpen}
          onClose={() => {
            setIsQuizModalOpen(false);
          }}
          selectedQuizList={selectedQuizList}
          setSelectedQuizList={setSelectedQuizList}
        />
      )}
      {isUserModalOpen && (
        <UserModal
          isOpen={isUserModalOpen}
          onClose={() => {
            setIsUserModalOpen(false);
          }}
          userCheckboxGroup={userCheckboxGroup}
          deptCheckboxGroup={deptCheckboxGroup}
          organTree={organTree}
        />
      )}
    </BoxNew>
  );
};

export default WriteQuizCourse;
