import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const utilsCommon = {
  emailValidation: (email: string): boolean => {
    const regex =
      /^[0-9a-zA-Z]([-.]?[0-9a-zA-Z_])+@[0-9a-zA-Z]([-.]?[0-9a-zA-Z_])+\.[a-zA-Z]{2,3}$/;
    return regex.test(email);
  },

  passwordValidation: (password: string): boolean => {
    const regex = /^(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{10,}$/;
    return regex.test(password);
  },
  userNameValidation: (name: string): boolean => {
    const regex = /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9-._ /,]+$/;
    return regex.test(name);
  },
  ipValidation: (ip: string): boolean => {
    const regex =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(ip);
  },
  deptCodeValidation: (code: string): boolean => {
    const regex = /[^\w\sㄱ-힣_]/g;
    return !regex.test(code);
  },
  deptNameValidation: (code: string): boolean => {
    const regex = /[^\w\sㄱ-힣_&\-\[\]\(\)]/g;
    return !regex.test(code);
  },
  getColorInCode: (color: "icon3") => {
    const isLightMode = !window.matchMedia("( prefers-color-scheme: light )")
      .matches;
    const colorPallete = {
      icon3: isLightMode ? "#b5bbc2" : "red",
      textmain: isLightMode ? "#252d38" : "#ffffff",
      wrapsodyBlack: isLightMode ? "#28292b" : "#ffffff",
    };
    return colorPallete[color];
  },
  getDayBefore: (type: number): number => {
    const daybefore = [1, 3, 6, 12];
    /**
     *  type
     *  1: 1개월전
     *  2: 3개월전
     *  3: 6개월전
     *  4: 12개월전
     */
    return dayjs().subtract(daybefore[type], "M").valueOf();
  },
  getFullDate: (timestamp?: number | string | null): string => {
    // const locale = i18n.language === "ko" ? false : true;
    // return dayjs(timestamp).format(
    //   locale ? "MM/DD/YYYY HH:mm:ss" : "YYYY-MM-DD HH:mm:ss"
    // );
    return timestamp ? dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss") : "";
  },
  getShortDate: (timestamp?: number | string | null): string => {
    // const locale = i18n.language === "ko" ? false : true;
    // return dayjs(timestamp).format(
    //   locale ? "MM/DD/YYYY HH:mm:ss" : "YYYY-MM-DD HH:mm:ss"
    // );
    let newTimestamp =
      typeof timestamp === "number" && timestamp?.toString().length === 10
        ? parseInt(timestamp + "000")
        : timestamp;
    return timestamp ? dayjs(newTimestamp).format("YYYY-MM-DD") : "";
  },
  /**
   * 날짜 포맷 변환 유틸 함수
   * @param timestamp unixtime
   * @returns 2025-03-31 11:50PM
   */
  getFullDateStr: (timestamp?: number | string | null): string => {
    const dateFormat = "YYYY-MM-DD hh:mm A";
    let newTimestamp =
      typeof timestamp === "number" && timestamp?.toString().length === 10
        ? timestamp * 1000 // 10자리면 s 단위
        : timestamp; // 13자리면 ms 단위

    dayjs.extend(utc);
    dayjs.extend(timezone);
    return dayjs(newTimestamp).format(dateFormat);
  },
  getStartDay: (date: Date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  },
  getEndDay: (date: Date) => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    return date;
  },
  pickColor: (id: string): string => {
    // const colors = ['green', 'red', 'orange', 'purple', 'cyan', 'blue', 'yellow'];
    const colors = [
      "red",
      "orange",
      "violet",
      "cyan",
      "blue",
      "yellow",
      "green",
      "yellow-green",
      "dark-blue",
      "pink",
    ];
    return id
      ? colors[(id.charCodeAt(0) + id.charCodeAt(id.length - 1)) % 10]
      : "gray";
    // return id ? colors[(id.charCodeAt(0) + id.charCodeAt(id.length - 1)) % 7] : 'gray';
  },
  generateHtmlFileFromString: (fileName: string, bodyStr: string) => {
    let htmlStr =
      "<html>\n<head></head>\n<body>\n" + bodyStr + "\n</body>\n</html>";

    let file = null;
    let fileNameWithExtension = fileName + ".html";
    let data = [];
    data.push(htmlStr);
    let properties = { type: "text/html" };
    file = new File(data, fileNameWithExtension, properties);
    return file;
  },
  truncateToTwoDecimals(num: number) {
    return Math.floor(num * 100) / 100;
  },
  getIntervalTime() {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const nowTime = dayjs();
    return nowTime.utcOffset() / 60;
  },
};
export default utilsCommon;
