const NoUserImage = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width="200"
      height="120"
      viewBox="0 0 200 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117.535 94.285H75.4999C71.6711 94.285 68.5596 91.1734 68.5596 87.3447V31.7528C68.5596 27.9241 71.6711 24.8125 75.4999 24.8125H117.535C121.364 24.8125 124.475 27.9241 124.475 31.7528V87.3447C124.475 91.185 121.364 94.285 117.535 94.285Z"
        fill="#DDE1E7"
      />
      <path
        d="M96.5185 58.6347C99.9363 58.6347 102.707 55.864 102.707 52.4462C102.707 49.0284 99.9363 46.2578 96.5185 46.2578C93.1007 46.2578 90.3301 49.0284 90.3301 52.4462C90.3301 55.864 93.1007 58.6347 96.5185 58.6347Z"
        fill="#A5AEB8"
      />
      <path
        d="M90.6648 61.1328H102.371C105.957 61.1328 108.86 64.0362 108.86 67.622C108.86 70.5138 106.523 72.8504 103.632 72.8504H89.3924C86.5007 72.8504 84.1641 70.5138 84.1641 67.622C84.1756 64.0362 87.079 61.1328 90.6648 61.1328Z"
        fill="#A5AEB8"
      />
      <path
        d="M126.814 31.0352H124.188V41.4456H126.814C127.919 41.4456 128.814 40.5502 128.814 39.4456V33.0352C128.814 31.9306 127.919 31.0352 126.814 31.0352Z"
        fill="#B2BAC2"
      />
      <path
        d="M126.814 46.0742H124.188V56.4847H126.814C127.919 56.4847 128.814 55.5892 128.814 54.4847V48.0742C128.814 46.9696 127.919 46.0742 126.814 46.0742Z"
        fill="#B2BAC2"
      />
      <path
        d="M126.814 61.1133H124.188V71.5237H126.814C127.919 71.5237 128.814 70.6283 128.814 69.5237V63.1133C128.814 62.0087 127.919 61.1133 126.814 61.1133Z"
        fill="#B2BAC2"
      />
      <path
        d="M56.472 21.7026C55.7201 21.7026 55.1187 21.0895 55.1187 20.3492V17.3533C55.1187 16.6015 55.7317 16 56.472 16C57.2239 16 57.8254 16.613 57.8254 17.3533V20.3492C57.8369 21.0895 57.2239 21.7026 56.472 21.7026Z"
        fill="#FFCC75"
      />
      <path
        d="M56.472 30.9214C55.7201 30.9214 55.1187 30.3083 55.1187 29.568V26.5721C55.1187 25.8202 55.7317 25.2188 56.472 25.2188C57.2239 25.2188 57.8254 25.8318 57.8254 26.5721V29.568C57.8369 30.3199 57.2239 30.9214 56.472 30.9214Z"
        fill="#FFCC75"
      />
      <path
        d="M58.2319 23.4588C58.2319 22.7069 58.845 22.1055 59.5853 22.1055H62.5812C63.333 22.1055 63.9345 22.7185 63.9345 23.4588C63.9345 24.2107 63.3215 24.8122 62.5812 24.8122H59.5853C58.845 24.8122 58.2319 24.1991 58.2319 23.4588Z"
        fill="#FFCC75"
      />
      <path
        d="M49 23.4588C49 22.7069 49.6131 22.1055 50.3534 22.1055H53.3493C54.1011 22.1055 54.7026 22.7185 54.7026 23.4588C54.7026 24.2107 54.0896 24.8122 53.3493 24.8122H50.3534C49.6131 24.8122 49 24.1991 49 23.4588Z"
        fill="#FFCC75"
      />
      <path
        d="M139.998 104C145.843 104 150.582 99.2614 150.582 93.416C150.582 87.5707 145.843 82.832 139.998 82.832C134.153 82.832 129.414 87.5707 129.414 93.416C129.414 99.2614 134.153 104 139.998 104Z"
        fill="#ADCDFB"
      />
      <path
        d="M136.666 89.7274C136.863 89.3226 137.129 88.9986 137.476 88.7442C137.823 88.4897 138.216 88.3046 138.667 88.189C139.107 88.0733 139.569 88.0039 140.044 88.0039C140.68 88.0039 141.27 88.108 141.813 88.3162C142.357 88.5244 142.785 88.8483 143.109 89.2763C143.433 89.7043 143.595 90.2364 143.595 90.8841C143.595 91.3352 143.514 91.7169 143.363 92.0177C143.213 92.3184 143.016 92.5729 142.773 92.7811C142.53 92.9893 142.288 93.1744 142.045 93.3479C141.744 93.5446 141.513 93.718 141.316 93.8684C141.131 94.0188 140.992 94.1692 140.911 94.3427C140.83 94.5162 140.784 94.736 140.784 95.0136V95.3837H138.632L138.621 95.0367C138.586 94.6434 138.609 94.2964 138.702 93.9841C138.794 93.6718 138.945 93.3942 139.153 93.1512C139.361 92.9083 139.616 92.677 139.928 92.4919C140.286 92.2606 140.576 92.0408 140.795 91.8326C141.015 91.636 141.119 91.3584 141.119 91.0229C141.119 90.7569 141.061 90.5487 140.946 90.3867C140.83 90.2248 140.68 90.1091 140.495 90.0282C140.31 89.9472 140.113 89.9124 139.905 89.9124C139.697 89.9124 139.523 89.9472 139.373 90.005C139.222 90.0744 139.095 90.1554 138.991 90.2826C138.887 90.3983 138.806 90.5371 138.748 90.699C138.69 90.8609 138.667 91.0229 138.667 91.208H136.435C136.388 90.618 136.469 90.1323 136.666 89.7274ZM138.667 96.633C138.933 96.4016 139.303 96.286 139.766 96.286C140.24 96.286 140.61 96.4016 140.876 96.633C141.154 96.8643 141.281 97.1766 141.281 97.5583C141.281 97.9285 141.142 98.2293 140.876 98.4722C140.599 98.7035 140.229 98.8192 139.766 98.8192C139.303 98.8192 138.945 98.7035 138.667 98.4722C138.401 98.2408 138.262 97.9401 138.262 97.5583C138.262 97.1766 138.401 96.8759 138.667 96.633Z"
        fill="white"
      />
    </svg>
  );
};

export default NoUserImage;
