import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sendHistoryApi from "../api/sendHistory";
import { TrainingResultReqType, TrainingResultType } from "../../types/Send";

interface SendHistoryState {
  sendHistory: TrainingResultType[];
  total: number;
}

// # initial state
const initialState: SendHistoryState = { sendHistory: [], total: 0 };

export const trunkGetTrainingResult = createAsyncThunk(
  "training/result",
  async (req: TrainingResultReqType) => {
    const resp = await sendHistoryApi.getTrainingResult(req);
    return resp;
  }
);

// export const trunkGetSendHistoryById = createAsyncThunk(
//   "sendHistory/id",
//   async (req: number) => {
//     const resp = await sendHistoryApi.getSendHistoryById(req);
//     return resp;
//   }
// );

export const trunkReportSendHistoryById = createAsyncThunk(
  "sendHistory/report",
  async (req: { sid: number; isReported: boolean }) => {
    const resp = await sendHistoryApi.reportSendHistory(req);
    return resp;
  }
);
export const trunkSendResultMail = createAsyncThunk(
  "sendHistory/result",
  async (config: number) => {
    const resp = await sendHistoryApi.sentResultMail(config);
    return resp;
  }
);
export const trunkCheckMailExist = createAsyncThunk(
  "sendHistory/mailExist",
  async (configId: number) => {
    const resp = await sendHistoryApi.checkMailExist(configId);
    return resp;
  }
);

export const trunkResendQuizSendHistory = createAsyncThunk(
  "program/result/resend",
  async (req?: TrainingResultReqType[]) => {
    const resp = await sendHistoryApi.resendQuizSendHistory(req);
    return resp;
  }
);

const SendHistorySlice = createSlice({
  name: "sendHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(
    //   trunkGetSendHistoryById.fulfilled,
    //   (state, { payload: data }) => {
    //     state.sendHistory = data;
    //   }
    // );
    builder.addCase(
      trunkGetTrainingResult.fulfilled,
      (state, { payload: data }) => {
        state.sendHistory = data.data?.list;
        state.total = data.data?.total;
      }
    );

    // builder.addCase(trunkResendQuizSendHistory.fulfilled, (state, req) => {
    //   if (req.payload.code === "SUCCESS") {
    //     state.sendHistory = state.sendHistory.map((item) =>
    //       item.sendHistoryId === req.meta.arg?.sendHistoryId
    //         ? { ...item, historyResult: 1, completionFlag: false }
    //         : item
    //     );
    //   }
    // });
  },
});
export const SendHistoryActions = SendHistorySlice.actions;

export default SendHistorySlice.reducer;
