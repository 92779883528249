import { CommonProps } from "fds/src/helpers/props";
import { lazy, Suspense } from "react";

const Variant = {
  line: "line",
  fill: "fill",
  document: "document",
};

export interface IconWrapperProp extends CommonProps, IconProps {
  variant: (typeof Variant)[keyof typeof Variant];
  label: string;
}

export interface IconProps extends CommonProps {
  stroke?: string;
  size?: string | number;
  strokeBg?: string;
}

const IconsLazyLoaded = {
  [Variant.line]: {
    doubleStar: lazy(() => import("./line/DoubleStar")),
    education: lazy(() => import("./line/Education")),
    award: lazy(() => import("./line/Award")),
    gallery: lazy(() => import("./line/Gallery")),
  },
  [Variant.fill]: {},
  [Variant.document]: {
    announcement: lazy(() => import("./document/Annoucement")),
    linkClick: lazy(() => import("./document/LinkClick")),
    privacy: lazy(() => import("./document/Privacy")),
    attachment: lazy(() => import("./document/Attachment")),
    report: lazy(() => import("./document/Report")),
    document: lazy(() => import("./document/Document")),
  },
};

export default function IconMindSat({
  variant,
  label,
  ...props
}: IconWrapperProp) {
  const IconGroup = IconsLazyLoaded[variant];
  const Icon = IconGroup[label as keyof typeof IconGroup];

  if (!Icon) {
    throw new Error(`${label} 아이콘을 찾을 수 없습니다. `);
  }

  return (
    <Suspense>
      <Icon {...props} />
    </Suspense>
  );
}
