import { Box, Button, getColor, Icons, Tooltip, Typography } from "fds";
import "./index.scss";
import useDashboardStore from "../../../redux/dispatcher/useDashboardStore";
import useCommonStore from "../../../redux/dispatcher/useCommonStore";
import useConfigStore from "../../../redux/dispatcher/useConfigStore";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import useTraineStaticsStore from "../../../redux/dispatcher/useTraineStaticsStore";
import useSendHistoryStore from "../../../redux/dispatcher/useSendHistoryStore";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import { useEffect, useState } from "react";
import {
  Category,
  ImagesInfo,
  MailTemplate,
  PhishingDocument,
  SiteInfo,
  SiteResourceBean,
} from "../../../types/Info";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { ConfigListType, SendHistoryType } from "../../../types/Send";
import useStatisticsStore from "../../../redux/dispatcher/useStatisticsStore";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import useManagerStore from "../../../redux/dispatcher/useManagerStore";
import { ManagerBean } from "../../../types/Manage";
import useAccountStore from "../../../redux/dispatcher/useAccountStore";
import utilsCommon from "../../../shared/utils/common";

const DummyAPIPage: React.FC = () => {
  const { getConfigIds, getMenu, getManager } = useCommonStore();
  const {
    selectCustomer,
    getAccessCompanyList,
    getCustomersList,
    updateCustomerInfo,
    checkCustomerTraining,
    getCustomerCategory,
    getCustomerSetting,
  } = useCustomerStore();
  const {
    categoryState,
    getCategories,
    addCategory,
    updateCategory,
    deleteCategory,
  } = useCategoryStore();
  const {
    getReportHistory,
    getRecentClickHistoryList,
    getProgressList,
    getDashboardReport,
    getSendHistoryList,
    getPhishTypes,
  } = useDashboardStore();
  const { getCustomerName } = useCustomerStore();
  const {
    managerState,
    getManagerList,
    getCommonMangerList,
    addNewManager,
    updateManagerInfo,
    deleteManager,
  } = useManagerStore();
  const { getConfigInfo, getConfigList } = useConfigStore();
  const {
    contentState,
    getAttaches,
    getSites,
    uploadSites,
    updateSites,
    deleteSite,
    getSitesFile,
    getSiteResource,
    insertSiteResource,
    updateFileSiteResource,
    updateSiteResource,
    deleteSiteResource,
    getImages,
    uploadImage,
    updateImage,
    updateFileImage,
    deleteImage,
    updateAttach,
    updateAttachFile,
    downloadAttachFile,
  } = useContentStore();
  const {
    programState,
    getProgramList,
    getProgramDetail,
    updateProgram,
    activateProgram,
    addNewProgram,
    deleteProgram,
  } = useProgramStore();
  const {
    getStaticsList,
    getStaticsTraineGraphListConfig,
    getStaticsTraineGraphListType,
    getStaticsTraineGraphList,
    getPhishingGraph,
  } = useStatisticsStore();
  const {
    getTraineGraphList,
    getTraineGraphListType,
    getTraineGraphListConfig,
    getInpercent,
    getInpercentType,
    getInpercentConfig,
  } = useTraineStaticsStore();
  const {
    sendHistoryState,
    // getSendHistoryById,
    reportSendHistory,
    checkMailExist,
    sendResultMail,
  } = useSendHistoryStore();
  const {
    mailTemplateState,
    getMailTemplateList,
    getMailTemplateInfo,
    insertMailTemplate,
    updateMailTemplate,
    getUsedTemplate,
    parseEMLFile,
    getMailTemplateData,
    deleteMailTemplate,
  } = useMailTemplateStore();
  const { sessionLogOut, changePwd } = useAccountStore();

  const [categoryList, changeCategoryList] = useState([] as Category[]);
  const [sendHistoryList, changeSendHistoryList] = useState(
    [] as SendHistoryType[]
  );
  const [configList, changeConfigList] = useState([] as ConfigListType[]);
  const [mailTemplateList, changeMailTemplateList] = useState(
    [] as MailTemplate[]
  );
  const [siteResources, changeSiteResources] = useState(
    [] as SiteResourceBean[]
  );
  const [managers, changeManagers] = useState([] as ManagerBean[]);
  const [sites, changeSites] = useState([] as SiteInfo[]);
  const [images, changeImaegs] = useState([] as ImagesInfo[]);
  const [attaches, changeAttaches] = useState([] as PhishingDocument[]);

  const DUMMY_CONFIG_ID = 23;
  useEffect(() => {
    changeCategoryList(categoryState.categoryList);
  }, [categoryState.categoryList]);
  useEffect(() => {
    mailTemplateState?.mailTemplateList.map((item: MailTemplate) =>
      getMailTemplateData(item.template_id)
    );
    changeMailTemplateList(mailTemplateState.mailTemplateList);
  }, [mailTemplateState.mailTemplateList.length]);
  useEffect(() => {
    changeMailTemplateList(mailTemplateState.mailTemplateList);
  }, [mailTemplateState.mailTemplateList]);
  useEffect(() => {
    console.log(programState.configList);
    changeConfigList(programState.configList);
  }, [programState.configList]);
  useEffect(() => {
    changeSendHistoryList(sendHistoryState.sendHistory);
  }, [sendHistoryState.sendHistory]);
  useEffect(() => {
    changeSiteResources(contentState.siteResources);
  }, [contentState.siteResources]);
  useEffect(() => {
    changeSites(contentState.sites);
  }, [contentState.sites]);
  useEffect(() => {
    changeImaegs(contentState.images);
  }, [contentState.images]);
  useEffect(() => {
    changeAttaches(contentState.attaches);
  }, [contentState.attaches]);
  useEffect(() => {
    changeManagers(managerState.managerList);
  }, [managerState.managerList]);
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => console.log("change"));
    console.log(
      window.matchMedia,
      window.matchMedia("prefers-color-scheme: light").matches,
      window.matchMedia("prefers-color-scheme: dark").matches
    );
  }, []);
  return (
    <Box classes="main dummyAPI">
      <Box>
        <Icons
          variant="line"
          label={"view"}
          stroke={utilsCommon.getColorInCode("icon3")}
        />
        {window.matchMedia &&
        window.matchMedia("( prefers-color-scheme: dark )").matches
          ? "T"
          : "F"}
      </Box>

      <Box direction="column">
        <Typography type="subtitle2">/</Typography>
        <Box classes="apis">
          <Button label="/getConfigIds" onClick={() => getConfigIds()} />
          <Button
            label="/menu"
            onClick={() => getMenu({ accessType: 1, managerCode: "admin" })}
          />
          <Button label="/manager" onClick={() => getManager("admin")} />
          <Button
            label=" /change/pwd"
            onClick={() =>
              // changePwd({
              //   email: "injubi@fasoo.com",
              //   pwd: "pwdchangeTest",
              // })
              console.log("API Changes")
            }
          />
        </Box>
      </Box>
      <Box direction="column">
        <Tooltip message={"몇개는 /site"}>
          <Typography type="subtitle2">/sites</Typography>
        </Tooltip>

        <Box classes="apis">
          <Button label="/" onClick={() => getSites({})} />
          <Button
            label="/upload"
            onClick={() =>
              uploadSites({
                request: { siteName: "테스트" },
                file: new File([], "새 파일~.jpg"),
              })
            }
          />
          <Button
            label="/update"
            onClick={() =>
              sites.length === 0 ? alert("리스트를 호출해주세요") : null
            }
          />
          <Button
            label=" /file"
            onClick={() =>
              sites.length === 0
                ? alert("리스트를 호출해주세요")
                : getSitesFile({
                    fileName:
                      sites[0].siteUrl.split("/")[
                        sites[0].siteUrl.split("/").length - 1
                      ],
                    site_id: sites[0].siteId,
                  })
            }
          />
          <Button
            label=" /delete"
            onClick={() =>
              sites.length === 0
                ? alert("리스트를 호출해주세요")
                : deleteSite({
                    request: {
                      siteIds: [sites[0].siteId],
                    },
                  })
            }
          />
          <Button label="/resources" onClick={() => getSiteResource()} />
          <Button
            label="/resource/update"
            onClick={() =>
              siteResources.length === 0 ? alert("리스트를 호출해주세요") : null
            }
          />
          <Button
            label=" /resource/update/file"
            onClick={() =>
              siteResources.length === 0 ? alert("리스트를 호출해주세요") : null
            }
          />
          <Button
            label="/resource/insert"
            // onClick={() =>
            //   insertSiteResource({
            //     resourceName: "tetett",
            //     file: new File([], "테스트파일~.jpg"),
            //   })
            // }
          />
          <Button
            label=" /resource/delete"
            onClick={
              () =>
                siteResources.length === 0
                  ? alert("리스트를 호출해주세요")
                  : null
              // : deleteSiteResource(
              //     siteResources[siteResources.length - 1].resource_id
              //   )
            }
          />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/images</Typography>
        <Box classes="apis">
          <Button label="/" onClick={() => getImages({ size: 20 })} />
          <Button
            label="/upload"
            onClick={() =>
              uploadImage({
                file: new File([], "테스트파일~.png"),
                request: { imageName: "ss" },
              })
            }
          />
          <Button
            label="/update"
            onClick={
              () =>
                images.length === 0 ? alert("리스트를 호출해주세요") : null

              // updateImage({
              //     image_id: images[0].imageId,
              //     image_name: "st",
              //   })
            }
          />
          <Button
            label="/file/update"
            onClick={() =>
              images.length === 0
                ? alert("리스트를 호출해주세요")
                : updateFileImage({
                    id: images[0].imageId,
                    file: new File([], "수정파일~.png"),
                    fileName: "st",
                  })
            }
          />
          <Button
            label="/delete"
            onClick={() =>
              images.length === 0
                ? alert("리스트를 호출해주세요")
                : deleteImage({
                    request: {
                      imageIds: [sites[0].siteId],
                    },
                  })
            }
          />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/attach</Typography>
        <Box classes="apis">
          <Button label="/" onClick={() => getAttaches({ size: 20 })} />
          <Button
            label="/insert"
            // onClick={() =>
            //   Attach({
            //     file: new File([], "테스트파일~.hwp"),
            //     fileName: "테스트",
            //   })
            // }
          />
          <Button
            label="/update"
            onClick={
              () =>
                attaches.length === 0 ? alert("리스트를 호출해주세요") : null

              // updateAttach({
              //     id: attaches[0].documentId,
              //     editFileName: "수정수정" + (attaches[0].documentId + 1),
              //   })
            }
          />
          <Button
            label="/file/update"
            onClick={() =>
              attaches.length === 0
                ? alert("리스트를 호출해주세요")
                : updateAttachFile({
                    fileName: "수정수정" + (attaches[0].documentId + 1),
                    file: new File([], "테스트파일~.hwp"),
                    filePath: attaches[0].documentPath,
                    id: attaches[0].documentId,
                  })
            }
          />
          <Button
            label="/download/id"
            onClick={() =>
              attaches.length === 0
                ? alert("리스트를 호출해주세요")
                : downloadAttachFile(2)
            }
          />
        </Box>
      </Box>

      <Box direction="column">
        <Typography type="subtitle2">/dashboard</Typography>
        <Box classes="apis">
          <Button label="/reportHistory" onClick={() => getReportHistory()} />
          <Button
            label="/recentClickHistory/list"
            onClick={() =>
              getRecentClickHistoryList({ configId: DUMMY_CONFIG_ID, cnt: 5 })
            }
          />
          <Button
            label="/progress/list"
            onClick={() => getProgressList(DUMMY_CONFIG_ID)}
          />
          <Button
            label="/report"
            onClick={() => getDashboardReport(DUMMY_CONFIG_ID)}
          />
          <Button
            label="/sendHistory"
            onClick={() => getSendHistoryList(DUMMY_CONFIG_ID)}
          />
          <Button
            label="/phishTypes"
            onClick={() => getPhishTypes(DUMMY_CONFIG_ID)}
          />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/managers</Typography>
        <Box classes="apis">
          <Button label="/" onClick={() => getManagerList()} />
          <Button
            label="/add"
            onClick={() =>
              managers.length === 0
                ? alert("리스트를 호출해주세요")
                : addNewManager({
                    managerCode: "",
                    managerName: "바경우" + managers.length,
                    managerPwd: "Asdf1234$$$$",
                    managerEmail: "parkyw1206@gmail.com",
                    customerCode: "d42585b2f4ce466a97b62f28f4236d77",
                    menuAccess: "[2, 5, 3, 4]",
                    managerType: 2,
                  })
            }
          />
          <Button
            label="/update"
            onClick={() =>
              managers.length === 0
                ? alert("리스트를 호출해주세요")
                : updateManagerInfo({
                    managerCode: "",
                    managerName: "바경우" + (managers.length + 12),
                    managerPwd: "Asdf1234$$$$",
                    managerEmail: "parkyw1206@gmail.com",
                    customerCode: "d42585b2f4ce466a97b62f28f4236d77",
                    menuAccess: "[2, 5, 3, 4]",
                    managerType: 2,
                  })
            }
          />
          <Button
            label="/delete"
            onClick={
              () =>
                managers.length === 0 ? alert("리스트를 호출해주세요") : null
              // deleteManager([managers[0].customerCode])
            }
          />
          <Button
            label="/common"
            onClick={() =>
              getCommonMangerList("d42585b2f4ce466a97b62f28f4236d77")
            }
          />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/statistics</Typography>
        <Box classes="apis">
          <Button label="/list" onClick={() => getStaticsList()} />
          <Button
            label="/getTraineGraphList/configs"
            onClick={() =>
              getStaticsTraineGraphListConfig([
                29, 26, 23, 21, 20, 19, 16, 15, 14, 12, 11, 10, 9, 6, 3, 2,
              ])
            }
          />
          <Button
            label="/getTraineGraphList/type"
            onClick={() =>
              getStaticsTraineGraphListType({
                startDate: 1709251200,
                endDate: 1709251200,
                realTrainingFlag: false,
              })
            }
          />
          <Button
            label="/getTraineGraphList"
            onClick={() =>
              getStaticsTraineGraphList({
                startDate: "2023-03-25+00:00:00",
                endDate: "2023-03-25+00:00:00",
              })
            }
          />
          <Button
            label="/phishing"
            onClick={() =>
              getPhishingGraph({
                configId: 16,
                clickType: 1,
              })
            }
          />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/config</Typography>
        <Box classes="apis">
          <Button
            label="/info"
            onClick={() => getConfigInfo(DUMMY_CONFIG_ID)}
          />
          <Button label="/list" onClick={() => getConfigList()} />
        </Box>
      </Box>
      <Box direction="column">
        <Tooltip message={"detail 만 /program"}>
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            /programs
          </Typography>
        </Tooltip>
        <Box classes="apis">
          <Button label="/list" onClick={() => getProgramList({ size: 20 })} />
          <Button
            label="/detail"
            onClick={() =>
              configList.length === 0
                ? alert("리스트를 호출해주세요")
                : getProgramDetail(configList[0].configId)
            }
          />
          <Button
            label="/add"
            // onClick={() =>
            //   addNewProgram({
            //     configName: "박영우테스트",
            //     startDate: "2024-03-25 00:00:00",
            //     endDate: "2024-04-25 23:59:00",
            //     count: 4,
            //     templates: [9, 8, 6, 1],
            //     isAllUsers: false,
            //     isAllTemplates: true,
            //     entryTime: "2024-03-25 09:40:36",
            //     startTime: null,
            //     endTime: null,
            //     realTrainingFlag: false,
            //   })
            // }
          />
          <Button
            label="/delete"
            onClick={() =>
              configList.length === 0
                ? alert("리스트를 호출해주세요")
                : deleteProgram(configList[0].configId)
            }
          />
          <Button
            label="/update"
            onClick={
              () =>
                configList.length === 0 ? alert("리스트를 호출해주세요") : null
              // updateProgram({
              //     configId: configList[0].configId,
              //     sendStartTime: "00:00",
              //     sendEndTime: "23:59",
              //   })
            }
          />
          <Button
            label="/update/active"
            onClick={() =>
              configList.length === 0
                ? alert("리스트를 호출해주세요")
                : activateProgram({
                    configId: configList[0].configId,
                    isActivated: true,
                  })
            }
          />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/sendHistory</Typography>
        <Box classes="apis">
          {/* <Button onClick={() => getSendHistoryById(DUMMY_CONFIG_ID)}>
            /$id
          </Button>
          <Button
            onClick={() =>
              sendHistoryList.length === 0
                ? alert("리스트를 호출해주세요")
                : reportSendHistory({
                    sid: sendHistoryList[0].sendHistoryId,
                    isReported: false,
                  })
            }
          >
            /report
          </Button> */}
          <Button
            label="/mail/exists"
            onClick={() => checkMailExist(DUMMY_CONFIG_ID)}
          />
          <Button
            label="/send/result"
            onClick={() => sendResultMail(DUMMY_CONFIG_ID)}
          />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/getMailTemplateList</Typography>
        <Box classes="apis">
          <Button
            label="/"
            onClick={() => {
              getMailTemplateList({ size: 20 });
              getImages({ size: 20 });
            }}
          />
          <Button label="/getInfo" onClick={() => getMailTemplateInfo()} />
          <Tooltip
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/insert 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              label="/insertMailTemplate"
              onClick={() =>
                insertMailTemplate({
                  file: new File([], "테스트파일~.txt"),
                  thumb: new File([], "이미지.png"),
                  request: {
                    templateName: "abc",
                    summary: "test description",
                    sendEmail: "parkyw1206@fasoo.com",
                    sendName: "asdfasdfasdf",
                    subject: "ㄴ",
                    siteId: 3,
                    imageId: 1,
                    documentId: 1,
                    categorys: null,
                  },
                })
              }
              type="error"
            />
          </Tooltip>
          <Tooltip
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/insert 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              label=" /updateTemplate"
              // onClick={() =>
              // updateMailTemplate({
              //   template: {
              //     templateName: "abc",
              //     sendEmail: "parkyw1206@fasoo.com",
              //     // templateFile: new File([], "테스트파일~.txt"),
              //     sendName: "asdfasdfasdf",
              //     subject: "ㄴ",
              //     siteId: 3,
              //     imageId: 1,
              //     documentId: 1,
              //     categorys: null,
              //     orgTemplateName: "abc",
              //   },
              //   id: 9,
              // })
              // }
              type="error"
            />
          </Tooltip>
          <Tooltip
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/usedTemplate 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              label="/getUsedTemplate"
              onClick={() => getUsedTemplate()}
              type="error"
            />
          </Tooltip>
          <Tooltip
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/getMailTemplateData 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              label="/getMailTemplateData"
              onClick={() =>
                mailTemplateList.length === 0
                  ? alert("리스트를 호출해주세요")
                  : getMailTemplateData(1)
              }
              type="error"
            />
          </Tooltip>
          <Tooltip
            message={"/getMailTemplateList 로 시작하지 않으나 성격이 미슷"}
          >
            <Button
              label="/eml/parser"
              onClick={() =>
                parseEMLFile({ file: new File([], "새 파일~.eml") })
              }
              type="error"
            />
          </Tooltip>
          <Tooltip
            message={
              "/getMailTemplateList 로 시작하지 않으나, getMailTemplateList/delete 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              label="/deleteMailTemplate"
              onClick={
                () =>
                  mailTemplateList.length === 0
                    ? alert("리스트를 호출해주세요")
                    : null
                // deleteMailTemplate(mailTemplateList.length - 1)
              }
              type="error"
            />
          </Tooltip>
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/traineStatistics</Typography>
        <Box classes="apis">
          <Button
            label="/getTraineGraphList"
            onClick={() =>
              getTraineGraphList({
                startDate: "2023-03-22+00:00:00",
                endDate: "2024-03-22+00:00:00",
              })
            }
          />
          <Button
            label="/inpercent"
            onClick={() =>
              getInpercent({
                startDate: "2023-03-22+00:00:00",
                endDate: "2024-03-22+00:00:00",
              })
            }
          />
          <Button
            label="/getTraineGraphList/type"
            onClick={() =>
              getTraineGraphListType({
                startDate: 1709251200,
                endDate: 1709251200,
                realTrainingFlag: true,
              })
            }
          />
          <Button
            label="/inpercent/type"
            onClick={() =>
              getInpercentType({
                startDate: 1709251200,
                endDate: 1709251200,
                realTrainingFlag: true,
              })
            }
          />
          <Button
            label="/getTraineGraphList/config"
            onClick={() =>
              getInpercentConfig([
                29, 26, 25, 23, 22, 21, 20, 19, 16, 15, 14, 12, 11, 10, 9, 6, 3,
                2,
              ])
            }
          />
          <Button
            label="/inpercent/config"
            onClick={() =>
              getTraineGraphListConfig([
                29, 26, 25, 23, 22, 21, 20, 19, 16, 15, 14, 12, 11, 10, 9, 6, 3,
                2,
              ])
            }
          />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/account</Typography>
        <Box classes="apis">
          <Button label="/logout" onClick={() => sessionLogOut()} />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/customers</Typography>
        <Box classes="apis">
          <Button
            label="/select"
            onClick={() => selectCustomer("d42585b2f4ce466a97b62f28f4236d77")}
          />
          <Button
            label="/name"
            onClick={() => getCustomerName("d42585b2f4ce466a97b62f28f4236d77")}
          />
          <Button label="/origin" onClick={() => getAccessCompanyList()} />
          <Button label="/" onClick={() => getCustomersList()} />
          <Button
            label="/update"
            onClick={() =>
              updateCustomerInfo({
                companyName: "랩소디개발팀B",
                customerCode: "9760866a088249d9ac66a7f22a44fe66",
              })
            }
          />
          <Button
            label="/training"
            onClick={() =>
              checkCustomerTraining("9760866a088249d9ac66a7f22a44fe66")
            }
          />
          <Button label="/categories" onClick={() => getCustomerCategory()} />
          <Button label="/settings" onClick={() => getCustomerSetting()} />
        </Box>
      </Box>
      <Box direction="column">
        <Typography type="subtitle2">/category</Typography>
        <Box classes="apis">
          <Tooltip
            message={
              "/category 로 시작하지 않으나, category/list 와 동일하다 생각해 해당 위치로 표시"
            }
          >
            <Button
              label="/getCategories"
              onClick={() => getCategories()}
              type="error"
            />
          </Tooltip>
          <Button
            label="/add"
            onClick={() =>
              categoryList.length === 0
                ? alert("리스트 호출 해주세요")
                : addCategory("안녕" + categoryList.length + 1)
            }
          />
          <Button
            label="/update"
            onClick={
              () =>
                categoryList.length === 0 ? alert("리스트 호출 해주세요") : null
              // updateCategory({
              //     category_code:
              //       categoryList[categoryList.length - 1].category_code,
              //     category_name:
              //       categoryList[categoryList.length - 1].category_name + "~",
              //   })
            }
          />
          <Button
            label="/delete"
            onClick={
              () =>
                categoryList.length === 0 ? alert("리스트 호출 해주세요") : null
              // deleteCategory(
              //     categoryList[categoryList.length - 1].category_code
              //   )
            }
          />
        </Box>
      </Box>
      <Box classes="thumbnails">
        {/* {mailTemplateList?.map((item) => (
          <HTMLThumbnail
            mail={item}
            image={images.filter((img) => img.imageId === item.image_id)[0]}
          />
        ))} */}
      </Box>
    </Box>
  );
};
export default DummyAPIPage;
