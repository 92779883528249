import "./index.scss";
import { Box, Button, getColor, Icons, Typography, useToast } from "fds";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { QuizContentDTO } from "../../../types/Quiz";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import CategoryDropdown from "../../../component/CategoryDropdown";
import Divider from "../../../component/Divider";
import useStickyPage from "../../../hooks/useStickyObserver";
import classNames from "classnames";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";

const QuizContentDetail: React.FC = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const quizId = searchParams.get("quiz_id");
  const localCustomerCode = getLocalCustomerCode();
  const {
    quizState,
    getQuizContentDetail,
    deleteQuizContent,
    updateQuizContent,
    getQuizCategory,
  } = useQuizStore();
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [categoryInput, setCategoryInput] = useState<string>("");
  const [quizContent, setQuizContent] = useState<QuizContentDTO>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<
    "USED" | "NOT_USED" | null
  >(null);
  const [isUpdateModalopen, setIsUpdateModalOpen] = useState<number | null>(
    null
  );
  const [selectedCategoryList, setSelectedCategoryList] = useState<string[]>(
    []
  );
  const { elementRef, sentinel, isAtTop, isHorizontallyScrollable } =
    useStickyPage();

  useEffect(() => {
    getQuizCategory();
    quizId && getQuizContentDetail({ quizId: parseInt(quizId) });
  }, []);

  useEffect(() => {
    if (quizState.quizContent) {
      setQuizContent(quizState.quizContent);
      setSelectedCategoryList(quizState.quizContent.categorys);
    }
  }, [quizState.quizContent]);

  useEffect(() => {
    const newCatName = categoryInput.trim();
    if (newCatName.length > 0) {
      setCategoryList(
        quizState.quizContentCategory
          .filter((item: string) => item.includes(newCatName))
          .map((c: string) => c)
      );
    } else {
      setCategoryList([]);
    }
  }, [categoryInput]);

  const onDelete = async () => {
    if (quizId) {
      const resp: any = await deleteQuizContent({
        quizIds: [parseInt(quizId)],
      });
      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("quiz.msg.deleteQuizContentSuccess"),
          "success"
        );
        setIsDeleteModalOpen(null);
        navigate(`${process.env.PUBLIC_URL}/quiz/content`);
      } else {
        toast.toastMsg(
          nanoid(),
          t("template.msg.templateDuplicatedTemplateMsg"),
          "error"
        );
      }
    }
  };

  const updateCategory = async (cate: string[]) => {
    if (quizId && quizContent) {
      const resp: any = await updateQuizContent({
        ...quizContent,
        categorys: cate,
      });
      return resp;
    } else return null;
  };

  const onCopy = () => {
    window.sessionStorage.setItem(
      "copyQuizFilter",
      JSON.stringify(quizContent)
    );
    navigate(`${process.env.PUBLIC_URL}/quiz/content/write`);
  };

  return (
    <Box classes="sticky-layout">
      <Box ref={sentinel}>
        <Box
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          <Box direction="column">
            <TitlePath path={[t("menu.quizTitle"), t("menu.quizContent")]} />
            <Box alignItems="center" classes="mg mt-8">
              <Box
                onClick={() =>
                  navigate(`${process.env.PUBLIC_URL}/quiz/content`)
                }
                style={{ cursor: "pointer" }}
              >
                <Icons
                  variant="line"
                  label="arrow"
                  direction="left"
                  classes="mg mr-8"
                />
              </Box>
              <Typography type="h1">{t("quiz.label.details")}</Typography>
            </Box>
          </Box>
          {quizId &&
          (quizContent?.commonId === undefined || !localCustomerCode) ? (
            <Box>
              <Button
                label={t("common.button.update")}
                type="secondary"
                btnStyle="fill"
                leadingIcon={<Icons variant="line" label="edit" />}
                onClick={() => setIsUpdateModalOpen(parseInt(quizId))}
              />

              <Button
                label={t("common.button.delete")}
                type="error"
                btnStyle="fill"
                leadingIcon={
                  <Icons
                    variant="line"
                    label="delete"
                    stroke={getColor("COLOR_ICON_ERROR")}
                  />
                }
                onClick={() => setIsDeleteModalOpen("USED")}
                classes="mg ml-10"
              />
            </Box>
          ) : (
            <Box>
              <Button
                label={t("common.button.copy")}
                type="secondary"
                btnStyle="fill"
                leadingIcon={<Icons variant="line" label="copy" />}
                onClick={onCopy}
                classes="mg ml-10"
              />
            </Box>
          )}
        </Box>
      </Box>
      {quizContent && (
        <Box direction="column" classes="body">
          <Box classes="mg mt-36 mb-36 ml-16">
            <Box width={248} alignItems="center" height={20}>
              <Typography
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.quizName")}
              </Typography>
            </Box>
            <Box alignItems="center" width={"598px"}>
              <Typography>{quizContent?.quizName}</Typography>
            </Box>
          </Box>

          <Box classes="mg mb-36 ml-16">
            <Box width={248} alignItems="center" height={20}>
              <Typography
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.question")}
              </Typography>
            </Box>
            <Box alignItems="center" width={"598px"}>
              <Typography>{quizContent.quizBody}</Typography>
            </Box>
          </Box>

          <Box classes="mg mb-36 ml-16">
            <Box width={248} alignItems="center" height={20}>
              <Typography
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.format")}
              </Typography>
            </Box>
            <Box alignItems="center" width={"598px"}>
              <Box direction="column">
                <Typography>
                  {quizContent.quizType === 1
                    ? t("quiz.label.formatTF")
                    : t("quiz.label.formatChoice")}
                </Typography>
                <Divider classes="mg mt-20 mb-20" direction="row" />
                {quizContent.quizType !== 1 && (
                  <Box classes="mg mt-20" direction="column">
                    {quizContent.quizChoices?.map((q, index) => (
                      <Box classes="mg mb-20">
                        <Box width={120} classes="mg mr-16 mb-20">
                          <Typography
                            exactColor={getColor("COLOR_TEXT_SECONDARY")}
                            width="120px"
                          >
                            {`${t("quiz.label.choice2")}  ${index + 1}`}
                          </Typography>
                        </Box>
                        {q}
                      </Box>
                    ))}
                  </Box>
                )}
                <Box classes="quiz__detail__answer__container">
                  <Box width={104} classes="mg mr-16">
                    <Typography exactColor={getColor("COLOR_TEXT_SECONDARY")}>
                      {t("quiz.label.answer")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {quizContent.quizType === 1
                        ? quizContent.quizAnswer === 0
                          ? t("quiz.label.choiceO")
                          : t("quiz.label.choiceX")
                        : quizContent?.quizAnswer !== undefined &&
                          quizContent?.quizAnswer + 1}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box classes="mg mb-20 ml-16">
            <Box width={248} alignItems="center" height={20}>
              <Typography
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.answerEx")}
              </Typography>
            </Box>
            <Box alignItems="center" width={"598px"}>
              <Typography>{quizContent.quizComments}</Typography>
            </Box>
          </Box>

          {/* category start */}
          <Box
            style={{
              marginBottom: "62px",
              marginTop: "24px",
              marginLeft: "16px",
            }}
          >
            <Box width={248} alignItems="center" height={20}>
              <Typography
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.category")}
              </Typography>
            </Box>
            <Box width={"598px"} direction="column">
              <CategoryDropdown
                dropdownList={categoryList}
                searchword={categoryInput}
                updateSearchword={(searchword: string) =>
                  setCategoryInput(searchword)
                }
                onCategoryChangeCb={updateCategory}
                initialSelectedChips={selectedCategoryList}
                updateSelectedChips={(selectedChips) =>
                  setSelectedCategoryList(selectedChips)
                }
                width={"598px"}
              />
            </Box>
          </Box>
          {/* category end  */}
        </Box>
      )}
      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen === null ? false : true}
          onClose={() => setIsDeleteModalOpen(null)}
          title={t("quiz.msg.deleteQuizContentTitle")}
          afterSubmitButton={onDelete}
          subMessage={
            isDeleteModalOpen === "USED"
              ? t("quiz.msg.deleteQuizContentAlert")
              : t("quiz.msg.deleteQuizContentMsg")
          }
          type="delete"
        />
      )}
      {!!isUpdateModalopen && (
        <CommonModal
          open={!!isUpdateModalopen}
          onClose={() => setIsUpdateModalOpen(null)}
          title={t("quiz.msg.updateQuizContentTitle")}
          afterSubmitButton={() => {
            navigate(
              `${process.env.PUBLIC_URL}/quiz/content/write?quiz_id=${isUpdateModalopen}`
            );
            setIsUpdateModalOpen(null);
          }}
          subMessage={t("quiz.msg.updateQuizContentAlert")}
        />
      )}
    </Box>
  );
};

export default QuizContentDetail;
