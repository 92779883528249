import {
  Modal,
  Box,
  Button,
  ColumnDatas,
  getColor,
  Icons,
  InputField,
  Table,
  Tooltip,
  Typography,
  useToast,
  Dropdown,
  DropdownItem,
} from "fds";
import TitlePath from "../../component/TitlePath";
import { useEffect, useLayoutEffect, useState } from "react";
import "./index.scss";
import TreeSimple from "../../component/TreeSimple";
import { SimpleTreeNode, UserType } from "../../types/User";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import NoUserImage from "../../shared/image/NoUserImage";
import CreateUpdateUserModal from "./CreateUpdateUserModal";
import { nanoid } from "@reduxjs/toolkit";
import InsertDeptByCSVModal from "./InsertDeptByCSVModal";
import { useTranslation } from "react-i18next";
import useCheckboxGroup from "../../shared/utils/checkbox";
import ManagerIcon from "../../shared/image/ManagerIcon";
import CreateUpdateDeptModal from "../../component/TreeSimple/CreateUpdateDeptModal";
import { TABLE_HIGHLIGHT_TIMED_OUT } from "../../shared/constant";
import useTimedReset from "../../hooks/useTimedReset";

const Organ: React.FC = () => {
  const {
    deptState,
    getDeptList,
    getOrganUserList,
    getOrganUserCount,
    exportDeptFile,
    deleteOrganUserList,
  } = useDeptStore();
  const toast = useToast();
  const { t } = useTranslation();
  const checkboxGroup = useCheckboxGroup<string>();
  const [clickedId, changeClickedId] = useState("COMPANY");
  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();
  const [userList, changeUserList] = useState<UserType[]>([]);
  const [targetDept, changeTargetDept] = useState<SimpleTreeNode | undefined>();
  const [regitOrUpdateDeptInfo, changeRegitOrUpdateDeptInfo] = useState({
    isOpen: false,
    parentCode: "",
    deptCode: "",
    deptName: "",
    parentName: "",
    type: "CLOSE",
  });
  const [usersIdCreated, setUsersIdCreated] = useTimedReset<string[]>(
    [],
    TABLE_HIGHLIGHT_TIMED_OUT
  );
  const [regitOrUpdateUserInfo, changeRegitOrUpdateUserInfo] = useState({
    isOpen: false,
    userId: "",
  });
  const [targetDeleteUser, changeTargetDeleteUser] = useState<string[]>([]);
  const [uploadUserDeptModalMode, changeUploadUserDeptModalMode] = useState<
    "DEPT" | "CLOSE" | "USER"
  >("CLOSE");
  const [searchDeptTxt, changeSearchDeptText] = useState("");
  const [buttonClick, setButtonClick] = useState(0);
  const [height, setHeight] = useState(window.innerHeight - 332);
  useEffect(() => {
    getDeptList();
    getOrganUserCount();
  }, []);
  useEffect(() => {
    if (searchDeptTxt === "") changeOrganTree(deptState.organList);
    else changeSearchDeptText("");
  }, [deptState.organList]);
  useEffect(() => {
    changeClickedId("COMPANY");
    changeOrganTree(filterDeptList(deptState.organList));
  }, [searchDeptTxt]);
  useEffect(() => {
    changeUserList(deptState.userListInDept);
  }, [deptState.userListInDept]);
  useEffect(() => {
    changeTargetDept(
      deptState.organList?.filter(
        (item: SimpleTreeNode) => item.deptCode === clickedId
      )[0]
    );
  }, [clickedId, deptState.organList]);
  useEffect(() => {
    checkboxGroup.handleAllClick([]);
    getOrganUserList(clickedId);
  }, [clickedId]);

  const handleRemoveUser = async (userId: string[] | null) => {
    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
    if (!userId) return;
    let resp: any = await deleteOrganUserList(userId);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("user.msg.userDeleteSuccessMsg"), "success");
    } else {
      toast.toastMsg(
        nanoid(),
        resp?.payload?.message ?? t("user.msg.userDeleteFailMsg"),
        "error"
      );
    }
    changeTargetDeleteUser([]);
    checkboxGroup.handleAllClick([]);
    getOrganUserList(clickedId);
  };

  const renderSelectDeptInfo = () => {
    return (
      <Box
        alignItems="center"
        height={24}
        classes="mg mb-12"
        justifyContent="space-between"
      >
        <Box alignItems="center" width={"90%"}>
          <Tooltip message={targetDept?.deptName} maxWidth={"100%"}>
            <Typography type="subtitle2" classes="mg ml-6" ellipsis>
              {targetDept?.deptName}
            </Typography>
          </Tooltip>

          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_BRAND")}
            classes="mg ml-8"
          >
            {userList?.length ?? 0}
          </Typography>
        </Box>
      </Box>
    );
  };
  const filterDeptList = (organList: SimpleTreeNode[]) => {
    if (searchDeptTxt === "") return organList;
    if (!organList || organList?.length === 0) return [];
    let remainDeptList = organList?.filter(
      (item) => item?.deptName.includes(searchDeptTxt) && item.type === "dept"
    );
    let returnDeptList = organList?.filter(
      (item) => item?.deptCode === "COMPANY"
    );

    while (remainDeptList?.length > 0 && remainDeptList[0]) {
      let targetDept = remainDeptList[0];
      if (
        targetDept?.parentCode !== "COMPANY" &&
        returnDeptList?.filter(
          (item) => item?.deptCode === targetDept?.parentCode
        ).length === 0 &&
        targetDept
      ) {
        remainDeptList.push(
          organList.filter(
            (item) =>
              item?.deptCode === targetDept?.parentCode && item.type === "dept"
          )[0]
        );
      }
      if (
        returnDeptList.filter((item) => item?.deptCode === targetDept?.deptCode)
          .length === 0
      )
        returnDeptList.push(targetDept);

      remainDeptList.shift();
    }

    return returnDeptList;
  };

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [userList]);

  function transformData(data: UserType[]) {
    const dataTransformed = [] as ColumnDatas[];
    data?.map((item: UserType) => {
      const dataItemTransformed = {
        data: [
          {
            columnText: (
              <Tooltip message={item.userName}>
                <Typography>{item.userName}</Typography>
              </Tooltip>
            ),
          },
          {
            columnText: (
              <Box alignItems="center">
                {item.managerFlag && <ManagerIcon />}
                <Typography>{item.jobTitle}</Typography>
              </Box>
            ),
          },
          {
            columnText: (
              <Tooltip message={item.userEmail}>
                <Typography>{item.userEmail}</Typography>
              </Tooltip>
            ),
          },
          {
            columnText: (
              <Tooltip message={item.etc1}>
                <Typography>{item.etc1}</Typography>
              </Tooltip>
            ),
          },
        ],
        id: String(item.userId),
        checkBoxClick: () => {
          item.userId && checkboxGroup.handleClick(item.userId);
        },
        hover: (
          <Dropdown buttonClick={buttonClick} align="right" width={180}>
            <DropdownItem
              onClick={() => {
                changeRegitOrUpdateUserInfo({
                  isOpen: true,
                  userId: item.userId ?? "",
                });
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              label={
                <Box>
                  <Icons variant="line" label="edit2" />
                  <Typography classes="mg ml-8">
                    {t("common.button.update")}
                  </Typography>
                </Box>
              }
            />
            <DropdownItem state="divider" />
            <DropdownItem
              type="warning"
              onClick={() => {
                item.userId && changeTargetDeleteUser([item.userId]);
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                checkboxGroup.handleAllClick([]);
              }}
              label={
                <Box>
                  <Icons
                    variant="line"
                    label="delete"
                    stroke={getColor("COLOR_ICON_ERROR")}
                  />
                  <Typography
                    classes="mg ml-8"
                    exactColor={getColor("COLOR_TEXT_ERROR")}
                  >
                    {t("common.button.delete")}
                  </Typography>
                </Box>
              }
            />
          </Dropdown>
        ),
      };
      dataTransformed.push(dataItemTransformed);
    });
    return dataTransformed;
  }

  return (
    <Box classes="main organ">
      {regitOrUpdateUserInfo.isOpen && organTree && organTree.length > 0 && (
        <CreateUpdateUserModal
          isOpen={regitOrUpdateUserInfo.isOpen}
          targetDept={targetDept}
          updateUserId={regitOrUpdateUserInfo.userId}
          onClose={() =>
            changeRegitOrUpdateUserInfo({
              isOpen: false,
              userId: "",
            })
          }
          treeNodes={organTree}
          setUsersIdCreated={setUsersIdCreated}
        />
      )}
      {regitOrUpdateDeptInfo.isOpen && organTree && organTree.length > 0 && (
        <CreateUpdateDeptModal
          isOpen={regitOrUpdateDeptInfo.isOpen}
          parentCode={regitOrUpdateDeptInfo.parentCode}
          type={regitOrUpdateDeptInfo.type}
          deptName={regitOrUpdateDeptInfo.deptName}
          deptCode={regitOrUpdateDeptInfo.deptCode}
          parentName={regitOrUpdateDeptInfo.parentName}
          onClose={() =>
            changeRegitOrUpdateDeptInfo({
              isOpen: false,
              parentCode: "",
              deptCode: "",
              deptName: "",
              parentName: "",
              type: "CLOSE",
            })
          }
          treeNodes={organTree}
        />
      )}
      {uploadUserDeptModalMode !== "CLOSE" && (
        <InsertDeptByCSVModal
          isOpen={true}
          type={uploadUserDeptModalMode}
          onClose={() => changeUploadUserDeptModalMode("CLOSE")}
        />
      )}
      {targetDeleteUser.length > 0 && (
        <Modal
          title={t("user.userDelete")}
          onClose={() => changeTargetDeleteUser([])}
          open={targetDeleteUser.length > 0}
          width={540}
          footer={
            <Box justifyContent="flex-end">
              <Button
                btnStyle="fill"
                type="secondary"
                label={t("common.button.cancel")}
                onClick={() => changeTargetDeleteUser([])}
              />
              <Button
                btnStyle="fill"
                type="error"
                label={t("common.button.delete")}
                classes={"mg ml-8"}
                onClick={() => {
                  handleRemoveUser(
                    targetDeleteUser.filter((item) => item !== null)
                  );
                }}
              />
            </Box>
          }
          body={
            <Box width={"100%"}>
              <Typography exactColor={getColor("COLOR_TEXT_SECONDARY")}>
                {t("common.msg.deleteConfirmMsg")}
              </Typography>
            </Box>
          }
        />
      )}
      <Box justifyContent="space-between" alignItems="center">
        <Box direction="column" height={86}>
          <TitlePath path={[t("menu.userManagement")]} />
          <Typography
            classes="mg mt-8"
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.userManagement")}
          </Typography>
        </Box>
        <Box alignItems="top" height={86}>
          <Button
            type="secondary"
            btnStyle="fill"
            label={t("dept.deptAddCsvTitle")}
            classes="mg ml-10"
            leadingIcon={<Icons variant="line" label="plusCircle" />}
            onClick={() => changeUploadUserDeptModalMode("DEPT")}
          />
          <Button
            type="secondary"
            btnStyle="fill"
            label={t("user.userAddCsvTitle")}
            classes="mg ml-10"
            leadingIcon={<Icons variant="line" label="addPerson" />}
            onClick={() => changeUploadUserDeptModalMode("USER")}
          />
          <Button
            type="secondary"
            btnStyle="fill"
            label={t("common.button.exportToExcel")}
            classes="mg ml-10"
            leadingIcon={<Icons variant="line" label="download" />}
            onClick={exportDeptFile}
          />
        </Box>
      </Box>
      <Box classes="organ" height={"calc(100% - 84px)"}>
        <Box classes="organ-tree" direction="column">
          <Box
            justifyContent="space-between"
            alignItems="center"
            height={32}
            classes="mg mb-12"
          >
            <Box>
              <Typography type="subtitle2">{t("dept.deptChart")}</Typography>
              <Typography
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_BRAND")}
                classes="mg ml-4"
              >
                {deptState.userTotal}
              </Typography>
            </Box>
            <Button
              size="sm"
              btnStyle="link"
              label={t("dept.deptAddSimple")}
              leadingIcon={<Icons variant="line" label="plusCircle" />}
              onClick={() =>
                changeRegitOrUpdateDeptInfo({
                  isOpen: true,
                  deptCode: "",
                  parentCode: "COMPANY",
                  deptName: "",
                  parentName: "",
                  type: "CREATE",
                })
              }
            />
          </Box>
          <InputField
            leadingIcon={<Icons variant="line" label="search" />}
            placeholder={t("dept.msg.undefinedNameMsg")}
            size={"md"}
            onChange={(e) => changeSearchDeptText(e.target.value)}
            width={296}
            classes="mg mb-12"
          />
          {organTree && organTree.length > 0 ? (
            <TreeSimple
              rootCode={"COMPANY"}
              clickedId={clickedId}
              changeClickedId={changeClickedId}
              treeNodes={organTree}
              treeType={"DEPT_ONLY"}
              depth={0}
              isOrganPage
            />
          ) : (
            <Box
              width={"100%"}
              height={500}
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <NoUserImage />
              <Typography classes="mg mt-20" type="h3">
                부서 없음
              </Typography>
              <Typography
                classes="mg mt-8"
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                부서를 추가해 주세요
              </Typography>
              <Button
                type="primary"
                btnStyle="fill"
                size="lg"
                label={t("dept.deptAddSimple")}
                classes="mg mt-24"
                leadingIcon={<Icons variant="line" label="plusCircle" />}
                onClick={() => alert("부서추가")}
              />
            </Box>
          )}
        </Box>
        <Box classes="organ-body" direction="column">
          <Box justifyContent="space-between" height={44} alignItems="center">
            <Box>{renderSelectDeptInfo()}</Box>
            <Box alignItems="start" height={44}>
              <Button
                btnStyle="link"
                label={t("user.commonUserAdd")}
                leadingIcon={<Icons variant="line" label="addPerson" />}
                onClick={() =>
                  changeRegitOrUpdateUserInfo({
                    isOpen: true,
                    userId: "",
                  })
                }
              />
              <Button
                btnStyle="link"
                label={t("common.button.delete")}
                onClick={() => changeTargetDeleteUser(checkboxGroup.selected)}
                leadingIcon={
                  <Icons
                    variant="line"
                    label="delete"
                    stroke={
                      checkboxGroup.selected.length >= 1
                        ? getColor("COLOR_TEXT_PRIMARY")
                        : getColor("COLOR_TEXT_DISABLED")
                    }
                  />
                }
                textColor={
                  checkboxGroup.selected.length >= 1
                    ? getColor("COLOR_TEXT_PRIMARY")
                    : getColor("COLOR_TEXT_DISABLED")
                }
                state={
                  checkboxGroup.selected.length === 0 ? "disable" : "default"
                }
              />
              {/*
              <Button
                btnStyle="link"
                type="primary"
                leadingIcon={
                  <Icons
                    variant="line"
                    label="filter"
                    stroke={getColor("COLOR_ICON_PRIMARY")}
                  />
                }
                onClick={() => {
                  alert("Filter");
                }}
                label={t("common.list.filter")}
              />
              */}
            </Box>
          </Box>
          <Table
            border="none"
            clickIds={checkboxGroup.selected.map((userId) => String(userId))}
            columnHeight="md"
            data={transformData(userList)}
            highlightIds={usersIdCreated}
            totalCount={userList?.length}
            endReached={() => {}}
            checkBoxClick={() =>
              checkboxGroup.handleAllClick(
                checkboxGroup.selected.length === userList.length
                  ? []
                  : userList.map((q) => q.userId + "")
              )
            }
            header={[
              {
                headerText: t("user.userName"),
                width: "150px",
              },
              {
                headerText: t("user.userPosition"),
                width: "120px",
              },
              {
                headerText: t("user.userEmail"),
                width: "272px",
              },
              {
                headerText: t("user.userMemo"),
                width: "calc(100% - 600px)",
              },
            ]}
            width={"100%"}
            height={userList?.length > 0 ? height + "px" : "0px"}
          />
          {!userList ||
            (userList.length === 0 && (
              <Box
                width={"100%"}
                height={500}
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <NoUserImage />
                <Typography classes="mg mt-20" type="h3">
                  사용자 없음
                </Typography>
                <Typography
                  classes="mg mt-8"
                  type="body2_rg"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("user.msg.noUserMsg")}
                </Typography>
                <Button
                  type="primary"
                  btnStyle="fill"
                  size="lg"
                  label={t("user.commonUserAdd")}
                  classes="mg mt-24"
                  leadingIcon={<Icons variant="line" label="plusCircle" />}
                  onClick={() =>
                    changeRegitOrUpdateUserInfo({
                      isOpen: true,
                      userId: "",
                    })
                  }
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Organ;
