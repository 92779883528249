import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Accordions as AccordionsNew,
  InputField,
  Icons as IconsNew,
  Button as ButtonNew,
  Tag as TagNew,
  Calendar as CalendarNew,
  Toggle as ToggleNew,
  Table as TableNew,
  Tooltip as TooltipNew,
  TimePicker,
  useToast as useToastNew,
  Radio as RadioNew,
  Checkbox as CheckboxNew,
  Tag,
  Dropdown,
  DropdownItem,
} from "fds";

import { useEffect, useState } from "react";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { SimpleTreeNode, UserType } from "../../../types/User";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import { ProgramAddBean, userListBean } from "../../../types/Info";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { Scenario } from "../../../types/Scenario";
import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate, useSearchParams } from "react-router-dom";
import TitlePath from "../../../component/TitlePath";
import NoQuizImage from "../../../shared/image/NoQuizImage";
import { QuizContentDTO, QuizTrainingInfoReq } from "../../../types/Quiz";
import QuizModal from "../../QuizCourse/Write/QuizModal";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import classNames from "classnames";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import ScenarioModal from "./ScenarioModal";

import "../../../shared/style/stickyLayout.scss";
import useStickyPage from "../../../hooks/useStickyObserver";
import EditCancelModal from "../../QuizCourse/Write/EditCancelModal";
import NoSelectedUserImage from "../../../shared/image/NoSelectedUserImage";
import OrganCard from "../../../component/OrganCard";
import { DeptType } from "../../../types/Dept";
import UserModal from "../../QuizCourse/Write/UserModal";
import useNavigateState from "../../../hooks/useNavigateState";
import { NavigateStateKeys } from "../../../hooks/NavigateStateKeys";

const WriteTraining: React.FC = ({}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  // step
  const { t, i18n } = useTranslation();

  const { deptState, getDeptList } = useDeptStore();
  const { getCategories } = useCategoryStore();
  const { programState, addNewProgram, getProgramDetail, updateProgram } =
    useProgramStore();
  const { mailTemplateState, getMailTemplateList } = useMailTemplateStore();
  const { quizState, getQuizContentList, getSelectedOrgan } = useQuizStore();
  const toast = useToastNew();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [configId, setConfigId] = useState<number | undefined>();
  const { elementRef, sentinel, isAtTop, isHorizontallyScrollable } =
    useStickyPage();

  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();

  const checkboxCateGroup = useCheckboxGroup<string>();
  const userCheckboxGroup = useCheckboxGroup<string>();
  const deptCheckboxGroup = useCheckboxGroup<string>();
  const quizCheckboxGroup = useCheckboxGroup<number | undefined>();
  const scenarioCheckboxGroup = useCheckboxGroup<number | undefined>();
  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState<UserType[]>([]);
  const [selectedDeptInfo, setSelectedDeptInfo] = useState<DeptType[]>([]);

  const [searchWord, setSearchWord] = useState("");

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);
  const [isAllDayDate, setIsAllDayDate] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [isAllDayTime, setIsAllDayTime] = useState<boolean>(false);
  const initialProgramInfo = {
    configName: "",
    sendStartDate: new Date().getTime() / 1000,
    sendEndDate: new Date().getTime() / 1000,
    sendMailCount: undefined,
    templateIds: "",
    sendStartTime: null,
    sendEndTime: null,
    autoReplyFlag: false,
    realTrainingFlag: false,
    activateFlag: false,
    collectionPeriod: 7,
  };
  const [programInfo, setProgramInfo] =
    useState<ProgramAddBean>(initialProgramInfo);
  const [selctedUserListInDetail, changeSelctedUserListInDetail] = useState<
    userListBean[]
  >([]);

  const [isStarted, changeIsStared] = useState(false);
  const [quizFlag, setQuizFlag] = useState<boolean>(false);
  const [isQuizModalOpen, setIsQuizModalOpen] = useState<boolean>(false);
  const [isScenarioModalOpen, setIsScenarioModalOpen] =
    useState<boolean>(false);
  const [selectedQuizList, setSelectedQuizList] = useState<QuizContentDTO[]>(
    []
  );
  const [selectedScenarioList, setSelectedScenarioList] = useState<Scenario[]>(
    []
  );

  const [quizInfo, setQuizInfo] = useState<QuizTrainingInfoReq>({
    quizType: 1,
    quizIds: [],
    quizCount: 0,
    quizSolvePeriod: 7,
  });
  const toShowMailCntError =
    programInfo.sendMailCount === undefined ||
    (programInfo.sendMailCount > 0 && programInfo.sendMailCount < 100);

  const formatTime = (str: string) => {
    const timePart = str.split("T")[1]; // "00:45:08"
    const [hours, minutes] = timePart.split(":"); // ["00", "45"]
    return `${hours}${minutes}`;
  };

  const { navigateWithState } = useNavigateState(
    NavigateStateKeys.fromProgramCreate
  );

  const updateSendDate = (type: "start" | "end", hour: number, min: number) => {
    const adjustedHour = hour === 12 ? 0 : hour === 24 ? 12 : hour;

    if (type === "start") {
      setStartDateTime(adjustedHour + ":" + min);
      setStartDate((prevStartDate) => {
        const newDate = prevStartDate ? new Date(prevStartDate) : new Date();
        newDate.setHours(adjustedHour);
        newDate.setMinutes(min);
        newDate.setMilliseconds(0);
        return newDate;
      });
    } else {
      setEndDateTime(adjustedHour + ":" + min);
      setEndDate((prevEndDate) => {
        const newDate = prevEndDate ? new Date(prevEndDate) : new Date();
        newDate.setHours(adjustedHour);
        newDate.setMinutes(min);
        newDate.setMilliseconds(0);
        return newDate;
      });
    }
  };

  const adjustTime = (time: string, hoursToSubtract: number): string => {
    const hours = time.includes(":")
      ? parseInt(time.slice(0, 2), 10)
      : parseInt(time.slice(0, 2), 10);
    const minutes = time.includes(":")
      ? parseInt(time.slice(3, 5), 10)
      : parseInt(time.slice(2, 4), 10);

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    date.setHours(date.getHours() + hoursToSubtract);

    const adjustedHours = date.getHours().toString().padStart(2, "0");
    const adjustedMinutes = date.getMinutes().toString().padStart(2, "0");

    return `${adjustedHours}:${adjustedMinutes}`;
  };

  const onSubmit = async () => {
    // 훈련 기본 정보
    // 유효성 검사
    if (!startDate || !endDate) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.notSelectedDurationMsg"),
        "error"
      );
      return;
    }

    const tempStartDate = new Date(dayjs(startDate).valueOf());
    const tempEndDate = new Date(dayjs(endDate).valueOf());
    // 시나리오
    if (isAllDayDate) {
      tempStartDate.setHours(0);
      tempStartDate.setMinutes(0);
      tempStartDate.setSeconds(0);
      tempEndDate.setHours(23);
      tempEndDate.setMinutes(59);
      tempEndDate.setSeconds(59);
    }

    if (startDate >= endDate) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programEndBeforeStartMsg"),
        "error"
      );
      return;
    }
    if (
      (!isStarted && dayjs(tempStartDate).valueOf() < dayjs().valueOf()) ||
      dayjs(tempEndDate).valueOf() < dayjs().valueOf()
    ) {
      toast.toastMsg(nanoid(), t("program.msg.programNowFailMsg"), "error");
      return;
    }
    if (!isAllDayTime && startTime >= endTime) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programStartTimeExceedMsg"),
        "error"
      );
      return;
    }
    if (
      programInfo.sendMailCount &&
      programInfo.sendMailCount > selectedScenarioList.length
    ) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programLessTemplateMsg"),
        "error"
      );
      return;
    }

    if (
      programInfo.configName &&
      startDate &&
      endDate &&
      programInfo.sendMailCount
    ) {
      const params = quizFlag
        ? {
            configName: programInfo.configName,
            sendStartDate: tempStartDate?.getTime() / 1000,
            sendEndDate: tempEndDate?.getTime() / 1000,
            sendMailCount: programInfo.sendMailCount,
            templateIds: selectedScenarioList
              .filter((s) => s.templateId != null)
              .map((item) =>
                item.templateId ? item.templateId.toString() : ""
              ),
            sendStartTime: isAllDayTime ? "" : adjustTime(startTime, -9),
            sendEndTime: isAllDayTime ? "" : adjustTime(endTime, -9),
            deptIds: deptCheckboxGroup.selected.filter((item) => item),
            userIds: organTree
              ?.filter(
                (item) =>
                  item.type === "user" &&
                  userCheckboxGroup.selected.includes(item.deptCode)
                // &&
                // !deptCheckboxGroup.selected.includes(item.parentCode)
              )
              .map((item1) => item1?.deptCode),
            autoReplyFlag: !programInfo.activateFlag
              ? false
              : programInfo.autoReplyFlag,
            activateFlag: programInfo.activateFlag,
            realTrainingFlag: programInfo.realTrainingFlag
              ? programInfo.realTrainingFlag
              : false,
            quizIds: selectedQuizList
              .filter((q) => q.quizId != null)
              .map((item) => (item.quizId ? item.quizId.toString() : "")),
            quizCount: quizInfo.quizCount,
            quizType: quizInfo.quizType,
            collectionPeriod: programInfo.collectionPeriod,
            quizSolvePeriod: quizInfo.quizSolvePeriod,
          }
        : {
            configName: programInfo.configName,
            sendStartDate: tempStartDate?.getTime() / 1000,
            sendEndDate: tempEndDate?.getTime() / 1000,
            sendMailCount: programInfo.sendMailCount,
            templateIds: selectedScenarioList
              .filter((s) => s.templateId != null)
              .map((item) =>
                item.templateId ? item.templateId.toString() : ""
              ),
            sendStartTime: isAllDayTime ? "" : adjustTime(startTime, -9),
            sendEndTime: isAllDayTime ? "" : adjustTime(endTime, -9),
            deptIds: deptCheckboxGroup.selected.filter((item) => item),
            userIds: organTree
              ?.filter(
                (item) =>
                  item.type === "user" &&
                  userCheckboxGroup.selected.includes(item.deptCode)
                // &&
                // !deptCheckboxGroup.selected.includes(item.parentCode)
              )
              .map((item1) => item1?.deptCode),
            autoReplyFlag: !programInfo.activateFlag
              ? false
              : programInfo.autoReplyFlag,
            activateFlag: programInfo.activateFlag,
            realTrainingFlag: programInfo.realTrainingFlag
              ? programInfo.realTrainingFlag
              : false,
            collectionPeriod: programInfo.collectionPeriod,
          };

      if (configId) {
        const resp: any = await updateProgram({
          ...params,
          configId,
        });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("program.msg.programUpdateSuccessMsg"),
            "success"
          );
          navigate(`${process.env.PUBLIC_URL}/manageTrain/program`);
        } else {
          toast.toastMsg(
            nanoid(),
            t("program.msg.programUpdateFailMsg"),
            "error"
          );
        }
      } else {
        const resp: any = await addNewProgram(params);
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("program.msg.programAddSuccessMsg"),
            "success"
          );
          const idCreated = resp.payload.data?.configId || "";
          navigateWithState(`${process.env.PUBLIC_URL}/manageTrain/program`, [
            idCreated.toString(),
          ]);
        } else {
          toast.toastMsg(nanoid(), t("program.msg.programAddFailMsg"), "error");
        }
      }
    }
  };

  function transformScenarioList(categories: Scenario[]) {
    const scenarioListTransformed = [];
    for (let i = 0; i < categories.length; i++) {
      const categoryCur = categories[i];
      const dataIsCommon = categoryCur.commonId ? (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
        />
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.private")}
          textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
        />
      );

      const dataScenarioName = (
        <BoxNew>
          <TooltipNew
            message={categoryCur.templateName}
            maxWidth={"100%"}
            place={"top"}
            children={
              <TypographyNew
                ellipsis
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {categoryCur.templateName}
              </TypographyNew>
            }
          ></TooltipNew>
        </BoxNew>
      );

      const dataCategory = categoryCur.categorys && (
        <CategoryTagList categorys={categoryCur.categorys} />
      );

      const scenarioTransformed = {
        data: [
          { columnText: "" },
          { columnText: dataIsCommon },
          { columnText: dataScenarioName },
          { columnText: dataCategory },
        ],
        id: String(categoryCur.templateId),
        checkBoxClick: () => {
          scenarioCheckboxGroup.handleClick(categoryCur.templateId);
        },
      };

      scenarioListTransformed.push(scenarioTransformed);
    }

    return scenarioListTransformed;
  }

  function transformQuizList(quizList: QuizContentDTO[]) {
    const quizListTransformed = [];
    for (let i = 0; i < quizList.length; i++) {
      const quizCur = quizList[i];

      // 구분
      const dataIsCommon = quizCur.commonId ? (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_DEEP_BLUE_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_DEEP_BLUE_BG")}
        />
      ) : (
        <TagNew
          as={"p"}
          type={"box"}
          text={t("template.private")}
          textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
          backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
        />
      );

      // 퀴즈이름
      const dataQuizName = (
        <BoxNew>
          <TooltipNew
            message={quizCur.quizName}
            maxWidth={"100%"}
            place={"top"}
            children={
              <TypographyNew
                ellipsis
                type={"body2_rg"}
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {quizCur.quizName}
              </TypographyNew>
            }
          ></TooltipNew>
        </BoxNew>
      );

      // 카테고리
      const dataCategory = quizCur.categorys && (
        <CategoryTagList categorys={quizCur.categorys} />
      );

      // 유형
      const dataQuizType = (
        <TypographyNew
          type={"body2_rg"}
          exactColor={getColor("COLOR_TEXT_PRIMARY")}
        >
          {quizCur.quizType === 1
            ? t("quiz.label.formatTF")
            : t("quiz.label.formatChoice")}
        </TypographyNew>
      );

      const quizTransformed = {
        data: [
          { columnText: "" },
          { columnText: dataIsCommon },
          { columnText: dataQuizName },
          { columnText: dataCategory },
          { columnText: dataQuizType },
        ],
        id: String(quizCur.quizId),
        checkBoxClick: () => {
          quizCheckboxGroup.handleClick(quizCur.quizId);
        },
      };

      quizListTransformed.push(quizTransformed);
    }

    return quizListTransformed;
  }

  function checkTableRenderStatus() {
    const hasQuizList = selectedQuizList && selectedQuizList.length > 0;
    if (!hasQuizList) return 1;
    else return 0;
  }

  function checkScenarioTableRenderStatus() {
    const hasScenarioList =
      selectedScenarioList && selectedScenarioList.length > 0;
    if (!hasScenarioList) return 1;
    else return 0;
  }

  function renderTableSwitch() {
    if (checkTableRenderStatus() === 1) {
      return noQuizSelected;
    }
  }

  function renderScenarioTable() {
    if (checkScenarioTableRenderStatus() === 1) {
      return noScenarioSelected;
    }
  }

  const noScenarioSelected = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <NoQuizImage />
      <TypographyNew
        type="body4_rg"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
      >
        {t("program.msg.noScenarioSelected")}
      </TypographyNew>
    </BoxNew>
  );
  const noQuizSelected = (
    <BoxNew
      width={"100%"}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <NoQuizImage />
      <TypographyNew
        type="body4_rg"
        exactColor={getColor("COLOR_TEXT_SECONDARY")}
      >
        {t("quiz.msg.choiceQuiz")}
      </TypographyNew>
    </BoxNew>
  );

  const [buttonClick, setButtonClick] = useState(0);

  const { navigateState } = useNavigateState(
    NavigateStateKeys.fromScenarioCreate
  );
  // 훈련 설정 body
  const infoBody = (
    <BoxNew direction="column" classes="mg mt-8 ml-10 mr-10 pd pt-28 pb-28">
      {/* training name start */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.programName")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew direction="column">
          <InputField
            placeholder={t("program.msg.programUndefinedNameMsg")}
            size={"md"}
            width={598}
            disabled={isStarted}
            maxLength={128}
            value={programInfo.configName ?? ""}
            onChange={(e) =>
              setProgramInfo((prev) => ({
                ...prev,
                configName: e.target.value,
              }))
            }
          />
        </BoxNew>
      </BoxNew>
      {/* training name end */}

      {/* trianing period start */}
      <BoxNew classes="mg mb-24">
        <BoxNew width={220} classes="mg mr-28">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.trainingDuration")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew direction="column" classes="mg">
          <BoxNew alignItems="center" classes="mg mb-12">
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("common.info.startDate")}
            </TypographyNew>
            <BoxNew classes="mg ml-16">
              <CalendarNew
                type="single"
                onSubmit={(date) => {
                  setStartDate((prevStartDate) => {
                    if (!(date instanceof Date)) return prevStartDate;
                    const newDate = new Date(date);
                    if (prevStartDate) {
                      newDate.setHours(prevStartDate.getHours());
                      newDate.setMinutes(prevStartDate.getMinutes());
                      newDate.setSeconds(0);
                      newDate.setMilliseconds(0);
                    }
                    return newDate;
                  });
                }}
                width={215}
                minDate={new Date(dayjs().subtract(1, "D").valueOf())}
                maxDate={endDate ? endDate : undefined}
                placeholder={t("quiz.label.selectDate")}
                selected={startDate ? startDate : undefined}
                classes={classNames({ "no-pointer": isStarted })}
              />
              <TimePicker
                key={configId && startDate + "manageTrainCalendarStart"}
                minOffset={1}
                classes="mg ml-16"
                width={215}
                onSubmit={(time) => {
                  const { hour, min } = time;
                  updateSendDate("start", hour, min);
                }}
                disable={isAllDayDate || isStarted}
                initialSelected={
                  configId
                    ? {
                        hour:
                          dayjs(startDate).get("hour") === 0
                            ? 12
                            : dayjs(startDate).get("hour"),
                        min: dayjs(startDate).get("minute"),
                      }
                    : undefined
                }
              />
            </BoxNew>
            <CheckboxNew
              check={isAllDayDate}
              onClick={() => setIsAllDayDate((prev) => !prev)}
              disable={isStarted}
              label={t("program.programAllDay")}
              classes="mg ml-20"
            />
          </BoxNew>
          <BoxNew alignItems="center">
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("common.info.endDate")}
            </TypographyNew>
            <BoxNew classes="mg ml-16">
              <CalendarNew
                type="single"
                onSubmit={(date) => {
                  setEndDate((prevEndDate) => {
                    if (!(date instanceof Date)) return prevEndDate;
                    const newDate = new Date(date);
                    if (prevEndDate) {
                      newDate.setHours(prevEndDate.getHours());
                      newDate.setMinutes(prevEndDate.getMinutes());
                      newDate.setSeconds(0);
                      newDate.setMilliseconds(0);
                    }
                    return newDate;
                  });
                }}
                placeholder={t("quiz.label.selectDate")}
                width={215}
                minDate={
                  startDate ?? new Date(dayjs().subtract(1, "D").valueOf())
                }
                selected={endDate ? endDate : undefined}
                classes={classNames({ "no-pointer": isStarted })}
              />
              <TimePicker
                key={configId && endDate + "manageTrainCalendarEnd"}
                minOffset={1}
                classes="mg ml-16"
                width={215}
                onSubmit={(time) => {
                  const { hour, min } = time;
                  updateSendDate("end", hour, min);
                }}
                initialSelected={
                  configId
                    ? {
                        hour:
                          dayjs(endDate).get("hour") === 0
                            ? 12
                            : dayjs(endDate).get("hour"),
                        min: dayjs(endDate).get("minute"),
                      }
                    : undefined
                }
                disable={isAllDayDate || isStarted}
              />
            </BoxNew>
          </BoxNew>
        </BoxNew>
      </BoxNew>
      {/* trining period end */}

      <BoxNew classes="mg mb-24 mr-24">
        <BoxNew width={220} classes="mg mr-28" alignItems="center">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.sendingMailTime")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew alignItems="center">
          <BoxNew>
            <TimePicker
              key={
                configId &&
                dayjs(startTime).get("hour") + "sendingMailTimeStart"
              }
              width={240}
              onSubmit={(time) => {
                const { hour, min } = time;
                setStartTime(`${hour}${min}`);
              }}
              initialSelected={
                startTime
                  ? {
                      hour: parseInt(startTime.slice(0, 2)) || 0,
                      min: parseInt(startTime.slice(2)) || 0,
                    }
                  : undefined
              }
              placeholder={
                startTime === "" ? t("program.selectStartTime") : undefined
              }
              disable={isAllDayTime}
            />
            <BoxNew alignItems="center" classes="mg ml-8 mr-8">
              <TypographyNew>-</TypographyNew>
            </BoxNew>
            <TimePicker
              key={
                configId && dayjs(endTime).get("hour") + "sendingMailTimeEnd"
              }
              width={240}
              onSubmit={(time) => {
                const { hour, min } = time;
                setEndTime(`${hour}${min}`);
              }}
              initialSelected={
                endTime
                  ? {
                      hour: parseInt(endTime.slice(0, 2)) || 0,
                      min: parseInt(endTime.slice(2)) || 0,
                    }
                  : undefined
              }
              placeholder={
                startTime === "" ? t("program.selectEndTime") : undefined
              }
              disable={isAllDayTime}
            />
          </BoxNew>
          <CheckboxNew
            check={isAllDayTime}
            onClick={() => setIsAllDayTime((prev) => !prev)}
            label={t("program.programAllDay")}
            classes="mg ml-20"
          />
        </BoxNew>
      </BoxNew>

      {/* send mail count start  */}
      <BoxNew classes="mg mb-24">
        <BoxNew width={220} direction="column" classes="mg mr-28">
          <BoxNew classes="mg mb-4">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("program.sendMailCnt")}
            </TypographyNew>
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_ERROR")}
            >
              &nbsp;*
            </TypographyNew>
          </BoxNew>
          <TypographyNew
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.cntInfo")}
          </TypographyNew>
        </BoxNew>

        <BoxNew direction="column">
          <BoxNew alignItems="center">
            <InputField
              size={"md"}
              maxLength={2}
              type="number"
              placeholder={""}
              width={80}
              value={programInfo.sendMailCount?.toString()}
              onChange={(e) =>
                setProgramInfo((prev) => ({
                  ...prev,
                  sendMailCount: parseInt(e.target.value),
                }))
              }
              disabled={isStarted}
              isError={!toShowMailCntError}
              classes="mg mr-8"
            />
            <TypographyNew
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("program.programDataUnit")}
            </TypographyNew>
          </BoxNew>
          {!toShowMailCntError && (
            <BoxNew classes="mg mt-4" alignItems="center">
              <IconsNew
                variant="line"
                label="errorCircle"
                size={16}
                stroke={getColor("COLOR_ICON_ERROR")}
              />
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_ERROR")}
                classes="mg ml-4"
              >
                {t("program.msg.cntInfoMsg")}
              </TypographyNew>
            </BoxNew>
          )}
        </BoxNew>
      </BoxNew>
      {/* send mail count end  */}

      {/* collection period */}
      <BoxNew classes="mg mb-28">
        <BoxNew width={220} classes="mg mr-28" alignItems="center">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.collectionPeriod")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew alignItems="center">
          <Dropdown
            btnSize="lg"
            btnStyle="line"
            dropdownMaxHeight={224}
            disabled={isStarted}
            placeholder={programInfo.collectionPeriod?.toString()}
            width={80}
            value={
              programInfo.collectionPeriod !== undefined
                ? programInfo.collectionPeriod + ""
                : "7"
            }
            buttonClick={buttonClick}
            classes="mg mr-8"
          >
            {Array(8)
              .fill("")
              .map((item, index) => (
                <DropdownItem
                  key={`dropdownCollectionPeriod-${index}`}
                  label={index}
                  onClick={() => {
                    setProgramInfo((prev) => ({
                      ...prev,
                      collectionPeriod: index,
                    }));
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                  checked={programInfo.collectionPeriod === index}
                  checkColor={programInfo.collectionPeriod === index}
                />
              ))}
          </Dropdown>
          <TypographyNew
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {/* 리소스  */}일
          </TypographyNew>
        </BoxNew>
      </BoxNew>
      {/* collection period  end*/}

      <BoxNew classes="mg mb-28">
        <BoxNew width={220} classes="mg mr-28" alignItems="center">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.type")}
          </TypographyNew>
        </BoxNew>
        <BoxNew classes="gap-24">
          <RadioNew
            label={t("program.mockTrainingLabel")}
            check={!programInfo.realTrainingFlag}
            id="모의"
            onClick={() =>
              setProgramInfo((prev) => ({
                ...prev,
                realTrainingFlag: false,
              }))
            }
            disable={isStarted}
          />
          <RadioNew
            label={t("program.realTrainingLabel")}
            check={programInfo.realTrainingFlag}
            id="실전"
            disable={isStarted}
            onClick={() =>
              setProgramInfo((prev) => ({ ...prev, realTrainingFlag: true }))
            }
          />
        </BoxNew>
      </BoxNew>

      <BoxNew classes="mg mb-28">
        <BoxNew width={220} classes="mg mr-28" alignItems="center">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.setActivateFlag")}
          </TypographyNew>
        </BoxNew>
        <ToggleNew
          checked={programInfo.activateFlag}
          onChange={() =>
            setProgramInfo((prev) => ({
              ...prev,
              activateFlag: !prev.activateFlag,
            }))
          }
          label={programInfo.activateFlag ? "ON" : "OFF"}
        />
      </BoxNew>

      <BoxNew classes="mg mb-28">
        <BoxNew width={220} classes="mg mr-28" alignItems="center">
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("reply.isActivateAutoReply")}
          </TypographyNew>
        </BoxNew>

        <ToggleNew
          checked={
            !programInfo.activateFlag ? false : programInfo.autoReplyFlag
          }
          onChange={() =>
            setProgramInfo((prev) => ({
              ...prev,
              autoReplyFlag: !prev.autoReplyFlag,
            }))
          }
          disabled={!programInfo.activateFlag}
          label={programInfo.autoReplyFlag ? "ON" : "OFF"}
        />
      </BoxNew>
    </BoxNew>
  );

  // 대상자 선택 body
  const userBody = (
    <BoxNew classes="gap-32 mg mt-28 pd pl-8 pr-8 pb-28">
      <BoxNew width={220} classes="mg mr-28">
        <TypographyNew
          type="subtitle2"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          {t("quiz.label.user")}
        </TypographyNew>
      </BoxNew>
      <BoxNew direction="column">
        <BoxNew>
          <InputField
            size="md"
            placeholder={t("account.msg.userUndefinedIdEmailMsg")}
            width={"100%"}
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            classes="mg mr-8"
          />
          <ButtonNew
            btnStyle="fill"
            type="secondary"
            leadingIcon={<IconsNew variant="line" label="plusCircle" />}
            state={isStarted ? "disable" : "default"}
            onClick={() => setIsUserModalOpen(true)}
            classes="quiz__select__button"
            label={t("quiz.label.selectOrgChart")}
            width={i18n.language === "ko" ? 180 : 280}
          />
        </BoxNew>
        <BoxNew classes="mg mt-8">
          <BoxNew
            classes="selected__user__container"
            alignItems="center"
            direction="column"
          >
            {(selectedDeptInfo && selectedDeptInfo.length > 0) ||
            (selectedUserInfo && selectedUserInfo.length > 0) ? (
              <BoxNew
                direction="column"
                classes="selected__user__container-card"
              >
                {selectedDeptInfo &&
                  selectedDeptInfo.length > 0 &&
                  selectedDeptInfo.map(
                    (i) =>
                      (searchWord === "" ||
                        i.deptName.includes(searchWord)) && (
                        <OrganCard
                          userName={i.deptName}
                          userId={i.deptCode}
                          type="DEPT"
                          deleteEvent={() => {
                            deptCheckboxGroup.handleClick(i.deptCode + "");
                          }}
                        />
                      )
                  )}
                {selectedUserInfo &&
                  selectedUserInfo.length > 0 &&
                  selectedUserInfo.map(
                    (i) =>
                      (searchWord === "" ||
                        i.userName.includes(searchWord) ||
                        i.userEmail.includes(searchWord)) && (
                        <OrganCard
                          userId={i.userId ?? undefined}
                          userName={i.userName}
                          jobTitle={i.jobTitle ?? undefined}
                          deptName={i.deptName}
                          userEmail={i.userEmail}
                          deleteEvent={() => {
                            userCheckboxGroup.handleClick(i.userId + "");
                          }}
                        />
                      )
                  )}
              </BoxNew>
            ) : (
              <BoxNew width={"100%"} alignItems="center" direction="column">
                <BoxNew classes="mg mt-24">
                  <NoSelectedUserImage />
                </BoxNew>
                <BoxNew>
                  <TypographyNew
                    type="body3_rg"
                    exactColor={getColor("COLOR_TEXT_SECONDARY")}
                  >
                    {t("quiz.msg.addTargetOrgChart")}
                  </TypographyNew>
                </BoxNew>
              </BoxNew>
            )}
          </BoxNew>
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );

  // 훈련 시나리오 선택
  const templateSelectBody = (
    <BoxNew classes="mg ml-8 mt-40" direction="column">
      <BoxNew classes="mg mb-28">
        <BoxNew width={220} direction="column" classes="mg mr-28">
          <BoxNew classes="mg mb-4">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("category.selectCategory")}
            </TypographyNew>
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_ERROR")}
            >
              &nbsp;*
            </TypographyNew>
          </BoxNew>
          <TypographyNew
            type="body3_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.msg.scenarioCntInfo")}
          </TypographyNew>
        </BoxNew>
        <BoxNew alignItems="center" direction="column" width={590}>
          <BoxNew width={"100%"} justifyContent="space-between">
            <BoxNew alignItems="center" justifyContent="flex-start">
              <ButtonNew
                size="lg"
                btnStyle="fill"
                type="secondary"
                leadingIcon={
                  <IconsNew
                    variant="line"
                    label="plusCircle"
                    stroke={getColor("COLOR_TEXT_PRIMARY")}
                  />
                }
                label={t("program.selectScenario")}
                onClick={() => setIsScenarioModalOpen(true)}
                classes="quiz__select__button"
              />
              <BoxNew classes="mg ml-12">
                <TypographyNew exactColor={getColor("COLOR_TEXT_PRIMARY")}>
                  {t("quiz.label.added")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="mg ml-4">
                <TypographyNew
                  type="body2_rg"
                  exactColor={
                    selectedScenarioList.length === 0
                      ? getColor("COLOR_TEXT_ERROR")
                      : getColor("COLOR_TEXT_BRAND")
                  }
                >
                  {selectedScenarioList.length}
                </TypographyNew>
                <BoxNew classes="mg mr-4"></BoxNew>
                <TypographyNew type="body2_rg">
                  {quizFlag &&
                    `/ ${
                      (programInfo.sendMailCount ?? 0) *
                      (quizInfo.quizCount ?? 0)
                    }`}
                </TypographyNew>
              </BoxNew>
            </BoxNew>
            <ButtonNew
              btnStyle={"link"}
              size={"lg"}
              classes="mg mr-4"
              state={
                scenarioCheckboxGroup.selected.length > 0
                  ? "default"
                  : "disable"
              }
              leadingIcon={
                <IconsNew
                  variant="line"
                  label="delete"
                  stroke={getColor("COLOR_ICON_PRIMARY")}
                />
              }
              onClick={() => {
                // eslint-disable-next-line no-lone-blocks
                {
                  setSelectedScenarioList((scenarioList) =>
                    scenarioList.filter(
                      (s) =>
                        !scenarioCheckboxGroup.selected.includes(s.templateId)
                    )
                  );
                  scenarioCheckboxGroup.handleAllClick([]);
                }
              }}
              label={t("common.button.delete")}
            />
          </BoxNew>
          {/* 시나리오 테이블  */}
          <TableNew
            border="none"
            clickIds={scenarioCheckboxGroup.selected.map((templateId) =>
              String(templateId)
            )}
            highlightIds={navigateState?.length ? navigateState : undefined}
            classes="mg mt-12 table-chip-hover"
            columnHeight="md"
            data={transformScenarioList(selectedScenarioList)}
            endReached={() => {}}
            checkBoxClick={() =>
              scenarioCheckboxGroup.handleAllClick(
                scenarioCheckboxGroup.selected.length ===
                  selectedScenarioList.length
                  ? []
                  : selectedScenarioList.map((q) => q.templateId)
              )
            }
            header={[
              {
                headerText: "",
                width: "12px",
              },
              {
                headerText: t("quiz.table.type"),
                width: "72px",
              },
              {
                headerText: t("quiz.table.quizName"),
                width: "180px",
              },
              {
                headerText: t("quiz.table.category"),
                width: "302px",
              },
            ]}
            width={"100%"}
            height={`${
              (selectedScenarioList.length + 1) * 50 > 248
                ? 248
                : (selectedScenarioList.length + 1) * 50
            }px`}
          />
          {renderScenarioTable()}
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );

  const templateBody = (
    <BoxNew classes="mg ml-8 mt-40" direction="column">
      <BoxNew classes="mg mb-28">
        <BoxNew width={220} direction="column" classes="mg mr-28">
          <BoxNew classes="mg mb-4">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("category.selectCategory")}
            </TypographyNew>
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_ERROR")}
            >
              &nbsp;*
            </TypographyNew>
          </BoxNew>
          <TypographyNew
            type="body3_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("program.msg.scenarioCntInfo")}
          </TypographyNew>
        </BoxNew>
        <BoxNew alignItems="center" direction="column" width={590}>
          <BoxNew width={"100%"} justifyContent="space-between">
            <BoxNew alignItems="center" justifyContent="flex-start">
              <ButtonNew
                size="lg"
                btnStyle="fill"
                type="secondary"
                state={"disable"}
                leadingIcon={
                  <IconsNew
                    variant="line"
                    label="plusCircle"
                    stroke={getColor("COLOR_TEXT_PRIMARY")}
                  />
                }
                label={t("program.selectScenario")}
                classes="quiz__select__button"
              />
              <BoxNew classes="mg ml-12">
                <TypographyNew exactColor={getColor("COLOR_TEXT_PRIMARY")}>
                  {t("quiz.label.added")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="mg ml-4">
                <TypographyNew
                  type="body2_rg"
                  exactColor={
                    selectedScenarioList.length === 0
                      ? getColor("COLOR_TEXT_ERROR")
                      : getColor("COLOR_TEXT_BRAND")
                  }
                >
                  {selectedScenarioList.length}
                </TypographyNew>
                <BoxNew classes="mg mr-4"></BoxNew>
                <TypographyNew type="body2_rg">
                  {quizFlag &&
                    `/ ${
                      (programInfo.sendMailCount ?? 0) *
                      (quizInfo.quizCount ?? 0)
                    }`}
                </TypographyNew>
              </BoxNew>
            </BoxNew>
          </BoxNew>
          {/* 시나리오 테이블  */}
          <TableNew
            border="none"
            classes="mg mt-12 table-chip-hover"
            columnHeight="md"
            data={transformScenarioList(selectedScenarioList)}
            endReached={() => {}}
            header={[
              {
                headerText: "",
                width: "12px",
              },
              {
                headerText: t("quiz.table.type"),
                width: "72px",
              },
              {
                headerText: t("quiz.table.quizName"),
                width: "180px",
              },
              {
                headerText: t("quiz.table.category"),
                width: "302px",
              },
            ]}
            width={"100%"}
            height={`${
              (selectedScenarioList.length + 1) * 50 > 248
                ? 248
                : (selectedScenarioList.length + 1) * 50
            }px`}
          />
          {renderScenarioTable()}
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );

  const quizSelectBody = (
    <BoxNew classes="mg ml-8 mt-12" direction="column">
      <BoxNew classes="mg mb-28">
        <TypographyNew
          type="body3_rg"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          {t("quiz.msg.trainingQuizInfo")}
        </TypographyNew>
      </BoxNew>
      <BoxNew classes="mg mb-28">
        <BoxNew width={220} direction="column" classes="mg mr-28">
          <BoxNew classes="mg mb-4">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.label.intergrationQuiz")}
            </TypographyNew>
          </BoxNew>
          <TypographyNew
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("quiz.msg.trainingQuizFlagInfo")}
          </TypographyNew>
        </BoxNew>
        <BoxNew>
          <ToggleNew
            label={quizFlag ? "ON" : "OFF"}
            onChange={() => setQuizFlag((prev) => !prev)}
            checked={quizFlag}
          />
        </BoxNew>
      </BoxNew>
      <BoxNew classes="mg mb-28">
        <BoxNew width={220} direction="column" classes="mg mr-28">
          <BoxNew classes="mg mb-4">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.label.trainingQuizType")}
            </TypographyNew>
          </BoxNew>
        </BoxNew>
        <BoxNew classes="gap-24">
          <RadioNew
            label={t("quiz.label.trainingClick")}
            id="1"
            check={quizInfo.quizType === 1}
            onClick={(id: string) => {
              setQuizInfo((prev) => ({
                ...prev,
                quizType: parseInt(id),
              }));
            }}
            disable={!quizFlag}
          />
          <RadioNew
            label={t("quiz.label.trainingEnd")}
            id="2"
            check={quizInfo.quizType === 2}
            onClick={(id: string) => {
              setQuizInfo((prev) => ({
                ...prev,
                quizType: parseInt(id),
              }));
            }}
            disable={!quizFlag}
          />
        </BoxNew>
      </BoxNew>
      <BoxNew classes="mg mb-40">
        <BoxNew width={220} direction="column" classes="mg mr-28">
          <BoxNew classes="mg mb-4">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.label.trainingQuizCount")}
            </TypographyNew>
          </BoxNew>
          <TypographyNew
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("quiz.msg.trainingQuizCount")}
          </TypographyNew>
        </BoxNew>
        <BoxNew alignItems="center">
          <InputField
            disabled={!quizFlag}
            width={66}
            size={"md"}
            placeholder={""}
            type="positive-integer"
            classes="mg mr-8"
            value={quizInfo.quizCount ? quizInfo.quizCount.toString() : ""}
            onChange={(e) => {
              if (
                !e.target.value ||
                (parseInt(e.target.value) >= 0 &&
                  parseInt(e.target.value) <= 10)
              ) {
                setQuizInfo((prev) => ({
                  ...prev,
                  quizCount: parseInt(e.target.value ? e.target.value : "0"),
                }));
              }
            }}
          />
          {t("quiz.label.ea1")}
        </BoxNew>
      </BoxNew>
      <BoxNew classes="mg mb-28">
        <BoxNew width={220} direction="column" classes="mg mr-28">
          <BoxNew classes="mg mb-4">
            <TypographyNew
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.label.quizQuestion")}
            </TypographyNew>
          </BoxNew>
          <TypographyNew
            type="body3_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("quiz.msg.trainingQuizSelectInfo")}
          </TypographyNew>
        </BoxNew>

        <BoxNew alignItems="center" direction="column" width={590}>
          <BoxNew width={"100%"} justifyContent="space-between">
            <BoxNew alignItems="center" justifyContent="flex-start">
              <ButtonNew
                size="lg"
                btnStyle="fill"
                type="secondary"
                leadingIcon={
                  <IconsNew
                    variant="line"
                    label="plusCircle"
                    stroke={getColor("COLOR_TEXT_PRIMARY")}
                  />
                }
                state={!quizFlag ? "disable" : "default"}
                label={t("quiz.label.choiceQuiz")}
                onClick={() => setIsQuizModalOpen(true)}
                classes="quiz__select__button"
              />
              <BoxNew classes="mg ml-12">
                <TypographyNew
                  exactColor={
                    quizFlag
                      ? getColor("COLOR_TEXT_PRIMARY")
                      : getColor("COLOR_TEXT_SECONDARY")
                  }
                >
                  {t("quiz.label.added")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="mg ml-4">
                <TypographyNew
                  type="body2_rg"
                  exactColor={
                    !quizFlag
                      ? getColor("COLOR_TEXT_SECONDARY")
                      : programInfo.sendMailCount &&
                        selectedQuizList.length <
                          programInfo.sendMailCount * quizInfo.quizCount
                      ? getColor("COLOR_TEXT_ERROR")
                      : getColor("COLOR_TEXT_BRAND")
                  }
                >
                  {selectedQuizList.length}
                </TypographyNew>
                <BoxNew classes="mg mr-4"></BoxNew>
                <TypographyNew type="body2_rg">
                  {quizFlag &&
                    `/ ${
                      (programInfo.sendMailCount ?? 0) *
                      (quizInfo.quizCount ?? 0)
                    }`}
                </TypographyNew>
              </BoxNew>
            </BoxNew>
            <ButtonNew
              btnStyle={"link"}
              size={"lg"}
              classes="mg mr-4"
              state={
                quizCheckboxGroup.selected.length > 0 ? "default" : "disable"
              }
              leadingIcon={
                <IconsNew
                  variant="line"
                  label="delete"
                  stroke={getColor("COLOR_ICON_PRIMARY")}
                />
              }
              onClick={() => {
                setSelectedQuizList((quizList) =>
                  quizList.filter(
                    (q) => !quizCheckboxGroup.selected.includes(q.quizId)
                  )
                );
                quizCheckboxGroup.handleAllClick([]);
              }}
              label={t("common.button.delete")}
            />
          </BoxNew>
          {/* 학습 연계 테이블  */}
          <TableNew
            border="none"
            clickIds={quizCheckboxGroup.selected.map((quizId) =>
              String(quizId)
            )}
            classes="mg mt-12 table-chip-hover"
            columnHeight="md"
            data={transformQuizList(selectedQuizList)}
            endReached={() => {}}
            checkBoxClick={() =>
              quizCheckboxGroup.handleAllClick(
                quizCheckboxGroup.selected.length === selectedQuizList.length
                  ? []
                  : selectedQuizList.map((q) => q.quizId)
              )
            }
            header={[
              {
                headerText: "",
                width: "12px",
              },
              {
                headerText: t("quiz.table.type"),
                width: "72px",
              },
              {
                headerText: t("quiz.table.quizName"),
                width: "142px",
              },
              {
                headerText: t("quiz.table.category"),
                width: "280px",
              },
              {
                headerText: t("quiz.table.formal"),
                width: "80px",
              },
            ]}
            width={"100%"}
            height={`${
              (selectedQuizList.length + 1) * 50 > 300
                ? 300
                : (selectedQuizList.length + 1) * 50
            }px`}
          />
          {renderTableSwitch()}
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );

  const quizBody = () => {
    return (
      <BoxNew classes="mg ml-8 pd pt-28 pb-28" direction="column">
        <BoxNew classes="mg mb-28">
          <BoxNew width={220} direction="column" classes="mg mr-28">
            <BoxNew classes="mg mb-4">
              <TypographyNew
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.intergrationQuiz")}
              </TypographyNew>
            </BoxNew>
            <TypographyNew
              type="body4_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.msg.trainingQuizFlagInfo")}
            </TypographyNew>
          </BoxNew>
          <BoxNew>
            <ToggleNew
              label={quizFlag ? "ON" : "OFF"}
              onChange={() => setQuizFlag((prev) => !prev)}
              checked={quizFlag}
              disabled
            />
          </BoxNew>
        </BoxNew>
        <BoxNew classes="mg mb-28">
          <BoxNew width={220} direction="column" classes="mg mr-28">
            <BoxNew classes="mg mb-4">
              <TypographyNew
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.trainingQuizType")}
              </TypographyNew>
            </BoxNew>
          </BoxNew>
          <BoxNew classes="gap-24">
            <RadioNew
              label={t("quiz.label.trainingClick")}
              id="1"
              check={quizInfo.quizType === 1}
              onClick={(id: string) => {
                setQuizInfo((prev) => ({
                  ...prev,
                  quizType: parseInt(id),
                }));
              }}
              disable={!quizFlag || isStarted}
            />
            <RadioNew
              label={t("quiz.label.trainingEnd")}
              id="2"
              check={quizInfo.quizType === 2}
              onClick={(id: string) => {
                setQuizInfo((prev) => ({
                  ...prev,
                  quizType: parseInt(id),
                }));
              }}
              disable={!quizFlag || isStarted}
            />
          </BoxNew>
        </BoxNew>
        <BoxNew classes="mg mb-40">
          <BoxNew width={220} direction="column" classes="mg mr-28">
            <BoxNew classes="mg mb-4">
              <TypographyNew
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.trainingQuizCount")}
              </TypographyNew>
            </BoxNew>
            <TypographyNew
              type="body4_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.msg.trainingQuizCount")}
            </TypographyNew>
          </BoxNew>
          <BoxNew alignItems="center">
            {quizInfo.quizCount} {t("quiz.label.ea1")}
          </BoxNew>
        </BoxNew>
        <BoxNew classes="mg mb-28">
          <BoxNew width={220} direction="column" classes="mg mr-28">
            <BoxNew classes="mg mb-4">
              <TypographyNew
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.label.quizQuestion")}
              </TypographyNew>
            </BoxNew>
            <TypographyNew
              type="body4_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {t("quiz.msg.trainingQuizSelectInfo")}
            </TypographyNew>
          </BoxNew>
          <BoxNew alignItems="center" direction="column" width={590}>
            <BoxNew
              alignItems="center"
              justifyContent="flex-start"
              width={"100%"}
            >
              <ButtonNew
                btnStyle="fill"
                type="secondary"
                classes="quiz__select__button"
                size="lg"
                leadingIcon={
                  <IconsNew
                    variant="line"
                    label="plusCircle"
                    stroke={getColor("COLOR_ICON_INTERACTIVE_PRIMARY_DISABLED")}
                  />
                }
                state="disable"
                label={t("quiz.label.choiceQuiz")}
                onClick={() => setIsQuizModalOpen(true)}
              />
              <BoxNew classes="mg ml-12">
                <TypographyNew
                  type="body2_rg"
                  exactColor={
                    quizFlag
                      ? getColor("COLOR_TEXT_PRIMARY")
                      : getColor("COLOR_TEXT_SECONDARY")
                  }
                >
                  {t("quiz.label.added")}
                </TypographyNew>
              </BoxNew>
              <BoxNew classes="mg ml-4">
                <TypographyNew
                  type="body2_rg"
                  exactColor={
                    !quizFlag
                      ? getColor("COLOR_TEXT_SECONDARY")
                      : programInfo.sendMailCount &&
                        selectedQuizList.length <
                          programInfo.sendMailCount * quizInfo.quizCount
                      ? getColor("COLOR_TEXT_ERROR")
                      : getColor("COLOR_TEXT_BRAND")
                  }
                >
                  {selectedQuizList.length}
                </TypographyNew>
                <BoxNew classes="mg mr-4"></BoxNew>
                <TypographyNew type="body2_rg">
                  {quizFlag &&
                    `/ ${
                      (programInfo.sendMailCount ?? 0) *
                      (quizInfo.quizCount ?? 0)
                    }`}
                </TypographyNew>
              </BoxNew>
            </BoxNew>
            {/* 학습 연계 테이블 */}
            <TableNew
              border="none"
              classes="mg mt-12 table-chip-hover"
              columnHeight="md"
              data={transformQuizList(selectedQuizList)}
              endReached={() => {}}
              header={[
                {
                  headerText: "",
                  width: "12px",
                },
                {
                  headerText: t("quiz.table.type"),
                  width: "72px",
                },
                {
                  headerText: t("quiz.table.quizName"),
                  width: "142px",
                },
                {
                  headerText: t("quiz.table.category"),
                  width: "280px",
                },
                {
                  headerText: t("quiz.table.formal"),
                  width: "80px",
                },
              ]}
              width={"100%"}
              height={`${
                (selectedQuizList.length + 1) * 50 > 300
                  ? 300
                  : (selectedQuizList.length + 1) * 50
              }px`}
            />
            {renderTableSwitch()}
          </BoxNew>
        </BoxNew>
      </BoxNew>
    );
  };

  useEffect(() => {
    if (configId && programState?.configDetail) {
      setProgramInfo({
        ...programState?.configDetail?.trainingInfo,
      });
      changeIsStared(
        programState?.configDetail?.trainingInfo?.sendStartDate
          ? !!configId &&
              dayjs(
                programState?.configDetail?.trainingInfo?.sendStartDate +
                  "+00:00"
              ).valueOf() < dayjs().valueOf()
          : false
      );
      changeSelctedUserListInDetail(programState?.configDetail?.userList ?? []);

      userCheckboxGroup.handleAllClick(
        programState?.configDetail?.trainingInfo.userIds ?? []
      );
      deptCheckboxGroup.handleAllClick(
        programState?.configDetail?.trainingInfo.deptIds ?? []
      );
      //  initializeUserCheckbox();
      setStartDate(
        new Date(
          programState?.configDetail?.trainingInfo.sendStartDate + "+00:00"
        )
      );

      setEndDate(
        new Date(
          programState?.configDetail?.trainingInfo.sendEndDate + "+00:00"
        )
      );

      //훈련 기간 종일 여부
      const endDateKST = new Date(
        programState?.configDetail?.trainingInfo.sendEndDate + "+00:00"
      );
      if (
        endDateKST.getHours() === 23 &&
        endDateKST.getMinutes() === 59 &&
        endDateKST.getSeconds() === 59
      ) {
        setIsAllDayDate(true);
      } else {
        setIsAllDayDate(false);
        setStartDateTime(
          formatTime(programState?.configDetail?.trainingInfo.sendStartDate)
        );
        setEndDateTime(
          formatTime(programState?.configDetail?.trainingInfo.sendEndDate)
        );
      }

      // 발송 시간 있는 경우
      if (programState?.configDetail?.trainingInfo.sendStartTime) {
        setStartTime(
          adjustTime(
            programState?.configDetail?.trainingInfo.sendStartTime,
            9
          ).replace(":", "")
        );

        setEndTime(
          adjustTime(
            programState?.configDetail?.trainingInfo.sendEndTime,
            9
          ).replace(":", "")
        );
        setIsAllDayTime(false);
      } else {
        setIsAllDayTime(true);
      }

      if (programState?.configDetail.trainingInfo.quizType) {
        setQuizFlag(true);
        setQuizInfo({
          quizCount: programState?.configDetail.trainingInfo.quizCount,
          quizIds: programState?.configDetail.trainingInfo.quizIds,
          quizType: programState?.configDetail.trainingInfo.quizType,
          quizSolvePeriod:
            programState?.configDetail.trainingInfo.quizSolvePeriod,
        });
        getQuizContentList({});
      }
    }
  }, [programState.configDetail]);

  useEffect(() => {
    if (configId) {
      if (
        quizState.quizContentList &&
        quizState.quizContentList.list.length > 0 &&
        programState?.configDetail?.trainingInfo?.quizIds
      ) {
        setSelectedQuizList(
          quizState.quizContentList.list.filter((t: QuizContentDTO) =>
            programState?.configDetail?.trainingInfo?.quizIds.some(
              (q: string) => parseInt(q) === t.quizId
            )
          )
        );
      } else {
        getMailTemplateList({});
      }
    }
  }, [quizState.quizContentList]);

  useEffect(() => {
    if (configId) {
      if (
        mailTemplateState.mailTemplateList &&
        mailTemplateState.mailTemplateList.length > 0 &&
        programState?.configDetail?.trainingInfo?.templateIds
      ) {
        setSelectedScenarioList(
          mailTemplateState.mailTemplateList.filter((t: Scenario) =>
            programState?.configDetail?.trainingInfo?.templateIds.some(
              (q: string) => parseInt(q) === t.templateId
            )
          )
        );
      }
    }
  }, [mailTemplateState.mailTemplateList]);

  useEffect(() => {
    getDeptList();
    getCategories();
    getMailTemplateList({});

    if (configId) {
      getProgramDetail(configId);
    }
  }, [configId]);

  useEffect(() => {
    const tempConfigId = searchParams.get("config_id");
    if (tempConfigId) {
      setConfigId(parseInt(tempConfigId));
    }
    setProgramInfo({ collectionPeriod: 7 });
    setStartDate(null);
    setEndDate(null);
    setStartDateTime(null);
    setEndDateTime(null);
    setStartTime("");
    setEndTime("");
    userCheckboxGroup.handleAllClick([]);
    deptCheckboxGroup.handleAllClick([]);
    checkboxCateGroup.handleAllClick([]);
    scenarioCheckboxGroup.handleAllClick([]);
  }, []);

  useEffect(() => {
    changeOrganTree(deptState.organList);
  }, [deptState.organList]);

  const getOrganInfo = async () => {
    const resp: any = await getSelectedOrgan({
      userIds: userCheckboxGroup.selected,
      deptCodes: deptCheckboxGroup.selected,
    });

    setSelectedDeptInfo(resp.payload.data.deptList ?? []);
    setSelectedUserInfo(resp.payload.data.userList ?? []);
  };

  useEffect(() => {
    if (
      userCheckboxGroup.selected.length > 0 ||
      deptCheckboxGroup.selected.length > 0
    ) {
      getOrganInfo();
    } else {
      setSelectedDeptInfo([]);
      setSelectedUserInfo([]);
    }
  }, [userCheckboxGroup.selected, deptCheckboxGroup.selected]);

  function isUserSelected() {
    return (
      userCheckboxGroup.selected.length + deptCheckboxGroup.selected.length > 0
    );
  }

  function isScenarioSelected() {
    return selectedScenarioList.length !== 0;
  }

  function isBasicInfoValid() {
    const hasConfigNameValid = !!programInfo.configName;
    const hasSendMail =
      (programInfo.sendMailCount || 0) > 0 &&
      (programInfo.sendMailCount || 0) < 100;
    const hasTrainingRange = startDate && endDate;
    const hasSendingTime = isAllDayTime || !!(startTime && endTime);
    const hasCollectionPeriod = !!programInfo.collectionPeriod;

    return (
      hasConfigNameValid &&
      hasSendMail &&
      hasTrainingRange &&
      hasSendingTime &&
      hasCollectionPeriod
    );
  }

  function isQuizValid() {
    if (quizFlag) {
      return quizInfo.quizCount !== 0 && selectedQuizList.length !== 0;
    }
    return true;
  }

  function isFormValid() {
    return (
      isUserSelected() &&
      isScenarioSelected() &&
      isBasicInfoValid() &&
      isQuizValid()
    );
  }

  const [isEditCancelModalOpen, setIsEditCancelModalOpen] = useState(false);

  const closeEditCancelModal = () => {
    navigate(`${process.env.PUBLIC_URL}/manageTrain/program`);
    setIsEditCancelModalOpen(false);
  };

  const stayOnPage = () => {
    setIsEditCancelModalOpen(false);
  };

  return (
    <BoxNew classes="sticky-layout">
      <BoxNew ref={sentinel}>
        <BoxNew
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          <BoxNew direction="column">
            <TitlePath
              path={[t("menu.trainingManagement"), t("menu.training")]}
            />
            <TypographyNew type="h1" classes="mg mt-8">
              {configId ? t("program.update") : t("menu.createTraining")}
            </TypographyNew>
          </BoxNew>
        </BoxNew>
      </BoxNew>

      <BoxNew classes={"body"}>
        <BoxNew alignItems="center" justifyContent="space-between">
          <AccordionsNew
            label={t("quiz.label.basicInfo")}
            initialOpenStatus={true}
            labelLeftComponent={
              isBasicInfoValid() ? (
                <Tag
                  backgroundColor={getColor("COLOR_BG_SUCCESS_DISABLED")}
                  text={t("common.input.completed")}
                  textColor={getColor("COLOR_TEXT_SUCCESS")}
                />
              ) : (
                <Tag
                  backgroundColor={getColor("COLOR_BG_LOADING_DISABLED")}
                  text={t("common.input.incompleted")}
                  textColor={getColor("COLOR_TEXT_LOADING")}
                />
              )
            }
          >
            {infoBody}
          </AccordionsNew>
        </BoxNew>

        <BoxNew
          alignItems="center"
          justifyContent="space-between"
          width={"100%"}
          classes="mg mt-2"
        >
          <AccordionsNew
            label={t("dashboard.user")}
            initialOpenStatus={true}
            labelLeftComponent={
              isUserSelected() ? (
                <Tag
                  backgroundColor={getColor("COLOR_BG_SUCCESS_DISABLED")}
                  text={t("common.input.completed")}
                  textColor={getColor("COLOR_TEXT_SUCCESS")}
                />
              ) : (
                <Tag
                  backgroundColor={getColor("COLOR_BG_LOADING_DISABLED")}
                  text={t("common.input.incompleted")}
                  textColor={getColor("COLOR_TEXT_LOADING")}
                />
              )
            }
          >
            {userBody}
          </AccordionsNew>
        </BoxNew>

        <BoxNew
          alignItems="center"
          justifyContent="space-between"
          width={"100%"}
          classes="mg mt-2"
        >
          <AccordionsNew
            label={t("menu.template")}
            initialOpenStatus={true}
            labelLeftComponent={
              isScenarioSelected() ? (
                <Tag
                  backgroundColor={getColor("COLOR_BG_SUCCESS_DISABLED")}
                  text={t("common.input.completed")}
                  textColor={getColor("COLOR_TEXT_SUCCESS")}
                />
              ) : (
                <Tag
                  backgroundColor={getColor("COLOR_BG_LOADING_DISABLED")}
                  text={t("common.input.incompleted")}
                  textColor={getColor("COLOR_TEXT_LOADING")}
                />
              )
            }
          >
            {isStarted ? templateBody : templateSelectBody}
          </AccordionsNew>
        </BoxNew>

        <BoxNew
          alignItems="center"
          justifyContent="space-between"
          width={"100%"}
          classes="mg mt-2"
        >
          <AccordionsNew
            label={t("quiz.label.trainingQuizTitle")}
            initialOpenStatus={true}
            labelLeftComponent={
              <Tag
                backgroundColor={getColor("COLOR_BG_INTERACTIVE_PRIMARY")}
                text={t("common.input.optional")}
                textColor={getColor("COLOR_TEXT_SECONDARY")}
              />
            }
          >
            {isStarted ? quizBody() : quizSelectBody}
          </AccordionsNew>
        </BoxNew>
      </BoxNew>

      <BoxNew
        classes={classNames("footer", { showScroll: isHorizontallyScrollable })}
      >
        <ButtonNew
          btnStyle="fill"
          type="secondary"
          onClick={() => setIsEditCancelModalOpen(true)}
          label={t("common.button.cancel")}
          size="lg"
        />

        <ButtonNew
          btnStyle="fill"
          type="primary"
          classes="mg ml-10"
          onClick={onSubmit}
          state={isFormValid() ? "default" : "disable"}
          label={t("common.button.save")}
          size="lg"
        />
      </BoxNew>

      <EditCancelModal
        closeModal={closeEditCancelModal}
        stayOnPage={stayOnPage}
        toShow={isEditCancelModalOpen}
      />

      {isQuizModalOpen && (
        <QuizModal
          isOpen={isQuizModalOpen}
          onClose={() => {
            setIsQuizModalOpen(false);
          }}
          selectedQuizList={selectedQuizList}
          setSelectedQuizList={setSelectedQuizList}
        />
      )}
      {isScenarioModalOpen && (
        <ScenarioModal
          isOpen={isScenarioModalOpen}
          onClose={() => {
            setIsScenarioModalOpen(false);
          }}
          selectedScenarioList={selectedScenarioList}
          setSelectedScenarioList={setSelectedScenarioList}
        />
      )}
      {isUserModalOpen && (
        <UserModal
          isOpen={isUserModalOpen}
          onClose={() => {
            setIsUserModalOpen(false);
          }}
          userCheckboxGroup={userCheckboxGroup}
          deptCheckboxGroup={deptCheckboxGroup}
          organTree={organTree}
        />
      )}
    </BoxNew>
  );
};

export default WriteTraining;
