import "./index.scss";
import {
  Typography as TypographyNew,
  Radio as RadioNew,
  InputArea,
  InputField,
  Button as ButtonNew,
  useToast,
  Box as BoxNew,
  getColor,
  Modal,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { QuizContentDTO } from "../../../types/Quiz";
import { nanoid } from "@reduxjs/toolkit";
import IconMindSat from "../../../shared/image/icon";
import CategoryDropdown from "../../../component/CategoryDropdown";

import "../../../shared/style/stickyLayout.scss";
import EditCancelModal from "../../QuizCourse/Write/EditCancelModal";
import useStickyPage from "../../../hooks/useStickyObserver";
import classNames from "classnames";
import AIPromptModal from "./AIPromptModal";
import { DataStructureUtils } from "../../../shared/utils/DataStructureUtils";
import useNavigateState from "../../../hooks/useNavigateState";
import { NavigateStateKeys } from "../../../hooks/NavigateStateKeys";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";

const WriteQuiz: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    insertQuizContent,
    updateQuizContent,
    getQuizCategory,
    getQuizContentDetail,
    quizState,
  } = useQuizStore();
  const toast = useToast();
  const quizId = searchParams.get("quiz_id");
  const initialQuizContent = {
    quizName: "",
    quizType: 1,
    quizAnswer: 0,
    quizChoices: [],
    quizBody: "",
    quizComments: "",
    categorys: [],
  };

  const [type, changeType] = useState<"CREATE" | "UPDATE">("CREATE");
  const [isAIModalOpen, setIsAIModalOpen] = useState<boolean>(false);
  const [isEditCancelModalOpen, setIsEditCancelModalOpen] = useState(false);
  const [isAICreateConfirmModalOpen, setIsAICreateConfirmModalOpen] =
    useState(false);
  const [selectedCategoryList, setSelectedCategoryList] = useState<string[]>(
    []
  );
  const [quizContents, setQuizContents] = useState<QuizContentDTO[]>([
    initialQuizContent,
  ]);
  const [quizNamesExisted, setQuizNamesExisted] = useState<string[]>([]);

  const { navigateWithState } = useNavigateState(
    NavigateStateKeys.fromQuizContentCreate
  );

  const { elementRef, sentinel, isAtTop, isHorizontallyScrollable } =
    useStickyPage();

  const handleSetQuiz = (quiz: QuizContentDTO) => {
    setQuizContents([quiz]);
    setSelectedCategoryList(quiz.categorys || []);
  };

  function handleSetQuizzes(quizzes: QuizContentDTO[]) {
    setQuizContents(quizzes);
  }

  const insertQuiz = async () => {
    const resp: any = await insertQuizContent({ quizList: quizContents });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      const insertedQuizCnt = resp.payload?.data?.quizIds.length || 0;
      toast.toastMsg(
        nanoid(),
        t("quiz.msg.addQuizContentSuccess", { count: insertedQuizCnt }),
        "success"
      );
      const idsCreated =
        resp.payload.data?.quizIds.map((id: number) => String(id)) || [];
      navigateWithState(`${process.env.PUBLIC_URL}/quiz/content`, idsCreated);
    } else {
      const quizNamesExisted = resp.payload.data?.quizNames || [];
      if (quizNamesExisted.length > 0) {
        setQuizNamesExisted(quizNamesExisted);
        toast.toastMsg(nanoid(), t("quiz.msg.duplicateQuizName"), "error");
      } else {
        toast.toastMsg(nanoid(), t("quiz.msg.insertQuizFail"), "error");
      }
    }
  };

  const updateQuiz = async () => {
    //validation
    if (quizId) {
      const resp: any = await updateQuizContent({
        quizId: parseInt(quizId),
        quizName: quizContents[0].quizName,
        quizType: quizContents[0].quizType,
        quizBody: quizContents[0].quizBody,
        quizComments: quizContents[0].quizComments,
        quizAnswer: quizContents[0].quizAnswer,
        quizChoices: quizContents[0].quizChoices,
        categorys: selectedCategoryList,
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("quiz.msg.updateQuizContentSuccess"),
          "success"
        );
        navigate("/quiz/content");
      } else {
        const quizNamesExisted = resp.payload.data?.quizNames || [];
        if (quizNamesExisted.length > 0) {
          setQuizNamesExisted(quizNamesExisted);
          toast.toastMsg(nanoid(), t("quiz.msg.duplicateQuizName"), "error");
        } else {
          toast.toastMsg(nanoid(), t("quiz.msg.insertQuizFail"), "error");
        }
      }
    }
  };

  const closeEditCancelModal = () => {
    navigate(`${process.env.PUBLIC_URL}/quiz/content`);
    setIsEditCancelModalOpen(false);
  };

  const stayOnPage = () => {
    setIsEditCancelModalOpen(false);
  };

  const checkIfSaveBtnIsActive = () => {
    for (const quiz of quizContents) {
      const isOX = quiz.quizType == 1;
      const keysToValidate = isOX
        ? ["quizName", "quizBody", "quizType"]
        : ["quizName", "quizBody", "quizType", "quizChoices"];

      if (!DataStructureUtils.isObjectRequiredFilled(quiz, keysToValidate)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    getQuizCategory();
    const copyData = window.sessionStorage.getItem("copyQuizFilter");

    if (quizId) {
      changeType("UPDATE");
      getQuizContentDetail({ quizId: parseInt(quizId) });
    } else {
      if (copyData) {
        const copyQuizContent = JSON.parse(copyData);
        setQuizContents([
          {
            quizName: `[복사] ${copyQuizContent.quizName}`,
            quizType: copyQuizContent.quizType,
            quizAnswer: copyQuizContent.quizAnswer,
            quizBody: copyQuizContent.quizBody,
            quizChoices: copyQuizContent.quizChoices,
            quizComments: copyQuizContent.quizComments,
          },
        ]);
        setSelectedCategoryList(copyQuizContent.categorys);
        window.sessionStorage.removeItem("copyQuizFilter");
      } else {
        setQuizContents([
          {
            quizName: "",
            quizType: 1,
            quizAnswer: 0,
            quizChoices: [],
            quizBody: "",
            quizComments: "",
          },
        ]);
        setSelectedCategoryList([]);
      }
    }
  }, []);

  useEffect(() => {
    if (quizId && quizState.quizContent) {
      if (!checkIfDataEditableByCommonId(quizState.quizContent.commonId)) {
        toast.toastMsg(nanoid(), t("common.msg.noPermission"), "error");
        navigate(`${process.env.PUBLIC_URL}/quiz/content`);
      }
      setQuizContents([
        {
          ...quizState.quizContent,
          quizType: quizState.quizContent.quizType.toString(),
        },
      ]);
      setSelectedCategoryList(quizState.quizContent.categorys);
    }
  }, [quizState.quizContent]);

  return (
    <BoxNew classes="sticky-layout">
      <BoxNew ref={sentinel}>
        <BoxNew
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          <BoxNew direction="column">
            <TitlePath path={[t("menu.quizTitle"), t("menu.quizContent")]} />
            <TypographyNew
              type={"h1"}
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
              classes="mg mt-8"
            >
              {/* 훈련 시나리오 생성 */}
              {type === "UPDATE"
                ? t("quiz.label.editQuizContent")
                : t("quiz.label.addQuizContent")}
            </TypographyNew>
          </BoxNew>
          {type === "CREATE" && (
            <BoxNew>
              <ButtonNew
                leadingIcon={
                  <IconMindSat
                    variant="line"
                    label="doubleStar"
                    stroke={getColor("COLOR_ICON_PRIMARY")}
                    size={16}
                  />
                }
                btnStyle={"fill"}
                state={"default"}
                type={"secondary"}
                size={"lg"}
                onClick={() => setIsAICreateConfirmModalOpen(true)}
                label={t("quiz.label.addAiQuiz")}
              />
            </BoxNew>
          )}
        </BoxNew>
      </BoxNew>
      <BoxNew direction="column" classes="body" style={{ gap: "40px" }}>
        {quizContents.length > 0 ? (
          <>
            {quizContents.map((quiz, index) => (
              <QuizInput
                key={quiz.quizId + "quizInput"}
                quizContent={quiz}
                updateQuizContent={(quiz) => {
                  const newQuizzes = [...quizContents];
                  newQuizzes[index] = quiz;
                  setQuizContents(newQuizzes);
                }}
                hasBorder={index !== quizContents.length - 1}
                quizNamesExisted={quizNamesExisted}
              />
            ))}
          </>
        ) : (
          <QuizInput
            quizContent={quizContents[0]}
            updateQuizContent={(quiz) => setQuizContents([quiz])}
            quizNamesExisted={quizNamesExisted}
          />
        )}
      </BoxNew>
      {/* footer start */}
      <BoxNew
        classes={classNames("footer", {
          showScroll: isHorizontallyScrollable,
        })}
      >
        <ButtonNew
          btnStyle={"fill"}
          state={"default"}
          size={"md"}
          type={"secondary"}
          onClick={() => setIsEditCancelModalOpen(true)}
          label={t("common.button.cancel")}
        />
        <ButtonNew
          btnStyle={"fill"}
          classes="mg ml-10"
          state={checkIfSaveBtnIsActive() ? "default" : "disable"}
          size={"md"}
          type={"primary"}
          onClick={() => {
            if (type === "UPDATE") updateQuiz();
            else insertQuiz();
          }}
          label={
            type === "UPDATE" ? t("common.button.save") : t("quiz.label.save")
          }
        />
      </BoxNew>
      {/* footer end */}
      <EditCancelModal
        closeModal={closeEditCancelModal}
        stayOnPage={stayOnPage}
        toShow={isEditCancelModalOpen}
      />
      {isAICreateConfirmModalOpen && (
        <Modal
          open={isAICreateConfirmModalOpen}
          onClose={() => setIsAICreateConfirmModalOpen(false)}
          title={t("quiz.label.aiCreate")}
          width={540}
          footer={
            <BoxNew>
              <ButtonNew
                btnStyle="fill"
                type="secondary"
                label={t("common.button.cancel")}
                size="md"
                onClick={() => setIsAICreateConfirmModalOpen(false)}
              />
              <ButtonNew
                btnStyle="fill"
                type="error"
                label={t("common.button.check")}
                size="md"
                onClick={() => {
                  setIsAICreateConfirmModalOpen(false);
                  setIsAIModalOpen(true);
                }}
              />
            </BoxNew>
          }
          body={
            <BoxNew>
              <TypographyNew
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("quiz.quizAiModal.msg.confirmOverwrite")}
              </TypographyNew>
            </BoxNew>
          }
        />
      )}
      {isAIModalOpen && (
        <AIPromptModal
          isOpen={isAIModalOpen}
          handleSetQuiz={handleSetQuiz}
          handleSetQuizzes={handleSetQuizzes}
          onClose={() => setIsAIModalOpen(false)}
        />
      )}
    </BoxNew>
  );
};

const QuizInput = ({
  quizContent,
  updateQuizContent,
  hasBorder = false,
  quizNamesExisted,
}: {
  quizContent: QuizContentDTO;
  updateQuizContent: (quiz: QuizContentDTO) => void;
  hasBorder?: boolean;
  quizNamesExisted: string[];
}) => {
  const { t } = useTranslation();
  // 사용자 입력값
  const [categoryInput, setCategoryInput] = useState<string>("");
  //사용자 입력값에 대한 기존 category 검색 리스트
  const [categoryList, setCategoryList] = useState<string[]>([]);

  const { quizState } = useQuizStore();

  const onChangeChoices = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (quizContent.quizChoices) {
      const temp = [...quizContent.quizChoices];
      temp[index] = e.target.value;
      updateQuizContent({ ...quizContent, quizChoices: temp });
    }
  };

  const getQuizNameHelpMsg = (quizName: string, maxLength: number) => {
    if (quizNamesExisted.includes(quizName || "")) {
      return t("quiz.msg.duplicateQuizName");
    } else if (quizName.length > maxLength) {
      return t("common.msg.maxLengthExceed");
    }
    return "";
  };

  useEffect(() => {
    const newCatName = categoryInput.trim();
    if (newCatName.length > 0) {
      setCategoryList(
        quizState.quizContentCategory
          .filter((item: string) => item.includes(newCatName))
          .map((c: string) => c)
      );
    } else {
      setCategoryList([]);
    }
  }, [categoryInput]);

  return (
    <BoxNew
      direction="column"
      style={{
        borderBottom: hasBorder ? "1px solid #E9ECEF" : "none",
        paddingBottom: hasBorder ? "40px" : "0px",
      }}
    >
      <BoxNew>
        <BoxNew width={248} alignItems="center" height={40}>
          <TypographyNew
            type={"subtitle2"}
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("quiz.label.quizName")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>
        <InputField
          width={598}
          size={"md"}
          placeholder={t("quiz.label.quizNameHolder")}
          value={quizContent.quizName}
          maxLength={128}
          onChange={(e) => {
            updateQuizContent({ ...quizContent, quizName: e.target.value });
          }}
          isError={getQuizNameHelpMsg(quizContent.quizName || "", 128) !== ""}
          helpMessage={getQuizNameHelpMsg(quizContent.quizName || "", 128)}
        />
      </BoxNew>

      <BoxNew classes="mg mt-24">
        <BoxNew width={248} alignItems="center" height={40}>
          <TypographyNew
            type={"subtitle2"}
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("quiz.label.question")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>
        <InputField
          size={"md"}
          width={598}
          placeholder={t("quiz.label.questionHolder")}
          value={quizContent.quizBody}
          maxLength={1000}
          onChange={(e) =>
            updateQuizContent({ ...quizContent, quizBody: e.target.value })
          }
        />
      </BoxNew>

      <BoxNew classes="mg mt-24">
        <BoxNew width={248} alignItems="center">
          <TypographyNew
            type={"subtitle2"}
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("quiz.label.format")}
          </TypographyNew>
          <TypographyNew
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_ERROR")}
          >
            &nbsp;*
          </TypographyNew>
        </BoxNew>

        <BoxNew classes="gap-24 mg mb-24">
          <RadioNew
            label={t("quiz.label.formatTF")}
            id="1"
            check={quizContent.quizType == 1}
            onClick={(id: string) => {
              updateQuizContent({
                ...quizContent,
                quizAnswer: 0,
                quizChoices: [],
                quizType: parseInt(id),
              });
            }}
          />
          <RadioNew
            label={t("quiz.label.formatChoice")}
            id="2"
            check={quizContent.quizType == 2}
            onClick={(id: string) => {
              updateQuizContent({
                ...quizContent,
                quizAnswer: 0,
                quizChoices: ["", "", "", "", ""],
                quizType: parseInt(id),
              });
            }}
          />
        </BoxNew>
      </BoxNew>

      <BoxNew
        classes="mg mt-14 pd pl-20 pr-20 pt-16 pb-16 "
        direction={"column"}
        width={598}
        style={{
          backgroundColor: getColor("COLOR_BG_SECONDARY"),
          marginLeft: "248px",
          borderRadius: "8px",
        }}
      >
        <TypographyNew
          type={"subtitle2"}
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
          classes="pd pb-12"
        >
          {quizContent.quizType == 1
            ? t("quiz.label.answer")
            : t("quiz.label.choice")}
        </TypographyNew>
        <BoxNew
          classes="mg mb-2"
          style={{
            borderBottom: "1px solid  #E9ECEF",
          }}
        ></BoxNew>
        {quizContent.quizType == 1 ? (
          <BoxNew classes="gap gap-24 mg mt-12">
            <RadioNew
              label={t("quiz.label.choiceO")}
              id="0"
              check={quizContent.quizAnswer?.toString() === "0"}
              onClick={(id: string) =>
                updateQuizContent({ ...quizContent, quizAnswer: parseInt(id) })
              }
            />
            <RadioNew
              label={t("quiz.label.choiceX")}
              id="1"
              check={quizContent.quizAnswer?.toString() === "1"}
              onClick={(id: string) =>
                updateQuizContent({ ...quizContent, quizAnswer: parseInt(id) })
              }
            />
          </BoxNew>
        ) : (
          <BoxNew direction="column">
            <BoxNew>
              <BoxNew
                width={106}
                alignItems="center"
                height={40}
                classes="mg mb-12"
              >
                <TypographyNew
                  type={"body2_rg"}
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.answer")}
                </TypographyNew>
              </BoxNew>
              <BoxNew alignItems="center" height={40} classes="mg mb-12">
                <TypographyNew
                  type={"body2_rg"}
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("quiz.label.text")}
                </TypographyNew>
              </BoxNew>
            </BoxNew>
            {quizContent.quizChoices &&
              quizContent.quizChoices.map((c, index) => (
                <BoxNew classes="mg mb-12" height={40} key={index}>
                  <BoxNew width={106} alignItems="center">
                    <BoxNew
                      width={20}
                      height={20}
                      classes="mg mr-8"
                      alignItems="center"
                    >
                      <RadioNew
                        onClick={() =>
                          updateQuizContent({
                            ...quizContent,
                            quizAnswer: index,
                          })
                        }
                        check={quizContent.quizAnswer === index}
                        classes="quiz__radio"
                      />
                    </BoxNew>
                    <TypographyNew
                      type={"body2_rg"}
                      exactColor={getColor("COLOR_TEXT_PRIMARY")}
                    >
                      {index + 1}
                    </TypographyNew>
                  </BoxNew>
                  <InputField
                    size={"md"}
                    placeholder=""
                    width={452}
                    maxLength={1000}
                    value={
                      quizContent.quizChoices && quizContent.quizChoices[index]
                    }
                    onChange={(e) => onChangeChoices(e, index)}
                  />
                </BoxNew>
              ))}
          </BoxNew>
        )}
      </BoxNew>

      <BoxNew classes="mg mt-24" height={80}>
        <BoxNew width={248} alignItems="center" height={40}>
          <TypographyNew
            type={"subtitle2"}
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("quiz.label.answerEx")}
          </TypographyNew>
        </BoxNew>
        <InputArea
          width={598}
          size={"md"}
          value={quizContent.quizComments && quizContent.quizComments}
          placeholder={t("quiz.label.answerExHolder")}
          onChange={(e) =>
            updateQuizContent({ ...quizContent, quizComments: e.target.value })
          }
          maxLength={2000}
          isError={(quizContent.quizComments || "").length > 2000}
          helpMessage={
            (quizContent.quizComments || "").length > 2000
              ? t("common.msg.maxLengthExceed")
              : ""
          }
        />
      </BoxNew>

      {/* category start */}
      <BoxNew
        style={{
          marginTop: "24px",
        }}
      >
        <BoxNew width={248} alignItems="center" height={40}>
          <TypographyNew
            type={"subtitle2"}
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
            classes="mg mb-12"
          >
            {t("quiz.label.category")}
          </TypographyNew>
        </BoxNew>

        <CategoryDropdown
          dropdownList={categoryList}
          searchword={categoryInput}
          updateSearchword={(searchword: string) =>
            setCategoryInput(searchword)
          }
          initialSelectedChips={quizContent.categorys || []}
          updateSelectedChips={(selectedChips) =>
            updateQuizContent({ ...quizContent, categorys: selectedChips })
          }
          width={"598px"}
        />
      </BoxNew>
      {/* category end  */}
    </BoxNew>
  );
};

export default WriteQuiz;
