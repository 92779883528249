import {
  Box,
  Button,
  Checkbox,
  Icons,
  Modal,
  getColor,
  Typography,
  useToast,
  InputField,
  Dropdown,
  DropdownItem,
  Table,
  ColumnDatas,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { useEffect, useState } from "react";
import { CustomerInfoBean } from "../../../types/Info";
import utilsCommon from "../../../shared/utils/common";
import NoInfoImage from "../../../shared/image/NoInfoImage";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

const ManageCustomer: React.FC = () => {
  const { t } = useTranslation();
  const checkboxGroup = useCheckboxGroup<string>([]);
  const [height, setHeight] = useState(window.innerHeight - 330);
  const toast = useToast();
  const { customerState, getAccessCompanyList, updateCustomerInfo } =
    useCustomerStore();
  const [companyList, chageCompanyList] = useState<CustomerInfoBean[]>([]);
  const [companyName, changeCompanyName] = useState("");
  const [buttonClick, setButtonClick] = useState(0);
  const [updateCustomerModalInfo, changeUpdateCustomerModalInfo] = useState({
    isOpen: false,
    customerCode: "",
  });
  const [updateCompanyName, changeUpdateCompanyName] = useState("");
  const [targetCompanyId, changeTargetCompanyId] = useState("");
  useEffect(() => {
    getAccessCompanyList();
  }, []);
  useEffect(() => {
    chageCompanyList(
      customerState.companyList?.filter((item: CustomerInfoBean) =>
        item.companyName.includes(companyName)
      )
    );
  }, [companyName]);
  useEffect(() => {
    chageCompanyList(customerState.companyList);
  }, [customerState.companyList]);
  useEffect(() => {
    if (updateCustomerModalInfo.customerCode) {
      changeUpdateCompanyName(
        companyList.filter(
          (item) => item.customerCode === updateCustomerModalInfo.customerCode
        )[0].companyName
      );
    } else {
      changeUpdateCompanyName("");
    }
  }, [updateCustomerModalInfo.isOpen]);

  const handleUpdateCustomerInfo = async () => {
    let resp: any = await updateCustomerInfo({
      customerCode: updateCustomerModalInfo.customerCode,
      companyName: updateCompanyName,
    });
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("customer.msg.customerUpdateSuccessMsg"),
        "success"
      );
      getAccessCompanyList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("customer.msg.customerUpdateFailMsg"),
        "error"
      );
    }
    changeUpdateCustomerModalInfo({
      isOpen: false,
      customerCode: "",
    });
  };

  function transformData(data: CustomerInfoBean[]) {
    const dataTransformed = [] as ColumnDatas[];
    data?.map((item: CustomerInfoBean) => {
      const dataItemTransformed = {
        data: [
          { columnText: item.companyName },
          {
            columnText:
              item.startDate && item.endDate
                ? utilsCommon.getShortDate(item.startDate) +
                  "~" +
                  utilsCommon.getShortDate(item.endDate)
                : item.startDate ?? "",
          },
          {
            columnText: item.reportInfo ? (
              <Icons
                variant="line"
                label="check"
                stroke={getColor("COLOR_ICON_BRAND")}
              />
            ) : (
              <Icons
                variant="line"
                label="closeLine"
                stroke={getColor("COLOR_ICON_ERROR")}
              />
            ),
          },
          {
            columnText: item.dbName ? (
              <Icons
                variant="line"
                label="check"
                stroke={getColor("COLOR_ICON_BRAND")}
              />
            ) : (
              <Icons
                variant="line"
                label="closeLine"
                stroke={getColor("COLOR_ICON_ERROR")}
              />
            ),
          },
        ],
        id: String(item.customerCode),
        checkBoxClick: () => {
          checkboxGroup.handleClick(item.customerCode);
        },
        clickAction: () => {
          changeTargetCompanyId(item.customerCode);
        },
        hover: (
          <Dropdown buttonClick={buttonClick} align="right" width={180}>
            <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                changeUpdateCustomerModalInfo({
                  isOpen: true,
                  customerCode: item.customerCode,
                });
              }}
              label={
                <Box>
                  <Icons variant="line" label="edit2" />
                  <Typography classes="mg ml-8">
                    {t("common.button.update")}
                  </Typography>
                </Box>
              }
            />
          </Dropdown>
        ),
      };
      dataTransformed.push(dataItemTransformed);
    });
    return dataTransformed;
  }

  const renderUpdateCustomerModal = () => {
    return (
      <CommonModal
        title={t("customer.updateTitle")}
        open={updateCustomerModalInfo.isOpen}
        onClose={() =>
          changeUpdateCustomerModalInfo({
            isOpen: false,
            customerCode: "",
          })
        }
        afterSubmitButton={() => handleUpdateCustomerInfo()}
        width={536}
        disabledConfirm={updateCompanyName.trim() === ""}
        body={
          <Box direction="column" width={"100%"}>
            <Typography type="body2_rg">
              {t("customer.customerName")}
            </Typography>
            <InputField
              placeholder=" "
              size={"md"}
              value={updateCompanyName}
              classes="mg mt-6"
              onChange={(e) => changeUpdateCompanyName(e.target.value)}
            />
            <Typography type="body2_rg" classes="mg mt-24">
              {t("customer.customerCode")}
            </Typography>

            <InputField
              placeholder=" "
              size={"md"}
              value={updateCustomerModalInfo.customerCode}
              classes="mg mt-6"
              disabled={true}
            />
          </Box>
        }
      ></CommonModal>
    );
  };

  const renderCustomerDetailModal = () => {
    let targetCompany = companyList.filter(
      (item) => item.customerCode === targetCompanyId
    )[0];
    return (
      <Modal
        title={t("customer.detailTitle")}
        onClose={() => changeTargetCompanyId("")}
        open={targetCompanyId !== ""}
        footer={
          <Box justifyContent="flex-end">
            <Button
              type="secondary"
              btnStyle="fill"
              label={t("common.button.cancel")}
              onClick={() => changeTargetCompanyId("")}
            />
          </Box>
        }
        body={
          <Box direction="column" width={"100%"}>
            <Box width={"100%"}>
              <Box width={"50%"} direction="column">
                <Typography
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("customer.customerName")}
                </Typography>
                <Typography type="body2_rg" classes="mg mt-6">
                  {targetCompany.companyName ?? ""}
                </Typography>
              </Box>
              <Box width={"50%"} direction="column">
                <Typography
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("customer.reportAddress")}
                </Typography>
                <Typography classes="mg mt-6">
                  {targetCompany.reportInfo ?? ""}
                </Typography>
              </Box>
            </Box>
            <Box width={"100%"} classes="mg mt-24" direction="column">
              <Typography
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("customer.customerCode")}
              </Typography>
              <Typography classes="mg mt-6">
                {targetCompany.customerCode ?? ""}
              </Typography>
            </Box>
            <Box width={"100%"} classes="mg mt-24">
              <Box width={"50%"} direction="column">
                <Typography
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  {t("customer.contractPeriod")}
                </Typography>
                <Typography classes="mg mt-6">
                  {targetCompany.startDate && targetCompany.endDate
                    ? utilsCommon.getShortDate(targetCompany.startDate) +
                      "~" +
                      utilsCommon.getShortDate(targetCompany.endDate)
                    : targetCompany.startDate ?? ""}
                </Typography>
              </Box>
              <Box width={"50%"} direction="column">
                <Typography
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_SECONDARY")}
                >
                  DB
                </Typography>
                <Typography classes="mg mt-6">
                  {targetCompany.dbName ? (
                    <Box alignItems="center">
                      <Icons
                        variant="line"
                        label="check"
                        stroke={getColor("COLOR_ICON_BRAND")}
                      />
                      <Typography classes="mg ml-6">
                        {t("common.button.yes")}
                      </Typography>
                    </Box>
                  ) : (
                    <Box alignItems="center">
                      <Icons
                        variant="line"
                        label="closeLine"
                        stroke={getColor("COLOR_ICON_ERROR")}
                      />
                      <Typography classes="mg ml-6">
                        {t("common.button.no")}
                      </Typography>
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box width={"100%"} classes="mg mt-24" direction="column">
              <Typography
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_SECONDARY")}
              >
                {t("customer.category")}
              </Typography>
              <Typography classes="mg mt-6">
                {targetCompany.categoryName ?? t("customer.noCategory")}
              </Typography>
            </Box>
          </Box>
        }
      ></Modal>
    );
  };

  return (
    <Box classes="main manageCustomer">
      {updateCustomerModalInfo.isOpen && renderUpdateCustomerModal()}
      {targetCompanyId !== "" && renderCustomerDetailModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.customerManagement")]} />
          <Typography classes="mg mt-8" type="h1">
            {t("menu.customerManagement")}
          </Typography>
        </Box>
      </Box>
      <Box
        classes="mg mt-4"
        direction="column"
        width={"100%"}
        height={"calc(100% - 84px)"}
      >
        <Box justifyContent="space-between">
          <Box alignItems="center">
            <Typography type="subtitle2">
              {t("customer.customerSimple")}
            </Typography>
            <Typography
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_BRAND")}
              classes="mg ml-4"
            >
              {companyList?.length ?? 0}
            </Typography>
          </Box>
          <Box>
            {checkboxGroup.selected.length === 1 && (
              <Button
                size="sm"
                btnStyle="link"
                leadingIcon={<Icons variant="line" label="edit2" />}
                label={t("common.button.update")}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  changeUpdateCustomerModalInfo({
                    isOpen: true,
                    customerCode: checkboxGroup.selected[0],
                  });
                }}
              />
            )}
            <InputField
              leadingIcon={<Icons variant="line" label="search" />}
              placeholder={t("customer.searchName")}
              size={"md"}
              onChange={(e) => changeCompanyName(e.target.value)}
              width={296}
            />
          </Box>
        </Box>

        {companyList.length > 0 ? (
          <Box direction="column" height="calc(100% - 40px)" classes="mg mt-12">
            <Table
              border="none"
              clickIds={checkboxGroup.selected.map((customerCode) =>
                String(customerCode)
              )}
              columnHeight="md"
              data={transformData(companyList)}
              endReached={() => {}}
              checkBoxClick={() =>
                checkboxGroup.handleAllClick(
                  checkboxGroup.selected.length === companyList.length
                    ? []
                    : companyList.map((item) => item.customerCode)
                )
              }
              header={[
                {
                  headerText: t("customer.customerName"),
                  width: "20%",
                },
                {
                  headerText: t("customer.contractPeriod"),
                  width: "20%",
                },
                {
                  headerText: t("customer.reportAddress"),
                  width: "20%",
                },
                {
                  headerText: "DB",
                  width: "20%",
                },
              ]}
              width={"100%"}
              height={companyList?.length > 0 ? height + "px" : "0px"}
            />
          </Box>
        ) : (
          <Box
            width={"100%"}
            height={"400px"}
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <NoInfoImage width={400} />
            <Typography classes="mg mt-20" type="h3">
              {t("common.msg.noHistoryMsg")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ManageCustomer;
