import {
  Button,
  Box,
  Modal,
  InputField,
  Typography,
  Radio,
  getColor,
  useToast,
} from "fds";

import { SimpleTreeNode, UserType } from "../../types/User";
import { useEffect, useState } from "react";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import { nanoid } from "@reduxjs/toolkit";
import utilsCommon from "../../shared/utils/common";
import { useTranslation } from "react-i18next";
import TreeSimple from "../../component/TreeSimple";

interface CreateUpdateUserModalProps {
  isOpen: boolean;
  targetDept?: SimpleTreeNode;
  updateUserId?: string;
  onClose: () => void;
  treeNodes: SimpleTreeNode[];
  setUsersIdCreated?: (id: string[]) => void;
}

const CreateUpdateUserModal: React.FC<CreateUpdateUserModalProps> = ({
  isOpen,
  targetDept,
  updateUserId,
  onClose,
  treeNodes,
  setUsersIdCreated,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [buttonClick, setButtonClick] = useState(0);
  const {
    deptState,
    insertOrganUserList,
    updateOrganUserList,
    getOrganUserList,
  } = useDeptStore();

  const [tempUserInfo, changeTempUserInfo] = useState({
    userId: "",
    userName: "",
    userEmail: "",
    deptCode: targetDept?.deptCode,
    jobTitle: "",
    managerFlag: false,
  } as UserType);
  const [errorInput, changeErrorInput] = useState({
    type: "",
    message: "",
  });
  useEffect(() => {
    if (!updateUserId) return;
    changeTempUserInfo(
      deptState.userListInDept?.filter(
        (item: UserType) => item.userId === updateUserId
      )[0]
    );
  }, [deptState.userListInDept]);
  useEffect(() => {
    if (!deptState.userListInDept && !!updateUserId)
      getOrganUserList(targetDept?.deptCode);
  });
  const handeRegisterNewUser = async () => {
    if (!utilsCommon.userNameValidation(tempUserInfo.userName)) {
      changeErrorInput({
        type: "name",
        message: t("user.msg.nameErrorMsg"),
      });
      return;
    } else {
      changeErrorInput({
        type: "name",
        message: "",
      });
    }
    if (!utilsCommon.emailValidation(tempUserInfo.userEmail)) {
      changeErrorInput({
        type: "email",
        message: t("user.msg.userInvalidEmailMsg"),
      });
      return;
    } else {
      changeErrorInput({
        type: "email",
        message: "",
      });
    }
    let resp: any = updateUserId
      ? await updateOrganUserList(tempUserInfo)
      : await insertOrganUserList(tempUserInfo);
    if (!updateUserId && setUsersIdCreated) {
      const idCreated = resp.payload.data?.userId || "";
      setUsersIdCreated([idCreated.toString()]);
    }
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        updateUserId
          ? t("user.msg.userUpdateSuccessMsg")
          : t("user.msg.userAddSuccessMsg"),
        "success"
      );
      getOrganUserList(targetDept?.deptCode);
      onClose();
    } else if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
      toast.toastMsg(nanoid(), t("account.msg.joinExistsEmailMsg"), "error");
    } else {
      toast.toastMsg(
        nanoid(),
        updateUserId
          ? t("user.msg.userUpdateFailMsg")
          : t("user.msg.userAddFailMsg"),
        "error"
      );
    }
  };
  return (
    <Modal
      open={isOpen}
      title={!updateUserId ? t("user.commonUserAdd") : t("user.commonUserEdit")}
      isFooterDivider={true}
      width={816}
      footer={
        <Box width={"100%"} justifyContent="flex-end">
          <Button
            btnStyle="fill"
            type="secondary"
            onClick={onClose}
            label={t("common.button.cancel")}
          />
          <Button
            btnStyle="fill"
            classes="mg ml-8"
            onClick={handeRegisterNewUser}
            state={
              !tempUserInfo.userEmail?.trim() ||
              !tempUserInfo.deptCode ||
              !tempUserInfo.userName?.trim()
                ? "disable"
                : "default"
            }
            label={
              !updateUserId ? t("common.button.save") : t("common.button.save")
            }
          />
        </Box>
      }
      onClose={onClose}
      body={
        <Box direction="row" width={"100%"}>
          <Box
            width={436}
            height={556}
            classes="treeRightBorder"
            direction="column"
          >
            <Box classes="mg mb-12">
              <Typography type="subtitle2">상위 부서 선택</Typography>{" "}
              <Typography
                type="subtitle2"
                classes="mg ml-4"
                exactColor={getColor("COLOR_TEXT_ERROR")}
              >
                *
              </Typography>
            </Box>
            {treeNodes && (
              <TreeSimple
                rootCode={"COMPANY"}
                clickedId={tempUserInfo.deptCode}
                changeClickedId={(deptCode) =>
                  changeTempUserInfo({
                    ...tempUserInfo,
                    deptCode: deptCode,
                  })
                }
                treeNodes={treeNodes}
                depth={0}
                treeType={"DEPT_ONLY"}
                defaultExpanded={false}
                isOrganModal
              />
            )}
          </Box>
          <Box direction="column">
            <Box>
              <Typography type="subtitle2">{t("user.userName")}</Typography>
              <Typography
                classes="mg ml-4"
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_ERROR")}
              >
                *
              </Typography>
            </Box>
            <InputField
              placeholder={t("user.msg.userUndefinedNameMsg")}
              size={"md"}
              classes="mg mt-6"
              onChange={(e) =>
                changeTempUserInfo({
                  ...tempUserInfo,
                  userName: e.target.value,
                })
              }
              isError={errorInput.type === "name"}
              helpMessage={errorInput.message}
              value={tempUserInfo.userName}
              width={296}
              maxLength={60}
            />
            <Box classes="mg mt-24">
              <Typography type="subtitle2">{t("dept.managerFlag")}</Typography>
              <Typography
                classes="mg ml-4"
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_ERROR")}
              >
                *
              </Typography>
            </Box>
            <Box classes="mg mt-6">
              <Radio
                label={t("common.button.yes")}
                check={tempUserInfo.managerFlag}
                onClick={() =>
                  changeTempUserInfo({
                    ...tempUserInfo,
                    managerFlag: true,
                  })
                }
              />
              <Radio
                label={t("common.button.no")}
                check={!tempUserInfo.managerFlag}
                onClick={() =>
                  changeTempUserInfo({
                    ...tempUserInfo,
                    managerFlag: false,
                  })
                }
                classes="mg ml-24"
              />
            </Box>
            <Box classes="mg mt-24">
              <Box direction="column" width={"50%"}>
                <Typography type="subtitle2">
                  {t("user.userPosition")}
                </Typography>
                <InputField
                  placeholder={t("user.msg.userPositionPlaceholder")}
                  size={"md"}
                  classes="mg mt-6"
                  onChange={(e) =>
                    changeTempUserInfo({
                      ...tempUserInfo,
                      jobTitle: e.target.value,
                    })
                  }
                  value={tempUserInfo.jobTitle ?? ""}
                  width={296}
                  maxLength={30}
                />
              </Box>
            </Box>
            <Box classes="mg mt-24">
              <Typography type="subtitle2">{t("user.userEmail")}</Typography>
              <Typography
                classes="mg ml-4"
                type="subtitle2"
                exactColor={getColor("COLOR_TEXT_ERROR")}
              >
                *
              </Typography>
            </Box>
            <InputField
              placeholder={t("user.msg.userUndefinedEmailMsg")}
              size={"md"}
              classes="mg mt-6"
              isError={errorInput.type === "email"}
              helpMessage={errorInput.message}
              onChange={(e) => {
                if (utilsCommon.emailValidation(tempUserInfo.userEmail)) {
                  changeErrorInput({
                    type: "",
                    message: "",
                  });
                }
                changeTempUserInfo({
                  ...tempUserInfo,
                  userEmail: e.target.value,
                });
              }}
              value={tempUserInfo.userEmail ?? ""}
              width={296}
            />
            <Typography type="subtitle2" classes="mg mt-24">
              {t("user.userMemo")}
            </Typography>
            <InputField
              placeholder={t("user.msg.memoPlaceholder")}
              size={"md"}
              classes="mg mt-6 mb-24"
              onChange={(e) =>
                changeTempUserInfo({
                  ...tempUserInfo,
                  etc1: e.target.value,
                })
              }
              value={tempUserInfo.etc1 ?? ""}
              width={296}
              maxLength={64}
            />
          </Box>
        </Box>
      }
    ></Modal>
  );
};

export default CreateUpdateUserModal;
