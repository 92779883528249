import "./index.scss";

import {
  Box as BoxNew,
  Icons as IconsNew,
  Button as ButtonNew,
  Typography as TypographyNew,
  getColor,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { useEffect, useState } from "react";
import { QuizTemplateDTO } from "../../../types/Quiz";
import MailBody from "../../../component/MailBody";

const QuizTemplate: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { quizState, getQuizTemplate } = useQuizStore();

  const [templateInfo, setTemplateInfo] = useState<QuizTemplateDTO>();

  useEffect(() => {
    getQuizTemplate();
  }, []);

  useEffect(() => {
    setTemplateInfo(quizState.quizTemplate);
  }, [quizState.quizTemplate]);

  return (
    <BoxNew classes="main">
      <BoxNew
        justifyContent="space-between"
        alignItems="center"
        classes="mg mb-24"
      >
        <BoxNew direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizTemplate")]} />
          <TypographyNew
            classes="mg mt-8"
            type={"h1"}
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("menu.quizTemplate")}
          </TypographyNew>
        </BoxNew>
        <BoxNew>
          <ButtonNew
            btnStyle="fill"
            type="secondary"
            label={t("common.button.update")}
            leadingIcon={
              <IconsNew
                variant="line"
                label="edit"
                stroke={getColor("COLOR_ICON_PRIMARY")}
              />
            }
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/quiz/template/write`)
            }
            size="lg"
          />
        </BoxNew>
      </BoxNew>
      <BoxNew classes="pd pt-20 pb-20">
        {templateInfo && (
          <BoxNew classes="quiz__template__container" direction="column">
            <BoxNew classes="mg mb-20">
              <TypographyNew
                type="h2"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {templateInfo.subject}
              </TypographyNew>
            </BoxNew>
            <BoxNew
              classes="pd pb-16"
              style={{ borderBottom: "1px solid #B5BBC2" }}
            >
              <TypographyNew
                type="body3_rg"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.label.sender")}:
              </TypographyNew>
              <TypographyNew
                type="body3_md"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                &nbsp;{templateInfo.sendName} ({templateInfo.sendEmail})
              </TypographyNew>
            </BoxNew>
            <BoxNew>
              {templateInfo.body && (
                <MailBody
                  maxHeight={"none"}
                  body={templateInfo.body}
                  background={false}
                ></MailBody>
              )}
            </BoxNew>
          </BoxNew>
        )}
      </BoxNew>
    </BoxNew>
  );
};

export default QuizTemplate;
