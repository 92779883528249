import { useEffect, useState } from "react";

// {orderId: <<정렬하려는 칼럼명>>, orderType: <<정렬 방향>>} 형태의 param을 받는 api 구조에 사용되는 훅임
// 테이블 정렬에 사용되는 api 호출 구조에 따라 타입 변형이 필요할 수 있음.

const ORDER_STATES = {
  NONE: 0,
  DESC: 1,
  ASC: 2,
} as const;

const ORDER_TYPES = {
  DOWN_OFF: "downOff",
  DOWN_ON: "downOn",
  UP_OFF: "upOff",
  UP_ON: "upOn",
} as const;

type OrderType = (typeof ORDER_TYPES)[keyof typeof ORDER_TYPES];
type OrderDirection = "asc" | "desc" | "";

interface TableOrderProps {
  orderCb?: (params: OrderParams) => void;
}

interface ClickState {
  orderId: string;
  clickCount: number;
}

interface OrderParams {
  orderId: string;
  orderType: OrderDirection;
}

interface OrderRenderProps {
  order: OrderType;
  orderAction: () => void;
}

export const useTableOrder = ({ orderCb }: TableOrderProps) => {
  const initialClickState: ClickState = {
    orderId: "",
    clickCount: ORDER_STATES.NONE,
  };

  const [clickState, setClickState] = useState<ClickState>(initialClickState);

  const updateOrderFetchParams = (column: string) => {
    // 다른 컬럼 선택
    if (clickState.orderId !== column) {
      setClickState({
        orderId: column,
        clickCount: ORDER_STATES.DESC,
      });
      return;
    }

    // 같은 컬럼 선택 (기본이 DESC 상태일 때 ASC를 OFF 직전으로 가정)
    setClickState((prev) => ({
      orderId: prev.clickCount === ORDER_STATES.ASC ? "" : column,
      clickCount:
        prev.clickCount === ORDER_STATES.ASC
          ? ORDER_STATES.NONE
          : prev.clickCount + 1,
    }));
  };

  const getOrderType = (column: string): OrderType => {
    let newClickCount = 0;
    if (column === clickState.orderId) {
      newClickCount = clickState.clickCount;
    }
    switch (newClickCount) {
      case ORDER_STATES.DESC:
        return ORDER_TYPES.DOWN_ON;
      case ORDER_STATES.ASC:
        return ORDER_TYPES.UP_ON;
      default:
        return ORDER_TYPES.DOWN_OFF;
    }
  };

  const getOrderDirection = (clickCount: number): OrderDirection => {
    switch (clickCount) {
      case ORDER_STATES.DESC:
        return "desc";
      case ORDER_STATES.ASC:
        return "asc";
      default:
        return "";
    }
  };

  const getOrderRenderProps = (column: string): OrderRenderProps => ({
    // Table 렌더링용 객체
    order: getOrderType(column),
    orderAction: () => updateOrderFetchParams(column),
  });

  const getOrderParams = (): OrderParams => ({
    // api 호출 시 전달할 객체
    orderId: clickState.orderId,
    orderType: getOrderDirection(clickState.clickCount),
  });

  useEffect(() => {
    orderCb?.(getOrderParams());
  }, [clickState]);

  return [getOrderRenderProps, getOrderParams] as const;
};
