import { useState, useEffect, useRef } from "react";

export default function useStickyPage() {
  const elementRef = useRef(null);
  const sentinel = useRef(null); // sticky 요소가 fixed 위치로 전환되기 직전의 위치를 표시하기 위한 용도로 elementRef를 div로 감싼 후 해당 요소에 등록해주어야함
  const lastScrollY = useRef(0);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isHorizontallyScrollable, setIsHorizontallyScrollable] =
    useState(false);

  function checkIfHorizontallyScrollable() {
    const bodyElement = document.querySelector("#body");
    if (!bodyElement) return;
    const clientWidth = bodyElement?.clientWidth;
    const scrollWidth = bodyElement?.scrollWidth;
    if (scrollWidth > clientWidth) {
      setIsHorizontallyScrollable(true);
    } else {
      setIsHorizontallyScrollable(false);
    }
  }

  function checkIfVerticallyScrollable() {
    const bodyElement = document.querySelector(".sticky-layout .body");
    if (!bodyElement) return;

    const currentScrollY = bodyElement.scrollTop;
    lastScrollY.current = currentScrollY;

    setIsAtTop(currentScrollY <= 0);
    setIsHorizontallyScrollable(
      bodyElement.scrollWidth > bodyElement.clientWidth
    );
    checkIfHorizontallyScrollable();
  }

  function handleScroll() {
    checkIfHorizontallyScrollable();
    checkIfVerticallyScrollable();
  }

  useEffect(() => {
    function subscribeListener() {
      const localBodyElement = document.querySelector(".sticky-layout .body");
      if (!localBodyElement) {
        setTimeout(subscribeListener, 100);
        return;
      }

      handleScroll();
      localBodyElement.addEventListener("scroll", checkIfVerticallyScrollable);
      window.addEventListener("resize", checkIfHorizontallyScrollable);
    }

    subscribeListener();

    return () => {
      const localBodyElement = document.querySelector(".sticky-layout .body");
      if (localBodyElement) {
        localBodyElement.removeEventListener(
          "scroll",
          checkIfVerticallyScrollable
        );
        window.removeEventListener("resize", checkIfHorizontallyScrollable);
      }
    };
  }, []);

  return {
    elementRef,
    sentinel,
    isAtTop,
    isHorizontallyScrollable,
    isSticky: false,
  };
}
