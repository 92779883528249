import { Box, getColor, Icons, Typography } from "fds";
interface TitlePathProps {
  path: string[];
}
const TitlePath: React.FC<TitlePathProps> = ({ path }) => {
  return (
    <Box alignItems="center">
      {path.map((item, index) =>
        index < path.length - 1 ? (
          <>
            <Typography
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_INFO")}
            >
              {item}
            </Typography>
            <Icons
              variant="line"
              label="chevron"
              direction="right"
              stroke={getColor("COLOR_ICON_INFO")}
            />
          </>
        ) : (
          <Typography type="subtitle2" exactColor={getColor("COLOR_TEXT_INFO")}>
            {item}
          </Typography>
        )
      )}
    </Box>
  );
};

export default TitlePath;
