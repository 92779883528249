const NoFileImage = ({
  classes,
  width,
  height,
}: {
  classes?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width || "200"}
      height={height || 120}
      viewBox="0 0 200 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M119.549 92.5274H80.7799C77.7968 92.5274 75.3657 90.1081 75.3657 87.1132V32.0314C75.3657 29.0365 77.7851 26.6172 80.7799 26.6172H107.463L124.951 44.1047V87.1132C124.951 90.0963 122.532 92.5274 119.549 92.5274Z"
        fill="#DDE1E7"
      />
      <path
        d="M116.884 59.6781H83.4707C82.9775 59.6781 82.5781 59.2788 82.5781 58.7855V57.7285C82.5781 57.2353 82.9775 56.8359 83.4707 56.8359H116.884C117.377 56.8359 117.776 57.2353 117.776 57.7285V58.7855C117.776 59.2788 117.377 59.6781 116.884 59.6781Z"
        fill="#A5AEB8"
      />
      <path
        d="M116.884 69.014H83.4707C82.9775 69.014 82.5781 68.6147 82.5781 68.1214V67.0645C82.5781 66.5712 82.9775 66.1719 83.4707 66.1719H116.884C117.377 66.1719 117.776 66.5712 117.776 67.0645V68.1214C117.776 68.6147 117.377 69.014 116.884 69.014Z"
        fill="#A5AEB8"
      />
      <path
        d="M99.3004 78.3539H83.4453C82.952 78.3539 82.5527 77.9545 82.5527 77.4613V76.4042C82.5527 75.911 82.952 75.5117 83.4453 75.5117H99.3004C99.7936 75.5117 100.193 75.911 100.193 76.4042V77.4613C100.193 77.9545 99.7936 78.3539 99.3004 78.3539Z"
        fill="#A5AEB8"
      />
      <path
        d="M107.487 26.6172V40.2173C107.487 42.3665 109.225 44.1047 111.374 44.1047H124.974L107.487 26.6172Z"
        fill="#A5AEB8"
      />
      <path
        d="M134.089 96.4134L133.971 96.5308C133.349 97.1533 132.327 97.1533 131.705 96.5308L124.529 89.3549C123.906 88.7325 123.906 87.7107 124.529 87.0882L124.646 86.9708C125.269 86.3483 126.29 86.3483 126.913 86.9708L134.089 94.1467C134.711 94.7574 134.711 95.7792 134.089 96.4134Z"
        fill="#6F7985"
      />
      <path
        d="M117.166 66.0664C109.79 66.0664 103.812 72.0444 103.812 79.4199C103.812 86.7955 109.79 92.7734 117.166 92.7734C124.542 92.7734 130.52 86.7955 130.52 79.4199C130.531 72.0444 124.553 66.0664 117.166 66.0664ZM117.166 89.1091C111.811 89.1091 107.477 84.7754 107.477 79.4199C107.477 74.0644 111.811 69.7307 117.166 69.7307C122.522 69.7307 126.855 74.0644 126.855 79.4199C126.855 84.7754 122.522 89.1091 117.166 89.1091Z"
        fill="#6F7985"
      />
      <path
        opacity="0.5"
        d="M117.164 69.7305C111.809 69.7305 107.475 74.0642 107.475 79.4197C107.475 84.7752 111.809 89.1089 117.164 89.1089C122.52 89.1089 126.854 84.7752 126.854 79.4197C126.854 74.0642 122.52 69.7305 117.164 69.7305Z"
        fill="white"
      />
      <path
        d="M144.168 64.2823C143.405 64.2823 142.794 63.6598 142.794 62.9081V59.8663C142.794 59.103 143.416 58.4922 144.168 58.4922C144.931 58.4922 145.542 59.1147 145.542 59.8663V62.9081C145.542 63.6598 144.931 64.2823 144.168 64.2823Z"
        fill="#FFCC75"
      />
      <path
        d="M144.168 73.6416C143.405 73.6416 142.794 73.0192 142.794 72.2675V69.2257C142.794 68.4623 143.416 67.8516 144.168 67.8516C144.931 67.8516 145.542 68.474 145.542 69.2257V72.2675C145.542 73.0309 144.931 73.6416 144.168 73.6416Z"
        fill="#FFCC75"
      />
      <path
        d="M145.955 66.0655C145.955 65.3021 146.577 64.6914 147.329 64.6914H150.371C151.134 64.6914 151.745 65.3138 151.745 66.0655C151.745 66.8289 151.122 67.4397 150.371 67.4397H147.329C146.565 67.4397 145.955 66.8171 145.955 66.0655Z"
        fill="#FFCC75"
      />
      <path
        d="M136.582 66.0655C136.582 65.3021 137.204 64.6914 137.956 64.6914H140.998C141.761 64.6914 142.372 65.3138 142.372 66.0655C142.372 66.8289 141.75 67.4397 140.998 67.4397H137.956C137.193 67.4397 136.582 66.8171 136.582 66.0655Z"
        fill="#FFCC75"
      />
      <path
        d="M57.7463 44.4924C63.6812 44.4924 68.4924 39.6812 68.4924 33.7462C68.4924 27.8112 63.6812 23 57.7463 23C51.8113 23 47 27.8112 47 33.7462C47 39.6812 51.8113 44.4924 57.7463 44.4924Z"
        fill="#ADCDFB"
      />
      <path
        d="M54.3747 30.0116C54.5743 29.6006 54.8445 29.2718 55.1968 29.0134C55.5491 28.755 55.9485 28.5671 56.4065 28.4497C56.8528 28.3322 57.3226 28.2617 57.8041 28.2617C58.4501 28.2617 59.049 28.3674 59.601 28.5788C60.153 28.7902 60.5875 29.119 60.9164 29.5536C61.2452 29.9881 61.4097 30.5284 61.4097 31.1861C61.4097 31.6441 61.3275 32.0317 61.1748 32.3371C61.0221 32.6425 60.8224 32.9008 60.5758 33.1122C60.3292 33.3236 60.0825 33.5115 59.8359 33.6877C59.5305 33.8873 59.2957 34.0635 59.096 34.2162C58.8963 34.3689 58.7672 34.5215 58.685 34.6977C58.6028 34.8739 58.5557 35.097 58.5557 35.3789V35.7547H56.3713L56.3595 35.4024C56.3243 35.0031 56.3478 34.6507 56.4417 34.3336C56.5357 34.0165 56.6884 33.7347 56.8998 33.488C57.1112 33.2414 57.3695 33.0065 57.6866 32.8186C58.0507 32.5837 58.3443 32.3606 58.5675 32.1492C58.7906 31.9495 58.8964 31.6676 58.8964 31.327C58.8964 31.0569 58.8376 30.8455 58.7202 30.6811C58.6027 30.5167 58.45 30.3992 58.2621 30.317C58.0742 30.2348 57.8746 30.1996 57.6632 30.1996C57.4518 30.1996 57.2756 30.2348 57.1229 30.2935C56.9702 30.364 56.841 30.4462 56.7353 30.5754C56.6296 30.6929 56.5475 30.8338 56.4887 30.9982C56.43 31.1509 56.4065 31.3271 56.4065 31.515H54.1398C54.0929 30.916 54.175 30.4227 54.3747 30.0116ZM56.4065 37.0232C56.6766 36.7883 57.0525 36.6708 57.5223 36.6708C58.0038 36.6708 58.3796 36.7883 58.6497 37.0232C58.9316 37.258 59.0608 37.5751 59.0608 37.9627C59.0608 38.3385 58.9198 38.6439 58.6497 38.8905C58.3678 39.1254 57.992 39.2428 57.5223 39.2428C57.0525 39.2428 56.6884 39.1254 56.4065 38.8905C56.1364 38.6556 55.9954 38.3503 55.9954 37.9627C55.9954 37.5751 56.1247 37.258 56.4065 37.0232Z"
        fill="white"
      />
    </svg>
  );
};

export default NoFileImage;
