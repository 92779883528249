import "./index.scss";
import "../../../shared/style/stickyLayout.scss";

import {
  Box as BoxNew,
  getColor,
  Typography,
  Accordions as AccordionsNew,
  useToast,
  Tooltip,
  Button,
  Modal,
  Icons,
  InputField,
} from "fds";

import { useTranslation } from "react-i18next";
import useStickyPage from "../../../hooks/useStickyObserver";
import TitlePath from "../../../component/TitlePath";
import classNames from "classnames";
import ButtonGoBack from "../../../component/ButtonGoBack";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useCategoryStore from "../../../redux/dispatcher/useCategoryStore";
import useCheckboxGroup from "fds/src/hooks/useCheckboxGroup";
import { nanoid } from "@reduxjs/toolkit";
import { Category } from "../../../types/Info";
import MailBody from "../../../component/MailBody";
import CategoryDropdown from "../../../component/CategoryDropdown";
import PlainToggle from "../../../component/PlainToggle";
import utilsCommon from "../../../shared/utils/common";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";

const DetailScenario: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { elementRef, sentinel, isAtTop } = useStickyPage();
  const params = useParams();
  const templateId = parseInt(params["*"] as string);

  const {
    mailTemplateState,
    getMailTemplateData,
    updateMailTemplate,
    deleteMailTemplate,
    sendTestScenario,
  } = useMailTemplateStore();
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState<string[]>(
    []
  );
  const [categoryInput, setCategoryInput] = useState<string>("");
  const [viewedBody, setViewedBody] = useState<string | null>(null);
  const { categoryState, getCategories } = useCategoryStore();
  const categoryCheckboxGroup = useCheckboxGroup();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isTestSendMailModalOpen, setIsTestSendMailModalOpen] = useState(false);
  const [testEmailForm, setTestEmailForm] = useState({
    email: "",
    errMsg: "",
  });

  const toast = useToast();

  const handleDelete = async () => {
    const resp: any = await deleteMailTemplate({
      request: {
        templateIds: [templateId],
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateDeleteSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateNoDeletedTemplateMsg"),
        "error"
      );
    }
  };

  const handleTestSend = async () => {
    if (!validateTestEmail()) return;
    const resp: any = await sendTestScenario({
      receiverEmail: testEmailForm.email,
      templateId: templateId,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateSendTestSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(
        nanoid(),
        t("template.msg.templateSendTestFailMsg"),
        "error"
      );
    }
    closeTestSendMailModal();
  };

  const validateTestEmail = () => {
    const isEmailValid = utilsCommon.emailValidation(testEmailForm.email);
    if (!isEmailValid)
      setTestEmailForm((d) => ({
        ...d,
        errMsg: t("common.msg.invalidEmailMsg"),
      }));
    return isEmailValid;
  };

  const updateScenarioCategories = async (cate: string[]) => {
    const resp: any = await updateMailTemplate({
      request: {
        ...mailTemplateState.mailTemplateDetail,
        categorys: cate,
      },
    });
    return resp;
  };

  useEffect(() => {
    getMailTemplateData(templateId);
    getCategories();
  }, []);

  const closeTestSendMailModal = () => {
    setIsTestSendMailModalOpen(false);
    setTestEmailForm({
      email: "",
      errMsg: "",
    });
  };

  useEffect(() => {
    const newCategoryName = categoryInput.trim();
    // category 검색
    if (newCategoryName.length > 0) {
      setCategoryList(
        categoryState.categoryList
          .filter((item: Category) =>
            item.categoryName.includes(newCategoryName)
          )
          .map((c: Category) => c.categoryName)
      );
    } else {
      setCategoryList([]);
    }
  }, [categoryInput]);

  useEffect(() => {
    if (
      mailTemplateState.mailTemplateDetail &&
      mailTemplateState.mailTemplateDetail.categorys
    ) {
      mailTemplateState.mailTemplateDetail.categorys.map((item: string) =>
        categoryCheckboxGroup.handleClick(item)
      );
      setSelectedCategoryList(mailTemplateState.mailTemplateDetail.categorys);

      //template image 테스트
      let orgHtml: string = mailTemplateState.mailTemplateDetail.templateHtml;
      if (mailTemplateState.mailTemplateDetail.imageId !== 0) {
        orgHtml = orgHtml.replaceAll(
          "##IMAGE##",
          mailTemplateState.mailTemplateDetail.imagePath
        );
      }

      if (mailTemplateState.mailTemplateDetail.siteId !== 0) {
        orgHtml = orgHtml.replaceAll(
          "##LINK##",
          mailTemplateState.mailTemplateDetail.siteUrl
        );
      }
      setViewedBody(orgHtml);

      if (
        mailTemplateState.mailTemplateDetail.imageId === 0 &&
        mailTemplateState.mailTemplateDetail.siteId === 0
      ) {
        setViewedBody(mailTemplateState.mailTemplateDetail.templateHtml);
      }
    }
  }, [mailTemplateState.mailTemplateDetail]);

  if (!mailTemplateState.mailTemplateDetail) return <></>; // optional 인쓰기 위해 early return 처리함

  // 기본 정보
  const basicInfo = (
    <BoxNew direction="column" classes="mg mt-8 ml-10 mr-10 pd pt-28 pb-28">
      {/* 시나리오 이름 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.scenarioName")}
          </Typography>
        </BoxNew>
        <BoxNew direction="column">
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            width={598}
          >
            {mailTemplateState.mailTemplateDetail.templateName}
          </Typography>
        </BoxNew>
      </BoxNew>
      {/* 훈련 시나리오 설명 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.scenarioDescription")}
          </Typography>
        </BoxNew>
        <BoxNew direction="column">
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            width={598}
          >
            {mailTemplateState.mailTemplateDetail.summary ||
              t("common.info.noData")}
          </Typography>
        </BoxNew>
      </BoxNew>
      {/* 이메일 제목 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.subject")}
          </Typography>
        </BoxNew>

        <BoxNew direction="column">
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            width={598}
          >
            {mailTemplateState.mailTemplateDetail.subject}
          </Typography>
        </BoxNew>
      </BoxNew>
      {/* 발신자 이름 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.senderName")}
          </Typography>
        </BoxNew>

        <BoxNew direction="column">
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            width={598}
          >
            {mailTemplateState.mailTemplateDetail.sendName}
          </Typography>
        </BoxNew>
      </BoxNew>
      {/* 발신자 이메일 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.senderEmail")}
          </Typography>
        </BoxNew>

        <BoxNew direction="column">
          {mailTemplateState.mailTemplateDetail.sendEmail}
        </BoxNew>
      </BoxNew>
    </BoxNew>
  );

  // 시나리오 콘텐츠
  const scenarioBody = (
    <BoxNew
      direction="column"
      classes="mg mt-10 ml-10 mr-10 pd pt-28 pb-28 detail-scenario"
    >
      {/* 이메일 내용 */}
      <BoxNew alignItems="center" classes="mg mb-24">
        <BoxNew width={220} classes="mg mr-28" height={"100%"}>
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.body")}
          </Typography>
        </BoxNew>

        <BoxNew direction="column">
          {viewedBody ? (
            <MailBody body={viewedBody} classes="email-body" />
          ) : (
            <Typography>{t("common.info.noData")}</Typography>
          )}
        </BoxNew>
      </BoxNew>

      {/* 훈련용 링크 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("link.link")}
          </Typography>
        </BoxNew>

        <BoxNew direction="column">
          {!mailTemplateState.mailTemplateDetail.siteId ||
          mailTemplateState.mailTemplateDetail.siteId === 0 ? (
            <Typography>{t("common.info.noData")}</Typography>
          ) : (
            <Tooltip message={mailTemplateState.mailTemplateDetail.siteUrl}>
              <Typography
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
                width={598}
              >
                {mailTemplateState.mailTemplateDetail.siteName}
              </Typography>
            </Tooltip>
          )}
        </BoxNew>
      </BoxNew>

      {/* 열람 확인용 이미지 */}
      <BoxNew alignItems="center" classes="mg mb-24">
        <BoxNew width={220} classes="mg mr-28" height={"100%"}>
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("image.image")}
          </Typography>
        </BoxNew>

        {mailTemplateState.mailTemplateDetail.imageOrgName ? (
          <PlainToggle>
            <PlainToggle.Trigger>
              {mailTemplateState.mailTemplateDetail.imageOrgName}
            </PlainToggle.Trigger>
            <PlainToggle.Content>
              <BoxNew classes="img-body">
                <img
                  src={mailTemplateState.mailTemplateDetail.imagePath}
                  style={{ maxWidth: "200px" }}
                />
              </BoxNew>
            </PlainToggle.Content>
          </PlainToggle>
        ) : (
          <Typography>{t("common.info.noData")}</Typography>
        )}
      </BoxNew>

      {/* 훈련용 첨부 파일 */}
      <BoxNew alignItems="center" classes="mg mb-24" height={40}>
        <BoxNew width={220} classes="mg mr-28">
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("attach.attach")}
          </Typography>
        </BoxNew>
        <BoxNew direction="column">
          {!mailTemplateState.mailTemplateDetail.documentId ||
          mailTemplateState.mailTemplateDetail.documentId === 0 ? (
            <Typography>{t("common.info.noData")}</Typography>
          ) : (
            <Tooltip
              message={mailTemplateState.mailTemplateDetail.documentOrgName}
            >
              <Typography
                type="body2_rg"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
                width={598}
              >
                {mailTemplateState.mailTemplateDetail.documentName}
              </Typography>
            </Tooltip>
          )}
        </BoxNew>
      </BoxNew>

      {/* 시나리오 카테고리*/}
      <BoxNew classes="mg mb-24">
        <BoxNew width={220} classes="mg mr-28 mt-8" direction="column">
          <Typography
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
            classes="mg mb-4"
          >
            {t("category.categorySimple")}
          </Typography>
          <Typography
            type="body4_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("template.msg.categoryGuideMsg")}
          </Typography>
        </BoxNew>
        <CategoryDropdown
          dropdownList={categoryList}
          searchword={categoryInput}
          updateSearchword={(searchword: string) => {
            setCategoryInput(searchword);
          }}
          onCategoryChangeCb={updateScenarioCategories}
          initialSelectedChips={selectedCategoryList}
          updateSelectedChips={(selectedChips) =>
            setSelectedCategoryList(selectedChips)
          }
          width={"362px"}
        />
      </BoxNew>
    </BoxNew>
  );

  return (
    <BoxNew classes="sticky-layout">
      <BoxNew ref={sentinel}>
        <BoxNew
          classes={classNames("header", { sticky: isAtTop })}
          ref={elementRef}
        >
          {/* 타이틀 */}
          <BoxNew direction="column">
            <TitlePath
              path={[t("menu.trainingManagement"), t("menu.template")]}
            />
            <BoxNew alignItems="center" classes="pd pt-8">
              <ButtonGoBack
                referer={`${process.env.PUBLIC_URL}/manageTrain/scenario`}
              />
              <Typography type="h1">{t("template.detail")}</Typography>
            </BoxNew>
          </BoxNew>
          {/* 버튼 */}
          <BoxNew classes="gap-8">
            <Button
              label={t("template.sendTest")}
              btnStyle="fill"
              type="secondary"
              onClick={() => setIsTestSendMailModalOpen(true)}
              leadingIcon={
                <Icons
                  variant="line"
                  label="mail"
                  stroke={getColor("COLOR_ICON_PRIMARY")}
                />
              }
              size="lg"
            />
            {checkIfDataEditableByCommonId(
              mailTemplateState.mailTemplateDetail?.commonId
            ) && (
              <>
                <Button
                  label={t("common.button.update")}
                  btnStyle="fill"
                  type="secondary"
                  onClick={() =>
                    navigate(`/manageTrain/scenario/update/${templateId}`)
                  }
                  leadingIcon={<Icons variant="line" label="edit" />}
                  size="lg"
                />
                <Button
                  label={t("common.button.delete")}
                  btnStyle="fill"
                  type="error"
                  onClick={() => setIsDeleteModalOpen(true)}
                  leadingIcon={
                    <Icons
                      variant="line"
                      label="delete"
                      stroke={getColor("COLOR_ICON_ERROR")}
                    />
                  }
                  size="lg"
                />
              </>
            )}
          </BoxNew>
        </BoxNew>
      </BoxNew>

      <BoxNew classes="body">
        <AccordionsNew
          label={t("quiz.label.basicInfo")}
          initialOpenStatus={true}
        >
          {basicInfo}
        </AccordionsNew>
        <AccordionsNew
          label={t("template.scenarioContents")}
          initialOpenStatus={true}
        >
          {scenarioBody}
        </AccordionsNew>
      </BoxNew>

      {isDeleteModalOpen && (
        <Modal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title={t("template.delete")}
          width={540}
          footer={
            <BoxNew justifyContent="flex-end" width="100%">
              <Button
                btnStyle="fill"
                type="secondary"
                onClick={() => setIsDeleteModalOpen(false)}
                label={t("common.button.cancel")}
              />
              <Button
                btnStyle="fill"
                type="error"
                classes="mg ml-8"
                onClick={handleDelete}
                label={t("common.button.delete")}
              />
            </BoxNew>
          }
          body={
            <Typography
              type="body2_rg"
              exactColor={getColor("COLOR_TEXT_SECONDARY")}
            >
              {mailTemplateState.mailTemplateDetail?.useConfig &&
              parseInt(mailTemplateState.mailTemplateDetail?.useConfig) > 0
                ? t("template.msg.templateDeleteUsedTemplateMsg")
                : t("common.msg.deleteConfirmMsg")}
            </Typography>
          }
        />
      )}

      <Modal
        open={isTestSendMailModalOpen}
        onClose={closeTestSendMailModal}
        title={t("template.sendTest")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" width="100%">
            <Button
              btnStyle="fill"
              type="secondary"
              onClick={closeTestSendMailModal}
              label={t("common.button.cancel")}
            />
            <Button
              btnStyle="fill"
              type="primary"
              classes="mg ml-8"
              onClick={handleTestSend}
              label={t("common.button.check")}
              state={"default"}
            />
          </BoxNew>
        }
        body={
          <BoxNew direction="column">
            <Typography type="subtitle2" classes="mg mb-6">
              {t("common.auth.email")}
            </Typography>
            <InputField
              placeholder={t("template.msg.templateSendTestPlaceholder")}
              size="md"
              value={testEmailForm.email}
              onChange={(e) => {
                setTestEmailForm({
                  email: e.target.value,
                  errMsg: "",
                });
              }}
              isError={testEmailForm.errMsg !== ""}
              helpMessage={
                testEmailForm.errMsg !== ""
                  ? t("common.msg.invalidEmailMsg")
                  : ""
              }
            />
          </BoxNew>
        }
      />
    </BoxNew>
  );
};

export default DetailScenario;
