import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface ObjectType {
  [key: string]: any;
}

// 페이지 이동 시 전달할 값을 window.history에 담아 전달하는 훅
export default function useNavigateState<T extends ObjectType>(
  stateKey: keyof T
) {
  const location = useLocation();
  const navigate = useNavigate();
  const [navigateState, setNavigateState] = useState<any>(null);

  useEffect(() => {
    if (location.state && location.state[stateKey]) {
      setNavigateState(location.state[stateKey]);
      window.history.replaceState({}, document.title);
    }
  }, [location, stateKey]);

  const navigateWithState = (path: string, value: any) => {
    const state = { [stateKey]: value };
    navigate(path, { state });
  };

  return {
    navigateState,
    navigateWithState,
  };
}
