import {
  Box,
  Button,
  getColor,
  Icons,
  Modal,
  Tag,
  Typography,
  useToast,
  Table as TableNew,
  Tooltip,
} from "fds";

import useContentStore from "../../../redux/dispatcher/useContentStore";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SiteResourceBean } from "../../../types/Info";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { nanoid } from "@reduxjs/toolkit";
import RegitOrEditResourceModal from "./RegitOrEditResourceModal";
import CommonModal from "../../../component/CommonModal";
import { useTranslation } from "react-i18next";
import NoInfo from "../../../component/NoInfo";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import { useElementPosition } from "../../../hooks/useElementPosition";
import useTimedReset from "../../../hooks/useTimedReset";
import { TABLE_HIGHLIGHT_TIMED_OUT } from "../../../shared/constant";
import { checkIfDataEditableByCommonId } from "../../../shared/utils/visibilityUtils";

interface ResourceListModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalType: "LIST" | "DELETE" | "INSERT" | "NONE";
  setModalType: Dispatch<SetStateAction<"LIST" | "DELETE" | "INSERT" | "NONE">>;
}

const ResourceListModal: React.FC<ResourceListModalProps> = ({
  isOpen,
  onClose,
  modalType,
  setModalType,
}) => {
  const { t, i18n } = useTranslation();
  const { contentState, getSiteResource, deleteSiteResource } =
    useContentStore();
  const [resources, setResources] = useState<SiteResourceBean[]>([]);
  const checkboxGroup = useCheckboxGroup<number>();
  const localCustomerCode = getLocalCustomerCode();

  const toast = useToast();

  const [selectedResourceId, setSelectedResourceId] = useState<number>(0);
  const [createdImgLinkId, setCreatedImgLinkId] = useTimedReset(
    "",
    TABLE_HIGHLIGHT_TIMED_OUT
  );

  useEffect(() => {
    getSiteResource();
    if (modalType !== "DELETE") {
      checkboxGroup.handleAllClick([]);
    }
  }, [isOpen, createdImgLinkId]);

  useEffect(() => {
    setResources(contentState.siteResources);
  }, [contentState.siteResources]);

  const onCopy = (url: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          toast.toastMsg(nanoid(), t("resource.msg.copy"), "success");
        })
        .catch((err) => {
          toast.toastMsg(nanoid(), t("resource.msg.copyFail"), "error");
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast.toastMsg(nanoid(), t("resource.msg.copy"), "success");
    }
  };

  const onDelete = async () => {
    const resp: any = await deleteSiteResource({
      request: {
        resourceIds: selectedResourceId
          ? [selectedResourceId]
          : (checkboxGroup.selected as number[]),
      },
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("resource.msg.deleteSuccessMsg"), "success");
    } else {
      toast.toastMsg(nanoid(), t("resource.msg.deleteFailMsg"), "error");
    }

    checkboxGroup.handleAllClick([]);
    setSelectedResourceId(0);
    setModalType("LIST");
  };

  const preventBubbling = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const { targetRef: tableContainerRef } = useElementPosition();

  function transformData(data: SiteResourceBean[]) {
    const dataTransformed = [];

    for (let i = 0; i < data.length; i++) {
      const imgInfo = data[i];
      const dataType = localCustomerCode ? (
        imgInfo.commonId ? (
          <Tag
            as={"p"}
            type={"box"}
            text={t("template.public")}
            textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
            backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
          />
        ) : (
          <Tag
            as={"p"}
            type={"box"}
            text={t("template.private")}
            textColor={getColor("COLOR_AVATAR_PINK_TEXT")}
            backgroundColor={getColor("COLOR_AVATAR_PINK_BG")}
          />
        )
      ) : (
        <Tag
          as={"p"}
          type={"box"}
          text={t("template.public")}
          textColor={getColor("COLOR_AVATAR_LIGHT_BLUE_TEXT", "blue")}
          backgroundColor={getColor("COLOR_AVATAR_LIGHT_BLUE_BG", "blue")}
        />
      );
      const dataResourceName = (
        <Tooltip message={imgInfo.resourceName}>
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            ellipsis
          >
            {imgInfo.resourceName}
          </Typography>
        </Tooltip>
      );
      const dataUrl = (
        <Tooltip message={imgInfo.resourceUrl}>
          <Typography
            type="body2_rg"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            ellipsis
          >
            {imgInfo.resourceUrl}
          </Typography>
        </Tooltip>
      );

      const hoverBtn = (
        <Box>
          <Button
            btnStyle={"icon"}
            leadingIcon={<Icons variant="line" label="copy" />}
            type={"primary"}
            size={"sm"}
            state={"hover"}
            onClick={(e) => {
              preventBubbling(e);
              onCopy(imgInfo.resourceUrl);
            }}
          />
          {checkIfDataEditableByCommonId(imgInfo.commonId) && (
            <>
              <Button
                btnStyle={"icon"}
                leadingIcon={
                  <Icons variant="line" label="edit2" classes="mg mr-4" />
                }
                type={"primary"}
                size={"sm"}
                state={"hover"}
                onClick={(e) => {
                  preventBubbling(e);
                  if (imgInfo.commonId) {
                    toast.toastMsg(
                      nanoid(),
                      t("common.msg.cannotDeletePublicContentsMsg"),
                      "error"
                    );
                    return;
                  }
                  setSelectedResourceId(imgInfo.resourceId);
                  setModalType("INSERT");
                }}
              />
              <Button
                btnStyle={"icon"}
                leadingIcon={
                  <Icons variant="line" label="delete" classes="mg mr-4" />
                }
                type={"primary"}
                size={"sm"}
                state={"hover"}
                onClick={(event) => {
                  preventBubbling(event);
                  let commonFlag = false;
                  if (commonFlag) {
                    toast.toastMsg(
                      nanoid(),
                      t("common.msg.cannotDeletePublicContentsMsg"),
                      "error"
                    );
                    return;
                  }
                  setSelectedResourceId(imgInfo.resourceId);
                  setModalType("DELETE");
                }}
              />
            </>
          )}
        </Box>
      );

      const dataItemTransformed = {
        data: [
          { columnText: dataType },
          { columnText: dataResourceName },
          { columnText: dataUrl },
        ],
        id: String(imgInfo.resourceId),
        hover: hoverBtn,
        checkBoxClick: () => {
          checkboxGroup.handleClick(imgInfo.resourceId);
        },
      };
      dataTransformed.push(dataItemTransformed);
    }
    return dataTransformed;
  }

  function checkTableRenderStatus() {
    const hasResult = resources && resources.length > 0;
    if (!hasResult) return 1;
    else return 0;
  }

  function renderTableSwitch() {
    switch (checkTableRenderStatus()) {
      case 1:
        return noItemCreated;
      default:
        return <></>;
    }
  }

  const noItemCreated = <NoInfo />;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={t("resource.phishingInfoImageManage")}
        width={1000}
        subTitle={t("resource.msg.phishingInfoImageInfoMsg")}
        footer={
          <Box width="100%" justifyContent="flex-end">
            <Button
              btnStyle="fill"
              type="secondary"
              onClick={onClose}
              label={t("common.button.cancel")}
            />
          </Box>
        }
        body={
          <Box
            width="100%"
            direction="column"
            style={{ width: "100%" }}
            ref={tableContainerRef}
          >
            <Box justifyContent="space-between" width="100%" classes="gap-4">
              <Box alignItems="center">
                <Typography
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("resource.imageLink")}
                </Typography>
                <Typography
                  classes="mg ml-8"
                  type="subtitle2"
                  exactColor={getColor("COLOR_TEXT_BRAND")}
                >
                  {resources.length}
                </Typography>
              </Box>
              <Box>
                <Button
                  btnStyle="link"
                  type="secondary"
                  leadingIcon={
                    <Icons
                      variant="line"
                      label="plusCircle"
                      classes="mg mr-1"
                    />
                  }
                  onClick={() => {
                    setModalType("INSERT");
                  }}
                  label={t("common.button.add")}
                />
                <Button
                  btnStyle="link"
                  type="secondary"
                  leadingIcon={
                    <Icons variant="line" label="delete" classes="mg mr-1" />
                  }
                  state={
                    checkboxGroup.selected.length > 0 ? "default" : "disable"
                  }
                  onClick={() => {
                    let commonFlag = false;
                    checkboxGroup.selected.map((c) => {
                      const item: SiteResourceBean[] = resources.filter(
                        (t) => t.resourceId === c
                      );

                      item.map((i) => {
                        if (i.commonId) {
                          commonFlag = true;
                        }
                      });
                    });

                    if (commonFlag) {
                      toast.toastMsg(
                        nanoid(),
                        t("common.msg.cannotDeletePublicContentsMsg"),
                        "error"
                      );
                      return;
                    }
                    setModalType("DELETE");
                  }}
                  label={t("common.button.delete")}
                />
              </Box>
            </Box>
            <Box direction="column" classes="pd pt-12 flex-1">
              <TableNew
                border="none"
                clickIds={checkboxGroup.selected.map((siteId) =>
                  String(siteId)
                )}
                highlightIds={[createdImgLinkId]}
                columnHeight="md"
                data={transformData(resources)}
                endReached={() => {}}
                header={[
                  {
                    headerText: t("template.type"),
                    width: "88px",
                  },
                  {
                    headerText: t("link.linkNameSimple"),
                    width: "300px",
                  },
                  {
                    headerText: t("link.linkUrl"),
                    width: "calc(100% - 450px)",
                  },
                ]}
                checkBoxClick={() =>
                  checkboxGroup.handleAllClick(
                    checkboxGroup.selected.length === resources.length
                      ? []
                      : resources.map((q) => q.resourceId)
                  )
                }
                width={"100%"}
                height={checkTableRenderStatus() === 0 ? "400px" : "0px"}
              />
              {renderTableSwitch()}
            </Box>
          </Box>
        }
      />
      <CommonModal
        zIndex={1000}
        open={modalType === "DELETE"}
        onClose={() => {
          setModalType("LIST");
          setSelectedResourceId(0);
        }}
        title={t("resource.phishingInfoImageDelete")}
        subMessage={t("resource.msg.deleteConfirmMsg")}
        type="delete"
        afterSubmitButton={onDelete}
      />
      <RegitOrEditResourceModal
        isOpen={modalType == "INSERT"}
        onClose={() => (
          setModalType("LIST"),
          setSelectedResourceId(0),
          checkboxGroup.handleAllClick([])
        )}
        resourceId={selectedResourceId > 0 ? selectedResourceId : undefined}
        setCreatedId={setCreatedImgLinkId}
      />
    </>
  );
};

export default ResourceListModal;
