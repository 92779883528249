import {
  Box as BoxNew,
  getColor,
  Typography as TypographyNew,
  Dropdown as DropdownNew,
  DropdownItem as DropdownItemNew,
  CalendarRange,
  Table as TableNew,
  Modal as ModalNew,
  Button as ButtonNew,
  useToast,
  Tooltip,
} from "fds";

import TitlePath from "../../component/TitlePath";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import useQuizStore from "../../redux/dispatcher/useQuizStore";
import dayjs from "dayjs";
import { QuizCourseDTO, QuizStatResDTO } from "../../types/Quiz";
import { nanoid } from "@reduxjs/toolkit";
import NoQuizImage from "../../shared/image/NoQuizImage";
import QuizStatUser from "../../shared/image/icon/QuizStatUser";
import QuizStatCompleteUser from "../../shared/image/icon/QuizStatCompleteUser";
import QuizStatPer from "../../shared/image/icon/QuizStatPer";

import "../../shared/style/filters.scss";
import utilsCommon from "../../shared/utils/common";

const QuizStat: React.FC = () => {
  const [buttonClick, setButtonClick] = useState<number>(0);
  const { t, i18n } = useTranslation();
  const toast = useToast();

  const [lineOptions, setLineOptions] = useState<any>([]);

  const [stat, setStat] = useState<QuizStatResDTO>();

  const [courseList, setCourseList] = useState<number[]>();

  const { getQuizStat, getQuizCourses, quizState } = useQuizStore();
  const [selectedPeriod, changeSelectedPeriod] = useState({
    value: "PERIOD_6MONTH",
    start: dayjs()
      .subtract(6, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const [selectedCourseId, setSelectedCourseId] = useState<string>("0"); // 0 - 전체, 그 외 - 차수

  const onSelectCourse = (id: number) => {
    setSelectedCourseId(id.toString());
    if (id === 0) {
      // 전체 조회
      getStatInfo();
    } else {
      getStatInfo([id]);
    }
  };

  const getStatInfo = async (courseIds?: number[]) => {
    const resp: any = await getQuizStat({
      startDate: selectedPeriod.start / 1000,
      endDate: selectedPeriod.end / 1000,
      intervalTime: 9,
      courseIds: courseIds ?? undefined,
    });

    if (resp.payload) {
      setStat(resp.payload.data);
    }
  };

  useEffect(() => {
    getStatInfo();
  }, [selectedPeriod]);

  useEffect(() => {
    getQuizCourses({});
  }, []);

  useEffect(() => {
    if (quizState.quizCourseList.list.length > 0) {
      setCourseList(
        quizState.quizCourseList.list.map((i: QuizCourseDTO) => i.courseId)
      );
    }
  }, [quizState.quizCourseList]);

  useEffect(() => {
    setLineOptions({
      chart: {
        width: document.getElementById("quizGraph")?.clientWidth ?? 682,
        height: 292,
      },
      title: {
        text: "",
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        margin: 30,
        itemStyle: {
          fontSize: "14px",
        },
      },
      xAxis: {
        categories:
          // quizStatInfo?.dateStat
          //   .map((item) => utilsCommon.getShortDate(item.date))
          //   .slice(0, 20)
          //   .reverse(),
          stat?.completedCourseStat.map((item) =>
            t("report.reportConfigId", { round: item.courseId })
          ),
        title: {
          // text: t("common.time.date"),
          align: "high",
          style: {
            fontSize: "14px",
          },
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
      },
      yAxis: {
        title: {
          text: t("program.programPeopleUnit"),
          align: "high",
          rotation: 0,
          labels: {
            style: {
              fontSize: "14px",
            },
          },
        },
        style: {
          fontSize: "14px",
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        tickInterval: 5,
      },
      plotOptions: {
        series: {
          allowPointSelect: false,
          events: {},
          states: {
            hover: {
              enabled: false, // 호버 상태 비활성화
            },
            inactive: {
              enabled: false, // 다른 시리즈 흐리게 처리 비활성화
            },
          },
        },
      },
      credits: { enabled: false },
      series: [
        {
          name: t("quiz.label.userCnt"),
          type: "column",
          data: stat?.completedCourseStat.map((item) => item.userCount),
          color: "#E9ECEF",
        },
        {
          name: t("quiz.label.completeUserCnt"),
          type: "spline",
          data: stat?.completedCourseStat.map((item) => item.completeUserCount),
          color: "#05C072",
        },
      ],
      tooltip: {
        // useHTML: true,
        // formatter: function (
        //   this: Highcharts.TooltipFormatterContextObject
        // ): string {
        //   console.log(this.series);
        //   const color = this.series.color; // 시리즈 색상
        //   const seriesName = this.series.name;
        //   const legendIcon =
        //     "<svg width='20' height='20'>" +
        //     (this.series as any).legendItem.group.element.outerHTML +
        //     "</svg>";

        //   return `
        //   <div>
        //     ${legendIcon}
        //     <b>${seriesName}</b>: ${this.y}<br/>
        //     Month: ${this.key}
        //   </div>`;
        // },
        shared: true,
        backgroundColor: "#4B596B", // 배경색 변경
        borderColor: "#4B596B", // 테두리 색 변경
        borderRadius: 6, // 둥근 모서리
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    });
  }, [i18n.language, stat]);

  const DATE_RESOURCE: { [key: string]: string } = {
    PERIOD_6MONTH: t("report.stats.reportRecent6Month"),
    PERIOD_1YEAR: t("report.stats.reportRecent1Year"),
    PERIOD_CUSTOM: t("graph.phishingCustomScope"),
  };
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const [openDateModal, toggleDateModal] = useState(false);

  const changeDateFilter = (type: string) => {
    if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(type === "PERIOD_6MONTH" ? 6 : 12, "month")
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const dateModal = () => {
    return (
      <ModalNew
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        width={540}
        footer={
          <BoxNew justifyContent="flex-end" classes="gap-8">
            <ButtonNew
              btnStyle="fill"
              type="secondary"
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              label={t("common.button.cancel")}
            />
            <ButtonNew
              btnStyle="fill"
              type="primary"
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              label={t("common.button.apply")}
            />
          </BoxNew>
        }
        body={
          <BoxNew
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <CalendarRange
              width={231}
              startDate={new Date(tempPeriod.start)}
              endDate={new Date(tempPeriod.end)}
              onSubmit={(date: any) => {
                changeTempPeriod({
                  ...tempPeriod,
                  start: dayjs(date.start)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs(date.end)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              maxDate={new Date()}
            />
          </BoxNew>
        }
      />
    );
  };

  function transformCompleteList(stats: QuizStatResDTO | undefined) {
    const completeList = stats?.lowCompletedUserStat || [];
    const completeListTransformed = [];
    for (let i = 0; i < completeList.length; i++) {
      const completeCur = completeList[i];

      const dataNum = (
        <TypographyNew
          type="body2_rg"
          exactColor={getColor("COLOR_TEXT_SECONDARY")}
        >
          {i + 1}
        </TypographyNew>
      );
      const dataUsername = (
        <Tooltip message={completeCur.userName}>
          <TypographyNew type="body2_rg" ellipsis={true}>
            {completeCur.userName}&nbsp;({completeCur.userEmail})
          </TypographyNew>
        </Tooltip>
      );
      const dataACompletePer = (
        <TypographyNew type="body2_rg" textAlign="end" classes="pd pr-12">
          {`${Math.floor(
            (completeCur.completeCount / completeCur.sendCount) * 100
          )} %`}
        </TypographyNew>
      );

      const completeTransformed = {
        data: [
          { columnText: dataNum },
          { columnText: dataUsername },
          { columnText: dataACompletePer },
        ],
        id: String(dataNum),
      };
      completeListTransformed.push(completeTransformed);
    }
    return completeListTransformed;
  }

  function checkIfDataNotExists() {
    const isDataNotFetched = !stat?.lowCompletedUserStat;
    const hasNoDataReturned =
      stat?.lowCompletedUserStat && stat?.lowCompletedUserStat.length === 0;

    if (isDataNotFetched || hasNoDataReturned) return true;
    else return false;
  }

  function renderTableSwitch() {
    if (checkIfDataNotExists()) {
      return (
        <BoxNew
          width={"100%"}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <NoQuizImage />
          <TypographyNew
            type="caption_rg"
            exactColor={getColor("COLOR_TEXT_SECONDARY")}
          >
            {t("quiz.msg.noItems")}
          </TypographyNew>
        </BoxNew>
      );
    }
  }

  return (
    <BoxNew classes="main">
      {openDateModal && dateModal()}
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizStat")]} />
          <TypographyNew
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            classes="mg mt-8"
          >
            {t("menu.quizStat")}
          </TypographyNew>
        </BoxNew>
      </BoxNew>
      {/* main start */}
      <BoxNew direction="column">
        <BoxNew classes="dropdown-filter-container mg mb-24">
          <DropdownNew
            btnStyle={"round"}
            btnSize={"md"}
            placeholder={t("quiz.label.period1")}
            value={
              selectedPeriod.value === "PERIOD_3MONTH"
                ? t("report.stats.reportRecent3Month")
                : selectedPeriod.value === "PERIOD_6MONTH"
                ? t("report.stats.reportRecent6Month")
                : selectedPeriod.value === "PERIOD_1MONTH"
                ? t("report.stats.reportRecent1Month")
                : selectedPeriod.value === "ALL"
                ? ""
                : `${utilsCommon.getShortDate(selectedPeriod.start)}
            - ${utilsCommon.getShortDate(selectedPeriod.end)}`
            }
            defaultValue={"PERIOD_6MONTH"}
            showValue
            selectValue={selectedPeriod.value}
            buttonClick={buttonClick}
            buttonWidth={224}
          >
            <DropdownItemNew
              checked={selectedPeriod.value === "PERIOD_3MONTH"}
              checkColor={selectedPeriod.value === "PERIOD_3MONTH"}
              onClick={() => {
                changeDateFilter("PERIOD_3MONTH");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("report.stats.reportRecent3Month")}
              type={"check"}
            />
            <DropdownItemNew
              checked={selectedPeriod.value === "PERIOD_6MONTH"}
              checkColor={selectedPeriod.value === "PERIOD_6MONTH"}
              onClick={() => {
                changeDateFilter("PERIOD_6MONTH");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("report.stats.reportRecent6Month")}
              type={"check"}
            />
            <DropdownItemNew
              checked={selectedPeriod.value === "PERIOD_1YEAR"}
              checkColor={selectedPeriod.value === "PERIOD_1YEAR"}
              onClick={() => {
                changeDateFilter("PERIOD_1YEAR");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              isFullWidth
              label={t("report.stats.reportRecent1Year")}
              type={"check"}
            />
            <DropdownItemNew
              label=""
              onClick={() => {}}
              state="divider"
              type="default"
            />
            <DropdownItemNew
              checked={selectedPeriod.value === "PERIOD_CUSTOM"}
              checkColor={selectedPeriod.value === "PERIOD_CUSTOM"}
              onClick={() => {
                changeDateFilter("PERIOD_CUSTOM");
              }}
              isFullWidth
              label={t("graph.phishingCustomScope")}
              type={"check"}
            />
          </DropdownNew>
        </BoxNew>

        <BoxNew>
          {/* left start  */}
          <BoxNew direction="column" width={"66%"}>
            {/* card start */}
            <BoxNew
              justifyContent="space-between"
              width={"100%"}
              classes="dashboard__boxes"
            >
              <BoxNew classes="dashboard__box blue__box">
                <BoxNew direction="column">
                  <TypographyNew
                    type="h1"
                    exactColor={getColor("COLOR_TEXT_INVERSE")}
                  >
                    {stat?.totalUserCount ? stat?.totalUserCount : "0"}
                  </TypographyNew>
                  <TypographyNew
                    type={"body1_rg"}
                    exactColor={getColor("COLOR_TEXT_INVERSE")}
                  >
                    {t("quiz.label.avgUser")}
                  </TypographyNew>
                </BoxNew>
                <QuizStatUser />
              </BoxNew>
              <BoxNew classes="dashboard__box green__box">
                <BoxNew direction="column">
                  <TypographyNew
                    type="h1"
                    exactColor={getColor("COLOR_TEXT_INVERSE")}
                  >
                    {stat?.totalCompletedUserCount
                      ? stat?.totalCompletedUserCount
                      : `0`}
                  </TypographyNew>
                  <TypographyNew
                    type={"body1_rg"}
                    exactColor={getColor("COLOR_TEXT_INVERSE")}
                  >
                    {t("quiz.label.avgComplet")}
                  </TypographyNew>
                </BoxNew>
                <QuizStatCompleteUser />
              </BoxNew>
              <BoxNew classes="dashboard__box purple__box">
                <BoxNew direction="column">
                  <TypographyNew
                    type="h1"
                    exactColor={getColor("COLOR_TEXT_INVERSE")}
                  >
                    {stat?.completedPer ? `${stat?.completedPer}%` : `N/A`}
                  </TypographyNew>
                  <TypographyNew
                    type={"body1_rg"}
                    exactColor={getColor("COLOR_TEXT_INVERSE")}
                  >
                    {t("quiz.label.avgRate")}
                  </TypographyNew>
                </BoxNew>
                <QuizStatPer />
              </BoxNew>
            </BoxNew>
            {/* card end */}

            {/* graph start */}
            <BoxNew classes="mg mt-40" width={"100%"} direction="column">
              <BoxNew classes="mg mb-28">
                <TypographyNew
                  type="subtitle1"
                  exactColor={getColor("COLOR_TEXT_PRIMARY")}
                >
                  {t("quiz.label.quizRates")}
                </TypographyNew>
              </BoxNew>
              <BoxNew id={"quizGraph"}>
                {!stat?.completedCourseStat ? (
                  <BoxNew
                    width={"100%"}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NoQuizImage />
                    <TypographyNew
                      type="caption_rg"
                      exactColor={getColor("COLOR_TEXT_SECONDARY")}
                    >
                      {t("quiz.msg.noItems")}
                    </TypographyNew>
                  </BoxNew>
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={lineOptions}
                  />
                )}
              </BoxNew>
            </BoxNew>
            {/* graph end  */}
          </BoxNew>
          {/* left end  */}

          {/* right start  */}
          <BoxNew classes="mg ml-32" width={"33%"} direction="column">
            <BoxNew classes="mg mb-8" justifyContent="space-between">
              <TypographyNew
                type="subtitle1"
                exactColor={getColor("COLOR_TEXT_PRIMARY")}
              >
                {t("quiz.label.lowCompletTop10")}
              </TypographyNew>
              <DropdownNew
                btnStyle="link"
                btnSize="md"
                placeholder={t("quiz.label.all")}
                value={
                  parseInt(selectedCourseId) === 0
                    ? t("quiz.label.all")
                    : t("report.reportConfigId", { round: selectedCourseId })
                }
                buttonClick={buttonClick}
                buttonWidth={224}
                align={"right"}
                dropdownMaxHeight={276}
              >
                <DropdownItemNew
                  checked={parseInt(selectedCourseId) === 0}
                  checkColor={parseInt(selectedCourseId) === 0}
                  onClick={() => {
                    onSelectCourse(0);
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                  isFullWidth
                  label={t("quiz.label.all")}
                  type={"check"}
                />
                {courseList?.length &&
                  courseList.map((round: number) => (
                    <DropdownItemNew
                      key={`courseList-dropdown-${round}`}
                      checked={round === parseInt(selectedCourseId)}
                      checkColor={round === parseInt(selectedCourseId)}
                      onClick={() => {
                        onSelectCourse(round);
                        setButtonClick(
                          buttonClick > 99999 ? 1 : buttonClick + 1
                        );
                      }}
                      isFullWidth
                      label={t("report.reportConfigId", { round: round })}
                      type={"check"}
                    />
                  ))}
              </DropdownNew>
            </BoxNew>
            <BoxNew direction="column">
              <TableNew
                border="none"
                columnHeight="md"
                clickIds={[]}
                data={transformCompleteList(stat)}
                endReached={() => {}}
                header={[
                  {
                    headerText: "",
                    width: "30px",
                  },
                  {
                    headerText: `${t("quiz.label.userName")} (${t(
                      "quiz.label.email"
                    )})`,
                    width: "calc(100% - 50px)",
                  },
                  {
                    headerText: t("quiz.label.rate"),
                    width: "120px",
                  },
                ]}
                height={checkIfDataNotExists() ? "100px" : "530px"}
              />
              {renderTableSwitch()}
            </BoxNew>
          </BoxNew>
          {/* right end  */}
        </BoxNew>
      </BoxNew>
      {/* main end */}
    </BoxNew>
  );
};

export default QuizStat;
