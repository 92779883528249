import {
  Modal as ModalNew,
  Box as BoxNew,
  Button as ButtonNew,
  Typography as TypographyNew,
  Radio as RadioNew,
  InputField,
  useToast,
} from "fds";

import { ReportReply, ReportReplyUpdateBean } from "../../../types/Reply";
import { useEffect, useState } from "react";
import useReplyStore from "../../../redux/dispatcher/useReplyStore";
import { nanoid } from "@reduxjs/toolkit";
import WSEdit from "../../../component/WSEdit";
import { useTranslation } from "react-i18next";
interface RegitOrEditTemplateModalProps {
  isOpen: boolean;
  targetId?: number;
  type: "CLOSE" | "CREATE" | "EDIT";
  onClose: () => void;
  setReplayTemplateId: (templateId: string) => void;
}
const RegitOrEditTemplateModal: React.FC<RegitOrEditTemplateModalProps> = ({
  isOpen,
  type,
  targetId,
  onClose,
  setReplayTemplateId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { replyState, getReplys, insertReply, updateReply } = useReplyStore();
  const [body, changeBody] = useState("");
  const [targetReply, changeReply] = useState<ReportReplyUpdateBean>(
    type === "EDIT"
      ? replyState.replyListWithCnt?.list?.filter(
          (item: ReportReply) => item.replyId + "" === type
        )[0]
      : {
          autoYn: false,
          replyBody: "<p></p>",
          replyTitle: "",
          updateUser: "admin",
        }
  );
  useEffect(() => {
    changeReply(
      type === "EDIT"
        ? replyState?.replyListWithCnt?.list?.filter(
            (item: ReportReply) => item.replyId === targetId
          )[0]
        : {
            autoYn: false,
            replyBody: "",
            replyTitle: "",
            updateUser: "admin",
          }
    );
    changeBody(
      type === "EDIT"
        ? replyState?.replyListWithCnt?.list?.filter(
            (item: ReportReply) => item.replyId === targetId
          )[0].replyBody
        : ""
    );
  }, [type, targetId]);
  const handleRegitOrEditTemplate = async () => {
    let resp: any =
      type === "CREATE"
        ? await insertReply({ ...targetReply, replyBody: body })
        : await updateReply({ ...targetReply, replyBody: body });

    if (type === "CREATE") {
      const idCreated = resp.payload.data?.replyId || "";
      setReplayTemplateId(idCreated.toString());
    }
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        type === "CREATE"
          ? t("reply.msg.reportReplyAddSuccessMsg")
          : t("reply.msg.reportReplyUpdateSuccessMsg"),
        "success"
      );
      getReplys({});
    } else {
      toast.toastMsg(
        nanoid(),
        type === "CREATE" ? "추가하지 못했습니다." : "수정하지 못했습니다.",
        "error"
      );
    }
    onClose();
  };

  return (
    <ModalNew
      open={isOpen}
      onClose={onClose}
      width={1200}
      title={
        type === "CREATE"
          ? t("reply.reportReplyAdd")
          : t("reply.reportReplyUpdate")
      }
      body={
        <BoxNew direction="column" width={"100%"}>
          <BoxNew justifyContent="space-between">
            <BoxNew direction="column" width={"48%"}>
              <TypographyNew type="subtitle2">
                {t("reply.reportTemplateName")}
              </TypographyNew>
              <InputField
                placeholder="답변 템플릿 이름을 입력하세요."
                classes="mg mt-12"
                size={"sm"}
                value={targetReply?.replyTitle}
                onChange={(e) =>
                  changeReply({ ...targetReply, replyTitle: e.target.value })
                }
              />
            </BoxNew>
            <BoxNew direction="column" width={"48%"}>
              <TypographyNew type="subtitle2">
                {t("reply.reportReplyType")}
              </TypographyNew>
              <BoxNew classes="mg mt-16" alignItems="center">
                <RadioNew
                  label={t("reply.reportReplyAuto")}
                  check={targetReply?.autoYn}
                  onClick={() =>
                    changeReply({
                      ...targetReply,
                      autoYn: true,
                    })
                  }
                  disable={
                    type == "EDIT" &&
                    targetReply &&
                    targetReply.replyTargetType !== 0
                      ? true
                      : false
                  }
                />
                <RadioNew
                  classes="mg ml-24"
                  label={t("reply.reportReplyManual")}
                  check={!targetReply?.autoYn}
                  onClick={() =>
                    changeReply({
                      ...targetReply,
                      autoYn: false,
                    })
                  }
                  disable={
                    type == "EDIT" &&
                    targetReply &&
                    targetReply.replyTargetType !== 0
                      ? true
                      : false
                  }
                />
              </BoxNew>
            </BoxNew>
          </BoxNew>
          <BoxNew classes="mg mt-20">
            <WSEdit
              initBody={targetReply?.replyBody}
              isShowButtons={false}
              onChange={changeBody}
            />
          </BoxNew>
        </BoxNew>
      }
      footer={
        <BoxNew justifyContent="flex-end">
          <ButtonNew
            type="secondary"
            btnStyle="fill"
            onClick={onClose}
            label={t("common.button.cancel")}
          />

          <ButtonNew
            type="primary"
            btnStyle="fill"
            onClick={() => handleRegitOrEditTemplate()}
            classes="mg ml-10"
            state={targetReply?.replyTitle ? "default" : "disable"}
            label={
              type === "CREATE"
                ? t("common.button.save")
                : t("common.button.save")
            }
          />
        </BoxNew>
      }
    />
  );
};

export default RegitOrEditTemplateModal;
