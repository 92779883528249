import { Box, Icons, Typography } from "fds";
import { createContext, useContext, useState, ReactNode } from "react";

interface ToggleContextType {
  isOpen: boolean;
  toggle: () => void;
}

const ToggleContext = createContext<ToggleContextType | undefined>(undefined);

const useToggleContext = () => {
  const context = useContext(ToggleContext);
  if (!context) {
    throw new Error(
      "Toggle compound components must be used within PlainToggle"
    );
  }
  return context;
};

interface PlainToggleProps {
  children: ReactNode;
}

const PlainToggle = ({ children }: PlainToggleProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <ToggleContext.Provider value={{ isOpen, toggle }}>
      <Box direction="column">{children}</Box>
    </ToggleContext.Provider>
  );
};

const Trigger = ({ children }: { children: ReactNode }) => {
  const { isOpen, toggle } = useToggleContext();

  return (
    <Box onClick={toggle} style={{ cursor: "pointer" }}>
      <Typography>{children}</Typography>
      <Box>
        <Icons
          variant="line"
          label="chevron"
          classes="mg ml-4"
          direction={isOpen ? "up" : "down"}
        />
      </Box>
    </Box>
  );
};

interface ContentProps {
  children: ReactNode;
}

const Content = ({ children }: ContentProps) => {
  const { isOpen } = useToggleContext();

  if (!isOpen) return null;

  return <Box>{children}</Box>;
};

PlainToggle.Trigger = Trigger;
PlainToggle.Content = Content;

export default PlainToggle;
