import {
  Box as BoxNew,
  Button as ButtonNew,
  getColor,
  InputField,
  Typography as TypographyNew,
  useToast,
} from "fds";

import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuizTemplateDTO } from "../../../types/Quiz";
import WSEdit from "../../../component/WSEdit";
import { useNavigate } from "react-router-dom";
import utilsCommon from "../../../shared/utils/common";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { nanoid } from "@reduxjs/toolkit";
import { useElementPosition } from "../../../hooks/useElementPosition";

const WriteQuizTemplate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [type, changeType] = useState<"CREATE" | "UPDATE">("CREATE");

  const [body, setBody] = useState<string>("");
  const [initBody, setInitBody] = useState<string>("");

  const { quizState, insertQuizTemplate, getQuizTemplate, updateQuizTemplate } =
    useQuizStore();

  const [templateInfo, setTemplateInfo] = useState<QuizTemplateDTO>({
    templateName: "",
    subject: "",
    sendName: "",
    sendEmail: "",
  });

  const { targetRef: heightFixedRef, position: heightFixedPosition } =
    useElementPosition();

  useEffect(() => {
    getQuizTemplate();
  }, []);

  useEffect(() => {
    if (quizState.quizTemplate) {
      setTemplateInfo(quizState.quizTemplate);
      setInitBody(quizState.quizTemplate.body);
      changeType("UPDATE");
    }
  }, [quizState.quizTemplate]);

  const toast = useToast();

  const insertTemplate = async () => {
    if (templateInfo) {
      const bodyFile = utilsCommon.generateHtmlFileFromString(
        templateInfo.templateName,
        body
      );

      const resp: any = await insertQuizTemplate({
        file: bodyFile,
        request: {
          templateName: templateInfo.templateName,
          subject: templateInfo.subject,
          sendEmail: templateInfo.sendEmail,
          sendName: templateInfo.sendName,
        },
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("template.msg.templateAddSuccessMsg"),
          "success"
        );
      } else {
        console.log("error..");
      }
    }
  };

  const updateTemplate = async () => {
    if (templateInfo) {
      const bodyFile = utilsCommon.generateHtmlFileFromString(
        templateInfo.templateName,
        body
      );

      const resp: any = await updateQuizTemplate({
        file: bodyFile,
        request: {
          templateName: templateInfo.templateName,
          subject: templateInfo.subject,
          sendEmail: templateInfo.sendEmail,
          sendName: templateInfo.sendName,
          templateId: templateInfo.templateId,
        },
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(nanoid(), t("quiz.msg.updateMsg"), "success");
        navigate(`${process.env.PUBLIC_URL}/quiz/template`);
      } else {
        console.log("error..");
      }
    }
  };

  return (
    <BoxNew classes="main" ref={heightFixedRef}>
      <BoxNew
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <BoxNew direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizTemplate")]} />
          <TypographyNew
            type="h1"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
            classes="mg mt-8"
          >
            {type === "UPDATE"
              ? t("quiz.label.editQuizMailTemp")
              : t("template.add")}
          </TypographyNew>
        </BoxNew>
      </BoxNew>

      <BoxNew classes="mg mt-16" direction="column">
        <BoxNew width="100%" justifyContent="space-between">
          <BoxNew direction="column" width="calc(100%/3)" classes="mg mr-24">
            <TypographyNew
              classes="pd pb-12"
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("template.subject")}
            </TypographyNew>
            <InputField
              size="md"
              value={templateInfo.subject ? templateInfo.subject : ""}
              placeholder={t("template.msg.subjectPlaceholder")}
              onChange={(e: any) =>
                setTemplateInfo((prev) => {
                  return { ...prev, subject: e.target.value };
                })
              }
              maxLength={255}
            />
          </BoxNew>

          <BoxNew direction="column" width="calc(100%/3)" classes="mg mr-24">
            <TypographyNew
              classes="pd pb-12"
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("template.senderName")}
            </TypographyNew>
            <InputField
              size="md"
              value={templateInfo.sendName ? templateInfo.sendName : ""}
              placeholder={t("template.msg.senderNamePlaceholder")}
              onChange={(e: any) =>
                setTemplateInfo((prev) => {
                  return { ...prev, sendName: e.target.value };
                })
              }
              maxLength={128}
            />
          </BoxNew>
          <BoxNew direction="column" width="calc(100%/3)">
            <TypographyNew
              classes="pd pb-12"
              type="subtitle2"
              exactColor={getColor("COLOR_TEXT_PRIMARY")}
            >
              {t("template.senderEmail")}
            </TypographyNew>

            <InputField
              size="md"
              value={templateInfo.sendEmail ? templateInfo.sendEmail : ""}
              placeholder={t("template.msg.senderEmailPlaceholder")}
              onChange={(e: any) =>
                setTemplateInfo((prev) => {
                  return { ...prev, sendEmail: e.target.value };
                })
              }
              maxLength={128}
            />
          </BoxNew>
        </BoxNew>

        {/* editor start */}
        <BoxNew classes="mg mt-28" direction="column">
          <TypographyNew
            classes="pd pb-12"
            type="subtitle2"
            exactColor={getColor("COLOR_TEXT_PRIMARY")}
          >
            {t("template.body")}
          </TypographyNew>
          <BoxNew
            // height={"fit-content"}
            minHeight={"400px"}
            maxHeight={heightFixedPosition.height - 347}
          >
            <WSEdit
              onChange={(e) => {
                setBody(e);
              }}
              initBody={initBody}
              isShowButtons={true}
            />
          </BoxNew>
        </BoxNew>
        {/* editor end */}

        {/* footer start */}
        <BoxNew
          width={"100%"}
          justifyContent="flex-end"
          classes="gap-8 pd pb-20 pt-20"
        >
          <ButtonNew
            btnStyle="fill"
            type="secondary"
            size="md"
            label={t("common.button.cancel")}
            onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/template`)}
          />
          <ButtonNew
            btnStyle="fill"
            type="primary"
            size="md"
            label={
              type === "UPDATE" ? t("common.button.save") : t("quiz.label.save")
            }
            onClick={() => {
              if (type === "UPDATE") updateTemplate();
              else insertTemplate();
            }}
          />
        </BoxNew>
        {/* footer end */}
      </BoxNew>
    </BoxNew>
  );
};

export default WriteQuizTemplate;
