import {
  Box,
  Button,
  Modal,
  Typography,
  Radio,
  InputField,
  Dropdown,
  DropdownItem,
  Checkbox,
  useToast,
} from "fds";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import { useEffect, useRef, useState } from "react";
import { UserType } from "../../../types/User";
import "./index.scss";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { CustomerInfoBean } from "../../../types/Info";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import utilsCommon from "../../../shared/utils/common";
import useManagerStore from "../../../redux/dispatcher/useManagerStore";
import { nanoid } from "@reduxjs/toolkit";
import { groupedMenus, parentMenus } from ".";
import { ManagerBean } from "../../../types/Manage";
import {
  getLocalCustomerCode,
  getUserInfoFromLocalStorage,
} from "../../../shared/utils/auth";
import { useTranslation } from "react-i18next";

interface RegistUpdateUserModalProps {
  isOpen: boolean;
  modalType: string;
  updateUserCode: string;
  onClose: () => void;
  setRowIdCreated: (rowId: string) => void;
}

const RegistUpdateUserModal: React.FC<RegistUpdateUserModalProps> = ({
  isOpen,
  modalType,
  updateUserCode,
  onClose,
  setRowIdCreated,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const ref = useRef<HTMLInputElement>(null);
  const checkboxGroup = useCheckboxGroup<number>();

  const { customerState, getAccessCompanyList } = useCustomerStore();
  const { deptState, getOrganUserList } = useDeptStore();
  const { managerState, addNewManager, updateManagerInfo, getManagerList } =
    useManagerStore();

  const [userList, changeUserList] = useState<UserType[]>([]);
  const [managerInfo, changeManagerInfo] = useState({
    managerPwd: "",
    managerName: "",
    managerType: 2,
    customerCode: getLocalCustomerCode() ?? "",
    managerEmail: "",
    menuAccess: "[]",
  });
  const [selectManagerType, changeSelectManagerType] = useState<string>("2");
  const [selectUsertType, changeSelectUserType] = useState<string>("NEW");
  const [companyList, chageCompanyList] = useState<CustomerInfoBean[]>([]);
  const [inputErr, changeInputErr] = useState("");
  const [buttonClick, setButtonClick] = useState(0);

  useEffect(() => {
    if (!deptState.userListInDept || deptState.userListInDept?.length === 0)
      getOrganUserList();
  }, []);
  useEffect(() => {
    if (updateUserCode === "") return;

    let targetManager = managerState.managerList?.filter(
      (item: ManagerBean) => item.managerCode === updateUserCode
    )[0];
    let menuAccess = targetManager?.menuAccess;
    changeManagerInfo(targetManager);
    changeSelectManagerType(targetManager.managerType + "");
    checkboxGroup.handleAllClick(
      menuAccess
        .substring(1, menuAccess.length - 1)
        .split(",")
        .filter((item2: string) => item2)
        .map((item1: string) => parseInt(item1))
    );
  }, [updateUserCode]);
  useEffect(() => {
    if (customerState?.companyList)
      chageCompanyList(customerState?.companyList);
    else getAccessCompanyList();
  }, [customerState?.companyList]);
  useEffect(() => {
    changeUserList(deptState?.userListInDept ?? []);
  }, [deptState?.userListInDept]);
  useEffect(() => {
    if (modalType === "CREATE")
      changeManagerInfo({ ...managerInfo, managerEmail: "", managerName: "" });
  }, [selectUsertType]);
  const handleUpdateManagerInfo = async () => {
    if (
      !utilsCommon.passwordValidation(managerInfo.managerPwd) &&
      modalType === "CREATE"
    ) {
      changeInputErr("INVALID_PASSWORD");
      return;
    }
    if (
      !utilsCommon.emailValidation(managerInfo.managerEmail) &&
      (selectUsertType === "NEW" || modalType === "CREATE")
    ) {
      changeInputErr("INVALID_MAIL");
      return;
    }
    changeInputErr("");

    let resp: any =
      modalType === "CREATE"
        ? await addNewManager({
            ...managerInfo,
            managerType: selectManagerType
              ? parseInt(selectManagerType)
              : undefined,
            menuAccess:
              selectManagerType === "2"
                ? "[" + checkboxGroup.selected.join(",") + "]"
                : null,
            customerCode:
              selectManagerType === "2" ? managerInfo.customerCode : undefined,
          })
        : await updateManagerInfo({
            managerCode: updateUserCode,
            managerName: managerInfo.managerName,
            managerType: selectManagerType
              ? parseInt(selectManagerType)
              : undefined,
            menuAccess:
              selectManagerType === "2" && checkboxGroup.selected.length > 0
                ? "[" + checkboxGroup.selected.join(",") + "]"
                : null,
            customerCode:
              selectManagerType === "2" ? managerInfo.customerCode : undefined,
            managerPwd: managerInfo.managerPwd
              ? managerInfo.managerPwd
              : undefined,
          });
    if (modalType === "CREATE") {
      debugger;
      const idCreated = resp.payload.data?.managerEmail || "";
      setRowIdCreated(idCreated.toString());
    }
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("manager.msg.managerUpdateSuccsessMsg"),
        "success"
      );
      getManagerList();
      onClose();
    } else if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
      toast.toastMsg(nanoid(), t("account.msg.joinExistsEmailMsg"), "error");
    } else {
      toast.toastMsg(nanoid(), t("manager.msg.managerAddFailMsg"), "error");
    }
  };

  return (
    <Modal
      open={isOpen}
      title={modalType === "CREATE" ? t("manager.add") : t("manager.update")}
      width={"536px"}
      height={720}
      footer={
        <Box width={"100%"} justifyContent="flex-end">
          <Button
            btnStyle="fill"
            type="secondary"
            onClick={onClose}
            label={t("common.button.cancel")}
          />
          <Button
            btnStyle="fill"
            type="primary"
            classes="mg ml-8"
            state={
              (modalType === "CREATE" &&
                (!managerInfo.managerName ||
                  !managerInfo.managerEmail ||
                  !managerInfo.managerPwd)) ||
              (selectManagerType === "2" && !managerInfo.customerCode) ||
              (modalType === "UPDATE" &&
                (!managerInfo.managerName || !managerInfo.managerEmail)) ||
              (selectManagerType === "2" && checkboxGroup.selected.length === 0)
                ? "disable"
                : "default"
            }
            onClick={handleUpdateManagerInfo}
            label={t("common.button.save")}
          ></Button>
        </Box>
      }
      body={
        <Box direction="column" width={"100%"} maxHeight={540}>
          <Box height={24}>
            <Typography type="subtitle2">{t("manager.name")}</Typography>
            {!!getLocalCustomerCode() && modalType !== "UPDATE" && (
              <>
                <Radio
                  label={t("manager.directInput")}
                  check={selectUsertType === "NEW"}
                  onClick={() => changeSelectUserType("NEW")}
                  classes="mg ml-24"
                />
                <Radio
                  label={t("manager.searchInUser")}
                  check={selectUsertType === "OLD"}
                  onClick={() => changeSelectUserType("OLD")}
                  classes="mg ml-24"
                />
              </>
            )}
          </Box>
          {selectUsertType === "NEW" ? (
            <InputField
              classes="mg mt-4"
              size={"md"}
              value={managerInfo.managerName}
              onChange={(e) => {
                changeManagerInfo({
                  ...managerInfo,
                  managerName: e.target.value,
                });
              }}
              placeholder=""
              disabled={modalType === "UPDATE"}
              id={"showAutoComplete1"}
            />
          ) : (
            <Dropdown
              value={managerInfo.managerName ? managerInfo.managerName : ""}
              placeholder=" "
              classes="mg mt-4"
              buttonClick={buttonClick}
              btnSize="md"
            >
              {userList?.map((user1, index) => (
                <DropdownItem
                  key={index}
                  id={user1.userId ?? ""}
                  checkColor={managerInfo.managerEmail === user1.userEmail}
                  onClick={() => {
                    changeManagerInfo({
                      ...managerInfo,
                      managerName: user1.userName,
                      managerEmail: user1.userEmail,
                    });
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                  label={user1.userName}
                />
              ))}
            </Dropdown>
          )}
          <Typography type="subtitle2" classes="mg mt-16">
            {t("common.auth.email")}
          </Typography>
          <InputField
            classes="mg mt-4"
            size={"md"}
            value={managerInfo.managerEmail}
            placeholder=""
            isError={inputErr === "INVALID_MAIL"}
            helpMessage={
              inputErr === "INVALID_MAIL"
                ? t("user.msg.userInvalidEmailMsg")
                : ""
            }
            disabled={selectUsertType === "OLD" || modalType === "UPDATE"}
            onChange={(e) =>
              changeManagerInfo({
                ...managerInfo,
                managerEmail: e.target.value,
              })
            }
          />
          {modalType !== "UPDATE" && (
            <>
              <Typography type="subtitle2" classes="mg mt-16">
                {t("common.auth.pwd")}
              </Typography>
              <InputField
                classes="mg mt-4"
                size={"md"}
                value={managerInfo.managerPwd}
                placeholder=""
                type={"password"}
                onChange={(e) =>
                  changeManagerInfo({
                    ...managerInfo,
                    managerPwd: e.target.value,
                  })
                }
                disabled={
                  modalType === "UPDATE" &&
                  managerInfo.managerEmail !==
                    getUserInfoFromLocalStorage()?.email
                  // modalType === "UPDATE"
                }
                isError={inputErr === "INVALID_PASSWORD"}
                helpMessage={
                  inputErr === "INVALID_PASSWORD" ? t("manager.pwdInfo") : "  "
                }
              />
            </>
          )}
          {!getLocalCustomerCode() && modalType !== "UPDATE" && (
            <Box classes="mg mt-16" direction="column">
              <Box>
                <Typography type="subtitle2">
                  {t("common.auth.right")}
                </Typography>
                <Radio
                  label={"Super"}
                  check={selectManagerType === "1"}
                  onClick={() => changeSelectManagerType("1")}
                  classes="mg ml-24"
                />
                <Radio
                  label={t("common.auth.generalAuth")}
                  check={selectManagerType === "2"}
                  onClick={() => changeSelectManagerType("2")}
                  classes="mg ml-24"
                />
              </Box>
              <Typography type="subtitle2" classes="mg mt-16">
                {t("manager.customer")}
              </Typography>
              <Dropdown
                buttonClick={buttonClick}
                placeholder=" "
                value={
                  managerInfo.customerCode
                    ? companyList?.filter(
                        (item) => item.customerCode === managerInfo.customerCode
                      )[0].companyName
                    : t("manager.selectCustomer")
                }
                classes="mg mt-4"
              >
                {companyList?.map((item) => (
                  <DropdownItem
                    onClick={() => {
                      changeManagerInfo({
                        ...managerInfo,
                        customerCode: item.customerCode,
                      });
                      setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                    }}
                    label={item.companyName}
                  />
                ))}
              </Dropdown>
            </Box>
          )}
          <Typography type="subtitle2" classes="mg mt-16">
            {t("manager.accessMenu")}
          </Typography>
          <Box classes="mg mt-4" direction="column">
            {/* {[3, 4, 5, 6].map((item) => (
              <Checkbox
                label={t(accessMenuOptions[item])}
                disabled={selectManagerType === "1"}
                checked={checkboxGroup.isSelected(item)}
                onChange={() => checkboxGroup.handleClick(item)}
              />
            ))} */}
            {/* {[3, 4, 5, 6].map((item) => {
              const parentMenu = menuData[item];
              // const subMenus = groupedMenus[itemIndex] || [];
  
              return (
                <Box direction="column" classes="mg mr-12">
                  <Checkbox
                    label={t(parentMenu[0])}
                    disabled={selectManagerType === "1"}
                    checked={checkboxGroup.isSelected(item)}
                    onChange={() => checkboxGroup.handleClick(item)}
                    key={item}
                  />
                  {subMenus.length
                    ? subMenus.map((i, index) => (
                        <Checkbox
                          label={t(i.label)}
                          onChange={checkboxGroup.handleClick(index)}
                        />
                   
                        ))
                    : ""}
                </Box>
              );
            })} */}

            {parentMenus.map((parentMenu) => {
              const subMenus = groupedMenus[parentMenu.index] || []; // 해당 parent의 하위 메뉴만 가져오기
              const isParentChecked =
                subMenus.length > 0
                  ? subMenus.every((subMenu) =>
                      checkboxGroup.selected.includes(subMenu.index)
                    )
                  : checkboxGroup.selected.includes(parentMenu.index);

              const isIconDash =
                subMenus.length > 0 &&
                subMenus.some((subMenu) =>
                  checkboxGroup.selected.includes(subMenu.index)
                ) &&
                !subMenus.every((subMenu) =>
                  checkboxGroup.selected.includes(subMenu.index)
                );

              const handleParentClick = () => {
                const isSelected = checkboxGroup.isSelected(parentMenu.index);
                const indicesToToggle = [
                  parentMenu.index,
                  ...subMenus.map((subMenu) => subMenu.index),
                ];

                if (isSelected) {
                  // 선택 해제
                  const updatedSelection = checkboxGroup.selected.filter(
                    (index) => !indicesToToggle.includes(index)
                  );

                  checkboxGroup.handleAllClick(updatedSelection);
                } else {
                  // 선택
                  const updatedSelection = Array.from(
                    new Set([...checkboxGroup.selected, ...indicesToToggle])
                  );

                  checkboxGroup.handleAllClick(updatedSelection);
                }
              };
              return (
                <Box
                  key={parentMenu.index}
                  direction="column"
                  classes="mg mt-8"
                >
                  {/* 상위 메뉴 체크박스 */}
                  <Checkbox
                    label={t(parentMenu.label)}
                    disable={selectManagerType === "1"}
                    check={selectManagerType === "1" ? true : isParentChecked}
                    onClick={handleParentClick}
                    indeterminate={isIconDash}
                    justifyContent="flex-start"
                  />
                  {/* 하위 메뉴 렌더링 */}
                  {subMenus.length > 0 && (
                    <Box classes="access__submenu__container">
                      {subMenus.map((subMenu) => (
                        <Checkbox
                          disable={selectManagerType === "1"}
                          key={subMenu.index}
                          label={t(subMenu.label)}
                          check={
                            selectManagerType === "1"
                              ? true
                              : checkboxGroup.isSelected(subMenu.index)
                          }
                          onClick={() =>
                            checkboxGroup.handleClick(subMenu.index)
                          }
                          justifyContent="flex-start"
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      }
      onClose={onClose}
    ></Modal>
  );
};

export default RegistUpdateUserModal;
